import React, {Component} from "react";
import {Button, Sidebar} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./BottomBar.css";

class BottomBar extends Component {
  static propTypes = {
    panelRight: PropTypes.element,
    panelLeft: PropTypes.element
  };

  render() {
    return (
      <Sidebar className={"admin-bottom-bar"} animation="push" direction={"bottom"} visible={true}>
        {this.props.panelLeft &&
          <Button.Group floated="left">
            {this.props.panelLeft}
          </Button.Group>
        }
        {this.props.panelRight &&
          <Button.Group floated="right">
            {this.props.panelRight}
          </Button.Group >
        }
      </Sidebar>
    );
  }
}

export default BottomBar;
