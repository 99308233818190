import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Grid} from "semantic-ui-react";
import "./Accordion.css";

export default class Accordion extends Component {
  /*
  This component displays button to show text get in the props.
   */
  constructor(props) {
    super(props);

    this.state = {display: false};
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
      PropTypes.element.isRequired,
      PropTypes.string.isRequired
    ]),
    color: PropTypes.string,
  };

  render() {
    const content = this.state.display ?
      <pre>{this.props.content}</pre> :
      "";
    const color = !this.state.display ?
      this.props.color || "teal" :
      "grey";

    return (
      <Grid className="Accordion">
        <Grid.Row centered>
          <Button
            color={color}
            content={this.props.title}
            onClick={() => this.setState({display: !this.state.display})}
          />
        </Grid.Row>
        <Grid.Row>
          {content}
        </Grid.Row>
      </Grid>
    );
  }
}
