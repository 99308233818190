import _ from "lodash";

import qs from "query-string";


export const yesNoOptions = [
  {key: "All", text: "All", value: null},
  {key: "Yes", text: "Yes", value: true},
  {key: "No", text: "No", value: false},
];

function convertFilterToArray(filters, asArray) {
  _.each(asArray, (k) => {
    if (_.has(filters, k) && typeof filters[k] === "string") {
      filters[k] = _.merge([], [filters[k]]);
    }
  });
  return filters;
}

export function setFilters(name, value, callback = () => {}) {
  /* Update state.filters and then run callback.
   *
   * Bind this function in Component constructor:
   * this.updateFilters = updateFilters.bind(this);
   *
   * Then use it in onChange event in filter also set value to state.filter.
   * Example:
   * <Input
   *   onChange={(e, data) => this.updateFilters('first_name', data.value, this.getUsers)}
   *   value={this.state.filters.first_name}
   * />
   * */
  const filters = {...this.state.filters};
  filters[name] = value;
  // return to first page for new filter
  this.setState({filters: filters}, callback);
  return filters;
}

export function updateFilters(dict, callback = () => {}) {
  /* Update state.filters, add filters to url parameters and then run callback.
   *
   * Bind this function in Component constructor:
   * this.updateFilters = updateFilters.bind(this);
   *
   * Dict should be object: {filter_name: filter_value}
   *
   * Then use it in onChange event in filter also set value to state.filter.
   * Example:
   * <Input
   *   onChange={(e, data) => this.updateFilters({first_name: data.value}, this.getUsers)}
   *   value={this.state.filters.first_name}
   * />
   * */
  const filters = {...this.state.filters};

  for (const key in dict) {
    filters[key] = dict[key];
  }

  // return to first page for new filter
  if (!("page" in dict)) {
    filters.page = 1;
  }
  this.setState({filters: filters}, callback);
  const url_filters = _.compact(filters);
  for (var key in filters) {
    if (key in filters && !_.includes([null, ""], filters[key])) {
      url_filters[key] = filters[key];
    }
  }
  this.props.history.push({search: qs.stringify(url_filters)});
}

export function updateFiltersStateless(dict, hasPages = true, asArray = []) {
  /* Get new filter values, without changing the state.
   *
   * Bind this function in Component constructor:
   * this.updateFilters = updateFilters.bind(this);
   *
   * Dict should be object: {filter_name: filter_value}
   */
  const filters = {...this.getFilters(this.props, asArray)};

  for (const key in dict) {
    filters[key] = dict[key];
  }

  // return to first page for new filter if there is pagination
  if (!("page" in dict) && hasPages) {
    filters.page = 1;
  }

  const url_filters = {..._.compact(filters)};
  for (var key in filters) {
    if (key in filters && !_.includes([null, ""], filters[key])) {
      url_filters[key] = filters[key];
    }
  }
  this.props.history.push({search: qs.stringify(url_filters)});
  return url_filters;
}

export function parseFilters(callback = () => {}) {
  /* Set state.filters from url parameters and then run callback.
   *
   * Bind this function in Component constructor:
   * this.parseFilters = parseFilters.bind(this);
   *
   * Then use it in componentWillMount.
   * */
  const location = this.props.location ? this.props.location : this.props.history.location;
  const filters = qs.parse(location.search);

  this.props.location.search ?
    this.setState({filters}, callback) : callback();
}

export function getParsedFilters(props, asArray = [], defaults = {}) {
  /* Set state.filters from url parameters and then run callback.
   *
   * Bind this function in Component constructor:
   * this.parseFilters = parseFilters.bind(this);
   *
   * Then use it in componentWillMount.
   * */
  const location = props.location ? props.location : props.history.location;
  let filters = qs.parse(location.search);
  if (Object.getOwnPropertyNames(filters).length === 0) {
    filters = {...defaults};
  }
  filters = unwrapTextBooleans(filters);
  return convertFilterToArray(filters, asArray);
}

export function parseFiltersWithMulti(asArray = [], callback = () => {}) {
  /* Set state.filters from url parameters and then run callback.
   *
   * Bind this function in Component constructor:
   * this.parseFilters = parseFilters.bind(this);
   *
   * Then use it in componentWillMount.
   *
   * @asArray: Which parameters  should be always as array;
   * */
  let filters = qs.parse(this.props.location.search);
  filters = convertFilterToArray(filters, asArray);

  this.props.location.search ?
    this.setState({filters: {...this.state.filters, ...filters}}, callback) : callback();
}

export function updateMultipleFilters(names) {
  /* Update multiple filters values in state.filters for given filter names.
   *
   * Bind this function in Component constructor:
   * this.updateMultipleFilters = updateMultipleFilters.bind(this);
   *
   * Then use it after parseFilters function for multiple values filters.
   * */

  const filters = _.cloneDeep(this.state.filters);
  for (let i = 0; i < names.length; i++) {
    const name = names[i];
    if (name in this.state.filters) {
      if (Array.isArray(filters[name])) {
        filters[name] = this.state.filters[name];
      } else {
        filters[name] = _.compact(filters[name].split(","));
      }
    }
  }
  this.setState({filters});
}

export function parseFilterOptions(name, data, key, value, text, empty = true, none = false) {
  /* Parse options from response and set it to state as filtersOptions.
   *
   * Bind this function in Component constructor:
   * this.parseFilterOptions = parseFilterOptions.bind(this);
   *
   * Params:
   * name - filter name
   * data - response data
   * key - which field from data should be option key
   * value - which field from data should be option value
   * text - which field from data should be option text
   * empty - if true than create empty option (reset filter)
   * none - if true than create none option (empty value)
   */
  const filtersOptions = {...this.state.filtersOptions};

  // create empty value
  filtersOptions[name] = [];
  if (empty) {
    filtersOptions[name].push({key: "empty", value: null, text: "---"});
  }
  // create none value
  if (none) {
    filtersOptions[name].push({key: "none", value: "none", text: "*NONE*"});
  }

  filtersOptions[name].push(..._.map(data, (option) => {
    return {key: option[key], value: option[value], text: option[text]};
  }));
  this.setState({filtersOptions});
}

export function filtersToUrlArgumentValues(filters) {
  /*
  Example usage:
  let filter_values = filtersToUrlArgumentValues({from: 'archive',  module: [1, 2, 3],});
  ajax.get(config.BASIC_TASKS_STATISTICS, {params: filter_values})
  */
  const filter_values = {};
  for (const key in filters) {
    if (Array.isArray(filters[key])) {
      let value = "";
      filters[key].forEach((val) => {
        value += val + ",";
      });
      filter_values[key] = value.slice(0, -1);
    } else {
      filter_values[key] = filters[key];
    }
  }
  return filter_values;
}

export function handleSort(name, callback = () => {}) {
  /* Handle sort in table header cell.
   *
   * Bind this function in Component constructor:
   * this.handleSort = handleSort.bind(this);
   *
   * Use it by add to Table.HeaderCell.
   * Example:
   * <Table.HeaderCell
   *   sorted={this.getSorted(name)}
   *   onClick={(e) => this.handleSort(name, callback)}>
   *
   */
  let order_by = this.state.filters.order_by;
  order_by = order_by === name ? "-" + name : name;
  this.updateFilters({order_by: order_by}, callback);
}

export function getSorted(name, filters = null) {
  /* Return if column should be sorted and in what direction.
   *
   * Bind this function in Component constructor:
   * this.getSorted = getSorted.bind(this);
   *
   * Use it by add to Table.HeaderCell.
   * Example:
   * <Table.HeaderCell
   *   sorted={this.getSorted(name)}
   *   onClick={(e) => this.handleSort(name, callback)}>
   */
  const order_by = filters ? filters.order_by : this.state.filters.order_by;

  if (order_by === name) {
    return "ascending";
  } else if (order_by === "-" + name) {
    return "descending";
  }
  return null;
}

export function sortFlip(filters, field) {
  /* Flips the sorting value, depending on value in existing filters. */
  if (filters.order_by === field) {
    return `-${field}`;
  }
  if (filters.order_by === `-${field}`) {
    return null;
  }
  return field;
}

export function unwrapTextBooleans(filters) {
  const tempFilters = _.cloneDeep(filters);
  _.forEach(filters, (value, filter) => {
    if (value === "true") {
      tempFilters[filter] = true;
    } else if (value === "false") {
      tempFilters[filter] = false;
    } else if (value === "null") {
      tempFilters[filter] = null;
    }
  });
  return tempFilters;
}
