import _ from "lodash";
import React, {Component} from "react";
import {Grid, Tab} from "semantic-ui-react";
import WidgetDnD from "./components/WidgetDnD";
import Toolbar from "./components/Toolbar";
import {getParentWidget, getWidget, iterateAllWidgets, removeWidget} from "../../components/Widgets/widgetUtils";
import Accordion from "../../components/simple/Accordion/Accordion";
import "./WidgetsEdit.css";
import widgetsMapping from "../../components/Widgets/WidgetsMapping";
import ContainerWidget from "../../components/Widgets/ContainerWidget/ContainerWidget";
import PropTypes from "prop-types";


export default class WidgetsEdit2 extends Component {
  static propTypes = {
    widgetConfig: PropTypes.object,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      popupDisplayId: null,
      widgetConfig: {
        structure: []
      },
      widgetVals: {},
      taskId: null,
      selectedWidgets: [],
      scrolling: null,
      dragged: false
    };

    this.rootId = _.uniqueId();
    this.submitId = "submit-button";

    this.validRegister = {};
    this.emptyRegister = {};
    this.prepareForSubmitRegister = {};
  }

  componentDidMount() {
    this.createSubmit();
    setInterval(this.scrollOnDrag, 20);
  }

  updateWidgets = (widgetVals) => {
    this.setState({widgetVals: widgetVals});
  }

  registerValid = (func, componentId) => {
    this.validRegister[componentId] = func;
  };

  registerEmpty = (func, componentId) => {
    this.emptyRegister[componentId] = func;
  };

  registerPrepareForSubmit = (func, componentId) => {
    this.prepareForSubmitRegister[componentId] = func;
  };

  isEmpty = (componentId) => {
    if (this.emptyRegister[componentId]) {
      return this.emptyRegister[componentId]();
    }
    return false;
  };

  isValid = (componentId) => {
    if (this.validRegister[componentId]) {
      return this.validRegister[componentId]();
    }
    return true;
  };

  prepareForSubmit = (componentId) => {
    if (this.prepareForSubmitRegister[componentId]) {
      return this.prepareForSubmitRegister[componentId]();
    }
    return true;
  };

  openPopup = (e, widgetId) => {
    const widget = getWidget(this.props.widgetConfig.structure, this.state.popupDisplayId);
    if (this.state.popupDisplayId !== null && this.state.popupDisplayId !== widgetId && widget) {
      return;
    }

    this.setState({popupDisplayId: this.state.popupDisplayId === widgetId ? null : widgetId});
  }

  getWidgetStructure = () => {
    return this.props.widgetConfig.structure;
  }

  getWidgetVals = () => {
    return this.state.widgetVals;
  }

  updateConfig = (structure, callback = null) => {
    this.props.onChange({
      ...this.props.widgetConfig,
      structure
    });
    this.setState({
      widgetConfig: {
        ...this.props.widgetConfig,
        structure
      }
    }, callback);
  }

  updateVals = (val, id) => {
    this.setState({
      widgetVals: {
        ...this.state.widgetVals,
        [id]: val
      }
    });
  }

  updateId = (oldId, newId) => {
    // change widget config
    const widgetConfig = _.cloneDeep(this.props.widgetConfig);
    const widget = getWidget(widgetConfig.structure, oldId);
    iterateAllWidgets(widgetConfig.structure, (w) => {
      if (_.get(w, "displayRule.data.targetId", null) === oldId) {
        w.displayRule.data.targetId = newId;
      }
    });
    const popupDisplayId = newId;
    widget.componentId = newId;

    // change widget values
    const widgetVals = _.cloneDeep(this.state.widgetVals);
    widgetVals[newId] = widgetVals[oldId];
    delete widgetVals[oldId];

    this.setState({popupDisplayId, widgetVals});
    this.props.onChange(widgetConfig);
  }

  // NOTE: In this function set only config for new widget.
  // If you need setState for change widgetVals do this in getEditor method.
  getDefaultConfig = (component, id) => {
    const props = {
      componentType: component,
      componentId: id,
    };

    let additionalProps = {};
    // special treating for ContainerWidget (cyclic imports issue)
    if (component === "ContainerWidget") {
      additionalProps = ContainerWidget.getDefaultConfig();
    } else {
      additionalProps = widgetsMapping[component].getDefaultConfig();
    }

    return {
      ...props,
      ...additionalProps,
    };
  }

  handleDrop = (dropId, e) => {
    const create = e.dataTransfer.getData("create");

    if (create) {
      this.handleDropCreate(dropId, e);
    }
  }

  handleDropCreate = (dropId, e) => {
    e.stopPropagation();

    const component = e.dataTransfer.getData("type"),
      widgetConfig = _.cloneDeep(this.props.widgetConfig);

    // find unique id for new component
    let id = _.uniqueId();
    while (getWidget(this.props.widgetConfig.structure, id)) {
      id = _.uniqueId();
    }

    const newWidget = this.getDefaultConfig(component, id);

    if (dropId === "rootWidget") {
      widgetConfig.structure.splice(widgetConfig.structure.length - 1, 0, newWidget);
    } else {
      iterateAllWidgets(widgetConfig.structure, (w) => {
        if (w.componentId === dropId) {
          // there has to be a smarter way to do this
          if (w.widgetConfig) {
            if (w.widgetConfig.structure) {
              w.widgetConfig.structure.push(newWidget);
            } else {
              w.widgetConfig.structure = [newWidget];
            }
          } else {
            w.widgetConfig = {structure: [newWidget]};
          }
        }
      });
    }
    this.props.onChange(widgetConfig);
    this.setState({dragged: false});
  }

  checkIdUnique = (id) => {
    const ids = [];
    iterateAllWidgets(this.props.widgetConfig.structure, (w) => {
      ids.push(w.componentId);
    });
    const result = !_.includes(ids, id);
    return result;
  };

  createSubmit = () => {
    const widgetConfig = _.cloneDeep(this.props.widgetConfig);
    if (!widgetConfig) {
      return;
    }
    const type = "SubmitWidget";
    const id = this.submitId;

    const newWidget = this.getDefaultConfig(type, id);
    if (!widgetConfig.structure) {
      widgetConfig.structure = [newWidget];
    } else {
      if (!getWidget(widgetConfig.structure, "submit-button")) {

        widgetConfig.structure.push(newWidget);
      }
    }

    this.props.onChange(widgetConfig);
    // this.setState({widgetConfig})
  };

  selectWidgets = (selected, componentId) => {
    const widgets = this.state.selectedWidgets;
    const index = widgets.indexOf(componentId);

    if (index > -1) {
      if (!selected) {
        widgets.splice(index, 1);
      }
    } else if (selected) {
      widgets.push(componentId);
    }

    this.setState({selectedWidgets: widgets});
  };

  getUniqueId = (widget) => {
    const id = widget.componentId;

    // Add numeral to end of new ID (to make it unique).
    let counter = 1;
    while (!this.checkIdUnique(id + counter)) {
      counter++;
    }

    widget.componentId = id + counter;
  };

  copyWidget = (componentId) => {
    // Prevent from copying submit button.
    if (componentId === "submit-button") {
      return;
    }

    // Get rootWidget object.
    const taskStructure = this.getWidgetStructure();

    let duplicate = null;
    iterateAllWidgets(taskStructure, (widget) => {
      if (widget.componentId === componentId) {
        duplicate = _.cloneDeep(widget);
      }
    });

    // Create unique ID for duplicate and it's children.
    iterateAllWidgets([duplicate], (w) => {
      this.getUniqueId(w, taskStructure);
    }
    );

    // Find parent of a widget.
    const parent = getParentWidget(taskStructure, componentId);
    // Add duplicate to rootWidget if parent is null.
    const structureToUpdate = !!parent ? parent.widgetConfig.structure : taskStructure;

    const lastWidget = structureToUpdate.pop();
    lastWidget.componentId === "submit-button" ?
      structureToUpdate.push(duplicate, lastWidget)
      : structureToUpdate.push(lastWidget, duplicate);

    const newConfig = {...this.state.widgetConfig, structure: taskStructure};
    // Force rerender.
    this.setState({widgetConfig: newConfig});
  };

  startCopy = () => {
    const widgets = this.state.selectedWidgets;
    _.forEach(widgets, (widget) => {
      this.copyWidget(widget);
    });

    this.setState({selectedWidgets: []});
  };

  deleteWidget = (componentId) => {
    // Prevent from deleting submit button.
    if (componentId === "submit-button") {
      return;
    }

    const taskStructure = this.getWidgetStructure();
    removeWidget(taskStructure, componentId);

    const newConfig = {...this.state.widgetConfig, structure: taskStructure};
    // Remove ID from selectedWidgets if is contained.
    this.selectWidgets(false, componentId);
    // Force rerender.
    this.setState({widgetConfig: newConfig});
  };

  startDelete = () => {
    const widgets = this.state.selectedWidgets;
    _.forEach(widgets, (widget) => {
      this.deleteWidget(widget);
    });

    this.setState({selectedWidgets: []});
  };

  handleDragEnd = () => {
    this.setState({dragged: false});
  };

  handleDrag = () => {
    if (!this.state.dragged) {
      this.setState({dragged: true});
    }
  };

  setScrolling = (mode) => {
    this.setState({scrolling: mode});
  };

  scrollOnDrag = () => {
    const scrollingElement = (document.scrollingElement || document.body);
    if (this.state.dragged) {
      if (this.state.scrolling === "DOWN") {
        scrollingElement.scrollTop += 10;
      } else if (this.state.scrolling === "UP") {
        scrollingElement.scrollTop -= 10;
      }
    }
  };

  renderPreview = () => {
    const widgetConfig = _.cloneDeep(this.props.widgetConfig);

    const config = (
      <React.Fragment>
        <pre>{JSON.stringify(widgetConfig, null, 2)}</pre>
        <pre>{JSON.stringify(this.state.widgetVals, null, 2)}</pre>
      </React.Fragment>
    );

    return (
      <Tab.Pane className="preview-panel">
        <Grid>
          <Grid.Row>
            <ContainerWidget
              componentId={"rootWidget"}
              widgetVals={this.state.widgetVals}
              update={this.updateVals}
              toRender={widgetConfig}
              getWidgetConfig={this.getWidgetStructure}
              questionId={"123"}
              registerValid={this.registerValid}
              registerEmpty={this.registerEmpty}
              registerPrepareForSubmit={this.registerPrepareForSubmit}
              isValid={this.isValid}
              isEmpty={this.isEmpty}
              prepareForSubmit={this.prepareForSubmit}
            />
          </Grid.Row>
          <Grid.Row>
            <Accordion
              title="JSON config"
              content={config}
            />
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }

  renderEdit = () => {
    const editorProps = {
      getWidgetConfig: this.getWidgetStructure,
      widgetVals: this.state.widgetVals,
      updateConfig: this.updateConfig,
      updateVals: this.updateVals,
      updateId: this.updateId,
      openPopup: this.openPopup,
      checkIdUnique: this.checkIdUnique,
    };

    const containerWidget = (
      <ContainerWidget
        key={this.rootId}
        editorProps={editorProps}
        componentId={"rootWidget"}
        widgetVals={this.state.widgetVals}
        update={null}
        onDrop={this.handleDrop}
        toRender={this.props.widgetConfig}
        openPopup={this.openPopup}
        popupDisplayId={this.state.popupDisplayId}
        skipDisplayRule={true}
        draggable={true}
        getWidgetConfig={this.getWidgetStructure}
        updateConfig={this.updateConfig}
        questionId={"123"}
        selectWidgets={this.selectWidgets}
        selectedWidgets={this.state.selectedWidgets}
        copyWidgets={this.startCopy}
        deleteWidgets={this.startDelete}
      />);

    const toolbar = <Toolbar/>;

    return (
      <Tab.Pane className="edit-panel">
        {this.state.dragged ?
          <div className="top-scroll"
            onDragOver={() => this.setScrolling("UP")}
            onDragLeave={() => this.setScrolling(null)}/>
          : ""}
        <Grid>
          <Grid.Row>
            <WidgetDnD
              choices={toolbar}
              selectedChange={(e) => this.handleDrop("rootWidget", e)}
              selected={[containerWidget]}
              onDrag={this.handleDrag}
              onDragEnd={this.handleDragEnd}
            />
          </Grid.Row>
          <Grid.Row>
            <Accordion title="JSON config"
              content={JSON.stringify(this.props.widgetConfig, null, 2)}
            />
            <Accordion title="JSON value"
              content={JSON.stringify(this.state.widgetVals, null, 2)}
            />
          </Grid.Row>
        </Grid>
        {this.state.dragged ?
          <div className="bottom-scroll"
            onDragOver={() => this.setScrolling("DOWN")}
            onDragLeave={() => this.setScrolling(null)}/>
          : ""}
      </Tab.Pane>
    );
  }

  render() {
    const panes = [
      {key: "iscd", menuItem: "Edit", render: this.renderEdit},
      {key: "iscd1", menuItem: "Preview", render: this.renderPreview},
    ];

    return (
      <Tab
        className="WidgetsEdit2"
        panes={panes}
      />
    );
  }
}
