import React from "react";
import PriceTariffsModal from "./PriceTariffsModal/PriceTariffsModal";
import {Button, Modal} from "semantic-ui-react";
import ModalActions from "semantic-ui-react/dist/commonjs/modules/Modal/ModalActions";
import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {toastr} from "react-redux-toastr";

const REQUIRED_FIELDS = ["date", "task_type", "task_subtype", "native", "language", "price", "currency"];
export const REQUIRED_FIELDS_INDIVIDUAL = [...REQUIRED_FIELDS, "username"];
export const REQUIRED_FIELDS_COMPANY = [...REQUIRED_FIELDS, "company", "user_type"];
export const REQUIRED_FIELDS_USERS = [...REQUIRED_FIELDS, "users"];

export const NATIVE_OPTIONS = [
  {
    key: 1,
    value: "native",
    text: "Native"
  },
  {
    key: 2,
    value: "non_native",
    text: "Non Native"
  }
];

export function setModal(type, data) {
  if (type === "delete") {
    this.setState({
      deleteModal: true,
      priceTariffsToDelete: data
    });
  } else if (type === "display") {
    this.setState({
      displayModal: true,
      data: data
    });
  }
}

export function renderModal() {
  return <PriceTariffsModal
    closeModal={() => {
      this.setState({
        displayModal: false,
        data: null
      });
      this.getData();
    }}
    currencies={this.state.currencies}
    isIndividual={!!this.state.user}
    username={this.state.user || null}
    users={this.state.users}
    filterByUser={this.state.filterByUser}
    companies={this.state.companies}
    userTypes={this.state.userTypes}
    languages={this.state.languages}
    taskTypes={this.state.taskTypes}
    natives={this.state.natives}
    data={this.state.data || null}
  />;
}

export function renderDeleteModal() {
  return <Modal
    dimmer={true}
    open={true}>
    <Modal.Header>
      Do you want to remove this tariff
    </Modal.Header>
    <ModalActions>
      <Button
        color={"green"}
        onClick={this.deletePrice}>
        Yes
      </Button>
      <Button
        color={"red"}
        onClick={() => {
          this.setState({deleteModal: false});
        }}>
        No
      </Button>
    </ModalActions>
  </Modal>;
}


export function deletePrice() {
  ajax.delete(config.PRICE_TARIFFS + this.state.priceTariffsToDelete + "/")
    .then((response) => {
      if (response.status === 200) {
        toastr.success("Success", "Tariffs deleted!");
        this.setState({deleteModal: false});
        this.getData();
      }
    });
}
