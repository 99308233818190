import React from "react";
import {Checkbox, Grid, Header} from "semantic-ui-react";
import {AlignSelector} from "../components";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./OrderListWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class OrderListWidgetEditor extends WidgetEditor {
  static getDefaultVals = (props) => {
    const widgetVals = {
      items: [
        "\ud83d\udcaf\ufe0f", "\ud83d\ude18\ufe0f", "\ud83d\udc95\ufe0f", "\ud83d\ude0a\ufe0f", "\u2764\ufe0f",
        "\ud83d\udc4d\ufe0f", "\ud83d\ude09\ufe0f"],
      initial: [0, 1, 2, 3, 4, 5, 6],
    };

    props.updateVals(widgetVals, props.widgetId);
  };

  static header = "Ordered List";

  updateAlign = (e, data) => {
    if (data !== "justify") {
      this.updateWidgetConfig((widgetConfig) => {
        widgetConfig.align = data;
      });
    } else {
      this.updateWidgetConfig((widgetConfig) => {
        widgetConfig.align = data;
      });
    }

    this.setState({}); // force re-render
  };

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    return (
      <Grid className={"OrderListWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header
              className="row"
              as="h4">Randomize</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.randomize}
              onChange={(e, data) => this.updateConfig("randomize", data.checked)}/>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Align</Header>
            <AlignSelector
              justify={false}
              className="row"
              size="mini"
              value={config.align}
              onChange={this.updateAlign}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default OrderListWidgetEditor;
