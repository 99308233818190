import _ from "lodash";
import React, {Component} from "react";
import {Accordion, Button, Icon, Table} from "semantic-ui-react";
import PropTypes from "prop-types";

import ModalConfirm from "../../components/ModalConfirm";

class UserTaskModal extends Component {
  static propTypes = {
    unresolvedReviews: PropTypes.any,
    action: PropTypes.func,
    title: PropTypes.string,
    onClose: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1
    };
  }

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({activeIndex: newIndex});
  };

  render() {
    const confirm = <h4>Are you sure you want to resolve these reviews?</h4>;
    const users = _.map(this.props.unresolvedReviews, (user, idx) => {
      let answerIds = _.map(user.answer_ids, (answerId) => {
        return (
          <Table.Row key={"usr" + idx}>
            <Table.Cell>{answerId}</Table.Cell>
          </Table.Row>
        );
      });
      return (
        <Table.Row key={"user" + idx}>
          <Table.Cell width={6}>
            {user.username}
          </Table.Cell>
          <Table.Cell width={3}>
            {user.unresolved}
          </Table.Cell>
          <Table.Cell>
            {user.unresolved !== 0 ?
              <Accordion as={"table"}>
                <Accordion.Title as={"thead"} active={this.state.activeIndex === idx} index={idx}
                  onClick={this.handleClick}>
                  <Icon size="small" name="dropdown"/>
                </Accordion.Title>
                <Accordion.Content as={"tbody"} active={this.state.activeIndex === idx}>
                  {answerIds}
                </Accordion.Content>
              </Accordion> : null
            }
          </Table.Cell>
        </Table.Row>
      );
    });

    const content =
      <React.Fragment>
        {confirm}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Username</Table.HeaderCell>
              <Table.HeaderCell width={2}>Unresolved reviews</Table.HeaderCell>
              <Table.HeaderCell width={3}>Unresolved answers</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users}
          </Table.Body>
        </Table>
      </React.Fragment>;

    const button =
        <Button
          color="green"
          className="datahive-button"
          onClick={this.props.action}
        >
          Confirm
        </Button>;

    return <ModalConfirm
      title={this.props.title}
      closeModal={this.props.onClose}
      content={content}
      button={button}
    />;
  }
}

export default UserTaskModal;
