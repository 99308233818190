import React from "react";
import {Group, Circle} from "react-konva/lib/ReactKonvaCore";
import PropTypes from "prop-types";

const Vertex = ({x, y, color="yellow", isEdit, isSettled, onDragStart, onDragMove, onDragEnd, onCursorChange}) =>
  <Group
    x={x}
    y={y}
    draggable={isEdit}
    onDragStart={onDragStart}
    onDragMove={onDragMove}
    onDragEnd={onDragEnd}
    onMouseOver={() => isEdit && onCursorChange("move")}
    onMouseOut={() => isEdit && onCursorChange("default")}>
    <Circle
      radius={isSettled ? 3 : 5}
      fill="black"/>
    <Circle
      radius={isSettled ? 1 : 2}
      fill={color}/>
  </Group>;

Vertex.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string,
  isEdit: PropTypes.bool.isRequired,
  isSettled: PropTypes.bool.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragMove: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onCursorChange: PropTypes.func.isRequired,
};

export default Vertex;
