import React from "react";
import PropTypes from "prop-types";

export default class DraggableSegmentCorner extends React.Component {
  static propTypes = {
    initialX: PropTypes.number,
    initialY: PropTypes.number,
    onDragStop: PropTypes.func,
    onDrag: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.elmnt = React.createRef();
  }

  elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    this.pos1 = this.pos3 - e.clientX;
    this.pos2 = this.pos4 - e.clientY;
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;

    // set the element's new position:
    this.elmnt.current.style.top = (this.elmnt.current.offsetTop - this.pos2) + "px";
    this.elmnt.current.style.left = (this.elmnt.current.offsetLeft - this.pos1) + "px";
    this.props.onDrag(e, {x: parseInt(this.elmnt.current.style.left, 10), y: parseInt(this.elmnt.current.style.top, 10)});
  };

  closeDragElement = (e) => {
    // stop moving when mouse button is released:
    document.removeEventListener("mouseup", this.closeDragElement);
    document.removeEventListener("mousemove", this.elementDrag);
    this.props.onDragStop(e, {x: parseInt(this.elmnt.current.style.left, 10), y: parseInt(this.elmnt.current.style.top, 10)});
  };

  componentDidMount() {
    if (this.props.initialY) {
      this.elmnt.current.style.top = `${this.props.initialY}px`;
    }
    if (this.props.initialX) {
      this.elmnt.current.style.left = `${this.props.initialX}px`;
    }

  }

  render() {
    return (
      <div
        ref={this.elmnt}
        onMouseDown={(e) => {
          e.preventDefault();
          // get the mouse cursor position at startup:
          this.pos3 = e.clientX;
          this.pos4 = e.clientY;
          document.addEventListener("mouseup", this.closeDragElement);
          // call a function whenever the cursor moves:
          document.addEventListener("mousemove", this.elementDrag);
        }}
        style={{position: "absolute", zIndex: 999}}
      >
        {this.props.children}
      </div>
    );
  }
}
