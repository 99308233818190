/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import _ from "lodash";

import "./SelectWidget.css";
import TreeSelect from "../../simple/TreeSelect/TreeSelect";
import {
  getNode as genericGetNode,
  getValuesToFind,
  makeOptionsTree,
  removeNode,
  treeLeavesValuesDiff
} from "../../simple/TreeSelect/utils";
import Widget from "../Widget";

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */


class SelectWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  static getDefaultConfig = () => {
    return {
      categories: [
        "test.1.1",
        "test.1.2",
        "test.1.3",
        "test2.1",
        "test2.1.1",
        "test2.1.2",
        "test2.1.3",
        "test2.3",
        "test3.1.1",
        "test3.1.2",
        "test3.1.3",
        "test3.2.1",
        "test3.2.2",
      ],
      isDefaultCategory: false,
      multiple: false,
      readonly: false,
      tags: false,
      customCategories: false,
    };
  };

  constructor(props) {
    super(props);
    let categories = [];

    if (this.props.widgetConfig.customCategories) {
      if (this.props.widgetVals[this.props.componentId]?.categories) {
        categories = this.props.widgetVals[this.props.componentId].categories;
      }
    } else {
      categories = this.props.widgetConfig.categories;
    }
    const options = makeOptionsTree(categories);
    const raw_options = _.cloneDeep(options);

    let defaultCategory = null;

    if (this.props.widgetVals[this.props.componentId]?.selected) {
      defaultCategory = this.props.widgetVals[this.props.componentId].selected;
    } else if (this.props.widgetConfig.customCategories) {
      defaultCategory = this.props.widgetVals[this.props.componentId]?.defaultCategory;
    } else {
      defaultCategory = this.props.widgetConfig.defaultCategory;
    }
    defaultCategory = _.isArray(defaultCategory) ? defaultCategory : [defaultCategory];

    this.getNode = this.getNode.bind(this);
    this.getValues = this.getValues.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateValueAndOptions = this.updateValueAndOptions.bind(this);
    this.state = {
      "options": options,
      "raw_options": raw_options,
      "searchQuery": "",
      "defaultCategory": defaultCategory,
      "selected": [],
      "categories": categories,
    };
  }

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetConfig = _.cloneDeep(this.props.widgetConfig);
    let widgetVal = null;
    let defaultCategoryQuestions = null;
    if (!!widgetVals && _.get(widgetVals[this.props.componentId], "selected")) {
      widgetVal = _.get(widgetVals[this.props.componentId], "selected");
      defaultCategoryQuestions = _.get(widgetVals[this.props.componentId], "defaultCategory");
    } else if (widgetConfig.customCategories) {
      if (!!widgetVals && _.get(widgetVals[this.props.componentId], "defaultCategory")) {
        widgetVal = _.get(widgetVals[this.props.componentId], "defaultCategory");
        widgetVal = _.isArray(widgetVal) ? widgetVal : [widgetVal];
        defaultCategoryQuestions = widgetVal;
      }
    } else {
      if (widgetConfig.isDefaultCategory) {
        widgetVal = [_.get(widgetConfig, "defaultCategory")];
      } else if (_.isArray(widgetVals[this.props.componentId])) {
        widgetVal = widgetVals[this.props.componentId];
      } else if (typeof (widgetVals[this.props.componentId]) === "string") {
        widgetVal = [widgetVals[this.props.componentId]];
      } else {
        widgetVal = [];
      }
    }
    this.registerEmptyValid();
    const defaultCategoryFromQuestions = widgetConfig.customCategories && !!defaultCategoryQuestions ? defaultCategoryQuestions : [];
    const defaultCategory = widgetConfig.isDefaultCategory ? [_.get(widgetConfig, "defaultCategory")] : defaultCategoryFromQuestions;
    const listCategories = [];
    this.state.options.map((item) => listCategories.push(item.value));
    const data = {
      "selected": widgetVal,
      "categories": listCategories,
      "defaultCategory": defaultCategory
    };
    this.update(data);
  }

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId].selected;

    return !(widgetVal && widgetVal.length);
  };

  isValid = () => {
    return true;
  };

  updateValue = (values, newVal, adding = false) => {
    if (!this.props.widgetConfig.readonly) {
      if (!adding) {
        const options = _.cloneDeep(this.state.options);
        newVal = newVal ? [newVal] : treeLeavesValuesDiff(options, this.state.raw_options);
        for (let i = 0; i < newVal.length; i++) {
          if (values.indexOf(newVal[i]) < 0 && !(this.getNode(newVal[i], this.state.raw_options)[0])) {
            removeNode(options, newVal[i]);
          }
        }
        this.setState({options});
      }
      const widgetVal = this.props.widgetVals[this.props.componentId];
      const listCategories = [];
      const allOptions = this.state.options;
      allOptions.map((item) => listCategories.push(item.value));
      const defaultCategory = this.props.widgetConfig.isDefaultCategory ? [_.get(this.props.widgetConfig, "defaultCategory")] : [];
      const data = {
        "selected": values,
        "categories": listCategories,
        "defaultCategory": widgetVal ? widgetVal.defaultCategory : []
      };
      this.update(data);
    }
  };

  getNode(value, options = this.state.options) {
    const data = _.cloneDeep(options);
    return [genericGetNode(data, value), data];
  }

  handleAddClick(newNode, parentValue) {
    const config = this.props.widgetConfig;
    if (!config.readonly && "value" in newNode && newNode.value) {
      const values = config.multiple && this.props.value ? _.cloneDeep(this.props.value) : [];
      const value = (parentValue ? parentValue + "." : "") + newNode["value"];
      newNode["value"] = newNode["title"] = value;
      if (!this.getNode(value)[0] && newNode["value"]) {
        /*eslint-disable-next-line*/
        let [node, data] = this.getNode(parentValue);
        if (!config.multiple) {
          data = _.cloneDeep(this.state.raw_options);
        }
        if (node) {
          if (node.children) {
            node.children.unshift(newNode);
          } else {
            node.children = [newNode];
          }
        } else {
          data.unshift(newNode);
        }
        values.push(value);
        this.updateValueAndOptions(values, null, data, true);
      }
    }
  }

  handleSubmit(text) {
    const config = this.props.widgetConfig;

    if (!text) {
      return;
    }
    let [node, data] = this.getNode(text);
    if (node) {
      text = node.value;
    }
    // Multiple search
    if (!node) {
      const valuesThatMatch = getValuesToFind(this.state.options, this.state.searchQuery);
      if (valuesThatMatch.length === 1) {
        node = this.getNode(valuesThatMatch[0])[0];
        text = node.value;
      }
    }
    // If still not found
    if (!node && config.tags && !config.readonly) {
      data.unshift({
        title: text,
        value: text,
      });
    }
    // Discard text if not found and config.tags is false
    if (!node && !config.tags) {
      return;
    }
    let values = this.getValues();
    if (!config.multiple) {
      values = [text];
    } else {
      values.push(text);
    }
    this.updateValueAndOptions(values, text, data, true);
    this.setState({searchQuery: ""});
  }

  updateData(options) {
    if (!this.props.widgetConfig.readonly) {
      this.setState({options});
    }
  }

  updateValueAndOptions(values, value, options, adding = false) {
    const config = this.props.widgetConfig;

    if (!config.readonly) {
      let optionsCopy = _.cloneDeep(options);
      if (!adding) {
        if (values.indexOf(value) < 0 && !(this.getNode(value, this.state.raw_options)[0])) {
          optionsCopy = options ? optionsCopy : _.cloneDeep(config.options);
          removeNode(optionsCopy, value);
        }
      }
      this.setState({options: optionsCopy});
      this.update(values);
    }
  }

  getValues() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    if (!widgetVal) {
      return widgetVals[this.props.componentId] = [];
    } else {
      return widgetVal;
    }
  }

  onSearchChange = (searchQuery) => {
    this.setState({searchQuery});
  };

  render() {
    const config = this.props.widgetConfig;
    let value = this.props.value;

    const item = _.get(this.props.value, "selected") ? value.selected : [];

    return (
      <div className={`SelectWidget ${config.align || "align-left"}`}
        style={{padding: "0 10px"}}>
        <TreeSelect
          defaultCategory={_.isArray(config.defaultCategory) ? [config.defaultCategory] : [config.defaultCategory]}
          isDefaultCategory={config.isDefaultCategory}
          multiple={config.multiple}
          value={item}
          data={this.state.options}
          enableAdditionOnEnter={config.tags && !config.readonly}
          updateValue={this.updateValue}
          updateData={this.handleSubmit}
          handleAddClick={this.handleAddClick}
          handleSubmitstomCategories={config.customCategories}
          searchQuery={this.state.searchQuery}
          onSearchChange={this.onSearchChange}
        />
      </div>
    );
  }
}


export default SelectWidget;
