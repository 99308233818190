import React, {Component} from "react";
import _ from "lodash";
import "react-dates/initialize";
import {Icon, Button, Popup, Table, Header} from "semantic-ui-react";
import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {
  deletePrice,
  renderDeleteModal,
  renderModal,
  setModal
} from "../PriceTariffs/PriceTariffsFunctions";
import PropTypes from "prop-types";
import {getPossibleDropdownValues} from "../../helpers/utils";
import LoaderSpinner from "../../components/LoaderSpinner";

import "react-dates/lib/css/_datepicker.css";
import "../Tasks.css";
import {toastr} from "react-redux-toastr";


class UserPriceTariffs extends Component {

  static propTypes = {
    username: PropTypes.string,
    edit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      currencies: [],
      languages: [],
      priceTariffs: null,
      taskTypes: {},
      user: this.props.username
    };

    this.deletePrice = deletePrice.bind(this);
    this.renderDeleteModal = renderDeleteModal.bind(this);
    this.renderModal = renderModal.bind(this);
    this.setModal = setModal.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getDropdownOptions();
  }

  getData = () => {
    let filter = {filter_by_user: true, user: [this.props.username]};
    ajax.get(config.PRICE_TARIFFS, {params: filter})
      .then((response) => {
        this.setState({priceTariffs: response.data});
      })
      .catch((err) => toastr.error("Error!", err.response.statusText));
  };

  getDropdownOptions = () => {
    let options;
    ajax.get(config.LANGUAGES)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({
          languages: options,
        });
      });
    ajax.get(config.TASK_TYPES)
      .then((response) => {
        this.setState({
          taskTypes: response.data,
        });
      });
    ajax.get(config.CURRENCY_OPTIONS)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({
          currencies: response.data,
        });
      });
  };

  listTariffs = () => {
    return _.map(this.state.priceTariffs, (tariff, idx) => {
      let priceData = {
        ...tariff,
        currency: tariff.price_currency,
        native: tariff.native ? "native" : "non_native",
        price: tariff.price.split(" ")[0]
      };
      return (
        <Table.Row key={idx}>
          <Table.Cell width={1}>{tariff.price} {tariff.price_currency}</Table.Cell>
          <Table.Cell width={2}>{tariff.language}</Table.Cell>
          <Table.Cell width={2}>
            <Icon
              disabled
              name={tariff.native ? "check" : "delete"}
              color={tariff.native ? "green" : "red"}
            />
          </Table.Cell>
          <Table.Cell width={2}>{tariff.task_type}</Table.Cell>
          <Table.Cell width={1}>{tariff.task_subtype}</Table.Cell>
          <Table.Cell width={2}>{tariff.date}</Table.Cell>
          {this.props.edit &&
            <Table.Cell width={1} textAlign={"right"}>
              <Popup
                content={"Modify tariff"}
                trigger={
                  <Button
                    icon={"wrench"}
                    color={"blue"}
                    onClick={() => this.setModal("display", priceData)}
                  />
                }
              />
              <Popup
                content={"Remove tariff"}
                trigger={
                  <Button
                    icon={"delete"}
                    color={"red"}
                    onClick={() => this.setModal("delete", tariff.id)}/>
                }
              />
            </Table.Cell>
          }
        </Table.Row>
      );
    });
  };

  render() {
    if (!this.state.priceTariffs) {
      return <LoaderSpinner/>;
    }
    if (!this.state.priceTariffs.length) {
      return <Header>There are no tariffs for this user</Header>;
    }
    return (
      <div>
        {this.state.displayModal && this.renderModal()}
        {this.state.deleteModal && this.renderDeleteModal()}
        <Table selectable singleLine textAlign={"center"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Price</Table.HeaderCell>
              <Table.HeaderCell width={2}>Language</Table.HeaderCell>
              <Table.HeaderCell width={2}>Native</Table.HeaderCell>
              <Table.HeaderCell width={2}>Task type</Table.HeaderCell>
              <Table.HeaderCell width={1}>Task subtype</Table.HeaderCell>
              <Table.HeaderCell width={2}>Date</Table.HeaderCell>
              {this.props.edit &&
                <Table.HeaderCell width={1} textAlign={"right"}>
                  <Popup
                    trigger={
                      <Button
                        color={"green"}
                        content={"Add"}
                        onClick={() => this.setState({displayModal: true})}
                      />
                    }
                    content={"Add new price tariff"}
                  />
                </Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.listTariffs()}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default UserPriceTariffs;
