/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import { Button, Progress } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import Recorder from "./recorder/recorder";
import "./AudioAnswerWidget.css";
import Widget from "../Widget";


const WARNING_MESSAGE = "In editor mode uploading files is disabled. " +
  "It works only on real scenario of task!";

class AudioAnswerWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    /* Id of question. Its necessary for saving files on the backend. */
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static getDefaultConfig = () => {
    return {};
  };

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    return !(widgetVal && widgetVal.length);
  };

  isValid = () => {
    return true;
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      progress: 0,
      readonly: false
    };
  }

  componentDidMount() {
    this.registerEmptyValid();
    if (this.props.editorMode) {
      return;
    }
    this.getUploadedFiles();
  }

  getUploadedFiles = () => {
    const taskSlug = this.props.location?.pathname?.split("/")[2];
    const params = {
      questionId: this.props.questionId,
      username: this.props.answerAuthor,
      slug: taskSlug
    };
    ajax.get(config.FILE_UPLOAD_WIDGET_UPLOADED, { params })
      .then((response) => {
        const data = response.data;
        this.setState({ uploadedFiles: data });
        const widgetVals = _.cloneDeep(this.props.widgetVals);
        let widgetVal = widgetVals[this.props.componentId];
        if (!widgetVal && data && data.length > 0) {
          widgetVal = _.map(data, (el) => el.path);
          if (data["duration"]) {
            widgetVal["duration"] = data["duration"];
          }
          this.update(widgetVal);
        }
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  onUploadProgress = (e) => {
    if (e.type === "progress") {
      this.setState({ progress: _.round(e.loaded / e.total * 100, 2) });
    }
  };

  handleAudioStop = (file) => {
    if (this.props.editorMode) {
      toastr.warning(WARNING_MESSAGE);
      return;
    }
    const data = new FormData();
    const taskSlug = this.props.location?.pathname?.split("/")[2];
    data.append("questionId", this.props.questionId);
    data.append("file", file, (this.props.questionId + "_" + Date.now() + ".wav"));
    data.append("slug", taskSlug);
    data.append("getDuration", true);

    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    ajax.post(config.FILE_UPLOAD_WIDGET, {
      data,
      options,
      onUploadProgress: this.onUploadProgress,
    })
      .then((response) => {
        toastr.success("Success!", "File saved");
        const data = response.data;
        const widgetVals = _.cloneDeep(this.props.widgetVals);
        let widgetVal = widgetVals[this.props.componentId];
        if (!widgetVal) {
          widgetVals[this.props.componentId] = _.map(data, (el) => el.path);
          widgetVal = widgetVals[this.props.componentId];
        } else {
          widgetVal = widgetVals[this.props.componentId].concat(_.map(data, (el) => el.path));
        }
        if (data && data["duration"]) {
          widgetVal["duration"] = data["duration"];
        }

        this.update(widgetVal);
        this.getUploadedFiles();
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  deleteFile = (e, file) => {
    e.preventDefault();
    if (!window.confirm(`Are you sure you want to delete file ${file}`)) {
      return;
    }
    const taskSlug = this.props.location?.pathname?.split("/")[2];
    const data = {
      questionId: this.props.questionId,
      file: _.last(file.split("/")),
      slug: taskSlug
    };
    ajax.delete(config.FILE_UPLOAD_WIDGET_UPLOADED, { data })
      .then((response) => {
        const widgetVals = _.cloneDeep(this.props.widgetVals);
        let widgetVal = widgetVals[this.props.componentId];
        widgetVal = _.filter(widgetVal, (file) => {
          return response.data.file_deleted !== _.last(file.split("/"));
        });
        this.update(widgetVal);
        this.getUploadedFiles();
        toastr.success("Success!", "File deleted!");
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  renderFile = (file, message, idx) => {
    const MEDIA_URL = (window.BACKEND_URL || config.BACKEND_URL) + config.MEDIA_SUFFIX;
    const button = (
      <Button
        size={"mini"}
        color={"red"}
        icon={"trash"}
        onClick={(e) => this.deleteFile(e, file)}
      />
    );
    if (this.props.widgetConfig.showPlayer) {
      let editableTab = ["new"].indexOf(window.location.href.split("/").pop()) > -1;

      return (
        <div key={idx}>
          {this.state.progress > 0 && this.state.progress < 100 ?
            <Progress percent={this.state.progress} progress success />
            :
            <div>
              <audio controls>
                <source src={MEDIA_URL + file} type="audio/wav" />
              </audio>
              <br />
              {editableTab && message}
              {editableTab && button}
            </div>
          }
        </div>
      );
    } else {
      return (
        <li key={idx}>{_.last(file.split("/"))} {message} {button}</li>
      );
    }
  };

  renderFiles = (widgetVal) => {
    // all files in answer
    let files = _.map(widgetVal, (file, idx) => {
      let message = null;
      return this.renderFile(file, message, idx);
    });
    //add files that are uploaded but not in answer.
    const uploaded = _.reduce(this.state.uploadedFiles, (acc, elem) => {
      const found = _.filter(widgetVal, (file) => {
        return elem.path === file;
      });
      if (!found.length) {
        acc.push(this.renderFile(elem.path, "This file was uploaded but is not submitted!", acc.length + files.length));
      }
      return acc;
    }, []);
    files = _.concat(files, uploaded);
    return files;
  };

  handleClickInEditor = () => {
    if (this.props.editorMode) {
      toastr.warning(WARNING_MESSAGE);
    }
  };

  render() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    
    const isFileMissing = (this.state.uploadedFiles && !this.state.uploadedFiles.length) || !this.state.uploadedFiles

    return (
      <div className={"AudioAnswerWidget "}>
        {isFileMissing && this.props.isTabDone &&
        <div className="ui red message">This file has been deleted</div>
        }
        
        {this.state.uploadedFiles && !this.state.uploadedFiles.length && !this.props.isTabDone &&
          <Recorder onRecordingComplete={(blob) => this.handleAudioStop(blob)} />
        }

        <div className={"dropzone-container"} onClick={this.handleClickInEditor}>
          <Dropzone
            disabled={true}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <ul className="files">
                    {(widgetVal || this.state.uploadedFiles) && this.renderFiles(widgetVal)}
                  </ul>
                </div>
              </section>
            )}
          </Dropzone>

        </div>
      </div>
    );
  }
}

export default withRouter(AudioAnswerWidget);
