import React, {Component} from "react";
import {Modal, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./ModalConfirm.css";

class ModalConfirm extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    button: PropTypes.element.isRequired,
    hideCloseIcon: PropTypes.bool,
  };

  render() {
    return <Modal
      dimmer={true}
      open={true}
      size={this.props.size}
    >
      <Modal.Header>
        <Icon name={"external"}/>
        {this.props.title}
        <div
          className={"close-button"}
          onClick={this.props.closeModal}
        >
          <Icon name={"window close"}/>
        </div>
      </Modal.Header>
      <Modal.Content>
        {this.props.content}
      </Modal.Content>
      <Modal.Actions>
        {this.props.button}
      </Modal.Actions>
    </Modal>;
  }
}

export default ModalConfirm;
