import _ from "lodash";
import React, {Component} from "react";
import {toastr} from "react-redux-toastr";
import moment from "moment-timezone";
import {Button, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import qs from "query-string";

import urls from "../../config/frontend_urls";
import config from "../../config/config";
import {setDragging} from "../../components/simple/DragAndDropSelector/utils";
import ajax from "../../helpers/ajax";
import {convertDateToISO8601, zeroWidthTrim} from "../../helpers/utils";
import AddManyUsersModal from "../AddManyUsersModal/AddManyUsersModal";
import AddManyUsersAdditionalInfo from "../AddManyUsersModal/AddManyUsersAdditionalInfo";
import Tabs from "../../components/Tabs";
import BottomBar from "../../components/BottomBar";
import AddManyUsersForm from "./AddManyUsersForm";
import { getPossibleDropdownValues } from "../../helpers/utils";
import "./AddManyUsers.css";

const USERS_FORM = "usersForm";
const EVALUATORS_FORM = "evaluatorsForm";

const defaultFormData = {
  selectedTasks: [],
  selectedUsers: [],
  questionLimit: "0",
  datetime: "",
  newUsers: "",
  company: "",
  division: ""
};

class AddManyUsers extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      formPath: USERS_FORM,
      displayModal: false,
      displayAdditionalInfoModal: false,
      //selection options
      users: [],
      tasks: [],
      evaluationTasks: [],
      companies: [],
      divisions: [],
      userTypes: [],
      existingInactiveUsers: [],
      //data
      [USERS_FORM]: defaultFormData,
      [EVALUATORS_FORM]: defaultFormData,
      mainSlug: qs.parse(this.props.location.search).title
    };

    this.setDragging = setDragging.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getTask() {
    const urlTask = qs.parse(this.props.location.search);
    const selectedTask = this.state.tasks.find((item) => item.title === (urlTask.title || this.state.mainSlug));
    if (selectedTask) {
      this.onChangeForm({selectedTasks: [selectedTask.id]}, selectedTask.enable_evaluations);
    }
  }

  getData = () => {
    ajax.get(config.USERS)
      .then((response) => this.setState({users: response.data}));
    ajax.get(config.TASKS)
      .then((response) => this.setState({
        tasks: response.data,
        evaluationTasks: response.data.filter((task) => task.enable_evaluations === true)
      }, this.getTask));
    ajax.get(config.COMPANY_OPTIONS, {params: {"active_companies": true}})
      .then((response) => {
        const companies = getPossibleDropdownValues("id", "name", response.data, true);
        this.setState({companies: companies});
      });
    ajax.get(config.ALL_DIVISIONS)
      .then((response) => {
        const divisions = getPossibleDropdownValues("id", "name", response.data);
        this.setState({divisions: divisions});
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        const userTypes = getPossibleDropdownValues("value", "text", response.data);
        this.setState({userTypes});
      });
  };

  checkAndSaveUsers = () => {
    if (!this.state.inactiveUsers.length && !this.state.existingUsers.length) {
      this.addUsers();
    } else {
      this.setState({displayAdditionalInfoModal: true});
    }
  }

  checkUsers = () => {
    const {formPath} = this.state;
    const form = this.state[formPath];

    const data = {
      new_users: form.newUsers,
      users: form.selectedUsers,
      tasks: form.selectedTasks,
      is_add_users: formPath === USERS_FORM
    };

    ajax.get([config.ADD_MANY_USERS], {params: data})
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            inactiveUsers: response.data.inactive_users,
            existingUsers: response.data.existing_users,
          }, this.checkAndSaveUsers);
        }
      }).catch(() => {
        this.setState({
          displayAdditionalInfoModal: false
        });
      });
  }

  addUsers = () => {
    const {formPath} = this.state;
    const form = this.state[formPath];
    const deadline = form.datetime ? convertDateToISO8601(form.datetime) : null;
    const data = {
      tasks: form.selectedTasks,
      company: form.company,
      division: form.division,
      users: form.selectedUsers,
      type: form.type,
      new_users: form.newUsers,
      question_limit: form.questionLimit,
      deadline_0: deadline ? moment(deadline).utc().format(config.FORMAT_Ymd) : "",
      deadline_1: deadline ? moment(deadline).utc().format(config.FORMAT_Hm) : "",
      is_add_users: formPath === USERS_FORM
    };
    ajax.post([config.ADD_MANY_USERS], {data})
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Success", "Users saved!");
          this.setState({
            userDetails: response.data,
            displayModal: true,
            displayAdditionalInfoModal: false
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data["__all__"]) {
            error.response.data["__all__"].map((msg) => toastr.error("Error! " + msg));
          }
        }
        this.setState({
          displayAdditionalInfoModal: false
        });
      });
  };

  closeModal = () => {
    const {formPath} = this.state;
    this.setState({displayModal: false, [formPath]: defaultFormData}, this.getTask);
  }

  updateNewUsers = (username) => {
    const formPath = this.state.formPath;
    const newUsers = this.state[formPath].newUsers;

    let elements = newUsers.split("\n");
    elements = elements.filter((item) => item !== username);
    elements = elements.join("\n");

    const obj = {"newUsers": elements};
    this.setState({[formPath]: {...this.state[formPath], ...obj}});
  }

  onReject = (obj) => {
    let inactiveUsers = this.state.inactiveUsers;

    if (inactiveUsers.includes(obj)) {
      inactiveUsers = inactiveUsers.filter((item) => item.username !== obj.username);
      this.setState({inactiveUsers}, this.updateNewUsers(obj.username));
    }
  }

  renderModal = () =>
    <AddManyUsersModal
      closeModal={this.closeModal}
      userDetails={this.state.userDetails}
    />;

  renderConfirmModal = () =>
    <AddManyUsersAdditionalInfo
      closeModal={() => this.setState({displayAdditionalInfoModal: false})}
      inactiveUsers={this.state.inactiveUsers}
      existingUsers={this.state.existingUsers}
      addUsers={this.addUsers}
      onReject={this.onReject}
    />;

  onChangeTab = (tab={key: USERS_FORM}) => {
    this.setState({formPath: tab.key});
  }

  onChangeForm = (obj, both) => {
    if (both) { //passing both allows to change users and evaluators forms at once
      return this.setState({
        [USERS_FORM]: {...this.state[USERS_FORM], ...obj},
        [EVALUATORS_FORM]: {...this.state[EVALUATORS_FORM], ...obj}
      });
    }
    const formPath = this.state.formPath;
    return this.setState({[formPath]: {...this.state[formPath], ...obj}});
  }

  convertSlug = () => {
    let slug = zeroWidthTrim(this.state.mainSlug);
    slug = _.replace(slug, /\//g, "").toLowerCase();
    return slug;
  }

  render() {
    const panes = [{
      key: USERS_FORM,
      label: {name: "New Users"},
      components: null,
      tabUrl: "/add-many-users/users"
    }, {
      key: EVALUATORS_FORM,
      label: {name: "New Evaluators"},
      components: null,
      tabUrl: "/add-many-users/evaluators"
    }];

    return <div className="add-many-user">
      {this.state.mainSlug && <div className="buttonsMainSlug">
        <div>
          <Link className="ui icon button"
            to={urls.ADMIN_TASK_DETAIL.replace(":taskSlug", this.convertSlug)}>
            <Icon name={"wrench"}/>
          </Link>
        </div>
      </div>}
      {this.state.displayModal && this.renderModal()}
      {this.state.displayAdditionalInfoModal && this.renderConfirmModal()}
      <Tabs
        className="tabs"
        panes={panes}
        onChangeTab={this.onChangeTab}
      />
      <AddManyUsersForm
        values={this.state[this.state.formPath]}
        tasks={this.state.formPath === USERS_FORM
          ? this.state.tasks
          : this.state.evaluationTasks
        }
        users={this.state.users}
        companies={this.state.companies}
        divisions={this.state.divisions}
        userTypes={this.state.userTypes}
        onChangeForm={this.onChangeForm}
      />
      <BottomBar
        panelRight={
          <Button className="datahive-button" color="green" onClick={this.checkUsers}>
            Save {this.state.formPath === USERS_FORM ? "Users" : "Evaluators"}
          </Button>}
      />
    </div>;
  }
}

export default AddManyUsers;
