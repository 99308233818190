/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ajax from "../../../helpers/ajax";
import { yesNoOptions } from "../../../helpers/filters";
import config from "../../../config/config";
import LoaderBlocker from "../../../components/LoaderBlocker";
import QuestionsSet from "../QuestionsSet";
import BaseTaskQuestions from "./BaseTaskQuestions";
import { FormLevenDropdown as FormDropdown } from "../../../components/simple/Dropdown/LevenDropdown";
import AdvancedFiltersSet from "../../../components/AdvancedFilters/AdvancedFiltersSet";

import "./TaskDone.css";
import LoaderSpinner from "../../../components/LoaderSpinner";

const reviewOptions = [
  {
    key: "All",
    text: "All",
    value: null
  },
  {
    key: "Commentary",
    text: "Commentary",
    value: "commentary"
  },
  {
    key: "Discussion (resolved)",
    text: "Discussion (resolved)",
    value: "discussion_resolved"
  },
  {
    key: "Discussion (not resolved)",
    text: "Discussion (not resolved)",
    value: "discussion_not_resolved"
  },
  {
    key: "No reviews",
    text: "No reviews",
    value: "no_reviews"
  },
];

export class TaskDone extends BaseTaskQuestions {
  static propTypes = {
    ...BaseTaskQuestions.propTypes,
    // if answers may be reviewed
    reviewable: PropTypes.bool.isRequired,
    canLinguistStartDiscussion: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      filters: {
        page: 1,
        page_size: props.questionsAtPage,
        order_by: "-date",
        user: [],
        company: [],
        review: [],
        advanced_answer: [],
        advanced_answer_not_empty: [],
        advanced_answer_empty: [],
        advanced_answer_exact_match: [],
        advanced_question: [],
        advanced_question_not_empty: [],
        advanced_question_empty: [],
        advanced_question_exact_match: []
      },
      possibleFilterValues: {
        user: [],
        company: [],
        review: []
      },
      processedSubmit: null
    };

    // set values for base component
    this.getQuestionsUrl = config.TASK_ANSWERED_QUESTIONS;
    this.orderBy = true;

    this.advancedPopupRef = React.createRef();
  }

  /**
   * Get options for filters.
   */
  getFilterOptions = () => {
    const params = { ...this.state.filters };

    ajax.get([config.TASK_FILTER_OPTIONS, this.props.slug], { params })
      .then((response) => {
        const userOptions = this.createOptions(response.data.user, "username");
        const evalAuthorOptions = this.createOptions(response.data.evaluation_authors, "username");
        const companyOptions = this.createOptions(response.data.company, "name");
        const userTypeOptions = this.createOptions(response.data.user_type, "name");
        const reviewAuthorOptions = this.createOptions(response.data.review_authors, "username");
        const fixAuthorOptions = this.createOptions(response.data.fix_authors, "username");
        const possibleFilterValues = {
          ...this.state.possibleFilterValues,
          user: userOptions,
          evaluation_authors: evalAuthorOptions,
          company: companyOptions,
          user_type: userTypeOptions,
          review_authors: reviewAuthorOptions,
          fix_authors: fixAuthorOptions,
        };
        this.setState({ possibleFilterValues });
      });
  };

  onSubmit = (questionIdx, widgetVals, correctionId, cb) => {
    // Show spinner in submit button.
    this.setState({ processedSubmit: questionIdx });
    const data = widgetVals;
    const questionId = this.state.questions[questionIdx].id;
    data["time_taken"] = moment() - this.state.timer;

    ajax.post(
      [config.TASK_SAVE_ANSWER, this.props.slug, questionId],
      { data, params: { correction: correctionId ? correctionId : null } }
    )
      .then((response) => {
        toastr.success("Changes saved");
        if (!correctionId) {
          const newAnswerIdx = response.data.answer_id;
          const questions = this.addAnswer(questionIdx, newAnswerIdx, widgetVals);
          const timer = moment();

          this.setState({
            timer,
            questions,
            processedSubmit: null
          }, cb);
        } else {
          this.onPageChange(this.state.filters.page);
          this.setState({ processedSubmit: null });
        }
      })
      .catch((error) => {
        this.setState({ processedSubmit: null });
        toastr.error("Error", error.response.data.detail);
      });
  };

  onCompanyFilterChange = (value) => {
    this.setFilters("company", value, this.getFilterOptions);
  };

  onReviewAccept = (questionIdx, answerIdx, comment = null, successCallback = () => null) => {
    const data = comment ? { content: comment } : {};
    const answer = this.state.questions[questionIdx].answers[answerIdx];

    ajax.post([config.TASK_ACCEPT_REVIEW, answer.id], { data })
      .then((response) => {
        toastr.success(`Discussion ${answer.review.is_accepted ? "unresolved" : "resolved"}!`);
        const questions = this.state.questions;
        questions[questionIdx].answers[answerIdx].review = response.data;
        this.setState({ questions });
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  /* eslint-disable-next-line react/display-name */
  renderFilters = () => {
    const possibleFilterValues = this.state.possibleFilterValues;
    const filters = this.state.filters;

    const userFilter = this.props.isStaff || this.props.isModerator || this.props.isCompanyCoordinator ?
      <FormDropdown
        label={"User"}
        placeholder="All"
        fluid search selection multiple
        options={possibleFilterValues.user}
        value={filters.user || []}
        onChange={(e, data) => this.setFilters("user", data.value)}
      /> : null;

    const companyFilter = this.props.isStaff || this.props.isModerator || this.props.isCompanyCoordinator ?
      <FormDropdown
        label={"Company"}
        placeholder="All"
        fluid search selection multiple
        options={possibleFilterValues.company}
        value={filters.company || []}
        onChange={(e, data) => this.onCompanyFilterChange(data.value)}
      /> : null;

    // If backend returns any user type then display it. There is always one 'All' option.
    const userTypeFilter = possibleFilterValues.user_type && possibleFilterValues.user_type.length > 1 ?
      <FormDropdown
        label={"User type"}
        placeholder="All"
        fluid search selection
        options={possibleFilterValues.user_type}
        value={filters.user_type}
        onChange={(e, data) => this.setFilters("user_type", data.value)}
      /> : null;

    let reviewAuthorFilter = null;
    if (this.props.isStaff || this.props.isModerator || this.props.isCompanyCoordinator) {
      reviewAuthorFilter =
        <Form.Field>
          <label>Review Author</label>
          <Form.Dropdown
            multiple
            placeholder="None"
            fluid selection
            options={possibleFilterValues.review_authors}
            value={filters.review_author || []}
            onChange={(e, data) => this.setFilters("review_author", data.value)}
          />
        </Form.Field>;
    }
    let fixAuthorFilter = null;
    if (this.props.isStaff) {
      fixAuthorFilter =
        <Form.Field>
          <label>Fix Author</label>
          <Form.Dropdown
            multiple
            placeholder="All"
            fluid selection
            options={possibleFilterValues.fix_authors}
            value={filters.fix_author || []}
            onChange={(e, data) => this.setFilters("fix_author", data.value)}
          />
        </Form.Field>;
    }
    const advancedFilter =
      <AdvancedFiltersSet
        advancedPopupRef={this.advancedPopupRef}
        filters={filters}
        structure={this.props.config.structure}
        answer
        question
        compare={this.props.isStaff || this.props.isModerator || this.props.isCompanyCoordinator}
        possibleFilterValuesUsers={possibleFilterValues.user}
        updateFilters={this.updateFilters}
      />;

    return (
      <div className="ui filters-menu menu" ref={this.advancedPopupRef}>
        <Form className="filters-form" size="mini">
          {reviewAuthorFilter}
          <Form.Field>
            <label>Skipped</label>
            <Form.Dropdown
              placeholder="All"
              fluid search selection
              options={yesNoOptions}
              value={filters.skipped}
              onChange={(e, data) => this.setFilters("skipped", data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Answer count"
              value={filters.answer_cnt}
              onChange={(e, data) => {
                this.setFilters("answer_cnt", data.value);
              }}
              min={0}
              type="number" />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Question ID"
              value={filters.id}
              onChange={(e, data) => {
                this.setFilters("id", data.value);
              }}
              type="number" />
          </Form.Field>
          {advancedFilter}
        </Form>
        <Form className={"filters-form"} size="mini">
          <Form.Field>
            <label>Review</label>
            <Form.Dropdown
              multiple
              placeholder="All"
              fluid selection
              options={reviewOptions}
              value={filters.review || []}
              onChange={(e, data) => this.setFilters("review", data.value)}
            />
          </Form.Field>
          {userFilter}
          {userTypeFilter}
          {companyFilter}
          <Form.Field>
            <Form.Input
              label="Question text"
              value={this.state.filters.question_text}
              onChange={(e, data) => {
                this.setFilters("question_text", data.value);
              }}
              type="text" />
          </Form.Field>
          {fixAuthorFilter}
          <div className={"filters-button"}>
            <Form.Button
              type="submit"
              className="datahive-button"
              color="yellow"
              style={{ marginTop: "15px" }}
              onClick={() => this.onFiltersSubmit()}>Submit</Form.Button>
          </div>
          <div className={"filters-button"} style={{ textAlign: "center" }}>
            <Form.Button
              className="datahive-button"
              color="red"
              style={{ marginTop: "15px" }}
              type="submit"
              onClick={this.resetFilters}
              content={"Clean"}
            />
          </div>
        </Form>
      </div>
    );
  };

  render() {
    const questions = this.state.questions;

    // display spinner if questions aren't loaded yet
    if (this.state.loading) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <div style={{ height: "500px" }}>
            <LoaderBlocker show={true} />
          </div>
        </div>
      );
    }

    // display no more answers
    if (!questions || _.isEmpty(questions)) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <div className="Done">
            <h2>There are no answers yet.</h2>
          </div>
        </div>
      );
    }

    if (this.state.displaySpinner) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <LoaderSpinner />
          {this.renderFooter()}
        </div>
      );
    }

    return (
      <div className="tab-panel TaskDone">
        {this.renderOptionsPanel()}
        <QuestionsSet
          questions={questions}
          config={this.props.config}
          onSubmit={this.onSubmit}
          isModerator={this.props.isModerator}
          reviewable={this.props.reviewable}
          onReviewAccept={this.onReviewAccept}
          canLinguistStartDiscussion={this.props.canLinguistStartDiscussion}
          processedSubmit={this.state.processedSubmit}
          isTabNew={false}
          isTabDone={true}
        />
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isStaff: state.auth.is_staff,
    username: state.auth.username,
  };
};

export default connect(mapStateToProps)(withRouter(TaskDone));
