import _ from "lodash";

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Header, Table} from "semantic-ui-react";
import PropTypes from "prop-types";

import config from "../../config/config";
import ajax from "../../helpers/ajax";
import Stars from "../../components/simple/Stars/Stars";
import LoaderSpinner from "../../components/LoaderSpinner";
import SortableTable from "../../components/simple/SortableTable/SortableTable";
import urls from "../../config/frontend_urls";
import "./UserRatings.css";

class UserRatings extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      reviewsList: null
    };
  }

  componentDidMount() {
    this.getReviewsList();
  }

  getReviewsList = () => {
    ajax.get([config.LINGUIST_REVIEWS, this.props.username])
      .then((response) => {
        this.setState({reviewsList: _.sortBy(response.data, "task_slug")});
      });
  };

  getAverageRating = (reviews) => {
    const sum = _.reduce(reviews, (s, o) => o.rating ? s + parseFloat(o.rating) : s, 0);
    const ratingCount = _.filter(reviews, (o) => !!o.rating).length;
    if (ratingCount) {
      return sum / ratingCount;
    }
    return null;
  };

  render() {
    const reviews = this.state.reviewsList;
    if (!reviews) {
      return <LoaderSpinner/>;
    }

    const rows = _.map(reviews, (review) => {
      return (
        <Table.Row key={review.id}>
          <Table.Cell className={"title"}>
            {review.title}
          </Table.Cell>
          <Table.Cell className={"title"}>
            <Link to={urls.RATING.replace(":ratingId", review.id)}>
              {review.task_slug || "===="}
            </Link>
          </Table.Cell>
          <Table.Cell>
            {review.author_username}
          </Table.Cell>
          <Table.Cell>
            {review.creation_date}
          </Table.Cell>
          <Table.Cell>
            <Stars count={parseFloat(review.rating)} max={6} showEmpty/>
          </Table.Cell>
        </Table.Row>
      );
    });

    let columns = [
      {name: "title", title: "Title", headerProps: {width: 3}},
      {name: "task", title: "Task", headerProps: {width: 3}},
      {name: "author", title: "Author", headerProps: {width: 2}},
      {name: "date", title: "Date", headerProps: {width: 2}},
      {name: "rating", title: "Rating", headerProps: {width: 2}}
    ];

    return (
      <div className="UserRatings">
        <Header>Average Rating:</Header>
        <Stars color="black" count={this.getAverageRating(reviews)} size={"big"} max={6} showEmpty/>
        <Link to={urls.NEW_RATING_NO_SLUG.replace(":username", this.props.username)}>
          <Button style={{marginBottom: "8px"}} width={3} basic color="green" className="datahive-button" content="Add Rating"
            floated={"right"}/>
        </Link>
        <SortableTable
          columns={columns}
          className={"RatingsTable"}
        >
          <Table.Body>
            {rows}
          </Table.Body>
        </SortableTable>
      </div>
    );
  }
}

export default UserRatings;
