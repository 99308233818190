import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Container, Icon} from "semantic-ui-react";
import Widget from "../Widget";
import "./SubmitButtonWidget.css";

class SubmitButtonWidget extends Widget {
  static propTypes = {
    // function triggered when submit button is clicked
    onSubmit: PropTypes.func,
    // function triggered when skip button is clicked
    onSkip: PropTypes.func,
    // if should render spinner
    showSpinner: PropTypes.bool,
  };

  static defaultProps = {
    onSubmit: () => {},
    onSkip: () => {}
  };

  static getDefaultConfig = () => {
    return {};
  };

  componentDidMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  componentDidUpdate() {
    this.addEventListeners();
  }

  addEventListeners = () => {
    const submitButtonHtml = this.submitButton.ref.current;
    // cheater's detection - custom event listener, needs to work directly on html element
    // otherwise manual change by user in devtools would not be detected
    submitButtonHtml.addEventListener("click", this.handleClick);
    if (this.correctButton) {
      this.correctButton.ref.current.addEventListener("click", this.handleClick);
    }
  }

  removeEventListeners = () => {
    if (this.submitButtonHtml) {
      this.submitButtonHtml.ref.current.removeEventListener("click", this.handleClick);
    }
    if (this.correctButton) {
      this.correctButton.ref.current.removeEventListener("click", this.handleClick);
    }
  }

  resolveRule = (rule) => {
    if (!rule.operator) {
      return true;
    }

    const empty = this.props.isEmpty ? this.props.isEmpty(rule.field) : true;
    const valid = this.props.isValid ? this.props.isValid(rule.field) : false;

    if ((rule.operator === "is_empty" && !empty) || (rule.operator === "is_not_empty" && empty) ||
      (rule.operator === "is_valid" && !valid) || (rule.operator === "is_not_valid" && valid)) {
      return false;
    }
    if (rule.operator === "is_empty" || rule.operator === "is_not_empty" ||
      rule.operator === "is_valid" || rule.operator === "is_not_valid") {
      return true;
    }

    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[rule.field];
    if (!widgetVal) {
      return false;
    }

    let idx = -1;
    switch (rule.operator) {
    case "select_equals":
      idx = _.findIndex(widgetVal, (o) => {
        return o === rule.value;
      });
      return idx > -1;
    case "select_not_equals":
      idx = _.findIndex(widgetVal, (o) => {
        return o === rule.value;
      });
      return idx === -1;
    default:
      return true;
    }
  };

  resolveGroup = (group) => {
    const condition = group.condition;
    const not = !!group.not;
    const values = [];

    _.forEach(group.rules, (rule, idx) => {
      if ("condition" in rule) {
        values[idx] = this.resolveGroup(rule);
      } else {
        values[idx] = this.resolveRule(rule);
      }
    });

    if (condition === "AND") {
      const result = values.every((element) => element);
      return !not ? result : !result; // is there a more elegant way to write it ? my brain is fried.
    } else if (condition === "OR") {
      const result = values.some((element) => element);
      return !not ? result : !result;
    } else {
      return false;
    }
  };

  checkAnnotationFullfill = (vals) => {
    if (vals.videoWidget && vals.videoWidget.annotations) {
      const emptyAnn = vals.videoWidget.annotations ? Object.values(vals.videoWidget.annotations).filter((x) => x.note && x.note.annotation_container?.length === 0 || x.note.annotation_container?.length === undefined) : [];
      let size = Object.keys(vals.videoWidget.annotations).length;
      if (size > 0) {
        return emptyAnn.length > 0;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  isEnable = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const config = this.props.widgetConfig;

    // for editor or empty query return true
    if (!config || !config.query) {
      return true;
    }
    if (config && config.query.checkAnnotationsFullfill) {
      return !this.checkAnnotationFullfill(widgetVals);
    }

    return this.resolveGroup(config.query);
  };

  isSkipped = () => {
    // This is done to handle the skippability of questions, because some of them use the 'skip' button on the submit button widget
    // while others have a normal radio button widget with id 'skip'.

    let skipped = !_.isEmpty(this.props.widgetVals.skip);
    if (_.get(this.props.widgetVals, "skip", false)) {
      skipped = _.get(this.props.widgetVals, "skip.0", false) === "skip" ||
        _.get(this.props.widgetVals, "skip", false) === true;
    }
    return skipped;
  };

  handleClick = (event) => {
    const submitButtonIsDisabled = this.submitButton.ref.current.hasAttribute("disabled");

    event.stopPropagation();
    // check if user tried to manually enable the button in React or html
    if (!this.isEnable() && !submitButtonIsDisabled) {
      // TODO send email to appropriate users on detecting a cheater
    } else {
      this.props.onSubmit(event.target.id === "correct");
    }
  };

  render() {
    this.getEditor();
    this.removeEventListeners();

    const config = this.props.widgetConfig;
    const disabled = !this.isEnable();
    const skipped = this.isSkipped();
    const spinner = this.props.showSpinner || false;

    const canSeeCorrectButton = (this.props.auth.is_staff || this.props.isModerator) &&
      this.props.answerAuthor && this.props.auth.username !== this.props.answerAuthor;
    const canSeeSkipButton = config.skippable && (this.props.onSkip || this.popupContent);

    const correctButton = canSeeCorrectButton && <Button
      id="correct"
      color="teal"
      ref={(e) => this.correctButton = e}
      disabled={disabled}
      content={"Correct"}
    />;
    const submitButton = <Button
      id="submit"
      color="teal"
      ref={(e) => this.submitButton = e}
      disabled={disabled}
      content={"Submit"}
    />;
    const spinnerButton = <Button
      id="submit"
      color="teal"
      ref={(e) => this.submitButton = e}
      disabled={true}
      content={<Icon loading name="spinner"/>}
    />;
    const skippedButton = skipped && <Button
      color="grey"
      content={"Skipped"}
      disabled={true}
    />;
    const skipButton = canSeeSkipButton && <Button
      color="yellow"
      onClick={this.props.onSkip}
      content={"Skip"}
    />;
    const correctAndSubmitButton = <>
      {correctButton}
      {submitButton}
    </>;
    const spinnerOrCorrectSubmitButtons = spinner ? spinnerButton : correctAndSubmitButton;

    return (
      <Container className="SubmitButtonWidget Widget">
        {skippedButton}
        {skipButton}
        {spinnerOrCorrectSubmitButtons}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(SubmitButtonWidget);
