import _ from "lodash";
import moment from "moment";

import React, {Component} from "react";
import {Button, Checkbox, Icon} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import Tabs from "../../../components/Tabs";

import "./TaskTutorial.css";

class TaskTutorial extends Component {
  static propTypes = {
    tutorials: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    slug: PropTypes.string.isRequired,
    onConfirmed: PropTypes.func.isRequired,
    confirmed: PropTypes.bool,
    tutorialDisplayTimeAck: PropTypes.func.isRequired,
    forEvaluators: PropTypes.bool,
    allConfirmed: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      // Keep info about checked button if `diff` is available
      "content": {},
    };
  }

  componentDidMount() {
    this.props.tutorialDisplayTimeAck();
  }

  confirmTutorial = (tutorial) => {
    const data = {id: tutorial.id, for_evaluators: this.props.forEvaluators};
    ajax.post([config.TASK_TUTORIAL_ACCEPT, this.props.slug], {data})
      .then(() => {
        this.props.onConfirmed(tutorial, this.props.forEvaluators);
      });
  };

  onShowDiff = (data, id) => {
    const content = this.state.content;
    if (data.checked) {
      const changedTutorial = this.props.tutorials.filter((tutorial) => tutorial.id === id);
      changedTutorial["changed"] = false;
    }
    content[id] = data.checked;
    this.setState({content});
  }

  render() {
    const panes =
      _.map(this.props.tutorials, (tutorial) => {
        const modified = moment(tutorial.modified).format("LLLL");
        const btnval = !this.state.content[tutorial.id] ? "content" : "diff";
        const confirmedTutorials = this.props.allConfirmed;
        let ifConfirmed = [];
        if (confirmedTutorials) {
          ifConfirmed = confirmedTutorials.filter((item) => item.wiki === tutorial.id);
        }

        const button = ifConfirmed.length ?
          <Button className="datahive-button" color="green" disabled>Tutorial accepted!</Button> :
          <Button className="datahive-button" color="yellow" onClick={() => this.confirmTutorial(tutorial)}>Accept tutorial</Button>;

        const difference = tutorial.diff
          ? <div className="ui label modified difference">
            <Checkbox
              id={`diff-${tutorial.id}`}
              className="difference"
              label="Show difference"
              onChange={(e, data) => this.onShowDiff(data, tutorial.id)}
              value={tutorial.id}
              checked={this.state.content[tutorial.id] || false}
            />
          </div>
          : "";

        const _render = (
          <React.Fragment>
            <div className="ui label modified">
              Modified: {modified}
            </div>
            {difference}
            <div
              className="tab-panel sub-tab"
              dangerouslySetInnerHTML={{__html: tutorial[btnval]}}/>
            {tutorial.is_required ? button : ""}
          </React.Fragment>
        );
        const label = (
          <React.Fragment>
            {tutorial.title || "TUTORIAL"}
            {tutorial.changed && <Icon style={{paddingLeft: "1rem"}} name="exclamation circle"/>}
          </React.Fragment>
        );

        return {
          key: `${tutorial.id}tutorial`,
          label: {
            icon: "",
            name: label,
          },
          components: _render,
        };
      });

    return (
      <div className="TaskTutorial">
        <Tabs panes={panes} isWithoutUrl={true}/>
      </div>
    );
  }
}

export default withRouter(TaskTutorial);
