import _ from "lodash";

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Icon, Table} from "semantic-ui-react";
import PropTypes from "prop-types";

export default class TasksStatisticsTable extends Component {
  static propTypes = {
    taskStatisticsList: PropTypes.array,
    selectedTasks: PropTypes.array,
    toggleCheckbox: PropTypes.func.isRequired
  };

  renderTaskRows() {
    let rows = {};
    if (!!this.props.taskStatisticsList) {
      rows = _.map(this.props.taskStatisticsList, (task, idx) => {
        return (
          <Table.Row key={"Task" + idx}>
            <Table.Cell collapsing>
              <Checkbox
                checked={_.includes(this.props.selectedTasks, task.task_id.toString())}
                value={task.task_id.toString()}
                onChange={this.props.toggleCheckbox}
              />
            </Table.Cell>
            <Table.Cell>
              <Link to={"/basic-task-statistics/" + _.replace(task.task, /\//g, "").toLowerCase()}>
                <Button basic fluid size="mini" color={"black"}>
                  <Icon name="tasks"/>{task.task}
                </Button>
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Icon name={task.is_active ? "check circle" : "times"} />
            </Table.Cell>
            <Table.Cell>{task.fully_answered_questions} / {task.total_questions}</Table.Cell>
            <Table.Cell>{task.total_answers} / {task.target_answers}</Table.Cell>
            <Table.Cell>{task.max_answers_per_question}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }


  render() {
    const rows = this.renderTaskRows();
    return (
      <Table celled structured textAlign="center" className="SortableTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell/>
            <Table.HeaderCell>Task</Table.HeaderCell>
            <Table.HeaderCell>Is active</Table.HeaderCell>
            <Table.HeaderCell>Questions (complete/all)</Table.HeaderCell>
            <Table.HeaderCell>Answers (done/target)</Table.HeaderCell>
            <Table.HeaderCell>Max answers per question</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <>{rows}</>
        </Table.Body>
      </Table>
    );
  }
}
