import * as actionTypes from "./types/types_messages";

export function messagesNew(messages) {
  return {
    type: actionTypes.MESSAGES_NEW,
    messages
  };
}

export function messagesClear() {
  return {
    type: actionTypes.MESSAGES_CLEAR,
  };
}
