import React from "react";
import {Checkbox, Grid, Header, Input} from "semantic-ui-react";
import {WidgetEditor} from "../WidgetEditor";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";
import {getWidget} from "../widgetUtils";

class MediaWaveformWidgetEditor extends WidgetEditor {
  static header = "Media Waveform";

  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  render() {

    const widgetConfig = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    return (
      <Grid className={"MediaWaveformWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} divided>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Enable videos</Header>
            <Checkbox
              className="row"
              toggle
              disabled={widgetConfig.enableCut}
              checked={widgetConfig.isVideo}
              onChange={(e, data) => this.updateConfig("isVideo", data.checked)}/>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Enable cut/trim</Header>
            <Checkbox
              className="row"
              toggle
              disabled={widgetConfig.isVideo}
              checked={widgetConfig.enableCut}
              onChange={(e, data) => this.updateConfig("enableCut", data.checked)}/>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header as="h4">Annotation count limit</Header>
            <Input
              type="number"
              size="mini"
              min="0"
              value={widgetConfig.annotationCntLimit || ""}
              onChange={(e, data) => this.updateConfig("annotationCntLimit", parseInt(data.value, 10))}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} divided>
          <Grid.Column textAlign="center">
            <Header as="h4">Block annotation intersection</Header>
            <Checkbox
              className="row"
              toggle
              checked={widgetConfig.disableAnnotationIntersection}
              onChange={(e, data) => this.updateConfig("disableAnnotationIntersection", data.checked)}/>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header as="h4">Disable notes</Header>
            <Checkbox
              className="row"
              toggle
              checked={widgetConfig.disableNote}
              onChange={(e, data) => this.updateConfig("disableNote", data.checked)}/>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header as="h4">Disable drag</Header>
            <Checkbox
              className="row"
              toggle
              checked={widgetConfig.disableDrag}
              onChange={(e, data) => this.updateConfig("disableDrag", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h4">Minimum annotation length</Header>
            <Input
              type="number"
              size="mini"
              min="1"
              value={widgetConfig.minLength || ""}
              onChange={(e, data) => this.updateConfig("minLength", parseInt(data.value, 10))}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row/> {/* Used as spacer. */}
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default MediaWaveformWidgetEditor;
