import React, {Component} from "react";

import {Card, Statistic} from "semantic-ui-react";
import PropTypes from "prop-types";

export default class BasicStatistics extends Component {
  static propTypes = {
    total: PropTypes.number,
    finished: PropTypes.number,
    in_progress: PropTypes.number,
    not_started: PropTypes.number,
    complete: PropTypes.number,
    all: PropTypes.number,
    done: PropTypes.number,
    target: PropTypes.number
  };

  formatBigNumber(number) {
    return number > 1000000 ? (number / 1000000.0).toFixed(2) + " M" : number;
  }


  renderStatistic(value, label, color) {
    return (
      <Statistic color={color}>
        <Statistic.Value>{value}</Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>);
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    return (
      <Card.Content>
        <Statistic.Group widths="six" size="mini">
          {this.renderStatistic(this.props.total, "Total tasks", "yellow")}
          {this.renderStatistic(this.props.finished, "Finished", "green")}
          {this.renderStatistic(this.props.in_progress, "In progress", "teal")}
          {this.renderStatistic(this.props.not_started, "Not started", "teal")}
          {this.renderStatistic(
            this.formatBigNumber(this.props.complete) + " / " + this.formatBigNumber(this.props.all),
            <span>Questions<br/>(complete/all)</span>, "blue")}
          {this.renderStatistic(
            this.formatBigNumber(this.props.done) + " / " + this.formatBigNumber(this.props.target),
            <span>Answers<br/>(done/target)</span>, "orange")}
        </Statistic.Group>
      </Card.Content>
    );
  }
}
