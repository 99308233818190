import _ from "lodash";

import React from "react";
import {Button, Checkbox, Grid, Header, Input} from "semantic-ui-react";
import {AlignSelector, SemanticColorSelector} from "../components";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./RadioButtonWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class RadioButtonWidgetEditor extends WidgetEditor {
  static header = "Buttons";

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      newOptionName: "",
      newColor: "teal",
    };

    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.updateAlign = this.updateAlign.bind(this);
  }

  addOption() {
    this.updateWidgetConfig((widgetConfig) => {
      for (const opt in widgetConfig.options) {
        if (widgetConfig.options[opt].value === this.state.newOptionName) {
          return;
        }
      }
      widgetConfig.options.push({value: this.state.newOptionName, color: this.state.newColor});
      this.setState({newOptionName: ""});
    });
  }

  removeOption(option) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.options = _.filter(widgetConfig.options, (o) => {
        return o.value !== option;
      });
    });

    this.setState({}); // force re-render
  }

  updateAlign(e, data) {
    if (data !== "justify") {
      this.updateWidgetConfig((widgetConfig) => {
        widgetConfig.align = data;
        widgetConfig.justify = false;
      });
    } else {
      this.updateWidgetConfig((widgetConfig) => {
        widgetConfig.align = data;
        widgetConfig.justify = true;
      });
    }

    this.setState({}); // force re-render
  }

  // update option value - check if they are not the same
  onUpdateValue = (idx, value) => {
    this.updateWidgetConfig((widgetConfig) => {
      for (const opt in widgetConfig.options) {
        if (widgetConfig.options[opt].value === value) {
          return;
        }
      }
      widgetConfig.options[idx].value = value;

      this.setState({}); // force re-render
    });
  };

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const options = _.map(config.options, (option, idx) => {
      return (
        <Grid.Row
          className="options-row"
          key={"option" + idx.toString()}>
          <Grid.Column>
            <SemanticColorSelector
              size="small"
              value={option.color}
              onChange={(e, data) => this.updateConfig(`options[${idx}].color`, data)}/>
          </Grid.Column>
          <Grid.Column width={10}>
            <Input
              size="mini"
              fluid
              value={option.value}
              onChange={(e, data) => this.onUpdateValue(idx, data.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Button circular icon="minus" size="mini" color="red"
              onClick={() => this.removeOption(option.value)}/>
          </Grid.Column>
        </Grid.Row>
      );
    });

    return (
      <Grid className={"RadioButtonWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header as="h4">Options</Header>
            <Grid>
              {options}
              <Grid.Row
                className="options-row"
                key="new-option">
                <Grid.Column/>
                <Grid.Column width={10}>
                  <Input
                    size="mini"
                    fluid
                    placeholder="New option"
                    value={this.state.newOptionName}
                    onChange={(e) => this.setState({newOptionName: e.target.value})}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button size="mini" circular icon="plus" color="green" onClick={this.addOption}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Align</Header>
            <AlignSelector
              justify
              className="row"
              size="mini"
              value={config.align}
              onChange={this.updateAlign}
            />
            <Header
              className="row"
              as="h4">Selection color</Header>
            <SemanticColorSelector
              className="row"
              size="small"
              value={config.selection_color}
              onChange={(e, data) => this.updateConfig("selection_color", data)}/>
            <Header
              className="row"
              as="h4">Grouped</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.grouped}
              onChange={(e, data) => this.updateConfig("grouped", data.checked)}/>
            <Header
              className="row"
              as="h4">Multiple</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.multiple}
              onChange={(e, data) => this.updateConfig("multiple", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default RadioButtonWidgetEditor;
