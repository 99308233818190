import React, {Component} from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Grid} from "semantic-ui-react";
import _ from "lodash";
import PropTypes from "prop-types";
import RowPriceTariff from "./RowPriceTariff";

import "../Tasks.css";

class ListPriceTariffs extends Component {
  static propTypes = {
    filterByUser: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    priceTariffs: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  listPriceTariffs = (tariffs) => {
    const {filterByUser} = this.props;
    return _.map(tariffs, (tariff) => {
      const tariffData = {
        ...tariff,
        currency: tariff.price_currency,
        native: tariff.native ? "native" : "non_native",
        setModal: this.props.setModal,
        filterByUser
      };

      return <RowPriceTariff {...tariffData} key={tariff.id}/>;
    });
  };

  render() {
    const {filterByUser} = this.props;
    return _.map(this.props.priceTariffs, (company, idx) =>
      <Grid className={"tariffs-list"} key={idx + "-table"}>
        <Grid.Row className={"title-row"} key={idx + "-title"}>
          <Grid.Column>{idx}</Grid.Column>
        </Grid.Row>
        <Grid.Row className={"sub-header"} textAlign={"center"} columns={8} key={idx + "-header"}>
          <Grid.Column width={3}>Date</Grid.Column>
          <Grid.Column width={3}>Language</Grid.Column>
          <Grid.Column width={2}>Native</Grid.Column>
          <Grid.Column width={2}>Task type</Grid.Column>
          <Grid.Column width={filterByUser ? 2 : 1}>Task subtype</Grid.Column>
          {!this.props.filterByUser ?
            <Grid.Column width={2}>User type</Grid.Column>
            : null}
          <Grid.Column width={filterByUser ? 3 : 2}>Price</Grid.Column>
          <Grid.Column width={1}/>
        </Grid.Row>
        {this.listPriceTariffs(company)}
      </Grid>
    );
  }
}

export default ListPriceTariffs;
