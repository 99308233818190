/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Form} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import config from "../../../config/config";
import {yesNoOptions} from "../../../helpers/filters";
import LoaderBlocker from "../../../components/LoaderBlocker";
import QuestionsSet from "../QuestionsSet";
import BaseTaskQuestions from "./BaseTaskQuestions";
import {FormLevenDropdown as FormDropdown} from "../../../components/simple/Dropdown/LevenDropdown";
import AdvancedFiltersSet from "../../../components/AdvancedFilters/AdvancedFiltersSet";

export class TaskQuestions extends BaseTaskQuestions {
  static propTypes = {
    ...BaseTaskQuestions.propTypes,
    editReview: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      filters: {
        page: 1,
        page_size: props.questionsAtPage,
        advanced_question: [],
        advanced_question_not_empty: [],
        advanced_question_empty: [],
        advanced_question_exact_match: []
      },
    };

    // set values for base component
    this.getQuestionsUrl = config.TASK_QUESTIONS;
    this.orderBy = false;
    this.advancedPopupRef = React.createRef();
  }

  /**
   * Get options for filters.
   */
  getFilterOptions = () => {
    // Intentionally left empty.
  };

  /* eslint-disable-next-line react/display-name */
  renderFilters = () => {
    return (
      <div className="ui filters-menu menu" ref={this.advancedPopupRef}>
        <Form className="filters-form" size="mini">
          <FormDropdown
            label={"Answered"}
            placeholder="Status"
            fluid selection
            options={yesNoOptions}
            value={this.state.filters.answered}
            onChange={(e, data) => this.setFilters("answered", data.value)}
          />
          <Form.Field>
            <Form.Input
              label="Question ID"
              value={this.state.filters.id}
              onChange={(e, data) => {
                this.setFilters("id", data.value);
              }}
              type="number" />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Question text"
              value={this.state.filters.question_text}
              onChange={(e, data) => {
                this.setFilters("question_text", data.value);
              }}
              type="text" />
          </Form.Field>
          <AdvancedFiltersSet
            advancedPopupRef={this.advancedPopupRef}
            filters={this.state.filters}
            structure={this.props.config.structure}
            question
            updateFilters={this.updateFilters}
          />
          <div className={"filters-button"}>
            <Form.Button
              className="datahive-button"
              color="yellow"
              style={{marginTop: "15px"}}
              type="submit"
              onClick={() => this.onFiltersSubmit()}>Submit</Form.Button>
          </div>
          <div className={"filters-button"}>
            <Form.Button
              className="datahive-button"
              color="red"
              style={{marginTop: "15px"}}
              type="submit"
              onClick={this.resetFilters}
              content={"Clean"}
            />
          </div>
        </Form>
      </div>
    );
  };

  render() {
    const questions = this.state.questions;

    // display spinner if questions aren't loaded yet
    if (this.state.loading) {
      return (
        <div className="tab-panel task-quesitions">
          {this.renderOptionsPanel()}
          <div style={{height: "500px"}}>
            <LoaderBlocker show={true}/>
          </div>
        </div>
      );
    }

    // display no more answers
    if (!questions || _.isEmpty(questions)) {
      return (
        <div className="tab-panel task-questions">
          {this.renderOptionsPanel()}
          <div className="">
            <h2>There are no questions yet.</h2>
          </div>
        </div>
      );
    }
    const config = _.cloneDeep(this.props.config);
    config.structure = config.structure.splice(0, config.structure.length - 1);

    return (
      <div className="tab-panel TaskDone">
        {this.renderOptionsPanel()}
        <QuestionsSet
          questions={questions}
          config={config}
          onSubmit={() => {
          }}
          onSkip={() => {
          }}
          isModerator={false}
          showReview={false}
          editReview={false}
        />
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isStaff: state.auth.is_staff,
    username: state.auth.username,
    editReview: false,
  };
};

export default connect(mapStateToProps)(withRouter(TaskQuestions));
