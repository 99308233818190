import _ from "lodash";
import React, {Component} from "react";
import {setDragging} from "../../components/simple/DragAndDropSelector/utils";
import {Form, Header} from "semantic-ui-react";
import PropTypes from "prop-types";

import DragAndDropSelector from "../../components/simple/DragAndDropSelector/DragAndDropSelector";
import LoaderSpinner from "../../components/LoaderSpinner";
import "./AddManyUsers.css";
import {DateTimeInput} from "../../components/DateTimeInput";

class AddManyUsersForm extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    tasks: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    divisions: PropTypes.array.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    userTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.setDragging = setDragging.bind(this);
  }

  render() {
    return <Form>
      <Header size="medium" textAlign="center">Select tasks to assign to</Header>
      {this.props.tasks.length
        ? <DragAndDropSelector
          choices={this.props.tasks}
          choiceValue={"title"} // Display task's title.
          filterable
          selectAll
          afterSelectionChange={(newVal) => this.props.onChangeForm({selectedTasks: newVal})}
          selected={this.props.values.selectedTasks}/>
        : <LoaderSpinner/>
      }
      <Form.Group>
        <Form.Input
          value={this.props.values.questionLimit}
          type="number"
          onChange={(e, data) => this.props.onChangeForm({questionLimit: data.value})}
          label="Questions limit"/>
        <DateTimeInput
          id="datetime"
          name="datetime"
          className="date"
          label="Deadline"
          closable
          autoComplete="off"
          placeholder="Deadline"
          dateFormat="YYYY-MM-DD"
          iconPosition="left"
          popupPosition="bottom center"
          value={this.props.values.datetime}
          onChange={(value) => this.props.onChangeForm({datetime: value})}
        />
      </Form.Group>
      <Header size="medium" textAlign="center">Select existing users</Header>
      {this.props.users.length
        ? <DragAndDropSelector
          choices={this.props.users}
          choiceValue={"username"} // Display user's username.
          filterable
          selectAll
          afterSelectionChange={(newVal) => this.props.onChangeForm({selectedUsers: newVal})}
          selected={this.props.values.selectedUsers || []}/>
        : <LoaderSpinner/>
      }
      <Header size="medium" textAlign="center">Add new users</Header>
      <Form.TextArea
        value={this.props.values.newUsers}
        max={600}
        onChange={(e, data) => this.props.onChangeForm({newUsers: data.value})}
        label="New users emails"/>
      <Form.Group>
        <Form.Dropdown
          placeholder="Select Company"
          options={this.props.companies}
          value={this.props.values.company || ""}
          label="Company"
          onChange={(e, data) => this.props.onChangeForm({
            company: _.find(this.props.companies, (o) => o.key === data.value).key
          })}/>
        <Form.Dropdown
          placeholder="Select User Type"
          options={this.props.userTypes}
          value={this.props.values.type || ""}
          label="User Type"
          onChange={(e, data) => this.props.onChangeForm({
            type: _.find(this.props.userTypes, (o) => o.key === data.value).key
          })}/>
        <Form.Dropdown
          placeholder="Select Division"
          options={this.props.divisions}
          value={this.props.values.division || ""}
          label="Division"
          onChange={(e, data) => this.props.onChangeForm({
            division: _.find(this.props.divisions, (o) => o.key === data.value).key
          })}/>
      </Form.Group>
    </Form>;
  }
}

export default AddManyUsersForm;
