import React, {PureComponent} from "react";
import PropTypes from "prop-types";


export default class DragElement extends PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  };

  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };

    this.dragType = null;
  }

  onDragStart = (e) => {
    e.dataTransfer.setData("id", this.props.id);
    e.dataTransfer.setData("type", this.dragType);
  };

  render() {
    return (
      <li
        draggable
        key={this.props.id}
        onDoubleClick={this.props.onDoubleClick}
        hidden={this.state.hidden}
        onDragStart={(e) => {
          this.onDragStart(e);
        }}
        onDragEnd={() => {
          this.setState({hidden: false});
        }}
      >
        {this.props.children}
      </li>
    );
  }
}
