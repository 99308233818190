export const SET_TOKEN = "SET_TOKEN";
export const RESTORE_TOKEN = "RESTORE_TOKEN";

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const STORE_PATH_FOR_AUTOLOGIN = "STORE_PATH_FOR_AUTOLOGIN";
export const CLEAR_PATH_AFTER_AUTOLOGIN = "CLEAR_PATH_AFTER_AUTOLOGIN";

export const SET_FRONTEND_USER_PERMS = "SET_FRONTEND_USER_PERMS";

export const CHANGE_CONFIRMATION_ACCESS_TO_USERS_DATA = "CHANGE_CONFIRMATION_ACCESS_TO_USERS_DATA";
