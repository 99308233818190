import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import TreeNode from "./TreeNode";
import TreeDropdown from "./TreeDropdown";
import {
  getNode as genericGetNode,
  iterateAllNodes,
  getValuesToFind,
  nodesFulfillCriteria,
  removeNode
} from "./utils";

import "./TreeSelect.css";


class TreeSelect extends Component {
  static propTypes = {
    /* expected structure is {title (optional), value, children(optional)} */
    data: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    // array of values for nodes that should be collapsed.
    collapse: PropTypes.array,
    // allows selecting multiple nodes and leaves.
    multiple: PropTypes.bool,
    // will display checkbox next to node title.
    checkableNodes: PropTypes.bool,
    // All nodes will be open by default. If falsy, all nodes will be closed by default.
    defaultOpen: PropTypes.bool,
    /* Only a leaf node can be truly checked. Checks on non leaf nodes are for display only. */
    statelessParents: PropTypes.bool,
    /* Show only the tree, without the dropdown and search functionality */
    showTreeOnly: PropTypes.bool,
    /* Keep the dropdown open. */
    dropdownOpen: PropTypes.bool,
    /* Pressing enter when search results are empty will add a new element to the options and select it. */
    enableAdditionOnEnter: PropTypes.bool,
    // overwrites the native function.
    handleCollapseClick: PropTypes.func,
    // overwrites the native function.
    handleCheckboxClick: PropTypes.func,
    // function called to update the data.
    updateData: PropTypes.func,
    /* checking / unchecking parent will change all children. Requires multiple and checkableNodes to be set. */
    parentUpdateChangeChildren: function (props, propName, componentName) {
      if (props[propName] && (!props.checkableNodes || !props.multiple)) {
        return new Error(
          "`" + propName + "` requires `checkableNodes` and `multiple` to be set. " + componentName + " Validation failed."
        );
      }
      if (/matchme/.test(props[propName])) {
        return new Error(
          "Invalid prop `" + propName + "` supplied to" +
          " `" + componentName + "`. Validation failed."
        );
      }
    },
    // Overwrites the add button handler
    handleAddButtonClick: PropTypes.func,
    // Overwrites the delete button handler
    handleDeleteButtonClick: PropTypes.func,
    searchQuery: PropTypes.string,
    updateCollapse: PropTypes.func,
    updateValue: PropTypes.func,
    handleAddClick: PropTypes.func,
    handleDeleteClick: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSearchChange: PropTypes.func,
    displayAddButton: PropTypes.bool,
    displayDeleteButton: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showTree: false,
      openInputValue: null,
      collapse: [],
    };
  }

  getNode = (value, field = "value") => {
    const data = _.cloneDeep(this.props.data);
    return [genericGetNode(data, value, field), data];
  };

  getSearchQuery = () => {
    return this.props.searchQuery ? this.props.searchQuery : this.state.searchQuery;
  };

  handleCollapseClick = this.props.handleCollapseClick ? this.props.handleCollapseClick : (value) => {
    const collapse = this.props.collapse ? [...this.props.collapse] : [...this.state.collapse];
    const idx = collapse.indexOf(value);
    if (idx !== -1) {
      collapse.splice(idx, 1);
    } else {
      collapse.push(value);
    }
    if (this.props.collapse) {
      this.props.updateCollapse(collapse);
    } else {
      this.setState({collapse});
    }

  };

  handleDataUpdateWithMultiple = (value) => {
    const newVals = _.cloneDeep(this.props.value);
    const node = this.getNode(value)[0];
    if (this.props.statelessParents && node.children) {
      return newVals;
    }

    const idx = newVals.indexOf(value);
    if (idx !== -1) {
      newVals.splice(idx, 1);
    } else {
      newVals.push(value);
    }
    return newVals;
  };

  handleStatelessParents = (value, node, newVals) => {
    const leavesUnderNode = [];
    iterateAllNodes(node.children, (n) => {
      if (!n.children) {
        leavesUnderNode.push(n.value);
      }
    });
    // if not all leaves are checked, add all missing ones to the values
    const difference = _.difference(leavesUnderNode, newVals);
    if (difference.length !== 0) {
      newVals = _.union(newVals, leavesUnderNode);
    } else {
      newVals = _.difference(newVals, leavesUnderNode);
    }

    return newVals;
  };

  handleDataUpdateWithChildrenUpdate = (value) => {
    let newVals = _.cloneDeep(this.props.value);
    const idx = newVals.indexOf(value);
    const node = this.getNode(value)[0];

    if (!node.children) { // is leaf
      if (idx !== -1) {
        newVals.splice(idx, 1);
      } else {
        newVals.push(value);
      }
    } else if (this.props.statelessParents) {
      newVals = this.handleStatelessParents(value, node, newVals);
    } else {
      const valuesUnderNode = [node.value];
      iterateAllNodes(node.children, (n) => valuesUnderNode.push(n.value));

      if (idx !== -1) {
        newVals = _.difference(newVals, valuesUnderNode);
      } else {
        newVals = _.union(newVals, valuesUnderNode);
      }
    }

    return newVals;
  };

  handleSingleValue = (value) => {
    const newVals = this.props.value;
    const node = this.getNode(value)[0];
    if (node.children && this.props.statelessParents) {
      return newVals;
    }

    return !_.isEqual(value, newVals[0]) ? [value] : [];
  };

  handleCheckboxClick = this.props.handleCheckboxClick ? this.props.handleCheckboxClick : (value) => {
    let val = [];
    if (this.props.multiple) {
      if (this.props.parentUpdateChangeChildren) {
        val = this.handleDataUpdateWithChildrenUpdate(value);
      } else {
        val = this.handleDataUpdateWithMultiple(value);
      }
    } else {
      val = this.handleSingleValue(value);
    }
    this.setState({searchQuery: ""});
    this.props.updateValue(val, value);
  };

  handleAddClick = this.props.handleAddClick ? this.props.handleAddClick : (newNode, parentValue) => {
    const [node, data] = this.getNode(parentValue);
    if (node) {
      if (node.children) {
        node.children.unshift(newNode);
      } else {
        node.children = [newNode];
      }
    } else {
      data.unshift(newNode);
    }

    this.props.updateData(data);
  };

  handleDeleteClick = this.props.handleDeleteClick ? this.props.handleDeleteClick : (value) => {
    const data = _.cloneDeep(this.props.data);
    removeNode(data, value);
    this.props.updateData(data);
  };

  handleChange = (e, data) => {
    if (e.keyCode === 13 && this.getSearchQuery().length === 0) {
      e.preventDefault();
      return;
    }
    if (e.keyCode === 13 && this.getSearchQuery().length > 0) {
      e.preventDefault();
      this.handleSubmit(this.getSearchQuery());
      return;
    }
    if (!data.value) {
      return;
    }
    if (!Array.isArray(data.value)) {
      data.value = [data.value];
    }
    this.props.updateValue(data.value);
  };

  filterOptions = (data) => {
    const valuesToFind = getValuesToFind(data, this.getSearchQuery());
    const dataTree = _.cloneDeep(data);
    return nodesFulfillCriteria(dataTree, (n) => {
      return _.includes(valuesToFind, n.value);
    });
  };

  handleSubmit = this.props.handleSubmit ? this.props.handleSubmit : (text) => {
    if (!text) {
      return;
    }
    // By title
    let [node, data] = this.getNode(text, "title");
    if (node) {
      text = node.value;
    }
    // Search by value
    if (!node) {
      [node, data] = this.getNode(text);
    }
    // Multiple search
    if (!node) {
      const valuesThatMatch = getValuesToFind(this.props.data, this.getSearchQuery());
      if (valuesThatMatch.length === 1) {
        node = this.getNode(valuesThatMatch[0])[0];
        text = node.value;
      }
    }
    // If still not found
    if (!node) {
      if (!this.props.enableAdditionOnEnter) {
        return;
      }
      data.unshift({
        title: text,
        value: text,
      });
      this.props.updateData(data);
    }

    let values = _.cloneDeep(this.props.value);
    if (!this.props.multiple) {
      values = [text];
    } else {
      values.push(text);
    }
    this.props.updateValue(values);
    this.setState({searchQuery: ""});
  };

  changeOpenInputValue = (openInputValue) => {
    this.setState({
      openInputValue: openInputValue !== this.state.openInputValue ? openInputValue : "",
    });
  };

  onSearchChange = this.props.onSearchChange ? this.props.onSearchChange : (searchQuery) => {
    this.setState({searchQuery});
  };

  render() {
    const tree =
      <TreeNode
        data={this.filterOptions(this.props.data)}
        allData={this.props.data}
        value={this.props.value}
        parentNodeValue={null}
        handleCollapseClick={this.handleCollapseClick}
        handleCheckboxClick={this.handleCheckboxClick}
        checkableNodes={this.props.checkableNodes}
        statelessParents={this.props.statelessParents}
        displayAddButton={this.props.displayAddButton}
        displayDeleteButton={this.props.displayDeleteButton}
        handleAddClick={this.handleAddClick}
        handleDeleteClick={this.handleDeleteClick}
        collapse={this.props.collapse ? this.props.collapse : this.state.collapse}
        openInputValue={this.state.openInputValue}
        updateOpenInputValue={this.changeOpenInputValue}
        defaultOpen={this.props.defaultOpen}
        searchQuery={this.props.searchQuery}
      />;

    return (
      <div className={"TreeSelect "}>
        {!this.props.showTreeOnly &&
        <TreeDropdown
          data={this.props.data}
          value={this.props.value}
          onItemClick={this.handleCheckboxClick}
          multiple={this.props.multiple}
          statelessParents={this.props.statelessParents}
          parentUpdateChangeChildren={this.props.parentUpdateChangeChildren}
          checkableNodes={this.props.checkableNodes}
          searchQuery={this.getSearchQuery()}
          onSearchChange={this.onSearchChange}
          tree={tree}
          dropdownOpen={this.props.dropdownOpen}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />}
        {this.props.showTreeOnly && tree}
      </div>
    );
  }
}

export default TreeSelect;
