import React from "react";
import _ from "lodash";
import {toastr} from "react-redux-toastr";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";

import "./waveSurferWaveform.css";
import {cut, trim} from "../utils/waveSurferOperation";
import {Button, Form, Checkbox, Icon} from "semantic-ui-react";
import {getPossibleDropdownValues2} from "../../../../../helpers/utils";
import PropTypes from "prop-types";
import LoaderSpinner from "../../../../LoaderSpinner";

export default class WaveSurferWaveform extends React.Component {
  /**
   * Based on:
   * https://github.com/vikasmagar512/wavesurfer-audio-editor/blob/master/src/components/waveSurferWaveform.js
   */
  static propTypes = {
    src: PropTypes.string,
    selectedRegion: PropTypes.shape({
      id: PropTypes.string.isRequired,
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
      note: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
    }),
    annotations: PropTypes.object.isRequired,
    removeAllAnnotations: PropTypes.func.isRequired,
    onRegionClick: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
    onRegionUpdated: PropTypes.func.isRequired,
    showRegionButtons: PropTypes.bool.isRequired,
    editorMode: PropTypes.bool,
    saveToWav: PropTypes.func.isRequired,
    setRecordingLength: PropTypes.func.isRequired,
    setPauseOnRegionBoundary: PropTypes.func.isRequired,
    pauseOnRegionBoundary: PropTypes.bool.isRequired,
    isVideo: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    setWavesurferInstance: PropTypes.func,
    enableCut: PropTypes.bool,
    disableDrag: PropTypes.bool,
    disableAnnotationIntersection: PropTypes.bool,
    minLength: PropTypes.number,
    removeAnnotation: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      pos: 0,
      zoomLevel: 1,
      shiftPressed: false,
      playing: false,
      loading: true,
    };
    this.handlePosChange = this.handlePosChange.bind(this);
    this.undoBuffer = this.undoBuffer.bind(this);
    this.resetBuffer = this.resetBuffer.bind(this);
    this.cutAndReturnAudioBuffer = this.cutAndReturnAudioBuffer.bind(this);
    this.trimAndReturnAudioBuffer = this.trimAndReturnAudioBuffer.bind(this);

    this.playbackRates = getPossibleDropdownValues2([
      "0.25", "0.50", "0.75", "1.00", "1.25", "1.50", "1.75", "2.00"
    ]);
    this.skipLength = 5;

    this.savedRegion = null;
    this.snapBlock = true;
    this.snapBlockSet = false;

    this.backwardSkipIndicatorRef = React.createRef();
    this.forwardSkipIndicatorRef = React.createRef();
  }

  componentDidMount() {
    if (!this.isSourceValid()) {
      /* If `this.props.src` is empty, it tries to get file from directory and stack trace appears.

      index.js:1575 Uncaught Error: The error you provided does not contain a stack trace.
      at B (index.js:1575)
      at G (index.js:1892)
      at index.js:1907
      at index.js:1926
      at index.js:1407
      */
      return;
    }

    const id = this.props.id;

    function formatTimeCallback(seconds, pxPerSec) {
      seconds = Number(seconds);
      let minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;

      // fill up seconds with zeroes
      let secondsStr = Math.round(seconds).toString();
      if (pxPerSec >= 25 * 10) {
        secondsStr = seconds.toFixed(2);
      } else if (pxPerSec >= 25) {
        secondsStr = seconds;
      }

      if (minutes > 0) {
        if (seconds < 10) {
          secondsStr = '0' + secondsStr;
        }
        return `${minutes}:${secondsStr}`;
      }
      return secondsStr;
    }

    function timeInterval(pxPerSec) {
      let retval = 1;
      if (pxPerSec >= 25 * 100) {
        retval = 0.01;
      } else if (pxPerSec >= 25 * 40) {
        retval = 0.025;
      } else if (pxPerSec >= 25 * 10) {
        retval = 0.1;
      } else if (pxPerSec >= 25 * 4) {
        retval = 0.25;
      } else if (pxPerSec >= 25) {
        retval = 1;
      } else if (pxPerSec * 5 >= 25) {
        retval = 5;
      } else if (pxPerSec * 15 >= 25) {
        retval = 15;
      } else {
        retval = Math.ceil(0.5 / pxPerSec) * 60;
      }
      return retval;
    }
    function primaryLabelInterval(pxPerSec) {
      let retval = 1;
      if (pxPerSec >= 25 * 100) {
        retval = 10;
      } else if (pxPerSec >= 25 * 40) {
        retval = 4;
      } else if (pxPerSec >= 25 * 10) {
        retval = 10;
      } else if (pxPerSec >= 25 * 4) {
        retval = 4;
      } else if (pxPerSec >= 25) {
        retval = 1;
      } else if (pxPerSec * 5 >= 25) {
        retval = 5;
      } else if (pxPerSec * 15 >= 25) {
        retval = 15;
      } else {
        retval = Math.ceil(0.5 / pxPerSec) * 60;
      }
      return retval;
    }
    function secondaryLabelInterval(pxPerSec) {
      return Math.floor(10 / timeInterval(pxPerSec));
    }
    let waveSurferProps = {
      container: document.querySelector(`#wave-${id}`),
      waveColor: "violet",
      progressColor: "purple",
      responsive: true,
      scrollParent: true,
      normalize: true,
      plugins: [
        RegionsPlugin.create({
          regions: [],
          dragSelection: {
            slop: 5
          },
          useSelection: true
        }),
        TimelinePlugin.create({
          container: document.querySelector(`#wave-timeline-${id}`),
          formatTimeCallback: formatTimeCallback,
          timeInterval: timeInterval,
          primaryLabelInterval: primaryLabelInterval,
          secondaryLabelInterval: secondaryLabelInterval
        })
      ]
    };

    let src = this.props.src;

    // Do not use MediaElement for audio files. It will crash cut/trim functions!
    if (this.props.isVideo) {
      src = document.querySelector(`#media-element-${id}`);
      waveSurferProps = {
        ...waveSurferProps,
        backend: "MediaElement",
        mediaType: "video",
      };
    }

    this.waveSurfer = WaveSurfer.create(waveSurferProps);
    this.waveSurfer.load(src);

    this.waveSurfer.on("ready", () => {
      this.setState({duration: this.waveSurfer.getDuration().toFixed(2)});
      this.saveOriginalBuffer(this.waveSurfer);
      this.props.setRecordingLength(this.waveSurfer.getDuration());

      this.waveSurfer.container.addEventListener("mousewheel", this.handleTimelineScroll);

      window.addEventListener("keydown", this.handleKeyboardInput);
      window.addEventListener("keyup", this.handleKeyboardInput);

      this.setState({loading: false});
    });

    this.waveSurfer.on("waveform-ready", () => {
      this.saveOriginalBuffer(this.waveSurfer);
      this.props.setRecordingLength(this.waveSurfer.getDuration());
    });

    this.waveSurfer.on("pause", () => {
      this.waveSurfer.params.container.style.opacity = 0.9;
    });

    this.waveSurfer.on("region-update-end", (region, e) => {
      e.stopPropagation();
      // To high precision bug fix
      let start  = parseFloat(region.start),
          end = parseFloat(region.end);
      region.start = start.toFixed(3);
      region.end = end.toFixed(3);
      if (
        this.props.disableAnnotationIntersection
        &&
        Object.values(this.waveSurfer.regions.list)
          .filter((reg) => reg.id !== region.id)
          .some((reg) => reg.start <= region.end && reg.end >= region.start)
      ) {
        /* toastr.error(
          `Annotation intersecting!`,
          `Annotations can't intersect with each other`
        );*/

        const savedRegion = Object.values(this.props.annotations)
          .find((reg) => reg.id === region.id)
        if (savedRegion) {
          region.start = savedRegion.start;
          region.end = savedRegion.end;
          this.props.onRegionUpdated(region);
        } else {
          this.props.removeAnnotation(region, true);
        }
      } else if (this.props.minLength && region.end - region.start < this.props.minLength) {
        toastr.error(
          `Annotation too short!`,
          `Annotations minimum length is ${this.props.minLength}s`
        );
        this.props.removeAnnotation(region, true);
      } else {
        this.props.onRegionUpdated(region);
      }
    });

    this.waveSurfer.on("region-updated", (e) => {
      if (this.props.disableAnnotationIntersection) {
        if (
          Object.values(this.waveSurfer.regions.list)
            .filter((reg) => reg.id !== e.id)
            .some((reg) => reg.start <= e.end && reg.end >= e.start)
        ) {
          /*toastr.error(
            `Annotation intersecting!`,
            `Annotations can't intersect with each other`
          );*/

          if (!this.savedRegion) {
            return;
          }

          e.start = this.savedRegion.start;
          e.end = this.savedRegion.end;
          //this.props.onRegionUpdated(e); -> This causes stack limit exceeded error
        } else {
          this.savedRegion = {start: e.start, end: e.end};
        }
      }

      if (this.snapBlock) {
        const currTime = this.waveSurfer.getCurrentTime();
        let snapDistance = this.skipLength > 1 ? this.skipLength / 10 : this.skipLength;
        if (e.isResizing) {
          if (e.start > currTime - snapDistance && e.start < currTime + snapDistance) {
            e.start = currTime;
            this.handleSnapTimeouts();
          } else if (e.end > currTime - snapDistance && e.end < currTime + snapDistance) {
            e.end = currTime;
            this.handleSnapTimeouts();
          }
        }
      }
    });

    this.waveSurfer.on("region-click", (region) => {
      this.props.onRegionClick(region, true);
    });

    this.waveSurfer.on("region-dblclick", (region, e) => {
      e.stopPropagation();
      setTimeout(() => this.props.onRegionClick(region), 0); //walkaround
    });

    this.waveSurfer.on("pause", () => {
      this.setState({playing: false});
    });

    this.waveSurfer.on("play", () => {
      this.setState({playing: true});
      this.props.hideMenu();
    });

    this.waveSurfer.on("region-in", () => {
      if (this.props.pauseOnRegionBoundary) {
        this.waveSurfer.pause();
      }
    });

    this.waveSurfer.on("region-out", () => {
      if (this.props.pauseOnRegionBoundary) {
        this.waveSurfer.pause();
      }
    });

    this.props.setWavesurferInstance(this.waveSurfer);
    this.addLoadedRegions(this.props.annotations);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.waveSurfer.load(this.props.src);
    }
  }

  componentWillUnmount() {
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
    }
    window.removeEventListener("keydown", this.handleKeyboardInput);
    window.removeEventListener("keyup", this.handleKeyboardInput);
  }

  handlePosChange(event) {
    this.setState({
      pos: event.originalArgs[0]
    });
  }

  handleTimelineScroll = (e) => {
    e.preventDefault();
    if (this.state.shiftPressed) {
      let newZoomValue = this.state.zoomLevel + e.wheelDelta / 12;
      if (newZoomValue > 500) {
        newZoomValue = 500;
      } else if (newZoomValue < 1) {
        newZoomValue = 1;
      }
      this.zoomWaveform({
        target: {
          value: newZoomValue
        }
      });
    } else {
      this.waveSurfer.container.firstChild.scrollLeft -= e.wheelDelta;
    }
  }

  handleSnapTimeouts = () => {
    const lockTimeout = 250;
    if (!this.snapBlockSet) {
      this.snapBlockSet = true;
      setTimeout(() => { // snapping works but freezez the cursor so after timeout
        this.snapBlock = false; // disable snapping (free cursor)
        setTimeout(() => {
          this.snapBlock = true; //enable snapping once again after another timeout
          this.snapBlockSet = false;
        }, lockTimeout);
      }, lockTimeout);
    }
  }

  handleSkip = (value, dontSetInterval) => {
    this.waveSurfer.skip(value);
    if (!dontSetInterval) {
      this.skipInterval = setInterval(() => this.handleSkip(value, true), 200);
    }
    const indicator = value < 0 ? this.backwardSkipIndicatorRef.current : this.forwardSkipIndicatorRef.current;
    indicator.classList.remove("floatUpAndDisappear");
    void indicator.offsetWidth;
    indicator.classList.add("floatUpAndDisappear");
  }

  handleThrottledSkip = _.throttle(this.handleSkip, 200)

  handleKeyboardInput = (e) => {
    if (e.target.matches('input, textarea')) {
      return;
    }
    e.preventDefault();
    if (e.keyCode === 32 && e.type === "keyup") { //spacebar
      this.waveSurfer.playPause();
    } else if (e.keyCode === 16) { //shift
      if (e.type === "keydown" && !this.state.shiftPressed) {
        this.setState({shiftPressed: true});
      } else if (e.type === "keyup") {
        this.setState({shiftPressed: false});
      }
    } else if (e.keyCode === 37 && e.type === "keydown") { //left arrow
      this.handleThrottledSkip(-this.skipLength, true);
    } else if (e.keyCode === 39 && e.type === "keydown") { //right arrow
      this.handleThrottledSkip(this.skipLength, true);
    }
  }

  loadBuffer = (waveSurfer, buffer) => {
    /**
     * wavesurfer.js' loadDecodedBuffer() is causing an unexpected behaviour.
     * Creating new Region adds Regions for previous buffers.
     * E.g. instead of one new Region we get three instances of the same Region.
     */
    waveSurfer.backend.load(buffer);
    waveSurfer.drawBuffer();
    waveSurfer.isReady = true;
  };

  saveOriginalBuffer = (waveSurfer) => {
    if (!this.state.originalBuffer) {
      this.setState({originalBuffer: waveSurfer.backend.buffer});
    }
  };
  generateColor = () => {
    let r = Math.floor(Math.random() * 235);
    let g = Math.floor(Math.random() * 235);
    let b = Math.floor(Math.random() * 235);
    let a = .6;
    return `rgba(${r},${g},${b},${a})`;
  }
  addLoadedRegions = (annotations) => {
    // Reads annotations and adds Regions.
    _.forEach(annotations, (annotation) => {
      let color
      if (!annotation.color) {
        color = this.generateColor();
      } else {
        color = annotation.color;
      }
      if (_.has(annotation, "start")) {
        this.waveSurfer.addRegion({
          id: annotation.id,
          start: annotation.start,
          end: annotation.end,
          data: annotation.note,
          resize: true,
          drag: !this.props.disableDrag,
          minLength: this.props.minLength || 0,
          color
        });
      }
    });
  };

  updateRegion = (region, changeColor = true) => {
    // It already exists so we're just updating it. Called from MediaWaveformWidget.
    const regionInstance = this.waveSurfer.regions.list[region.id];

    const extraProps = {color: regionInstance.color};
    if (changeColor) {
      extraProps.color = this.generateColor();
    }

    if (this.props.isVideo) {
      extraProps.start = region.start;
      extraProps.end = region.end;
    }
    regionInstance.update({
      start: region.start,
      end: region.end,
      data: region.note ? region.note : "",
      resize: true,
      drag: !this.props.disableDrag,
      minLength: this.props.minLength || 0,
      ...extraProps
    });
  };

  removeRegion = (regionId) => {
    // Remove region from WaveForm. Called from AudioWidget.
    const region = this.waveSurfer.regions.list[regionId];
    region.remove();
  };

  removeAllRegions = () => {
    this.waveSurfer.clearRegions();
    // Clear AudioWidget values.
    this.props.removeAllAnnotations();
  };

  undoBuffer() {
    // Loads previous audio buffer to WaveSurfer.
    if (!this.state.previousBuffer) {
      return;
    }
    const waveSurfer = this.waveSurfer;
    this.loadBuffer(waveSurfer, this.state.previousBuffer);
    this.setState({previousBuffer: null});
    waveSurfer.clearRegions();
  }

  resetBuffer() {
    // Loads initial audio buffer to WaveSurfer.
    if (!this.state.originalBuffer) {
      return;
    }
    if (window.confirm("Are you sure you want to reset waveform?")) {
      this.removeAllRegions()
      const waveSurfer = this.waveSurfer;
      this.loadBuffer(waveSurfer, this.state.originalBuffer);
      waveSurfer.clearRegions();
    }
  }

  cutAndReturnAudioBuffer() {
    const waveSurfer = this.waveSurfer;
    const region = waveSurfer.regions.list[this.props.selectedRegion.id];

    // Save previous buffer.
    this.setState({previousBuffer: waveSurfer.backend.buffer});

    // Cut selected region and join leftovers.
    const newAudioBuffer = cut(region, waveSurfer);
    this.loadBuffer(waveSurfer, newAudioBuffer);
    waveSurfer.clearRegions();
    this.props.removeAllAnnotations();
  }

  trimAndReturnAudioBuffer() {
    const waveSurfer = this.waveSurfer;
    const region = waveSurfer.regions.list[this.props.selectedRegion.id];

    // Save previous buffer.
    this.setState({previousBuffer: waveSurfer.backend.buffer});

    // Cut selected region and drop leftovers.
    const newAudioBuffer = trim(region, waveSurfer);
    this.loadBuffer(waveSurfer, newAudioBuffer);
    waveSurfer.clearRegions();
    this.props.removeAllAnnotations();
  }

  isSourceValid() {
    return !this.props.src.endsWith("/");
  }

  zoomWaveform(event) {
    const zoomLevel = Number(event.target.value);
    this.props.hideMenu();
    this.setState({zoomLevel}, () => {
      this.waveSurfer.zoom(zoomLevel);
      this.skipLength = Math.floor((1 / zoomLevel) * 5000) / 1000;
    });
  }

  formatDuration = (totalSeconds) => {
    if (!totalSeconds) {
      return "";
    } else {
      let seconds = parseFloat(totalSeconds);
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      // seconds = Math.floor(seconds % 60);
      seconds = (seconds % 60).toFixed(2);
      return `${hours}:${minutes}:${seconds}`;
    }
  }

  render() {
    if (!this.isSourceValid()) {
      return (
        <div>
          <b>WAV file is not valid or is missing.</b>
        </div>
      );
    }
    const id = this.props.id;

    const formatedSkipLength = this.skipLength > 1
      ? this.skipLength + "s"
      : this.skipLength * 1000 + "ms";

    return (
      <div>
        {this.state.loading && <LoaderSpinner/>}
        <div className={`waveform ${this.state.loading && "hidden"}`}>
          {this.props.isVideo ?
            <div className="video-container">
              <div className="video-controls">
                <span ref={this.backwardSkipIndicatorRef} className="left">
                  -{formatedSkipLength}
                </span>
                <Icon
                  size="huge"
                  name="undo"
                  onMouseDown={() => this.handleSkip(-this.skipLength)}
                  onMouseUp={() => clearInterval(this.skipInterval)}/>
                <Icon
                  size="huge"
                  name={this.state.playing ? "pause" : "play"}
                  onClick={() => this.waveSurfer.playPause()}/>
                <Icon
                  size="huge"
                  name="redo"
                  onMouseDown={() => this.handleSkip(this.skipLength)}
                  onMouseUp={() => clearInterval(this.skipInterval)}/>
                <span ref={this.forwardSkipIndicatorRef} className="right">
                  +{formatedSkipLength}
                </span>
              </div>
              <video
                id={`media-element-${id}`}
                className="media-element-recording"
                src={this.props.src}
              />
            </div>
            : null}
          <div id={`wave-timeline-${id}`} className="wave-timeline"/>
          <div id={`wave-${id}`} className="wave"/>
          <Form className="player-menu">
            <Form.Group inline>
              <Button
                id="play"
                disabled={this.props.editorMode}
                compact
                color="green"
                onClick={() => this.waveSurfer.playPause()}
                icon={this.state.playing ? "pause" : "play"}
              />
              <label>Duration: {this.formatDuration(this.state.duration)} ({this.state.duration}s)</label>
              <Form.Input
                label="Zoom"
                id="slider"
                disabled={this.props.editorMode}
                min={1}
                max={500}
                value={this.state.zoomLevel}
                onChange={(e) => this.zoomWaveform(e)}
                type="range"/>
              {!this.props.isVideo &&
              <Button.Group>
                <Button
                  id="undo"
                  disabled={this.props.editorMode || !this.state.previousBuffer}
                  compact
                  color="blue"
                  onClick={this.undoBuffer}>
                  Undo
                </Button>
                <Button
                  id="reset"
                  disabled={this.props.editorMode}
                  compact
                  color="blue"
                  onClick={this.resetBuffer}>
                  Reset
                </Button>
              </Button.Group>
              }
              {this.props.showRegionButtons && this.props.enableCut &&
              <Button.Group>
                <Button
                  id="cut"
                  disabled={this.props.editorMode}
                  compact
                  color="blue"
                  onClick={this.cutAndReturnAudioBuffer}>
                  Cut
                </Button>
                <Button
                  id="trim"
                  disabled={this.props.editorMode}
                  compact
                  color="blue"
                  onClick={this.trimAndReturnAudioBuffer}>
                  Trim
                </Button>
              </Button.Group>}
              <Checkbox
                label="Pause on region boundary"
                onChange={(e, data) => {
                  this.props.setPauseOnRegionBoundary(data.checked);
                }}
                checked={this.props.pauseOnRegionBoundary}
              />
              <Form.Select
                label="Playback speed rate"
                defaultValue={"1.00"}
                size="small"
                options={this.playbackRates}
                onChange={(e, {value}) => this.waveSurfer.setPlaybackRate(Number(value))}
                onClick={() =>  this.props.hideMenu()}
              />
              <Button
                id="remove-all"
                disabled={this.props.editorMode || !Object.keys(this.props.annotations).length}
                compact
                color="red"
                onClick={() =>
                  window.confirm("Are you sure you want to remove all annotations?") &&
                  this.removeAllRegions()
                }>
                Remove All
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}
