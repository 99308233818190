import _ from "lodash";
import React from "react";
import {connect} from "react-redux";
import config from "../../config/config";
import {Button, Grid} from "semantic-ui-react";
import ajax from "../../helpers/ajax";
import PriceTariffsFilters from "./PriceTariffsFilters";
import ListPriceTariffs from "./ListPriceTariffs";
import {deletePrice, NATIVE_OPTIONS, renderDeleteModal, renderModal, setModal} from "./PriceTariffsFunctions";
import PropTypes from "prop-types";
import {getPossibleDropdownValues} from "../../helpers/utils";
import FilteredComponent from "../../components/FilteredComponent";
import Tabs from "../../components/Tabs";
import LoaderSpinner from "../../components/LoaderSpinner";

import "./PriceTariffs.css";
import { toastr } from "react-redux-toastr";

class PriceTariffs extends FilteredComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      currencies: [],
      languages: [],
      users: [],
      userOptions: [],
      userTypes: [],
      natives: NATIVE_OPTIONS,
      priceTariffs: {},
      taskTypes: {},
      deleteModal: false,
      displayModal: false,
      filterByUser: props.location.pathname.indexOf("users") !== -1,
      loading: true
    };
    this.deletePrice = deletePrice.bind(this);
    this.renderDeleteModal = renderDeleteModal.bind(this);
    this.renderModal = renderModal.bind(this);
    this.setModal = setModal.bind(this);
    this.arrayFilters = ["company", "language", "user", "task_type", "user_type"];
  }

  componentDidMount() {
    this.getData();
    this.getOptions();
  }

  getData = () => {
    let params = this.getFilters();
    params["filter_by_user"] = this.state.filterByUser;
    ajax.get(config.PRICE_TARIFFS, {params})
      .then((response) => {
        let priceTariffs = {};
        _.map(response.data, (tariff) => {
          const tariff_type = tariff.user ? "user" : "company";
          if (tariff[tariff_type] in priceTariffs) {
            priceTariffs[tariff[tariff_type]].push(tariff);
          } else {
            priceTariffs[tariff[tariff_type]] = [tariff];
          }
        });
        this.setState({priceTariffs, loading: false});
      }).catch((error) => {
        toastr.error("Error", error);
      });
  };

  getUsersOptions = () => {
    ajax.get(config.USER_LIST_ALL)
      .then((response) => {
        let options = getPossibleDropdownValues("username", "username", response.data);
        this.setState({
          "users": response.data,
          "userOptions": options
        });
      });
  }

  getCompanyOptions = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        let options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({"companies": options});
      });
  }

  getOptions = () => {
    let options = [];
    if (this.state.filterByUser) {
      this.getUsersOptions();
    } else {
      this.getCompanyOptions();
    }
    ajax.get(config.CURRENCY_OPTIONS)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({"currencies": response.data});
      });
    ajax.get(config.LANGUAGES)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({"languages": options});
      });
    ajax.get(config.TASK_TYPES)
      .then((response) => {
        this.setState({"taskTypes": response.data});
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        options = _.map(response.data, (type) => {
          return {
            text: type.text,
            value: type.text,
            key: type.key
          };
        });
        this.setState({"userTypes": options});
      });
  };

  handleTabChange = (activeTab) => {
    const isUsersUrl = activeTab.key === "users";
    this.setState({
      filterByUser: isUsersUrl,
      loading: true
    }, this.getData);

    if (!this.state.companies.length && !isUsersUrl) {
      this.getCompanyOptions();
    } else if (!this.state.users.length && isUsersUrl) {
      this.getUsersOptions();
    }
  }

  updateFilter = (field, value) => {
    this.changeFilterInstantly({[field]: value}, false);
  };

  render() {
    const params = this.getFilters();
    const panes = [
      {
        key: "users",
        label: {name: "Users Price Tariffs"},
        components: null,
        tabUrl: "/price-tariffs/users"
      },
      {
        key: "companies",
        label: {name: "Companies Price Tariffs"},
        components: null,
        tabUrl: "/price-tariffs/companies"
      }
    ];
    return (
      <div className="PriceTariffs">
        <Tabs panes={panes} onChangeTab={this.handleTabChange}></Tabs>
        <h1>{this.state.filterByUser ? "Users" : "Companies"} Price Tariffs</h1>
        {this.state.deleteModal && this.renderDeleteModal()}
        {this.state.displayModal && this.renderModal()}
        <PriceTariffsFilters
          changeFilter={this.updateFilter}
          companyOptions={this.state.companies}
          filterByUser={this.state.filterByUser}
          filterParams={params}
          languageOptions={this.state.languages}
          taskTypeOptions={this.state.taskTypes}
          userOptions={this.state.userOptions}
          userTypeOptions={this.state.userTypes}
        />
        <Grid columns={8} className={"table"}>
          <Grid.Row className={"header"}>
            <Grid.Column width={14}>
              {this.state.filterByUser ? "User" : "Company"}
            </Grid.Column>
            <Grid.Column width={2} textAlign={"right"}>
              {this.props.is_superuser &&
                <Button
                  compact
                  color="yellow"
                  className="datahive-button"
                  onClick={() => this.setState({displayModal: true})}
                  content={"Add"}
                />
              }
            </Grid.Column>
          </Grid.Row>
          {this.state.loading
            ? <div className="tariffs-load"><LoaderSpinner/></div>
            : <ListPriceTariffs
              filterByUser={this.state.filterByUser}
              priceTariffs={this.state.priceTariffs}
              setModal={this.setModal}
            />
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_superuser: state.auth.is_superuser
});

export default connect(mapStateToProps)(PriceTariffs);
