import _ from "lodash";

import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Card, Container, Form, Grid, Header, Icon, Menu, Popup} from "semantic-ui-react";
import qs from "query-string";

import config from "../../config/config";
import {updateFiltersStateless} from "../../helpers/filters";
import ajax from "../../helpers/ajax";
import LoaderSpinner from "../../components/LoaderSpinner";
import BasicStatistics from "./BasicStatistics";
import TasksStatisticsTable from "./TasksStatisticsTable";
import Pagination from "../../components/Pagination";
import {archive_switch} from "../../helpers/utils";
import {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import FilteredComponent from "../../components/FilteredComponent";
import {yesNoOptions} from "../../helpers/filters";
import "./TasksStatistics.css";


class TasksStatistics extends FilteredComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      basicStatistics: null,
      taskStatisticsList: null,
      switchIndex: 0,
      totalPages: 1,
      popupOpen: false,
      title: _.get(qs.parse(props.location.search), "title") || "",
      selected_tasks: [],
      possibleFilterValues: {
        modules: [],
        projects: []
      }
    };

    this.arrayFilters = ["module", "project"];

    this.updateFiltersStateless = updateFiltersStateless.bind(this);
  }

  componentDidMount() {
    this.getFiltersValues();
    this.updateSwitchIndex();
    this.getBasicStatistics();
    super.componentDidMount();
  }

  getData = () => {
    this.getTasksStatistics();
  };

  getBasicStatistics = () => {
    ajax.get(config.BASIC_TASKS_STATISTICS, {params: this.state.filters})
      .then((response) => {
        this.setState({
          basicStatistics: response.data,
        });
      })
      .catch(() => {
      });
  };

  toggleCheckbox = (event, data) => {
    let selected_tasks = this.state.selected_tasks
      ? [...this.state.selected_tasks] : [];
    const value = data.value.toString();
    if (data.checked === true) {
      if (value in selected_tasks) {
        return;
      }
      selected_tasks.push.apply(selected_tasks, [value]);
    } else if (_.includes(selected_tasks, value)) {
      selected_tasks = _.pull(selected_tasks, value);
    }

    this.setState({selected_tasks});
  };

  getFiltersValues = () => {
    ajax.get(config.PROJECT_OPTIONS)
      .then((response) => {
        const projects = _.map(response.data, (project) => ({
          key: project.id,
          value: project.id,
          text: project.name
        }));
        this.setState({
          possibleFilterValues: {
            ...this.state.possibleFilterValues,
            projects
          }
        });
      })
      .catch(() => {
      });
    ajax.get(config.MODULE_OPTIONS)
      .then((response) => {
        const modules = _.map(response.data, (module) => (
          {
            key: module.id,
            value: module.id,
            text: module.name
          }
        ));
        this.setState({
          possibleFilterValues: {
            ...this.state.possibleFilterValues,
            modules
          }
        });
      })
      .catch(() => {
      });
  };

  getTasksStatistics = () => {
    let data = {
      "fields": "task,task_id,module,project,total_questions,answered_questions,fully_answered_questions,total_answers,target_answers,max_answers_per_question,is_active",
    };
    data = Object.assign({}, this.state.filters, data);
    ajax.get(config.TASKS_STATISTICS, {params: data})
      .then((response) => {
        this.setState({
          taskStatisticsList: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE)
        });
      })
      .catch(() => {
      });
  };

  updateSwitchIndex = () => {
    const switchIndex = this.getFilters().from === "archive" ? 1 : 0;
    this.setState({switchIndex});
  };

  switchDataBase = () => {
    const {filters} = this.state;
    filters.from = !filters.from ? "archive" : "";
    const switchIndex = filters.from === "archive" ? 1 : 0;
    this.setState(
      {switchIndex},
      () => this.changeFilterInstantly({"from": filters.from})
    );
  };

  handlePaginationChange = (e, {activePage}) => {
    this.changeFilterInstantly({page: activePage});
  };

  render() {
    const {filters} = this.state;
    const pagination =
      <Pagination activePage={filters?.page || 1}
        onPageChange={this.handlePaginationChange}
        totalPages={this.state.totalPages}
      />;

    const dataLoaded = !!this.state.taskStatisticsList;
    const filterPopup =
      <Menu className={"filters-menu"}>
        <Form style={{padding: "14px"}}>
          <Form.Field className={"Name"}>
            <Form.Input
              label="Name"
              value={this.state.title}
              onChange={(e, data) => this.searchChange(data.value.toLowerCase(), "title")}
              type="text"/>
          </Form.Field>
          <FormDropdown
            className={"Module"}
            placeholder="Module"
            label="Module"
            fluid search multiple selection
            options={this.state.possibleFilterValues.modules}
            value={filters.module ? filters.module.map(Number) : []}
            onChange={(e, data) => this.changeFilterInstantly({module: data.value})}
          />
          <FormDropdown
            className={"Project"}
            placeholder="Project"
            label={"Project"}
            fluid search multiple selection
            options={this.state.possibleFilterValues.projects}
            value={filters.project ? filters.project.map(Number) : []}
            onChange={(e, data) => this.changeFilterInstantly({project: data.value})}
          />
          <FormDropdown
            className="isActive"
            placeholder="Is active"
            label="Is active"
            fluid selection
            options={yesNoOptions}
            value={filters.is_active !== undefined ? filters.is_active : null}
            onChange={(e, data) => this.changeFilterInstantly({is_active: data.value})}
          />
          <div style={{textAlign: "center"}}>
            <Form.Button
              className="datahive-button"
              color="red"
              onClick={() => this.setState({
                popupOpen: false
              }, this.resetFilters)}
              content={"Reset"}
            />
            <Button
              type="group"
              className="datahive-button"
              color="dark-grey"
              content={"Group"}
              onClick={() => {
                filters.selected_tasks = this.state.selected_tasks;
                this.props.history.push("/task-statistics-group?" + qs.stringify(filters));
              }}
            />
          </div>
        </Form>
      </Menu>;

    return (
      <Container className="TasksStatistics">
        <Card.Group>
          <Card fluid className="datahive-card">
            <Card.Content>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                  </Grid.Column>
                  <Grid.Column>
                    <Header textAlign="center">Basic
                      statistics {archive_switch.title[this.state.switchIndex]}</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      color="dark-grey"
                      className={"switch-db-button datahive-button"}
                      size="mini" floated={"right"}
                      onClick={this.switchDataBase}>
                      <Icon
                        name={archive_switch.icon[this.state.switchIndex]}/>
                      {archive_switch.text[this.state.switchIndex]}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
            {this.state.basicStatistics &&
            <BasicStatistics {...this.state.basicStatistics}/>}
            {!this.state.basicStatistics && <LoaderSpinner/>}
          </Card>
          <Card fluid className="datahive-card">
            <Card.Content>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                  </Grid.Column>
                  <Grid.Column>
                    <Header textAlign="center">Tasks
                      statistics {archive_switch.title[this.state.switchIndex]}</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Popup
                      on="click"
                      position={"bottom center"}
                      style={{padding: 0}}
                      trigger={
                        <Button size="mini" floated="right"
                          color="dark-grey"
                          className="datahive-button"
                          onClick={() => {
                            this.setState({popupOpen: true});
                          }}>Filter <Icon
                            name="chevron down"/></Button>
                      }
                      content={filterPopup}
                      onClose={() => this.setState({popupOpen: false})}
                      open={this.state.popupOpen}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
            {dataLoaded &&
            <Card.Content>
              <TasksStatisticsTable
                taskStatisticsList={this.state.taskStatisticsList}
                toggleCheckbox={this.toggleCheckbox}
                selectedTasks={this.state.selected_tasks}
              />
              {pagination}
            </Card.Content>
            }
            {!dataLoaded && <LoaderSpinner/>}
          </Card>
        </Card.Group>
      </Container>
    );
  }
}

const
  mapStateToProps = (state) => {
    return {
      auth: state.auth
    };
  };

const
  mapDispatchToProps = (dispatch) => {
    return {
      errorsNew: (message) => dispatch({
        type: ERRORS_NEW,
        errors: message
      }),
      errorsClear: () => dispatch({
        type: ERRORS_CLEAR,
      })
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksStatistics));
