import React from "react";
import {Button, Table, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {toastr} from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import FilteredComponent from "../../../components/FilteredComponent";
import ContainerList from "../../../components/ContainerList";


class CompanyList extends FilteredComponent {
  static propTypes = {
    allUsers: PropTypes.array,
    companies: PropTypes.array,
    selectedCompany: PropTypes.object,
    divisions: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.emptyCompany = {
      id: null,
      name: "",
      division: null,
      accessible_to_staff: false,
      individual_rates: false,
      special_users: [],
      users: []
    };

    this.state = {
      companies: [],
      selectedCompany: this.emptyCompany,
      allUsers: [],
      divisions: [{
        key: "",
        value: "",
        text: ""
      }],
      displaySpinner: false,
      totalPages: 1,
      filters: {
        page: 1,
      },
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getData = () => {
    const params = (this.state.filters ? this.state.filters : this.getFilters());
    params.page = params.page ? params.page : 1;

    ajax.get(config.COMPANIES, {params})
      .then((response) => {
        this.setState({
          companies: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE)
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  }

    renderRow = (item) => {
      const {history} = this.props;

      return (
        <Table.Row
          key={item.id}
          onClick={() => history.push(urls.COMPANY_DETAIL.replace(":id", item.id))}>
          <Table.Cell style={{textAlign: "center"}}>
            <Icon size="small" name={item.is_active ? "check circle" : "times"}
              color={item.is_active ? "green-icon" : "red-icon"}/>
          </Table.Cell>
          <Table.Cell className={"list-item clickable"}>
            {item.name}
          </Table.Cell>
        </Table.Row>
      );
    }

  pageChange = (e, {activePage}) => {
    this.updateFilters({page: activePage});
  };

  render() {
    let columns = [
      {name: "status", title: "Status", headerProps: {width: 1, textAlign: "center"}},
      {name: "name", title: "Name", headerProps: {width: 13}},
    ];
    const button = <Link to={urls.COMPANY_DETAIL_NEW}>
      <Button
        className="ui button new-company-btn datahive-button"
        color="yellow"
        floated="right"
        content={"Add company"}
      />
    </Link>;

    return <ContainerList
      renderRow={this.renderRow}
      items={this.state.companies}
      columns={columns}
      button={button}
      history={this.props.history}
      totalPages={this.state.totalPages}
      pageChange={this.pageChange}
      filters={this.state.filters.page || 1}
    />;
  }
}

export default CompanyList;
