import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Button, Form, Input, Menu, Popup, Radio, Segment, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import {toastr} from "react-redux-toastr";
import qs from "query-string";
import {Link} from "react-router-dom";
import fs from "file-saver";

import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import Pagination from "../../components/Pagination";
import SortableTable from "../../components/simple/SortableTable/SortableTable";
import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {updateFiltersStateless, yesNoOptions} from "../../helpers/filters";
import Dropdown, {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";

import "./PermissionsList.css";


export class PermissionsList extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  static tableColumns = [
    {
      name: "username",
      title: "Username",
      headerProps: {
        textAlign: "center",
        width: 4
      }
    },
    {
      name: "isActive",
      title: "Active",
      headerProps: {
        textAlign: "center",
        width: 1
      }
    },
    {
      name: "isSuperuser",
      title: "Superuser",
      headerProps: {
        textAlign: "center",
        width: 1
      }
    },
    {
      name: "isStaff",
      title: "Staff",
      headerProps: {
        textAlign: "center",
        width: 1
      }
    },
    {
      name: "hasUserDataAccess",
      title: "User data access",
      headerProps: {
        textAlign: "center",
        width: 1
      }
    },
    {
      name: "specialCompanies",
      title: "Coordinator in",
      headerProps: {
        textAlign: "center",
        width: 2
      }
    },
    {
      name: "action",
      title: "Action",
      headerProps: {
        textAlign: "center",
        width: 1
      }
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      displaySpinner: false,
      filters: {},
      filtersOptions: {
        company: []
      },
      // Saved in state to debounce search.
      name: _.get(qs.parse(props.location.search), "name") || "",
      totalPages: 1,
      usersPermissions: []
    };
    this.arrayFilters = ["company", "special_company"];
    this.defaultFilters = {order_by: "username", page: 1};
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
  }

  componentDidMount() {
    this.getFilterOptions();
    super.componentDidMount();
  }

  getFilterOptions = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        this.parseFilterOptions("company", response.data, "name", "name", "name", false);
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        this.parseFilterOptions("type", response.data, "text", "text", "text", false);
      });
  };

  getData = () => {
    const params = this.state.filters;
    ajax.get(config.USERS_PERMISSIONS, {params})
      .then((response) => {
        this.setState({
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          usersPermissions: response.data.results
        }, () => {
          this.setState({displaySpinner: false});
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  };

  exportPermissions = () => {
    this.setState({exportLoading: true});
    const params = this.state.filters;
    ajax.get(
      config.USER_EXPORT_PERMISSIONS,
      {
        params,
        responseType: "arraybuffer"
      }
    ).then((result) => {
      this.setState({exportLoading: false});
      const blob = new Blob(
        [result.data],
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"}
      );
      fs.saveAs(blob, result.headers["x-filename"]);
    });
  };

  renderFilter() {
    const params = this.state.filters;
    return (
      <Menu secondary className="filter-menu">
        <Form className="filter-form">
          <FormDropdown
            multiple selection fluid search
            label="Company"
            placeholder="All"
            className="company-filter"
            options={this.state.filtersOptions.company || []}
            onChange={(e, data) => this.changeFilterInstantly({company: data.value})}
            value={params.company || []}
          />
          <FormDropdown
            multiple selection fluid search
            label="Coordinator in"
            placeholder="None"
            className="company-filter"
            options={this.state.filtersOptions.company || []}
            onChange={(e, data) => this.changeFilterInstantly({special_company: data.value})}
            value={params.special_company || []}
          />
          <FormDropdown
            selection fluid
            label="Is active"
            placeholder="All"
            className="active-filter"
            options={yesNoOptions}
            onChange={(e, data) => this.changeFilterInstantly({is_active: data.value})}
            value={params.is_active !== undefined ? params.is_active : null}
          />
          <FormDropdown
            selection fluid
            label="Is superuser"
            placeholder="All"
            className="superuser-filter"
            options={yesNoOptions}
            onChange={(e, data) => this.changeFilterInstantly({is_superuser: data.value})}
            value={params.is_superuser !== undefined ? params.is_superuser : null}
          />
          <FormDropdown
            selection fluid
            label="Is staff"
            placeholder="All"
            className="staff-filter"
            options={yesNoOptions}
            onChange={(e, data) => this.changeFilterInstantly({is_staff: data.value})}
            value={params.is_staff !== undefined ? params.is_staff : null}
          />
          <FormDropdown
            selection fluid
            label="User Type"
            placeholder="All"
            options={this.state.filtersOptions.type || []}
            onChange={(e, data) => this.changeFilterInstantly({type: data.value})}
            value={params.type || null}
          />
          <Form.Button
            fluid
            color="red"
            className="clear-filters datahive-button"
            content="Clean"
            onClick={this.resetFilters}/>
        </Form>
      </Menu>);
  }

  render() {
    const dataLoaded = !!this.state.usersPermissions;
    const params = this.state.filters;
    let rows = <Table.Row>
      <Table.Cell colSpan={7}><LoaderSpinner/></Table.Cell>
    </Table.Row>;

    if (dataLoaded && !this.state.displaySpinner) {
      rows = _.map(this.state.usersPermissions, (userPermissions) => {
        return (
          <UserPermissions
            key={`UserPermissions-${userPermissions.id}`}
            data={userPermissions}
            companyOptions={this.state.filtersOptions.company}
          />
        );
      });
    }

    return (
      <div className={"container"}>
        <Segment.Group className={"users-permissions-list"}>
          <Segment.Group horizontal className="filter-segment">
          <Segment textAlign="center" className="datahive-segment">
            <Input
              fluid
              className="name-filter"
              icon="search"
              iconPosition="left"
              placeholder="Search by first name, last name, username, email, phone number or user hash code..."
              value={this.state.name || ""}
              onChange={(e, data) =>
                this.searchChange(data.value, "name")
              }
              action={
                <Popup
                  on="click"
                  position="bottom right"
                  className="filter-popup"
                  content={this.renderFilter()}
                  trigger={
                    <Button
                      color="dark-grey"
                      className="filter-button datahive-button"
                      size="tiny"
                      floated="right"
                      content="More"
                      icon="filter"
                    />
                  }
                />
              }
            />
          </Segment>
          <Segment className="export-segment datahive-segment" textAlign="center">
            <Button
              fluid
              color="black"
              className="export-button datahive-button"
              loading={this.state.exportLoading}
              onClick={this.exportPermissions}
            >
              Export users
            </Button>
          </Segment>
          </Segment.Group>
          <Segment className="table-segment datahive-segment">
            <SortableTable
              columns={PermissionsList.tableColumns}
              tableProps={{compact: true, className: "SortableTable"}}>
              <Table.Body>
                {rows}
              </Table.Body>
            </SortableTable>
          </Segment>
          <Segment textAlign="center">
            <Pagination
              activePage={params.page}
              onPageChange={(e, {activePage}) =>
                this.changeFilterInstantly({page: activePage})}
              totalPages={this.state.totalPages}
            />
          </Segment>
        </Segment.Group>
      </div>
    );
  }
}

const UserPermissions = ({data, companyOptions}) => {
  const [currentData, setCurrentData] = useState(data);
  const [latestData, setLatestData] = useState(data);
  useEffect(() => setCurrentData(data), [data]);

  const onSave = () => {
    if (!currentData.has_user_data_access) {
      currentData.confirmed_access_to_users_data = false;
    }
    delete currentData['company']
    delete currentData['type']

    ajax.patch([config.USER_PERMISSIONS_DETAIL, data.id], {data: currentData})
      .then((response) => {
        setLatestData(response.data);
        toastr.success("Success!", "Permissions saved!");
      })
      .catch(() => {
        toastr.error("Error! Unable to change permissions.");
      });
  };

  return (
    <Table.Row key={data.id}>
      <Table.Cell textAlign={"center"}>
        <Link to={`/user/profile/${currentData.username}`}>
          {
            currentData.username.length > 30 ?
              `${currentData.username.substring(0, 30)}...`
              : currentData.username
          }
        </Link>
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Radio
          toggle
          checked={currentData.is_active}
          onChange={
            () => setCurrentData({
              ...currentData,
              is_active: !currentData.is_active
            })
          }
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Radio
          toggle
          checked={currentData.is_superuser}
          onChange={
            () => setCurrentData({
              ...currentData,
              is_superuser: !currentData.is_superuser
            })
          }
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Radio
          toggle
          checked={currentData.is_staff}
          onChange={
            () => setCurrentData({
              ...currentData,
              is_staff: !currentData.is_staff
            })
          }
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Radio
          toggle
          checked={currentData.has_user_data_access}
          onChange={
            () => setCurrentData({
              ...currentData,
              has_user_data_access: !currentData.has_user_data_access
            })
          }
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Dropdown
          placeholder={"None"}
          selection
          multiple
          fluid
          options={companyOptions || []}
          value={currentData.special_companies}
          onChange={
            (e, {value}) => setCurrentData({
              ...currentData,
              special_companies: value
            })
          }
        />
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Button
          compact
          disabled={_.isEqual(latestData, currentData)}
          content={"Save"}
          onClick={onSave}
        />
      </Table.Cell>
    </Table.Row>
  );
};
UserPermissions.propTypes = {
  companyOptions: PropTypes.array,
  data: PropTypes.object
};
export default PermissionsList;
