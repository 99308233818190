/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import _ from "lodash";
import {Pagination} from "semantic-ui-react";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";

import Widget from "../../Widget";
import Segment from "./Segment";

import "./RectangleSegmentationWidget.css";

class RectangleSegmentationWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      segmentsOnPages: {},
      activePage: 1,
    };
    this.image = React.createRef();
  }

  componentDidMount() {
    this.registerEmptyValid();
  }

  isValid = () => {
    return true;
  };

  isEmpty = () => {
    return Object.keys(_.get(this.props, "value.segmentsOnPages", {})).length === 0;
  };

  updateSegment = (segment, idx) => {
    let widgetVal = _.cloneDeep(this.props.value);
    let segmentsOnPages = widgetVal.segmentsOnPages;
    segmentsOnPages[this.state.activePage][idx] = segment;

    this.props.update(widgetVal, this.props.componentId);
  };

  removeSegment = (idx) => {
    let widgetVal = _.cloneDeep(this.props.value);
    let segmentsOnPages = widgetVal.segmentsOnPages;
    segmentsOnPages[this.state.activePage.toString()].splice(idx, 1);
    if (segmentsOnPages[this.state.activePage.toString()].length === 0) {
      delete segmentsOnPages[this.state.activePage.toString()];
    }

    this.props.update(widgetVal, this.props.componentId);
  };

  addSegment = (e, data) => {
    let newSegment = {
      type: data.type,
      width: 90,
      height: 20,
      x: e.clientX - this.image.current.getBoundingClientRect().x,
      y: e.clientY - this.image.current.getBoundingClientRect().y,
      uuid: _.uniqueId(),
    };
    let widgetVal = _.cloneDeep(this.props.value);
    if (!widgetVal.segmentsOnPages) {
      widgetVal.segmentsOnPages = {};
    }
    let segmentsOnPages = widgetVal.segmentsOnPages;
    if (!segmentsOnPages[this.state.activePage]) {
      segmentsOnPages[this.state.activePage] = [newSegment];
    } else {
      segmentsOnPages[this.state.activePage].push(newSegment);
    }
    this.props.update(widgetVal, this.props.componentId);
  };

  onPageChange = (e, data) => {
    this.setState({activePage: data.activePage});
  };

  renderMenuItems = (types) => {
    return _.map(types, (type) =>
      <MenuItem
        data={{"type": type.name || type}}
        onClick={this.addSegment}
        key={type.name || type}
      >
        Add {type.name || type}
      </MenuItem>
    );
  };

  renderSegments = (segments) => {
    return _.map(segments, (seg, idx) =>
      <Segment
        key={seg.uuid}
        idx={idx}
        updateSegment={(segment) => this.updateSegment(segment, idx)}
        segment={seg}
        remove={() => {
          this.removeSegment(idx);
        }}
      />
    );
  };

  render() {
    let config = this.props.widgetConfig;
    let images = _.get(this.props, "value.images", []);
    let segments = _.get(this.props, `value.segmentsOnPages[${this.state.activePage}]`, []);

    return (
      <div className="ImageSegmentationWidget Widget">
        <div>
          <ContextMenuTrigger
            holdToDisplay={-1}
            id="context-menu-id">
            <div
              className="image-container"
              ref={this.image}
            >
              <img
                src={images[this.state.activePage-1]}
                alt={""}
              />
              {this.renderSegments(segments)}
            </div>
          </ContextMenuTrigger>
        </div>
        <ContextMenu id="context-menu-id">
          {this.renderMenuItems(config.segmentTypes)}
        </ContextMenu>
        <Pagination activePage={this.state.activePage} totalPages={images.length} onPageChange={this.onPageChange} />
      </div>
    );
  }
}

export default RectangleSegmentationWidget;
