import _ from "lodash";

import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import Wikis from "./Wikis";

import PropTypes from "prop-types";
import RichTextEditor from "../../../../components/RichTextEditor";
import "./Detail.css";

export default class Description extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    faq: PropTypes.array,
    tutorial: PropTypes.array,
    updateTask: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isEvaluationEnable: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps);
  }

  render() {
    const errors = _.get(this.props.errors, "description");

    return (
      <div className={"TaskDetailAdmin"}>
        <Form>
          {errors && (
            <div className="descError">This field may not be blank.</div>
          )}
          <Form.Field
            className={errors ? "tab-errors description" : "description"}
          >
            <RichTextEditor
              height="auto"
              content={this.props.description}
              onChange={(content) =>
                this.props.updateTask({
                  description: content,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <Wikis
              onChange={(newFaq) => this.props.updateTask({ faq: newFaq })}
              title={"FAQs"}
              type={"faq"}
              wikis={_.get(this.props, "faq", [])}
              errors={this.props.errors}
              isEvaluationEnable={this.props.isEvaluationEnable}
            />
          </Form.Field>
          <Form.Field>
            <Wikis
              onChange={(newTutorial) =>
                this.props.updateTask({ tutorial: newTutorial })
              }
              title={"Tutorials"}
              type={"tutorial"}
              wikis={_.get(this.props, "tutorial", [])}
              errors={this.props.errors}
              isEvaluationEnable={this.props.isEvaluationEnable}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}
