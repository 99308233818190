import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { Icon, Popup, Table } from "semantic-ui-react";
import UserTaskBase from "./UserTaskBase";
import PropTypes from "prop-types";

export default class UserTask extends React.Component {
  static propTypes = {
    handleCheckboxClick: PropTypes.func.isRequired,
    idx: PropTypes.number,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    setModified: PropTypes.func.isRequired,
    usernameOptions: PropTypes.array.isRequired,
    usertask: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (!_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    );
  }

  compareLastActivity = (lastAnswer, tutorialDisplay) => {
    const lastestAnswer = lastAnswer ? moment(lastAnswer).format("YYYY-MM-DDTHH:mm:ss[Z]") : "";
    const diplayAnswer = lastestAnswer ? moment(lastestAnswer).format("DD.MM.YYYY HH:mm") + "\n(last answer)" : "";
    const diplayTutorial = tutorialDisplay ? moment(tutorialDisplay).format("DD.MM.YYYY HH:mm") + "\n(tutorial display)" : "";

    if (moment(lastestAnswer) > moment(tutorialDisplay)) {
      return diplayAnswer;
    } else if (moment(lastestAnswer) < moment(tutorialDisplay)) {
      return diplayTutorial;
    } else if (lastAnswer) {
      return diplayAnswer;
    } else if (tutorialDisplay) {
      return diplayTutorial;
    }
    return "";
  }

  render() {
    const { usertask } = this.props;

    let status, statusContent;
    if (usertask.resignation) {
      status = "resigned";
      statusContent = `Resigned on: ${usertask.resignation}`;
    } else if (usertask.blocked) {
      status = "blocked";
      statusContent = "Blocked";
    } else if (usertask.started) {
      status = "started";
      statusContent = "Started";
    } else if (usertask.id) {
      status = "assigned";
      statusContent = "Assigned";
    } else {
      status = "template";
      statusContent = "Unassigned";
    }

    const extraColumns = <>
      <Table.Cell>{this.compareLastActivity(usertask.last_activity, usertask.displayed_tutorial_datetime)}
      </Table.Cell>
      <Table.Cell textAlign={"center"}>
        <Popup
          content={statusContent}
          trigger={
            <Icon
              className={`status-${status}`}
              name={"handshake outline"}
              size={"large"}
            />
          } />
      </Table.Cell>
    </>;

    let actions = null;
    if (usertask.username) {
      actions = <>
        <Popup
          content={"Block user"}
          trigger={
            <Icon
              name={"minus circle"}
              size={"large"}
              color={usertask.blocked ? "red" : "grey"}
              onClick={() => this.props.onChange(`usertasks.${this.props.idx}.blocked`, !usertask.blocked)}
            />
          }
        />
        <Icon
          name={"trash alternate"}
          size={"large"}
          color={"red"}
          onClick={() => this.props.onChange(`usertasks.${this.props.idx}.toBeDeleted`, !usertask.toBeDeleted)}
        />
      </>;
    }

    return (
      <UserTaskBase
        actions={actions}
        collectionName={"usertasks"}
        extraColumns={extraColumns}
        idx={this.props.idx}
        row={this.props.usertask}
        handleCheckboxClick={this.props.handleCheckboxClick}
        isChecked={this.props.isChecked}
        onChange={this.props.onChange}
        setModified={this.props.setModified}
        usernameOptions={this.props.usernameOptions}
      />
    );
  }
}
