import React from "react";
import {Button, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {toastr} from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import FilteredComponent from "../../../components/FilteredComponent";
import ContainerList from "../../../components/ContainerList";
import LoaderSpinner from "../../../components/LoaderSpinner";


class ModulesList extends FilteredComponent {
  static propTypes = {
    divisions: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.emptyModule= {
      id: null,
      name: "",
      description: "",
      coordinators: [],
      project: null
    };

    this.state = {
      modules: [],
      allUsers: [],
      displaySpinner: true,
      projects: [],
      totalPages: 1,
      filters: {
        page: 1,
      },
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getData = () => {
    const params = (this.state.filters ? this.state.filters : this.getFilters());
    params.page = params.page ? params.page : 1;

    ajax.get(config.MODULES, {params})
      .then((response) => {
        this.setState({
          modules: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          displaySpinner: false
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  }
  pageChange = (e, {activePage}) => {
    this.updateFilters({page: activePage});
  };

    renderRow = (item) => {
    const {history} = this.props;
    return (
      <Table.Row
        key={item.id}
        onClick={() => history.push(urls.MODULE_DETAIL.replace(":id", item.id))}
      >
        <Table.Cell className={"list-item clickable"}>
          {item.name}
        </Table.Cell>
        <Table.Cell>{item.project}</Table.Cell>
      </Table.Row>
    );
  }


  render() {
    let columns = [
      {name: "name", title: "Name", headerProps: {width: 8}},
      {name: "project", title: "Project", headerProps: {width: 4}},
    ];

    const button = <Link to={urls.MODULE_DETAIL_NEW}>
      <Button
        className={"ui button new-btn datahive-button"}
        color="yellow"
        floated="right"
        content={"Add module"}
      />
    </Link>;

    if (this.state.displaySpinner) {
      return (
        <div className="tab-panel">
          <LoaderSpinner/>
        </div>
      );
    }

    return <ContainerList
      renderRow={this.renderRow}
      items={this.state.modules}
      columns={columns}
      button={button}
      totalPages={this.state.totalPages}
      pageChange={this.pageChange}
      filters={this.state.filters.page || 1}
    />;
  }
}

export default ModulesList;
