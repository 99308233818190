// will be reused for all login related views after UI refactor
import {Grid} from "semantic-ui-react";
import React from "react";
import PropTypes from "prop-types";

export const AuthView = ({children, wide=false}) => {
  const wideInput = wide;
  return (
    <Grid
      columns={3}
      className="password-reset"
    >
      <Grid.Column width={wideInput ? 3 : undefined}></Grid.Column>
      <Grid.Column textAlign="center" width={wideInput ? 10 : undefined}>
        {children}
      </Grid.Column>
      <Grid.Column width={wideInput ? 3 : undefined}></Grid.Column>
    </Grid>
  );
};

AuthView.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool
};
