import * as actionType from "../actions/types/types_auth";


const initState = {
  username: null,
  id: null,
  is_logged: false,
  is_company_coordinator: false,
  is_company_normal_user: false,
  is_moderator: false,
  is_normal_user: false,
  is_staff: false,
  is_superuser: false,
  is_external: false,
  auto_login_path: undefined,
  session_id: "",
  has_user_data_access: false,
  confirmed_access_to_users_data: false
};

export default function (state = initState, action) {
  let auth;
  switch (action.type) {
  case actionType.LOGIN_FAILURE:
    return state;

  case actionType.SET_FRONTEND_USER_PERMS:
    return {
      ...state,
      ...action.perms,
      is_logged: true
    };

  case actionType.LOGOUT:
    return Object.assign({}, initState);

  case actionType.STORE_PATH_FOR_AUTOLOGIN:
    if (!!action.auto_login_path) {
      auth = {
        ...state,
        auto_login_path: action.auto_login_path,
      };
      return auth;
    }
    return state;

  case actionType.CLEAR_PATH_AFTER_AUTOLOGIN:
    if (!!action.auto_login_path) {
      auth = {
        ...state,
        auto_login_path: undefined,
      };
      return auth;
    }
    return state;

  case actionType.CHANGE_CONFIRMATION_ACCESS_TO_USERS_DATA:
    auth = {
      ...state,
      confirmed_access_to_users_data: action.data,
    };
    return auth;

  default:
    return state;
  }
}
