import React, {Component} from "react";
import PropTypes from "prop-types";


class Connector extends Component {
  static propTypes = {
    connector: PropTypes.object.isRequired,
    target: PropTypes.object.isRequired,
    localIdx: PropTypes.number.isRequired,
    rowIdx: PropTypes.number.isRequired,
    addRef: PropTypes.func.isRequired,
    clickHandler: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    r: PropTypes.object.isRequired, // Begin range.
    baseStrokeColorName: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
  };

  render() {
    return (

      <g
        className={`${this.props.connector.id}`}
        key={this.props.localIdx}
        ref={this.props.addRef}
        data-from={this.props.r.id}
        data-to={this.props.target.id}
        data-rowidx={this.props.rowIdx}
      >
        <path
          className={""}
          stroke={this.props.colors[this.props.baseStrokeColorName]} strokeWidth="1" fill="none"
          y={0}
          d={"M 0 0 l 10 0"} //irrelevant, gets overwritten later when adjusting position
          strokeDasharray={this.props.connector.isFragment ? "6,2,2,2" : null}
          key={this.props.localIdx.toString() + "path"}
          markerEnd={`url(#arrow-${this.props.baseStrokeColorName})`}
        />
        <text
          onClick={this.props.clickHandler}
          onMouseLeave={this.props.onMouseLeave}
          onMouseOver={this.props.onMouseOver}
          y="0" fontSize="12px" space="preserve"
        >
          {this.props.connector.descr || "connection"}
        </text>
      </g>
    );
  }
}

export default Connector;
