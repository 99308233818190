import React from "react";
import PropTypes from "prop-types";
import "./ContextMenu.css";

const {Menu: {ContextMenu, MenuItem, SubMenu}} = require("react-data-grid-addons");


export default class SpreadsheetContextMenu extends React.Component {
  static propTypes = {
    onRowDelete: PropTypes.func.isRequired,
    onRowInsertAbove: PropTypes.func.isRequired,
    onRowInsertBelow: PropTypes.func.isRequired,
    rowIdx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    idx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    add_row: PropTypes.bool,
    remove_row: PropTypes.bool,
  };

  onRowDelete = (e, data) => {
    if (typeof (this.props.onRowDelete) === "function") {
      this.props.onRowDelete(e, data);
    }
  };

  onRowInsertAbove = (e, data) => {
    if (typeof (this.props.onRowInsertAbove) === "function") {
      this.props.onRowInsertAbove(e, data);
    }
  };

  onRowInsertBelow = (e, data) => {
    if (typeof (this.props.onRowInsertBelow) === "function") {
      this.props.onRowInsertBelow(e, data);
    }
  };

  render() {
    const {idx, id, rowIdx} = this.props;

    const add_row = this.props.add_row ?
      <AddRowMenu
        idx={idx}
        rowIdx={rowIdx}
        onRowInsertAbove={this.onRowInsertAbove}
        onRowInsertBelow={this.onRowInsertBelow}
      /> :
      "";
    const remove_row = this.props.remove_row ?
      <RemoveRowMenu
        idx={idx}
        rowIdx={rowIdx}
        onRowDelete={this.onRowDelete}
      /> :
      "";

    return (
      <ContextMenu id={id} className="ContextMenu">
        {add_row}
        {remove_row}
      </ContextMenu>
    );
  }
}

function RemoveRowMenu(props) {
  const rowIdx = props.rowIdx;
  const idx = props.idx;

  return (
    <MenuItem data={{rowIdx, idx}} onClick={props.onRowDelete}>Delete Row</MenuItem>
  );
}

RemoveRowMenu.propTypes = {
  rowIdx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  idx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onRowDelete: PropTypes.func.isRequired
};

function AddRowMenu(props) {
  const rowIdx = props.rowIdx;
  const idx = props.idx;
  return (
    <SubMenu title="Insert Row">
      <MenuItem data={{rowIdx, idx}} onClick={props.onRowInsertAbove}>Above</MenuItem>
      <MenuItem data={{rowIdx, idx}} onClick={props.onRowInsertBelow}>Below</MenuItem>
    </SubMenu>
  );
}

AddRowMenu.propTypes = {
  rowIdx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  idx: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onRowInsertAbove: PropTypes.func.isRequired,
  onRowInsertBelow: PropTypes.func.isRequired
};
