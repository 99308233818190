import React, {Component} from "react";
import {Container, Icon, Pagination as SemanticPagination} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./Pagination.css";


export default class Pagination extends Component {
  static propTypes = {
    activePage: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired
    ]),
    onPageChange: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    boundaryRange: PropTypes.number,
    siblingRange: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 832
    };
  }

  // eslint-disable-next-line
  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    if (!this.props.activePage) {
      /* Warning: Encountered two children with the same key, `ellipsisItem-NaN`. */
      return "";
    }
    const boundaryRange = this.state.isMobile ? 1 : 2;
    const siblingRange = this.state.isMobile ? 1 : 2;
    const firstItem = this.state.isMobile ? null : {content: <Icon name="angle double left"/>, icon: true};
    const lastItem = this.state.isMobile ? null : {content: <Icon name="angle double right"/>, icon: true};
    const prevItem = this.state.isMobile ? null : {content: <Icon name="angle left"/>, icon: true};
    const nextItem = this.state.isMobile ? null : {content: <Icon name="angle right"/>, icon: true};
    return (
      <Container textAlign={"center"} className="Pagination">
        <SemanticPagination
          activePage={this.props.activePage || 1}
          boundaryRange={this.props.boundaryRange || boundaryRange}
          onPageChange={this.props.onPageChange}
          siblingRange={this.props.siblingRange || siblingRange}
          totalPages={this.props.totalPages}
          ellipsisItem={{content: <Icon name="ellipsis horizontal"/>, icon: true}}
          firstItem={firstItem}
          lastItem={lastItem}
          prevItem={prevItem}
          nextItem={nextItem}
        />
      </Container>
    );
  }
}
