export var defaultSIOSTRAConfig = {
  enableSelectionTrimming: false,
  breakLinesOnlyOnNewLineCharacters: false,
  enableConnectorNotes: true,
  enableRangeNotes: true,
  inheritFields: [
    "color", "attributeSelectConfig"
  ],
  rangeTypes: [
    {
      title: "Phrases",
      value: "v0",
      color: "Red",
      children: [
        {
          title: "active aggression",
          value: "v0-0",
          attributeSelectConfig: {
            multiple: true,
            options: [
              {
                value: "v1",
                title: "Opti1",
              },
            ]
          },
        },
      ]
    },
  ],
  connectorTypes: [
    {
      title: "Type 1",
      value: "Type1",
    },
    {
      title: "Type2",
      value: "Type2",
      attributeSelectConfig: {
        multiple: false,
        options: [
          {
            value: "Type21",
            title: "Type21",
          }
        ]
      },
    },
  ],
};
