import React from "react";
import "konva/lib/Group";
import "konva/lib/shapes/Circle";
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Label";
import "konva/lib/shapes/Line";

import Widget from "../Widget";
import RectangleSegmentationWidget from "./RectangleSegmentationWidget/RectangleSegmentationWidget";
import PolygonSegmentationWidget from "./PolygonSegmentationWidget/PolygonSegmentationWidget";
import ShapeSegmentationWidget from "./ShapeSegmentationWidget/ShapeSegmentationWidget";

import "./ImageSegmentationWidget.css";

const ImageSegmentationWidget = (props) => {
  switch (props.widgetConfig.segmentationMethod) {
  case "Polygons":
    return <PolygonSegmentationWidget {...props}/>;
  case "Shape":
    return <ShapeSegmentationWidget {...props}/>;
  case "Rectangles":
  default:
    return <RectangleSegmentationWidget {...props}/>;
  }
};

ImageSegmentationWidget.getDefaultConfig = () => ({
  segmentationMethod: "Rectangles",
  segmentTypes: []
});

ImageSegmentationWidget.propTypes = {
  ...Widget.propTypes
};

export default ImageSegmentationWidget;
