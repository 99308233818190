import React, {Component} from "react";
import {Container} from "semantic-ui-react";

import PropTypes from "prop-types";

export default class Draggable extends Component {
    static propTypes = {
      objType: PropTypes.string,
      wraps: PropTypes.object
    };

    constructor(props) {
      super(props);
      this.handleDragStart = this.handleDragStart.bind(this);
    }

    handleDragStart(e) {
      e.dataTransfer.setData("create", true);
      e.dataTransfer.setData("type", this.props.objType);
    }

    render() {
      return (
        <Container
          draggable
          onDragStart={this.handleDragStart}
        >
          {this.props.wraps}
        </Container>
      );
    }
}

