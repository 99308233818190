import React, { Component } from "react";
import PropTypes from "prop-types";
import {Icon} from "semantic-ui-react";
import {Link, withRouter} from "react-router-dom";

import "./Tab.css";
import {getClass} from "../helpers/utils";
import config from "../config/config";

/* eslint-disable react/prop-types */
export const Label = (props) => (
  <React.Fragment>
    {props.icon ? <Icon name={props.icon} /> : null}
    {props.name}
  </React.Fragment>
);
/* eslint-enable react/prop-types */

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.number.isRequired,
    label: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isWithoutUrl: PropTypes.bool,
    customClass: PropTypes.string,
    history: PropTypes.object.isRequired,
    tabUrl: PropTypes.string
  };

  handleClick = (event) => {
    event.preventDefault();
    // change target to anchor if clicked on child element
    if (!event.target.hasAttribute("href")) {
      event.target = event.target.parentElement;
    }
    let url = event.target.getAttribute("href");
    url = url.split(config.VERSION).pop(); // Remove VERSION prefix. It's unwanted in history.push().
    this.props.history.push(url);
  };

  render() {
    const {
      activeTab,
      label,
      index,
      tabUrl,
      isWithoutUrl,
      customClass,
    } = this.props;

    const active = activeTab !== -1 ? activeTab : 0;
    const clsActive = active === index ? "tab-list-active" : "";
    const clsCustom = customClass ? customClass : "";

    const className = getClass(["tab-list-item", clsActive, clsCustom]);

    return (
      <li className={"tab-list"}>
        <Link
          className={className}
          to={isWithoutUrl ? `#${index}` : tabUrl}
          onClick={this.handleClick}
        >
          <Label {...label} />
        </Link>
      </li>
    );
  }
}

export default withRouter(Tab);
