import * as actionType from "../actions/types/types_errors";

const initState = [];

export default function(state=initState, action) {
  switch (action.type) {
  case actionType.ERRORS_NEW:
    return action.errors;
  case actionType.ERRORS_CLEAR:
    return initState;
  default:
    return state;
  }
}
