import _ from "lodash";
import React, {Component} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {Button, Form, Segment} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";

import LoaderSpinner from "../../../components/LoaderSpinner";
import ajax from "../../../helpers/ajax";
import {getPossibleDropdownValues2} from "../../../helpers/utils";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import DragAndDropSelector from "../../../components/simple/DragAndDropSelector/DragAndDropSelector";
import ContainerDetail from "../../../components/ContainerDetail";


class DivisionDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.emptyDivision = {
      id: null,
      name: "",
      users: [],
      timezone_name: ""
    };
    this.state = {
      displaySpinner: true,
      division: {...this.emptyDivision},
      displayDeleteModal: false,
      timezones: []
    };
  }

  componentDidMount() {
    this.getData();
    this.getOptions();
  }

  getData = () => {
    if (!this.props.location.pathname.includes(urls.DIVISION_DETAIL_NEW)) {
      ajax.get([config.DIVISION_DETAIL, this.props.match.params.id])
        .then((response) => {
          this.setState({
            division: response.data,
            displaySpinner: false,
          });
        });
    } else {
      this.setState({
        displaySpinner: false,
      });
    }
  };

  getOptions = () => {
    let options = [];
    ajax.get(config.USER_LIST_ALL_USERS)
      .then((response) => {
        this.setState({
          allUsers: response.data,
        });
      });
    options = getPossibleDropdownValues2(moment.tz.names());
    this.setState({timezones: options});
  };

  save = () => {
    let data = _.cloneDeep(this.state.division);
    if (!this.props.location.pathname.includes(urls.DIVISION_DETAIL_NEW)) {
      ajax.patch([config.DIVISION_DETAIL, data.id], {data})
        .then(() => {
          toastr.success("Success", "Division updated!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    } else {
      ajax.post(config.DIVISIONS, {data})
        .then(() => {
          this.props.history.push(urls.DIVISIONS);
          toastr.success("Success", "Division saved!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    }
  }

  delete = () => {
    let data = _.cloneDeep(this.state.division);
    if (!this.props.location.pathname.includes(urls.DIVISION_DETAIL_NEW)) {
      ajax.delete([config.DIVISION_DETAIL, data.id], {data})
        .then(() => {
          this.props.history.push(urls.DIVISIONS);
          toastr.success("Success", "Division deleted!");
        })
        .catch((error) => {
          toastr.error("Error!", error.response);
        });
    } else {
      toastr.error("Error!", "Division doesn't exist");
    }
  }

  changeDivision = (newVal) =>
    this.updateDivision({
      ...this.state.division,
      ...newVal
    });

  updateDivision = (division) => {
    this.setState({division});
  };

  toggleModal = () => {
    this.setState((prevState) => ({displayDeleteModal: !prevState.displayDeleteModal}));
  };

  render() {
    const {division, timezones, allUsers, displaySpinner,
      displayDeleteModal} = this.state;
    const {history} = this.props;
    const details = <Segment className="datahive-segment">
      <Form>
        <Form.Group>
          <Form.Field  width="5">
            <label>Division name</label>
            <Form.Input
              id="division"
              placeholder="Name"
              autoComplete="off"
              value={division.name}
              onChange={(e) => this.changeDivision({name: e.target.value})}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width="5">
            <label>Timezone name</label>
            <Form.Dropdown
              id="timezone"
              placeholder="Select Timezone"
              options={timezones}
              search selection
              value={division.timezone_name ? division.timezone_name : ""}
              onChange={(e, {value}) => this.changeDivision({timezone_name: value})
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field className={"DragAndDropSelectorGroup"}>
            <label>Users</label>
            {allUsers
              ? <DragAndDropSelector
                name={"users"}
                choices={allUsers || []}
                choiceValue={"username"}
                filterable
                selectAll
                afterSelectionChange={(usr) => this.changeDivision({users: usr})}
                selected={division.users || []}
              />
              : <LoaderSpinner/>
            }
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>;
    const panelRight =
      <Button color="green"
        className="datahive-button"
        onClick={this.save}
        content="Save"
      />;
    const panelLeft =
      <Button color="red"
        className="datahive-button"
        onClick={this.toggleModal}
        content="Delete"
      />;
    const title = "Are you sure you want to delete this division?";
    const buttons = <>
      <Button onClick={this.delete} content="Delete" color="red" className="datahive-button" id={"delete"} floated={"left"}/>
      <Button onClick={this.toggleModal} className="datahive-button" color="dark-grey" content="Cancel"/>
    </>;

    return <ContainerDetail
      details={details}
      url={urls.DIVISION_DETAIL}
      urlNew={urls.DIVISION_DETAIL_NEW}
      button={buttons}
      item={division}
      history={history}
      displaySpinner={displaySpinner}
      displayDeleteModal={displayDeleteModal}
      panelRight={panelRight}
      panelLeft={panelLeft}
      title={title}
      toggleModal={this.toggleModal}
    />;
  }
}

export default withRouter(DivisionDetail);
