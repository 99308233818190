/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";

import React from "react";
import {Container} from "semantic-ui-react";
import update from "immutability-helper";
import ReactDataGrid from "react-data-grid";
import {Menu, Toolbar} from "react-data-grid-addons";
import PropTypes from "prop-types";
import "./SpreadsheetWidget.css";
import ContextMenu from "./ContextMenu";
import Widget from "../Widget";

const {ContextMenuTrigger } = Menu;


class SpreadsheetWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    config: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        editor: PropTypes.object,
        width: PropTypes.number,
      })),
      rows: PropTypes.array,
      row_add: PropTypes.bool,
      remove_row: PropTypes.bool,
    }),
  };

  static getDefaultConfig = () => {
    return {
      columns: [],
      rows: [],
      add_row: false,
      remove_row: false,
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      sorting: {},
    };
  }

  componentDidMount() {
    this.registerEmptyValid();
  }

  isEmpty = () => {
    return false;
  };

  isValid = () => {
    return true;
  };

  getColumns = () => {
    return this.props.widgetConfig.columns.slice();
  };

  getRows = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    if (widgetVal) {
      return [...widgetVal];
    }
    return this.props.widgetConfig.rows ? this.props.widgetConfig.rows : [];
  };

  handleGridRowsUpdated = ({fromRow, toRow, updated}) => {
    const rows = this.getRows();

    for (let i = fromRow; i <= toRow; i++) {
      const rowToUpdate = rows[i];
      rows[i] = update(rowToUpdate, {$merge: updated});
    }

    this.update(rows);
  };

  insertRow = (newRowIndex) => {
    if (typeof newRowIndex === "object") {
      newRowIndex = newRowIndex.newRowIndex;
    }

    const newRow = {};

    const rows = this.getRows();
    rows.splice(newRowIndex, 0, newRow);
    this.setState({rows});

    this.update(rows);
  };

  insertRowAbove = (e, {rowIdx}) => {
    this.insertRow(rowIdx);
  };

  insertRowBelow = (e, {rowIdx}) => {
    this.insertRow(rowIdx + 1);
  };

  removeRow = (e, {rowIdx}) => {
    const rows = this.getRows();
    rows.splice(rowIdx, 1);

    this.update(rows);
  };

  getRowAt = (index) => {
    if (index < 0 || index > this.getSize()) {
      return undefined;
    }

    return this.getRows()[index];
  };

  getSize = () => {
    return this.getRows().length;
  };

  // TODO: Remove this comment if you want to enable sorting and filtering
  // handleFilterChange(filter) {
  //   let newFilters = Object.assign({}, this.state.filters);
  //   if (filter.filterTerm) {
  //     newFilters[filter.column.key] = filter;
  //   } else {
  //     delete newFilters[filter.column.key];
  //   }
  //   this.setState({ filters: newFilters });
  // };
  //
  // onClearFilters() {
  //   // all filters removed
  //   this.setState({filters: {}});
  // };
  //
  // handleGridSort(sortColumn, sortDirection) {
  //   const comparer = (a, b) => {
  //     if (sortDirection === 'ASC') {
  //       return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
  //     } else if (sortDirection === 'DESC') {
  //       return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
  //     }
  //   };
  //
  //   const rows = sortDirection === 'NONE' ? this.state.rows.slice(0) : this.state.rows.sort(comparer);
  //
  //   this.setState({ rows });
  // };

  render() {
    const config = this.props.widgetConfig;
    const onAddRow = config.add_row ? this.insertRow : null;
    const contextMenu = config.add_row || config.remove_row ?
      <ContextMenu
        onRowDelete={this.removeRow}
        onRowInsertAbove={this.insertRowAbove}
        onRowInsertBelow={this.insertRowBelow}
        add_row={config.add_row}
        remove_row={config.remove_row}
      /> :
      "";

    return (
      <Container className="SpreadsheetWiget Widget">
        <ReactDataGrid
          ref={ (node) => this.grid = node }
          enableCellSelect={true}
          columns={this.getColumns()}
          rowGetter={this.getRowAt}
          rowsCount={this.getSize()}
          onGridRowsUpdated={this.handleGridRowsUpdated}
          // onGridSort={this.handleGridSort}
          toolbar={<Toolbar
            onAddRow={onAddRow}
            // enableFilter={true}
          />}
          contextMenu={contextMenu}
          enableRowSelect={false}
          rowHeight={50}
          minHeight={600}
          rowScrollTimeout={200}
          RowsContainer={ContextMenuTrigger}
        />
      </Container>
    );
  }
}

export default SpreadsheetWidget;
