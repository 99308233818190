import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Icon, Button, Table, List, Divider, Header } from "semantic-ui-react";
import moment from "moment";

import "./ChangelogTaskModal.css";

export class ChangelogTaskModal extends Component {
  static propTypes = {
    allUsers: PropTypes.array,
    updateFields: PropTypes.object,
    onClose: PropTypes.func,
    task: PropTypes.object,
    modules: PropTypes.array,
    getData: PropTypes.func,
  };

  getModule = (key, value, elem) => {
    let module = this.props.modules.filter((item) => item.id === value[elem]);
    return module.length ? module[0].name : "";
  }

  getUser = (value, elem = false) => {
    const users = [];
    if (elem && Object.prototype.hasOwnProperty.call(value, elem)) {
      if (typeof value[elem] == "number") {
        let usr = this.props.allUsers.filter((item) => item.id === value[elem]);
        users.push(usr[0].username);
      } else if (typeof value[elem] == "object") {
        for (let element of value[elem]) {
          let usr = this.props.allUsers.filter((item) => item.id === element);
          users.push(usr[0].username);
        }
      } else {
        for (let element of value) {
          let usr = this.props.allUsers.filter((item) => item.id === element);
          users.push(usr[0].username);
        }
      }
    } else {
      for (let element of value) {
        let usr = this.props.allUsers.filter((item) => item.id === element);
        users.push(usr[0].username);
      }
    }
    let listUser = _.map(users, (user) => {
      return (<List.Item key={user}>{user}</List.Item>);
    });
    return <List>{listUser}</List>;
  }

  getWikis = (key, value, elem) => {
    if (key === "description") {
      return <div style={{ maxHeight: "150px", overflow: "auto" }}><span>{value[elem]}</span></div>;
    }
    if (key === "tutorials" || key === "faqs") {
      let listWikis;
      let wikis;
      wikis = _.map(value, (val, idx) => {
        listWikis = _.map(val[elem], (val, x) => {
          if (elem === "content") {
            return <div><div>{x}:</div><span>{val}</span></div>;
          } else {
            return <List.Item className={"ChangelogTaskModalItem"}
              key={idx + x}>{x}: <b>{this.convertTypeData(val)}</b></List.Item>;
          }
        });
        return <><List.Item>{key === "tutorials" ? "Tutorial" : "Faq"} : {listWikis}</List.Item>
          {value.length > 1 && <Divider fitted />}</>;
      });
      return <List>{wikis}</List>;
    }
  }

  convertTypeData = (val) => {
    if (val === true) {
      return "true";
    } else if (val === false) {
      return "false";
    } else {
      if (!!val && val.length > 10 && moment(val.slice(0, 10), "YYYY-MM-DD", true).isValid()) {
        return moment(val).format("YYYY-MM-DD HH:mm");
      }
      return val;
    }
  }

  getUserOrEvaluatorTasks = (key, value, elem) => {
    let usrtask;
    const listFields = ["username", "deadline", "max_questions", "blocked"];
    if (value) {
      usrtask = _.map(value, (val, idx) => {
        const listItemUt = _.map(val[elem], (val, item) => {
          if (listFields.includes(item)) {
            return <List.Item key={idx + item}>{item}: <b>{this.convertTypeData(val)}</b></List.Item>;
          }
        });
        return <List.Item key={idx + val[elem]}>
          {key === "usertasks" ? "Usertask" : "Evaluatortask"} : {listItemUt}
        </List.Item>;
      });
    }
    return <List>{usrtask}</List>;
  }

  checkTypeData = (key, value, item) => {
    if (typeof value[item] == "boolean") {
      return (value[item] ? "true" : "false");
    } else if (key === "users" || key === "evaluators" || key === "owner" || key === "samsung_coordinator"
      || key === "moderators") {
      return this.getUser(value, item);
    } else if (key === "usertasks" || key === "evaluatortasks") {
      return this.getUserOrEvaluatorTasks(key, value, item);
    } else if (key === "description" || key === "tutorials" || key === "faqs") {
      return this.getWikis(key, value, item);
    } else if (key === "module") {
      return this.getModule(key, value, item);
    } else if (key === "config" || key === "config_evaluation") {
      return <div className={"ChangelogTaskModalItem"}><span>{JSON.stringify(value[item])}</span></div>;
    } else {
      if (Object.prototype.hasOwnProperty.call(value, item) && !!value[item] && value[item].length > 10
        && moment(value[item].slice(0, 10), "YYYY-MM-DD", true).isValid()) {
        return moment(value[item]).format("YYYY-MM-DD HH:mm");
      }
      return value[item];
    }
  }

  onCloseModal = () => {
    this.props.onClose();
    this.props.getData();
  }

  render() {
    const content = Object.entries(this.props.updateFields).map(([key, value]) => {
      if (key === 'coordinators') {
        return null;
      }
      return (<Table.Row key={key}>
        <Table.Cell width={3}>
          {_.capitalize(key).split("_").join(" ")}
        </Table.Cell>
        <Table.Cell width={3} >
          {this.checkTypeData(key, value, "saved")}
        </Table.Cell>
        <Table.Cell width={3}>
          <div>
            {this.checkTypeData(key, value, "current")}
          </div>
        </Table.Cell>
      </Table.Row>);
    });

    const coordinators = Object.entries(this.props.updateFields).map(([key, value]) => {
      if (!(key === 'coordinators')) return null;
      const rows = this.getUser(value);
      return (
        <Table key={key}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Automatically added coordinators</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={key}>
              <Table.Cell>
                {rows}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )
    });

    return <Modal
      dimmer={true}
      open={true}>
      <Modal.Header>
        <Icon name={"external"} />
        Changes
      </Modal.Header>
      <Modal.Content>
        <Header as='h4'>{this.props.task.title}</Header>
        <Divider />
        <Table fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Field</Table.HeaderCell>
              <Table.HeaderCell width={3}>Old value</Table.HeaderCell>
              <Table.HeaderCell width={3}>New value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {content}
          </Table.Body>
        </Table>
        {coordinators}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          className="datahive-button"
          onClick={this.onCloseModal}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>;
  }
}

export default ChangelogTaskModal;
