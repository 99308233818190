import * as actionType from "../actions/types/types_top_menu";

const initState = true;

export default function(state=initState, action) {
  switch (action.type) {
  case actionType.SHOW_MENU:
    return true;
  case actionType.HIDE_MENU:
    return false;
  default:
    return state;
  }
}
