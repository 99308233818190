import _ from "lodash";
import React from "react";
import {toastr} from "react-redux-toastr";
import {Checkbox, Input, Table, Button} from "semantic-ui-react";
import qs from "query-string";

import config from "../../../config/config";
import FilteredComponent from "../../../components/FilteredComponent";
import LoaderSpinner from "../../../components/LoaderSpinner";
import SortableTable from "../../../components/simple/SortableTable/SortableTable";
import Pagination from "../../../components/Pagination";
import BottomBar from "../../../components/BottomBar";
import ModalConfirm from "../../../components/ModalConfirm";
import ajax from "../../../helpers/ajax";
import AnswerListRow from "./AnswerListRow";
import "./AnswerAdminListByTask.css";

export default class AnswerAdminListByTask extends FilteredComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      answers: null,
      question: _.get(qs.parse(props.history.location.search), "question") || "",
      id: _.get(qs.parse(props.history.location.search), "id") || "",
      user: _.get(qs.parse(props.history.location.search), "user") || "",
      is_answered: _.get(qs.parse(props.history.location.search), "is_answered") || "",
      totalPages: 1,
      checkedList: [],
      displayDeleteModal: false,
    };
  }

  getData = (filters = null) => {
    const params = filters ? filters : this.getFilters();
    this.setState({answers: null});
    ajax.get([config.ADMIN_ANSWERS_BY_TASK, this.props.match.params.taskSlug], {params})
      .then((response) => {
        if (response.data) {
          this.setState({
            answers: response.data.results,
            totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          }, () => {
            this.setState({display_spinner: false});
          });
        }
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  };

  getAllAnswers = () => {
    this.setState({answersToDelete: null});
    ajax.get([config.ADMIN_ALL_ANSWERS_BY_TASK, this.props.match.params.taskSlug])
      .then((response) => {
        if (response.data) {
          this.setState({displayDeleteAllModal: true,
            answersToDelete: response.data.results});
        }
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  };

  deleteAnswers = () => {
    const data = this.state.checkedList;
    ajax.delete([config.ADMIN_DELETE_ANSWERS_BY_TASK, this.props.match.params.taskSlug], {data})
      .then((response) => {
        this.setState({
          answers: response.data.results,
          checkedList: [],
          displayDeleteModal: false
        });
        toastr.success("Success!", "Answers deleted.");
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  deleteAll = () => {
    ajax.delete([config.ADMIN_DELETE_ALL_ANSWERS_BY_TASK, this.props.match.params.taskSlug])
      .then((response) => {
        this.setState({
          answers: response.data.results,
          displayDeleteAllModal: false
        });
        toastr.success("Success!", "All answers deleted.");
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  pageChange = (e, {activePage}) => {
    const newFilters = this.updateFilters({page: activePage});
    this.getData(newFilters);
  };

  handleChange = (e, {checked, id}) => {
    checked
      ? this.setState({checkedList: this.state.checkedList.concat(id)})
      : this.setState({checkedList: this.state.checkedList.filter((ids) => ids !== id)});
  }

  toggleModal = () => {
    this.setState((prevState) => ({displayDeleteModal: !prevState.displayDeleteModal}));
  }

  render() {
    const dataLoaded = !!this.state.answers;
    const filters = this.getFilters();
    const columns = [
      {name: "checkAll", title: "", unsortable: true, headerProps: {width: 1}},
      {name: "id", title: "Answer ID", headerProps: {width: 4}},
      {name: "question", title: "Question", headerProps: {width: 4}},
      {name: "user__username", title: "User", headerProps: {width: 6}},
      {name: "is_answered", title: "Is answered", headerProps: {width: 2}},
      {name: "is_active", title: "Is active", headerProps: {width: 2}},
      {name: "is_skipped", title: "Is skipped", headerProps: {width: 2}},
      {name: "is_first", title: "Is first", headerProps: {width: 2}}
    ];

    const contentModal = this.state.displayDeleteModal &&
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Answer Id</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(this.state.checkedList, (item) => {
            return (
              <Table.Row key={"ansId" + item}>
                <Table.Cell>{item}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>;

    const contentDeleteAllModal = this.state.displayDeleteAllModal && <div className="DeleteAllModal">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Answer Id</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(this.state.answersToDelete, (item) => {
            return (
              <Table.Row key={"ansId" + item}>
                <Table.Cell>{item}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table></div>;

    const buttonsDeleteAll = <>
      <Button
        onClick={this.deleteAll}
        content="I'm aware of the consequences"
        color="red"
        className="datahive-button"
        floated="left"/>
      <Button
        onClick={() => this.setState((prevState) => ({displayDeleteAllModal: !prevState.displayDeleteAllModal}))}
        className="datahive-button"
        color="dark-grey"
        content="Cancel"/></>;

    return (
      <div className={"AnswerAdminListByTaskContainer"}>
        <div className={"title"}>
          <span>Task&nbsp;</span>
          <span>{this.props.match.params.taskSlug}</span>
          <Button
            color="red"
            className="datahive-button"
            floated="right"
            onClick={this.getAllAnswers}
            content="Delete all answers"/>
        </div>
        <div className={"filters"}>
          <Input
            placeholder="Answer ID"
            onChange={(e, data) => {
              this.searchChange(data.value, "id");
            }}
            value={this.state.id}
          />
          <Input
            placeholder="Question ID"
            onChange={(e, data) => {
              this.searchChange(data.value, "question");
            }}
            value={this.state.question}
          />
          <Input
            placeholder="User"
            onChange={(e, data) => {
              this.searchChange(data.value, "user");
            }}
            value={this.state.user}
          />
          <Checkbox
            label="Is answered"
            className={"checkboxFilters"}
            onChange={(e, {checked}) => {
              this.changeFilterInstantly(checked ? {is_answered: checked} : {is_answered: ""});
            }}
            checked={!!filters.is_answered}
          />
          <Checkbox
            className={"checkboxFilters"}
            label="Is active"
            onChange={(e, {checked}) => {
              this.changeFilterInstantly(checked ? {is_active: checked} : {is_active: ""});
            }}
            checked={!!filters.is_active}
          />
          <Checkbox
            className={"checkboxFilters"}
            label="Is skipped"
            onChange={(e, {checked}) => {
              this.changeFilterInstantly(checked ? {is_skipped: checked} : {is_skipped: ""});
            }}
            checked={!!filters.is_skipped}
          />
          <Checkbox
            className={"checkboxFilters"}
            label="Is first"
            onChange={(e, {checked}) => {
              this.changeFilterInstantly(checked ? {is_first: checked} : {is_first: ""});
            }}
            checked={!!filters.is_first}
          />
        </div>
        <SortableTable
          columns={columns}
          sorted={_.get(filters, "order_by")}
          tableProps={{compact: true, className: "AnswerAdminListByTask"}}
          updateSorting={this.updateSorting}>
          <Table.Body>
            {!dataLoaded &&
            <Table.Row>
              <Table.Cell colSpan={8}><LoaderSpinner/></Table.Cell>
            </Table.Row>}
            {dataLoaded && _.map(
              this.state.answers, (a) =>
                <AnswerListRow
                  classname={"AnswerListRow"}
                  name={a.id}
                  checked={!!this.state.checkedList.find((id) => id === a.id)}
                  answer={a}
                  key={"answerId:" + a.id}
                  handleChange={this.handleChange}/>)}
          </Table.Body>
        </SortableTable>
        <Pagination
          activePage={filters.page || 1}
          onPageChange={this.pageChange}
          totalPages={this.state.totalPages}/>
        <BottomBar
          panelLeft={
            <Button
              color="red"
              onClick={this.toggleModal}
              content="Delete"
              size="large"/>}/>
        {this.state.displayDeleteModal && <ModalConfirm
          id={"deleteModal"}
          title="Are you sure you want to delete these answers?"
          content={contentModal}
          closeModal={this.toggleModal}
          button={<>
            <Button onClick={this.deleteAnswers} content="Delete" color="red" id={"delete"}/>
            <Button onClick={this.toggleModal} content="Cancel"/></>}/>}
        {this.state.displayDeleteAllModal && <ModalConfirm
          title="Are you sure you want to delete all answers in task?"
          content={contentDeleteAllModal}
          closeModal={() => this.setState((prevState) => ({displayDeleteAllModal: !prevState.displayDeleteAllModal}))}
          button={buttonsDeleteAll}/>}
      </div>
    );
  }
}
