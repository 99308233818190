import _ from "lodash";

import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Container} from "semantic-ui-react";
import "./WidgetsTests.css";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import ContainerWidget from "../../components/Widgets/ContainerWidget/ContainerWidget";


class WidgetsTests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetConfig: {
        "structure": [
          {
            "rangeTypes": [
              {
                "value": "!LOC",
                "children": [
                  {
                    "value": "!LOC_ARTIFACT",
                    "children": [
                      {
                        "value": "!LOC_ARTIFACT_BUILDING",
                        "children": [
                          {
                            "color": "LightBlue",
                            "value": "LOC_ARTIFACT_BUILDING_ART-MUSEUM"
                          },
                          {
                            "color": "LightBlue",
                            "value": "LOC_ARTIFACT_BUILDING_FOOD"
                          },
                          {
                            "color": "LightBlue",
                            "value": "LOC_ARTIFACT_BUILDING_LODGEMENT"
                          },
                          {
                            "color": "LightBlue",
                            "value": "LOC_ARTIFACT_BUILDING_SHOPPING"
                          },
                          {
                            "color": "LightBlue",
                            "value": "LOC_ARTIFACT_BUILDING_SPORT"
                          }
                        ]
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_ARTIFACT_CULTURAL-ASSET"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_ARTIFACT_TRANSPORT"
                      }
                    ]
                  },
                  {
                    "value": "!LOC_GEO",
                    "children": [
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_CONTINENT"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_COSMOS"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_ISLAND"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_MOUNTAIN"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_PARK-FOREST"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_TOPOGRAPHY"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_GEO_WATER"
                      }
                    ]
                  },
                  {
                    "color": "LightBlue",
                    "value": "LOC_OTHER"
                  },
                  {
                    "value": "!LOC_PLACE",
                    "children": [
                      {
                        "color": "LightBlue",
                        "value": "LOC_PLACE_CITY"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_PLACE_COUNTRY"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_PLACE_COUNTY"
                      },
                      {
                        "color": "LightBlue",
                        "value": "LOC_PLACE_STATE-PROVINCE"
                      }
                    ]
                  }
                ]
              },
              {
                "value": "!ORG",
                "children": [
                  {
                    "color": "Yellow",
                    "value": "ORG_ART-ENTERTAINMENT-MUSEUM"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_BUSINESS"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_EDUCATION"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_FINANCE"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_LAW"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_MEDIA"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_MEDICINE"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_MILITARY"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_OTHER"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_POLITICS-GOVERNMENT"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_RELIGION"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_SCIENCE-TECHNOLOGY"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_SOCIETY"
                  },
                  {
                    "color": "Yellow",
                    "value": "ORG_SPORT"
                  }
                ]
              },
              {
                "value": "!PER",
                "children": [
                  {
                    "color": "LightGreen",
                    "value": "PER_ART-ENTERTAINMENT"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_BUSINESS"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_CRIME"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_EDUCATION"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_FICTIONAL"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_FINANCE"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_FOOD"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_LAW"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_MEDIA"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_MEDICINE"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_MILITARY"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_OTHER"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_POLITICS-GOVERNMENT"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_RELIGION"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_SCIENCE-TECHNOLOGY"
                  },
                  {
                    "color": "LightGreen",
                    "value": "PER_SPORT"
                  }
                ]
              }
            ],
            "componentId": "SIOSTRAWidget_1",
            "overlapRules": [],
            "componentType": "SIOSTRAWidget",
            "inheritFields": [
              "color"
            ],
            "connectorTypes": [],
            "enableRangeNotes": true,
            "enableConnectorNotes": true
          },
          {
            "componentId": "submit-button",
            "componentType": "SubmitWidget"
          }
        ],
      },
      /* eslint-disable no-irregular-whitespace */
      widgetVals: {
        "SIOSTRAWidget_1": {
          // "originalString": "Kontakt\n1994. Siegfried Eger und Wolfgang Wagner haben den Mut zur Selbstständigkeit\nNach der Hitzewelle 1994 weicht der kalte Krieg endgültig den warmen Sommerabenden. Die letzte russische Garnison zieht aus Ostdeutschland ab und 17.000 amerikanische Soldaten verlassen Augsburg. Zurück bleiben Freundschaften, hektargroße Kasernenareale und eine wirtschaftliche Ungewissheit.\nZuversicht hingegen herrscht bei Siegfried Eger und Wolfgang Wagner. \nDer eine hat es gelernt, mit dem Geld Dritter verantwortungsbewusst umzugehen, der andere trägt Verantwortung für anspruchsvolle Hochbauprojekte und entwickelt neben seinem gerade gegründeten Planungsbüro in Augsburg ein attraktives Baugrundstück in Sachsen. \nSo wird 1994 zur Geburtsstunde der infrabau GmbH. \nHochbau-Erfahrung trifft nun auf Geldverständnis und erste erfolgreiche Immobilienprojekte auf eine vielversprechende Nachfrage. \nDen Höhen folgen bekanntlich Tiefen, doch nach einer 20 jährigen Bilanz bleibt unter dem Strich eine überaus positive Geschäftsentwicklung mit stetig wachsender Nachfrage nach hochwertigem Schlüsselfertigbau zu verzeichnen. \nGute Voraussetzungen also für Kunden und Geschäftspartner, auch die nächsten 20 Jahre auf die infrabau GmbH zu vertrauen. Denn ein Unternehmen, das sich schon so lange erfolgreich mit kaufmännischer und technischer Kompetenz dem Schlüsselfertigbau verschrieben hat, ist auch morgen noch allererste Wahl...“\nSiegfried Eger\n",
          "originalString": "Kontakt... 1994. Nach\n Der Hitzewelle  123.00 Selbstständigkeit...\n\n\n\n 1994 aa bb haben. Dr. Frankenstein aus berlinale. Bzzt der kamraden den Mr. Sommerabenden.\n Imma floating end. www.sasa.sadsad.d", // Die letzte russische Garnison zieht aus Ostdeutschland ab und 17.000 amerikanische Soldaten verlassen Augsburg. Zurück bleiben Freundschaften, hektargroße Kasernenareale und eine wirtschaftliche Ungewissheit.\nZuversicht hingegen herrscht bei Siegfried Eger und Wolfgang Wagner. \nDer eine hat es gelernt, mit dem Geld Dritter verantwortungsbewusst umzugehen, der andere trägt Verantwortung für anspruchsvolle Hochbauprojekte und entwickelt neben seinem gerade gegründeten Planungsbüro in Augsburg ein attraktives Baugrundstück in Sachsen. \nSo wird 1994 zur Geburtsstunde der infrabau GmbH. \nHochbau-Erfahrung trifft nun auf Geldverständnis und erste erfolgreiche Immobilienprojekte auf eine vielversprechende Nachfrage. \nDen Höhen folgen bekanntlich Tiefen, doch nach einer 20 jährigen Bilanz bleibt unter dem Strich eine überaus positive Geschäftsentwicklung mit stetig wachsender Nachfrage nach hochwertigem Schlüsselfertigbau zu verzeichnen. \nGute Voraussetzungen also für Kunden und Geschäftspartner, auch die nächsten 20 Jahre auf die infrabau GmbH zu vertrauen. Denn ein Unternehmen, das sich schon so lange erfolgreich mit kaufmännischer und technischer Kompetenz dem Schlüsselfertigbau verschrieben hat, ist auch morgen noch allererste Wahl...“\nSiegfried Eger\n",
          "ranges": [
            // {
            //   "id": "T1_0",
            //   "end": 223,
            //   "name": "LOC_GEO_TOPOGRAPHY",
            //   "type": "LOC_GEO_TOPOGRAPHY",
            //   "begin": 209,
            //   "content": "Ostdeutschland",
            //   "entityId": "T1"
            // },
            // {
            //   "id": "T2_0",
            //   "end": 279,
            //   "name": "LOC_PLACE_CITY",
            //   "type": "LOC_PLACE_CITY",
            //   "begin": 271,
            //   "content": "Augsburg",
            //   "entityId": "T2"
            // },
            // {
            //   "id": "T3_0",
            //   "end": 705,
            //   "name": "LOC_PLACE_STATE-PROVINCE",
            //   "type": "LOC_PLACE_STATE-PROVINCE",
            //   "begin": 698,
            //   "content": "Sachsen",
            //   "entityId": "T3"
            // },
            // {
            //   "id": "T4_0",
            //   "end": 664,
            //   "name": "LOC_PLACE_CITY",
            //   "type": "LOC_PLACE_CITY",
            //   "begin": 656,
            //   "content": "Augsburg",
            //   "entityId": "T4"
            // },
            // {
            //   "id": "T5_0",
            //   "end": 756,
            //   "name": "ORG_BUSINESS",
            //   "type": "ORG_BUSINESS",
            //   "begin": 743,
            //   "content": "infrabau GmbH",
            //   "entityId": "T5"
            // },
            // {
            //   "id": "T6_0",
            //   "end": 1221,
            //   "name": "ORG_BUSINESS",
            //   "type": "ORG_BUSINESS",
            //   "begin": 1208,
            //   "content": "infrabau GmbH",
            //   "entityId": "T6"
            // },
            // {
            //   "id": "T7_0",
            //   "end": 425,
            //   "name": "PER_BUSINESS",
            //   "type": "PER_BUSINESS",
            //   "begin": 411,
            //   "content": "Siegfried Eger",
            //   "entityId": "T7"
            // },
            // {
            //   "id": "T8_0",
            //   "end": 28,
            //   "name": "PER_BUSINESS",
            //   "type": "PER_BUSINESS",
            //   "begin": 14,
            //   "content": "Siegfried Eger",
            //   "entityId": "T8"
            // },
            // {
            //   "id": "T9_0",
            //   "end": 48,
            //   "name": "PER_BUSINESS",
            //   "type": "PER_BUSINESS",
            //   "begin": 33,
            //   "content": "Wolfgang Wagner",
            //   "entityId": "T9"
            // },
            // {
            //   "id": "T10_0",
            //   "end": 445,
            //   "name": "PER_BUSINESS",
            //   "type": "PER_BUSINESS",
            //   "begin": 430,
            //   "content": "Wolfgang Wagner",
            //   "entityId": "T10"
            // },
            // {
            //   "id": "T11_0",
            //   "end": 1435,
            //   "name": "PER_BUSINESS",
            //   "type": "PER_BUSINESS",
            //   "begin": 1421,
            //   "content": "Siegfried Eger",
            //   "entityId": "T11"
            // }
          ]
          /* eslint-enable no-irregular-whitespace */
        }
      }
    };

    this.updateWidgetVals = this.updateWidgetVals.bind(this);
  }

  updateWidgetVals(widgetVals) {
    this.setState({widgetVals});
  }

  updateRangeTypes = (rangeTypes) => {
    const widgetConfig = _.cloneDeep(this.state.widgetConfig);
    widgetConfig.structure[0].rangeTypes = rangeTypes;
    this.setState({widgetConfig});
  };

  updateRangeValues = (rangeTypes) => {
    const widgetConfig = _.cloneDeep(this.state.widgetConfig);
    widgetConfig.structure[0].rangeTypes = rangeTypes;
    this.setState({widgetConfig});
  };

  render() {
    return (
      <Container>
        <ContainerWidget
          componentId="OrderListID"
          getWidgetConfig={() => {
            return this.state.widgetConfig;
          }}
          toRender={this.state.widgetConfig}
          update={this.updateWidgetVals}
          widgetVals={this.state.widgetVals}
        />
        <pre>{JSON.stringify(this.state.widgetVals, null, 1)}</pre>
        {/*<pre>{JSON.stringify(this.state.widgetVals.jedenjedenjeden, null, 1)}</pre>*/}
        <pre>{JSON.stringify(this.state.widgetConfig, null, 1)}</pre>

        {/*<TreeSelect*/}
        {/*data={this.state.data}*/}
        {/*value={this.state.value}*/}
        {/*updateValue={value => {*/}
        {/*this.setState({value})*/}
        {/*}}*/}
        {/*multiple*/}
        {/*/>*/}


      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(WidgetsTests));
