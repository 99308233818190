import React, {Component} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Container} from "semantic-ui-react";
import PropTypes from "prop-types";

import urls from "../config/frontend_urls";

import "./Home.css";
import "../style/colors.css";

class Home extends Component {
  static propTypes = {
    errors: PropTypes.array,
    auth: PropTypes.shape({
      is_logged: PropTypes.bool.isRequired,
      is_staff: PropTypes.bool
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      is_staff: ""
    };

    this.renderHomePage = this.renderHomePage.bind(this);
    this.renderNotLogged = this.renderNotLogged.bind(this);
  }

  renderNotLogged() {
    return (
          <Redirect to={urls.LOGIN}/>
        );
  }

  renderHomePage() {
    const url = this.props.auth.is_staff ?
      urls.ADMIN_TASK_LIST : urls.TASKS_LIST;
    return (
      <Redirect to={url}/>
    );
  }

  render() {
    let content;
    if (!this.props.auth.is_logged) {
      content = this.renderNotLogged();
    } else {
      content = this.renderHomePage();
    }

    return (
      <Container className={"Home"} textAlign={"center"}>
        {content}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    errors: state.errors
  };
}

export default withRouter(connect(mapStateToProps)(Home));
