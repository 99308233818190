import React, {Component} from "react";
import {List} from "semantic-ui-react";
import ShowInputButton from "./ShowInputButton";
import NewNodeInput from "./NewNodeInput";
import PropTypes from "prop-types";

class TreeLeaf extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    deleteButton: PropTypes.object,
    displayAddButton: PropTypes.bool,
    openInputValue: PropTypes.string,
    updateOpenInputValue: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      newNode: {
        title: "",
        value: "",
      }
    };
  }

  render() {
    const input = (
      <React.Fragment>
        <br/>
        <NewNodeInput
          nodeValue={this.props.node.value}
          handleAddClick={this.props.handleAddClick}
        />
      </React.Fragment>
    );

    return (
      <List.Content className={"full-width"}>
        <div className={`node-title ${this.props.color ? "background_" + this.props.color : ""}`}>
          {this.props.title}
        </div>
        {
          this.props.deleteButton
        }
        {this.props.displayAddButton &&
        <ShowInputButton
          showInput={this.props.openInputValue === this.props.node.value}
          onClick={() => this.props.updateOpenInputValue(this.props.node.value)}
        />
        }
        {this.props.openInputValue === this.props.node.value &&
        input
        }
      </List.Content>
    );
  }
}

export default TreeLeaf;
