import React from "react";
import {Button, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {toastr} from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import FilteredComponent from "../../../components/FilteredComponent";
import ContainerList from "../../../components/ContainerList";


class DivisionList extends FilteredComponent {
  static propTypes = {
    divisions: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.emptyDivision = {
      id: null,
      name: "",
    };

    this.state = {
      divisions: [],
      allUsers: [],
      displaySpinner: false,
      totalPages: 1,
      filters: {
        page: 1,
      },
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getData = () => {
    const params = (this.state.filters ? this.state.filters : this.getFilters());
    params.page = params.page ? params.page : 1;

    ajax.get(config.DIVISIONS, {params})
      .then((response) => {
        this.setState({
          divisions: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE)
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  }

  renderRow = (item) => {
    const {history} = this.props;
    return (
      <Table.Row
        key={item.id}
        onClick={() => history.push(urls.DIVISION_DETAIL.replace(":id", item.id))}>
        <Table.Cell className={"list-item clickable"}>
          {item.name}
        </Table.Cell>
      </Table.Row>);
  }

  pageChange = (e, {activePage}) => {
    this.updateFilters({page: activePage});
  };

  render() {
    let columns = [
      {name: "name", title: "Name", headerProps: {width: 11}},
    ];
    const button = <Link to={urls.DIVISION_DETAIL_NEW}>
      <Button
        className={"ui button new-btn datahive-button"}
        color="yellow"
        floated="right"
        content={"Add division"}
      />
    </Link>;

    return <ContainerList
      renderRow={this.renderRow}
      items={this.state.divisions}
      columns={columns}
      button={button}
      totalPages={this.state.totalPages}
      pageChange={this.pageChange}
      filters={this.state.filters.page || 1}
    />;
  }
}

export default DivisionList;
