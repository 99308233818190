import _ from "lodash";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Container, Header, Tab} from "semantic-ui-react";
import "./UserProfile.css";
import UserProfileInfo from "./UserProfileInfo";
import UserRatings from "./UserRatings";
import UserPriceTariffs from "./UserPriceTariffs";
import urls from "../../config/frontend_urls";
import {loginAs} from "../../actions/actions_auth";
import PropTypes from "prop-types";

export class UserProfile extends Component {
  static propTypes = {
    loginAs: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const username = props.match.params.username;
    const {is_company_coordinator, is_ele, is_staff, is_superuser} = props.auth;
    const isOwnProfile = username === props.auth.username;

    this.tabIndexes = {profile: 0};
    if (is_superuser || is_company_coordinator || (isOwnProfile && is_ele)) {
      this.tabIndexes.tariffs = Object.keys(this.tabIndexes).length;
    }
    if (is_staff) {
      this.tabIndexes.ratings = Object.keys(this.tabIndexes).length;
    }
  }

  tabChange = (e, {activeIndex}) => {
    const username = this.props.match.params.username;
    const url = urls.USER_PROFILE.replace(":username", username)
      .replace(":tabName?", _.findKey(this.tabIndexes, (o) => o === activeIndex));
    this.props.history.push(url);
  };

  loginAs = () => {
    const username = this.props.match.params.username;
    this.props.loginAs(this.props.history, username);
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // do not re render if just the auth token changes
    const nnextProps = _.cloneDeep(nextProps);
    const props = _.cloneDeep(this.props);
    delete props.auth.token;
    delete nnextProps.auth.token;
    const fields = ["auth", "location", "match"];
    for (const field of fields) {
      if (!_.isEqual(_.get(nnextProps, field), _.get(props, field))) {
        return true;
      }
    }
    return false;
  }

  render() {
    const username = this.props.match.params.username;
    const {is_staff, is_superuser} = this.props.auth;
    const adminRights = is_superuser || is_staff;

    const profile = <UserProfileInfo key="profile" username={username} isStaff={is_staff}/>;

    const panes = [
      {
        menuItem: "Profile",
        render: () => <Tab.Pane>{profile}</Tab.Pane>
      },
    ];

    // Superusers always see tariffs tab. Company coordinators have access only to their users.
    if (this.tabIndexes.tariffs) {
      panes.push({
        menuItem: "Tariffs",
        render: () => <Tab.Pane><UserPriceTariffs key="priceTariffs" username={username}/></Tab.Pane>
      });
    }

    if (this.tabIndexes.ratings) {
      panes.push({
        menuItem: "Ratings",
        render: () => <Tab.Pane><UserRatings key="ratings" username={username}/></Tab.Pane>
      });
    }

    const loginAs = adminRights ?
      <Button id="login-as" color="orange" className="datahive-button" onClick={this.loginAs}>Login as</Button> : "";

    return (
      <Container className={"UserProfile"}>
        <div className="header">
          <Header size="large">{username}</Header>
          {loginAs}
        </div>
        <br/>
        {panes.length > 1
          ? <Tab
            activeIndex={_.get(this.tabIndexes, this.props.match.params.tabName, 0)}
            panes={panes}
            onTabChange={this.tabChange}/>
          : profile
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export default withRouter(connect(mapStateToProps, {loginAs})(UserProfile));
