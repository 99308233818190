import React from "react";
import { Button, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import FilteredComponent from "../../../components/FilteredComponent";
import ContainerList from "../../../components/ContainerList";
import LoaderSpinner from "../../../components/LoaderSpinner";

import moment from "moment";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import { updateFiltersStateless } from "../../../helpers/filters";

class TicketList extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.emptyTicket = {
      id: null,
      author: null,
      title: "",
      description: "",
      category: "",
      status: "",
      priority: null,
      created_at: "",
      last_updated: "",
    };

    this.state = {
      tickets: [],
      displaySpinner: true,
      totalPages: 1,
      filters: {
        page: "1",
      },
    };

    this.updateFiltersStateless = updateFiltersStateless.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getData = () => {
    const params = this.state.filters ? this.state.filters : this.getFilters();
    params.page = params.page ? params.page : 1;

    ajax
      .get(config.TICKETS, { params })
      .then((response) => {
        this.setState({
          tickets: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          displaySpinner: false,
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  };

  pageChange = (e, { activePage }) => {
    this.updateFilters({ page: activePage });
  };

  renderRow = (item) => {
    const { history } = this.props;

    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    const truncatedTitle =
      "#" +
      item.id +
      " " +
      item.title.slice(0, 50) +
      (item.title.length > 50 ? "..." : "");
    const truncatedDescription =
      item.description.slice(0, 50) +
      (item.description.length > 50 ? "..." : "");

    return (
      <Table.Row
        key={item.id}
        onClick={() =>
          history.push(urls.SUPPORT_TICKET_DETAIL.replace(":id", item.id))
        }
      >
        <Table.Cell className={"list-item clickable"}>
          {truncatedTitle}
        </Table.Cell>
        <Table.Cell>{truncatedDescription}</Table.Cell>
        <Table.Cell>{item.category}</Table.Cell>
        <Table.Cell>{item.status}</Table.Cell>
        <Table.Cell>{item.priority}</Table.Cell>
        <Table.Cell>{item.attachments?.length}</Table.Cell>
        <Table.Cell>
          {item.created_at
            ? moment(item.created_at).format(config.FORMAT_Ymd)
            : ""}
        </Table.Cell>
        <Table.Cell>
          {item.last_updated
            ? moment(item.last_updated).format(config.FORMAT_Ymd)
            : ""}
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    let columns = [
      {
        name: "ticket",
        title: "Ticket",
        headerProps: { width: 3 },
      },
      { name: "description", title: "Description", headerProps: { width: 3 } },
      { name: "category", title: "Category", headerProps: { width: 2 } },
      { name: "status", title: "Status", headerProps: { width: 2 } },
      { name: "priority", title: "Priority", headerProps: { width: 2 } },
      { name: "attachments", title: "Attachments", headerProps: { width: 2 } },
      { name: "created_at", title: "Created at", headerProps: { width: 2 } },
      {
        name: "last_updated",
        title: "Last updated",
        headerProps: { width: 2 },
      },
    ];
    const button = (
      <Link to={urls.SUPPORT_TICKET_NEW}>
        <Button
          className="ui button new-ticket-btn datahive-button"
          color="yellow"
          floated="right"
          content={"Add new ticket"}
        />
      </Link>
    );

    if (this.state.displaySpinner) {
      return (
        <div className="tab-panel">
          <LoaderSpinner />
        </div>
      );
    }

    return (
      <ContainerList
        renderRow={this.renderRow}
        items={this.state.tickets}
        columns={columns}
        button={button}
        totalPages={this.state.totalPages}
        pageChange={this.pageChange}
        filters={this.state.filters.page || 1}
      />
    );
  }
}

export default TicketList;
