import React, {Component} from "react";
import {Table} from "semantic-ui-react";
import _ from "lodash";
import PropTypes from "prop-types";

export default class TaskProblematicQuestions extends Component {
  static propTypes = {
    problematicQuestions: PropTypes.array
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderTaskRows() {
    let rows = <Table.Row/>;
    if (this.props.problematicQuestions) {
      rows = _.map(this.props.problematicQuestions, (user, idx) => {
        return (
          <Table.Row key={"Problem" + idx}>
            <Table.Cell>{user.id}</Table.Cell>
            <Table.Cell>{user.text}</Table.Cell>
            <Table.Cell>{user.times_seen}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }

  render() {
    const rows = this.renderTaskRows();
    return (
      <Table selectable sortable celled structured textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Text</Table.HeaderCell>
            <Table.HeaderCell>Times seen</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    );
  }
}
