import _ from "lodash";
import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {Card, Container, Dimmer, Form, Grid, Header, Segment, FormDropdown} from "semantic-ui-react";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import qs from "query-string";
import moment from "moment-timezone";

import UsersStatisticsTable from "./UsersStatisticsTable";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {updateFiltersStateless} from "../../helpers/filters";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import Pagination from "../../components/Pagination";
import {formatDate} from "../../helpers/utils";

import "./TasksStatistics.css";
import "./FormStatistics.css";


class UsersStatistics extends FilteredComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 1,
      popupOpen: false,
      usersStatisticsList: null,
      user: _.get(qs.parse(props.location.search), "user") || "",
      filtersOptions: {
        company: [],
        language: [],
        project_name: [],
      },
    };
    this.defaultFilters = {all: true};
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.arrayFilters = ["company", "language", "project_name"];
  }

  componentDidMount() {
    this.getFilterOptions();
    super.componentDidMount();
  }

  getData = () => {
    this.setState({usersStatisticsList: null});
    const params = this.getFilters();
    if (params.date_range) {
      [params.start_date, params.end_date] = params.date_range.split(" - ");
    }

    if (!params.all) {
      formatDate(params);
    }
    let data = {
      "fields": "id,username,tasks_count,summary_time,last_answer,active_tasks,mean_answers_time,answers_count,mean_summary_time"
    };
    data = Object.assign({}, params, data);
    ajax.get(config.USERS_STATISTIC, {params: data})
      .then((response) => {
        this.setState({
          usersStatisticsList: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE)
        });
      })
      .catch(() => {
        toastr.error("Error!", "Something went wrong!");
      });
  };

  getFilterOptions = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        this.parseFilterOptions("company", response.data, "name", "name", "name", false);
      });
    ajax.get(config.PROJECT_OPTIONS)
      .then((response) => {
        this.parseFilterOptions("project_name", response.data, "name", "name", "name", false);
      });
    ajax.get(config.LANGUAGES, {params: {filter: true}})
      .then((response) => {
        this.parseFilterOptions("language", response.data, "name", "name", "name", false);
      });
  };

  closePopup = () => {
    this.setState({
      popupOpen: false,
    });
  };

  handlePaginationChange = (e, {activePage}) => {
    this.updateFilters({page: activePage}, this.getData);
  };

  setAll = (flag) => {
    const date = `${moment("01", "DD").format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`;
    const date_range = flag ? "" : date;
    this.changeFilterInstantly({
      all: flag,
      date_range
    });
  };

  render() {
    const params = {...this.state.filters};
    const pagination =
      <Pagination activePage={params?.page || 1}
        onPageChange={this.handlePaginationChange}
        totalPages={this.state.totalPages}
      />;
    const dataLoaded = !!this.state.usersStatisticsList;
    const filters =
      <Form className={"usersStatisticsFilters"}>
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              label="Username"
              value={this.state.user}
              onChange={(e, data) => this.searchChange(data.value.toLowerCase(), "user")}
              type="text"
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              className="Company"
              placeholder="All"
              label="Company"
              fluid search multiple selection
              options={this.state.filtersOptions.company || []}
              value={params.company || []}
              onChange={(e, data) => this.changeFilterInstantly({company: data.value})}
            /></Form.Field>
          <Form.Field>
            <label>Language</label>
            <Form.Dropdown
              placeholder="Language"
              fluid search multiple selection
              options={this.state.filtersOptions.language || []}
              value={_.compact(_.concat([], params.language))}
              onChange={(e, data) => this.changeFilterInstantly({language: data.value})}
            />
          </Form.Field>
          <Form.Field>
            <label>Project</label>
            <Form.Dropdown
              className={"project-dropdown"}
              placeholder="Project"
              fluid search multiple selection
              options={this.state.filtersOptions.project_name || []}
              value={_.compact(_.concat([], params.project_name))}
              onChange={(e, data) => this.changeFilterInstantly({project_name: data.value})}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field width={8} style={{textAlign: "center", paddingRight: "0px"}}>
            <label>Date range</label>
            <DatesRangeInput
              autoComplete="off"
              dateFormat="YYYY-MM-DD"
              name="datesRange"
              placeholder="Start Date - End Date"
              value={params.date_range || ""}
              iconPosition="left"
              popupPosition="bottom center"
              onChange={(e, data) => this.changeFilterInstantly({date_range: data.value})}
              disabled={params.all}/>
          </Form.Field>
          <Form.Field width={8} style={{textAlign: "center"}}>
            <div className={"checkboxAllTime"}>
              <label>All time</label>
              <Form.Checkbox
                checked={params.all || false}
                onChange={(e, data) => this.setAll(data.checked)}/>
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Field style={{textAlign: "center"}}>
          <Form.Button
            type="submit"
            className="datahive-button"
            color="dark-grey"
            onClick={this.resetFilters}>
              Reset
          </Form.Button>
        </Form.Field>
      </Form>;
    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} style={{border: "0px", boxShadow: "none"}}>
        <Container className="UsersStatistics">
          <Card.Group>
            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                      <Header textAlign="center">Users statistics</Header>
                    </Grid.Column>
                    <Grid.Column/>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              <Card.Content>
                <Grid columns={1}>
                  <Grid.Row>
                    <Grid.Column>
                      {filters}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
            <Card fluid>
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Header textAlign="center">Statistics</Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {dataLoaded &&
              <Card.Content>
                <UsersStatisticsTable
                  usersStatisticsList={this.state.usersStatisticsList}
                  all={params.all}/>
                {pagination}
              </Card.Content>
              }
              {!dataLoaded && <LoaderSpinner/>}
            </Card>
          </Card.Group>
        </Container>
        <Dimmer className={"invisible-dimmer"} active={this.state.popupOpen}
          onClickOutside={this.closePopup}/>
      </Dimmer.Dimmable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersStatistics));
