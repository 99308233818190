import React from "react";
import {Checkbox, Dropdown, Grid, Header, Input} from "semantic-ui-react";
import {AlignSelector} from "../components";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import "./TextareaWidgetEditor.css";
import _ from "lodash";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class TextareaWidgetEditor extends WidgetEditor {
  static header = "Textarea";

  updateReadOnlyConfig(value) {
    this.updateWidgetConfig((widgetConfig) => {
      _.set(widgetConfig, "readonly", value);
      _.set(widgetConfig, "maxlength", 120);
    });

    this.setState({});
  }

  updateCounterConfig(value) {
    const enters = value.indexOf("enters") !== -1;
    const characters = value.indexOf("characters") !== -1;
    this.updateWidgetConfig((widgetConfig) => {
      _.set(widgetConfig, "enters", enters);
      _.set(widgetConfig, "characters", characters);
    });

    this.setState({});
  }

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    const counting = _.reduce(config, (result, val, key) => {
      if ((key === "enters") && val) {
        result.push("enters");
      }
      if ((key === "characters") && val) {
        result.push("characters");
      }
      return result;
    }, []);
    const options = [
      {key: "enters", text: "Enters", value: "enters"},
      {key: "characters", text: "Characters", value: "characters"}
    ];
    return (
      <Grid className={"TextareaWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <div className={"label"}>
              <div id={"label-value"}>
                <Header as="h4">Label</Header>
                <Input
                  placeholder="Label"
                  value={config.label || ""}
                  onChange={(e) => {
                    this.updateConfig("label", e.target.value);
                  }}
                />
              </div>
              <div id={"label-width"}>
                <Header as="h4">Width</Header>
                <Input
                  type="number"
                  placeholder=""
                  value={config.labelWidth || ""}
                  onChange={(e) => {
                    this.updateConfig("labelWidth", e.target.value);
                  }}
                />
              </div>
            </div>
            <Header as="h4">Maximum number of available characters</Header>
            <Input
              placeholder="Value"
              label="Number"
              disabled={config.readonly}
              value={config.maxlength || 120}
              onChange={(e) => {
                if (/^\d+$/.exec(e.target.value) && parseFloat(e.target.value) > 0) {
                  this.updateConfig("maxlength", parseInt(e.target.value));
                } else {
                  this.updateConfig("maxlength", 120);
                }
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="left">
            <Header className="row" as="h4">Align</Header>
            <AlignSelector
              justify
              id="align-selector"
              textAlign="center"
              className="row"
              value={config.align}
              onChange={(e, data) => {
                this.updateConfig("align", data);
              }}
            />
            <p/>
            <Header className="row" as="h4">Counter</Header>
            <Dropdown
              fluid
              multiple
              selection
              id="counter-dropdown"
              disabled={config.readonly}
              options={options}
              value={counting}
              onChange={(e, {value}) => {
                this.updateCounterConfig(value);
              }
              }
            />
            <p/>
            <Checkbox
              toggle
              id="random-checkbox"
              label="Random Topic"
              checked={config.random}
              onChange={(e, data) => {
                this.updateConfig("random", data.checked);
              }}
            />
            <p/>
            <Checkbox
              toggle
              id="readonly-checkbox"
              label="Read Only"
              size="mini"
              placeholder="Value"
              checked={config.readonly}
              onChange={(e, data) => {
                this.updateReadOnlyConfig(data.checked);

              }}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default TextareaWidgetEditor;
