import _ from "lodash";
import {Component} from "react";
import {getParsedFilters, getSorted, parseFilterOptions, sortFlip, updateFiltersStateless} from "../helpers/filters";
import PropTypes from "prop-types";


export default class FilteredComponent extends Component {
  /* This is a class that should be the parent for classes that use filters or other url parameters.
      especially aimed at list views. They should inherit it by:
      ```class SomeListComponent extends Filtered component{...}``` */
  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
    this.updateFilters = updateFiltersStateless.bind(this);
    this.parseFilterOptions = parseFilterOptions.bind(this);
    this.arrayFilters = [];
    this.defaultFilters = {};
  }

  componentDidMount() {
    this.setState({filters: this.getFilters(), displaySpinner: true}, this.getData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location
      && prevProps.location.search !== this.props.location.search) {
      this.setState({
        filters: this.getFilters(),
        displaySpinner: true
      }, this.getData);
    }
  }

  getFilters = () => {
    /*Convenience method for extracting params from url.
      Might be useful if not all parameters in url are filter related. */
    return getParsedFilters(this.props, this.arrayFilters, this.defaultFilters);
  };

  getData = () => {
    /*Abstract method, must be implemented on the child class. */
    throw new Error("Not implemented error! Please implement getData function.");
  };

  getSorted = (columnName) => {
    /* Check sorting order. */
    return getSorted(columnName, this.getFilters());
  };

  updateSorting = (field) => {
    /* This function is used to update sorting. The only difference from plain filter update is that
    the field name is determined to be order_by. It is a convenience function, developer could just use
    _changeFilter with the sortFlip. */
    const sort = sortFlip(this.getFilters(), field);
    this.updateFilters({order_by: sort});
  };

  setFilterState = (filterField, value) => {
    /* This function update filter state, but did not update url. */
    const filters = _.cloneDeep(this.state.filters);
    filters[filterField] = value;
    this.setState({filters});
  };

  changeFilter = (filterField, path) => {
    /* This function updates the filters in the url - which means it changes the props. By default it also
    downloads data. This is done synchronously, to avoid copying url params from props to state.
     */
    this.updateFilters({[filterField]: _.get(this, path)});
  };

  debouncedChangeFilter = _.debounce(this.changeFilter, 1000);

  _changeFilter = (filterField, path) => {
    /* Debounced change filter, to be used on fields when user has to type a search query for example. */
    this.debouncedChangeFilter.cancel();
    this.debouncedChangeFilter(filterField, path);
  };

  debouncedFilterChange = (filterField, value) => {
    /* This function change filter state and call debounced url change - which means it changes the props. */
    const filters = _.cloneDeep(this.state.filters);
    filters[filterField] = value;
    this.setState({filters}, () => {
      this._changeFilter(filterField, `state.filters.${filterField}`);
    });
  };

  onSubmit = () => {
    /* This function update url with filters from state and call getData */
    this.updateFilters(this.state.filters);
  };

  resetFilters = () => {
    /* This function reset all filters and call getData */
    const filters = getParsedFilters(this.props);
    const clearedFilters = _.mapValues(filters, () => null);
    if ("page" in filters) {
      clearedFilters["page"] = 1;
    }
    this.updateFilters(clearedFilters);
  };

  changeFilterInstantly = (newFilter, hasPages = true, asArray = this.arrayFilters) => {
    this.updateFilters(newFilter, hasPages, asArray);
  };

  searchChange = (value, field) => {
    //stores the value in the state, in order to debounce user input.
    this.setState({[field]: value}, () => this._changeFilter(field, `state.${field}`));
  };
}
