/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {Button, Container, TextArea} from "semantic-ui-react";
import "./PipedTextAreaWidget.css";
import Widget from "../Widget";

class PipedTextAreaWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
  };

  static getDefaultConfig = () => {
    return {
      segmentsNumber: 1,
      wordsInSegment: 1,
      defaultValue: "",
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      empty: false,
      valid: false,
    };

    this.textarea = React.createRef();
  }

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    this.registerEmptyValid();

    if (!widgetVal) {
      this.reset();
    } else {
      // call onChange similar to semantic TextArea
      this.onChange(null, {value: widgetVal});
    }
  }

  isEmpty = () => {
    return this.state.empty;
  };

  isValid = () => {
    return this.state.valid;
  };

  reset = () => {
    const defaultValue = this.props.widgetVals[this.props.defaultValue] ?
      this.props.widgetVals[this.props.widgetConfig.defaultValue] : "";
    const widgetVal = defaultValue;
    this.update(widgetVal);
  };

  preventSelectionChange(e) {
    const selectionStart = e.target.selectionStart - 1;
    const selectionEnd = e.target.selectionEnd - 1;

    // chackish method to set selectionStart and selectionEnd
    this.setState({},
      () => {
        this.textarea.current.ref.selectionStart = selectionStart;
        this.textarea.current.ref.selectionEnd = selectionEnd;
      }
    );
  }

  onChange = (e, data) => {
    const config = this.props.widgetConfig;
    const update = true;
    let defaultValue = this.props.widgetVals[config.defaultValue] ?
      this.props.widgetVals[config.defaultValue] : "";
    if (Array.isArray(defaultValue)) {
      defaultValue = defaultValue[0];
    }
    let value = data.value;

    // cast from array to string
    if (Array.isArray(value)) {
      value = value[0];
    }

    let valueLowercase = value.toLowerCase();
    const defaultValueLowercase = defaultValue.toLowerCase();

    let valid = true;
    let empty = false;

    // check if value is different from default_value
    if (value.localeCompare(defaultValue) === 0) {
      empty = true;
    }

    // check number of pipes and segments
    const pipes_number = (valueLowercase.match(/\|/g) || []).length;
    if (config.segmentsNumber !== 0 && pipes_number > config.segmentsNumber * 2) {
      this.preventSelectionChange(e);
      return;
    }
    if (pipes_number % 2 !== 0) {
      valid = false;
    }

    // validate all pipes
    let last_pipe_position = -1;
    const valueLowercaseCpy = valueLowercase;
    for (let i = 0; i < pipes_number / 2; i++) {
      // find 2 pipes
      const first = valueLowercaseCpy.indexOf("|", last_pipe_position + 1);
      const second = valueLowercaseCpy.indexOf("|", first + 1);
      last_pipe_position = second;

      const segment = valueLowercaseCpy.substr(first + 1, second - first - 1).trim();

      // empty segment
      const words = segment.split(/\s+/);
      if (words.length === 1 && words[0] === "") {
        valid = false;
      }

      // too many words
      if (words.length > config.wordsInSegment) {
        this.preventSelectionChange(e);
        return;
      }

      // remove segments text from value
      const text = valueLowercaseCpy.substr(first, second - first + 1);
      valueLowercase = valueLowercase.replace(text, "");
    }

    // check if text outside segments is different than default
    const result = defaultValueLowercase.replace(/\s/g, "").localeCompare(
      valueLowercase.replace(/\s/g, "")
    );

    if (result !== 0) {
      valid = false;
    }

    this.setState({valid, empty});

    if (!!update) {
      this.update(value);
    }
  };

  render() {
    const config = this.props.widgetConfig;
    const defaultValue = this.props.widgetVals[config.defaultValue];
    const align = config.align || "justify";
    const customClasses = `${align}`;
    let value = "";
    if (defaultValue) {
      value = defaultValue;
    }
    if (this.props.value) {
      value = this.props.value;
    }

    return (
      <Container className={`PipedTextAreaWidget Widget ${customClasses}`}>
        <TextArea
          autoHeight
          rows={1}
          ref={this.textarea}
          value={value}
          onChange={(e, data) => this.onChange(e, data)}
        />
        <Button
          className={"reset-button"}
          color="teal"
          size="mini"
          onClick={this.reset}
        >Reset</Button>
      </Container>
    );
  }
}

export default PipedTextAreaWidget;
