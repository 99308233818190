import _ from "lodash";
import React, {Component} from "react";
import {Message} from "semantic-ui-react";
import {connect} from "react-redux";
import store from "../store/store";
import {errorsClear} from "../actions/actions_errors";
import PropTypes from "prop-types";

export class FormErrors extends Component {
  static propTypes = {
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
  };

  componentDidMount() {
    // clear messages on mount
    store.dispatch(errorsClear());
  }

  renderErrors() {
    const errors = this.props.errors;
    if (_.isObject(errors)) {
      if (_.has(errors, "non_field_errors")) {
        return [errors.non_field_errors];
      }
      if (_.has(errors, "details")) {
        return [errors.details];
      }
      if (Array.isArray(errors)) {
        return _.map(errors, function (v, k) {
          return <span key={k}><span>{k}</span>: {v}<br/></span>;
        });
      }
      return JSON.stringify(errors);
    }
    return String(errors);
  }

  render() {
    const e = this.renderErrors();
    if (e.length <=0) {
      return (
        ""
      );
    }

    return (
      <Message
        className="FormErrors datahive-message-error"
        warning={e.length <= 0}
        header={""}
        content={this.renderErrors()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {errors: state.errors};
}

export default connect(mapStateToProps)(FormErrors);
