import _ from "lodash";
import React from "react";
import {Grid, Header, Divider, Card} from "semantic-ui-react";
import {ContainerWidget} from "../../components/Widgets";
import Registrator from "./Registrator";

import "./QuestionsSet.css";
import {connect} from "react-redux";


class EvaluationContainer extends Registrator {
  componentDidMount() {
    // get widgetVals from question and active answer
    let widgetVals = this.props.question.input;
    let evaluationVals = _.cloneDeep(this.props.question.evaluation_input);

    const activeAnswer = 0;
    if (this.props.question.answers.length) {
      widgetVals = this.mergeQuestionWithAnswer(this.props.question, activeAnswer);
    }

    this.widgetVals = _.cloneDeep(widgetVals);
    this.evaluationVals = evaluationVals;
    this.setState({
      questionId: this.props.question.id,
      widgetVals,
      activeAnswer,
      evaluationVals: evaluationVals
    });
  }

  async onSubmit() {
    const promises = [];
    _.each(this.prepareForSubmitRegister, (fun) => promises.push(fun())); //call prepare for submit on every registered widget.

    await Promise.all(promises)
      .then(() => {
        // const widgetVals = this.splitQuestionFromAnswer();
        const answer = this.props.question.answers[this.state.activeAnswer];
        this.props.onSubmit(this.props.idx, this.state.evaluationVals, answer);
      });
  }

  onUpdate = (widgetVal, componentId) => {
    this.evaluationVals[componentId] = _.cloneDeep(widgetVal);
    this.setState({evaluationVals: this.evaluationVals});
  };


  renderQuestion = () => { // eslint-disable-line react/display-name
    // we are waiting for new question
    if (this.props.question.id !== this.state.questionId) {
      return;
    }

    const config = this.props.config;
    const config_evaluation = this.props.config_evaluation;
    const question = this.props.question;

    const customClass = question.isAnswered ? "question evaluation answered" : "question evaluation";
    return (
      <div key={"question" + question.id.toString()}>
        <Grid className="header" stackable columns={2}>
          <Grid.Column floated="right" className="ui label question-id">
            Question id: {question.id}
          </Grid.Column>
        </Grid>
        <div className="wrapper">
          <div className={customClass}>
            <Divider horizontal>
              <Header as="h4">
                Question
              </Header>
            </Divider>
            <Card className="card-question" fluid>
              <ContainerWidget
                key={"answer1"}
                widgetVals={this.state.widgetVals}
                update={() => null}
                toRender={config}
                getWidgetConfig={() => config}
                componentId={"rootWidget"}
                onSubmit={() => null}
                onSkip={() => null}
                questionId={question.id}
                answerAuthor={null}
                registerValid={() => null}
                registerEmpty={() => null}
                registerPrepareForSubmit={() => null}
                isValid={() => true}
                isEmpty={() => false}
                prepareForSubmit={() => null}
              />
            </Card>
            <Divider horizontal>
              <Header as="h4">
                Evaluation
              </Header>
            </Divider>
            <ContainerWidget
              key={"evaluation1"}
              widgetVals={this.state.evaluationVals}
              update={(widgetVal, componentId) => this.onUpdate(widgetVal, componentId)}
              toRender={config_evaluation}
              getWidgetConfig={() => config_evaluation}
              componentId={"rootWidget"}
              onSubmit={this.onSubmit}
              onSkip={this.props.onSkip ? this.onSkip : null}
              questionId={question.id}
              answerAuthor={null}
              registerValid={this.registerValid}
              registerEmpty={this.registerEmpty}
              registerPrepareForSubmit={this.registerPrepareForSubmit}
              isValid={this.isValid}
              isEmpty={this.isEmpty}
              prepareForSubmit={this.prepareForSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="Question">
        {this.renderQuestion()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
  };
};

export default connect(mapStateToProps)(EvaluationContainer);
