import {toastr} from "react-redux-toastr";
import * as actionTypes from "./types/types_auth";
import ajax from "../helpers/ajax";
import config from "../config/config";
import urls from "../config/frontend_urls";
import store from "../store/store";
import {errorsNew} from "./actions_errors";
import {lastOTPMethod} from "../containers/Auth/SecondFactorLogin";


/* eslint-disable no-unused-vars */

/* eslint-disable no-shadow */


function authClear() {
  localStorage.setItem("AUTH", JSON.stringify({is_logged: false}));
  return {type: actionTypes.LOGOUT};
}

function loginFailure(error) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error
  };
}

function loginSetPerms(perms) {
  localStorage.setItem("AUTH", JSON.stringify({is_logged: true}));
  return {
    type: actionTypes.SET_FRONTEND_USER_PERMS,
    perms
  };
}

export function logout(history) {
  return (dispatch) => {
    ajax.get(config.LOGOUT)
      .then(() => {
        dispatch(authClear());
        history.push(urls.LOGOUT);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // Token expired so ensure that frontend perms will be cleared.
          dispatch(authClear());
          history.push(urls.LOGOUT);
        }
      });
  };
}

export function getConfirmationAccessToUsersData(data) {
  return {
    type: actionTypes.CHANGE_CONFIRMATION_ACCESS_TO_USERS_DATA,
    data
  };
}

export function autoLoginSaveRoute(path) {
  /* State for login from Django with cookie */
  return {
    type: actionTypes.STORE_PATH_FOR_AUTOLOGIN,
    auto_login_path: path ? path : undefined
  };
}

// refresh token every REFRESH_TOKEN_TIME seconds
export function refreshTokenDelayed(history) {
  return (dispatch) => {
    setTimeout(
      () => dispatch(refreshTokenImmediately(history)),
      config.REFRESH_TOKEN_TIME
    );
  };
}

// refresh token after server failure
function refreshTokenAfterError(history, callback) {
  return (dispatch) => {
    ajax.post(config.TOKEN_REFRESH)
      .then(({data}) => {
        dispatch(loginSetPerms(data));
        dispatch(refreshTokenDelayed(history));
        typeof callback == "function" && callback();
      })
      // Logout after another unsuccessful request.
      .catch(() => {
        dispatch(authClear());
        typeof callback == "function" && callback();
        history && history.push(urls.LOGOUT);
      });
  };
}

// refresh token
export function refreshTokenImmediately(history, callback) {
  return (dispatch) =>
    ajax.post(config.TOKEN_REFRESH)
      .then(({data}) => {
        dispatch(loginSetPerms(data));
        dispatch(refreshTokenDelayed(history));
        typeof callback == "function" && callback();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          dispatch(authClear());
          typeof callback == "function" && callback();
          history && history.push(urls.LOGOUT);
        } else {
          // Could be a problem with server availability. Try again.
          setTimeout(
            () => dispatch(refreshTokenAfterError(history, callback)),
            config.REQUEST_REPEAT_DELAY
          );
        }
      });
}

export function getPerms(callback) {
  // Load user permissions
  return (dispatch) => {
    ajax.get(config.FRONTEND_USER_PERMS)
      .then(({data}) => {
        dispatch(loginSetPerms(data));
        typeof callback == "function" && callback();
      })
      .catch((error) => {
        dispatch(errorsNew(error.message));
        dispatch(loginFailure(error.message));
      });
  };
}

// login via API
export function passwordFactorLogin(history, username, password, token = "", callback = null) {
  return (dispatch) => {
    const data = {
      username,
      password
    };

    const isLogged = store.getState().auth.is_logged;
    if (!isLogged) {
      ajax.post(config.PASSWORD_LOGIN + token, {data})
        .then((response) => {
          history.push(urls.SECOND_FACTOR_LOGIN, response.data);
        })
        .catch((err) => {
          dispatch(errorsNew(err.response.data.message));
          typeof callback == "function" && callback();
        });
    }
  };
}

export function otpFactorLogin(history, data) {
  return (dispatch) => {
    ajax.post(config.OTP_LOGIN, {data})
      .then((response) => {
        dispatch(loginSetPerms(response.data));
        dispatch(refreshTokenDelayed(history));
        history.push(urls.HOME);
        localStorage.setItem(lastOTPMethod, data.auth_type);
      })
      .catch((error) => {
        const responseData = error.response.data;
        if (error.response.status === 401) {
          if (responseData["password_change_required"]) {
            toastr.error("Password expired!");
            history.push(urls.PASSWORD_CHANGE, {});
          } else {
            toastr.error("Error", responseData.detail);
            setTimeout(() => history.push(urls.LOGIN), 2000);
          }
        } else {
          toastr.error("Error", responseData.non_field_errors[0]);
        }
      });
  };
}

// login via API
export function loginAs(history, username) {
  return (dispatch) => {
    const data = {username};

    ajax.post(config.LOGIN_AS, {data})
      .then((response) => {
        dispatch(loginSetPerms(response.data));
        dispatch(refreshTokenDelayed(history));
        history.push(urls.HOME);
        toastr.success("Success", `Logged as ${username}`);
      });
  };
}

export const confirmAccessToUsersData = () => {
  return (dispatch) => {
    const params = {
      confirmed_access_to_users_data: true
    };
    ajax.patch([config.FRONTEND_USER_PERMS], {data: params})
      .then((response) => {
        const data = response.data.confirmed_access_to_users_data;
        dispatch(getConfirmationAccessToUsersData(data));
        window.location.reload();
      });
  };
};
