import _ from "lodash";
import React from "react";
import {Button, Grid, Header, Input, Label, Radio} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";

import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";
import ColorPicker from "../../simple/ColorPicker/ColorPicker";

import "./ImageSegmentationWidgetEditor.css";

const defaultColor = "#dddd00";

export default class ImageSegmentationWidgetEditor extends WidgetEditor {
  static header = "ImageSegmentationWidgetEditor";

  state = {
    ...this.state,
    newSegmentType: "",
    numOfVertices: 3,
    color: defaultColor
  }

  handleSegmentationMethodChange = (e, {value}) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.segmentationMethod = value;
      widgetConfig.segmentTypes = [];
    });
    this.setState({newSegmentType: "", numOfVertices: 3, color: defaultColor});
  }

  addSegmentType = () => {
    this.updateWidgetConfig((widgetConfig) => {
      if (this.state.numOfVertices < 3 && this.state.numOfVertices !== null) {
        return toastr.error("Invalid polygon", "Polygon must have at least 3 vertices");
      }
      for (const seg of widgetConfig.segmentTypes) {
        if (seg.name === this.state.newSegmentType) {
          return toastr.error("Wrong segment name!", "Segment name already exists");
        }
      }
      const newSegmentType = {name: this.state.newSegmentType};
      if (widgetConfig.segmentationMethod === "Polygons") {
        newSegmentType.vertices = this.state.numOfVertices;
      }
      if (widgetConfig.segmentationMethod === "Shape" || widgetConfig.segmentationMethod === "Polygons") {
        newSegmentType.color = this.state.color;
      }
      widgetConfig.segmentTypes.push(newSegmentType);
      this.setState({newSegmentType: "", numOfVertices: 3, color: defaultColor});
    });
  };

  removeSegmentType = (segmentTypeName) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.segmentTypes = _.filter(widgetConfig.segmentTypes, ({name}) => {
        return name !== segmentTypeName;
      });
    });
    this.forceUpdate();
  }

  getOppositeColor = (color) => {
    const hex = color.substr(1);
    const oppositeHex = (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1);
    return "#" + oppositeHex;
  }

  render() {
    let config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const segmentTypes = _.map(config.segmentTypes, (segmentType) => {
      return (
        <Grid.Row key={"segment-" + segmentType.name || segmentType}>
          <Grid.Column width={config.segmentationMethod === "Rectangles" ? 14 : 6}>
            {segmentType.name || segmentType}
          </Grid.Column>
          {config.segmentationMethod === "Polygons" &&
            <Grid.Column width={4}>
              {segmentType.vertices === null ? "Infinite" : segmentType.vertices}
            </Grid.Column>
          }
          {(config.segmentationMethod === "Polygons" || config.segmentationMethod === "Shape") &&
            <Grid.Column width={config.segmentationMethod === "Polygons" ? 4 : 8}>
              <Label
                style={{
                  color: this.getOppositeColor(segmentType.color || defaultColor),
                  backgroundColor: segmentType.color || defaultColor,
                }}
              >{segmentType.color || defaultColor}</Label>
            </Grid.Column>
          }
          <Grid.Column width={2}>
            <Button circular icon="minus" size="mini" color="red"
              onClick={() => this.removeSegmentType(segmentType.name || segmentType)}/>
          </Grid.Column>
        </Grid.Row>
      );
    });

    const colorPicker = <ColorPicker
      color={this.state.color}
      onColorChange={(color) => this.setState({color})}
    />;

    return (
      <Grid className={"ImageSegmentationWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h3">Chose segmentation type</Header>
            <Radio
              label="Rectangles"
              value="Rectangles"
              checked={config.segmentationMethod === "Rectangles" || !config.segmentationMethod}
              onChange={this.handleSegmentationMethodChange}
            />
            <Radio
              label="Polygons"
              value="Polygons"
              checked={config.segmentationMethod === "Polygons"}
              onChange={this.handleSegmentationMethodChange}
            />
            <Radio
              label="Shape"
              value="Shape"
              checked={config.segmentationMethod === "Shape"}
              onChange={this.handleSegmentationMethodChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h3">Segment Types</Header>
            <Grid className="segmentTypes">
              {(config.segmentationMethod === "Polygons" || config.segmentationMethod === "Shape") &&
                <Grid.Row>
                  <Grid.Column width={6}>
                    <b>Segment type</b>
                  </Grid.Column>
                  {config.segmentationMethod === "Polygons" &&
                    <Grid.Column width={4}>
                      <b>Number of vertices</b>
                    </Grid.Column>
                  }
                  <Grid.Column width={config.segmentationMethod === "Polygons" ? 4 : 8}>
                    <b>Color</b>
                  </Grid.Column>
                </Grid.Row>
              }
              {segmentTypes}
              <Grid.Row>
                <Grid.Column width={config.segmentationMethod === "Rectangles" ? 14 : 6}>
                  <Input
                    size="mini"
                    fluid
                    placeholder="New segment type"
                    value={this.state.newSegmentType}
                    onChange={(e, {value}) => this.setState({newSegmentType: value})}
                  />
                </Grid.Column>
                {config.segmentationMethod === "Polygons" && <>
                  <Grid.Column width={4}>
                    <Input
                      placeholder="Number of vertices"
                      size="mini"
                      type="number"
                      min={3}
                      disabled={this.state.numOfVertices === null}
                      value={this.state.numOfVertices !== null ? this.state.numOfVertices : ""}
                      onChange={(e, {value}) => this.setState({numOfVertices: Number(value)})}
                    />
                    <Radio
                      toggle
                      checked={this.state.numOfVertices === null}
                      onChange={(e, {checked}) => checked
                        ? this.setState({numOfVertices: null})
                        : this.setState({numOfVertices: 3})}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    {colorPicker}
                  </Grid.Column>
                </>}
                {config.segmentationMethod === "Shape" &&
                  <Grid.Column width={8}>
                    {colorPicker}
                  </Grid.Column>
                }
                <Grid.Column width={2}>
                  <Button
                    size="mini"
                    circular
                    icon="plus"
                    color="green"
                    disabled={
                      !this.state.newSegmentType.length
                      ||
                      !/^#[0-9A-F]{6}$/i.test(this.state.color)
                    }
                    onClick={this.addSegmentType}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}
