import React from "react";
import {connect} from "react-redux";
import {Button, Grid, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";


const RowPriceTariff = (props) =>
  <Grid.Row columns={8} textAlign={"center"}>
    <Grid.Column width={3}>
      {props.date}
    </Grid.Column>
    <Grid.Column width={3}>
      {props.language}
    </Grid.Column>
    <Grid.Column width={2}>
      <Icon
        disabled
        name={props.native === "native" ? "check" : "delete"}
        color={props.native === "native" ? "green" : "red"}
      />
    </Grid.Column>
    <Grid.Column width={2}>
      {props.task_type}
    </Grid.Column>
    <Grid.Column width={props.filterByUser ? 2 : 1}>
      {props.task_subtype}
    </Grid.Column>
    {!props.filterByUser ?
      <Grid.Column width={2}>
        {props.user_type}
      </Grid.Column>
      : null}
    <Grid.Column width={props.filterByUser ? 3 : 2}>
      {props.price} {props.price_currency}
    </Grid.Column>
    {props.isSuperuser &&
      <Grid.Column width={1}>
        <Button.Group>
          <Button
            icon={"wrench"}
            className="datahive-button"
            color="green"
            onClick={() => props.setModal("display", props)}/>
          <Button
            icon={"delete"}
            className="datahive-button"
            color="red"
            onClick={() => props.setModal("delete", props.id)}/>
        </Button.Group>
      </Grid.Column>
    }
  </Grid.Row>;

const mapStateToProps = (state) => ({
  isSuperuser: state.auth.is_superuser
});

RowPriceTariff.propTypes = {
  date: PropTypes.string.isRequired,
  language: PropTypes.string,
  native: PropTypes.bool,
  task_type: PropTypes.string.isRequired,
  task_subtype: PropTypes.string.isRequired,
  filterByUser: PropTypes.bool,
  user_type: PropTypes.bool,
  price: PropTypes.string,
  price_currency: PropTypes.string,
  isSuperuser: PropTypes.bool,
  setModal: PropTypes.func,
  id: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(RowPriceTariff);
