import _ from "lodash";

import React, {PureComponent, useState} from "react";
import memoize from "memoize-one";
import {Form} from "semantic-ui-react";
import PropTypes from "prop-types";
import {DateInput} from "semantic-ui-calendar-react";
import moment from "moment";
import DragAndDropSelector from "../../../../components/simple/DragAndDropSelector/DragAndDropSelector";
import {FormLevenDropdown as FormDropdown} from "../../../../components/simple/Dropdown/LevenDropdown";

import "./Detail.css";
import {getDateFormat} from "../../../../helpers/utils";


export default class Detail extends PureComponent {
  static propTypes = {
    task: PropTypes.object.isRequired, // task informations
    users: PropTypes.array.isRequired, // list of users
    allUsers: PropTypes.array.isRequired, // list of users (incl. inactive)
    coordinators: PropTypes.array.isRequired, // list of coordinators users
    staff: PropTypes.array.isRequired, // list of staff users
    modules: PropTypes.array.isRequired, // list of modules
    languages: PropTypes.array.isRequired, // list of languages
    taskTypes: PropTypes.object.isRequired, // object with task types
    updateTask: PropTypes.func.isRequired,
    errors: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.dateFormat = getDateFormat();

    this.state = {
      moduleOptions: [],
      languageOptions: [],
      ownerOptions: [],
      coordinatorOptions: [],
      taskTypeOptions: [],
      taskSubtypeOptions: []
    };
  }

  componentDidMount() {
    const moduleOptions = _.map(this.props.modules, (module) => (
      {
        text: module.name,
        value: module.id,
        key: module.id
      }
    ));
    const languageOptions = _.map(this.props.languages, (lang) => (
      {
        text: lang.display_name,
        value: lang.name,
        key: lang.name
      }
    ));
    const coordinatorOptions = _.map(this.props.coordinators, (user) => (
      {
        text: user.username,
        value: user.id,
        key: user.id
      }
    ));
    const ownerOptions = _.map(this.props.staff, (user) => (
      {
        text: user.username,
        value: user.id,
        key: user.id
      }
    ));
    const taskTypeOptions = _.map(Object.keys(this.props.taskTypes), (type) => (
      {
        text: type,
        value: type,
        key: type.id
      }
    ));
    const taskSubtypeOptions = this.props.task.type ? _.map(this.props.taskTypes[this.props.task.type], (user) => (
      {
        text: user.name,
        value: user.name,
        key: user.name
      }
    )) : [];
    this.setState({
      moduleOptions,
      languageOptions,
      ownerOptions,
      coordinatorOptions,
      taskTypeOptions,
      taskSubtypeOptions
    });
  }

  changeTask = (newVal) => {
    this.props.updateTask({
      ...this.props.task,
      ...newVal
    });
  }

  debouncedChangeTask = _.debounce(this.changeTask, 1000, {
    leading: false,
    trailing: true
  });

  onMultiChange = (diff) => {
    const task = _.cloneDeep(this.props.task);
    _.forEach(diff, (value, key) => _.set(task, key, value));
    this.props.updateTask(task);
  }

  renderModuleDropdown = (task) => {
    let label = "Module";
    const errors = _.get(this.props.errors, "module");
    if (errors) {
      label += " - " + errors.join();
    }

    return (
      <FormDropdown
        className={"module"}
        label={label} placeholder="Module" search selection
        options={this.state.moduleOptions}
        error={!!_.get(this.props.errors, "module")}
        value={task.module || ""}
        onChange={(e, data) => this.changeTask({
          module: data.value
        })}
      />
    );
  };

  renderLanguageDropdown = (task) => {
    let label = "Language";
    const errors = _.get(this.props.errors, "language");
    if (errors) {
      label += " - " + errors.join();
    }

    return (
      <FormDropdown
        className={"language"}
        label={label} placeholder="Language" search selection
        options={this.state.languageOptions}
        error={!!_.get(this.props.errors, "language")}
        value={task.language || ""}
        onChange={(e, data) => this.changeTask({
          language: data.value
        })}
      />
    );
  };

  renderOwnerDropdown = (task) => {
    let label = "Owner";
    const errors = _.get(this.props.errors, "owner");
    if (errors) {
      label += " - " + errors.join();
    }
    return (
      <FormDropdown
        className={"owner"}
        label={label} placeholder="Owner" search selection
        options={this.state.ownerOptions}
        error={!!_.get(this.props.errors, "owner")}
        value={task.owner || ""}
        onChange={(e, data) => this.changeTask({
          owner: data.value
        })}
      />
    );
  };

  renderSamsungCoordinatorDropdown = (task) => {
    let label = "Samsung coordinator";
    const errors = _.get(this.props.errors, "samsung_coordinator");
    if (errors) {
      label += " - " + errors.join();
    }
    return (
      <FormDropdown
        className={"samsung-coordinator"}
        label={label} placeholder="Samsung coordinator" search selection
        options={this.state.coordinatorOptions}
        error={!!_.get(this.props.errors, "samsung_coordinator")}
        value={task.samsung_coordinator || ""}
        onChange={(e, data) => this.changeTask({
          samsung_coordinator: data.value
        })}
      />
    );
  };

  getInputArgs = memoize((errors, task) => {
    const paths = {
      title: "title",
      questions_at_page: "questions_at_page",
      max_answers: "max_answers",
      possible_empty_answers: "possible_empty_answers",
      possible_review: "possible_review",
      can_linguist_start_discussion: "can_linguist_start_discussion",
      enable_evaluations: "enable_evaluations",
      enable_search_evaluations: "enable_search_evaluations"
    };

    return _.mapValues(paths, (path, idx) => {
      let label = _.capitalize(path).split("_").join(" ");
      const error = _.get(errors, idx);
      if (error) {
        label += " - " + error.join();
      }
      const value = _.get(task, idx);

      return {
        value: value || "",
        checked: value,
        label,
        placeholder: label,
        error: !!error,
        onChange: ((e) =>
          this.changeTask({
            idx: e.target.value
          })
        ),
        className: idx,
      };
    });
  }, _.isEqual);

  render() {
    const {task} = this.props;

    const inputArgs = this.getInputArgs(this.props.errors, task);

    const deadline = _.get(task, "end_date") ?
      moment(_.get(task, "end_date")).format(this.dateFormat) : "";

    return (
      <div className={"Detail"}>
        <Form>
          <div className={"questions-answers"}>
            <TitleInput
              args={inputArgs.title}
              title={task.title}
              onChange={this.debouncedChangeTask}
            />
            {this.renderModuleDropdown(task)}
            {this.renderLanguageDropdown(task)}
          </div>
          <div className={"questions-answers"}>
            {this.renderOwnerDropdown(task)}
            {this.renderSamsungCoordinatorDropdown(task)}
            <DateInput
              dateFormat={this.dateFormat}
              className={"deadline"}
              popupPosition="bottom left"
              closable={true}
              key={"deadline"}
              name="deadline"
              placeholder="Deadline"
              label={"Deadline"}
              value={deadline}
              onChange={(e, {value}) => {
                // add 24h to deadline so it won't block task for example in the morning on deadline date
                const deadline = value ? moment(value, this.dateFormat).add(86399, "seconds").toISOString() : null;
                this.changeTask({end_date: deadline});
              }}
            />
          </div>
          <div className={"questions-answers"}>
            <Form.Input
              className={"narrow-field"}
              inline
              type="number"
              min={1}
              {...inputArgs.questions_at_page}
              onChange={(e) =>
                this.changeTask({
                  questions_at_page: parseInt(e.target.value)
                })
              }
            />
            <Form.Input
              className={"narrow-field"}
              inline
              type="number"
              min={1}
              {...inputArgs.max_answers}
              onChange={(e) =>
                this.changeTask({
                  max_answers: parseInt(e.target.value)
                })
              }
            />
            <Form.Checkbox
              {...inputArgs.possible_empty_answers}
              value={""}
              onChange={((e, data) => {
                this.changeTask({possible_empty_answers: data.checked});
              })}
              label={"possible empty answers"}
            />
            <Form.Checkbox
              {...inputArgs.possible_review} value={""}
              onChange={((e, data) => {
                this.changeTask({possible_review: data.checked});
              })}
              label={"possible review"}
            />
            <Form.Checkbox
              {...inputArgs.can_linguist_start_discussion} value={""}
              onChange={((e, data) => {
                this.changeTask({can_linguist_start_discussion: data.checked});
              })}
            />
            <Form.Checkbox
              {...inputArgs.enable_evaluations} value={""}
              onChange={(e, {checked}) => {
                if (!checked) {
                  this.onMultiChange({
                    "enable_evaluations": checked,
                    "enable_search_evaluations": checked
                  });
                } else {
                  this.changeTask({enable_evaluations: checked});
                }
              }}
            />
            <Form.Checkbox
              {...inputArgs.enable_search_evaluations} value={""}
              disabled={!this.props.task.enable_evaluations}
              onChange={((e, data) => {
                this.changeTask({enable_search_evaluations: data.checked});
              })}
            />
          </div>
          <div className={"types"}>
            <FormDropdown
              className={"type"}
              label={"Task type"}
              placeholder="Task type"
              search selection
              options={this.state.taskTypeOptions}
              error={!!_.get(this.props.errors, "type")}
              value={task.type || ""}
              onChange={(e, data) => this.changeTask({type: data.value})}
            />
            <FormDropdown
              className={"subtype"}
              label={"Task subtype"}
              placeholder="Task subtype"
              search selection
              options={this.state.taskSubtypeOptions}
              error={!!_.get(this.props.errors, "subtype")}
              value={task.subtype || ""}
              onChange={(e, data) => this.changeTask({subtype: data.value})}
            />
          </div>
          <Form.Field className={"resizer"}>
          </Form.Field>
        </Form>
        <div className={"form-bottom"}>
          <div className={"moderators"}>
            <label>Moderators</label>
            <DragAndDropSelector
              choices={this.props.users || []}
              allOptions={this.props.allUsers || []}
              choiceValue={"username"}
              filterable
              selectAll
              selected={task.moderators || []}
              afterSelectionChange={(newVal) => this.changeTask({
                moderators: newVal
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

const TitleInput = ({title, onChange, args}) => {
  const [input, setInput] = useState(title);

  return <Form.Input
    {...args}
    value={input}
    onChange={(e) => {
      const value = e.target.value;
      setInput(value);
      const slug = _.replace(value, / +/g, "-").toLowerCase();
      onChange({
        title: value,
        slug
      });
    }}
  />;
};

TitleInput.propTypes = {
  args: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.string
};
