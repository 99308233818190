import React from "react";
import {Group, Line} from "react-konva/lib/ReactKonvaCore";
import PropTypes from "prop-types";

const Edge = ({points, color="yellow"}) =>
  <Group>
    <Line
      points={points}
      stroke="black"
      strokeWidth={4}
    />
    <Line
      points={points}
      stroke={color}
      strokeWidth={1}
    />
  </Group>;

Edge.propTypes = {
  points: PropTypes.arrayOf(PropTypes.number).isRequired,
  color: PropTypes.string,
};

export default Edge;
