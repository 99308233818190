import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Label as KonvaLabel, Text, Tag} from "react-konva/lib/ReactKonvaCore";
import PropTypes from "prop-types";

const Label = ({x, y, name, inverted, onSetIsDraggable, onSegmentSelection, onRightClick, onCursorChange}) => {
  const [key, setKey] = useState(_.uniqueId());

  useEffect(() => {
    setKey(_.uniqueId());
    onCursorChange("default");
  }, [inverted, onCursorChange]);

  return <KonvaLabel
    key={key}
    x={x}
    y={y}
    onMouseDown={() => {
      onSetIsDraggable(true);
      onSegmentSelection();
    }}
    onMouseUp={() => onSetIsDraggable(false)}
    onContextMenu={onRightClick}
    onMouseOver={() => onCursorChange("move")}
    onMouseOut={() => onCursorChange("default")}>
    <Tag
      fill="black"
      pointerDirection={inverted ? "up" : "down"}
      pointerWidth={5}
      pointerHeight={7}/>
    <Text
      text={name}
      fontSize={10}
      padding={3}
      fill="white"/>
  </KonvaLabel>;
};


Label.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  inverted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSetIsDraggable: PropTypes.func.isRequired,
  onSegmentSelection: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,
  onCursorChange: PropTypes.func.isRequired,
};

export default Label;
