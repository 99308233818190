import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, Input, Form, Segment, Dropdown} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";

import LoaderSpinner from "../../../components/LoaderSpinner";
import ajax from "../../../helpers/ajax";
import {getPossibleDropdownValues} from "../../../helpers/utils";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import DragAndDropSelector from "../../../components/simple/DragAndDropSelector/DragAndDropSelector";
import ContainerDetail from "../../../components/ContainerDetail";


class CompanyDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.emptyCompany = {
      id: null,
      name: "",
      division: null,
      accessible_to_staff: false,
      individual_rates: false,
      special_users: [],
      users: [],
      contact_points: [],
      is_active: true
    };
    this.state = {
      allUsers: [],
      users: [],
      displaySpinner: true,
      company: {...this.emptyCompany},
      displayDeleteModal: false
    };
  }

  componentDidMount() {
    this.getData();
    this.getUsers();
  }

  getData = () => {
    if (!this.props.location.pathname.includes(urls.COMPANY_DETAIL_NEW)) {
      ajax.get([config.COMPANY_DETAIL, this.props.match.params.id])
        .then((response) => {
          this.setState({
            company: response.data,
            displaySpinner: false,
          });
        });
    } else {
      this.setState({
        displaySpinner: false,
      });
    }
  };

  getUsers = () => {
    let options;
    ajax.get(config.USER_LIST_ALL, {params: {include_inactive: true}})
      .then((response) => {
        this.setState({
          allUsers: response.data,
          users: _.filter(response.data, "is_active")
        });
      });
    ajax.get(config.ALL_DIVISIONS)
      .then((response) => {
        options = getPossibleDropdownValues("id", "name", response.data);
        this.setState({
          divisions: options
        });
      });
  };

  save = () => {
    let data = _.cloneDeep(this.state.company);
    if (!this.props.location.pathname.includes(urls.COMPANY_DETAIL_NEW)) {
      ajax.patch([config.COMPANY_DETAIL, data.id], {data})
        .then(() => {
          toastr.success("Success", "Company updated!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    } else {
      ajax.post(config.COMPANIES, {data})
        .then(() => {
          this.props.history.push(urls.COMPANIES);
          toastr.success("Success", "Company saved!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    }
  }

  delete = () => {
    let data = _.cloneDeep(this.state.company);
    if (!this.props.location.pathname.includes(urls.COMPANY_DETAIL_NEW)) {
      ajax.delete([config.COMPANY_DETAIL, data.id], {data})
        .then(() => {
          this.props.history.push(urls.COMPANIES);
          toastr.success("Success", "Company deleted!");
        })
        .catch((error) => {
          toastr.error("Error!", error.response);
        });
    } else {
      toastr.error("Error!", "Company doesn't exist");
    }
  }

  changeCompany = (newVal) =>
    this.updateCompany({
      ...this.state.company,
      ...newVal
    });

  updateCompany = (company) => {
    this.setState({company});
  };

  toggleModal = () => {
    this.setState((prevState) => ({displayDeleteModal: !prevState.displayDeleteModal}));
  };

  render() {
    const {company, allUsers, users, divisions, displaySpinner, displayDeleteModal} = this.state;
    const {history} = this.props;
    const selectableUsers = _.map(users, function(value) {
      return {key: value.id,
        text: value.username,
        value: value.id}
    });
    const styleInactive = {
      backgroundColor: "#ebebeb",
      pointerEvents: "none",
      opacity: "0.5"
    }
    const details = <Segment.Group>
      <Segment className="datahive-segment">
        <Form.Group>
          <Form.Field>
            <Checkbox
              className={"green is-active green-radio"}
              label={"is active"}
              checked={company.is_active}
              onChange={(e, {checked}) => this.changeCompany({is_active: checked})}
            />
          </Form.Field>
        </Form.Group>
      </Segment>
      <Segment
        style={!company.is_active ? styleInactive : null}
        className={"datahive-segment"}>
      <Form>
        <Form.Group>
          <Form.Field className={"companyName"}>
            <label>Company name</label>
            <Input
              placeholder="Name"
              value={company.name}
              onChange={(e) => this.changeCompany({name: e.target.value})}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field className={"DragAndDropSelectorGroup"}>
            <label>Special users</label>
            {allUsers
              ? <DragAndDropSelector
                name={"special_users"}
                allOptions={allUsers}
                choices={users}
                choiceValue={"username"}
                filterable
                selectAll
                selected={company.special_users || []}
                afterSelectionChange={(usr) => this.changeCompany({special_users: usr})}
              />
              : <LoaderSpinner/>
            }
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width="6">
            <label>Contact points</label>
            { selectableUsers ?
                <Dropdown
                    placeholder='Email'
                    fluid
                    multiple
                    search
                    selection
                    value={company.contact_points}
                    options={selectableUsers}
                    onChange={(e, {value}) => this.changeCompany({contact_points: value})}
                />
              :
                <Dropdown disabled loading />
            }
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              id={"individual_rates"}
              name={"individual_rates"}
              checked={company.individual_rates}
              onChange={(e, {checked}) => this.changeCompany({individual_rates: checked})}
              label={"individual rates"}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              id={"accessible_to_staff"}
              name={"accessible_to_staff"}
              checked={company.accessible_to_staff}
              onChange={(e, {checked}) => this.changeCompany({accessible_to_staff: checked})}
              label={"staff has access to company data"}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>User Division</label>
            <Form.Dropdown
              id="division"
              placeholder="Select Division"
              selection
              options={divisions || []}
              value={company.division ? company.division : ""}
              onChange={(e, {value}) => this.changeCompany(
                {division: value}
              )}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field className={"DragAndDropSelectorGroup"}>
            <label>Users</label>
            {allUsers
              ? <DragAndDropSelector
                allOptions={allUsers}
                choices={users}
                choiceValue={"username"} // Display user's username.
                filterable
                selectAll
                afterSelectionChange={(usr) => this.changeCompany({users: usr})}
                selected={company.users || []}/>
              : <LoaderSpinner/>
            }
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  </Segment.Group>;
    const panelRight =
      <Button color="green"
        className="datahive-button"
        onClick={this.save}
        content="Save"
      />;
    const panelLeft =
      <Button color="red"
        className="datahive-button"
        onClick={this.toggleModal}
        content="Delete"
      />;
    const title = "Are you sure you want to delete this company?";
    const buttons = <>
      <Button onClick={this.delete} content="Delete" color="red" className="datahive-button" id={"delete"} floated={"left"}/>
      <Button onClick={this.toggleModal} className="datahive-button" color="dark-grey" content="Cancel"/>
    </>;

    return <ContainerDetail
      details={details}
      url={urls.COMPANY_DETAIL}
      urlNew={urls.COMPANY_DETAIL_NEW}
      button={buttons}
      item={company}
      history={history}
      displaySpinner={displaySpinner}
      displayDeleteModal={displayDeleteModal}
      panelRight={panelRight}
      panelLeft={panelLeft}
      title={title}
      toggleModal={this.toggleModal}
    />;
  }
}

export default withRouter(CompanyDetail);
