import _ from "lodash";
import React, {Component} from "react";
import {Dropdown} from "semantic-ui-react";
import {getNode, getOptions, search} from "./utils";
import PropTypes from "prop-types";


class TreeDropdown extends Component {
  static propTypes = {
    searchQuery: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    value: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    statelessParents: PropTypes.bool,
    parentUpdateChangeChildren: PropTypes.bool,
    onSearchChange: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    dropdownOpen: PropTypes.bool,
    tree: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.treeContainerRef = React.createRef();
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 13 && this.props.searchQuery.length > 0) {
      e.preventDefault();
      this.props.handleSubmit(this.props.searchQuery);
    }
  };

  close = () => {
    //ie has the event messed up and closes the component for no reason. this solves this problem.
    const ua = window.navigator.userAgent;
    if ((ua.indexOf("MSIE ") > -1) || (ua.indexOf("Trident/") > -1)) {
      return;
    }
    this.setState({open: false});
  }

  render() {
    let text = null;
    if (!this.props.multiple && this.props.value) {
      const node = getNode(this.props.data, this.props.value[0]);
      if (node) {
        text = node.title ? node.title : node.value;
      }
    }

    //always open on IE.
    const ua = window.navigator.userAgent;
    const open = ((ua.indexOf("MSIE ") > -1) || (ua.indexOf("Trident/") > -1)) || this.state.open;

    return (
      <div
        onKeyUp={this.handleKeyUp}
        tabIndex="1"
        onBlur={(e) => {
          e.stopPropagation();
          this.close();
        }}>
        <Dropdown
          className={`hiddendropdown-spooky ${open ? "open-dropdown" : ""}`}
          fluid selection
          placeholder={"Search"}
          text={text}
          search={search}
          multiple={this.props.multiple}
          options={getOptions(this.props.data, this.props.statelessParents, this.props.parentUpdateChangeChildren)}
          value={this.props.multiple ? this.props.value : _.last(this.props.value) || null}
          // cant be done by search function override because its called during render. And setting the state of a parent during render is bad.
          onSearchChange={(e, data) => {
            this.props.onSearchChange(data.searchQuery);
          }}
          searchQuery={this.props.searchQuery}
          open={false}
          onBlur={(e) => {
            e.stopPropagation();
            if (e.relatedTarget !== this.treeContainerRef.current) {
              this.close();
            }
          }}
          onFocus={() => {
            this.setState({open: true});
          }}
          onChange={this.props.multiple ? this.props.handleChange : null}
        />
        {(open || this.props.dropdownOpen) &&
        <div
          className={"dropdown-content"}
          ref={this.treeContainerRef}
          tabIndex="0"
          onBlur={(e) => {
            e.stopPropagation();
            this.close();
          }}
        >
          {this.props.tree}
        </div>
        }
      </div>
    );
  }
}

export default TreeDropdown;
