import _ from "lodash";
import React from "react";
import FilteredComponent from "../../../components/FilteredComponent";
import { toastr } from "react-redux-toastr";
import { Input, Table, Button, Segment } from "semantic-ui-react";
import ajax from "../../../helpers/ajax";
import qs from "query-string";

import config from "../../../config/config";
import LoaderSpinner from "../../../components/LoaderSpinner";
import SortableTable from "../../../components/simple/SortableTable/SortableTable";
import Pagination from "../../../components/Pagination";
import BottomBar from "../../../components/BottomBar";
import ModalConfirm from "../../../components/ModalConfirm";
import QuestionListRow from "./QuestionListRow";
import QuestionAnswerTable from "./QuestionAnswerTable";

import "./QuestionAdminByTaskList.css";


export class QuestionAdminByTaskList extends FilteredComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      questions: null,
      id: _.get(qs.parse(props.history.location.search), "id") || "",
      answer_cnt: _.get(qs.parse(props.history.location.search), "answer_cnt") || "",
      totalPages: 1,
      totalQuestions: 0,
      selectedQuestions: [],
      deleteModal: false,
      deleteData: null,
      deleteAllMode: false,
      totalAnswers: 0
    };
  }

  getData = (filters = null) => {
    const params = filters ? filters : this.getFilters();
    this.setState({ questions: null });
    ajax.get([config.ADMIN_QUESTIONS_BY_TASK, this.props.match.params.taskSlug], { params })
      .then((response) => {
        if (response.data) {
          this.setState({
            questions: response.data.results,
            totalPages: response.data.num_pages,
            totalQuestions: response.data.count,
            display_spinner: false
          });
        }
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  };

  deleteAllQuestions = () => {
    ajax.get([config.ADMIN_ALL_ANSWERS_COUNT_BY_TASK, this.props.match.params.taskSlug])
      .then((response) => {
        if (response.data) {
          this.setState({ deleteAllMode: true, deleteModal: true, totalAnswers: response.data.answer_cnt });
        }
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  getDeleteData = () => {
    ajax.get(config.ADMIN_QUESTIONS_DETAIL.slice(0, -1), { params: { ids: this.state.selectedQuestions } })
      .then((response) => this.setState({ deleteData: response.data }))
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  bulkDelete = () => {
    ajax.delete([config.ADMIN_QUESTIONS_BY_TASK, this.props.match.params.taskSlug], { data: this.state.selectedQuestions })
      .then((response) => {
        toastr.success("Success bulk delete!", "Questions with answers deleted successfully");
        this.setState({
          questions: response.data.results,
          totalPages: response.data.num_pages,
          totalQuestions: response.data.count,
          deleteModal: false
        });
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  allDelete = () => {
    ajax.delete([config.ADMIN_DELETE_ALL_QUESTIONS_BY_TASK, this.props.match.params.taskSlug])
      .then((response) => {
        toastr.success("Success all questions delete!", "Questions with answers deleted successfully");
        this.setState({
          questions: response.data.results,
          totalPages: response.data.num_pages,
          totalQuestions: response.data.count,
          deleteModal: false
        });
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  pageChange = (e, { activePage }) => {
    const newFilters = this.updateFilters({ page: activePage });
    this.getData(newFilters);
  };

  idChange = (e, data) => {
    this.searchChange(data.value, "id");
  }

  cntChange = (e, data) => {
    if (data.value < 0) {
      return;
    }
    this.searchChange(data.value, "answer_cnt");
  };

  handleSelection = (e, { checked, label: { props: { "data-q-id": qId } } }) => {
    if (checked) {
      this.setState({ selectedQuestions: this.state.selectedQuestions.concat(qId) });
    } else {
      this.setState({ selectedQuestions: this.state.selectedQuestions.filter((id) => id !== qId) });
    }
  }

  manageDeleteModal = () => {
    if (this.state.deleteModal) {
      this.setState({ deleteModal: false, deleteData: null, deleteAllMode: false });
    } else {
      this.setState({ deleteModal: true }, this.getDeleteData);
    }
  }

  deleteButtonProps = {
    size: "large",
    color: "red",
    content: "Delete"
  }

  render() {
    const dataLoaded = !!this.state.questions;

    const filters = this.getFilters();
    const columns = [
      { name: "id", title: "Id" },
      { name: "answer_cnt", title: "Answer count" },
    ];

    let deleteModalContent = <LoaderSpinner />;
    if (this.state.deleteAllMode) {
      deleteModalContent = <h2>Answers for this task: {this.state.totalAnswers}</h2>;
    } else if (this.state.deleteData) {
      deleteModalContent = this.state.deleteData.map((q) =>
        <Segment key={q.id}>
          <h3>Question {q.id}:</h3>
          <QuestionAnswerTable answers={q.answers} />
        </Segment>);
    }
    return (
      <div className="QuestionAdminByTaskList">
        <Table>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width={5}>
                Task: {this.props.match.params.taskSlug}
              </Table.HeaderCell>
              <Table.HeaderCell width={5}>
                Number of questions: {this.state.totalQuestions}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
        Search for: <Input
          placeholder="Question ID"
          onChange={this.idChange}
          value={this.state.id} />
        <Button
          color="red"
          className="datahive-button"
          floated="right"
          disabled={this.state.deleteAllMode}
          onClick={this.deleteAllQuestions}
          content="Delete all questions" />
        <SortableTable
          columns={columns}
          sorted={_.get(filters, "order_by")}
          tableProps={{ compact: true, className: "QuestionAdminListByTask" }}
          updateSorting={this.updateSorting}>
          <Table.Body>
            {!dataLoaded &&
              <Table.Row><Table.Cell colSpan={2}><LoaderSpinner /></Table.Cell></Table.Row>}
            {dataLoaded && _.map(
              this.state.questions, (q) =>
                <QuestionListRow
                  question={q}
                  key={q.id}
                  checked={!!this.state.selectedQuestions.find((id) => id === q.id)}
                  handleSelection={this.handleSelection} />)}
          </Table.Body>
        </SortableTable>
        <BottomBar
          panelLeft={
            <Button
              {...this.deleteButtonProps}
              disabled={!this.state.selectedQuestions.length}
              onClick={this.manageDeleteModal} />}
          panelRight={
            <Pagination
              activePage={filters.page || 1}
              onPageChange={this.pageChange}
              totalPages={this.state.totalPages} />} />
        {this.state.deleteModal &&
          <ModalConfirm
            title="Are you sure you want to delete selected questions with all their answers?"
            content={<div className="DeleteAllModal">{deleteModalContent}</div>}
            closeModal={this.manageDeleteModal}
            button={
              <Button
                {...this.deleteButtonProps}
                disabled={!this.state.deleteAllMode && !this.state.deleteData}
                onClick={this.state.deleteAllMode ? this.allDelete : this.bulkDelete} />} />}
      </div>
    );
  }
}

export default QuestionAdminByTaskList;
