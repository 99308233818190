import _ from "lodash";
import React from "react";
import {Checkbox, Grid, Header} from "semantic-ui-react";

import JSONEditorReact from "../../simple/JSONEditor/JSONEditor";
import "jsoneditor/dist/jsoneditor.css";

import {getWidget, removeWidget, updateWidgetConfig} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class SIOSTRAWidgetEditor extends WidgetEditor {
  static header = "SIOSTRA Annotation Tool";

  static getDefaultVals = (props) => {
    const widgetVals = {
      originalString: "Placeholder string. Second placeholder sentence. Last placeholder extra sentence, this one is longer.",
      ranges: [],
    };

    props.updateVals(widgetVals, props.widgetId);
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      codeMode: false,
      selectedClasses: [],
    };

    this.updateClasses = this.updateClasses.bind(this);
    this.deleteWidget = this.deleteWidget.bind(this);
    this.updateWidgetConfig = updateWidgetConfig.bind(this);

    this.opts = [];
    this.classesOptions = _.map(this.opts, (opt) => {
      return {key: opt, text: opt, value: opt};
    });
  }

  updateClasses(e, data) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.customClass = data.value.join(" ");
    });
  }

  deleteWidget() {
    const config = [...this.props.getWidgetConfig()];
    removeWidget(config, this.state.widgetId);
    this.props.updateConfig(config);
  }

  configChange = (data) => {
    const config = data;
    const widgetConfig = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    data.componentId = widgetConfig.componentId;
    data.componentType = widgetConfig.componentType;
    config.popupContent = widgetConfig.popupContent;
    this.updateWidgetConfig((wC) => {
      _.each(config, (val, key) => {
        wC[key] = val;
      });
      _.each(wC, (val, key) => {
        if (config[key] === undefined) {
          delete wC[key];
        }
      });
    });
  };

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const displayConfig = _.cloneDeep(config);
    delete displayConfig.popupContent;
    delete displayConfig.componentType;
    delete displayConfig.componentId;

    return (
      <Grid className={"SIOSTRAWidgetEditor"} centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <div className={""}>
              <Header
                className="row"
                as="h4">Edit text</Header>
              <Checkbox
                className="row"
                toggle
                checked={this.state.codeMode}
                onChange={(e, data) => this.setState({codeMode: data.checked})}/>
              <Header as={"h4"}>Config</Header>
              <JSONEditorReact
                id={"a_unique_id"}
                key={this.state.codeMode}
                className={"json-editor"}
                value={displayConfig}
                mode={ this.state.codeMode ? "code" : "tree"}
                onChange={this.configChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}

export default SIOSTRAWidgetEditor;
