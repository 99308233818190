import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Icon} from "semantic-ui-react";
import "./Stars.css";

export default class Stars extends Component {
  /*
  This component displays stars based on the number it gets in the props.
   */
  static propTypes = {
    count: PropTypes.number,
    /* Amount of stars that will be displayed. Non even numbers are displayed as half of a star. */
    size: PropTypes.string,
    /* Size of the icons to be displayed. Possible values can be found in React Semantic UI documentation about icons. */
    max: PropTypes.number,
    /* Max count of stars. If this prop is passed, empty stars will be rendered to fill out the difference between count and max. */
    showEmpty: PropTypes.bool,
    /* Show stars up to max value with empty outlines. */
  };

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  wrapIcon(icon, idx) {
    return (
      <div className={"star-container"} key={idx}>
        {icon}
      </div>
    );
  }

  render() {
    if (!this.props.count) {
      return null;
    }
    const icons = [];
    const size = this.props.size;
    const max = this.props.max;
    const count = this.props.count;
    for (let i = 0; i < parseInt(count); i++) {
      icons.push(this.wrapIcon(<Icon color="yellow" name="star" size={size}/>, icons.length));
    }
    if (count % 1 !== 0) {
      icons.push(this.wrapIcon(<Icon color="yellow" name="star half" size={size}/>, icons.length));
    }
    if (this.props.showEmpty && this.props.max && this.props.max !== count) {
      if (count % 1 !== 0) {
        icons.push(this.wrapIcon(
          <Icon className={"flipped"} color="yellow" name="star half empty" size={size}/>, icons.length));
      }
      for (let i = 0; i < parseInt((max - count)); i++) {
        icons.push(this.wrapIcon(<Icon color="yellow" name="star outline" size={size}/>, icons.length));
      }
    }

    return (
      <div className={"Stars"}>
        {icons}
      </div>
    );
  }
}
