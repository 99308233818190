import _ from "lodash";
import React, {useRef, useState} from "react";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";
import {Pagination} from "semantic-ui-react";
import {Stage, Layer} from "react-konva/lib/ReactKonvaCore";

import Widget from "../../Widget";
import Segment from "./Segment";
import useMousePosition from "../Hooks/useMousePosition";
import useCanvasResize from "../Hooks/useCanvasResize";
import useSegmentsState from "../Hooks/useSegmentsState";
import useRegisterEmpty from "../Hooks/useRegisterEmpty";
import useRegisterValid from "../Hooks/useRegisterValid";
import useUpdateWidgetValue from "../Hooks/useUpdateWidgetValue";

import "./PolygonSegmentationWidget.css";

const PolygonSegmentationWidget = (props) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [canvasSize, setCanvasSize] = useCanvasResize();
  const imageRef = useRef();

  const mousePosition = useMousePosition(imageRef);

  let images = props.value ? props.value.images : [null];

  const [selectedSegment, setSelectedSegment] = useState({idx: null, active: false});

  const [segments, segmentsUpdateUtil] = useSegmentsState(
    props.value && props.value.segmentsOnPages
      ? props.value.segmentsOnPages.map(
        (img) => img.map(({type, absoluteVertices}) => ({
          key: _.uniqueId(),
          type,
          isDone: true,
          isEdit: false,
          absoluteVertices,
        }))
      )
      : images.map(() => []),
    activePageIndex, selectedSegment
  );

  const [isCreationMode, setIsCreationMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const {registerEmpty, registerValid, componentId, value, update} = props;

  useRegisterEmpty(segments, registerEmpty, componentId);
  useRegisterValid(segments, registerValid, componentId);
  useUpdateWidgetValue(segments, (!isCreationMode && !isEditMode), componentId, update, value);

  const [clickedPoint, setClickedPoint] = useState(null);
  const handleClickedPoint = (e) => {
    if (isCreationMode || isEditMode) {
      setClickedPoint(null);
    } else {
      setClickedPoint({
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY
      });
    }
  };

  const handleAddSegment = (e, {segmentType}) => {
    segmentsUpdateUtil((activeImage) =>
      activeImage.push({
        key: _.uniqueId(),
        type: segmentType,
        ...clickedPoint,
        isDone: false,
        isEdit: false,
        absoluteVertices: []
      }));
    setIsCreationMode(true);
  };

  const handleSegmentDone = (absolutePositions) => {
    segmentsUpdateUtil((activeImage) => {
      const lastSegment = activeImage[activeImage.length - 1];
      lastSegment.isDone = true;
      lastSegment.absoluteVertices = absolutePositions;
    });
    setIsCreationMode(false);
  };

  const handleAbortCreation = () => {
    segmentsUpdateUtil((activeImage) => activeImage.splice(-1, 1));
    setIsCreationMode(false);
  };

  const handleDeleteSegment = () => {
    let removed;
    segmentsUpdateUtil((activeImage) =>
      removed = activeImage.splice(selectedSegment.idx, 1)[0]
    );
    setSelectedSegment({idx: null, active: false});
    if (removed.isEdit) {
      setIsEditMode(false);
    }
  };

  const handleSetEditMode = () => {
    segmentsUpdateUtil((activeImage, activeSegment) => activeSegment.isEdit = true);
    setIsEditMode(true);
  };

  const usedSegmentRef = React.useRef(); /*currently created or edited segment ref,
    must be invoked from React object for testing utility on line 164 in
    src/tests/Widgets/ImageSegmentationWidget/PolygonSegmentationWidget/PolygonSegmentationWidget.test.js
  */

  const handleSaveEdit = () => {
    segmentsUpdateUtil((activeImage, activeSegment) => {
      activeSegment.isEdit = false;
      activeSegment.absoluteVertices = usedSegmentRef.current.getAbsoluteVertices();
    });
    setIsEditMode(false);
    setSelectedSegment({idx: null, active: false});
  };

  const handleDragEnd = (absolutePositions) => {
    segmentsUpdateUtil((activeImage, activeSegment) => {
      activeSegment.absoluteVertices = absolutePositions;
    });
  };

  const handlePagination = (e, {activePage}) => {
    if (isCreationMode) {
      handleAbortCreation();
    } else if (isEditMode) {
      handleSaveEdit();
    }
    setActivePageIndex(activePage - 1);
  };

  let contextMenuItems;
  if (isCreationMode) {
    contextMenuItems = <>
      <MenuItem
        onClick={handleAbortCreation}>
        Abort creation
      </MenuItem>
      {usedSegmentRef.current && usedSegmentRef.current.verticesCount > 1 &&
        <MenuItem onClick={() => usedSegmentRef.current.undo()}>
          Undo
        </MenuItem>
      }
    </>;
  } else if (selectedSegment.active !== false || isEditMode) {
    contextMenuItems = <>
      <MenuItem
        onClick={isEditMode ? handleSaveEdit : handleSetEditMode}>
        {isEditMode ? "Save" : "Edit"}
      </MenuItem>
      <MenuItem
        onClick={handleDeleteSegment}>
        Delete
      </MenuItem>
    </>;
  } else {
    contextMenuItems = props.widgetConfig.segmentTypes.map((segmentType) =>
      <MenuItem
        data={{segmentType}}
        onClick={handleAddSegment}
        key={segmentType.name}>
        Add {segmentType.name}
      </MenuItem>
    );
  }

  return <div className="ImageSegmentationWidget Widget">
    <ContextMenuTrigger
      holdToDisplay={-1}
      id="context-menu-id">
      <div
        ref={imageRef}
        className="image-container"
        onContextMenu={handleClickedPoint}>
        <img src={images[activePageIndex]} onLoad={setCanvasSize} alt={""}/>
        <Stage width={canvasSize.width} height={canvasSize.height} className="canvas-container">
          <Layer>
            {segments[activePageIndex].map((segmentProps, idx) =>
              <Segment
                {...segmentProps}
                key={segmentProps.key} //eslint doesn't see key in spread line above :(
                imageRef={imageRef}
                mousePosition={mousePosition}
                onSegmentDone={handleSegmentDone}
                onSegmentSelection={setSelectedSegment}
                onDragEnd={handleDragEnd}
                idx={idx}
                canvasSize={canvasSize}
                isDisabled={
                  (isCreationMode && segments[activePageIndex].length - 1 !== idx)
                  ||
                  (isEditMode && !segmentProps.isEdit)
                }
                ref={
                  (selectedSegment.idx === idx && isEditMode && usedSegmentRef)
                  ||
                  (segments[activePageIndex].length - 1 === idx && isCreationMode && usedSegmentRef)
                  ||
                  (() => null) //fake ref so react won't cry
                }/>
            )}
          </Layer>
        </Stage>
      </div>
    </ContextMenuTrigger>
    <ContextMenu
      id="context-menu-id"
      onHide={() => setSelectedSegment({...selectedSegment, active: false})}>
      {contextMenuItems}
    </ContextMenu>
    <Pagination
      activePage={activePageIndex + 1}
      onPageChange={handlePagination}
      totalPages={images.length}/>
  </div>;
};

PolygonSegmentationWidget.propTypes = {
  ...Widget.propTypes
};

export default PolygonSegmentationWidget;
