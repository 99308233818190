import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Table} from "semantic-ui-react";
import moment from "moment";
import urls from "../../../config/frontend_urls";
import config from "../../../config/config";
import {IconChecked, IconUnchecked} from "../ComponentAdmin";
import _ from "lodash";
import {getLocale} from "../../../helpers/utils";


const QuestionAnswerTable = ({answers}) => {
  const locale = getLocale();
  moment.locale(locale);
  return <Table celled compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>User</Table.HeaderCell>
        <Table.HeaderCell>Is first</Table.HeaderCell>
        <Table.HeaderCell>Is answered</Table.HeaderCell>
        <Table.HeaderCell>Is active</Table.HeaderCell>
        <Table.HeaderCell>Is skipped</Table.HeaderCell>
        <Table.HeaderCell>Time taken</Table.HeaderCell>
        <Table.HeaderCell>Timestamp</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {_.map(answers, (answer) =>
        <Table.Row key={answer.id}>
          <Table.Cell collapsing>
            <Link to={urls.ADMIN_ANSWER_DETAIL.replace(":id", answer.id)}>
              {answer.id}
            </Link>
          </Table.Cell>
          <Table.Cell>{answer.user}</Table.Cell>
          <Table.Cell textAlign={"center"}>
            {answer.is_first ? <IconChecked/> : <IconUnchecked/>}
          </Table.Cell>
          <Table.Cell textAlign={"center"}>
            {answer.is_answered ? <IconChecked/> : <IconUnchecked/>}
          </Table.Cell>
          <Table.Cell textAlign={"center"}>
            {answer.is_active ? <IconChecked/> : <IconUnchecked/>}
          </Table.Cell>
          <Table.Cell textAlign={"center"}>
            {answer.is_skipped ? <IconChecked/> : <IconUnchecked/>}
          </Table.Cell>
          <Table.Cell>{answer.time_taken}</Table.Cell>
          <Table.Cell>{moment(answer.timestamp).format(config.DATETIME_FORMAT_LLT)}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>;
};
QuestionAnswerTable.propTypes = {
  answers: PropTypes.array
};

export default QuestionAnswerTable;
