import UserTaskModal from "../../UserTaskModal";
import React from "react";
import _ from "lodash";
import ajax from "../../../../helpers/ajax";
import config from "../../../../config/config";
import {toastr} from "react-redux-toastr";

export function renderModal(action=null) {
  return <UserTaskModal
    title="Resolve all reviews"
    onClose={() => this.setState({displayUnresolvedReviewsModal: false})}
    action={action}
    content={this.props.modalContent}
    checkedList={this.state.checkedList}
    isAllChecked={this.state.isAllChecked}
    unresolvedReviews={this.state.unresolvedReviews}
  />;
}

export function handleCheckboxClick(e, data) {
  if (data.checked) {
    this.setState((prevState) => ({
      checkedList: [...prevState.checkedList, data.id]
    }), () => {
      if (this.props.task[this.collectionName].length === this.state.checkedList.length) {
        this.setState({
          isAllChecked: true
        });
      }
    });
  } else {
    this.setState((prevState) => ({
      checkedList: _.filter(prevState.checkedList, (item) => item !== data.id),
      isAllChecked: false
    }));
  }
}

export function handleSelectAll(e, data) {
  const allUsers = [];
  if (data.checked) {
    _.map(this.props.task[this.collectionName], (element) => allUsers.push(element.username));
  }
  this.setState({
    checkedList: allUsers,
    isAllChecked: data.checked
  });
}

export function getUsers() {
  ajax.get(config.USER_DETAILS_USERS_UNPAGINATED)
    .then((response) =>
      this.setState({
        usernameOptions: _.map(response.data, (o, idx) => {
          return {
            text: o.username,
            value: o.username,
            key: idx
          };
        })
      })
    )
    .catch((errors) =>
      toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"))
    );
}

export function onTaskChange(changes) {
  const task = _.cloneDeep(this.props.task);

  for (const [path, val] of Object.entries(changes)) {
    if (path.split(".").pop() === "username") {
      const filter = _.filter(this.props.task[this.collectionName], {username: val});
      if (filter.length) {
        toastr.error("This username is already selected!");
        return;
      }
    }
    _.set(task, path, val);
  }

  this.props.updateTask(task);
}

export function getUnresolvedReviews() {
  let users = this.state.checkedList;
  if (users.length === 0) {
    toastr.error("Error!", "Select user or users");
    return;
  }
  const type = this.collectionName === "usertasks" ? "answers" : "evaluations";
  const params = {
    type,
    users_list: users
  };
  ajax.get([config.ADMIN_TASKS_DETAIL_RESOLVE, this.props.task.slug], {params})
    .then((response) => {
      if (response.data) {
        this.setState({
          unresolvedReviews: response.data,
          displayUnresolvedReviewsModal: true,
        });
      }
    })
    .catch(() => {
      toastr.error("Error!", "Select user or users");
    });
}

export function resolveReviews() {
  const type = this.collectionName === "usertasks" ? "answers" : "evaluations";
  const value = {
    users: _.map(this.state.unresolvedReviews, (usr) => usr.username),
    type
  };
  ajax.post(
    [config.ADMIN_TASKS_DETAIL_RESOLVE, this.props.task.slug], {data: value},
  ).then((response) => {
    if (response.status === 200) {
      toastr.success("Success", "Reviews resolved!");
      this.setState({
        displayUnresolvedReviewsModal: false,
        checkedList: [],
        isAllChecked: false
      });
    }
  }).catch((error) => {
    if (error.response) {
      toastr.error("Error", error);
    }
  });
}
