import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Popup,
  Segment,
  TextArea,
  Dimmer,
  Loader
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import "./Review.css";

export default class Review extends Component {
  static propTypes = {
    // text of review
    currentComment: PropTypes.string,
    // review
    review: PropTypes.shape({
      is_accepted: PropTypes.bool,
      is_discussion: PropTypes.bool,
      comments: PropTypes.array,
      accepted_at: PropTypes.string,
      accepted_by: PropTypes.shape({
        username: PropTypes.string
      }),
    }),
    // if user can start discussion
    canStartDiscussion: PropTypes.bool,
    // if user can resolve discussion
    canResolveDiscussion: PropTypes.bool,
    // user object
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      isModerator: PropTypes.bool.isRequired,
      is_staff: PropTypes.bool.isRequired
    }).isRequired,
    // function triggered on comment submit
    onCommentSubmit: PropTypes.func.isRequired,
    // function triggered when user decides to alter comment
    onEditCommentClick: PropTypes.func.isRequired,
    // function triggered on comment removal
    onCommentRemoval: PropTypes.func.isRequired,
    // function triggered on comment update
    onCommentUpdate: PropTypes.func.isRequired,
    // function triggered on discussion init
    onDiscussionInit: PropTypes.func.isRequired,
    // function triggered on review resolve
    onResolve: PropTypes.func.isRequired,
    // function triggered on comment and resolve
    onCommentAndResolve: PropTypes.func.isRequired,
    // function triggered on comment text change
    onCommentChange: PropTypes.func.isRequired,
    // variable if data is being loaded
    loading: PropTypes.bool.isRequired,
    // variable if existing comment is being edited
    editingComment: PropTypes.bool.isRequired,
    // function to set editingComment
    setEditingComment: PropTypes.func.isRequired,
    isTabNew: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showed: !!props.review,
      // 'true' if user is changing existing comment
    };
  }

  editComment = (comment) => {
    this.props.setEditingComment(true);
    this.props.onEditCommentClick(comment.id, comment.content);
  };

  abortEditing = () => {
    this.props.setEditingComment(false);
    this.props.onEditCommentClick(null, "");
  };

  renderPreviousComments = (review) => {
    if (!review) {
      return "";
    }

    const commentProps = {
      onEdit: this.editComment,
      onDelete: this.props.onCommentRemoval,
      user: this.props.user
    };

    const comments = [];
    _.forEach(review.comments, (c) => {
      const comment = Comment({...commentProps, comment: c});
      comments.push(comment);
    });

    return (
      <Segment.Group className="content">
        {comments}
      </Segment.Group>
    );
  };

  renderNewCommentSection = (review) => {
    if (review) {
      // Do not render section if moderator left just a comment.
      if (!review.is_discussion) {
        return "";
      } else {
        // Do not render section if user cannot unresolve discussion.
        if (review.is_accepted && !this.props.canResolveDiscussion) {
          return "";
        }
      }
    }
    const {isTabNew} = this.props;
    const commentButton =
      <Button
        onClick={this.props.onCommentSubmit}
        disabled={!this.props.currentComment}
      >
        {review ? "Reply" : "Comment"}
      </Button>;

    const startDiscussionButton =
      <Button
        primary
        onClick={this.props.onDiscussionInit}
        disabled={!this.props.currentComment}
      >
        Start discussion
      </Button>;

    const commentAndResolveButton = this.props.canResolveDiscussion ?
      <>
        <Button
          color="green"
          onClick={this.props.onCommentAndResolve}
          disabled={!this.props.currentComment}
        >
          Reply & Resolve
        </Button>
        <Button
          primary
          onClick={this.props.onResolve}
        >
          Resolve
        </Button>
      </>
      : "";

    const commentAndUnresolveButton = this.props.canResolveDiscussion ?
      <>
        <Button
          color="green"
          onClick={this.props.onCommentAndResolve}
          disabled={!this.props.currentComment}
        >
          Reply & Unresolve
        </Button>
        <Button
          onClick={this.props.onResolve}
        >
          Unresolve
        </Button>
      </>
      : "";

    const buttonsToDisplay =
      <Button.Group vertical compact className="submit-buttons">
        {!isTabNew && ((!review && this.props.canResolveDiscussion) || (review && !review.is_accepted)) ? commentButton : ""}
        {!review ? startDiscussionButton : ""}
        {review && !review.is_accepted && !isTabNew ? commentAndResolveButton : ""}
        {review && review.is_accepted && !isTabNew ? commentAndUnresolveButton : ""}
      </Button.Group>;

    return (
      <Segment className="new-comment">
        <Label attached="top">Message</Label>
        <TextArea
          value={this.props.currentComment}
          onChange={(event, data) => this.props.onCommentChange(data.value)}
        />
        {buttonsToDisplay}
      </Segment>
    );
  };

  renderEditCommentSection = () => {
    const saveButton =
      <Button
        color="green"
        onClick={this.props.onCommentUpdate}
      >
        Save
      </Button>;

    const cancelButton =
      <Button
        onClick={this.abortEditing}
      >
        Cancel
      </Button>;

    return (
      <Segment className="new-comment">
        <Label attached="top">Edit comment</Label>
        <TextArea
          value={this.props.currentComment}
          onChange={(event, data) => this.props.onCommentChange(data.value)}
        />
        <Button.Group vertical compact className="submit-buttons">
          {cancelButton}
          {saveButton}
        </Button.Group>
      </Segment>
    );
  };

  render() {
    const review = this.props.review;
    const resolved = review && review.is_accepted;
    let popupText = "Create new review";
    let type = "new";
    if (review) {
      type = review.is_discussion ? "discussion" : "commentary";
      popupText = resolved ?
        `Resolved at: ${moment(review.accepted_at).local()} (by ${review.accepted_by.username})`
        : "Not yet resolved.";
      popupText = review.is_discussion ? popupText : "Commentary";
    }

    const angleType = this.state.showed ? "angle up" : "angle down";
    const reviewStatus =
      <Popup
        content={popupText}
        trigger={
          <Label as="a" size="large">
            {resolved ?
              <Icon color="green" name="check square"/> : ""}
            Review
            <Label.Detail>{type}</Label.Detail>
            <Icon className="review-toggle-angle" name={angleType}/>
          </Label>
        }
      />;

    return (
      <div className="Review">
        <Accordion className="content">
          <Accordion.Title
            active={this.state.showed}
            onClick={() => this.setState({showed: !this.state.showed})}
          >
            {reviewStatus}
          </Accordion.Title>
          <Dimmer.Dimmable as={Accordion.Content} active={this.state.showed}>
            {this.renderPreviousComments(review)}
            {
              this.props.editingComment ?
                this.renderEditCommentSection()
                : this.renderNewCommentSection(review)
            }
            <Dimmer active={this.props.loading} inverted>
              <Loader size="medium">Loading…</Loader>
            </Dimmer>
          </Dimmer.Dimmable>
        </Accordion>
      </div>
    );
  }
}

const Comment = ({comment, onEdit, onDelete, user}) => {
  /* eslint-disable react/no-unescaped-entities */
  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br/>
      </React.Fragment>
    ));

  const postingTime = moment(comment.posting_time).local();
  const modificationTime = moment(comment.modification_time).local();
  const themeColor = comment.by_linguist ? "teal" : "orange";
  const hideUsername = !comment.by_linguist && !user.isModerator;
  const isAuthor = comment.author.username === user.username;

  return (
    <Segment key={"comment-" + comment.id} color={themeColor} textAlign="left">
      <Label
        color={themeColor}
        ribbon
        icon="user"
        content={hideUsername ? "Moderator" : comment.author.username}>
      </Label>
      <Popup
        content={`Last modified at: ${modificationTime.format()}`}
        trigger={
          <span className="comment-timestamp">{postingTime.fromNow()}</span>
        }/>
      <div className="comment-tools">
        {
          isAuthor || (!comment.by_linguist && user.isModerator) ?
            <Popup
              content={"Edit"}
              trigger={
                <Button icon onClick={() => onEdit(comment)}>
                  <Icon name={"edit"}/>
                </Button>
              }
            /> : null
        }
        {
          user.is_staff ?
            <Popup flowing hoverable
              on="click"
              trigger={<Button icon><Icon name={"close"}/></Button>}
            >
              <Grid centered divided columns={1}>
                <Grid.Column textAlign="center">
                  <Header as="h4">Confirmation</Header>
                  <p>Do you want to remove this message?</p>
                  <p>You won't be able to recover it.</p>
                  <Button color={"red"} className="ui icon button link" id="delete-button"
                    onClick={() => onDelete(comment.id)}>Yes</Button>
                  <Button color={"green"} className="ui icon button link">No</Button>
                </Grid.Column>
              </Grid>
            </Popup> : null
        }
      </div>
      <Divider/>
      {addLineBreaks(comment.content)}
    </Segment>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
    author: PropTypes.shape({
      username: PropTypes.string
    }),
    content: PropTypes.string,
    posting_time: PropTypes.string,
    modification_time: PropTypes.string,
    // if comment was authored by linguist
    by_linguist: PropTypes.bool
  }),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    isModerator: PropTypes.bool.isRequired,
    is_staff: PropTypes.bool.isRequired
  }).isRequired,
};
