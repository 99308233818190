import React from "react";
import {Icon} from "semantic-ui-react";

export const IconChecked = () => (
  <Icon name={"check circle outline"}/>
);

export const IconUnchecked = () => (
  <Icon name={"circle outline"}/>
);
