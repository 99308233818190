import ajax from "../helpers/ajax";
import {flattenObject} from "../helpers/utils";
import {errorsClear, errorsNew} from "./actions_errors";
import {messagesClear, messagesNew} from "./actions_messages";


export function postForm(target, data, callback) {
  return (dispatch) => {
    return ajax.post(target, {data: data})
      .catch((error) => {
        dispatch(messagesClear());
        const result = flattenObject(error.response.data, []);
        dispatch(errorsNew(result.join("\n")));
        throw error;
      })
      .then((result) => {
        dispatch(errorsClear());
        dispatch(messagesNew(result.data));
        callback && callback(result);
      });
  };
}

export function deleteForm(target, data, callback) {
  return (dispatch) => {
    return ajax.delete(target, data)
      .catch((error) => {
        dispatch(messagesClear());
        dispatch(errorsNew(error.response.data));
        throw error;
      })
      .then((result) => {
        dispatch(errorsClear());
        dispatch(messagesNew(result.data));
        callback && callback(result);
      });
  };
}

export function updateForm(target, data, callback) {
  return (dispatch) => {
    return ajax.patch(target, {data: data})
      .catch((error) => {
        dispatch(messagesClear());
        dispatch(errorsNew(error.response.data));
        throw error;
      })
      .then((result) => {
        dispatch(errorsClear());
        dispatch(messagesNew(result.data));
        callback && callback(result);
      });
  };
}
