/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import {Container} from "semantic-ui-react";
import Widget from "../Widget";

import "./RichTextEditorWidget.css";
import RichTextEditor from "../../RichTextEditor";

class RichTextEditorWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  constructor(props) {
    super(props);

    this.state = {
      editorState: ""
    };
  }

  static getDefaultConfig = () => {
    return {};
  };

  componentDidMount() {
    this.registerEmptyValid();
    this.update(this.props.value);
  }

  isEmpty = () => {
    const mockDiv = document.createElement("div");
    mockDiv.innerHTML = this.props.value;
    return mockDiv.innerText.trim().length === 0;
  };

  isValid = () => {
    return true;
  };

  render() {
    return (
      <Container className="RichTextEditorWidget">
        <RichTextEditor
          height={this.props.widgetConfig.autoHeight ? "auto" : undefined}
          disable={window.location.pathname.indexOf("widgets") !== -1}
          content={this.props.value || ""}
          onChange={(content) => this.update(content)}
        />
      </Container>
    );
  }
}

export default RichTextEditorWidget;
