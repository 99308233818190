import React, {useLayoutEffect, useState} from "react";
import {Button, Form, Header, Input, Popup} from "semantic-ui-react";
import _ from "lodash";
import PropTypes from "prop-types";


const LanguageSkill = ({lang, options, updateLanguage}) => {
  const [popupContent, setPopupContent] = useState(null);
  useLayoutEffect(() => {
    const skills = _.map(options, (skill) =>
      <Form.Button compact fluid
        size={"mini"}
        key={skill.value}
        className={"skill-button"}
        content={skill.text}
        onClick={() => {
          updateLanguage(lang.name, "skill", skill.value);
          setSelectedSkill(skill.text);
        }}
      />);

    setPopupContent(<Form>
      <label>Select skill</label>
      {skills}
    </Form>);
  }, [lang, options, updateLanguage]);

  const [hidden, setHidden] = useState(false);

  const [selectedSkill, setSelectedSkill] = useState(
    _.get(
      _.find(options, ["value", lang.skill]), "text"));

  const onDragStart = (e) => {
    e.dataTransfer.setData("id", lang.name);
    e.dataTransfer.setData("type", "LanguageSkill");
  };

  return (
    <li
      draggable
      key={lang.name} data-id={lang.name}
      onDragStart={onDragStart}
      onDrag={() => setHidden(true)}
      onDragEnd={() => setHidden(false)}
      hidden={hidden}
    >
      <Header size="small">{lang.name}</Header>
      <Form>
        <Form.Group inline>
          <Form.Field width={8}>
            <label>
              Skill
            </label>
            <Popup
              className={"skill-popup"}
              content={popupContent}
              trigger={
                <Button compact size={"mini"} content={selectedSkill} />
              }
              on={"click"}
            />
          </Form.Field>
          <Form.Field width={4} size="big">
            <label>Native</label>
            <Input
              name={"native"}
              type={"checkbox"} placeholder="Full Name" checked={lang.native}
              className="checkboxx"
              onChange={() => updateLanguage(lang.name, "native", !lang.native)}/>
          </Form.Field>
        </Form.Group>
      </Form>
    </li>
  );
};

LanguageSkill.propTypes = {
  // language
  lang: PropTypes.object.isRequired,
  // options for language proficiency
  options: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  // function for update language
  updateLanguage: PropTypes.func.isRequired
};

export default LanguageSkill;
