import React from "react";
import {Button, Form, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./AnnotationContainer.css";
import ContainerWidget from "../ContainerWidget/ContainerWidget";


class AnnotationContainer extends React.Component {

  static propTypes = {
    annotation: PropTypes.shape({
      id: PropTypes.string.isRequired,
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
      note: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
    }).isRequired,
    closable: PropTypes.bool,
    onAnnotationChange: PropTypes.func.isRequired,
    removeAnnotation: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
    noteChoices: PropTypes.object,
    recordingLength: PropTypes.number.isRequired,
    setNote: PropTypes.func.isRequired,
    disableNote: PropTypes.bool
  };

  setVals = () => {
    if (this.props.annotation && this.props.annotation.note) {
      if (typeof this.props.annotation.note === "string") {
        return Object.entries(this.props.annotation.note);
      } else {
        return this.props.annotation.note;
      }
    } else {
      return {};
    }
  }

  renderNote = () => {
    return (
      this.props.noteChoices ?
        <ContainerWidget
          id={"note"}
          widgetVals={this.setVals()}
          update={
            (widgetVal, componentId) => this.props.setNote(widgetVal, componentId)
          }
          smallWidth={true}
          toRender={this.props.noteChoices}
        /> :
        <Form.Input
          id={"note"}
          label={"Note"}
          placeholder={"Note"}
          value={this.props.annotation.note || ""}
          onChange={(e, data) => {
            this.props.setNote(data.value);
          }}
        />
    );
  };
  render() {
    const note = !this.props.disableNote ? this.renderNote() : null;
    return (
      <div className="annotation-container">
        <Form>
          <Form.Group inline>
            <Form.Input
              id="start"
              label="Start"
              type="number"
              placeholder="0"
              min={0}
              max={this.props.annotation.end}
              step={0.001}
              disabled={this.props.disableNote}
              value={this.props.annotation.start}
              onChange={(e, data) => this.props.onAnnotationChange(
                {start: parseFloat(data.value), end: null, note: null})
              }
            />
            <Form.Input
              id="end"
              label="End"
              type="number"
              placeholder="0"
              min={this.props.annotation.start}
              max={this.props.recordingLength}
              step={0.001}
              value={this.props.annotation.end}
              disabled={this.props.disableNote}
              onChange={(e, data) => this.props.onAnnotationChange(
                {start: null, end: parseFloat(data.value), note: null})
              }
            />
            <Button
              id="remove"
              icon
              color="red"
              onClick={() => this.props.removeAnnotation()}
            >
              <Icon name="trash alternate outline"/>
            </Button>
            {this.props.closable ?
              <Button
                id="close"
                icon
                onClick={() => this.props.hideMenu()}
              >
                <Icon name="close"/>
              </Button> : ""}
          </Form.Group>
          {note}
        </Form>
      </div>
    );
  }
}

export default AnnotationContainer;
