import ImageSegmentationWidget from "./ImageSegmentationWidget/ImageSegmentationWidget";
import SpreadsheetWidget from "./SpreadsheetWidget/SpreadsheetWidget";
import RadioButtonWidget from "./RadioButtonWidget/RadioButtonWidget";
import RichTextEditorWidget from "./RichTextEditorWidget/RichTextEditorWidget";
import RichTextEditorReadOnlyWidget from "./RichTextEditorReadOnlyWidget/RichTextEditorReadOnlyWidget";
import TextareaWidget from "./TextareaWidget/TextareaWidget";
import PipedTextAreaWidget from "./PipedTextArea/PipedTextAreaWidget";
import GooglePlayWidget from "./GooglePlayWidget/GooglePlayWidget";
import SelectWidget from "./SelectWidget/SelectWidget";
import SubmitButtonWidget from "./SubmitButtonWidget/SubmitButtonWidget";
import SIOSTRAWidget from "./SIOSTRAWidget/SIOSTRAWidget";
import OrderListWidget from "./OrderListWidget/OrderListWidget";
import FileUploadWidget from "./FileUploadWidget/FileUploadWidget";
import MediaPlayerWidget from "./MediaPlayerWidget/MediaPlayerWidget";
import MediaWaveformWidget from "./MediaWaveformWidget/MediaWaveformWidget";
import AudioAnswerWidget from "./AudioAnswerWidget/AudioAnswerWidget";

const widgetsMapping = {
  "SpreadsheetWidget": SpreadsheetWidget,
  "RadioButtonWidget": RadioButtonWidget,
  "RichTextWidget": RichTextEditorWidget,
  "RichTextReadOnlyWidget": RichTextEditorReadOnlyWidget,
  "TextareaWidget": TextareaWidget,
  "PipedTextAreaWidget": PipedTextAreaWidget,
  "GooglePlayWidget": GooglePlayWidget,
  "SelectWidget": SelectWidget,
  "SubmitWidget": SubmitButtonWidget,
  "SIOSTRAWidget": SIOSTRAWidget,
  "OrderListWidget": OrderListWidget,
  "FileUploadWidget": FileUploadWidget,
  "MediaPlayerWidget": MediaPlayerWidget,
  "AudioWidget": MediaWaveformWidget,
  "MediaWaveformWidget": MediaWaveformWidget,
  "ImageSegmentationWidget": ImageSegmentationWidget,
  "AudioAnswerWidget": AudioAnswerWidget
};

export default widgetsMapping;
