/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {Button, Grid, Header, Icon, Popup, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import Registrator from "./Registrator";
import Review from "./Review";
import {ContainerWidget} from "../../components/Widgets";
import {toastr} from "react-redux-toastr";
import ajax from "../../helpers/ajax";
import config from "../../config/config";

import "./QuestionsSet.css";


class QuestionContainer extends Registrator {
  static propTypes = {
    ...Registrator.propTypes,
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      is_staff: PropTypes.bool.isRequired
    }),
    onCommentSubmit: PropTypes.func,
    // function triggered on comment removal
    onCommentRemoval: PropTypes.func,
    // function triggered on comment update
    onCommentUpdate: PropTypes.func,
    // function triggered on review accept
    onReviewAccept: PropTypes.func,
    username: PropTypes.string,
    isModerator: PropTypes.bool.isRequired,
    // if not only moderator can start discussion
    canLinguistStartDiscussion: PropTypes.bool,
    // if submit is processed
    processingSubmit: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      questionId: -1,
      copyPopupContent: "Right click to copy.",
      copyPopupOpen: false,
      reviewLoading: false,
      editingComment: false,
    };

    // objects mapping componentId to the isEmpty and isValid functions from Widgets
    this.validRegister = {};
    this.emptyRegister = {};
    this.prepareForSubmitRegister = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // get widgetVals from question and active answer
    let widgetVals = this.props.question.input;
    const activeAnswer = 0;
    if (this.props.question.answers.length) {
      widgetVals = this.mergeQuestionWithAnswer(this.props.question, activeAnswer);
    }

    this.widgetVals = _.cloneDeep(widgetVals);
    this.setState({
      questionId: this.props.question.id,
      widgetVals,
      activeAnswer,
      currentComment: ""
    });
  }

  onActiveAnswerChange = (idx) => {
    // get widgetVals from question and active answer
    let widgetVals = this.props.question.input;
    if (this.props.question.answers) {
      widgetVals = this.mergeQuestionWithAnswer(this.props.question, idx);
    }
    this.widgetVals = _.cloneDeep(widgetVals);
    this.setState({widgetVals, activeAnswer: idx});
  };

  onFixAnswerChange = (question_id) => {
    this.props.onFixAnswer(question_id);
  };

  onReviewTextChange = (reviewText) => {
    this.setState({currentComment: reviewText});
  };

  onEditCommentClick = (commentId, reviewText) => {
    this.setState({currentComment: reviewText, modifiedCommentId: commentId});
  };

  splitQuestionFromAnswer = () => {
    const result = _.cloneDeep(this.state.widgetVals);
    _.each(result, (value, key) => {
      if (key in this.props.question.input) {
        if (typeof value === "object" && !Array.isArray(value)) {
          _.each(value, (v, k) => {
            if (_.isEqual(v, this.props.question.input[key][k])) {
              delete result[key][k];
            }
          });
        }
      }
    });
    return result;
  };

  async onSubmit(isCorrection) {

    const promises = [];
    _.each(this.prepareForSubmitRegister, (fun) => promises.push(fun())); //call prepare for submit on every registered widget.

    await Promise.all(promises)
      .then(() => {
        const widgetVals = this.splitQuestionFromAnswer();

        const activeAnswer = this.props.question.answers[this.state.activeAnswer];

        const answerId = isCorrection && activeAnswer?.id; //id correct answer without creating new one

        const cb = () => { //callback to change active answer when staff submits answer in done tab
          if (!isCorrection && this.props.user.username !== activeAnswer.user) {
            this.onActiveAnswerChange(this.state.activeAnswer +1);
          }
        };
        this.props.onSubmit(this.props.idx, widgetVals, answerId, cb);
      });
  }

  onSkip = () => {
    this.props.onSkip(this.props.idx, null);
  };

  onUpdate = (widgetVal, componentId) => {
    this.widgetVals[componentId] = _.cloneDeep(widgetVal);
    this.setState({widgetVals: this.widgetVals});
  };

  copyToClipboard = (e, data, id=false) => {
    e.preventDefault();
    const text = id ? "ID" : "User";
    navigator.clipboard.writeText(data);
    this.setState({copyPopupContent: `${text} copied to clipboard!`});
  };

  handleClose = (id=false) => {
    const text = id ? "ID" : "user";
    this.setState({copyPopupContent: `Right click to copy ${text}.`});
  };

  handleCommentSubmitSuccess = () => {
    this.setState({currentComment: "", reviewLoading: false});
  };

  onCommentSubmit = (answerIdx, comment, discussion = false, commentId = null, successCallback = () => null) => {
    const questions = this.props.question;

    const data = discussion ? {
      content: comment,
      discussion: true
    } : {content: comment};

    if (commentId) {
      data.commentId = commentId;
    }
    let request;
    if (!this.props.isTabNew) {
      const answerId = this.props.question.answers[answerIdx].id;
      request = ajax.post([config.TASK_SAVE_REVIEW, answerId], {data});
    } else {
      data.new_answer = true;
      data.question_id = questions.id;
      request = ajax.post(config.TASK_QUESTION_SAVE_REVIEW, {data});
    }

    request
      .then((response) => {
        toastr.success(`${discussion ? "Discussion started!" : "Message submitted!"}`);

        if (!this.props.isTabNew) {
          const questions = this.props.question;
          questions.answers[answerIdx].review = response.data;
        } else {
          const questions = this.props.question;
          const answersContent = {
            review: response.data,
          };
          questions.answers = [];
          questions.answers.push(answersContent);
          toastr.info("Please, remember to submit your answer!");
        }
        this.setState({questions});
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  onCommentRemoval = (commentId, answerIdx, successCallback = () => null) => {
    ajax.delete([config.TASK_DELETE_COMMENT, commentId])
      .then((response) => {
        const questions = this.props.question;
        questions.answers[answerIdx].review = response.data;
        this.setState({questions});
        toastr.success("Message deleted!");
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  onCommentUpdate = (commentId, comment, answerIdx, successCallback = () => null) => {
    const data = {content: comment};
    ajax.post([config.TASK_UPDATE_COMMENT, commentId], {data})
      .then((response) => {
        const question = this.props.question;
        question.answers[answerIdx].review = response.data;
        this.setState({question});
        toastr.success("Message updated!");
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  render() {
    // answer === -1 -> we are waiting for new answer from parent
    if (this.state.activeAnswer === -1) {
      const idx = _.findIndex(this.props.question.answers, {user: this.props.user.username});
      if (idx !== -1) {
        this.setState({activeAnswer: idx});
      }

      return null;
    }

    // we are waiting for new question
    if (this.props.question.id !== this.state.questionId) {
      return null;
    }

    const config = this.props.config;
    const question = this.props.question;
    const user = this.props.user;

    const activeAnswerIdx = this.state.activeAnswer;

    let answers = "";
    let review = "";
    let activeAnswerLastModification = "";
    let hasSubmit = false;

    let answer = null;
    let isAnswerAuthor = null;
    if (question.answers.length > 0) {
      answer = question.answers[activeAnswerIdx];
      let timestamp;
      if (answer.fix_timestamp) {
        timestamp = moment(answer.fix_timestamp).format("YYYY-MM-DD HH:mm:ss");
        activeAnswerLastModification = `Last modified on ${timestamp} by ${answer.fix_author || "Moderator"}`;
      } else if (answer.timestamp) {
        timestamp = moment(answer.timestamp).format("YYYY-MM-DD HH:mm:ss");
        activeAnswerLastModification = `Last modified on ${timestamp}`;
      }
      isAnswerAuthor = answer.user === user.username;
    }
    // done, new tab
    if (this.props.reviewable) {
      const {isTabNew} = this.props;
      const canStartDiscussion = this.props.isModerator || (this.props.canLinguistStartDiscussion && isAnswerAuthor)
        || (isTabNew && this.props.canLinguistStartDiscussion);
      const canSeeReview = canStartDiscussion || (answer?.review && (isAnswerAuthor || user.is_company_coordinator));
      review = this.props.reviewable && canSeeReview ?
        <Review
          canStartDiscussion={canStartDiscussion}
          canResolveDiscussion={!isTabNew ? (this.props.isModerator || user.is_superuser) : false}
          user={{...user, isModerator: this.props.isModerator}}
          review={!isTabNew ? answer.review : question.answers[activeAnswerIdx]?.review}
          currentComment={this.state.currentComment}
          onResolve={() => this.props.onReviewAccept(
            this.props.idx,
            activeAnswerIdx,
            null,
            this.handleCommentSubmitSuccess
          )}
          onCommentAndResolve={
            () => {
              this.setState({reviewLoading: true});
              this.props.onReviewAccept(
                this.props.idx,
                activeAnswerIdx,
                this.state.currentComment,
                this.handleCommentSubmitSuccess
              );
            }
          }
          onCommentSubmit={() => {
            this.setState({reviewLoading: true});
            this.onCommentSubmit(
              activeAnswerIdx,
              this.state.currentComment,
              false,
              null,
              this.handleCommentSubmitSuccess
            );
          }}
          onEditCommentClick={this.onEditCommentClick}
          onCommentRemoval={(commentId) => {
            this.setState({reviewLoading: true});
            this.onCommentRemoval(
              commentId,
              activeAnswerIdx,
              () => this.setState({reviewLoading: false})
            );
          }}
          onCommentUpdate={() => {
            this.onCommentUpdate(
              this.state.modifiedCommentId,
              this.state.currentComment,
              activeAnswerIdx,
              () => {
                this.setState({
                  reviewLoading: false,
                  currentComment: "",
                  modifiedCommentId: null,
                  editingComment: false
                });
              });
          }}
          onDiscussionInit={() => this.onCommentSubmit(
            activeAnswerIdx,
            this.state.currentComment, true, null,
            this.handleCommentSubmitSuccess)
          }
          onCommentChange={this.onReviewTextChange}
          loading={this.state.reviewLoading}
          editingComment={this.state.editingComment}
          setEditingComment={(editingComment) => this.setState({editingComment})}
          key="review"
        /> : "";
    }

    answers = _.map(question.answers, (answer, answerIdx) => {
      const color = activeAnswerIdx === answerIdx ? "green" : "vk";
      return (
        <Popup
          hoverable
          id="username"
          key={"popup-" + answerIdx}
          content={<>
            {this.state.copyPopupContent}
            {this.props.user.has_user_data_access &&
              <p className="copyHashCode" onClick={(e) => this.copyToClipboard(e, answer.username_hash)}>
                Or click here to copy user hash <Icon name="slack hash"/>
              </p>
            }
          </>}
          onClose={this.handleClose}
          trigger={
            <Button
              key={question.id.toString() + answerIdx.toString()}
              size="mini"
              color={color}
              onClick={() => this.onActiveAnswerChange(answerIdx)}
              onContextMenu={(e) => this.copyToClipboard(e, answer.user)}>
              {answer.user}
            </Button>
          }
        />
      );
    });

    const lastModifiedLabel = activeAnswerLastModification ?
      <Grid.Column floated="left" className="ui label active-answer-timestamp">
        {activeAnswerLastModification}
      </Grid.Column> : <div></div>;

    let customClass = question.isAnswered ? "question answered" : "question";
    if (!_.isEmpty(_.get(this.props, "config.structure"))) {
      hasSubmit = _.last(
        _.get(this.props, "config.structure"), {componentType: "empty"}
      ).componentType === "SubmitWidget";
      if (!hasSubmit) {
        customClass += " non-submit";
      }
    }

    const questionId = <Popup
      hoverable
      key={"popup-" + question.id}
      content={<>
        {this.state.copyPopupContent}
        <p
          className="copyHashCode"
          onClick={(e) => this.copyToClipboard(e, question.id, true)}>
            Or click here to copy question id <Icon name="slack hash"/>
        </p>
      </>}
      onClose={() => this.handleClose(true)}
      trigger={<span>Question id: {question.id}</span>}/>;

    return (
      <div className="Question" key={"question" + question.id.toString()}>
        <Grid className="header" stackable columns={2}>
          {lastModifiedLabel}
          <Grid.Column className="ui label question-id">
            {questionId}
          </Grid.Column>
          <Grid.Column width={16} className="answers">{!this.props.isTabNew ? answers : null}</Grid.Column>
        </Grid>
        <div className="wrapper">
          <div className={customClass}>
            <ContainerWidget
              key={"answer" + this.state.activeAnswer}
              widgetVals={this.state.widgetVals}
              update={hasSubmit ? (widgetVal, componentId) => this.onUpdate(widgetVal, componentId) : () => null}
              toRender={config}
              getWidgetConfig={() => config}
              componentId={"rootWidget"}
              onSubmit={this.onSubmit}
              onSkip={this.props.onSkip ? this.onSkip : null}
              questionId={question.id}
              answerAuthor={_.get(question, `answers[${activeAnswerIdx}].user`)}
              registerValid={this.registerValid}
              registerEmpty={this.registerEmpty}
              registerPrepareForSubmit={this.registerPrepareForSubmit}
              isValid={this.isValid}
              isEmpty={this.isEmpty}
              isModerator={this.props.isModerator}
              prepareForSubmit={this.prepareForSubmit}
              processingSubmit={this.props.processingSubmit}
              isTabDone={this.props.isTabDone}
            />
            {review}
          </div>
          {
            question.isAnswered &&
            <div>
              <Segment attached="top" compact style={{border: "0"}}>
                <Button
                  key={question.id.toString() + "." + question.answer_id.toString()}
                  size="mini"
                  color="vk"
                  onClick={() => this.onFixAnswerChange(question.id)}
                >
                  Fix answer
                </Button>
              </Segment>
              <div className="overlay">
                <Header
                  as="h2"
                  textAlign="center"
                  color="red">
                  Answered
                </Header>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth
});

export default connect(mapStateToProps)(QuestionContainer);
