import _ from "lodash";
import {useEffect} from "react";

const useUpdateWidgetValue = (segments, canUpdate, componentId, update, value) => {
  useEffect(() => {
    if (!canUpdate || !value) {
      return;
    }
    const widgetVal = _.cloneDeep(value);
    widgetVal.segmentsOnPages = segments.map(
      (img) => img.map(({key, ...data}) => data) // eslint-disable-line no-unused-vars
    );
    if (!_.isEqual(widgetVal, value)) {
      update(widgetVal, componentId);
    }
  }, [segments, canUpdate, componentId, update, value]);
};

export default useUpdateWidgetValue;
