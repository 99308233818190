import _ from "lodash";
import moment from "moment";

import {ERRORS_CLEAR, ERRORS_NEW} from "../actions/types/types_errors";
import config from "../config/config";
import {LANGUAGE_SKILLS, LANGUAGES} from "./languages";

export var color_table = [
  "#cc0237",
  "#0096ed",
  "#b5ff7c",
  "#fff830",
];

export var archive_switch = {
  "icon": ["archive", "lightning"],
  "text": ["Change to archive", "Change to current"],
  "title": ["", " [Archived]"],
};

export function getTimeChartData(datasets, x_label, y_label) {
  var _datasets = [];
  for (let i = 0; i < datasets.length; i++) {
    const dataset = {
      label: datasets[i].label,
      backgroundColor: datasets[i].color,
      borderColor: datasets[i].color,
      data: datasets[i].data,
      fill: false,
      pointRadius: 0,
    };

    _datasets.push(dataset);
  }

  const options = {
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ": " + moment(tooltipItem.yLabel).format("lll");
        }
      }
    },
    hover: {
      mode: "nearest",
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: x_label
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: y_label
        },
        ticks: {
          callback: function (v) {
            return moment(v).format("lll");
          },
        }
      }]
    }
  };
  return [_datasets, options];
}

export function getDurationChartData(datasets, x_label, y_label) {
  // set datasets
  var _datasets = [];
  for (var i = 0; i < datasets.length; i++) {
    const dataset = {
      label: datasets[i].label,
      backgroundColor: datasets[i].color,
      borderColor: datasets[i].color,
      data: datasets[i].data,
      fill: false,
      pointRadius: 0,
    };

    _datasets.push(dataset);
  }

  var options = {
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ": " + formatSeconds(tooltipItem.yLabel);
        }
      }
    },
    hover: {
      mode: "nearest",
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: x_label,
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: y_label,
        },
        ticks: {
          callback: function (v) {
            return formatSeconds(v);
          },
        }
      }]
    }
  };
  return [_datasets, options];
}

export function getCountChartData(datasets, x_label, y_label) {
  var _datasets = [];
  for (var i = 0; i < datasets.length; i++) {
    const dataset = {
      label: datasets[i].label,
      backgroundColor: datasets[i].color,
      borderColor: datasets[i].color,
      data: datasets[i].data,
      fill: false,
      pointRadius: 0,
    };

    _datasets.push(dataset);
  }

  var options = {
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
        }
      }
    },
    hover: {
      mode: "nearest",
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: x_label,
        },
        ticks: {
          callback: function (v) {
            return moment(v).format("MMM Do YY");
          },
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: y_label,
        },
        ticks: {
          callback: function (v) {
            return v;
          },
        }
      }]
    }
  };
  return [_datasets, options];
}

export function formatSeconds(s) {
  const h = Math.floor(s / 3600); //Get whole hours
  s -= h * 3600;
  const m = Math.floor(s / 60); //Get remaining minutes
  s -= m * 60;
  if (h > 0) {
    s = (Math.round(s * 100) / 100).toFixed(0);
    return h + ":" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s); //zero padding on minutes and seconds
  } else if (m > 0) {
    s = (Math.round(s * 100) / 100).toFixed(0);
    return "0:" + (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s);
  } else {
    s = (Math.round(s * 100) / 100).toFixed(1);
    return (s + " s");
  }
}

export function getClass(elements) {
  return elements.join(" ").trim();
}

export function redirectToExternal(target, pk) {
  /*
   Redirect to old system, until we finish new components.
   Convert raw string .../datahivebuzz/task/(pk)/change/ into /datahivebuzz/task/981/change/
   Only one (pk) allowed.
   */
  if (_.isUndefined(pk)) {
    console.error(`PK is undefined for ${target}`); /* eslint-disable-line no-console */
    return;
  }
  const parts = target.split(/\(|\)/),
    newlocation = String.raw({raw: [parts[0], parts[2]]}, pk);
  window.location = newlocation;
  // return <Redirect to={{pathname: '/'}}/>
}

export function getExternalUrl(target, pk) {
  /*
   Redirect to old system, until we finish new components.
   Convert raw string .../datahivebuzz/task/(pk)/change/ into /datahivebuzz/task/981/change/
   Only one (pk) allowed.
   */
  if (_.isUndefined(pk)) {
    console.error(`PK is undefined for ${target}`); /* eslint-disable-line no-console */
    return;
  }
  const parts = target.split(/\(|\)/),
    newlocation = String.raw({raw: [parts[0], parts[2]]}, pk);
  return newlocation;
}

export function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function getPossibleDropdownValues(valuePath, textPath, source, allow_empty = false, sort = true) {
  /*
   Returns a list of objects suitable for use by the semantic ui dropdown, from a given source. Can be used to generate values
   from nested fields
   */
  const values = {};
  if (allow_empty) {
    values[null] = {
      text: "-----",
      value: null,
      key: null
    };
  }
  /* eslint-disable-next-line no-unused-vars */
  _.each(source, (e, idx) => {
    const value = _.get(e, valuePath);
    const text = _.get(e, textPath) ? _.get(e, textPath) : "None"; //if the value is nullable, component has to display something
    values[value] = {
      text: text,
      value: value,
      key: value
    }; //use dict keys to get unique values
  });
  if (sort) {
    return _.values(values).sort(function (a, b) {
      return ("" + a.text).localeCompare(b.text);
    });
  } else {
    return _.values(values);
  }
}
export function getPossibleDropdownValues2(source, allow_empty = false) {
  /*
   Returns a list of objects suitable for use by the semantic ui dropdown, from a given source. Can be used to generate values
   from array of generic values (e.g.string).
   */
  const values = {};
  if (allow_empty) {
    values[null] = {
      text: "-----",
      value: null,
      key: null
    };
  }

  _.each(source, (e) => {
    values[e] = {
      text: e,
      value: e,
      key: e
    }; //use dict keys to get unique values
  });
  return _.values(values);
}
/**
 * Function return filtered list of elements.
 * @param listRaw Array with all elements.
 * @param filters Active filters dictionary
 * @param filtersStructure Dictionary with mapping between listRaw fields and filters.
 * @returns {Array} Filtered list of elements.
 */
export function getFilteredList(listRaw, filters, filtersStructure) {
  if (!listRaw) {
    return [];
  }
  const result = _.filter(listRaw, (obj) => {
    let flag = true;

    _.forEach(filters, (fvalue, fidx) => {
      if (!(fidx in filtersStructure)) {
        return true;
      }

      const value = _.get(obj, filtersStructure[fidx], undefined);
      const isArray = _.isArray(fvalue);
      if ((isArray && fvalue.length && !fvalue.includes(value)) || (!isArray && fvalue !== value)) {
        flag = false;
      }
    });

    return flag;
  });

  return result;
}

/**
 * Function return list of elements sorted by filters.order_by.
 * @param listRaw List of elements to sort
 * @param filters Filters dict
 * @returns {Array}
 */
export function getSortedList(listRaw, filters, filtersStructure) {
  let orderBy = filters.order_by;
  let reverse = false;
  if (!orderBy) {
    return listRaw;
  }
  if (orderBy[0] === "-") {
    orderBy = orderBy.slice(1);
    reverse = true;
  }
  let sorted = _.sortBy(listRaw, ((element) => {
    return _.get(element, filtersStructure[orderBy]);
  }));
  if (reverse) {
    sorted = _.reverse(sorted);
  }
  return sorted;
}

//make these work
export function defaultMapDispatchToProps(dispatch) {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
}

export function defaultmapStateToProps(state) {
  return {
    auth: state.auth
  };
}

/**
 * Function return widgetVals without one nested level 'value'.
 * @param widgetVals
 */
export function removeValueLevel(widgetVals) {
  const result = {};
  _.forEach(widgetVals, (widgetVal, widgetId) => {
    result[widgetId] = widgetVal.value;
  });
  return result;
}

/**
 * Function return widgetVals with added one nested level 'value'.
 * @param widgetVals
 */
export function addValueLevel(widgetVals) {
  const result = {};
  _.forEach(widgetVals, (widgetVal, widgetId) => {
    result[widgetId] = {value: widgetVal};
  });
  return result;
}


/**
 * Function create options for semantic dropdown.
 * @param values
 * @returns {Array}
 */
export function createDropdownOptions(values, empty = true) {
  const emptyOption = {
    key: "null",
    value: null,
    text: "---"
  };
  const options = empty ?
    [emptyOption] : [];
  /* eslint-disable-next-line no-unused-vars */
  _.forEach(values, (value, idx) => {
    if (!!value) {
      options.push({
        key: value,
        value: value,
        text: value
      });
    } else if (value === "") {
      options.push(emptyOption);
    }
  });

  return options;
}

export function sleep(ms) {
  /* Helpful for tests and others ...
  * async componentWillMount() {
  *   ...
  *   await sleep(1000);
  * }
  * */
  return new Promise((resolve) => setTimeout(resolve, ms));
}


export function getImagePath(name) {
  /* On development version there isn't real prefix for images */
  const prefix = window.VERSION !== undefined ? config.HOME_PAGE : "/";
  return prefix + name;
}

export function getLocale() {
  return window.navigator.userLanguage || window.navigator.language;
}

export function getDateFormat() {
  const locale = getLocale();
  return locale === "pl-PL" ? "DD.MM.YYYY" : "L";
}

export function getFormatted(datetime, format) {
  return moment(datetime).isValid() ? moment(datetime).locale(getLocale()).format(format) : "---";
}

export function getFormattedDate(date) {
  return getFormatted(date, "L");
}

export function getFormattedDateTime(datetime) {
  return getFormatted(datetime, "L LT");
}

export function formatErrorMessage(message) {
  // message is an object -> return pairs key: value
  if (typeof message === "object" && message !== null) {
    return _.reduce(message, (result, value, key) => {
      return result += `${key}: ${value}\n`;
    }, "");
  }
  // message is a string
  return message;
}

export function flattenObject(obj, arr) {
  if (!_.isObject(obj)) {
    arr.push(obj);
    return arr;
  } else if (_.isArray(obj)) {
    _.forEach(obj, (o) => flattenObject(o, arr));
  } else {
    _.forEach(Object.keys(obj), (k) => flattenObject(obj[k], arr));
  }
  return arr;
}

export function copyToClipboard(data) {
  const textField = document.createElement("textarea");
  textField.innerText = data;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}

export function convertDateToISO8601(data) {
  const timezone = moment.tz.guess();
  const date = moment.tz(data, timezone).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  return date;
}

export function convertDateToLocalDatetime(data) {
  let deadline = moment.utc(data).toDate();
  deadline = moment(deadline).format(config.FORMAT_Ymd_Hm);
  return deadline;
}

export function formatPhoneNumber(phone) {
  phone = phone.split(/\s/).join("");
  if (phone.length < 6) {
    return `${phone}`;
  }
  if (phone.length <= 9) {
    return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6)}`;
  }
  if (phone.length <= 11) {
    return `${phone.slice(0, 2)} ${phone.slice(2, 5)} ${phone.slice(5, 8)} ${phone.slice(8)}`;
  }
  if (phone.length <= 13 && phone[0] === "+") {
    return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 9)} ${phone.slice(9)}`;
  }
  return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 10)} ${phone.slice(10)}`;
}

export function convertDate(data) {
  const start = data["start_date"];
  const end = data["end_date"];

  if (start && end) {
    const timezone = moment.tz.guess();
    const start_1 = moment.tz(start + " 00:00", timezone).utc().format("YYYY-MM-DD HH:mm:ss");
    const end_1 = moment.tz(end + " 24:00", timezone).utc().format("YYYY-MM-DD HH:mm:ss");

    data["start_date"] = start_1;
    data["end_date"] = end_1;
  }
}

export function formatDate(data) {
  const start = data["start_date"];
  const end = data["end_date"];

  if (start || end) {
    const start_1 = start ? moment.utc(moment(start + " 00:00")).format() : "";
    const end_1 = end ? moment.utc(moment(end + " 23:59:59")).format() : "";

    data["start_date"] = start ? start_1 : "";
    data["end_date"] = end ? end_1 : "";

    delete data.date_range;
  }
}

export function renderLanguage(data) {
  const languages = [];
  _.map(data.languages, (language) => {
    if (LANGUAGES[language.name]) {
      languages.push(LANGUAGES[language.name].toUpperCase() + " - " + LANGUAGE_SKILLS[language.skill]);
    }
  });
  return languages.join(", ");
}

export function zeroWidthTrim(data) {
/**
 * fn remove zero-width spaces in string
 * Regex for zero-width space Unicode characters.
 * U+200B zero-width space.
 * U+200C zero-width non-joiner.
 * U+200D zero-width joiner.
 * U+200E left-to-right mark.
 * U+200F right-to-left mark.
 * U+FEFF zero-width non-breaking space.
 */
  // eslint-disable-next-line
  return data.replace(/[\u200B\u200D\u200C\u200E\u200F\uFEFF]/g, '')
}
