import _ from "lodash";

import React, { Component } from "react";
import { Table, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import Pagination from "../components/Pagination";
import SortableTable from "./simple/SortableTable/SortableTable";
import LoaderSpinner from "../components/LoaderSpinner";
import "./ContainerList.css";

class ContainerList extends Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    button: PropTypes.element,
    renderRow: PropTypes.func.isRequired,
    filters: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired,
    pageChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  render() {
    const rows = _.map(this.props.items, (item) => {
      return this.props.renderRow(item);
    });
    return (
      <div
        className={
          this.props.className
            ? `${this.props.className} ContainerList`
            : "ContainerList"
        }
      >
        <Segment.Group className="items-list">
          <Segment clearing className="datahive-segment">
            {this.props.button}
          </Segment>
          <Segment className="table-segment datahive-segment fix-loading-width">
            <SortableTable
              tableProps={{ className: "SortableTable" }}
              columns={this.props.columns}
            >
              <Table.Body>
                {this.props.isLoading ? <LoaderSpinner /> : rows}
              </Table.Body>
            </SortableTable>
          </Segment>
          <Pagination
            activePage={this.props.filters}
            onPageChange={this.props.pageChange}
            totalPages={this.props.totalPages}
          />
        </Segment.Group>
      </div>
    );
  }
}

export default ContainerList;
