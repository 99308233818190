import _ from "lodash";
import React, {Component} from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Icon,
  Input,
  Pagination,
  Table
} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import config from "../../config/config";

import LoaderSpinner from "../../components/LoaderSpinner";
import "./UPEUList.css";
import ajax from "../../helpers/ajax";
import {Link} from "react-router-dom";
import qs from "query-string";
import TreeSelect from "../../components/simple/TreeSelect/TreeSelect";
import {makeOptionsTree, } from "../../components/simple/TreeSelect/utils";
import PropTypes from "prop-types";

class UPEUList extends Component {
   static propTypes = {
     location: PropTypes.object.isRequired,
     history: PropTypes.object.isRequired
   };

   constructor(props) {
     super(props);
     this.state = {
       displayColumns: {
         title: true,
         appId: true,
         GPCategories: true,
         categories: true,
         noUsers: true,
         blacklist: true,
         accept: true,
       },
       loadingData: false,
       sortColumns: {},
       page: 1,
       tasks: [],
       selectedTaskId: null,
       appSearch: "",
     };
   }

  colHeaders = {
    appId: "App ID",
    title: "App name",
    GPCategories: "Google Play category",
    categories: "Categories",
    noUsers: "No. of users",
    blacklist: "Blacklist",
    accept: "Accept",
  };

  componentDidMount() {
    const filters = qs.parse(this.props.location.search);
    this.getData(filters.selectedTaskId, filters.page, filters.appSearch, filters.order_by, filters.filter);
    this.getTasksApps();
  }

  getTasksApps = () => {
    ajax.get(config.UPEU_TASKS)
      .then((response) => {
        const data = response.data;
        this.setState({
          tasks: data
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      const filters = qs.parse(this.props.location.search);
      this.getData(filters.selectedTaskId, filters.page, filters.appSearch, filters.order_by, filters.filter);
    }
  }

  saveAnswer = (data, idx) => {
    ajax.post(config.UPEU_APPS, {data})
      .then((response) => {
        if (_.get(response, "status") === 200) {
          const newDataRows = _.cloneDeep(this.state.dataRows);
          newDataRows[idx].ans_id = response.data.ans_id;
          this.setState({dataRows: newDataRows});
          toastr.success("Saved!");
        }
      })
      .catch((error) => {
        toastr.error("Error occurred!", error.response.statusText);
        const filters = qs.parse(this.props.location.search);
        this.getData(filters.selectedTaskId, filters.page, filters.appSearch, filters.order_by, filters.filter);
      });
  };

  handleBlacklist = (idx, checked) => {
    const dataRows = _.cloneDeep(this.state.dataRows);
    const row = dataRows[idx];
    row.blacklist = checked;
    row.accept = !checked;
    this.saveAnswer({
      ans_id: row.ans_id,
      task_slug: row.task_slug,
      question_id: row.question_id,
      blacklist: checked,
    }, idx);
    this.setState({dataRows});
  };

  handleAccept = (idx, checked) => {
    const dataRows = _.cloneDeep(this.state.dataRows);
    const row = dataRows[idx];
    row.accept = checked;
    row.blacklist = !checked;
    this.saveAnswer({
      ans_id: row.ans_id,
      task_slug: row.task_slug,
      question_id: row.question_id,
      accept: checked,
    }, idx);
    this.setState({dataRows});
  };

  handleCategoriesChange = (idx, value) => {
    const newRows = _.cloneDeep(this.state.dataRows);
    const row = newRows[idx];
    row.categories = value;
    this.saveAnswer({
      ans_id: row.ans_id,
      task_slug: row.task_slug,
      question_id: row.question_id,
      categories: value,
    }, idx);
    this.setState({dataRows: newRows});
  };

  renderContentRows = () => {
    const rows = _.map(this.state.dataRows, (row, idx) => {
      if (!row) {
        return (
          <Table.Row key={idx}>
            <Table.Cell>No data in row.</Table.Cell>
          </Table.Row>
        );
      }

      return (
        <Table.Row key={idx}>
          {this.state.displayColumns.appId &&
          <Table.Cell width={2}>
            <Link to={`/task/${row.task_slug}/done?order_by=date&page=1`}>
              {row.appId}
            </Link>
          </Table.Cell>
          }
          {this.state.displayColumns.title &&
          <Table.Cell width={2}>{row.title}</Table.Cell>
          }
          {this.state.displayColumns.GPCategories &&
          <Table.Cell>{row.GPCategories ? row.GPCategories.join(", ") : null}</Table.Cell>
          }
          {this.state.displayColumns.categories && row.categories_options &&
          <Table.Cell>
            <TreeSelect
              multiple={true}
              value={row.categories || []}
              data={makeOptionsTree(row.categories_options)}
              updateValue={(value) => this.handleCategoriesChange(idx, value)}
            />

          </Table.Cell>
          }
          {this.state.displayColumns.noUsers &&
          <Table.Cell>{row.noUsers}</Table.Cell>
          }
          {this.state.displayColumns.blacklist &&
          <Table.Cell>
            <Checkbox
              className={"blacklist-checkbox"}
              checked={row.blacklist}
              onChange={(e, {checked}) => this.handleBlacklist(idx, checked)}
            />
          </Table.Cell>
          }
          {this.state.displayColumns.accept &&
          <Table.Cell>
            <Checkbox
              className={"accept-checkbox"}
              checked={row.accept}
              onChange={(e, {checked}) => this.handleAccept(idx, checked)}
            />
          </Table.Cell>
          }

        </Table.Row>
      );
    });

    return rows;
  };

  toggleDisplay = (colName) => {
    this.setState({
      displayColumns: {
        ...this.state.displayColumns,
        [colName]: !this.state.displayColumns[colName],
      }
    });
  };

  renderTableWhichColumns = () => {
    const cols = this.state.displayColumns;
    const headers = _.cloneDeep(this.colHeaders);
    delete headers.appId;
    const colNames = _.map(headers, (colText, colKey) => colKey);
    return (
      <Table selectable singleLine>
        <Table.Header className={"header"}>
          <Table.Row>
            <Table.HeaderCell width={2}>Column name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Show</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(colNames, (colName, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell width={2}>{colName}</Table.Cell>
                <Table.Cell width={2}>
                  <Checkbox
                    className="toggle-display"
                    checked={cols[colName]}
                    onClick={() => this.toggleDisplay(colName)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  getIcon = (colName) => {
    const filters = qs.parse(this.props.location.search);
    if (filters.order_by) {
      if (filters.order_by === colName) {
        return <Icon name="sort up"/>;
      }
      if (filters.order_by === "-" + colName) {
        return <Icon name="sort down"/>;
      }
    }
    return <Icon name="sort"/>;
  };

  toggleFilter = (colName, checked) => {
    const filters = qs.parse(this.props.location.search);
    const filter = _.get(filters, "filter");

    let newFilter = qs.parse(this.props.location.search);
    if (!checked) {
      newFilter = null;
    }
    if (filter !== colName) {
      newFilter = colName;
    }

    filters.filter = newFilter;

    this.props.history.push({search: qs.stringify(filters)});
    this.getData(filters.selectedTaskId, filters.page, filters.appSearch, filters.order_by, filters.filter);
  };

  getHeader = (colName) => {
    const filters = qs.parse(this.props.location.search);
    if (this.state.displayColumns[colName]) {
      if (_.includes(["blacklist", "accept"], colName)) {
        return (
          <Table.HeaderCell className="upeu-header">
            <Checkbox
              className={"filter-checkbox"}
              label={this.colHeaders[colName]}
              checked={filters.filter === colName}
              onChange={(e, {checked}) => this.toggleFilter(colName, checked)}
            />
          </Table.HeaderCell>);
      }
      if (colName === "categories") {
        return (
          <Table.HeaderCell
            width={2} key={colName}
          >
            {this.colHeaders[colName]}
          </Table.HeaderCell>);
      }
      return (
        <Table.HeaderCell
          className="upeu-header"
          width={2} key={colName}
          onClick={() => this.toggleSort(colName)}
        >
          {this.colHeaders[colName]} {this.getIcon(colName)}
        </Table.HeaderCell>
      );
    }
    return null;
  };

  getHeaders = () => {
    return _.map(this.colHeaders, (colText, colKey) => {
      return this.getHeader(colKey);
    });
  };

  toggleSort = (colName) => {
    const filters = qs.parse(this.props.location.search);
    const orderBy = _.get(filters, "order_by");
    let newOrder = null;
    if (orderBy === colName) {
      newOrder = "-" + orderBy;
    } else if (orderBy === "-" + colName) {
      newOrder = null;
    } else {
      newOrder = colName;
    }
    filters.order_by = newOrder;
    if (!filters.order_by) {
      delete filters.order_by;
    }
    this.props.history.push({search: qs.stringify(filters)});

    this.getData(filters.selectedTaskId, filters.page, this.state.appSearch, null /*sortColumns */, this.state.filter);
  };

  getTaskOptions = () => {
    return (
      _.map(this.state.tasks, (task, idx) => {
        return {
          text: task.title,
          key: idx,
          value: task.slug,
        };
      })
    );
  };

  taskSelect = (e, {value}) => {
    const newSearch = qs.parse(this.props.location.search);
    newSearch.selectedTaskId = value;
    newSearch.page = 1;
    this.props.history.push({search: qs.stringify(newSearch)});
    this.getData(value, 1, this.state.appSearch, this.state.sort, this.state.filter);
  };

  clearTask = () => {
    const filters = qs.parse(this.props.location.search);
    delete filters.selectedTaskId;
    this.props.history.push({search: qs.stringify(filters)});
    this.getData(null, 1, this.state.appSearch, filters.order_by);
  };

  getData = (task_id, page, appSearch = null, sort = null, filter = null) => {
    this.setState({loadingData: true});
    const params = {};
    if (task_id) {
      params.task_id = task_id;
    }
    if (page) {
      params.page = page;
    }
    if (appSearch) {
      params.app_search = appSearch;
    }
    if (sort) {
      params.order_by = sort;
    }
    if (filter) {
      params.filter = filter;
    }
    ajax.get(config.UPEU_APPS, {
      params
    })
      .then((response) => {
        const data = response.data;
        this.setState({
          loadingData: false,
          pages: data.num_pages,
          page: data.page_number,
          dataRows: data.results,
          categories: data.categories,
        });
      });
  };

  _appSearchChange = (value) => {
    const filters = qs.parse(this.props.location.search);
    filters.appSearch = value;
    if (!filters.appSearch) {
      delete filters.appSearch;
    }
    filters.page = 1;
    this.props.history.push({search: qs.stringify(filters)});
    this.getData(filters.selectedTaskId, filters.page, filters.appSearch, filters.order_by, filters.filter);
  };

  debouncedAppSearchChange = _.debounce(this._appSearchChange, 1500);

  appSearchChange = (e, {value}) => {
    this.setState({appSearch: value});

    this.debouncedAppSearchChange.cancel();
    this.debouncedAppSearchChange(value);
  }

  pageChange = (e, {activePage}) => {
    const filters = qs.parse(this.props.location.search);
    filters.page = activePage;
    this.props.history.push({search: qs.stringify(filters)});

    this.getData(filters.selectedTaskId, filters.page, this.state.appSearch, filters.order_by, this.state.filter);
  };

  render() {
    const filters = qs.parse(this.props.location.search);
    const taskOptions = this.getTaskOptions();
    return (
      <Container className={"UPEUList"}>
        <Grid>
          <Grid.Row columns={1} centered>
            <Pagination
              activePage={filters.page}
              ellipsisItem={{content: <Icon name="ellipsis horizontal"/>, icon: true}}
              firstItem={{content: <Icon name="angle double left"/>, icon: true}}
              lastItem={{content: <Icon name="angle double right"/>, icon: true}}
              prevItem={{content: <Icon name="angle left"/>, icon: true}}
              nextItem={{content: <Icon name="angle right"/>, icon: true}}
              onPageChange={this.pageChange}
              totalPages={this.state.pages || 1}
            />
          </Grid.Row>
          <Grid.Row>
            <Button as="div" labelPosition="left">
              <Dropdown
                className={"task-select-dropdown"}
                search selection fluid
                placeholder="Task"
                options={taskOptions}
                value={filters.selectedTaskId || null}
                onChange={this.taskSelect}
              />
              <Button
                className={"task-unselect"}
                icon={"delete"}
                onClick={this.clearTask}/>
            </Button>
            <Input
              placeholder="App"
              value={this.state.appSearch}
              onChange={this.appSearchChange}
            />
          </Grid.Row>
          <Grid.Row>
            <Table selectable>
              <Table.Header className={"header"} fullWidth>
                <Table.Row>
                  {this.getHeaders()}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {(!this.state.loadingData) && this.renderContentRows()}

              </Table.Body>
            </Table>
          </Grid.Row>
          <Grid.Row>
            {(this.state.loadingData) && <LoaderSpinner/>}
          </Grid.Row>
          <Grid.Row>
            <Accordion>
              <Accordion.Title
                active={this.state.openAccordion}
                onClick={() => this.setState({openAccordion: !this.state.openAccordion})}
              >
                <Icon name="dropdown"/>
                Columns to display
              </Accordion.Title>
              <Accordion.Content active={this.state.openAccordion}>
                {this.renderTableWhichColumns()}
              </Accordion.Content>
            </Accordion>
          </Grid.Row>
        </Grid>

      </Container>
    );
  }
}

export default UPEUList;
