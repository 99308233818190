import _ from "lodash";

import React, {Component} from "react";
import PropTypes from "prop-types";

import "./Movable.css";

const COMMAND_MOVE = "move";

export default class Movable extends Component {
  static propTypes = {
    componentId: PropTypes.string,
    draggable: PropTypes.bool,
    onDrop: PropTypes.func,
    component: PropTypes.element.isRequired,
    componentType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dragged: false,
      dragOverMove: false,
      counter: 0,
    };
  }

  onDragStart = (e) => {
    e.dataTransfer.setData(COMMAND_MOVE, true);
    e.dataTransfer.setData("id", this.props.componentId);

    e.stopPropagation();

    this.setState({dragged: true});
  };

  onDragEnd = () => {
    this.setState({dragged: false});
  };

  onDragEnter = (e) => {
    if (_.includes(e.dataTransfer.types, COMMAND_MOVE)) {
      e.stopPropagation();
      if (!this.state.dragged) {
        this.setState({dragOverMove: true});
      }
      const counter = this.state.counter + 1;
      this.setState({counter});
    }
  };

  onDragLeave = (e) => {
    if (_.includes(e.dataTransfer.types, COMMAND_MOVE)) {
      const counter = this.state.counter - 1;
      if (counter === 0) {
        this.setState({dragOverMove: false});
      }
      this.setState({counter});
    }
  };

  onDragOver = (e) => {
    if (_.includes(e.dataTransfer.types, COMMAND_MOVE)) {
      const counter = 1;
      this.setState({counter});
    }
  };

  onDrop = (e) => {
    // if element is not drop on itself -> call onDrop
    if (e.dataTransfer.getData("id") !== this.props.component.props.componentId) {
      this.props.onDrop(e);
      e.stopPropagation();
    }
    this.setState({dragOverMove: false});
  };

  render() {
    let className = "Movable ";
    if (this.state.dragged) {
      className += "dragged ";
    }

    let spacerClassName = "";
    if (this.state.dragOverMove) {
      spacerClassName += "dragover ";
    }

    // spacer visible only with ondragover, space to drop widget
    const spacerDiv = this.state.dragOverMove ?
      <div
        className={spacerClassName}
        style={{height: "75px"}}
        onDrop={(e) => this.onDrop(e)}
      /> : "";

    return (
      <div
        className={className}
        draggable={this.props.draggable || false}
        onDragStart={(e) => this.onDragStart(e)}
        onDragEnd={(e) => this.onDragEnd(e)}
        onDragEnter={(e) => this.onDragEnter(e)}
        onDragLeave={(e) => this.onDragLeave(e)}
        onDragOver={(e) => this.onDragOver(e)}
      >
        {spacerDiv}
        <div
          onDrop={() => this.setState({dragOverMove: false})}
        >
          {this.props.component}
        </div>
      </div>
    );
  }
}
