/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import PropTypes from "prop-types";
import "./MediaPlayerWidget.css";
import Widget from "../Widget";

import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";


class MediaPlayerWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    align: PropTypes.string,
    justify: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static getDefaultConfig = () => {
    return {
      align: "align-left",
      justify: false,
    };
  };

  componentDidMount() {
    this.registerEmptyValid();
  }

  isValid = () => {
    return true;
  }

  isEmpty = () => {
    return false;
  }

  render() {
    const customClasses = `${this.props.widgetConfig.align}`;
    const url = this.props.value ? this.props.value.url : "empty_url";
    const width = this.props.widgetConfig.width ? this.props.widgetConfig.width : 640;
    const height = this.props.widgetConfig.height ? this.props.widgetConfig.height : 480;
    const audio_only = this.props.widgetConfig.audio_only || false;

    let player;
    if (audio_only) {
      player = <ReactAudioPlayer
        className={`player ${customClasses}`}
        src={url}
        controls
        title="-"
      />;
    } else {
      player = <ReactPlayer
        controls
        className={`player ${customClasses}`}
        url={url}
        width={width + "px"}
        height={height + "px"}
      />;
    }

    return (
      <div className={"MediaPlayerWidget Widget"}>
        {!this.props.editorMode && (url === undefined || !url.startsWith("http")) ?
          <span className={"configurationError"}>
            Player has invalid url configuration: {url}
          </span> :
          ""}
        {player}
      </div>
    );
  }
}

export default MediaPlayerWidget;
