import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";

const PasswordRequirements = ({message}) => {
  return <div>
    {
      message ?
        <Segment.Group>
          {_.map(message, (msg) =>
            <Segment
              className="datahive-segment"
              key={msg}
              inverted={true}
              color={"red"}>
              {msg}
            </Segment>
          )}
        </Segment.Group> : undefined
    }
  </div>;
};

PasswordRequirements.propTypes = {
  message: PropTypes.array
};

export default PasswordRequirements;
