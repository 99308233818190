import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Input, Form, Segment, Message} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";

import LoaderSpinner from "../../../components/LoaderSpinner";
import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import DragAndDropSelector from "../../../components/simple/DragAndDropSelector/DragAndDropSelector";
import ContainerDetail from "../../../components/ContainerDetail";

import "./ModuleDetail.css";


class ModuleDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.emptyModule = {
      id: null,
      name: "",
      project: null,
      coordinators: [],
      tasks: [],
      description: ""
    };
    this.state = {
      allUsers: [],
      users: [],
      tasks: [],
      displaySpinner: true,
      module: {...this.emptyModule},
      displayDeleteModal: false,
      accessible_tasks: [],
      displayMessage: false
    };
    this.arrayFilters = ["projects"];
  }

  componentDidMount() {
    this.getData();
    this.getOptions();
  }

  getData = () => {
    if (!this.props.location.pathname.includes(urls.MODULE_DETAIL_NEW)) {
      ajax.get([config.MODULE_DETAIL, this.props.match.params.id])
        .then((response) => {
          this.setState({
            module: response.data,
            displaySpinner: false,
            tasks: response.data.tasks
          });
        })
        .catch(() => {
          toastr.error("Error!", "Probably you don't have access to module");
          this.setState({ displayMessage: true})
        });
    } else {
      this.setState({
        displaySpinner: false,
      });
    }
  };

  getOptions = () => {
    const params = {};
    if (!this.props.location.pathname.includes(urls.MODULE_DETAIL_NEW)) {
      params["module_id"] = this.props.match.params.id;
    } else {
      params["unassigned_module"] = true;
    }
    ajax.get(config.USER_LIST_ALL, {params: {include_inactive: true}})
      .then((response) => {
        this.setState({
          allUsers: response.data,
          users: _.filter(response.data, "is_active")
        });
      });
    ajax.get(config.PROJECT_OPTIONS)
      .then((response) => {
        const options = _.map(response.data, (project) => ({
          key: project.id,
          value: project.id,
          text: project.name
        }));
        this.setState({
          projects: options
        });
      });
    ajax.get(config.TASKS, {params})
      .then((response) => {
        this.setState({
        accessible_tasks: response.data});
      })
  };

  save = () => {
    let data = _.cloneDeep(this.state.module);
    if (!this.props.location.pathname.includes(urls.MODULE_DETAIL_NEW)) {
      ajax.patch([config.MODULE_DETAIL, data.id], {data})
        .then(() => {
          toastr.success("Success", "Module updated!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    } else {
      ajax.post(config.MODULES, {data})
        .then(() => {
          this.props.history.push(urls.MODULES);
          toastr.success("Success", "Module saved!");
        })
        .catch((error) => {
          let message = "Error ";
          if (error.response) {
            for (const key in error.response.data) {
              message = message + key + ": " + error.response.data[key] + " ";
            }
          }
          toastr.error("Error!", message);
        });
    }
  }

  delete = () => {
    let data = _.cloneDeep(this.state.module);
    if (!this.props.location.pathname.includes(urls.MODULE_DETAIL_NEW)) {
      ajax.delete([config.MODULE_DETAIL, data.id], {data})
        .then(() => {
          this.props.history.push(urls.MODULES);
          toastr.success("Success", "Module deleted!");
        })
        .catch((error) => {
          toastr.error("Error!", error.response.data["detail"]);
        });
    } else {
      toastr.error("Error!", "Module doesn't exist");
    }
  }

  changeModule = (newVal) =>
    this.updateModule({
      ...this.state.module,
      ...newVal
    });

  updateModule = (module) => {
    this.setState({module});
  };

  toggleModal = () => {
    this.setState((prevState) => ({displayDeleteModal: !prevState.displayDeleteModal}));
  };

  render() {
    const {module, allUsers, users, projects, displaySpinner, displayDeleteModal, accessible_tasks} = this.state;
    const {history} = this.props;

    const details = <Segment.Group>
      <Segment className="datahive-segment">
        <Form>
        <Form.Group>
          <Form.Field className={"companyName"} width="12">
            <label>Module name</label>
            <Input
              placeholder="Name"
              value={module.name}
              onChange={(e) => this.changeModule({name: e.target.value})}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width="12">
            <label>Description</label>
            <Input
              fluid
              placeholder="Description"
              value={module.description}
              onChange={(e) => this.changeModule({description: e.target.value})}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <label>Project</label>
            <Form.Dropdown
              id="project"
              placeholder="Select Project"
              selection
              options={projects || []}
              value={module.project ? module.project : ""}
              onChange={(e, {value}) => this.changeModule(
                {project: value}
              )}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field className={"DragAndDropSelectorGroup"}>
            <label>Coordinators</label>
            {allUsers
              ? <DragAndDropSelector
                name={"coordinators"}
                allOptions={allUsers}
                choices={users}
                choiceValue={"username"}
                filterable
                selectAll
                selected={module.coordinators || []}
                afterSelectionChange={(usr) => this.changeModule({coordinators: usr})}
              />
              : <LoaderSpinner/>
            }
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field className={"DragAndDropSelectorGroup DragAndDropSelectorTasks"}>
            <label>Tasks</label>
            {accessible_tasks
              ? <DragAndDropSelector
                choices={accessible_tasks || []}
                choiceValue={"title"}
                selectAll
                afterSelectionChange={(t) => this.changeModule({tasks: t})}
                selected={module.tasks || []}/>
              : <LoaderSpinner/>
            }
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  </Segment.Group>;
    const panelRight =
      <Button color="green"
        className="datahive-button"
        onClick={this.save}
        content="Save"
      />;
    const panelLeft =
      <Button color="red"
        className="datahive-button"
        onClick={this.toggleModal}
        content="Delete"
      />;
    const title = "Are you sure you want to delete this module?";
    const buttons = <>
      <Button onClick={this.delete} content="Delete" color="red" className="datahive-button" id={"delete"} floated={"left"}/>
      <Button onClick={this.toggleModal} className="datahive-button" color="dark-grey" content="Cancel"/>
    </>;

    if (!!this.state.displayMessage) {
      return <Message
          className="datahive-message moduleDetail"
          warning={false}
          header="Information"
          content="Probably you don't have sufficient rights to module."/>}

    return <ContainerDetail
      details={details}
      url={urls.MODULE_DETAIL}
      urlNew={urls.MODULE_DETAIL_NEW}
      button={buttons}
      item={module}
      history={history}
      displaySpinner={displaySpinner}
      displayDeleteModal={displayDeleteModal}
      panelRight={panelRight}
      panelLeft={panelLeft}
      title={title}
      toggleModal={this.toggleModal}
    />;
  }
}

export default withRouter(ModuleDetail);
