import React, {Component} from "react";
import {Table, Checkbox} from "semantic-ui-react";
import urls from "../../../config/frontend_urls";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class QuestionListRow extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    handleSelection: PropTypes.func,
  };

  render() {
    const question = this.props.question;

    return (
      <Table.Row>
        <Table.Cell>
          <Checkbox label={
            <label data-q-id={question.id}>
              <Link to={urls.ADMIN_QUESTIONS_DETAIL.replace(":id", question.id)}>
                {question.id}
              </Link>
            </label>}
          checked={this.props.checked}
          onChange={this.props.handleSelection}/>
        </Table.Cell>
        <Table.Cell>
          {question.answer_cnt}
        </Table.Cell>
      </Table.Row>
    );
  }
}
