/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import _ from "lodash";
import Widget from "../Widget";
import {withRouter} from "react-router-dom";
import WaveSurferWaveform from "./WaveSurfer/components/waveSurferWaveform";
import AnnotationContainer from "./AnnotationContainer";
import PropTypes from "prop-types";
import {bufferToWave16} from "./WaveSurfer/utils/waveSurferOperation";
import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import {toastr} from "react-redux-toastr";
import {Popup, List, Icon, Label} from "semantic-ui-react";


class MediaWaveformWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static getDefaultConfig = () => {
    return {
      annotations: {},
      record: ""
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      record: "",
      showAnnotationMenu: false,
      selectedRegion: null,
      pauseOnRegionBoundary: false,
      recordingLength: 0,

    };

    // Create reference to change WaveSurfer's Regions from AnnotationContainer.
    this.waveSurfer = React.createRef();
    this.regionPopupRef = null;
    this.wavesurferInstance = null;

    const MEDIA_URL = (window.BACKEND_URL || config.BACKEND_URL) + config.MEDIA_SUFFIX;
    const record = _.get(props.widgetVals, `[${props.componentId}].record`, "");
    if (record.includes("http")) {
      this.src = record;
    } else {
      this.src = MEDIA_URL + record;
    }
    this.frameRate = _.get(props.widgetVals, `[${props.componentId}].frame_rate`, 0);

    this.onAnnotationChange = this.onAnnotationChange.bind(this);

    this.id = String(_.uniqueId());
  }

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    return !(widgetVal && !!widgetVal.annotations && _.keys(widgetVal.annotations).length);
  };

  hasEmptyAnnotations = () => {
    const emptyAnn = this.props.widgetVals.videoWidget.annotations ? Object.values(this.props.widgetVals.videoWidget.annotations).filter(x => x.note && x.note.annotation_container?.length === 0 || x.note.annotation_container?.length === undefined): [];
    return emptyAnn.length > 0;
  };

  isValid = () => {
    return true;
  };

  hideMenu = () => {
    // Hides annotation menu and (cut, trim) buttons.
    this.setState({showAnnotationMenu: false, selectedRegion: null});
  };

  showMenu = (region) => {
    /* Shows annotation menu and (cut, trim) buttons.
    Sets recently created Region as selected. */
    const {id, start, end, data, color} = region;
    this.regionPopupRef = region.element;
    const selectedRegion = {id, start, end, note: data ? data : this.props.widgetConfig.noteChoices ? {} : "", color: color};
    this.setState({showAnnotationMenu: true, selectedRegion});
  };

  setWavesurferInstance = (wavesurfer) => {
    this.wavesurferInstance = wavesurfer;
  };

  prepareForSubmit = () => {
    if (this.props.widgetConfig.enableCut) {
      return this.saveToWav();
    }
  };

  saveToWav = () => {
    const waveSurfer = this.wavesurferInstance;
    // Convert audio buffer to .wav blob and save it as file.
    const buffer = waveSurfer.backend.buffer;
    const blob = bufferToWave16(buffer, 0, buffer.length);
    const taskSlug = this.props.location?.pathname?.split('/')[2]

    const data = new FormData();
    data.append("questionId", this.props.questionId);
    data.append("file", blob, "submitted_file.wav");
    data.append("slug", taskSlug)

    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return (
      ajax.post(config.FILE_UPLOAD_WIDGET, {
        data, options
      })
        .then((response) => {
          toastr.success("Success!", "File saved");
          const {data} = response;
          if (data.length === 1) {
            const widgetVals = _.cloneDeep(this.props.widgetVals);
            let widgetVal = widgetVals[this.props.componentId];
            const filename = data[0].path;
            if (!widgetVal) {
              widgetVals[this.props.componentId].record = filename;
              widgetVal = widgetVals[this.props.componentId];
            } else {
              widgetVal.record = filename;
            }

            this.update(widgetVal);
          }
        })
        .catch((error) => {
          toastr.error("Error!", error);
        })
    );
  };

  setRecordingLength = (recordingLength) => {
    this.setState({recordingLength});
  };

  onRegionUpdated = (region) => {
    // Invoked on region creation or drag.
    if (region.start < 0) {
      region.start = 0;
    }
    const annotations = _.get(this.props.value, `annotations`);
    const oldRegion = _.find(annotations, {id: region.id});
    region.note = oldRegion ? oldRegion.note : this.props.widgetConfig.noteChoices ? {} : "";

    this.saveAnnotation(region, !oldRegion)

    this.showMenu(region);
  };

  onRegionClick = (region, selectionOnly) => {
    this.showMenu(region);
    if (selectionOnly) {
      return;
    }
    this.wavesurferInstance.setCurrentTime(region.start);
    if (!this.state.pauseOnRegionBoundary) {
      this.wavesurferInstance.play();
    }
  };

  onAnnotationChange = ({start = null, end = null, note = null} = {}) => {
    const region = _.cloneDeep(this.state.selectedRegion);
    const widgetVal = this.props.widgetVals[this.props.componentId];
    region.start = start != null ? start : region.start;
    region.end = end != null ? end : region.end;
    region.note = note != null ? note : region.note;

    if (
      this.props.widgetConfig.disableAnnotationIntersection &&
      Object.values(widgetVal.annotations)
        .filter((reg) => reg.id !== region.id)
        .some((reg) => reg.start <= region.end && reg.end >= region.start)
    ) {
      return/* toastr.error(
        `Annotation intersecting!`,
        `Annotations can't intersect with each other`
      )*/;
    }

    this.setState({selectedRegion: region}, () => this.saveAnnotation(region, false));
  };

  setNote = (note) => {
    const region = _.cloneDeep(this.state.selectedRegion);
    region.note = note;
    this.setState({selectedRegion: region}, () => this.saveAnnotation(region, false));
  };

  setContainer = (widgetVal, componentId) => {
    const region = _.cloneDeep(this.state.selectedRegion);
    region.note[componentId] = _.cloneDeep(widgetVal);
    this.setState({selectedRegion: region}, () => this.saveAnnotation(region, false));
  }

  saveAnnotation = (region, changeColor = true) => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    const {widgetConfig} = this.props;

    if (!widgetVal.annotations) {
      widgetVal.annotations = {}; // Initialize
    }

    // Update region in WaveSurfer.
    this.waveSurfer.current.updateRegion(region, changeColor);

    // Add correlated annotation to MediaWaveformWidget's values.
    const annotation = {
      id: region.id,
      start: region.start,
      start_frame: Math.round(region.start * this.frameRate),
      end: region.end,
      end_frame: Math.round(region.end * this.frameRate),
      note: region.note,
      color: region.color
    };

    if (!_.isEmpty(widgetVal.annotations)) {
      if (isNaN(annotation.id) === false) {
        // id is numeric
        _.set(widgetVal, `annotations[${annotation.id}]`, annotation);
      } else {
        const found = _.filter(widgetVal.annotations, function (o) {
          return o.id === annotation.id;
        });
        if (found.length > 0) {
          // Update
          let index = -1, value = undefined;
          for ([index, value] of Object.entries(widgetVal.annotations)) {
            // Find position
            if (value.id === annotation.id) {
              break;
            }
          }
          _.set(widgetVal, `annotations[${index}]`, annotation);
        } else {
          // Add
          const l = Math.max(...Object.keys(widgetVal.annotations).map((item) => parseInt(item)));
          _.set(widgetVal, `annotations[${l + 1}]`, annotation);
        }
      }
    } else {
      // First annotation
      _.set(widgetVal, "annotations[0]", annotation);
    }

    if (widgetConfig.annotationCntLimit && !!widgetVal.annotations &&
      _.keys(widgetVal.annotations).length > widgetConfig.annotationCntLimit) {
      this.waveSurfer.current.removeRegion(annotation.id);
      return false;
    } else {

      this.update(widgetVal);
      return true;
    }

  };

  removeAnnotation = (
    region = null,
    ommitWidgetVals = false // don't update widget vals on create annotation error
  ) => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    const regionId = region ? region.id : this.state.selectedRegion.id;

    // Remove region from WaveSurfer.
    this.waveSurfer.current.removeRegion(regionId);

    // Remove correlated annotation from MediaWaveformWidget's values.
    if (widgetVal.annotations && !ommitWidgetVals) {
      const key = Object.entries(widgetVal.annotations).find(([, value]) => value.id === regionId)[0]
      delete widgetVal.annotations[key];
    }

    this.update(widgetVal);
    this.hideMenu();
  };

  removeAllAnnotations = () => {
    // Clear MediaWaveformWidget's state.
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    widgetVal.annotations = {};

    this.update(widgetVal);
    this.hideMenu();
  };

  componentDidMount() {
    if (!this.props.editorMode) {
      this.registerEmptyValid();
    }
  }

  render() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetConfig = _.cloneDeep(this.props.widgetConfig);
    const widgetVal = widgetVals[this.props.componentId];
    const availableChoices = widgetConfig.noteChoices ? widgetConfig.noteChoices.structure[0].options: [];
    const annotations = widgetVal ? widgetVal.annotations : {};
    let annotation = null;
    const regionId = _.get(this.state.selectedRegion, "id");
    if (!_.isEmpty(annotations) && typeof regionId !== "undefined") {
      if (isNaN(regionId) === false) {
        // regionId is numeric
        annotation = annotations[regionId];
      } else {
        const found = _.filter(annotations, function (o) {
          return o.id === regionId;
        });
        if (this.state.selectedRegion && !!found.length) {
          annotation = found[0];
        }
      }
    }
    let sorted = annotations? Object.values(annotations).sort((a, b) => a.start - b.start): "";
    return (
      <div>
        <div className="media-waveform-widget">
          <WaveSurferWaveform
            ref={this.waveSurfer}
            src={this.src}
            questionId={this.props.questionId}
            editorMode={this.props.editorMode}
            selectedRegion={this.state.selectedRegion}
            annotations={annotations || {}}
            removeAllAnnotations={this.removeAllAnnotations}
            onRegionClick={this.onRegionClick}
            hideMenu={this.hideMenu}
            onRegionUpdated={this.onRegionUpdated}
            showRegionButtons={this.state.showAnnotationMenu}
            saveToWav={this.saveToWav}
            setRecordingLength={this.setRecordingLength}
            setWavesurferInstance={this.setWavesurferInstance}
            enableCut={widgetConfig.enableCut}
            pauseOnRegionBoundary={this.state.pauseOnRegionBoundary}
            setPauseOnRegionBoundary={(pauseOnRegionBoundary) => {this.setState({pauseOnRegionBoundary}); this.hideMenu()}}
            isVideo={widgetConfig.isVideo}
            disableAnnotationIntersection={widgetConfig.disableAnnotationIntersection}
            disableDrag={widgetConfig.disableDrag}
            minLength={widgetConfig.minLength}
            id={this.id}
            removeAnnotation={this.removeAnnotation}
          />
        </div>
        {widgetConfig.noteChoices ?
            <List divided verticalAlign='middle' size='medium'>
              {sorted ? Object.keys(sorted).map((item, i) => (
                  <List.Item style={{padding: '1rem'}} className={regionId === sorted[item].id ? 'selectedRegion': ''} key={i}>
                    <List.Content floated='right'>
                      {sorted[item].note.annotation_container && sorted[item].note.annotation_container.length > 0 && availableChoices ? availableChoices.map((choice, key) => (
                          <Label
                              className={sorted[item].note.annotation_container && sorted[item].note.annotation_container.includes(choice.value.replace(" ", "_")) ? "visible" : "hidden"}
                              key={key} size='small' color={choice.color}>{choice.value}</Label>
                      )) : <Label size='small'>Empty</Label>}

                    </List.Content>
                    <div className="flex">
                      <Icon size='large' style={{color: sorted[item].color}} name='circle'/>
                      <span
                          style={{color: sorted[item].note.annotation_container && sorted[item].note.annotation_container.length > 0 ? "black" : "red"}}><b>{sorted[item].start} - {sorted[item].end} s.&nbsp;</b></span>
                      <Label size='mini' color="red"
                             className={sorted[item].note.annotation_container && sorted[item].note.annotation_container.length > 0 ? "hidden" : "visible"}><Icon
                          name='warning sign'/> No sounds annotated</Label>
                    </div>
                  </List.Item>
              )) : ''}
            </List>
            : ''
        }
        {widgetConfig.noteChoices  && !this.props.editorMode && annotation ?
          <Popup
              open={this.state.showAnnotationMenu}
              context={this.regionPopupRef}

          >
            <AnnotationContainer
                noteChoices={widgetConfig.noteChoices}
                annotation={annotation}
                removeAnnotation={this.removeAnnotation}
                onAnnotationChange={this.onAnnotationChange}
                setNote={widgetConfig.noteChoices ? this.setContainer : this.setNote}
                recordingLength={this.state.recordingLength}
                disableNote={widgetConfig.disableNote}
                closable={true}
                hideMenu={this.hideMenu}
             />
          </Popup>
          :
          this.state.showAnnotationMenu && !this.props.editorMode && annotation ?
            <AnnotationContainer
          annotation={annotation}
          removeAnnotation={this.removeAnnotation}
          onAnnotationChange={this.onAnnotationChange}
          recordingLength={this.state.recordingLength}
          closable={false}
          /> : ""}
      </div>
    );
  }
}

export default withRouter(MediaWaveformWidget);
