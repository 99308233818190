import React, {Component} from "react";

import {Card, Statistic, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";

export default class BasicTaskStatistics extends Component {
  static propTypes = {
    status: PropTypes.string,
    questions: PropTypes.number,
    max_answers: PropTypes.number,
    done_questions: PropTypes.string,
    done_answers: PropTypes.string
  };

  renderStatistic(value, label, color) {
    return (
      <Statistic color={color}>
        <Statistic.Value>{value}</Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>);
  }

  renderStatus(value, label) {
    let color = "black", icon = "", status = "";
    switch (value) {
    case "FIN":
      color = "green";
      icon = "checkmark box";
      status = "Finished";
      break;
    case "PROG":
      color = "yellow";
      icon = "refresh";
      status = "In progress";
      break;
    case "NS":
      color = "grey";
      icon = "square outline";
      status = "Not started";
      break;
    default:
      break;
    }
    return (
      <Statistic color={color}>
        <Statistic.Value><Icon color={color} name={icon}/>{status}</Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>);
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    return (
      <Card.Content>
        <Statistic.Group widths="five" size="mini">
          {this.renderStatus(this.props.status, "Status")}
          {this.renderStatistic(this.props.questions, "Questions", "grey")}
          {this.renderStatistic(this.props.max_answers, "Max answers", "teal")}
          {this.renderStatistic(this.props.done_questions + "%", "Done questions", "blue")}
          {this.renderStatistic(this.props.done_answers + "%", "Done answers", "orange")}
        </Statistic.Group>
      </Card.Content>
    );
  }
}
