import * as actionTypes from "./types/types_network_errors";

export function networkErrorsNew(networkErrors) {
  return {
    type: actionTypes.NETWORK_ERRORS_NEW,
    networkErrors
  };
}

export function networkErrorsClear() {
  return {
    type: actionTypes.NETWORK_ERRORS_CLEAR,
  };
}
