import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {logout} from "../../actions/actions_auth";
import {Grid, Message} from "semantic-ui-react";

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Grid centered columns={3}>
      <Grid.Row>
        <Grid.Column>
          <Message className="datahive-message" header="You have been successfully logged out!"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>;
  }
}

export default connect(null, {logout})(Logout);
