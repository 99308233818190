import React, {Component} from "react";
import {Button, Popup, Label} from "semantic-ui-react";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import {string_to_slug} from "../../helpers/utils";


export const withReportHistoryPopup = (WrappedComponent) => {
  return class Comp extends Component {
    static propTypes = {
      reportHistory: PropTypes.array,
      report: PropTypes.object,
      getHistory: PropTypes.func,
      config: PropTypes.string,
      showWholeHistory: PropTypes.bool
    }

    constructor(props) {
      super(props);
      this.state = {
        lastReportActivity: "",
        showWholeHistory: false,
        reportHistory: []
      };
    }

    getHistory = (id, text) => {
      if (!this.state.reportHistory.length || this.state.lastReportActivity === "error fetching history") {
        ajax.get(this.props.config, {params: {id}})
          .then((result) => {
            this.setState({
              reportHistory: result.data,
              lastReportActivity: `Last ${text} by ${result.data[0].username} on ${result.data[0].timestamp}`
            });
          })
          .catch(() => {
            this.setState({lastReportActivity: "error fetching history"});
          });
      }
    };

    setShowWholeHistory = (bool) => {
      this.setState({showWholeHistory: bool});
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getHistory={this.getHistory}
          lastReportActivity={this.state.lastReportActivity}
          showWholeHistory={this.state.showWholeHistory}
          setShowWholeHistory={this.setShowWholeHistory}
          reportHistory={this.state.reportHistory}/>);
    }
  };
};

const ReportHistoryPopupContent = (props) => {
  return <Popup
    flowing
    hoverable
    position="top right"
    basic
    className={props.showWholeHistory ? "wholeReportHistory" : ""}
    trigger={<span className="hours">{props.hours}</span>}
    onOpen={() => props.getHistory(props.reportId, "edit")}
    onClose={() => props.setShowWholeHistory(false)}
    onClick={(e) => e.stopPropagation()}
    content={
      <HistoryPopupContent
        lastReportActivity={props.lastReportActivity}
        showWholeHistory={props.showWholeHistory}
        reportHistory={props.reportHistory}
        onClick={() => props.setShowWholeHistory(true)}
      />
    }/>;
};

ReportHistoryPopupContent.propTypes = {
  lastReportActivity: PropTypes.string,
  onClick: PropTypes.func,
  reportHistory: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    timestamp: PropTypes.string
  })),
  showWholeHistory: PropTypes.bool,
  report: PropTypes.object,
  reportId: PropTypes.number,
  hours: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object]),
  getHistory: PropTypes.func,
  setShowWholeHistory: PropTypes.func
};

const ReportAcceptedPopupContent = (props) => {
  const {report, getHistory, reportHistory, lastReportActivity} = props;
  const statusLabel = <Label size={"small"} basic className={string_to_slug(!props.status ? report.status : props.status)}>
    {!props.status ? report.status : props.status}
  </Label>;

  const reportStatus = !props.status ? report.status : props.status;

  const getData = () => {
    const status = !props.status ? report.status.toLowerCase() : props.status.toLowerCase();
    !!props.ids ? getHistory(props.ids, status) : getHistory(report.id, status);
  };

  return (<>{reportStatus === "ACCEPTED" || reportStatus === "PRE_ACCEPT" ?
    <Popup
      flowing
      hoverable
      position="top right"
      basic
      trigger={statusLabel}
      onOpen={getData}
      onClick={(e) => e.stopPropagation()}
      content={reportHistory.length > 0 && <p>{lastReportActivity}</p>}/>
    : <>{statusLabel}</>}</>);
};

ReportAcceptedPopupContent.propTypes = {
  lastReportActivity: PropTypes.string,
  onClick: PropTypes.func,
  reportHistory: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    timestamp: PropTypes.string
  })),
  showWholeHistory: PropTypes.bool,
  report: PropTypes.object,
  getHistory: PropTypes.func,
  status: PropTypes.string,
  ids: PropTypes.array,
};

export const HistoryPopupContent = ({showWholeHistory, reportHistory, lastReportActivity, onClick}) => {

  const wholeHistory = reportHistory.map((el, i) => <p key={i}>Edited by {el.username} on {el.timestamp}</p>).reverse();

  const diplayButton = reportHistory.length > 1 && <Button
    compact
    size="tiny"
    floated="right"
    className="datahive-button"
    color="black"
    onClick={onClick}
  >Show whole history</Button>;

  return (showWholeHistory ? wholeHistory : <><p>{lastReportActivity}</p>{diplayButton}</>);
};

HistoryPopupContent.propTypes = {
  lastReportActivity: PropTypes.string,
  onClick: PropTypes.func,
  reportHistory: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    timestamp: PropTypes.string
  })),
  showWholeHistory: PropTypes.bool
};

export const ReportHistoryPopup = withReportHistoryPopup(ReportHistoryPopupContent);
export const ReportAcceptedPopup = withReportHistoryPopup(ReportAcceptedPopupContent);
