import _ from "lodash";


export function validateRelationships(ranges, overlapRules) {
  if (!overlapRules || overlapRules.length === 0) {
    return _.map(ranges, (r) => {
      r.valid = true;
      return r;
    });
  }
  _.each(ranges, (range) => {
    range.valid = true;
    delete range.errorMessage;
  });

  for (let i = 0; i < ranges.length - 1; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const range1 = ranges[i];
      const range2 = ranges[j];
      const [allowed, errorMessage] = checkIfRelationshipAllowed(overlapRules, range1, range2);
      if (!allowed) {
        range1.valid = false;
        range1.errorMessage = (range1.errorMessage ? range1.errorMessage + " " + errorMessage : errorMessage) + "aaa";
        range2.valid = false;
        range2.errorMessage = (range2.errorMessage ? range2.errorMessage + " " + errorMessage : errorMessage) + "bbb";
      }
    }
  }

  return ranges;
}

export function checkIfRelationshipAllowed(overlapRules, range1, range2) {
  // Get both 'sides' of relationship
  const relationship1 = determineRelationship(range1, range2);
  const relationship2 = determineRelationship(range2, range1);
  if (!relationship1 && !relationship2) {
    return [true, null];
  }

  for (let i = 0; i < overlapRules.length; i++) { // Done this way so we can break when we get a match.
    const rule = overlapRules[i];
    if (_.includes(rule.Arg1, range1.type) && _.includes(rule.Arg2, range2.type)) {
      const allowedRelationship = rule["OVL-TYPE"].split("|");

      if (allowedRelationship[0] === "<ANY>") {
        return [true, null];
      }
      // You have to take into account both sides of relationship. One of them being allowed is enough.
      if (_.includes(allowedRelationship, relationship1)) {
        return [true, null];
      }
      if (_.includes(allowedRelationship, relationship2)) {
        return [true, null];
      }
    }
  }
  if (relationship1 === relationship2) {
    return [false, `${relationship1} not allowed between ${range1.type} and ${range2.type}`];
  }
  return [false, `${relationship1} or ${relationship2} not allowed between ${range1.type} and ${range2.type}`];
}

export function determineRelationship(range1, range2) {
  // This function determines what is the relationship between range1 and range2
  // possible values: contain, equal, cross

  if (range2.begin === range1.begin && range2.end === range1.end) {
    return "equal";
  }
  if ((range2.begin >= range1.begin && range2.end < range1.end)
    || (range2.begin > range1.begin && range2.end <= range1.end)) {
    return "contain";
  }
  if (range2.begin < range1.end && range2.end > range1.begin) {
    return "cross";
  }
  return null;
}
