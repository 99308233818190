import _ from "lodash";
import React, {Component} from "react";
import {Dropdown, Header} from "semantic-ui-react";
import PropTypes from "prop-types";

class AttributeDropdown extends Component {
  static propTypes = {
    obj: PropTypes.object.isRequired,
    attributeConfig: PropTypes.object.isRequired,
    updateAttribute: PropTypes.func.isRequired,
  };

  render() {
    const attributeConfig = this.props.attributeConfig;
    const obj = this.props.obj;
    const attributes = obj.attributes ? obj.attributes : [];
    const value = attributeConfig.multiple ? attributes || [] : attributes[0] || "";
    return (
      <React.Fragment>
        <Header as="h4" content={`Attribute${attributeConfig.multiple ? "s" : ""}`}/>
        <Dropdown
          fluid search selection
          multiple={attributeConfig.multiple}
          placeholder="Choose an attribute"
          options={_.map(attributeConfig.options, (o) => {
            return {key: o.value, value: o.value, text: o.title ? o.title : o.value};
          })}
          value={value}
          onChange={this.props.updateAttribute}
        />
      </React.Fragment>
    );
  }
}

export default AttributeDropdown;
