/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {Container} from "semantic-ui-react";
import WidgetId from "../WidgetId";
import {applyDisplayRule, iterateAllWidgets} from "../widgetUtils";

import "./ContainerWidget.css";

import PropTypes from "prop-types";
import Widget from "../Widget";
import ContainerWidgetEditor from "./ContainerWidgetEditor";
import widgetsMapping from "../WidgetsMapping";
import ModalWidget from "../ModalWidget";
import widgetEditorMapping from "../WidgetsEditorMapping";


class ContainerWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    // Below props are used in 'normal' context - displaying and answering questions
    updateConfig: PropTypes.func,
    /* Update widgets config function. */
    getWidgetConfig: PropTypes.func,
    /* Get widgets config function. */
    toRender: PropTypes.object,
    /* Current node  of the tree of widgets that will be rendered. */
    questionId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    /* Id of a question that the widget corresponds to. */
    answerAuthor: PropTypes.string,
    /* Username of the user who made an answer. */

    // Below props are needed in the drag and drop config view.
    onDrop: PropTypes.func,
    /* Function to handle drop on the widget. */
    popupDisplayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /* Id of the selected widget, used to display popup. */
    skipDisplayRule: PropTypes.bool,
    /* If true, display rule will not be applied. Used to keep things always visible in the edit of a widget. */
    draggable: PropTypes.bool,
    /* If true then children components are draggable */
    onSubmit: PropTypes.func,
    /* Function to handle container submit */
    onSkip: PropTypes.func,
    /* Function to handle question skip */
    editorMode: PropTypes.bool,
    /* If true, widget will be disabled in the edit view */

    // Methods to call isValid and isEmpty from parent register. Used by SubmitWidget.
    isValid: PropTypes.func,
    isEmpty: PropTypes.func,
    // If submit button should show spinner
    processingSubmit: PropTypes.bool,
    isModerator: PropTypes.bool
  };

  static getDefaultConfig = () => {
    return {
      widgetConfig: {structure: []},
    };
  };

  constructor(props) {
    super(props);

    this.renderWidgets = this.renderWidgets.bind(this);
    this.resolveComponent = this.resolveComponent.bind(this);

    if (props.componentId === "rootWidget") {
      this.state = {widgetsToCopy: []};
    }
  }

  isEmpty = () => {
    return false;
  };

  isValid = () => {
    return true;
  };

  /**
   * Find component with componentId and remove it from config
   * @param config - container configuration
   * @param componentId - id of component to cut
   */
  cutWidget = (config, componentId) => {
    const widgetConfig = _.cloneDeep(config);
    let cuttedConfig = null;
    _.forEach(config, (widget, idx) => {
      if (widget.componentId === componentId) {
        cuttedConfig = _.cloneDeep(widget);
        widgetConfig.splice(idx, 1);
        return false;
      }
      if (widget.componentType === "ContainerWidget") {
        const cut = this.cutWidget(widget.widgetConfig.structure, componentId);
        widgetConfig[idx].widgetConfig.structure = cut.widgetConfig;
        cuttedConfig = cut.cuttedConfig;
      }
    });
    return {widgetConfig, cuttedConfig};
  };

  /**
   * Paste cuttedConfig before componentId into config.
   * @param config
   * @param componentId
   * @param cuttedConfig
   */
  pasteWidget = (config, componentId, cuttedConfig) => {
    const widgetConfig = _.cloneDeep(config);
    _.forEach(config, (widget, idx) => {
      if (widget.componentId === componentId) {
        widgetConfig.splice(idx, 0, cuttedConfig);
        return false;
      }

      if (widget.componentType === "ContainerWidget") {
        widgetConfig[idx].widgetConfig.structure = this.pasteWidget(
          widget.widgetConfig.structure, componentId, cuttedConfig);
      }
    });

    return widgetConfig;
  };

  /**
   * Handle drop from movable components.
   * @param e
   * @param widget
   */
  handleDrop = (e, widget) => {
    if (!e.dataTransfer.getData("move")) {
      return;
    }

    const sourceId = e.dataTransfer.getData("id");
    const targetId = widget.componentId;

    let config = _.cloneDeep(this.props.getWidgetConfig());
    if (config) {
      const cut = this.cutWidget(config, sourceId);
      config = cut.widgetConfig;

      config = this.pasteWidget(config, targetId, cut.cuttedConfig);
      this.props.updateConfig(config);
    }
  };

  checkIdUnique = (id) => {
    this.props.checkIdUnique(id);
  };

  resolveComponent(widget, idx) {
    let returnedComponent = null;
    let returnedEditor = null;
    let props = {};
    const widgetConfig = !!this.props.getWidgetConfig ? this.props.getWidgetConfig() : [];

    // Get selected widgets.
    const selectedWidgets = [];
    if (!!this.props.selectedWidgets) {
      iterateAllWidgets(widgetConfig, (w) => {
        if (this.props.selectedWidgets.includes(w.componentId)) {
          selectedWidgets.push(w);
        }
      });
    }
    // Get IDs of selected widgets and their children.
    const idsOfSelected = [];
    iterateAllWidgets(selectedWidgets, (w) => {
      idsOfSelected.push(w.componentId);
    });

    const selected = idsOfSelected.includes(widget.componentId);

    if (widget.componentType === "ContainerWidget") {
      const extraProps = {
        copyWidgets: this.props.copyWidgets,
        deleteWidgets: this.props.deleteWidgets,
        selectedWidgets: this.props.selectedWidgets,
        selected,
      };
      if (this.props.onDrop) {
        extraProps.onDrop = this.props.onDrop;
      }
      props = {
        ...extraProps,
        key: idx,
        editorProps: this.props.editorProps || null,
        componentId: widget.componentId,
        toRender: widget.widgetConfig,
        update: this.props.update,
        widgetVals: this.props.widgetVals,
        popupDisplayId: this.props.popupDisplayId,
        openPopup: this.props.openPopup,
        skipDisplayRule: this.props.skipDisplayRule,
        draggable: this.props.draggable || false,
        getWidgetConfig: this.props.getWidgetConfig,
        widgetConfig: widget,
        updateConfig: this.props.updateConfig,
        questionId: this.props.questionId,
        registerValid: this.props.registerValid,
        registerEmpty: this.props.registerEmpty,
        registerPrepareForSubmit: this.props.registerPrepareForSubmit,
        answerAuthor: this.props.answerAuthor,
        selectWidgets: this.props.selectWidgets,
        isModerator: this.props.isModerator,
      };
      returnedComponent = ContainerWidget;
      returnedEditor = ContainerWidgetEditor;
    } else {
      props = {
        key: idx,
        editorMode: this.props.editorMode,
        componentId: widget.componentId,
        update: this.props.update,
        value: this.props.widgetVals[widget.componentId] ? this.props.widgetVals[widget.componentId] : null,
        widgetVals: this.props.widgetVals,
        customClass: widget.customClass,
        widgetConfig: widget,
        questionId: this.props.questionId,
        registerValid: this.props.registerValid,
        registerEmpty: this.props.registerEmpty,
        registerPrepareForSubmit: this.props.registerPrepareForSubmit,
        answerAuthor: this.props.answerAuthor,
        selectWidgets: this.props.selectWidgets,
        isModerator: this.props.isModerator,
        selected,
      };

      // add custom properties for widgets, not related with widgetConfig
      if (widget.componentType === "SubmitWidget") {
        props = {
          ...props,
          getConfig: this.props.getWidgetConfig,
          onSubmit: this.props.onSubmit,
          onSkip: this.props.onSkip,
          isValid: this.props.isValid,
          isEmpty: this.props.isEmpty,
          prepareForSubmit: this.props.prepareForSubmit,
          showSpinner: this.props.processingSubmit
        };
      }

      if (widget.componentType === "AudioAnswerWidget") {
        props = { ...props, isTabDone: this.props.isTabDone }
      }

      returnedComponent = widgetsMapping[widget.componentType];
      returnedEditor = widgetEditorMapping[widget.componentType];
    }
    return {
      component: returnedComponent,
      editor: returnedEditor,
      props: props,
    };
  }

  renderWidgets(widgetConfig) {
    if (!widgetConfig) {
      return;
    }
    const widgets = _.map(widgetConfig.structure, (widget, idx) => {
      if (widget && widget.displayRule) {
        const rule = widget.displayRule;
        if (!applyDisplayRule(rule, this.props.widgetVals) && !this.props.skipDisplayRule) {
          return null;
        }
      }

      const resolvedComponent = this.resolveComponent(widget, idx);
      const component = resolvedComponent.component;
      const editor = resolvedComponent.editor;
      const props = resolvedComponent.props;

      if (this.props.draggable) {
        return <ModalWidget
          key={widget.componentType + idx}
          component={component}
          componentProps={props}
          editor={editor}
          editorProps={this.props.editorProps}
          idx={idx}
          open={this.props.popupDisplayId === widget.componentId}
          openPopup={this.props.openPopup}
          onDrop={(e) => this.handleDrop(e, widget)}
          onCopy={this.props.copyWidgets}
          onDelete={this.props.deleteWidgets}
          onSelect={this.props.selectWidgets}
          selected={props.selected}
        />;
      } else {
        return React.createElement(component, props);
      }
    });
    return widgets;
  }

  render() {
    const {componentId, widgetVals} = this.props;
    const extraProps = {};
    if (this.props.onDrop) {
      extraProps.onDrop = (e) => {
        this.props.onDrop(componentId, e);
      };
    }

    if (widgetVals[componentId] && widgetVals[componentId].display === false) {
      return "";
    }
    const component = (
      <Container {...extraProps} className={"ContainerWidget Widget"}
        style={{borderStyle: "solid", borderWidth: "1px", width: this.props.smallWidth != undefined? "400px": "", padding: this.props.smallWidth != undefined? "0 1rem": ""}}>
        {this.props.onDrop && <WidgetId left componentId={componentId}/>}
        {this.renderWidgets(this.props.toRender)}
      </Container>
    );

    return component;
  }
}

export default ContainerWidget;
