import React from "react";
import PropTypes from "prop-types";
import {Form} from "semantic-ui-react";

import AdvancedFilterWrapper from "./AdvancedFilterWrapper";
import WidgetValueFilter from "./WidgetValueFilter";
import CompareFilter from "./CompareFilter";
import "./AdvancedFilters.css";

const AdvancedFiltersSet = ({
  question, answer, evaluationQuestion, evaluationAnswer, compare, //options
  filters, updateFilters, possibleFilterValuesUsers,
  structure=[], evaluationStructure=[],
  advancedPopupRef
}) => {
  const baseFilterProps = {filters, updateFilters, advancedPopupRef};
  const widgets = structure.slice(0, -1); //remove submit button
  const evaluationWidgets = evaluationStructure.slice(0, -1); //remove submit button

  return <Form.Field>
    <label>Advanced Filters</label>
    {question &&
      <AdvancedFilterWrapper
        {...baseFilterProps}
        type="question"
        advancedFilter={WidgetValueFilter}
        widgets={widgets}
      />
    }
    {answer &&
      <AdvancedFilterWrapper
        {...baseFilterProps}
        type="answer"
        advancedFilter={WidgetValueFilter}
        widgets={widgets}
      />
    }
    {evaluationQuestion &&
      <AdvancedFilterWrapper
        {...baseFilterProps}
        type="evaluation_question"
        advancedFilter={WidgetValueFilter}
        widgets={evaluationWidgets}
      />
    }
    {evaluationAnswer &&
      <AdvancedFilterWrapper
        {...baseFilterProps}
        type="evaluation_answer"
        advancedFilter={WidgetValueFilter}
        widgets={evaluationWidgets}
      />
    }
    {compare &&
      <AdvancedFilterWrapper
        {...baseFilterProps}
        type="compare"
        advancedFilter={CompareFilter}
        possibleFilterValuesUsers={possibleFilterValuesUsers}
      />
    }
  </Form.Field>;
};

AdvancedFiltersSet.propTypes = {
  question: PropTypes.bool,
  answer: PropTypes.bool,
  structure: PropTypes.array, //required with answer or question prop
  evaluationQuestion: PropTypes.bool,
  evaluationAnswer: PropTypes.bool,
  evaluationStructure: PropTypes.array, //required with evaluationQuestion and evaluationAnswer prop
  compare: PropTypes.bool,
  possibleFilterValuesUsers: PropTypes.array, //required with compare prop
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  advancedPopupRef: PropTypes.object.isRequired
};

export default AdvancedFiltersSet;
