import _ from "lodash";

export const createPoint = (x, y) => ({x, y, key: _.uniqueId()});

export const canSnap = ({x, y}, {x: sx, y: sy}, distance=10) => {
  let snappX = x > sx - distance && x < sx + distance;
  let snappY = y > sy - distance && y < sy + distance;
  return snappX && snappY;
};

export const checkOutOfBounds = (vertex, dimensions) =>
  (vertex.x >= dimensions.width || vertex.x <= 0 ||
  vertex.y >= dimensions.height || vertex.y <= 0);

export const check2LinesIntersection = (A, B, C, D) => {
  let det, gamma, lambda;
  det = (B.x - A.x) * (D.y - C.y) - (D.x - C.x) * (B.y - A.y);
  if (det === 0) {
    return false;
  } else {
    lambda = ((D.y - C.y) * (D.x - A.x) + (C.x - D.x) * (D.y - A.y)) / det;
    gamma = ((A.y - B.y) * (D.x - A.x) + (B.x - A.x) * (D.y - A.y)) / det;
    return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
  }
};

export const checkPointToCloseToLine = (point, [lineA, lineB], requiredDistance=10) => {
  const distanceSquared = (v, w) => Math.pow(v.x - w.x, 2) + Math.pow(v.y - w.y, 2);

  let result = ((point.x - lineA.x) * (lineB.x - lineA.x) +
          (point.y - lineA.y) * (lineB.y - lineA.y)) / distanceSquared(lineA, lineB);
  result = Math.max(0, Math.min(1, result));
  result = distanceSquared(
    point,
    { x: lineA.x + result * (lineB.x - lineA.x), y: lineA.y + result * (lineB.y - lineA.y)}
  );
  return requiredDistance > Math.sqrt(result);
};
