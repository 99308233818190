import {useState, useCallback} from "react";

const useSetCanvasSize = () => {
  const [canvasSize, setCanvasSize] = useState({width: 0, height: 0});
  const handleSetCanvasSize = useCallback((e) =>
    setCanvasSize({
      width: e.target.clientWidth,
      height: e.target.clientHeight
    })
  , []);
  return [canvasSize, handleSetCanvasSize];
};

export default useSetCanvasSize;
