import _ from "lodash";
import React, {Component} from "react";
import {Message} from "semantic-ui-react";
import {connect} from "react-redux";
import store from "../store/store";
import {messagesClear} from "../actions/actions_messages";
import PropTypes from "prop-types";

class Messages extends Component {
  static propTypes = {
    messages: PropTypes.object
  };

  componentDidMount() {
    // clear messages before mount
    store.dispatch(messagesClear());
  }

  renderMessages() {
    const messages = this.props.messages;
    if (_.isObject(messages)) {
      return _.map(messages, function (v, k) {
        return <span key={k}><span>{k}</span>: {v}<br/></span>;
      });
    }
    return String(messages);
  }

  render() {
    const msg = this.renderMessages();
    if (msg.length <=0) {
      return (
        ""
      );
    }

    return (
      <Message
        className="datahive-message"
        warning={msg.length <= 0}
        color={"green"}
        header={""}
        content={this.renderMessages()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {messages: state.messages};
}

export default connect(mapStateToProps)(Messages);
