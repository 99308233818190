import store from "../store/store";
import urls from "./frontend_urls";

export default class Perms {
  constructor() {
    // Permissions / roles
    this.roles = {
      is_logged: "is_logged",
      is_normal_user: "is_normal_user", // Normal user
      is_ele: "is_ele", // ELE user
      is_external: "is_external", // External user
      is_company_coordinator: "is_company_coordinator", // Company coordinator
      is_moderator: "is_moderator", // Task moderator
      is_staff: "is_staff", // Django staff
      is_superuser: "is_superuser", // Django superuser
      has_user_data_access: "has_user_data_access", // User with access to personal data
      is_samsung_employee: "is_samsung_employee", // Internal or Samsung Remote User
    };

    this.userAuth = store.getState().auth; // all auth data
    this.userRoles = this.getUserRoles(this.userAuth);
    this.sensitiveDataUrls = [
      urls.ADD,
      urls.ADD_USERS,
      urls.USERS_LIST,
      urls.ADMIN_TASK_DETAIL,
      urls.ADMIN_TASK_DETAIL_TAB,
      urls.ADMIN_TASK_DETAIL_NEW,
      urls.ADMIN_TASK_DETAIL_NEW_TAB,
    ];
  }

  // get active roles, i.e. keys with a value 'true'
  getUserRoles = () =>
    Object.keys(this.userAuth).filter((key) => this.userAuth[key] === true);

  /**
   * Roles should be in listed in an array, empty array = only for not logged in users
   */
  permissions = () => {
    /* This checks are for full url path */
    return {
      [urls.LOGIN]: [],
      [urls.AUDIO_HUNTER_CONFIRM]: [],
      [urls.LOGIN_WITH_TOKEN]: [],
      [urls.LOGOUT]: [this.roles.is_logged],
      [urls.PASSWORD_RESET]: [],
      [urls.PASSWORD_RESET_CONFIRM]: [],
      [urls.CREATE_ACCOUNT]: [],
      [urls.USERS_LIST]: [
        this.roles.is_staff,
        this.roles.is_company_coordinator,
      ],
      [urls.USER_PROFILE]: [this.roles.is_logged],
      [urls.USER_RATINGS]: [this.roles.is_staff],
      [urls.USER_TARIFFS]: [this.roles.is_superuser],
      [urls.ADD]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.ADD_USERS]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.ADD_USER]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.COMPANIES]: [this.roles.is_superuser],
      [urls.COMPANY_DETAIL]: [this.roles.is_superuser],
      [urls.COMPANY_DETAIL_NEW]: [this.roles.is_superuser],
      [urls.USERS_PERMISSIONS]: [this.roles.is_superuser],
      [urls.DIVISIONS]: [this.roles.is_superuser],
      [urls.DIVISION_DETAIL]: [this.roles.is_superuser],
      [urls.DIVISION_DETAIL_NEW]: [this.roles.is_superuser],
      [urls.MODULES]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.MODULE_DETAIL]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.MODULE_DETAIL_NEW]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.TASKS_LIST]: [this.roles.is_logged],
      [urls.TASK_RATINGS]: [this.roles.is_moderator, this.roles.is_superuser],
      [urls.USER_RATINGS]: [this.roles.is_moderator, this.roles.is_superuser],
      [urls.RATING]: [this.roles.is_moderator, this.roles.is_superuser],
      [urls.NEW_RATING]: [this.roles.is_moderator, this.roles.is_superuser],
      [urls.NEW_RATING_NO_SLUG]: [
        this.roles.is_moderator,
        this.roles.is_superuser,
      ],
      [urls.TASK]: [this.roles.is_logged],
      [urls.TASK_TAB]: [this.roles.is_logged],
      [urls.TASKS_STATISTICS]: [this.roles.is_staff],
      [urls.TASK_STATISTICS]: [this.roles.is_staff],
      [urls.GROUP_TASK_STATISTICS]: [this.roles.is_staff],
      [urls.USERS_STATISTICS]: [this.roles.is_staff],
      [urls.USER_STATISTICS]: [this.roles.is_staff],
      [urls.TASK_USER_STATISTICS]: [this.roles.is_staff],
      [urls.BASIC_TASK_STATISTICS]: [this.roles.is_staff],
      [urls.REPORTS]: [this.roles.is_logged],
      [urls.USER_REPORT]: [this.roles.is_logged],
      [urls.REPORTS_LIST]: [
        this.roles.is_staff,
        this.roles.is_company_coordinator,
      ],
      [urls.REPORTS_DEADLINE]: [this.roles.is_staff],
      [urls.UPEU]: [this.roles.is_staff],
      [urls.WIDGETS_EDIT]: [this.roles.is_staff],
      [urls.WIDGETS_TESTS]: [this.roles.is_superuser],
      [urls.ADMIN_TASK_LIST]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.ADMIN_TASK_DETAIL]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.ADMIN_TASK_DETAIL_TAB]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ADMIN_TASK_DETAIL_NEW]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ADMIN_TASK_DETAIL_NEW_TAB]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ADMIN_QUESTIONS_DETAIL]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.QUESTION_ADMIN_LIST]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ADMIN_QUESTIONS_BY_TASK_LIST]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ANSWER_ADMIN_LIST]: [this.roles.is_superuser, this.roles.is_staff],
      [urls.ADMIN_ANSWER_DETAIL]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.ADMIN_ANSWERS_BY_TASK_LIST]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.PRICE_TARIFFS]: [this.roles.is_superuser],
      [urls.AUDIO_HUNTER_MIGRATE]: [this.roles.is_superuser],
      [urls.AUDIO_HUNTER_COMPANY_MIGRATE]: [this.roles.is_superuser],
      [urls.ADMIN_GENERATE_REPORTS]: [this.roles.is_superuser],
      [urls.DOCUMENTATION_WIDGETS]: [
        this.roles.is_superuser,
        this.roles.is_staff,
      ],
      [urls.SUPPORT]: [this.roles.is_samsung_employee],
      [urls.SUPPORT_TICKET_NEW]: [this.roles.is_samsung_employee],
      [urls.SUPPORT_TICKET_DETAIL]: [this.roles.is_samsung_employee],
      [urls.ADMIN_SUPPORT]: [this.roles.is_superuser],
    };
  };

  reload = () => {
    this.userAuth = store.getState().auth;
    this.userRoles = this.getUserRoles(this.userAuth);
  };

  /**
   * Checks if overlap exists between user roles and route perms, if yes returns true.
   * Superuser has access everywhere
   * @param {string} url
   * @returns {bool}
   */
  haveAccessTo = (url) => {
    this.reload();
    if (this.userAuth.is_superuser) {
      return true;
    }

    const urlsWithPerms = this.permissions();
    const routePerms = urlsWithPerms[url];

    if (!this.userAuth.is_logged && !routePerms.length) {
      return true;
    }

    if (
      this.sensitiveDataUrls.includes(url) &&
      !this.userRoles.includes(this.roles.has_user_data_access)
    ) {
      return false;
    }
    return routePerms
      .map((perm) => this.userRoles.some((role) => role === perm))
      .includes(true);
  };
}
