import React, {Component} from "react";
import "./flexbox.css";
import PropTypes from "prop-types";


export class Flexbox extends Component {
  static propTypes = {
    children: PropTypes.array
  };

  render() {
    return (
      <div className="Flexbox">
        {this.props.children}
      </div>
    );
  }
}

export class FlexSpacer extends Component {
  render() {
    return (
      <div className="FlexSpacer">
      </div>
    );
  }
}
