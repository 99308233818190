import React, {Component} from "react";
import PropTypes from "prop-types";
import {Input} from "semantic-ui-react";
import "./InputOptions.css";

export class InputOptions extends Component {
  /*
  This component displays stars based on the number it gets in the props.
   */
  static propTypes = {
    value: PropTypes.string,
    /* Values passed to the input. */
    label: PropTypes.object,
    /* Label object passed to the input */
    onChange: PropTypes.func.isRequired,
    /* On change handler passed to the input*/
  };

  render() {
    return (
      <Input
        width={12}
        size="mini"
        labelPosition="right"
        placeholder="Option Name"
        value={this.props.value || ""}
        onChange={this.props.onChange}
        label={this.props.label}
      />
    );
  }
}

