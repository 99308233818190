import React from "react";
import {Grid, Header, Input, TextArea} from "semantic-ui-react";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./PipedTextAreaWidget.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class PipedTextAreaWidgetEditor extends WidgetEditor {
  static header = "Segmented Textarea";

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    return (

      <Grid className={"PipedTextAreaEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header as="h4">Default value field name</Header>
            <TextArea
              autoHeight
              rows={1}
              placeholder="Value"
              style={{width: "100%"}}
              value={config.defaultValue}
              onChange={(e, data) => this.updateConfig("defaultValue", data.value)}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            {/*<Header as='h4'>Align</Header>*/}
            {/*<AlignSelector*/}
            {/*justify*/}
            {/*size="mini"*/}
            {/*value={config.align}*/}
            {/*onChange={(e,data) => this.updateConfig('align', data)}*/}
            {/*/>*/}
            <Header as="h4">Max number of segments</Header>
            <Input
              type="number"
              size="mini"
              min="1"
              max="10"
              value={config.segmentsNumber}
              onChange={(e, data) => this.updateConfig("segmentsNumber", parseInt(data.value, 10))}
            />
            <Header as="h4">Max number of words in segment</Header>
            <Input
              type="number"
              size="mini"
              min="1"
              max="50"
              value={config.wordsInSegment}
              onChange={(e, data) => this.updateConfig("wordsInSegment", parseInt(data.value, 10))}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default PipedTextAreaWidgetEditor;
