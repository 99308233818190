import React, {Component} from "react";
import {Line} from "react-chartjs-2";
import {color_table, getDurationChartData} from "../../helpers/utils";
import _ from "lodash";
import PropTypes from "prop-types";

export default class TaskStatisticsPercentiles extends Component {
  static propTypes = {
    taskStatisticsPercentiles: PropTypes.shape({
      task_percentiles: PropTypes.array,
      avg_percentiles: PropTypes.array,
      min_percentiles: PropTypes.array,
      max_percentiles: PropTypes.array
    })
  };

  render() {
    var labels = _.range(1, 101, 1);
    // percentiles mean values chart
    var datasets = [
      {
        label: "Task mean",
        color: color_table[1],
        data: this.props.taskStatisticsPercentiles.task_percentiles
      },
      {
        label: "Users mean",
        color: color_table[2],
        data: this.props.taskStatisticsPercentiles.avg_percentiles
      },
      {
        label: "Users min",
        color: color_table[3],
        data: this.props.taskStatisticsPercentiles.min_percentiles
      },
      {
        label: "Users max",
        color: color_table[3],
        data: this.props.taskStatisticsPercentiles.max_percentiles
      },
    ];
    const chart_data = getDurationChartData(datasets, "Percentile", "Time");
    return (<div>
      {!!this.props.taskStatisticsPercentiles &&
      <Line data={{labels: labels, datasets: chart_data[0]}} options={chart_data[1]}/>}
    </div>);
  }
}
