import _ from "lodash";
import React, { Component } from "react";
import { Form, Header, Icon, Image, Button } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./NewTicketForm.css";
import { toastr } from "react-redux-toastr";

export class NewTicketForm extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    attachments: PropTypes.array.isRequired,
    addAttachment: PropTypes.func.isRequired,
    removeAttachment: PropTypes.func.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.attachments.forEach((attachment) =>
      URL.revokeObjectURL(attachment.preview)
    );
  }

  ALLOWED_ATTACHMENT_EXTENSIONS = [
    "jpeg",
    "jpg",
    "pdf",
    "png",
    "mp4",
    "docx",
    "xlsx",
    "xls",
    "txt",
  ];
  ALLOWED_MAX_ATTACHMENTS = 5;
  ALLOWED_ATTACHMENT_SIZE = 100 * 1024 * 1024;

  validationTasks = {
    isLimitAllowed: {
      isValid: () => {
        return this.ALLOWED_MAX_ATTACHMENTS > this.props.attachments.length;
      },
      errorMessage: `Exceeded the maximum limit of ${this.ALLOWED_MAX_ATTACHMENTS} attachments.`,
    },
    isFileExtensionAllowed: {
      isValid: (file) => {
        const extension = file.name.toLowerCase().split(".").pop();
        return this.ALLOWED_ATTACHMENT_EXTENSIONS.includes(extension);
      },
      errorMessage: `Invalid file format. Allowed formats: ${this.ALLOWED_ATTACHMENT_EXTENSIONS.join(
        " "
      )}.`,
    },
    isFileSizeAllowed: {
      isValid: (file) => {
        return this.ALLOWED_ATTACHMENT_SIZE > file.size;
      },
      errorMessage: `File size must be no more than ${
        this.ALLOWED_ATTACHMENT_SIZE / 1024 ** 2
      } MB.`,
    },
    isFileUnique: {
      isValid: (file) => {
        return !this.props.attachments.some((f) => f.name === file.name);
      },
      errorMessage: `Already is added for upload.`,
    },
  };

  fileValidator = (file) => {
    const validator = {
      isValidated: false,
      errors: [],
    };

    const validationTasks = this.validationTasks;
    const validationTaskStatuses = [];

    for (const validationTask in validationTasks) {
      const task = validationTasks[validationTask];
      const isTaskValid = task.isValid(file);

      validationTaskStatuses.push(isTaskValid);

      if (!isTaskValid) {
        validator.errors.push(task.errorMessage);
      }
    }

    validator.isValidated = validationTaskStatuses.every(Boolean);

    return validator;
  };

  onDrop = (acceptedFiles) => {
    if (acceptedFiles?.length) {
      acceptedFiles.forEach((file) => {
        const { isValidated, errors } = this.fileValidator(file);

        if (!isValidated && errors?.length) {
          toastr.error(`${file.name} - ${errors}`);
        }

        if (isValidated) {
          file.preview = URL.createObjectURL(file);
          this.props.addAttachment(file);
        }
      });
    }
  };

  renderAttachmentPreview = (attachment) => {
    if (attachment.type && attachment.type.startsWith("image/")) {
      return (
        <Image
          src={attachment.preview}
          alt={attachment.name}
          onLoad={() => {
            URL.revokeObjectURL(attachment.preview);
          }}
        />
      );
    } else {
      return <Icon name="file" size="big" />;
    }
  };

  render() {
    return (
      <Form className="NewTicketForm">
        <Header size="medium" textAlign="center">
          How can we help you?
        </Header>
        <Form.Input
          required
          label="Title"
          placeholder="Title"
          onChange={(e, data) => this.props.onChangeForm({ title: data.value })}
        />
        <Form.Group widths="equal">
          <Form.Dropdown
            fluid
            required
            search
            selection
            placeholder="Select Category"
            options={this.props.categories}
            label="Category"
            onChange={(e, data) =>
              this.props.onChangeForm({
                category: _.find(
                  this.props.categories,
                  (o) => o.key === data.value
                ).key,
              })
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            fluid
            required
            search
            selection
            placeholder="Select Priority"
            options={this.props.priorities}
            label="Priority"
            onChange={(e, data) =>
              this.props.onChangeForm({
                priority: _.find(
                  this.props.priorities,
                  (o) => o.key === data.value
                ).key,
              })
            }
          />
        </Form.Group>
        <Form.TextArea
          required
          label="Description"
          placeholder="Briefly explain the reason for your ticket."
          onChange={(e, data) =>
            this.props.onChangeForm({ description: data.value })
          }
        ></Form.TextArea>
        <Form.Field>
          <label>Attachments</label>
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <Icon name="cloud upload" size="big" />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag and drop some files here, or click to select files</p>
                )}
                <p className="dropzone-counter">
                  {this.props.attachments.length}/{this.ALLOWED_MAX_ATTACHMENTS}
                </p>
              </div>
            )}
          </Dropzone>
          <ul className="dropzone-files">
            {this.props.attachments.map((attachment) => (
              <li key={attachment.name} className="dropzone-file">
                <div className="dropzone-file-preview">
                  {this.renderAttachmentPreview(attachment)}
                  <p>{attachment.name}</p>
                </div>
                <Button
                  type="button"
                  icon
                  onClick={() => this.props.removeAttachment(attachment.name)}
                >
                  <Icon name="minus square" size="big" color="red" />
                </Button>
              </li>
            ))}
          </ul>
        </Form.Field>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps)(NewTicketForm));
