import _ from "lodash";

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button} from "semantic-ui-react";
import "./AlignSelector.css";

export class AlignSelector extends Component {
  /*
  This component displays stars based on the number it gets in the props.
   */
  static propTypes = {
    /* On change handler passed to the input*/
    onChange: PropTypes.func.isRequired,
    /* Value passed to selector */
    value: PropTypes.string,
    /* If justify available */
    justify: PropTypes.bool,
    size: PropTypes.string
  };

  static defaultProps = {
    justify: true
  };

  constructor(props) {
    super(props);

    this.opts = {
      "align-left": "align left",
      "align-center": "align center",
      "align-right": "align right",
    };
    if (this.props.justify) {
      this.opts.justify = "align justify";
    }
  }

  render() {
    const size = this.props.size ? this.props.size : "medium";
    const buttons = _.map(this.opts, (icon, value) => {
      return (
        <Button
          key={value}
          icon={icon}
          size={size}
          active={_.includes(this.props.value, value)}
          onClick={(e) => this.props.onChange(e, value)}
        />
      );
    });

    return (
      <div className="AlignSelector">
        <Button.Group>
          {buttons}
        </Button.Group>
      </div>
    );
  }
}

