import _ from "lodash";
import React from "react";
import {Button, Grid, Header, Popup, Card, Icon, Divider} from "semantic-ui-react";
import {connect} from "react-redux";
import moment from "moment";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import config from "../../config/config";
import ajax from "../../helpers/ajax";
import Review from "./Review";
import Registrator from "./Registrator";
import {ContainerWidget} from "../../components/Widgets";

import "./QuestionsSet.css";


class EvaluationDoneContainer extends Registrator {
  static propTypes = {
    ...Registrator.propTypes,
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      is_staff: PropTypes.bool.isRequired,
      has_user_data_access: PropTypes.bool.isRequired,
      is_superuser: PropTypes.bool.isRequired
    }),
    isModerator: PropTypes.bool.isRequired,
    question: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    config_evaluation: PropTypes.object.isRequired,
    onCommentSubmit: PropTypes.func,
    onCommentRemoval: PropTypes.func,
    onCommentUpdate: PropTypes.func,
    onReviewAccept: PropTypes.func,
    onSkip: PropTypes.func,
    idx: PropTypes.number
  };

  state = {
    questionId: -1,
    copyPopupContent: "Right click to copy user.",
    editingComment: false,
    reviewLoading: false
  }

  componentDidMount() {
    // get widgetVals from question and active answer
    let widgetVals = this.props.question.input;
    const activeEvaluationIdx = 0;
    widgetVals = this.mergeQuestionWithAnswer(this.props.question);

    this.widgetVals = _.cloneDeep(widgetVals);
    this.evaluationVals = this.props.question.evaluations[activeEvaluationIdx]
      ? _.cloneDeep(this.props.question.evaluations[activeEvaluationIdx].content)
      : _.cloneDeep(this.props.question.evaluation_input);

    this.setState({
      questionId: this.props.question.id,
      widgetVals,
      activeEvaluationIdx,
      evaluationVals: this.evaluationVals,
    });
  }

  mergeQuestionWithAnswer = (question) => {
    const widgetVals = _.cloneDeep(question.input);
    // load values from answer

    _.each(question.answer_content, (value, key) => {
      // for object do not remove existed keys
      if (typeof value === "object" && !Array.isArray(value)) {
        _.each(value, (v, k) => {
          // if key doesn't exist in question -> create it!
          if (!Object.keys(widgetVals).includes(key)) {
            widgetVals[key] = {};
          }
          widgetVals[key][k] = v;
        });
      } else {
        widgetVals[key] = value;
      }
    });

    return widgetVals;
  };

  async onSubmit(isCorrection) {
    const promises = [];
    _.each(this.prepareForSubmitRegister, (fun) => promises.push(fun())); //call prepare for submit on every registered widget.

    await Promise.all(promises)
      .then(() => {
        // const widgetVals = this.splitQuestionFromAnswer();
        const activeEvaluation = this.props.question.evaluations[this.state.activeEvaluationIdx];

        const evaluationId = isCorrection && activeEvaluation?.id; //id correct answer without creating new one

        const callback = () => { //callback to change active answer when staff submits answer in done tab
          if (!isCorrection && activeEvaluation?.user && this.props.user.username !== activeEvaluation.user) {
            this.onActiveEvaluationChange(this.state.activeEvaluation + 1);
          }
        };
        this.props.onSubmit(this.props.idx, this.state.evaluationVals, evaluationId, callback);
      });
  }

  onActiveEvaluationChange = (idx) => {
    // get widgetVals from question and active answer
    const widgetVals = this.props.question.input;

    this.widgetVals = _.cloneDeep(widgetVals);
    this.evaluationVals = this.props.question.evaluations[idx].content;
    this.setState({evaluationVals: this.evaluationVals, activeEvaluationIdx: idx});
  };

  onUpdate = (widgetVal, componentId) => {
    this.evaluationVals[componentId] = _.cloneDeep(widgetVal);
    this.setState({evaluationVals: this.evaluationVals});
  };

  onReviewTextChange = (reviewText) => {
    this.setState({currentComment: reviewText});
  };

  copyToClipboard = (e, username) => {
    e.preventDefault();
    navigator.clipboard.writeText(username);
    this.setState({copyPopupContent: "User copied to clipboard!"});
  };

  handleClose = () => {
    this.setState({copyPopupContent: "Right click to copy user."});
  };

  handleCommentSubmitSuccess = () => {
    this.setState({currentComment: "", reviewLoading: false});
  };

  onEditCommentClick = (commentId, reviewText) => {
    this.setState({currentComment: reviewText, modifiedCommentId: commentId});
  };

  onReviewAccept = (questionIdx, evaluationIdx, comment = null, successCallback = () => null) => {
    const data = comment ? {content: comment} : {};
    const evaluation = this.state.questions[questionIdx].evaluations[evaluationIdx];

    ajax.post([config.TASK_ACCEPT_EVALUATION_REVIEW, evaluation.id], {data})
      .then((response) => {
        toastr.success(`Discussion ${evaluation.review.is_accepted ? "unresolved" : "resolved"}!`);
        const questions = this.state.questions;
        questions[questionIdx].answers[0].evaluations[evaluationIdx].review = response.data;
        this.setState({questions});
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };


  render() {
    // answer === -1 -> we are waiting for new answer from parent
    if (this.state.activeEvaluationIdx === -1) {
      const idx = _.findIndex(this.props.question.evaluations, {user: this.props.user.username});
      if (idx !== -1) {
        this.setState({activeEvaluationIdx: idx});
      }

      return null;
    }

    // we are waiting for new question
    if (this.props.question.id !== this.state.questionId) {
      return null;
    }

    const config = this.props.config;
    const config_evaluation = this.props.config_evaluation;
    const question = this.props.question;

    const activeEvaluationIdx = this.state.activeEvaluationIdx;

    let activeEvaluationLastModification = "";

    const evaluation = question.evaluations[activeEvaluationIdx];
    // done tab
    if (question.evaluations.length > 0) {
      let timestamp;
      if (evaluation.fix_timestamp) {
        timestamp = moment(evaluation.fix_timestamp).format("YYYY-MM-DD HH:mm:ss");
        activeEvaluationLastModification = `Last modified on ${timestamp} by ${evaluation.fix_author || "Moderator"}`;
      } else if (evaluation.timestamp) {
        timestamp = moment(evaluation.timestamp).format("YYYY-MM-DD HH:mm:ss");
        activeEvaluationLastModification = `Last modified on ${timestamp}`;
      }
    }

    const lastModifiedLabel = activeEvaluationLastModification ?
      <div className="ui label active-answer-timestamp">
        {activeEvaluationLastModification}
      </div> : null;

    const evaluations = _.map(question.evaluations, (evaluation, evaluationIdx) => {
      const color = activeEvaluationIdx === evaluationIdx ? "green" : "vk";
      return (
        <Popup
          hoverable
          key={evaluationIdx}
          content={<>
            {this.state.copyPopupContent}
            {this.props.user.has_user_data_access &&
              <p className="copyHashCode" onClick={(e) => this.copyToClipboard(e, evaluation.username_hash)}>
                Or click here to copy user hash <Icon name="slack hash"/>
              </p>
            }
          </>}
          onClose={this.handleClose}
          trigger={
            <Button
              key={question.id.toString() + evaluationIdx}
              size="mini"
              color={color}
              onClick={() => this.onActiveEvaluationChange(evaluationIdx)}
              onContextMenu={(e) => this.copyToClipboard(e, evaluation.user)}>
              {evaluation.user}
            </Button>
          }
        />
      );
    });
    const customClass = question.isAnswered ? "question evaluation answered" : "question evaluation";
    let user = _.cloneDeep(this.props.user);
    user.isStaff = user.is_staff;
    let review = evaluation ? <Review
      canStartDiscussion={true}
      canResolveDiscussion={this.props.isModerator || user.is_superuser}
      user={{...user, isModerator: this.props.isModerator}}
      review={evaluation.review}
      currentComment={this.state.currentComment}
      onResolve={() => this.props.onReviewAccept(
        this.props.idx,
        activeEvaluationIdx,
        null,
        this.handleCommentSubmitSuccess
      )}
      onCommentAndResolve={
        () => {
          this.setState({reviewLoading: true});
          this.props.onReviewAccept(
            this.props.idx,
            activeEvaluationIdx,
            this.state.currentComment,
            this.handleCommentSubmitSuccess
          );
        }
      }
      onCommentSubmit={() => {
        this.setState({reviewLoading: true});
        this.props.onCommentSubmit(
          this.props.idx,
          activeEvaluationIdx,
          this.state.currentComment,
          false,
          null,
          this.handleCommentSubmitSuccess
        );
      }}
      onEditCommentClick={this.onEditCommentClick}
      onCommentRemoval={(commentId) => {
        this.setState({reviewLoading: true});
        this.props.onCommentRemoval(
          commentId,
          this.props.idx,
          activeEvaluationIdx,
          () => this.setState({reviewLoading: false})
        );
      }}
      onCommentUpdate={() => {
        this.props.onCommentUpdate(
          this.state.modifiedCommentId,
          this.state.currentComment,
          this.props.idx, activeEvaluationIdx,
          () => {
            this.setState({
              reviewLoading: false,
              currentComment: "",
              modifiedCommentId: null,
              editingComment: false
            });
          });
      }}
      onDiscussionInit={() => this.props.onCommentSubmit(
        this.props.idx,
        activeEvaluationIdx,
        this.state.currentComment, true, null,
        this.handleCommentSubmitSuccess)
      }
      onCommentChange={this.onReviewTextChange}
      loading={this.state.reviewLoading}
      editingComment={this.state.editingComment}
      setEditingComment={(editingComment) => this.setState({editingComment})}
    /> : "";

    return (
      <div className="Question" key={"question" + question.id.toString()}>
        <div className="wrapper">
          <div className={customClass}>
            <div>
              <Divider horizontal>
                <Header as="h4">
                  Question
                </Header>
              </Divider>
              <div className="eval-question">
                <Card className="card-question" fluid>
                  <Grid className="header" stackable columns={2}>
                    {this.props.question.user &&
                    <Popup
                      hoverable
                      content={<>
                        {this.state.copyPopupContent}
                        {this.props.user.has_user_data_access &&
                        <p className="copyHashCode" onClick={(e) => this.copyToClipboard(e, question.username_hash)}>
                          Or click here to copy user hash <Icon name="slack hash"/>
                        </p>
                        }
                      </>}
                      onClose={this.handleClose}
                      trigger={
                        <Button
                          key={question.id.toString()}
                          size="mini"
                          color={"green"}
                          onContextMenu={(e) => this.copyToClipboard(e, question.user)}>
                          {question.user}
                        </Button>
                      }
                    />}
                    <Grid.Column className="ui label question-id">
                      Question id: {question.question_id}
                    </Grid.Column>
                  </Grid>
                  <ContainerWidget
                    key={"answer" + this.state.activeAnswer}
                    widgetVals={this.state.widgetVals}
                    update={() => null}
                    toRender={config}
                    getWidgetConfig={() => config}
                    componentId={"rootWidget"}
                    onSubmit={() => null}
                    onSkip={() => null}
                    questionId={question.id}
                    registerValid={this.registerValid}
                    registerEmpty={this.registerEmpty}
                    registerPrepareForSubmit={this.registerPrepareForSubmit}
                    isValid={this.isValid}
                    isEmpty={this.isEmpty}
                    prepareForSubmit={this.prepareForSubmit}
                  />
                </Card>
              </div>
            </div>
            <Divider horizontal>
              <Header as="h4">
                Evaluation
              </Header>
            </Divider>
            <Grid className="eval-answers-label">
              <Grid.Row columns={2}>
                <Grid.Column floated="left" className="answers">
                  {evaluations}
                </Grid.Column>
                <Grid.Column floated="right">
                  {lastModifiedLabel}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ContainerWidget
              key={"evaluation" + this.state.activeAnswer}
              widgetVals={this.state.evaluationVals}
              update={(widgetVal, componentId) => this.onUpdate(widgetVal, componentId)}
              toRender={config_evaluation}
              getWidgetConfig={() => config_evaluation}
              componentId={"rootWidget"}
              onSubmit={this.onSubmit}
              onSkip={this.props.onSkip ? this.onSkip : null}
              questionId={question.id}
              answerAuthor={_.get(question, `evaluations[${activeEvaluationIdx}].user`)}
              registerValid={this.registerValid}
              registerEmpty={this.registerEmpty}
              registerPrepareForSubmit={this.registerPrepareForSubmit}
              isValid={this.isValid}
              isEmpty={this.isEmpty}
              prepareForSubmit={this.prepareForSubmit}
              isModerator={this.props.isModerator}
            />
            {review}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(mapStateToProps)(EvaluationDoneContainer);
