import _ from "lodash";

import React, {Component} from "react";
import {Button, Grid, Icon, Input, Label, Popup} from "semantic-ui-react";

import config from "../../config/config";
import {TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED, TASK_MONTH_REPORT_STATUS_PRE_ACCEPT} from "../../constraints/Ele";
import {ReportHistoryPopup, ReportAcceptedPopup} from "./ReportHistoryPopup";
import {string_to_slug} from "../../helpers/utils";
import {getHoursAcceptable, getMaxHours, getMinHours, GROUPED_BY, roundHours} from "./utils";
import PropTypes from "prop-types";

export default class TaskReportRow extends Component {
  static propTypes = {
    taskReport: PropTypes.shape({
      hours: PropTypes.number,
      id: PropTypes.number,
      title: PropTypes.string,
      language: PropTypes.string,
      activity: PropTypes.object,
      description: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
      answers: PropTypes.number,
      enable_evaluations: PropTypes.bool,
      user: PropTypes.shape({
        username: PropTypes.string,
        company_name: PropTypes.string
      })
    }).isRequired,
    groupedBy: PropTypes.string,
    onHoursChange: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      is_superuser: PropTypes.bool,
      is_coordinator: PropTypes.bool,
      is_staff: PropTypes.bool
    }),
    onAccept: PropTypes.func.isRequired,
    report: PropTypes.object,
    idx: PropTypes.number,
    allTime: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      hours: props.taskReport.hours,
      editable: false,
    };
  }

  onHoursChange = (event, data) => {
    let hours = parseFloat(data.value);
    hours = _.isNaN(hours) ? this.state.hours : hours;
    hours = roundHours(hours);
    this.setState({hours: hours});
  };

  onSaveClick = () => {
    this.setState({editable: false}, () => {
      this.props.onHoursChange(this.props.taskReport, this.state.hours);
    });
  };

  renderButton = (status, status_value) => {
    const coordinator_or_super = this.props.auth.is_superuser || this.props.auth.is_coordinator;
    let className, color, value, icon;
    if (status) {
      className = "datahive-button";
      color = "green";
      icon = "checkmark box";
      value = coordinator_or_super ? "Accept" : "Pre-Accept";
    } else {
      className = "datahive-button";
      color = "red";
      value = "Reject";
      icon = "window close outline";
    }

    let onClick = null;
    switch (this.props.groupedBy) {
    case GROUPED_BY.USER:
      onClick = () => this.props.onAccept(this.props.report, status, this.props.idx);
      break;
    default:
      onClick = () => this.props.onAccept(this.props.taskReport, status);
      break;
    }

    return (
      <Button
        color={color}
        size={"mini"}
        className={`accept-button ${className}`}
        disabled={!this.props.auth.is_staff && status_value === "ACCEPTED"}
        onClick={onClick}
      >
        <Icon name={icon}/>
        {value}
      </Button>
    );
  };

  getEditButton = () => {
    const taskReport = this.props.taskReport;
    if (this.state.editable) {
      return (
        <Button
          size="mini"
          disabled={!this.props.auth.is_staff && taskReport.status === "ACCEPTED"}
          className={"edit datahive-button"}
          color="yellow"
          onClick={this.onSaveClick}
          content={"Save"}
        />
      );
    }
    return (
      <Button
        size="mini"
        disabled={!this.props.auth.is_staff && taskReport.status === "ACCEPTED"}
        className={"edit datahive-button"}
        color="black"
        onClick={() => this.setState({editable: true})}
        content={"Edit"}
      />
    );
  };

  getHours = () => {
    const {taskReport} = this.props;
    const hours_text =
      this.props.auth.is_staff
        ? <p>
          <span className={getHoursAcceptable(taskReport) ? "hours acceptable" : "hours nonacceptable"}>
            {taskReport.hours.toFixed(2)}
          </span>
          <span className="calculated-hours">
            ({Math.round(getMinHours(taskReport) * 100) / 100} - {Math.round(getMaxHours(taskReport) * 100) / 100})
          </span>
        </p>
        : <p>
          <span className="hours">
            {taskReport.hours.toFixed(2)}
          </span>
        </p>;
    return (this.state.editable) ? (
      <Input
        type="number"
        className={"hours-input"}
        key={this.props.idx || taskReport.id}
        value={this.state.hours.toFixed(2)}
        onChange={this.onHoursChange}
        onBlur={this.onEditClick}
        size="tiny"
        step={0.25}
      />) : hours_text;
  };

  mapActivity = (activity) => {
    return <>
      <div className="activityItem">
        {"Answers / Del:"}
        <Label basic color="grey" size="small">
          {activity["answers"] + " / " + activity["deleted_answers"]}
        </Label>
      </div>
      {!!activity["reviews"] && <div className="activityItem">
        {"Reviews:"}
        <Label basic color="grey" size="small">
          {activity["reviews"]}
        </Label>
      </div>}
      {(!!activity["evaluation_answers"] || !!activity["deleted_evaluation_answers"]) &&
      <div className="activityItem">
        {"Evals / Del:"}
        <Label basic color="grey" size="small">
          {activity["evaluation_answers"] + " / " + activity["deleted_evaluation_answers"]}
        </Label>
      </div>}
    </>;
  };

  renderRowGroupedByUser = (taskReport, status, tempo) => {
    const {title, language, activity} = taskReport;
    return (
      <Grid.Row className={string_to_slug(taskReport.type)} verticalAlign="middle">
        <Grid.Column width="3">
          {title || ""}
          {taskReport.enable_evaluations ? <Label className="evalLabel" circular color="grey" size="mini">eval</Label> : undefined}
        </Grid.Column>
        <Grid.Column>{language || ""}</Grid.Column>
        <Grid.Column width="3">{<div>{this.mapActivity(activity)}</div>}</Grid.Column>
        <Grid.Column>
          {!this.state.editable ?
            <ReportHistoryPopup
              report={this.props.report}
              config={config.LINGUISTS_TASK_REPORT_HISTORY}
              hours={this.getHours()}
              reportId={taskReport.id}
            /> : this.getHours()}
        </Grid.Column>
        <Grid.Column width="1">{tempo}</Grid.Column>
        <Grid.Column>
          <ReportAcceptedPopup
            report={taskReport}
            config={config.LINGUISTS_TASK_REPORT_HISTORY}
          />
        </Grid.Column>
        <Grid.Column width="3">
          {this.renderButton(status, taskReport.status)}
          {this.getEditButton()}
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderUserNativity = (nativityStatus) => {
    switch (nativityStatus) {
      case "NATIVE":
        return <Icon name="flag" className="native-icon"/>
      case "NON-NATIVE":
        return <Icon name="flag outline" className="non-native-icon"/>
      default:
        return <span>N/A</span>
    }
  }

  renderRowGroupedByTask = (taskReport, status, tempo) => {
    return (
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={2}><a href={`/user/profile/${taskReport.user.username}`}>{taskReport.user.username}</a></Grid.Column>
        <Grid.Column width={2}>{taskReport.user.company?.name}</Grid.Column>
        <Grid.Column width={1}>{this.renderUserNativity(taskReport.user_nativity)}</Grid.Column>
        {this.props.allTime && <Grid.Column width={2}>{taskReport.deadline_name}</Grid.Column>}
        <Grid.Column width={3}>{this.mapActivity(taskReport.activity)}</Grid.Column>
        <Grid.Column width={2}>
          {!this.state.editable ?
            <ReportHistoryPopup
              report={taskReport}
              config={config.LINGUISTS_MONTH_REPORT_HISTORY}
              reportId={taskReport.month_report}
              hours={this.getHours()}
            /> : this.getHours()}
          {taskReport.description && <Popup
            flowing
            position={"top center"}
            content={<>
              <div>Description:</div>
              <div>{taskReport.description}</div>
            </>}
            trigger={<Icon name={"info circle"}/>}
          />}
        </Grid.Column>
        <Grid.Column width={1}>{tempo}</Grid.Column>
        <Grid.Column width={2}>
          <ReportAcceptedPopup
            report={taskReport}
            config={config.LINGUISTS_TASK_REPORT_HISTORY}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          {this.renderButton(status, taskReport.status)}
          {this.getEditButton()}
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const {taskReport, auth: {is_staff}} = this.props;
    const status = is_staff ?
      _.includes([TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED, TASK_MONTH_REPORT_STATUS_PRE_ACCEPT], taskReport.status)
      : (taskReport.status === TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED);
    const tempo = taskReport.hours && taskReport.activity.answers ?
      (taskReport.activity.answers / taskReport.hours).toFixed(2) : "-";

    let row;
    switch (this.props.groupedBy) {
    case GROUPED_BY.USER:
      row = this.renderRowGroupedByUser(taskReport, status, tempo);
      break;
    default:
      row = this.renderRowGroupedByTask(taskReport, status, tempo);
      break;
    }

    return row;
  }
}
