import {combineReducers} from "redux";

import auth from "./reducers_auth";
import messages from "./reducers_messages";
import errors from "./reducers_errors";
import tasks from "./reducers_tasks";
import topMenu from "./reducers_top_menu";
import notifications from "./reducers_notifications";
import {reducer as toastrReducer} from "react-redux-toastr";

const rootReducer = combineReducers({
  auth,
  messages,
  errors,
  tasks,
  topMenu,
  notifications,
  toastr: toastrReducer,
});

export default rootReducer;
