import _ from "lodash";
import jsonLogic from "json-logic-js";

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */


export const OPERATIONS = {
  EQUALS: "===",
  NOT_EQUALS: "!==",
};

export function updateParent(e, val, field = "value") {
  // This is a convenience function for updating the widgetVals of the widget container. Has to be bound to a React
  // component.
  const widgetVals = _.cloneDeep(this.props.widgetVals);
  const widgetVal = widgetVals[this.props.componentId];
  if (!widgetVal) {
    if (!field) {
      widgetVals[this.props.componentId] = val;
    } else {
      widgetVals[this.props.componentId] = {[field]: val};
    }
  } else {
    if (!field) {
      widgetVals[this.props.componentId] = val;
    } else {
      widgetVal[field] = val;
    }
  }
  if (this.props.update) {
    this.props.update(widgetVals);
  } else {
    // console.warn('Update not passed to widget!')
  }
}

export function applyDisplayRule(rule, vals) {
  try {
    if (rule.rules.op === "intersects") {
      let valuesArray = vals[rule.data.targetId] ? vals[rule.data.targetId] : [];
      const rulesArray = _.map(rule.rules.val, (val) => {
        return val.toLowerCase().split(" ").join("_");
      });
      if (!Array.isArray(valuesArray)) {
        valuesArray = [valuesArray];
      }
      return _.intersection(valuesArray, rulesArray).length > 0;
    } else {
      let value = vals[rule.data.targetId] ? vals[rule.data.targetId] : [];
      if (Array.isArray(value)) {
        if (value.length === 1) {
          value = value[0];
        } else if (value.length === 0) {
          value = "";
        } else {
          return false;
        }
      }
      return jsonLogic.apply(
        {[rule.rules.op]: [{var: "field"}, rule.rules.val.toLowerCase().split(" ").join("_")]},
        {field: value}
      );
    }
  } catch (err) {
    // console.warn(err);
    return true;
  }
}

export function getAllIdsToRemove(widgets, vals) {
  // returns all ids where the components or parents display rule returns false in the tree of widgets
  return _.reduce(widgets, (ids, w) => {
    if (w.displayRule) {
      if (!applyDisplayRule(w.displayRule, vals)) {
        ids.push(w.componentId);
        if (w.widgetConfig) {
          iterateAllWidgets(w.widgetConfig.structure, (w) => {
            ids.push(w.componentId);
          });
        }
      }
    }

    if (w.widgetConfig) {
      ids = ids.concat(getAllIdsToRemove(w.widgetConfig.structure, vals));
    }

    return ids;
  }, []);
}

export function iterateAllWidgets(widgets, iteratee) {
  // iterates over all widgets in the tree and passes the widget to the given function
  _.each(widgets, (w) => {
    iteratee(w);
    if (w.widgetConfig) {
      iterateAllWidgets(w.widgetConfig.structure, iteratee);
    }
  });
}

export function getWidget(widgets, id) {
  // returns a widget with the given id
  let widget = null;
  iterateAllWidgets(widgets, (w) => {
    widget = w.componentId === id ? w : widget;
  });
  return widget;
}

export function removeWidget(widgets, id) {
  // iterates over all widgets in the tree and removes one that has matching id
  for (let i = 0; i < widgets.length; i++) {
    const w = widgets[i];
    if (w.componentId === id) {
      widgets.splice(i, 1);
      return;
    } else if (w.widgetConfig) {
      removeWidget(w.widgetConfig.structure, id);
    }
  }
}

export function updateWidgetConfig(func) {
  /*
  Calls the passed function on the config of the widget. Function has to be passed because only the full config is
  passed from the parent, so the action has to be performed 'in the middle' of calls, and then the full config is
  passed to the parent.
  This function has to be bound to a react component.
  */
  const config = _.cloneDeep(this.props.getWidgetConfig());
  const widgetConfig = getWidget(config, this.state.widgetId);

  func(widgetConfig);

  this.props.updateConfig(config);
}


/* Developers only */

export function makeClassesOptions(opts) {
  /*
  Used by: <DropdownClasses ... options={this.classesOptions}>
   */
  return _.map(opts, (opt) => {
    return {key: opt, text: opt, value: opt};
  });
}

export function listChildrenId(config, excludedList) {
  excludedList.push(config.componentId);
  if (!!config.widgetConfig) {
    if (config.widgetConfig.structure.length > 0) {
      _.map(config.widgetConfig.structure, (w) => {
        listChildrenId(w, excludedList);
      });
    }
  }
}

export function addWidgetOnInit(this_, name) {
  /*
  Add specified widget on init (containers/WidgetsEdit/WidgetsEdit.js:constructor()),
  for testing only [/widgets-edit].

  name = "TextareaWidget" or ["TextareaWidget", "RadioWidget"]
  addWidgetOnInit(this, ["TextareaWidget", "RadioWidget" ])
  */
  setTimeout(() => {
    const event = (name) => {
      return {
        stopPropagation: () => {
        },
        dataTransfer: {
          getData: (_) => {
            return name;
          }
        }
      };
    };
    if (_.isString(name)) {
      name = [name];
    }
    _.each(name, function (value, _) {
      this_.handleDrop("rootWidget", event(value));
    }
    );
  }, 0);
}

export function getParentWidget(widgets, id) {
  let parent = null;
  iterateAllWidgets(widgets, (widget) => {
    if (!!widget.widgetConfig && !!widget.widgetConfig.structure) {
      widget.widgetConfig.structure.forEach((child) => {
        if (id === child.componentId) {
          parent = widget;
          // parent = _.cloneDeep(widget);
        }
      });
    }
  });
  return parent;
}
