/*eslint no-restricted-globals: 1*/

import React, { useEffect, useRef, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button, Form, Header, Icon, Message, Modal, Tab, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import QRCode from "qrcode";
import { toastr } from "react-redux-toastr";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import { otpFactorLogin } from "../../actions/actions_auth";
import urls from "../../config/frontend_urls";
import { AuthView } from "./AuthView";

import "./SecondFactorLogin.css";

export const AUTH_TYPES = {
  APP: "app",
  EMAIL: "email"
};

export const lastOTPMethod = 'lastOTPMethod'

const OTPAuthForm = ({
  configUri,
  onReset,
  onSubmit,
  resetEmails,
  selectedRadioEmail,
  setSelectedRadioEmail
}) => {
  const canvasRef = useRef();

  useEffect(() => {
    if (configUri && canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, configUri);
    }
  }, [configUri]);

  const [otpKey, setOtpKey] = useState("");
  const copyQrUriToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(configUri);
      toastr.success("Success", "URI for authentication app was copied to your clipboard");
    }
  };
  const handleSelect = (id) => {
    setSelectedRadioEmail(id);
  }

  return <Form className="OTPFactorLogin">
    {configUri && <>
      <Message className="datahive-message" header="You have to set your second authentication factor.">
        <p>
          Please scan this QR code with authentication app.
          <Modal
            className="otp-disclaimer-modal"
            closeIcon
            header="Disclaimer"
            content={
              <div className="modal-content">
                <p>1. Add one of the following applications to your mobile device: SEC MFA, Google Authenticator,
                  Microsoft Authenticator.</p>
                <p>2. Open the installed application on your mobile device.</p>
                <p>
                  3. Follow the instructions within the application, e.g.:
                  <div className="small-indent">
                    a. SEC MFA:
                    <div className="big-indent">
                      i. Add a new form of authentication (click the blue shield in the bottom right corner).
                    </div>
                    <div className="big-indent">
                      ii. Choose the QR Scan option.
                    </div>
                    <div className="big-indent">
                      iii. Scan the QR code.
                    </div>
                    <div className="big-indent">
                      iv. Enter the numeric code generated by the application to the datahive.buzz platform.
                    </div>
                  </div>
                </p>
                <p>4. The QR code is unique and the application will remember it on your device. From now on, after
                  entering your datahive.buzz password, you will have to confirm your log-in by entering a new numeric
                  code generated by the mobile application.</p>
              </div>
            }
            trigger={<Icon link name="info" />}
          />
        </p>
      </Message>
      <canvas ref={canvasRef} />
      <Message compact info size="tiny" className="clickableMessage datahive-message" onClick={copyQrUriToClipboard}>
        Alternatively click here to copy URI that can be pasted in to authentication app
      </Message>
    </>}
    {resetEmails && resetEmails.length < 2 ?
      <Header>Enter the code generated by app on your device</Header>
      :
      <Header>Please, select email for further reset from list:</Header>
    }
    {resetEmails && resetEmails.length < 2 ?
      <div>
        <Form.Input
          autoFocus
          className="verificationCodeInput"
          width="6"
          type="text"
          label="Verification code"
          placeholder="code"
          value={otpKey}
          onChange={(e) => setOtpKey(e.target.value)}
        />
        <Form.Button
          className="login-btn datahive-button"
          color="yellow"
          content="Login"
          onClick={() => onSubmit(otpKey)}
          disabled={otpKey.length !== 6}
        />
      </div>
      :
      resetEmails ? resetEmails.map((item, key) =>
        <Form.Radio
          key={key}
          label={item.email}
          value={item.id}
          checked={selectedRadioEmail === item.id}
          onChange={() => handleSelect(item.id)}
        />
      ) : ''
    }
    {!configUri &&
      <>
        <Divider horizontal>Or</Divider>
        <Button
          basic
          className="reset-btn datahive-button"
          color="red"
          onClick={onReset}
          content="Reset auth device"
        /></>}
  </Form>;
};

OTPAuthForm.propTypes = {
  configUri: PropTypes.string,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resetEmails: PropTypes.array,
  selectedRadioEmail: PropTypes.number,
  setSelectedRadioEmail: PropTypes.func
};

const EmailAuthForm = ({
  hiddenEmail,
  onSend,
  onSubmit,
  wasEmailSent,
}) => {
  const [otpKey, setOtpKey] = useState("");

  return <>
    <Form className="OTPFactorLogin">
      <Header>{`Use your ${hiddenEmail} mailbox to authenticate`}</Header>
      <Form.Button
        className="send-btn datahive-button"
        color="green"
        type="email"
        content="Send OTP code"
        onClick={onSend}
        disabled={wasEmailSent}
      />
    </Form>
    <br />
    <Form>
      <Header>Enter the code from email</Header>
      <Form.Input
        autoFocus
        className="verificationCodeInput"
        width="6"
        type="text"
        label="Verification code"
        placeholder="code"
        value={otpKey}
        onChange={(e) => setOtpKey(e.target.value)}
      />
      <Form.Button
        className="login-btn datahive-button"
        color="yellow"
        content="Login"
        onClick={() => onSubmit(otpKey)}
        disabled={otpKey.length !== 6}
      />
    </Form>
  </>;
};

EmailAuthForm.propTypes = {
  hiddenEmail: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  wasEmailSent: PropTypes.bool.isRequired
};

const SecondFactorLogin = ({
  history,
  location,
  login
}) => {
  const configUri = location.state?.configure_qr_code;
  const hiddenEmail = location.state?.hidden_email;
  const [wasEmailSent, setWasEmailSent] = useState(false);
  const [resetEmails, setResetEmails] = useState([]);
  const [selectedRadioEmail, setSelectedRadioEmail] = useState(0);
  useEffect(() => {
    if (resetEmails && resetEmails.length === 1) {
      toastr.success("Password reset link sent!");
    }
  });

  const sendEmail = () =>
    ajax.get(config.SEND_EMAIL_WITH_OTP)
      .then(() => {
        toastr.success("Success", "One-time code has been sent to your mailbox.");
        setWasEmailSent(true);
      })
      .catch((error) => {
        let errorMsg = error.response.data?.message || error.response.data?.detail;
        toastr.error("Error", errorMsg);
        history.push(urls.LOGIN);
      });
  const resetSecondFactor = () =>
    ajax.post(config.SEND_2FA_RESET, { data: { 'auth_type': AUTH_TYPES.APP, selectedRadioEmail, 'reset_type': '2fa' } })
      .then(({ data }) => {
        if (data.emails && data.status === "SelectEmail") {
          setResetEmails(data.emails);
          toastr.info("Please, select your coordinator email!");
        } else {
          toastr.success("Success", `Reset email has been send to ${data.emails[0]}!
              Follow instructions to reset this authentication method.`);
          setTimeout(() => history.push(urls.LOGIN), 2000);
        }
      })
      .catch((error) => {
        let errorMsg = error.response.data?.message || error.response.data?.detail;
        toastr.error("Error", errorMsg);
        history.push(urls.LOGIN);
      });

  if (!location.state) {
    return <Redirect to={urls.LOGIN} />;
  }
  /* eslint-disable react/display-name*/
  const panes = [{
    menuItem: { key: "Mobile App", icon: 'mobile alternate', content: "Mobile App" },
    render: () =>
      <OTPAuthForm
        resetEmails={resetEmails}
        selectedRadioEmail={selectedRadioEmail}
        setSelectedRadioEmail={setSelectedRadioEmail}
        configUri={configUri || ''}
        onReset={resetSecondFactor}
        onSubmit={(otpKey) => login(history, {
          auth_type: AUTH_TYPES["APP"],
          otp_key: otpKey,
          token: location.state.token
        })}
      />
  }]

  if (hiddenEmail) {
    panes.push({
      menuItem: { key: 'Email', icon: 'mail', content: 'Email' },
      render: () =>
        <EmailAuthForm
          hiddenEmail={hiddenEmail}
          onSend={sendEmail}
          wasEmailSent={wasEmailSent}
          onSubmit={(otpKey) => login(history, {
            auth_type: AUTH_TYPES["EMAIL"],
            otp_key: otpKey,
            token: location.state.token
          })}
        />,
    })
  }
  /* eslint-enable react/display-name*/
  const lastOTP = localStorage.getItem(lastOTPMethod)

  return <AuthView>
    <Tab
      defaultActiveIndex={!lastOTP || lastOTP === AUTH_TYPES.APP ? 0 : panes.length - 1}
      panes={panes}
    />
  </AuthView>
};

SecondFactorLogin.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { login: otpFactorLogin })(SecondFactorLogin));
