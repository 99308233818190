import _ from "lodash";
import React from "react";
import {connect} from "react-redux";
import {Button, Checkbox, Container, Grid, Radio} from "semantic-ui-react";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import moment from "moment";
import PropTypes from "prop-types";

import {errorsClear} from "../../actions/actions_errors";
import {postForm} from "../../actions/actions_form";
import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import Pagination from "../../components/Pagination";
import Dropdown from "../../components/simple/Dropdown/LevenDropdown";
import config from "../../config/config";
import urls from "../../config/frontend_urls";
import {
  LINGUIST_MONTH_REPORT_STATUS_ACCEPTED,
  LINGUIST_MONTH_REPORT_STATUS_PENDING,
  LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT,
  REPORT_TYPE_LANGTASK,
  TASK_MONTH_REPORT_STATUS_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_PRE_ACCEPT,
} from "../../constraints/Ele";
import ajax from "../../helpers/ajax";
import FormErrors from "../FormErrors";
import ReportExport from "./ReportExport";
import ReportTable from "./ReportTable";
import {GROUPED_BY} from "./utils";

import "./LinguistsReportList.css";


export class LinguistsReportList extends FilteredComponent {
  static propTypes = {
    auth: PropTypes.shape({
      is_staff: PropTypes.bool
    }).isRequired,
    errorsClear: PropTypes.func.isRequired,
    postForm: PropTypes.func.isRequired,
    errorsNew: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      reportFilters: [],
      reports: [],
      activeIndex: -1,
      reportsSortData: {},
      totalPages: 1,
      isDateRange: false,
      additionalFiltersDisabled: false
    };
    this.arrayFilters = ["deadlines", "company_id", "language", "user_type"];
  }

  getData = () => {};

  componentDidMount() {
    this.getCompanies();
    this.getUserType();
    this.getDeadlines();
    super.componentDidMount();
  }

  getUserType = () => {
    ajax.get(config.USER_TYPES)
      .then((response) => {
        this.setState({"userTypes": response.data});
      });
  }

  pageChange = (e, {activePage}) => {
    const newFilters = this.updateFilters({page: activePage});
    this.parseReportsWithReload(newFilters);
  };

  setReportsGroupedByTask = () => {
    const ids = _.reduce(this.state.reports, (acc, row) => {
      return _.concat(acc, _.map(row.reports, (report) => report.month_report));
    }, []);
    this.getStatistics(ids);
  };

  setReportsGroupedByTaskAndProject = () => {
    const reports = _.reduce(this.state.reports, (acc, row) => {
      return _.concat(acc, row.reports);
    }, []);
    const ids = _.reduce(reports, (acc, row) => {
      return _.concat(acc, _.map(row.reports, (report) => report.month_report));
    }, []);
    this.getStatistics(ids);
  };

  setReportsGroupedByUser = () => {
    this.getStatistics();
  };

  isDateInDeadline = (date, start_date, end_date) => {
    return moment(moment(date).format("YYYY-MM-DD")).isBetween(moment(start_date), moment(end_date));
  }

  checkDeadlines(date) {
    const {deadlines} = this.state;
    let start_date;
    let end_date;
    if (date) {
      [start_date, end_date] = date.split(" - ");
    }

    const deadlinesIds = deadlines.filter((item) => this.isDateInDeadline(item.start, start_date, end_date)
      || this.isDateInDeadline(item.end, start_date, end_date)).map((item) => item.id);
    return deadlinesIds;
  }

  getGroupedReports = (params, callback) => {
    this.setState({displayLoaderSpinner: true});
    ajax.get(config.LINGUISTS_MONTH_REPORTS, {params})
      .then((response) => {
        /* eslint-disable-next-line no-unused-vars */
        response.data.filter_options["user_type"] = this.state.userTypes ?
          this.state.userTypes.map((item) => item.text) : [];
        const filterOptions = _.mapValues(response.data.filter_options, (v) =>
          _.map(v, (o) => {
            return {
              key: o,
              value: o,
              text: o
            };
          }));
        if (this.getGroupedBy() === GROUPED_BY.USER) {
          filterOptions.status = [
            {
              key: "pe",
              value: LINGUIST_MONTH_REPORT_STATUS_PENDING,
              text: "Pending"
            },
            {
              key: "ac",
              value: LINGUIST_MONTH_REPORT_STATUS_ACCEPTED,
              text: "Accepted"
            }
          ];
        }
        if (this.getGroupedBy() === GROUPED_BY.PROJECT) {
          filterOptions.status = [
            {
              key: "na",
              value: TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
              text: "Not-accepted"
            },
            {
              key: "ac",
              value: LINGUIST_MONTH_REPORT_STATUS_ACCEPTED,
              text: "Accepted"
            },
            {
              key: "pa",
              value: TASK_MONTH_REPORT_STATUS_PRE_ACCEPT,
              text: "Pre-accept"
            }

          ];
        }
        if (this.getGroupedBy()) {
          if (!filterOptions.user_type.some((obj) => obj.text === "Unknown")) {
            filterOptions.user_type.push({
              key: "---",
              value: "---",
              text: "Unknown"
            });
          }
        }
        this.setState({
          reports: response.data.table_content,
          filterOptions,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE)
        }, callback);
      });
  };

  getReports = (params) => {
    const groupedBy = this.getGroupedBy();
    switch (groupedBy) {
    case GROUPED_BY.TASK:
      this.getGroupedReports(params, this.setReportsGroupedByTask);
      break;
    case GROUPED_BY.PROJECT:
      // Reports in the same format as the ones grouped by task.
      this.getGroupedReports(params, this.setReportsGroupedByTask);
      break;
    case GROUPED_BY.TASK_AND_PROJECT:
      this.getGroupedReports(params, this.setReportsGroupedByTaskAndProject);
      break;
    default:
      this.getGroupedReports(params, this.setReportsGroupedByUser);
      break;
    }
  };

  getCompanies = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        const companies = response.data;
        if (this.props.auth.is_staff && !_.find(response.data, ["id", -1])) {
          companies.push({
            id: -1,
            name: "No company assigned"
          });
        }
        if (!this.props.auth.is_staff && companies.length === 1) {
          this.updateFilters({company_id: [companies[0].id]});
        }
        this.setState({
          companies: companies,
        });
      });
  };

  getStatistics = (reportIds) => {
    const ids = reportIds ? reportIds : _.map(this.state.reports, (report) => report.id);
    ajax.get(config.LINGUISTS_MONTH_REPORTS_TIMES, {params: {ids}})
      .then((response) => {
        const groupedBy = this.getGroupedBy();
        switch (groupedBy) {
        case GROUPED_BY.TASK:
          this.setStatisticsGroupByTask(response.data);
          break;
        case GROUPED_BY.PROJECT:
          this.setStatisticsGroupByTask(response.data);
          break;
        case GROUPED_BY.TASK_AND_PROJECT:
          this.setStatisticsGroupByTaskAndProject(response.data);
          break;
        default:
          this.setStatisticsGroupByUser(response.data);
          break;
        }
      })
      .catch(() => this.setState({displayLoaderSpinner: false}));
  };

  setStatisticsGroupByUser = (data) => {
    let id;
    const reports = _.cloneDeep(this.state.reports);

    _.each(reports, (report) => {
      for (let j = 0; j < report.tasks_reports.length; j++) {
        id = report.tasks_reports[j].id;
        if (report.tasks_reports[j].type === REPORT_TYPE_LANGTASK && data[id]) {
          report.tasks_reports[j].sessions = data[id].sessions;
          report.tasks_reports[j].percentiles = data[id].percentiles;
        }
      }
    });
    this.setState({
      reports,
      displayLoaderSpinner: false
    });
  };

  setStatisticsGroupByTask = (data) => {
    const reports = _.cloneDeep(this.state.reports);
    _.each(reports, (row) => {
      _.each(row.reports, (report) => {
        if (!data[report.id]) {
          return;
        }
        report.percentiles = data[report.id].percentiles;
        report.sessions = data[report.id].sessions;
      });
    });

    this.setState({
      reports,
      displayLoaderSpinner: false
    });
  };

  setStatisticsGroupByTaskAndProject = (data) => {
    const projectReports = _.cloneDeep(this.state.reports);
    _.each(projectReports, (projectReport) => {
      _.each(projectReport.reports, (taskReports) => {
        _.each(taskReports.reports, (report) => {
          if (!data[report.id]) {
            return;
          }
          report.percentiles = data[report.id].percentiles;
          report.sessions = data[report.id].sessions;
        });
      });
    });

    this.setState({
      reports: projectReports,
      displayLoaderSpinner: false
    });
  };

  // click on table row -> show row content
  onClick = (event, idx) => {
    const activeIndex = this.state.activeIndex;
    const newIndex = activeIndex === idx ? -1 : idx;

    this.setState({activeIndex: newIndex});
  };

  acceptUserReport = (event, report, flag) => {
    /* Accept user report -> accept all task reports for this user. */
    event.preventDefault();
    event.stopPropagation();

    const reports = _.cloneDeep(this.state.reports);
    report = _.find(reports, {id: report.id});

    let status, taskStatus;
    if (flag) {
      status = this.props.auth.is_staff ?
        LINGUIST_MONTH_REPORT_STATUS_ACCEPTED : LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT;
      taskStatus = this.props.auth.is_staff ?
        TASK_MONTH_REPORT_STATUS_ACCEPTED : TASK_MONTH_REPORT_STATUS_PRE_ACCEPT;
    } else {
      status = LINGUIST_MONTH_REPORT_STATUS_PENDING;
      taskStatus = TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED;
    }

    report.status = status;
    for (let i = 0; i < report.tasks_reports.length; i++) {
      report.tasks_reports[i].status = taskStatus;
    }

    // send report to server
    this.setState(
      {reports: reports},
      () => {
        this.postReport(report);
      }
    );
  };

  acceptTaskReport = (report, flag, taskIdx = null) => {
    /* Accept user's task report. */
    const reports = _.cloneDeep(this.state.reports);
    const groupedBy = this.getGroupedBy();
    switch (groupedBy) {
    case GROUPED_BY.USER:
      // report = MonthReport
      report = report.tasks_reports[taskIdx];
      //report = _.find(reports, {id: report.id});
      report.status = flag
        ? this.props.auth.is_staff
          ? TASK_MONTH_REPORT_STATUS_ACCEPTED
          : TASK_MONTH_REPORT_STATUS_PRE_ACCEPT
        : TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED;

      // send report to server
      this.setState(
        {reports},
        () => {
          this.postTaskReport(report);
        }
      );
      break;
    default:
      // report = TaskReport
      report.status = flag
        ? this.props.auth.is_staff
          ? TASK_MONTH_REPORT_STATUS_ACCEPTED
          : TASK_MONTH_REPORT_STATUS_PRE_ACCEPT
        : TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED;

      // send report to server
      this.setState(
        {reports},
        () => {
          this.postTaskReport(report);
          this.cleanReportFilters();
        }
      );
      break;
    }
  };

  changeTaskReportsHours = (taskReport, hours) => {
    const reports = _.cloneDeep(this.state.reports);

    if (this.state.filters.groupByTask || this.state.filters.groupByProject) {
      taskReport.hours = parseFloat(hours);

      // send report to server
      this.setState(
        {reports},
        () => {
          this.postTaskReport(taskReport);
          this.cleanReportFilters();
        }
      );
    } else {
      const report = _.find(reports, {id: taskReport.month_report});
      taskReport = _.find(report.tasks_reports, {id: taskReport.id});
      taskReport.hours = parseFloat(hours);

      // send report to server
      this.setState(
        {reports},
        () => {
          this.postReport(report);
          this.cleanReportFilters();
        }
      );
    }
  };

  updateMonthReport = (monthReport) => {
    const tasksReportsIds = [];
    monthReport.tasks_reports.map((item) => tasksReportsIds.push(item.id));
    ajax.get([config.EDIT_LINGUIST_MONTH_REPORT, monthReport.id], {params: {tasks_reports: tasksReportsIds}})
      .then((response) => {
        const reports = _.cloneDeep(this.state.reports);
        const reportIdx = _.findIndex(reports, {id: response.data.id});
        reports[reportIdx] = response.data;
        this.setState({reports});
      });
    this.getStatistics();
  };

  postTaskReport = (taskReport) => {
    ajax.post(
      [config.EDIT_LINGUIST_TASK_REPORT, taskReport.id], {data: taskReport})
      .then((response) => {
        const reports = _.cloneDeep(this.state.reports);
        switch (this.getGroupedBy()) {
        case GROUPED_BY.USER:
          this.postTaskReportCallback(reports, response.data);
          break;
        case GROUPED_BY.TASK_AND_PROJECT:
          this.postTaskReportGroupedByTaskAndProjectCallback(reports, response.data);
          break;
        default:
          this.postTaskReportGroupedByTaskCallback(reports, response.data);
          break;
        }
      })
      .catch((error) => {
        this.showErrorForm(error.data);
      });
  };

  postTaskReportGroupedByTaskCallback = (reports, data) => {
    _.each(reports, (row) => {
      const reportIdx = _.findIndex(row.reports, {id: data.id});
      if (reportIdx !== -1) {
        const rep = row.reports[reportIdx];
        data.percentiles = rep.percentiles;
        data.sessions = rep.sessions;
        row.reports[reportIdx] = data;
      }
    });
    this.setState({reports});
  };

  postTaskReportGroupedByTaskAndProjectCallback = (reports, data) => {
    _.each(reports, (row) => {
      _.each(row.reports, (taskReports) => {
        const reportIdx = _.findIndex(taskReports.reports, {id: data.id});
        if (reportIdx !== -1) {
          const rep = taskReports.reports[reportIdx];
          data.percentiles = rep.percentiles;
          data.sessions = rep.sessions;
          taskReports.reports[reportIdx] = data;
          return false;
        }
      });
    });
    this.setState({reports});
  };

  postTaskReportCallback = (reports, data) => {
    const reportIdx = _.findIndex(reports, {id: data.month_report});
    const monthReport = reports[reportIdx];
    const taskReportIdx = _.findIndex(monthReport.tasks_reports, {id: data.id});
    monthReport.tasks_reports[taskReportIdx] = data;
    if (data.month_report_status !== monthReport.status) {
      //if status changed, reload month report.
      monthReport.status = data.month_report_status;
      this.setState({reports}, () => this.updateMonthReport(monthReport));
    } else {
      this.setState({reports}, () => this.updateMonthReport(monthReport));
    }
  };

  postReport = (report) => {
    this.props.errorsClear();
    this.props.postForm(
      [config.EDIT_LINGUIST_MONTH_REPORT, report.id],
      report,
      this.updateStatusPopup
    );
  };

  updateStatusPopup = (data) => {
    const reports = _.cloneDeep(this.state.reports);
    const idx = _.findIndex(reports, {id: data.data.id});
    const report = data.data;
    // copy calculated times to new report
    _.forEach(reports[idx].tasks_reports, (taskReport, tidx) => {
      report.tasks_reports[tidx].percentiles = taskReport.percentiles ? taskReport.percentiles : 0;
      report.tasks_reports[tidx].sessions = taskReport.sessions ? taskReport.sessions : 0;
    });

    reports[idx] = report;
    this.setState({reports});
  };

  parseReportsWithReload = (params) => {
    this.getReports(params);
    this.props.errorsClear();
  };

  cleanReportFilters = () => {
    //after a change in the displayed data, removes the now irrelevant values from filters
    const filters = {...this.state.reportFilters};
    _.forEach(Object.keys(this.state.reportFilters), (filterName) => {
      const filter = filters[filterName];
      const possibleValues = null;
      filters[filterName].value = _.filter(filter.value, (val) => {
        return _.includes(possibleValues, val);
      });
    });
    this.setState({reportFilters: filters});
  };

  onCloseModal = () => {
    this.setState({displayModal: false});
  };

  getFilterOptionsGeneric = (objs) => {
    return _.map(objs, (o) => {
      return {
        key: o.id,
        value: o.id,
        text: o.name ? o.name : o.id
      };
    });
  };

  showErrorForm = (error) => {
    this.props.errorsNew(error.response.data);
  };

  getDeadlines = () => {
    ajax.get(config.LINGUISTS_MONTH_REPORTS_DEADLINE)
      .then((response) => {
        this.setState({deadlines: response.data});
      })
      .catch(() => {
        this.setState({deadlines: []});
        this.showErrorForm("Error fetching deadlines!");
      });
  };

  handleSubmit = () => {
    this.parseReportsWithReload(this.state.filters);
    this.setState({displayLoaderSpinner: true, additionalFiltersDisabled: true});
  };

  getGroupedBy = () => {
    const {filters} = this.state;
    let groupedBy = filters.groupByTask ? "TASK" : "USER";
    if (filters.groupByProject) {
      groupedBy = groupedBy === "TASK" ? "TASK_AND_PROJECT" : "PROJECT";
    }
    return GROUPED_BY[groupedBy];
  };

  changeFilter = (field, value) => {
    this.setState({reports: [], totalPages: 1},
      () => this.changeFilterInstantly({[field]: value}));
  }

  changeMainFilter = (field, value) => {
    this.setState(
      {reports: [],
        totalPages: 1,
        displayLoaderSpinner: false},
      () => this.changeFilterInstantly({
        [field]: value,
        module_name: [],
        project_name: [],
        tasks: [],
        order_by: null,
        allTime: null,
      }));
  }

  renderTableFilters = (params) => {
    const groupedBy = this.getGroupedBy();
    const possibleFilterValues = this.state.filterOptions || {};

    const groupByProjectFilters = (
      <React.Fragment>
        <Grid.Row centered>
          <Grid.Column className={"ui center aligned"}>
            User
            <Dropdown
              className={"user-dropdown"}
              placeholder="User"
              fluid search multiple selection
              options={possibleFilterValues.username || []}
              value={_.compact(_.concat([], params.username))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("username", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            User type
            <Dropdown
              className={"type-dropdown"}
              placeholder="User type"
              fluid search multiple selection
              options={possibleFilterValues.user_type || []}
              value={_.compact(_.concat([], params.user_type))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("user_type", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            Status
            <Dropdown
              className={"status-dropdown"}
              placeholder="Status"
              fluid search multiple selection
              options={possibleFilterValues.status || []}
              value={_.compact(_.concat([], params.status))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("status", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            Language
            <Dropdown
              placeholder="Language"
              fluid search multiple selection
              options={possibleFilterValues.language || []}
              value={_.compact(_.concat([], params.language))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("language", value);
              }}/>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>);

    const groupByTaskProjectFilters = (
      <React.Fragment>
        <Grid.Column className={"ui center aligned"}>
          User type
          <Dropdown
            className={"type-dropdown"}
            placeholder="User type"
            fluid search multiple selection
            options={possibleFilterValues.user_type || []}
            value={_.compact(_.concat([], params.user_type))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("user_type", value);
            }}/>
        </Grid.Column>
        <Grid.Column className={"ui center aligned"}>
          Language
          <Dropdown
            placeholder="Language"
            fluid search multiple selection
            options={possibleFilterValues.language || []}
            value={_.compact(_.concat([], params.language))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("language", value);
            }}/>
        </Grid.Column>
      </React.Fragment>
    );
    const groupByTaskFilters = (
      <React.Fragment>
        <Grid.Row centered columns={5}>
          <Grid.Column className={"ui center aligned"}>
            Task
            <Dropdown
              className={"task-dropdown"}
              placeholder={"Task"}
              fluid search multiple selection
              options={possibleFilterValues.task || []}
              value={_.compact(_.concat([], params.task))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("task", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            Module
            <Dropdown
              className={"module-dropdown"}
              placeholder="Module"
              fluid search multiple selection
              options={possibleFilterValues.module_name || []}
              value={_.compact(_.concat([], params.module_name))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("module_name", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            Project
            <Dropdown
              className={"project-dropdown"}
              placeholder="Project"
              fluid search multiple selection
              options={possibleFilterValues.project_name || []}
              value={_.compact(_.concat([], params.project_name))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("project_name", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            Language
            <Dropdown
              placeholder="Language"
              fluid search multiple selection
              options={possibleFilterValues.language || []}
              value={_.compact(_.concat([], params.language))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("language", value);
              }}/>
          </Grid.Column>
          <Grid.Column className={"ui center aligned"}>
            User type
            <Dropdown
              className={"type-dropdown"}
              placeholder="User type"
              fluid search multiple selection
              options={possibleFilterValues.user_type || []}
              value={_.compact(_.concat([], params.user_type))}
              disabled={!this.state.additionalFiltersDisabled}
              onChange={(e, {value}) => {
                this.changeFilter("user_type", value);
              }}/>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );

    const groupByUsersFilters = (
      <React.Fragment>
        <Grid.Column className={"ui center aligned"}>
          User
          <Dropdown
            className={"user-dropdown"}
            placeholder="User"
            fluid search multiple selection
            options={possibleFilterValues.username || []}
            value={_.compact(_.concat([], params.username))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("username", value);
            }}/>
        </Grid.Column>
        <Grid.Column className={"ui center aligned"}>
          User type
          <Dropdown
            className={"type-dropdown"}
            placeholder="User type"
            fluid search multiple selection
            options={possibleFilterValues.user_type || []}
            value={_.compact(_.concat([], params.user_type))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("user_type", value);
            }}/>
        </Grid.Column>
        <Grid.Column className={"ui center aligned"}>
          Status
          <Dropdown
            className={"status-dropdown"}
            placeholder="Status"
            fluid search multiple selection
            options={possibleFilterValues.status || []}
            value={_.compact(_.concat([], params.status))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("status", value);
            }}/>
        </Grid.Column>
        <Grid.Column className={"ui center aligned"}>
          Language
          <Dropdown
            placeholder="Language"
            fluid search multiple selection
            options={possibleFilterValues.language || []}
            value={_.compact(_.concat([], params.language))}
            disabled={!this.state.additionalFiltersDisabled}
            onChange={(e, {value}) => {
              this.changeFilter("language", value);
            }}/>
        </Grid.Column>
      </React.Fragment>
    );

    const additionalFilters = (
      <React.Fragment>
        {groupedBy === GROUPED_BY.USER && <Grid.Row centered>{groupByUsersFilters}</Grid.Row>}
        {groupedBy === GROUPED_BY.TASK && groupByTaskFilters}
        {groupedBy === GROUPED_BY.TASK_AND_PROJECT && <Grid.Row centered>{groupByTaskProjectFilters}</Grid.Row>}
        {groupedBy === GROUPED_BY.PROJECT && groupByProjectFilters}
      </React.Fragment>);

    return (
      <div>
        <div className="header">
          <Button
            id="addReportButton"
            color="yellow"
            className="datahive-button"
            onClick={() => {
              this.props.history.push(urls.USER_REPORT);
            }}
          >
            Add report
          </Button>
        </div>
        <div className="filters">
          <Grid columns={4}>
            <Grid.Row columns={2}>
              <Grid.Column className={"ui center aligned"}>
                Company
                <Dropdown
                  className={"company-dropdown"}
                  placeholder="Company"
                  fluid search multiple selection
                  options={this.getFilterOptionsGeneric(this.state.companies || [])}
                  value={_.compact(_.concat([], _.map(params.company_id, (o) => parseInt(o))))}
                  onChange={(e, {value}) => this.changeMainFilter("company_id", value)}/>
                <><br/>
                  <div className={"filterModes"}>
                    <Checkbox
                      className={"group-by-checkbox"}
                      color="green"
                      disabled={this.state.displayLoaderSpinner}
                      checked={!!params.groupByTask}
                      label={"Group by tasks"}
                      onChange={() => this.changeMainFilter("groupByTask", !params.groupByTask)}/>
                    <Checkbox
                      className={"group-by-checkbox"}
                      color="green"
                      disabled={this.state.displayLoaderSpinner}
                      checked={!!params.groupByProject}
                      label={"Group by project"}
                      onChange={() => this.changeMainFilter("groupByProject", !params.groupByProject)}/>
                  </div>
                </>
              </Grid.Column>
              <Grid.Column className={"ui center aligned"}>
                {!params.allTime && <>
                  Deadlines
                  {!this.state.isDateRange && <Dropdown
                    className={"deadline-dropdown"}
                    placeholder="Deadlines"
                    fluid search multiple selection
                    options={this.getFilterOptionsGeneric(this.state.deadlines)}
                    value={_.compact(_.concat([], _.map(params.deadlines, (o) => parseInt(o))))}
                    onChange={(e, {value}) => this.changeMainFilter("deadlines", value)}/>}
                  {this.state.isDateRange && <DatesRangeInput
                    className={"datesRange"}
                    autoComplete="off"
                    dateFormat="YYYY-MM-DD"
                    name="datesRange"
                    placeholder="Start Date - End Date"
                    value={params.dateRange || ""}
                    iconPosition="left"
                    popupPosition="bottom center"
                    onChange={(e, data) => {
                      const newVal = data.value;
                      const deadlineIds = this.checkDeadlines(newVal);
                      this.setState({reports: []},
                        this.changeFilterInstantly({
                          deadlines: deadlineIds,
                          order_by: null,
                          dateRange: newVal,
                          groupByProject: params.groupByProject
                        }));
                    }}/>}
                </>}
                <><br/>
                  <div className={"filterModes"}>
                    {!params.allTime &&
                    <Radio
                      slider
                      label={"Date range"}
                      checked={this.state.isDateRange}
                      onChange={(e, data) => this.setState({isDateRange: data.checked})}/>}
                    {params.groupByTask && !params.groupByProject &&
                    <Checkbox
                      color="green"
                      disabled={this.state.displayLoaderSpinner}
                      checked={params.allTime || false}
                      label={"All time"}
                      onChange={() => {
                        this.changeFilterInstantly({allTime: !params.allTime,
                          dateRange: "",
                          deadlines: []});
                      }}/>}
                  </div>
                </>
              </Grid.Column>
            </Grid.Row>
            {additionalFilters}
            <Grid.Row columns={2}>
              <Grid.Column className={"no-padding-column"} textAlign="right">
                <Button
                  className="datahive-button submit-button"
                  color="yellow"
                  size="tiny"
                  type="submit"
                  disabled={this.state.displayLoaderSpinner}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Grid.Column>
              <Grid.Column className={"no-padding-column"}>
                <Button
                  className="datahive-button export-button"
                  color="light-black"
                  size="tiny"
                  onClick={() => {
                    this.setState({displayModal: true});
                  }}>Export reports
                </Button>
                <span
                  className={"reset-filters-button"}
                  onClick={() => this.setState({reports: [],
                    additionalFiltersDisabled: true},
                  this.resetFilters)}
                >
                  Reset all filters
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  };

  render() {
    const {filters} = this.state;
    if (this.state.displayModal) {
      return <ReportExport
        closeModal={this.onCloseModal}
        reports={this.state.reports}
        groupedBy={this.getGroupedBy()}
      />;
    }

    let reports;
    if (this.state.displayLoaderSpinner) {
      reports = <LoaderSpinner/>;
    } else {
      reports = (
        <ReportTable
          rows={this.state.reports}
          updateFilters={(filters) =>
            this.changeFilterInstantly(filters)
          }
          orderBy={filters.order_by}
          groupedBy={this.getGroupedBy()}
          onUserAccept={this.acceptUserReport}
          onTaskAccept={this.acceptTaskReport}
          onTaskHoursChange={this.changeTaskReportsHours}
        />);
    }

    return (
      <Container className={"LinguistsReportList"}>
        {this.renderTableFilters(filters)}
        <FormErrors/>
        {reports}
        {filters.allTime && <Pagination
          activePage={filters.page || 1}
          onPageChange={this.pageChange}
          totalPages={this.state.totalPages}
        />}
      </Container>
    );
  }
}


function mapStateToProps(state) {
  return {auth: state.auth};
}

export default connect(mapStateToProps, {
  postForm,
  errorsClear,
})(LinguistsReportList);

