import _ from "lodash";
import React, {Component} from "react";
import {List, Button} from "semantic-ui-react";
import PropTypes from "prop-types";

import TreeLeaf from "./TreeLeaf";
import NewNodeInput from "./NewNodeInput";
import NodeWithChildren from "./NodeWithChildren";
import {iterateAllNodes} from "./utils";


class TreeNode extends Component {
  static propTypes = {
    /* expected structure is {title (optional), value, children(optional)} */
    data: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    // array of values for nodes that should be collapsed.
    collapse: PropTypes.array,
    // will display checkbox next to node title.
    checkableNodes: PropTypes.bool,
    // All nodes will be open by default. If falsy, all nodes will be closed by default.
    defaultOpen: PropTypes.bool,
    /* Only a leaf node can be truly checked. Checks on non leaf nodes are for display only. */
    statelessParents: PropTypes.bool,
    // overwrites the native function.
    handleCollapseClick: PropTypes.func,
    // overwrites the native function.
    handleCheckboxClick: PropTypes.func,
    searchQuery: PropTypes.string,
    handleAddClick: PropTypes.func,
    handleDeleteClick: PropTypes.func,
    displayAddButton: PropTypes.bool,
    displayDeleteButton: PropTypes.bool,
    updateOpenInputValue: PropTypes.func,
    openInputValue: PropTypes.string,
    parentNodeValue: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      newNode: {
        title: "",
      },
      hovered: null,
    };
  }

  getChecked = (node) => {
    if (this.props.statelessParents && node.children) {
      let allChildrenChecked = true; // check if all leaves under node are checked.
      iterateAllNodes(node.children, (n) => {
        if (!n.children && !_.includes(this.props.value, n.value)) {
          allChildrenChecked = false;
        }
      });

      return allChildrenChecked;
    }

    return _.includes(this.props.value, node.value);
  };

  getSomeDescendantsSelected = (node) => {
    let someDescendantsSelected = false; // check if all leaves under node are checked.
    iterateAllNodes(node.children, (n) => {
      if (_.includes(this.props.value, n.value)) {
        someDescendantsSelected = true;
      }
    });

    return someDescendantsSelected;
  };

  getIcon = (node, collapse) => {
    const checked = this.getChecked(node);
    const someDescendantsSelected = this.getSomeDescendantsSelected(node);

    let icon = <List.Icon name="square outline"/>;
    if (node.children) {
      if (!collapse) {
        icon = [<List.Icon key={1} name="caret down"/>];
        if (checked) {
          icon.push(<List.Icon key={3} name="check square outline"/>);
        } else if (someDescendantsSelected) {
          icon.push(<List.Icon key={3} name="minus square outline"/>);
        } else {
          icon.push(<List.Icon key={2} name="square outline"/>);
        }
      } else if (!!collapse) {
        icon = [<List.Icon key={1} name="caret right"/>];
        if (checked) {
          icon.push(<List.Icon key={3} name="check square outline"/>);
        } else {
          icon.push(<List.Icon key={2} name="square outline"/>);
        }
      }
      if (this.props.checkableNodes && checked) {
        icon.push(<List.Icon key={2} name="check square outline"/>);
      } else if (this.props.checkableNodes && !checked && !someDescendantsSelected) {
        icon.push(<List.Icon key={2} name="square outline"/>);
      } else if (this.props.checkableNodes && !checked && someDescendantsSelected) {
        icon.push(<List.Icon key={2} name="dot circle outline"/>);
      }
    } else if (checked) {
      icon = <List.Icon key={1} name="check square outline"/>;
    }
    return icon;
  };

  getNodes = () => {
    const nodes = _.map(this.props.data, (node) => {
      let collapse = (_.findIndex(this.props.collapse, (o) => o === node.value) !== -1);
      if (!this.props.defaultOpen) {
        collapse = !collapse;
      }
      const search = this.props.searchQuery;
      const original = _.split(node.value, ".");
      const search_elements = _.split(search, ".");
      if (!!search && (search === node.value || _.difference(original, search_elements).length === 0)) {
        collapse = false;
      }
      const title = node.title ? node.title : node.value;
      const deleteButton =
        <Button
          size="tiny" icon={"minus"} className={"tiniest"} compact
          onClick={() => this.props.handleDeleteClick(node.value)}
        />;

      const icon = this.getIcon(node, collapse);
      let onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.handleCheckboxClick(node.value);
      };

      const onMouseEnter = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({hovered: node.value});
      };

      const onMouseLeave = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({hovered: null});
      };

      if (node.children && !this.props.checkableNodes) {
        onClick = (e) => {
          if (e.target.getAttribute("class") === "caret right icon" ||
            e.target.getAttribute("class") === "caret down icon") {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.props.handleCollapseClick(node.value);
          } else {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.props.handleCheckboxClick(node.value);
          }
        };
      }

      const commonProps = {
        node: node,
        title: title,
        color: node.color,
        deleteButton: this.props.displayDeleteButton ? deleteButton : null,
        displayAddButton: this.props.displayAddButton,
        handleAddClick: this.props.handleAddClick,
        openInputValue: this.props.openInputValue,
        updateOpenInputValue: this.props.updateOpenInputValue,
      };
      let content =
        <TreeLeaf
          {...commonProps}
        />;


      if (node.children) {
        if (!collapse) {
          content =
            <NodeWithChildren
              parentProps={this.props}
              {...commonProps}
            />;
        }
      }

      return (
        <List.Item
          onClick={onClick}
          key={node.value}
          onMouseOver={onMouseEnter}
          onMouseOut={onMouseLeave}
          onMouseLeave={onMouseLeave}
        >
          {icon}
          {content}
        </List.Item>
      );
    });

    if (this.props.displayAddButton && (this.props.openInputValue === this.props.parentNodeValue || !this.props.parentNodeValue)) {
      nodes.unshift(
        <List.Item key={"addnewNode"}>
          <NewNodeInput
            nodeValue={this.props.parentNodeValue}
            handleAddClick={this.props.handleAddClick}
          />
        </List.Item>
      );
    }

    return nodes;
  };


  render() {
    const nodes = this.getNodes();

    return (
      <List className={"TreeNode "}>
        {nodes}
      </List>
    );
  }
}

export default TreeNode;
