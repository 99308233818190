import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Icon, Table} from "semantic-ui-react";
import _ from "lodash";
import {formatSeconds} from "../../helpers/utils";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import config from "../../config/config";

export default class UsersStatisticsTable extends Component {
  static propTypes = {
    usersStatisticsList: PropTypes.arrayOf(PropTypes.object).isRequired,
    all: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderUserRows() {
    let rows = {};
    if (!!this.props.usersStatisticsList) {
      rows = _.map(this.props.usersStatisticsList, (user, idx) => {
        return (
          <Table.Row key={"User" + idx} className={"SortableTableItem"}>
            <Table.Cell>
              <Link to={"/user-statistics/" + user.username}>
                <Button basic fluid size="mini" color={"black"}>
                  <Icon name="user"/>{user.username}
                </Button>
              </Link>
            </Table.Cell>
            <Table.Cell>{user.tasks_count}</Table.Cell>
            <Table.Cell>{user.answers_count}</Table.Cell>
            <Table.Cell>
              {this.props.all ? formatSeconds(user.summary_time) : formatSeconds(user.mean_summary_time)}
            </Table.Cell>
            <Table.Cell>
              {user.last_answer ? moment(user.last_answer).utc().format(config.FORMAT_Dmy_Hm) : ""}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }

  render() {
    const rows = this.renderUserRows();
    return (
      <Table celled structured textAlign="center" className="SortableTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Tasks</Table.HeaderCell>
            <Table.HeaderCell>Answers</Table.HeaderCell>
            <Table.HeaderCell>{this.props.all ? "Summary time" : "Mean summary time"}</Table.HeaderCell>
            <Table.HeaderCell>Last answer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <>{rows}</>
        </Table.Body>
      </Table>
    );
  }
}
