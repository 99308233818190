import React, {Component} from "react";
import _ from "lodash";
import {Link} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";
import {Button, Checkbox, Container, Header, Table} from "semantic-ui-react";
import moment from "moment";
import "moment/min/locales";

import JSONEditorReact from "../../../components/simple/JSONEditor/JSONEditor";
import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import urls from "../../../config/frontend_urls";
import BottomBar from "../../../components/BottomBar";
import ModalConfirm from "../../../components/ModalConfirm";
import "./AnswerAdminDetail.css";

export default class AnswerAdminDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      displayDeleteModal: false,
    };
  }

  componentDidMount() {
    ajax.get([config.ADMIN_ANSWERS_DETAIL, this.props.match.params.id])
      .then((response) => {
        this.setState({
          answer: response.data
        });
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  deleteAnswer = () => {
    ajax.delete([config.ADMIN_ANSWER_DETAIL, this.props.match.params.id])
      .then(() => {
        this.props.history.push(
          urls.ADMIN_ANSWERS_BY_TASK_LIST.replace(":taskSlug", this.state.answer.task_slug));
        toastr.success("Success!", "Answer deleted.");
      })
      .catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  TableRow = (props) => {
    return (
      <Table.Row>
        <Table.Cell collapsing>{props.title}</Table.Cell>
        <Table.Cell>{props.content}</Table.Cell>
      </Table.Row>
    );
  }

  toggleModal = () => {
    this.setState((prevState) => ({displayDeleteModal: !prevState.displayDeleteModal}));
  }

  render() {
    if (!this.state.answer) {
      return null;
    }
    const ans = this.state.answer;
    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);


    return (
      <Container className={"AnswerAdminDetail"}>
        <Button basic color="black"
          onClick={() => this.props.history.goBack()}
          icon={"arrow left"}
          content={"Back"}
        />
        <Header>
          {ans.task_slug} #{ans.id}
        </Header>
        <JSONEditorReact
          value={ans.content || {}}
          mode={"view"}
          open
        />
        <Table celled compact>
          <Table.Body>
            <this.TableRow title={"Is answered"} content={<Checkbox checked={ans.is_answered}/>}/>
            <this.TableRow title={"Is active"} content={<Checkbox checked={ans.is_active}/>}/>
            <this.TableRow title={"Is skipped"} content={<Checkbox checked={ans.is_skipped}/>}/>
            <this.TableRow title={"Is first"} content={<Checkbox checked={ans.is_first}/>}/>
            <this.TableRow title={"Time taken"} content={ans.time_taken}/>
            <this.TableRow title={"Timestamp"}
              content={moment(ans.timestamp).format(config.DATETIME_FORMAT_LLT)}/>
            <this.TableRow title={"User"} content={ans.user}/>
            <this.TableRow title={"Fix author"} content={ans.fix_author}/>
            <this.TableRow title={"Fix timestamp"}
              content={ans.fix_timestamp && moment(ans.fix_timestamp).format(config.DATETIME_FORMAT_LLT)}/>
            <this.TableRow title={"Times seen"} content={ans.times_seen}/>
            <this.TableRow title={"Question"} content={
              <Link to={urls.ADMIN_QUESTIONS_DETAIL.replace(":id", ans.question)}>
                {`${ans.task_slug} #${ans.question}`}
              </Link>}
            />
          </Table.Body>
        </Table>
        <BottomBar
          panelLeft={
            <Button color="red"
              onClick={this.toggleModal}
              content="Delete"
            />
          }/>
        {this.state.displayDeleteModal &&
        <ModalConfirm
          id={"deleteModal"}
          title="Are you sure you want to delete this answer?"
          content={<>{this.props.match.params.id}</>}
          closeModal={this.toggleModal}
          button={<>
            <Button onClick={this.deleteAnswer} content="Delete" color="red" id={"delete"} floated={"left"}/>
            <Button onClick={this.toggleModal} content="Cancel"/>
          </>}
        />}
      </Container>
    );
  }
}
