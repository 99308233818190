import React from "react";
import {Popup} from "semantic-ui-react";
import PropTypes from "prop-types";

import "./TaskListProgressBar.css";

const TaskListProgressBar = (props) => {
  const pDone = Math.round(props.done / props.total * 100);
  const pAssigned = Math.round(props.assigned / props.total * 100);
  return (
    <div className={"progress-mini"}>
      <div className="ui tiny progress progress-middle">
        <Popup content={
          <div>
            <p>Assigned: {pAssigned}%</p>
            <p>{props.assigned} of {props.total}</p>
          </div>
        }
        trigger={
          <div className="bar assigned" style={{width: pAssigned + "%"}}/>
        }/>
        <Popup content={
          <div>
            <p>Done: {pDone}%</p>
            <p>{props.done} of {props.total}</p>
          </div>
        }
        trigger={
          <div className="bar done" style={{width: pDone + "%"}}/>
        }/>
      </div>
    </div>
  );
};


TaskListProgressBar.propTypes = {
  total: PropTypes.number,
  done: PropTypes.number,
  assigned: PropTypes.number
};

export default TaskListProgressBar;
