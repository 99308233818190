export const MAX_PASSWORD_LENGTH = 128;
export const MAX_USERNAME_LENGTH = 150;
export const MAX_EMAIL_LENGTH = 254;
export const USERNAME_REGEX = /^[-_.@0-9a-z]*$/

export const FIELD_LENGTHS = {
  PASSWORD: MAX_PASSWORD_LENGTH,
  USERNAME: MAX_USERNAME_LENGTH,
  EMAIL: MAX_EMAIL_LENGTH
};

export const FIELD_ERRORS = {
  FIELD_REQUIRED: "Required field!",
  PASSWORDS_DO_NOT_MATCH: "Passwords do not match!",
  PASSWORD_TOO_WEAK: "Password does not meet the requirements!",
  PASSWORD_SAME_AS_OLD: "Password must be different from the old password!",
  USERNAME_REGEX_NO_MATCH: "Please, use company id. Digits are also allowed!"
};

export const TOO_LONG_ERRORS = {
  PASSWORD: "Password is too long!",
  EMAIL: "Email is too long!",
  USERNAME: "Username is too long!"
};

export const BACKEND_ERROR_TYPES = {
  EMAIL_ERROR: "email",
  PASSWORD_ERROR: "password",
  COMBINATION_ERROR: "login_combination",
  OLD_PASSWORD_ERROR: "old_password"
};
