import _ from "lodash";
import React, {Component} from "react";
import {Popup} from "semantic-ui-react";
import "./ProgressBar.css";
import PropTypes from "prop-types";

import ajax from "../helpers/ajax";
import config from "../config/config";

/* eslint-disable no-shadow */

class ProgressBar extends Component {
  static propTypes = {
    taskSlug: PropTypes.string.isRequired,
    evaluation: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      userAnswers: 0,
      otherAnswers: 0,
      toAnswer: 1,
      evaluated: 0,
    };
  }

  componentDidMount() {
    this.getProgress();
    this.interval = setInterval(this.getProgress, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getProgress = () => {
    ajax.get([config.TASK_PROGRESS_BAR, this.props.taskSlug])
      .then((response) => {
        if (response.data) {
          const userAnswers = response.data.done;
          const toAnswer = response.data.new;
          const evaluated = response.data.evaluated;
          const otherAnswers = response.data.all - toAnswer - userAnswers;

          this.setState({userAnswers, toAnswer, otherAnswers, evaluated});
        }
      });
  };

  increaseAnswered = () => {
    const userAnswers = this.state.userAnswers + 1;
    const toAnswer = this.state.toAnswer - 1;

    this.setState({userAnswers, toAnswer});
  };

  increaseEvaluated = () => {
    const evaluated = this.state.userAnswers + 1;
    this.setState({evaluated});
  };

  getBar = (bar, idx) => {
    const width = bar.width;
    const className = bar.className;
    const style = {
      borderRadius: 0,
      opacity: 0.7,
    };

    if (width === 0) {
      return "";
    }
    return (
      <Popup
        key={idx}
        trigger={
          <div
            className={`bar ${className}`}
            style={{width: `${width}%`}}
          >
            {bar.value}
          </div>
        }
        content={`${bar.tooltip}`}
        position="top center"
        inverted
        style={style}
        size="mini"
      />
    );
  }

  render() {
    const total = this.state.userAnswers + this.state.otherAnswers + this.state.toAnswer;
    const userWidth = (this.state.userAnswers * 100 / total.toFixed(2));
    const otherWidth = (this.state.otherAnswers * 100 / total.toFixed(2));
    const unansweredWidth = (this.state.toAnswer * 100 / total.toFixed(2));
    const BARS_TOP = [
      {className: "user", width: userWidth,
        value: this.state.userAnswers, tooltip: "My answers: " + this.state.userAnswers },
      {className: "others", width: otherWidth,
        value: this.state.otherAnswers, tooltip: "Other answers: " + this.state.otherAnswers},
      {className: "unanswered", width: unansweredWidth,
        value: this.state.toAnswer, tooltip: "Unanswered questions: " + this.state.toAnswer}
    ];
    const barsTop = _.map(BARS_TOP, this.getBar);

    const evaluatedWidth = (this.state.evaluated * 100 / total.toFixed(2));
    const nonEvaluated = total - this.state.evaluated;
    const nonEvaluatedWidth = (nonEvaluated * 100 / total.toFixed(2));
    const BARS_BOTTOM = [
      {className: "evaluations", width: evaluatedWidth,
        value: this.state.evaluated, tooltip: "Evaluated: " + this.state.evaluated },
      {className: "evaluations-answered", width: nonEvaluatedWidth,
        value: nonEvaluated, tooltip: "Non evaluated: " + nonEvaluated},
    ];
    const barsBottom = _.map(BARS_BOTTOM, this.getBar); //eslint-disable-line no-unused-vars

    return (
      <React.Fragment>
        <div className={"ProgressBar"}>
          {barsTop}
        </div>
        {/*<div className={"ProgressBar"}>*/}
        {/*  {barsBottom}*/}
        {/*</div>*/}
      </React.Fragment>
    );
  }
}


export default ProgressBar;
