import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export default class Registrator extends React.Component {
  static propTypes = {
    // index of container in QuestionsSet
    idx: PropTypes.number.isRequired,
    // array with all questions for display
    question: PropTypes.object.isRequired,
    // task config
    config: PropTypes.object.isRequired,
    // function triggered on answer submit
    onSubmit: PropTypes.func.isRequired,
    // function triggered on answer skip
    onSkip: PropTypes.func,
    // function triggered on answer fix
    onFixAnswer: PropTypes.func,
    // flag if review should be displayed (only in done tab)
    showReview: PropTypes.bool,
    // flag if user can edit review
    editReview: PropTypes.bool,
    // function triggered on review submit
    onReviewSubmit: PropTypes.func,
    // function triggered on review accept
    onReviewAccept: PropTypes.func,
    username: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      questionId: -1,
      copyPopupContent: "Right click to copy username.",
      copyPopupOpen: false,
      activeAnswer: 0,
    };

    // objects mapping componentId to the isEmpty and isValid functions from Widgets
    this.validRegister = {};
    this.emptyRegister = {};
    this.prepareForSubmitRegister = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * This method save isValid function to this.validFunctions
   * @param func isValid function from widget
   * @param componentId
   */
  registerValid = (func, componentId) => {
    this.validRegister[componentId] = func;
  };

  /**
   * This method save isEmpty function to this.emptyFunctions
   * @param func isValid function from widget
   * @param componentId
   */
  registerEmpty = (func, componentId) => {
    this.emptyRegister[componentId] = func;
  };

  /**
   * This method saves PrepareForSubmit function to this.prepareForSubmitRegister
   * @param func prepareForSubmit function from widget
   * @param componentId
   */
  registerPrepareForSubmit = (func, componentId) => {
    this.prepareForSubmitRegister[componentId] = func;
  };

  /**
   * This method call mothod isEmpty from Widget with componentId.
   * NOTE: Widget must register this method in componentDidMount.
   * @param componentId
   * @returns {*}
   */
  isEmpty = (componentId) => {
    if (this.emptyRegister[componentId]) {
      return this.emptyRegister[componentId]();
    }
    return false;
  };

  /**
   * This method call mothod isValid from Widget with componentId.
   * NOTE: Widget must register this method in componentDidMount.
   * @param componentId
   * @returns {*}
   */
  isValid = (componentId) => {
    if (this.validRegister[componentId]) {
      return this.validRegister[componentId]();
    }
    return true;
  };

  /**
   * This method calls method prepareForSubmit from Widget with componentId.
   * NOTE: Widget must register this method in componentDidMount.
   * @param componentId
   * @returns {*}
   */
  prepareForSubmit = (componentId) => {
    if (this.prepareForSubmitRegister[componentId]) {
      return this.prepareForSubmitRegister[componentId]();
    }
    return true;
  };


  onSkip = () => {
    this.props.onSkip(this.props.idx, null);
  };

  mergeQuestionWithAnswer = (question, answerIdx) => {
    const widgetVals = _.cloneDeep(question.input);
    // load values from answer
    if (question.answers.length > answerIdx) {
      _.each(question.answers[answerIdx].content, (value, key) => {
        // for object do not remove existed keys
        if (typeof value === "object" && !Array.isArray(value)) {
          _.each(value, (v, k) => {
            // if key doesn't exist in question -> create it!
            if (!Object.keys(widgetVals).includes(key) || typeof widgetVals[key] !== "object") {
              widgetVals[key] = {};
            }
            widgetVals[key][k] = v;
          });
        } else {
          widgetVals[key] = value;
        }
      });
    }
    return widgetVals;
  };

}
