import _, { forEach } from "lodash";

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";
import moment from "moment";

import config from "../../config/config";
import {Button, Form, FormField, Grid, Icon, Input, Popup} from "semantic-ui-react";
import ajax from "../../helpers/ajax";
import "./UserProfileInfo.css";
import DragAndDropSelector from "../../components/simple/DragAndDropSelector/DragAndDropSelector";
import BottomBar from "../../components/BottomBar";

import {getPossibleDropdownValues, formatPhoneNumber} from "../../helpers/utils";
import FormErrors from "../FormErrors";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import LanguageSkill from "./LanguageSkill";
import {LANGUAGE_SKILLS} from "../../helpers/languages";
import {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";
import LoaderSpinner from "../../components/LoaderSpinner";
import {AUTH_TYPES} from "../Auth/SecondFactorLogin";


export class UserProfileInfo extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
    auth: PropTypes.object.isRequired,
    match: PropTypes.object,
    errorsClear: PropTypes.func.isRequired,
    errorsNew: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
      languageChoicesFilter: "",
      languageSelectedFilter: "",
      errors: {},
      userTasksList: [],
    };
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile = () => {
    const username = this.props.username ? this.props.username : this.props.match.params.username;
    const isStaff = this.props.auth.is_staff;
    const isCompanyCoordinator = this.props.auth.is_company_coordinator;

    this.setState({
      languageSkills: _.map(Object.keys(LANGUAGE_SKILLS), (skill) => ({
        key: parseInt(skill),
        value: parseInt(skill),
        text: LANGUAGE_SKILLS[skill]
      }))
    });

    ajax.get([config.USER_DETAILS, username])
      .then((response) => {
        const userDetails = response.data;
        userDetails.profile.languages.forEach((lang) => {
          lang._id = lang.id;
          lang.id = lang.name;
        });
        this.setState({userDetails});
      })
      .catch(() => {
        toastr.error("User not found!");
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        this.setState({userTypes: response.data});
      });
    ajax.get(config.LANGUAGES)
      .then((response) => {
        this.setState({
          languageAvailableOptions: _.map(response.data, (language) => ({
            id: language.name,
            ...language
          })),
          communicationLanguageAvailableOptions: _.map(response.data, (language) => ({
            text: language.display_name,
            value: language.name,
            key: language.name
          }))
        });
      });
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        this.setState({
          companiesDisplayOptions: getPossibleDropdownValues("id", "name", response.data, true, true),
          companiesOptions: response.data
        });
      });
    ajax.get([config.USER_TASKS, username])
      .then((response) => {
        this.setState({
          userTasksList: response.data
        });
      });
    ajax.get(config.EDUCATION)
      .then((response) => {
        this.setState({
          educationDisplayOptions: getPossibleDropdownValues("id", "name", response.data, true),
          educationOptions: response.data
        });
      });
    if (isStaff || isCompanyCoordinator) {
      ajax.get(config.USER_PROFILE_STATUSES)
        .then((response) => {
          this.setState({
            statusDisplayOptions: getPossibleDropdownValues("id", "name", response.data, false),
            statusOptions: response.data
          });
        });
    }
    if (this.props.auth.is_superuser) {
      ajax.get(config.ALL_DIVISIONS)
        .then((response) => {
          this.setState({
            divisionDisplayOptions: getPossibleDropdownValues("id", "name", response.data, false),
            divisionOptions: response.data
          });
        });
    }
  };

  saveDetails = () => {
    const username = this.props.username ? this.props.username : this.props.match.params.username;
    const userDetails = _.cloneDeep(this.state.userDetails);
    _.forEach(userDetails.profile.languages, (lang) => {
      lang.id = lang._id;
      delete lang._id;
    });
    let new_communication_languages = [];
    forEach(userDetails.profile.communication_languages, (lang) => {
      new_communication_languages.push({"name": lang.name ? lang.name : lang});
    });
    userDetails.profile.communication_languages = new_communication_languages;
    if (!userDetails.profile.has_user_data_access) {
      userDetails.profile.confirmed_access_to_users_data = false;
    }
    delete userDetails.profile.username_hash;
    if (userDetails.profile.phone_number) {
      userDetails.profile.phone_number = userDetails.profile.phone_number.split(/\s/).join("");
    }
    ajax.patch(
      [config.USER_DETAILS, username],
      {data: userDetails}
    ).then((response) => {
      this.props.errorsClear();
      if (response.status === 200) {
        toastr.success("Success", "User saved!");
        const userDetails = response.data;
        userDetails.profile.languages.forEach((lang) => {
          lang._id = lang.id;
          lang.id = lang.name;
        });
        this.setState({userDetails});
      }
    }).catch((error) => {
      this.props.errorsNew(error.response.data);
      this.setState({errors: error.response.data});
    });
  };

  resetTwoFactorAuth = (authType) => {
    const username = this.props.username ? this.props.username : this.props.match.params.username;

    ajax.delete(config.RESET_2FA, {data: {username, auth_type: authType, "reset_type": "2fa"}})
      .then(() => toastr.success("Success", "Second factor auth has been reset."));
  }

  unlockAccount = () => {
    const username = this.props.username ? this.props.username : this.props.match.params.username;
    ajax.post(config.UNLOCK_ACCOUNT, {data: {username}})
      .then(() => {
        let userDetails = {...this.state.userDetails};
        userDetails.is_account_locked = false;
        this.setState({userDetails});
        toastr.success("Success", "Account unlocked");
      })
      .catch(() => {
        toastr.success("Error", "Account unlock failed");
      });
  }

  getDetails = (path) => {
    //returns field from userDetails object. Short name because its a convenience function
    return _.get(this.state.userDetails, path) || "";
  };

  setDetails = (path, value) => {
    const details = {...this.state.userDetails};
    _.set(details, path, value);
    this.setState({
      userDetails: details
    });
  };

  onLanguageSelect = (e, choiceListRef, selectedListRef) => {
    /* Handler for moving languages from choices to selected. */
    e.preventDefault();
    const type = e.dataTransfer.getData("type");
    let selected;
    if (type === "Drag") {
      const id = e.dataTransfer.getData("id");
      selected = [...this.state.userDetails.profile.languages];
      const newLanguage = {
        id,
        name: id,
        native: false,
        skill: 10,
        profile: this.state.userDetails.profile.id
      };
      selected.push(newLanguage);
      selected = _.sortBy(selected, ["name"]);

      // Scroll to this position in list to fix 'blank-list' bug.
      choiceListRef.scrollToItem(0);
      selectedListRef.scrollToItem(0);

      this.setDetails("profile.languages", selected);
    }
  }

  onLanguageDeselect = (e, choiceListRef, selectedListRef) => {
    /* Handler for moving languages from selected to choices. */
    e.preventDefault();
    const type = e.dataTransfer.getData("type");
    let selected;
    if (type === "LanguageSkill") {
      const id = e.dataTransfer.getData("id");
      selected = [...this.state.userDetails.profile.languages];
      const idx = _.findIndex(selected, (o) => o.name === id);
      selected.splice(idx, 1);

      // Scroll to this position in list to fix 'blank-list' bug.
      choiceListRef.scrollToItem(0);
      selectedListRef.scrollToItem(0);

      this.setDetails("profile.languages", selected);
    }
  }

  updateLanguage = (id, field, value) => {
    /* Handler for changes in the languages that are selected */
    const profile = _.cloneDeep(this.state.userDetails.profile);
    const language = _.find(profile.languages, {"name": id});

    _.set(language, field, value);
    if (field === "native" && value === true) {
      _.set(language, "skill", 110);
    }
    if (field === "skill" && value !== 110) {
      _.set(language, "native", false);
    }

    this.setState({
      userDetails: {...this.state.userDetails, profile}
    });
  };

  SelectedLanguage = ({data, index, style}) => (
    /*
    This function returns React elements to be passed to DragAndDropSelector as selected items.
    These contain a handler for the edit of actual language objects.
     */
    <div style={style}>
      <LanguageSkill
        key={data[index].name}
        lang={data[index]}
        updateLanguage={this.updateLanguage}
        options={this.state.languageSkills}/>
    </div>
  )

  renderTasks = () => {
    return _.map(this.state.userTasksList, (task, idx) => {
      return (
        <Grid.Row key={"Task" + idx}>
          <Button
            as={Link}
            to={(!this.props.auth.is_company_coordinator ? "/task-statistics/": "/task/") + _.replace(task, /\//g, "").toLowerCase()}
            className={"drop"}
            basic
            fluid
            size="mini"
            color={"black"}>
            <Icon name="tasks"/>
            {task}
          </Button>
        </Grid.Row>
      );
    });
  };

  render() {
    const {is_staff: isStaff, is_superuser: isSuperuser, is_company_coordinator: isCompanyCoordinator} = this.props.auth;
    const isOwnProfile = this.props.username === this.props.auth.username;
    // Disable saving own data for external users.
    const canSave = !(isOwnProfile && this.props.auth.is_external);

    let dataLoaded = !!this.state.companiesOptions && !!this.state.languageAvailableOptions
      && !!this.state.userDetails && !!this.state.communicationLanguageAvailableOptions;

    if (isStaff || isCompanyCoordinator) {
      dataLoaded = dataLoaded && !!this.state.statusOptions;
    }

    if (!dataLoaded) {
      return <LoaderSpinner/>;
    }

    const {userDetails} = this.state;

    const paths = {
      first_name: "first_name",
      last_name: "last_name",
      remote: "profile.remote",
      education: "profile.education",
      linguistic_education: "profile.linguistic_education",
      company: "profile.company",
      email: "email",
      second_email: "profile.second_email",
      phone: "profile.phone_number",
      status: "profile.status",
      comments: "profile.comments",
      type: "profile.type",
      receive_emails: "receive_emails",
      staff: "is_staff",
      superuser: "is_superuser",
      user_data_access: "profile.has_user_data_access",
      divisions: "divisions",
      communication_languages: "profile.communication_languages",
      last_deadline: "last_deadline",
      last_login: "last_login",
      date_joined: "date_joined"
    };

    var _this = this;
    // eslint-disable-next-line no-unused-vars
    const inputArgs = _.mapValues(paths, (obj, idx) => {
      if (obj === paths.phone) {
        const phone = _this.getDetails(obj);
        return {
          value: formatPhoneNumber(phone),
          error: !!_.get(_this.state.errors, obj),
          onChange: ((e) => _this.setDetails(obj, e.target.value)),
        };
      }
      return {
        value: _this.getDetails(obj),
        error: !!_.get(_this.state.errors, obj),
        onChange: ((e) => _this.setDetails(obj, e.target.value)),
      };
    });

    const company = <FormDropdown
      id="company" search selection
      placeholder="Select Company"
      label="Company"
      disabled={!isStaff || !canSave}
      options={this.state.companiesDisplayOptions}
      error={!!_.get(this.state.errors, paths.company)}
      value={userDetails.profile.company ? userDetails.profile.company.id : null}
      onChange={(e, data) => this.setDetails(paths.company,
        _.find(this.state.companiesOptions, (o) => o.id === data.value))}
    />;

    const status = isStaff || isCompanyCoordinator ? <FormDropdown
      id="status" search selection
      label="Status"
      placeholder="Select Status"
      options={this.state.statusDisplayOptions}
      error={!!_.get(this.state.errors, paths.status)}
      value={userDetails.profile.status ? userDetails.profile.status : null}
      onChange={(e, data) => this.setDetails(paths.status,
        _.find(this.state.statusOptions, (o) => o.id === data.value).id)
      }
    /> : null;

    const type = isStaff ? <Form.Dropdown
      id="type" search selection
      label="Type"
      placeholder="Select Type"
      options={this.state.userTypes}
      error={!!_.get(this.state.errors, paths.type)}
      value={userDetails.profile.type || null}
      onChange={(e, data) => this.setDetails(paths.type, data.value)}
    /> : null;

    const education = <FormDropdown
      id="education" search selection
      label="Education"
      disabled={(!isOwnProfile && !isStaff) || !canSave}
      placeholder="Select Education"
      options={this.state.educationDisplayOptions || []}
      error={!!_.get(this.state.errors, paths.education)}
      value={userDetails.profile.education ? userDetails.profile.education.id : null}
      onChange={(e, data) => this.setDetails(paths.education,
        _.find(this.state.educationOptions, (o) => o.id === data.value))}
    />;

    const linguisticEducation = <FormDropdown
      id="linguisticEducation" search selection
      label="Linguistic education"
      disabled={(!isOwnProfile && !isStaff) || !canSave}
      placeholder="Select yes/no"
      options={[
        {
          key: "Yes",
          text: "Yes",
          value: true
        },
        {
          key: "No",
          text: "No",
          value: false
        }
      ]}
      value={userDetails.profile.linguistic_education}
      onChange={(e, data) => this.setDetails(paths.linguistic_education, data.value)}
    />;

    const remote = isStaff ? <Form.Checkbox
      id="remote" className="centerContent" toggle
      label="Remote"
      disabled={!isStaff}
      checked={userDetails.profile.remote}
      onChange={(e, data) => this.setDetails(paths.remote, data.checked)}
    /> : null;

    const tasks = this.renderTasks();

    const receive_emails = <Form.Checkbox
      toggle className={"centerContent"}
      label="Receive e-mails"
      disabled={!canSave}
      checked={userDetails.receive_emails}
      onChange={(e, data) => this.setDetails(paths.receive_emails, data.checked)}
    />;

    const buttonRight =
      <Button
        id="save"
        color="green"
        className="datahive-button"
        onClick={this.saveDetails}
        content="Save"
      />;

    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    return (
      <div className="UserProfileInfo">
        <Form widths="equal">
          <FormErrors/>
          <FormField>
            <label className="user-info-field data-field-left">User hash code:</label>
            <span className="user-info-field">{this.state.userDetails.profile.username_hash}</span>
          </FormField>
          <Form.Group>
            <Form.Field>
              <label>First name</label>
              <Input
                id="first-name"
                disabled={!isStaff || !canSave}
                placeholder="First Name"
                {...inputArgs.first_name}
              />
            </Form.Field>
            <Form.Field>
              <label>Last name</label>
              <Input
                id="last-name"
                disabled={!isStaff || !canSave}
                placeholder="Last Name"
                {...inputArgs.last_name}
              />
            </Form.Field>
            {company}
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Email</label>
              <Input
                id="email"
                placeholder="Email"
                disabled={(!isStaff && !isCompanyCoordinator) || !canSave}
                {...inputArgs.email}
              />
            </Form.Field>
            <Form.Field>
              <label>Second Email</label>
              <Input
                id="second-email"
                disabled={(!isOwnProfile && !isStaff) || !canSave}
                placeholder="Second Email"
                {...inputArgs.second_email}
              />
            </Form.Field>
            <Form.Field>
              <label>Phone</label>
              <Input
                id="phone"
                disabled={!isStaff || !canSave}
                placeholder="Phone number"
                {...inputArgs.phone}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            {education}
            {linguisticEducation}
            {status}
          </Form.Group>
          <Form.Group>
            <FormDropdown
              id="communication" search selection multiple
              label="Communication languages"
              placeholder="Select Languages"
              disabled={!(isOwnProfile || canSave)}
              options={this.state.communicationLanguageAvailableOptions || []}
              error={false}
              value={userDetails.profile.communication_languages ? userDetails.profile.communication_languages.map(
                (e) => e.name) : []}
              onChange={(e, data) => this.setDetails(paths.communication_languages, data.value.map((d) => Object({"name": d})))
              }
            />
            {isSuperuser && <FormDropdown
              id="divisions" search selection multiple
              label="Divisions"
              placeholder="Select Divisions"
              options={this.state.divisionDisplayOptions || []}
              error={!!_.get(this.state.errors, paths.divisions)}
              value={userDetails.divisions ? userDetails.divisions.map(Number) : []}
              onChange={(e, data) => this.setDetails(paths.divisions, data.value)
              }
            />}
            {type}
          </Form.Group>
          <Form.Group>
            {remote}
            {receive_emails}
            <Form.Field></Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Input
              disabled={!canSave}
              id="status"
              label="Comments"
              placeholder="Comments"
              max={120}
              {...inputArgs.comments}
            />
          </Form.Group>
          {(isSuperuser || isStaff) && <Form.Group>
            <FormField>
              <label className="user-info-field data-field-left">Date joined:</label>
              <span className="user-info-field">{this.state.userDetails.date_joined ? moment(this.state.userDetails.date_joined).format(config.FORMAT_Ymd) : ""}</span>
            </FormField>
            <FormField>
              <label className="user-info-field data-field-left">Last login:</label>
              <span className="user-info-field">{this.state.userDetails.last_login ? moment(this.state.userDetails.last_login).format(config.FORMAT_Ymd) : ""}</span>
            </FormField>
            <FormField>
              <label className="user-info-field data-field-left">Last deadline:</label>
              <span className="user-info-field">{this.state.userDetails.last_deadline ? this.state.userDetails.last_deadline : ""}</span>
            </FormField>
          </Form.Group>}
          <Form.Group>
            {(isSuperuser || isCompanyCoordinator || isOwnProfile) &&
            <Form.Field>
              <label>
                Reset app auth
                <Popup
                  trigger={<Icon name="info"/>}
                  content="Clicking this button will reset your configured authentication device. It will have to be configured once again on next user login."
                />
              </label>
              <Button color="red" className="datahive-button" onClick={() => this.resetTwoFactorAuth(AUTH_TYPES["APP"])}>
                Reset
              </Button>
            </Form.Field>}
            {isSuperuser && userDetails.is_account_locked && <Form.Field>
              <label>
                Unlock account
                <Popup
                  trigger={<Icon name="info"/>}
                  content="Clicking this button will unlock user account."
                />
              </label>
              <Button color="red" className="datahive-button" onClick={this.unlockAccount}>
                Unlock account
              </Button>
            </Form.Field>}
          </Form.Group>
        </Form>
        <br/>
        <DragAndDropSelector
          filterable
          choices={this.state.languageAvailableOptions}
          choiceValue={"name"}
          selected={userDetails.profile.languages}
          selectedElement={this.SelectedLanguage}
          onSelect={this.onLanguageSelect}
          onDeselect={this.onLanguageDeselect}
          selectedProps={{itemSize: () => 50}}
        />
        <Grid columns={1} className={"table"}>
          <Grid.Row>
            <Grid.Column textAlign={"center"}>
              <b>Tasks</b>
            </Grid.Column>
          </Grid.Row>
          <Grid columns={1} className={"tasks"}>
            {tasks}
          </Grid>
        </Grid>
        {(isOwnProfile || canSave) && <BottomBar panelRight={buttonRight}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileInfo);
