import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {iterateAllWidgets} from "../../components/Widgets/widgetUtils";

import "./QuestionsSet.css";
import QuestionContainer from "./QuestionContainer";

export default class QuestionsSet extends Component {
  static propTypes = {
    // array with all questions for display
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    // task config
    config: PropTypes.object.isRequired,
    // function triggered on answer submit
    onSubmit: PropTypes.func.isRequired,
    // function triggered on answer skip
    onSkip: PropTypes.func,
    // function triggered on answer fix
    onFixAnswer: PropTypes.func,
    // is moderator in this task
    isModerator: PropTypes.bool.isRequired,
    // if answer may be reviewed
    reviewable: PropTypes.bool,
    // function triggered on comment submit
    onCommentSubmit: PropTypes.func,
    // function triggered on comment removal
    onCommentRemoval: PropTypes.func,
    // function triggered on comment update
    onCommentUpdate: PropTypes.func,
    // function triggered on review accept
    onReviewAccept: PropTypes.func,
    // if not only moderator can start discussion
    canLinguistStartDiscussion: PropTypes.bool,
    // which question has been submitted and is under processing
    processedSubmit: PropTypes.number,
    isTabNew: PropTypes.bool
  };

  onSkip = (question_idx) => {
    this.props.onSkip(question_idx, null);
  };

  renderQuestions = () => {
    const config = this.props.config;
    // remove popup content from all widgets
    iterateAllWidgets(config.structure, (w) => {
      if (w.popupContent) {
        delete w.popupContent;
      }
    });

    return _.map(this.props.questions, (question, questionIdx) => {
      const questionId = question.id;

      if (!("answers" in question)) {
        question.answers = [];
      } else {
        question.answers.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }

      return (
        <QuestionContainer
          key={"question" + questionId}
          idx={questionIdx}
          question={question}
          onSubmit={this.props.onSubmit}
          onSkip={this.props.onSkip ? this.onSkip : null}
          onFixAnswer={this.props.onFixAnswer}
          config={this.props.config}
          isModerator={this.props.isModerator}
          reviewable={this.props.reviewable}
          onCommentSubmit={this.props.onCommentSubmit}
          onCommentRemoval={this.props.onCommentRemoval}
          onCommentUpdate={this.props.onCommentUpdate}
          onReviewAccept={this.props.onReviewAccept}
          canLinguistStartDiscussion={this.props.canLinguistStartDiscussion}
          processingSubmit={this.props.processedSubmit === questionIdx}
          isTabNew={this.props.isTabNew}
          isTabDone={this.props.isTabDone}
        />
      );
    });
  };

  render() {
    return (
      <div className="QuestionsSet">
        {this.renderQuestions()}
      </div>
    );
  }
}
