import _ from "lodash";
import splitRetain from "split-retain";
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */


export function getAllRanges(ranges, rowContent, beginIdx, endIdx) {
  // transforms overlapping ranges into non overlapping smaller ranges
  let allCoordinates = [];
  const allRanges = [];
  _.each(ranges, (r) => {
    if (r.begin < beginIdx) {
      allCoordinates.push(beginIdx);
    } else {
      allCoordinates.push(r.begin);
    }
    if (r.end > endIdx) {
      allCoordinates.push(endIdx);
    } else {
      allCoordinates.push(r.end);
    }
  });

  allCoordinates = _.uniq(allCoordinates).sort();
  allCoordinates = allCoordinates.sort((a, b) => {
    return a - b;
  });

  for (let i = 0; i < allCoordinates.length - 1; i++) {
    allRanges.push({begin: allCoordinates[i], end: allCoordinates[i + 1]});
  }

  return allRanges;
}

export function getAllCrossingRanges(ranges, beginIdx, endIdx) {
  const allRanges = getAllRanges(ranges, null, beginIdx, endIdx);
  return _.filter(allRanges, (r) => r.begin < endIdx && r.end > beginIdx);
}

export function getAllCrossingRangesWithData(ranges, beginIdx, endIdx, propRanges) {
  const crossingRanges = getAllCrossingRanges(ranges, beginIdx, endIdx);
  return addDataToAllRanges(crossingRanges, propRanges);
}

export function addDataToAllRanges(allRanges, ranges) {
  return _.map(allRanges, (textRange) => {
    const rangeData = _.find(ranges, (dataRange) => textRange.begin >= dataRange.begin && textRange.end <= dataRange.end);
    if (rangeData) {
      textRange.id = rangeData.id;
    }

    return textRange;
  });
}

function getSentences(sentencesSplit, ranges, propRanges, originalString) {
  // Returns sentences made up of words.
  const sentences = [];
  _.reduce(sentencesSplit, (charactersBefore, sentence) => {
    const end = sentence.length + charactersBefore;
    const allRanges = getAllCrossingRangesWithData(ranges, charactersBefore, end, propRanges);

    const fragments = _.map(allRanges, (range) => {
      return {
        text: originalString.slice(range.begin, range.end),
        begin: range.begin,
        end: range.end
      };
    });

    const words = _.reduce(fragments, (words, fragment) => {
      const fragmentWords = splitRetain(fragment.text, " ");
      const newWords = [];

      _.each(fragmentWords, (word, idx) => {
        const wordsInFragment = _.slice(fragmentWords, 0, idx);
        const begin = fragment.begin + wordsInFragment.join("").length;
        newWords.push({
          text: word,
          begin: begin,
          end: begin + word.length,
        });
      });
      return words.concat(newWords);
    }, []);
    sentences.push(words);
    return end;
  }, 0);

  return sentences;
}

function addOffsetToFragment(fragment, ranges, measureLabel, measureText, w) {
  // Update offset of a fragment, if it is inside a range
  const containerRange = _.find(ranges, (r) => r.begin <= w.begin && r.end >= w.end);
  if (containerRange) {
    const fragmentText = _.reduce(fragment.words, (text, word) => {
      return text + word.text;
    }, "");

    measureLabel.textContent = containerRange ? containerRange.name : "";
    measureText.textContent = fragmentText;

    const labelWidth = measureLabel.getBoundingClientRect().width;
    const textWidth = measureText.getBoundingClientRect().width;

    fragment.offset = labelWidth > textWidth ? labelWidth - textWidth + 6 : 0;
  }
  return fragment;
}

function getSentencesOfFragmentsOfWords(sentences, measureLabel, measureText, ranges, allRanges, width) {
  /*
    This function parses sentences of words into sentences made of fragments made of words.
   */
  const sentencesRows = [];
  const rangesWithoutGeneral = _.cloneDeep(ranges);
  rangesWithoutGeneral.shift();

  _.each(sentences, (words, sentenceIdx) => {
    const rows = []; // rows of text displayed. They contain fragments.
    let fragments = [{words: [], width: 0, offset: 0}]; // fragments of text displayed.
    _.each(words, (w, idx) => {
      /* for each word:
      check if word starts a range.
      if yes:
        add current fragment to fragments,
        start new one with only this word

      if not:
        add word to last fragment.

      update the offset of the last fragment, if it is inside a range.

      check if currentFragment and others exceed the width of row
      if yes:
        remove the word from current fragment
        start new row
        start new currentFragment with the word
      if not:
        continue

      */

      // check if this word starts a range
      const range = _.find(allRanges, (r) => r.begin === w.begin);
      let textWidth = 0;
      if (range) {
        measureText.textContent = w.text;
        textWidth = measureText.getBoundingClientRect().width;

        const lastFragment = _.last(fragments);

        if (!lastFragment.words || lastFragment.words.length === 0) {
          fragments.pop();
        }
        fragments.push({words: [w], width: textWidth, offset: 0});
      } else {
        const lastFragment = _.last(fragments);
        lastFragment.words.push(w);
        lastFragment.width = getFragmentWidth(lastFragment, measureText);
      }

      if (_.find(rangesWithoutGeneral, (r) => r.begin <= w.begin && r.end >= w.end)) {
        const lastFragment = _.last(fragments);
        addOffsetToFragment(lastFragment, rangesWithoutGeneral, measureLabel, measureText, w);
      }

      const rowTextWidth = _.reduce(fragments, (sum, fragment) => sum + fragment.width + fragment.offset, 0);

      if (rowTextWidth > width) {
        // too wide, remove last word
        const lastFragment = _.last(fragments);
        lastFragment.words.pop();

        if (!lastFragment.words) {
          fragments.pop();
        } else {
          lastFragment.width = getFragmentWidth(lastFragment, measureText);
        }

        rows.push([...fragments]);
        const fragment = {words: [w], width: 0, offset: 0};
        fragment.width = getFragmentWidth(fragment, measureText);

        if (_.find(rangesWithoutGeneral, (r) => r.begin <= w.begin && r.end >= w.end)) {
          addOffsetToFragment(fragment, rangesWithoutGeneral, measureLabel, measureText, w);
        }

        fragments = [fragment];
      }
    });

    rows.push([...fragments]);
    sentencesRows.push({dataRowIdx: sentenceIdx, content: [...rows]});
  });

  // remove empty fragments. Something above adds them, can't find it now.
  _.each(sentencesRows, (sentenceRow) => {
    _.each(sentenceRow.content, (fragments) => {
      for (let i = 0; i < fragments.length; i++) {
        if (!fragments[i].words || fragments[i].words.length === 0) {
          fragments.splice(i, 1);
        }
      }
    });
  });
  const cleaned = _.reduce(sentencesRows, (clean, sentenceRow) => {
    if (sentenceRow.content && sentenceRow.content[0].length !== 0) {
      clean.push(sentenceRow);
    }
    return clean;
  }, []);

  return cleaned;
}

function getFragmentWidth(fragment, measureText) {
  measureText.textContent = getFragmentText(fragment);
  return measureText.getBoundingClientRect().width;
}

function getFragmentText(fragment) {
  const pureWords = _.map(fragment.words, (word) => word.text);
  return pureWords.join("");
}

function parseSentenceRowsToRows(sentencesRows) {
  /*
    Parses sentenceRows to data rows.
    Data rows contains sentence (dataRow) id, begin and end indexes and fragments that contain words.
  */
  return _.reduce(sentencesRows, (allRows, sentenceRow) => allRows.concat(
    _.map(sentenceRow.content, (displayRow, idx) => {
      const begin = displayRow[0].words[0].begin;
      const end = _.last(_.last(displayRow).words).end;

      return {
        dataRowIdx: sentenceRow.dataRowIdx,
        content: displayRow,
        begin,
        end,
      };
    })
  ), []);
}

function getRangesForDisplayPurposes(propRanges, originalString) {
  const ranges = _.cloneDeep(propRanges);
  ranges.unshift({
    begin: 0,
    end: originalString ? originalString.length : 0,
    id: "General range"
  });
  return ranges;
}

export function lineBreakSplitter(text) {
  const regex = /(\r?\n|\r|\n)/g;
  const result = text.split(regex).reduce((acc, curr) => {
    if (!curr) {
      return acc;
    } else if (regex.test(curr)) { //test if splited fragment is line break
      for (let char in curr) { //for each line break character (as it can be \n or \r or \r\n)
        if (acc[acc.length - 1]) {
          acc[acc.length - 1] += " "; // append space to previous item
        } // so length of original string will remain the same as SIOSTRA depends on it
      }
      return acc; //and dont append line break to list
    } else {
      return [...acc, curr];
    }
  }, []);

  //If string begins with line break -> add space to keep the length of the original string
  // console.log(text.match(/^(\r?\n|\r|\n)/))
  if((/^(\r|\n)/).test(text)){
    result[0] += " "
  }

  return result;
}

export function sentenceSplitter(text) {
  /*
  This function splits text into sentences. This is a simplification of BRAT heuristic sentence split refiner.
   */
  // Heurestically determined words on which the sentence should not be split.
  const skippedTermsDotAfter = [
    "Mr",
    "mr",
    "Ms", "ms",
    "Mrs", "mrs",
    "Dr", "dr",
    "Prof", "prof",
    "etc",
    "inc",
    "www"];
  const skippedTermsDotBefore = ["\\w", "\\d"];

  /* eslint-disable-next-line no-useless-escape */
  const exp = `([\.\!\?\n]+)(?!${skippedTermsDotBefore.join("|")})|([\n]+)`;
  const sep = new RegExp(exp, "g");
  const splitByRegex = text.split(sep);

  // why some are undefined? Not even the american scientists know.
  const notUndefined = _.reduce(splitByRegex, (acc, o) => o ? _.concat(acc, o) : acc, []);

  let merged = [notUndefined[0]]; // parts that have been merged together to avoid splitting on acronyms etc. (like Dr. Mr. Ms.)

  for (let i = 1; i < notUndefined.length; i++) {
    const elem = notUndefined[i];
    if (sep.test(elem)) {
      merged[merged.length - 1] += elem;
    } else {
      merged.push(elem);
    }
  }
  if (merged.length === 1) {
    return merged;
  }
  //for each fragment, test if it ends with a term that should not end a sentence. for example Dr.
  // this is done because firefox does not support lookbehinds, im basically trying to achieve what this regex would do:
  // `(?<!(${skippedTermsDotAfter.join('|')}))([\.\!\?\n]+)(?!${skippedTermsDotBefore.join('|')})`;
  /* eslint-disable-next-line no-useless-escape */
  const merging_exp = `(${skippedTermsDotAfter.join("|")})([\.\!\?\n]+)$`;
  let wrongfully_split = "";
  merged = _.reduce(merged, (acc, o) => {
    if (o.match(merging_exp)) {
      wrongfully_split += o;
    } else if (wrongfully_split) {
      acc.push(wrongfully_split+o);
      wrongfully_split="";
    } else {
      acc.push(o);
    }
    return acc;
  }, []);

  return merged;
}

export function getRowData(width, breakLinesOnlyOnNewLineCharacters) {
  /*
    Now put on your thinking hat, because this is complicated.
    This function returns a list of objects that represent display rows, while also holding data about which sentence
    they are from (sentences are separated by newlines in the text string).
  */
  width = width > 0 ? width : 500;
  const measureText = this.hiddenSpanRef.current;
  const measureLabel = this.hiddenLabelRef.current;
  if (!measureText || !measureLabel) {
    return [];
  }
  const splitter = breakLinesOnlyOnNewLineCharacters ? lineBreakSplitter : sentenceSplitter;

  const splitted = this.props.originalString ? splitter(this.props.originalString) : [];

  // Range covering all of the text needed for proper splitting into subranges
  const ranges = getRangesForDisplayPurposes(this.props.ranges, this.props.originalString);

  //get sentences split by spaces and ranges, made up of words. Word is an object with text, begin and end indexes.
  const sentences = getSentences(splitted, ranges, this.props.ranges, this.props.originalString);

  const allRanges = getAllCrossingRangesWithData(ranges, 0, this.props.originalString.length - 1, this.props.ranges);

  // get sentences made up of rows made up of fragments - based on ranges and widths + offset data for fragment
  const sentencesRows = getSentencesOfFragmentsOfWords(sentences, measureLabel, measureText, ranges, allRanges, width);
  return parseSentenceRowsToRows(sentencesRows);
}
