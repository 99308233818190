/**
 * Query builder component. As base is used:
 * https://github.com/ukrbublik/react-awesome-query-builder
 *
 * Added simple format for save and read from DB.
 */

import _ from "lodash";
import PropTypes from "prop-types";
import React, {Component} from "react";

import {fromJS} from "immutable";
import {Builder, Query} from "react-awesome-query-builder";
import config from "./config";
import "./QueryBuilder.css";

export default class QueryBuilder extends Component {
  static propTypes = {
    // fields config
    fields: PropTypes.objectOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      operators: PropTypes.arrayOf(PropTypes.string).isRequired,
      listValues: PropTypes.object,
    })),
    // function triggered when query change
    onChange: PropTypes.func,
    // initial query
    init: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.config = {...config, fields: props.fields};
  }

  ruleFromImmutable = (rule) => {
    return {
      field: rule.properties.field,
      type: rule.properties.valueType ? rule.properties.valueType[0] : null,
      input: rule.properties.valueSrc[0],
      operator: rule.properties.operator,
      value: rule.properties.value[0],
    };
  };

  groupFromImmutable = (group) => {
    if (!group) {
      return null;
    }
    const condition = group.properties.conjunction;
    const not = group.properties.not;

    // eslint-disable-next-line
    const rules = _.map(group.children1, (child, idx) => {
      if (child.type === "rule") {
        return this.ruleFromImmutable(child);
      } else {
        return this.groupFromImmutable(child);
      }
    });
    return {
      condition,
      not,
      rules,
    };
  };

  fromImmutableFormat = (tree) => {
    const jsTree = tree.toJS();
    return this.groupFromImmutable(jsTree);
  };

  ruleToImmutable = (rule, path) => {
    const id = _.uniqueId();
    const properties = {
      field: rule.field,
      operator: rule.operator,
      operatorOptions: null,
      value: [rule.value],
      valueSrc: [rule.input],
      valueType: [rule.type],
    };

    return {
      type: "rule",
      id,
      path: _.concat(path, id),
      properties
    };
  };

  groupToImmutable = (group, initPath = []) => {
    const id = _.uniqueId();
    const type = "group";
    const properties = {
      conjunction: group.condition,
      not: group.not
    };
    const path = _.concat(initPath, id);

    const children1 = {};

    // eslint-disable-next-line
    _.forEach(group.rules, (rule, key) => {
      if ("condition" in rule) {
        const value = this.groupToImmutable(rule, path);
        children1[value.id] = value;
      } else {
        const value = this.ruleToImmutable(rule, path);
        children1[value.id] = value;
      }
    });

    return {
      id,
      type,
      properties,
      path,
      children1
    };
  };

  toImmutableFormat = (tree) => {
    const result = this.groupToImmutable(tree);
    return fromJS(result);
  };

  getChildren = (props) => {
    return (
      <div className="query-builder">
        <Builder {...props} />
      </div>
    );
  };

  onChange = (tree) => {
    const value = this.fromImmutableFormat(tree);
    this.props.onChange(value);
  };

  render() {
    if (!this.props.init) {
      return (
        <Query
          {...this.config}
          get_children={this.getChildren}
          onChange={this.onChange}
        />
      );
    }
    const initialValue = this.toImmutableFormat(this.props.init);
    return (
      <Query
        value={initialValue}
        {...this.config}
        get_children={this.getChildren}
        onChange={this.onChange}
      />
    );
  }
}
