import React from "react";
import {Grid} from "semantic-ui-react";
import {WidgetEditor} from "../WidgetEditor";

import "./GooglePlayWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class GooglePlayWidgetEditor extends WidgetEditor {
  static getDefaultVals = (props) => {
    const widgetVals = {
      icon: "//lh3.googleusercontent.com/UvZyV73jQTlBpKTFxsET-Kup0HIa-aM6rxEjdlYpmOI_xp0z-u-qsdFUkZV6QSq30ds=s180-rw",
      developer: "Samsung Electronics Co., Ltd.",
      genre: "Health & Fitness",
      title: "Samsung Health",
      contentRating: "PEGI 3",
      screenshots: [
        "//lh3.googleusercontent.com/9ecsP5uonWW2DY2n76ffuB5XgVBn0PBA2k2MLL8WXUlY3Qm6L5LAaCwCkGYIqhslD3ZM=w720-h310-rw",
        "//lh3.googleusercontent.com/AWNLcreMNlGZNSTm4kUUL9RJqhqnxFtX0rY_QV538FrCZlpQrNT5LfXYnrrobQb-7D8=w720-h310-rw",
        "//lh3.googleusercontent.com/uxy94F6ERqaSPtwTeOkJZlIsCrxuhA-X8r3n7JrEZY1yhTDToBxIlyd9YSV9Y9tY7Z4=w720-h310-rw",
        "//lh3.googleusercontent.com/NAKUkt7fd-VYTo3oYiLkTs_RoEcdvtgEG9_HfLsdvv_ZHoGWByXZtk31si-4nzG8PSrH=w720-h310-rw",
        "//lh3.googleusercontent.com/-skWCzpfqKrymkvDWIFPuM-JlgGdJFE1netPbGGNpqtArkAkis-2dvr9J6s599-DZznv=w720-h310-rw"
      ],
      descriptionHTML: "Samsung Health provides core features to keep up your body fit and healthy. It will record and analyze your daily activities and habits to help maintain successful diet and lead healthy lifestyle.<br><br> Whether you walk or run, hike or bike, play indoor or outdoor sports, you can add and track the various physical exercises and activities in a single step using the various built-in trackers<br><br>Samsung Health helps to create a balanced lifestyle pattern by recording a variety of information like your food, caffeine and water intake details.<br><br>",
      url: "https://play.google.com/store/apps/details?id=com.facebook.orca&hl=es&gl=es",
      appId: "com.sec.android.app.shealth",
      video: null
    };

    props.updateVals(widgetVals, props.widgetId);
  };

  static header = "Google Play";

  render() {
    return (
      <Grid className={"GooglePlayWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default GooglePlayWidgetEditor;
