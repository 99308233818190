import * as actionTypes from "./types/types_errors";

export function errorsNew(errors) {
  return {
    type: actionTypes.ERRORS_NEW,
    errors
  };
}

export function errorsClear() {
  return {
    type: actionTypes.ERRORS_CLEAR,
  };
}
