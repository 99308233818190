import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Grid, Icon, Input, Popup} from "semantic-ui-react";
import ajax from "../../../helpers/ajax";
import {toastr} from "react-redux-toastr";
import Dropdown from "../../../components/simple/Dropdown/LevenDropdown";

import {parseFiltersWithMulti, setFilters, updateFilters} from "../../../helpers/filters";
import PaginationCompact from "../../../components/PaginationCompact";

import "./BaseTaskQuestions.css";

/**
 * Base class for components that should render task questions with the filtering option.
 */
class BaseTaskQuestions extends Component {
  static propTypes = {
    // task configuration
    config: PropTypes.object.isRequired,
    // task slug
    slug: PropTypes.string.isRequired,
    // default questions at page
    questionsAtPage: PropTypes.number,
    saveFilters: PropTypes.func.isRequired,
    filters: PropTypes.object,
    username: PropTypes.string.isRequired,
    isStaff: PropTypes.bool,
    isModerator: PropTypes.bool,
    isCompanyCoordinator: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        page: 1,
        page_size: props.questionsAtPage,
      },
      possibleFilterValues: {},
      filterOpened: false,
      questions: [],
      prevQuestions: [],
      nextQuestions: [],
      totalPages: 1,
      loading: true,
      timer: null,
      displaySpinner: true
    };

    this.setFilters = setFilters.bind(this);
    this.updateFilters = updateFilters.bind(this);
    this.parseFiltersWithMulti = parseFiltersWithMulti.bind(this);

    // above values should be changed in derived component
    this.getQuestionsUrl = null;
    this.orderBy = false;
  }

  componentDidMount() {
    //REFACTOR: each inheriting component should declare its own multiple filters
    this.parseFiltersWithMulti([
      "user", "company", "review",
      "advanced_answer", "advanced_answer_not_empty", "advanced_answer_empty",
      "advanced_question", "advanced_question_not_empty", "advanced_question_empty",
      "advanced_evaluation_answer", "advanced_evaluation_answer_not_empty", "advanced_evaluation_answer_empty",
      "advanced_evaluation_question", "advanced_evaluation_question_not_empty", "advanced_evaluation_question_empty"
    ], this.getFilterOptionsAndQuestions);
  }

  componentWillUnmount() {
    this.props.saveFilters(this.state.filters);
  }

  getFilterOptionsAndQuestions = () => {
    this.getFilterOptions();
    this.getQuestions();
  };

  saveQuestions = (response) => {
    const questions = response.data.results;
    const totalPages = response.data.num_pages;

    const timer = moment();
    this.setState({
      questions,
      totalPages,
      timer,
      loading: false,
      totalAnswers: response.data.count,
      displaySpinner: false
    });
  };

  savePreloadQuestions = (response, prev) => {
    const questions = response.data.results;

    if (prev) {
      this.setState({prevQuestions: questions, displaySpinner: false});
    } else {
      this.setState({nextQuestions: questions, displaySpinner: false});
    }
  };

  getQuestions = (page = this.state.filters.page) => {
    if (!this.props.slug) {
      return;
    }

    // fix -> parseFilter return string, not int
    if (typeof page === "string") {
      page = isNaN(page) ? 1 : parseInt(page);

      const filters = this.state.filters;
      filters.page = page;
      this.setState({filters});
    }

    const params = {...this.state.filters};
    params.page = page;

    if (page === this.state.filters.page) {
      this.setState({loading: true});
    }

    // throw error if derived class not specify getQuestionsUrl
    if (!this.getQuestionsUrl) {
      throw new Error("Please specify getQuestionsUrl in constructor!");
    }
    this.setState({displaySpinner: true});
    ajax.get([this.getQuestionsUrl, this.props.slug], {params})
      .then((response) => {
        // current questions
        if (page === this.state.filters.page) {
          this.saveQuestions(response);

          // load previous page
          if (page > 1) {
            this.getQuestions(page - 1);
          }

          // load next page
          const totalPages = response.data.num_pages;
          if (page < totalPages) {
            this.getQuestions(page + 1);
          }
        }
        // previous page questions
        if (page === this.state.filters.page - 1) {
          this.savePreloadQuestions(response, true);
        }
        // next page questions
        if (page === this.state.filters.page + 1) {
          this.savePreloadQuestions(response, false);
        }
      })
      .catch(() => {
        toastr.error("Page doesn't exist");
        this.setState({loading: false, displaySpinner: false});
      });
  };

  createOptions = (data, attribute) => {
    const options = _.map(data, (option) => {
      return {
        key: option[attribute],
        text: option[attribute],
        value: option[attribute]
      };
    });
    options.unshift({
      key: "All",
      text: "All",
      value: null
    });
    return options;
  };

  /**
   * Get options for filters.
   */
  getFilterOptions = () => {
    // Method example.
    // ajax.get([config.TASK_FILTER_OPTIONS, this.props.slug]).then((response) => {
    //   const userOptions = this.createOptions(response.data.user, 'username');
    //
    //   let possibleFilterValues = {
    //     ...this.state.possibleFilterValues,
    //     user: userOptions,
    //   };
    //
    //   this.setState({possibleFilterValues});
    // })

    throw new Error("Please implement getFilterOptions() in this component!");
  };

  /**
   * Add answer to question.asnwers or replace current with widgetVals.
   * @param questionIdx
   * @param newAnswerIdx
   * @param widgetVals
   */
  addAnswer(questionIdx, newAnswerIdx, widgetVals, isEvaluation) {
    const path = isEvaluation ? "evaluations" : "answers";
    const idx = _.findIndex(this.state.questions[questionIdx][path], {user: this.props.username});

    const questions = _.cloneDeep(this.state.questions);

    // first answer for this question -> add new answer
    if (idx !== -1) {
      questions[questionIdx][path][idx].id = newAnswerIdx;
      questions[questionIdx][path][idx].content = widgetVals;
    } else {
      // replace answer
      questions[questionIdx][path].push({
        id: newAnswerIdx,
        user: this.props.username,
        content: widgetVals,
        timestamp: moment(),
      });
    }

    return questions;
  }

  onFiltersSubmit = () => {
    this.setState({filterOpened: false});
    this.updateFilters({page: 1}, this.getQuestions);
  };

  resetFilters = () => {
    const {filters} = this.state;
    const clearedFilters = _.mapValues(filters, () => []);
    if ("page" in filters) {
      clearedFilters["page"] = 1;
      clearedFilters["page_size"] = this.props.questionsAtPage;
    }
    this.updateFilters(clearedFilters, this.onFiltersSubmit);
  }

  renderFilters = () => {
    throw new Error("Please implement renderFilters() in this component!");
  };

  onPageSizeChange = (event, data) => {
    if (data.value.length > 0 && parseInt(data.value) < 1) {
      return;
    }
    const filters = {
      ...this.state.filters,
      page_size: data.value
    };

    this.setState({filters});
  };

  // when user press Enter -> change url value and load questions
  onPageSizeAccept = (event) => {
    if (event.key === "Enter") {
      this.updateFilters({page_size: this.state.filters.page_size}, this.getQuestions);
    }
  };

  renderOptionsPanel = () => {
    const orderOptions = [
      {key: "-date", value: "-date", text: "Newest"},
      {key: "+date", value: "date", text: "Oldest"},
      {key: "id", value: "id", text: "Id"},
    ];

    return (
      <Grid className="options-panel" centered>
        <Grid.Row className="options-panel_header">
          <Grid.Column width={2} textAlign="center">
            Page size:
          </Grid.Column>
          <Grid.Column width={2} textAlign="center">
            {this.orderBy && "Order by:"}
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            Total: {this.state.totalAnswers}
          </Grid.Column>
          <Grid.Column width={4} textAlign="center"/>
        </Grid.Row>
        <Grid.Row className="content">
          <Grid.Column width={2} textAlign="center">
            <Input
              type="number" fluid
              value={this.state.filters.page_size}
              onChange={this.onPageSizeChange}
              onKeyPress={this.onPageSizeAccept}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign="center">
            {this.orderBy &&
            <Dropdown
              search selection fluid
              options={orderOptions}
              defaultValue={orderOptions[0].value}
              onChange={(e, data) => this.updateFilters({order_by: data.value}, this.getQuestions)}
            />
            }
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <PaginationCompact
              activePage={parseInt(this.state.filters.page)}
              onPageChange={(activePage) => this.onPageChange(activePage)}
              totalPages={this.state.totalPages}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <Popup
              on="click" position="bottom center"
              style={{padding: 0}}
              trigger={
                <Button size="mini" floated="right" className="datahive-button" color="dark-grey"
                  onClick={() => {
                    this.setState({filterOpened: !this.state.filterOpened});
                  }}>
                  Filter
                  <Icon style={{paddingLeft: "1rem"}} name="chevron down"/>
                </Button>
              }
              open={this.state.filterOpened}
              onClose={(e) => {
                if (!e.target.matches(".advanced-filter, .advanced-filter *")) {
                  this.setState({filterOpened: false});
                }
              }}
            >{this.renderFilters()}</Popup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderFooter = () => {
    return (
      <Grid className={"task-questions-footer"} centered>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <PaginationCompact
              activePage={parseInt(this.state.filters.page)}
              onPageChange={(activePage) => this.onPageChange(activePage)}
              totalPages={this.state.totalPages}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  /**
   * Special function to deal with preloaded questions.
   * @param page
   */
  onPageChange = (page) => {
    // wrong page number -> do nothing
    if (page < 1 || page > this.state.totalPages) {
      return;
    }

    let questions = this.state.questions,
      prevQuestions = [],
      nextQuestions = [];

    if (page === this.state.filters.page - 1) {
      questions = _.cloneDeep(this.state.prevQuestions);
      nextQuestions = _.cloneDeep(this.state.questions);
      if (page > 1) {
        this.getQuestions(page - 1);
      }
    } else if (page === this.state.filters.page + 1) {
      questions = _.cloneDeep(this.state.nextQuestions);
      prevQuestions = _.cloneDeep(this.state.questions);
      if (page < this.state.totalPages) {
        this.getQuestions(page + 1);
      }
    } else {
      window.scrollTo(0, 0);
      this.updateFilters({page}, this.getQuestions);
      return;
    }

    this.updateFilters({page});
    if (questions.length === 0) {
      return;
    }

    window.scrollTo(0, 0);
    const timer = moment();
    this.setState({
      questions,
      prevQuestions,
      nextQuestions,
      timer,
      loading: false,
    });
  };

  render() {
    return;

    // Example method implementation.
    // const questions = this.state.questions;
    //
    // return (
    //   <div className="tab-panel TaskDone">
    //     {this.renderOptionsPanel()}
    //     <QuestionsSet
    //       questions={questions}
    //       config={this.props.config}
    //       onSubmit={this.onSubmit}
    //       showReview={true}
    //       editReview={this.props.editReview || false}
    //       onReviewSubmit={this.onReviewSubmit}
    //       onReviewAccept={this.onReviewAccept}
    //     />
    //     {this.renderFooter()}
    //   </div>
    // )
  }
}

export default BaseTaskQuestions;
