export const REPORT_TYPE_LANGTASK = "LANGTASKS";
export const REPORT_TYPE_OTHER = "OTHER";

export const TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED = "NOT_ACCEPTED";
export const TASK_MONTH_REPORT_STATUS_AUTO_ACCEPTED = "AUTO_ACCEPTED";
export const TASK_MONTH_REPORT_STATUS_PRE_ACCEPT = "PRE_ACCEPT";
export const TASK_MONTH_REPORT_STATUS_ACCEPTED = "ACCEPTED";
export const TASK_MONTH_REPORT_STATUS_CLOSED = "CLOSED";

export const TASK_MONTH_REPORT_STATUSES = {
  TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED: TASK_MONTH_REPORT_STATUS_NOT_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_AUTO_ACCEPTED: TASK_MONTH_REPORT_STATUS_AUTO_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_PRE_ACCEPT: TASK_MONTH_REPORT_STATUS_PRE_ACCEPT,
  TASK_MONTH_REPORT_STATUS_ACCEPTED: TASK_MONTH_REPORT_STATUS_ACCEPTED,
  TASK_MONTH_REPORT_STATUS_CLOSED: TASK_MONTH_REPORT_STATUS_CLOSED,
};

export const LINGUIST_MONTH_REPORT_STATUS_PENDING = "PENDING";
export const LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT = "PRE_ACCEPT";
export const LINGUIST_MONTH_REPORT_STATUS_ACCEPTED = "ACCEPTED";

export const LINGUIST_MONTH_REPORT_STATUSES = {
  LINGUIST_MONTH_REPORT_STATUS_PENDING: LINGUIST_MONTH_REPORT_STATUS_PENDING,
  LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT: LINGUIST_MONTH_REPORT_STATUS_PRE_ACCEPT,
  LINGUIST_MONTH_REPORT_STATUS_ACCEPTED: LINGUIST_MONTH_REPORT_STATUS_ACCEPTED,
};

