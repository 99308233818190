import _ from "lodash";
import moment from "moment";

import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {DateInput} from "semantic-ui-calendar-react";
import {Container, Form, Header, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import urls from "../../config/frontend_urls";
import FormErrors from "../FormErrors";
import Stars from "../../components/simple/Stars/Stars";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";

import "./Rating.css";


export class Rating extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    errorsNew: PropTypes.func.isRequired,
    errorsClear: PropTypes.func.isRequired,
    auth: PropTypes.shape({
      username: PropTypes.string
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.emptyReview = {
      rating: 1,
      linguist: null,
      linguistic: "",
      other: "",
      title: "",
      creation_date: moment().format("DD-MM-YYYY"),
    };

    this.state = {
      userDetails: null,
      taskDetails: null,
      review: {...this.emptyReview},
      errors: {},
    };

    this.getDetails = this.getDetails.bind(this);
    this.setDetails = this.setDetails.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.getDataForNew = this.getDataForNew.bind(this);
    this.getDataForExisting = this.getDataForExisting.bind(this);
    this.getTaskAndUser = this.getTaskAndUser.bind(this);
    this.getData = this.getData.bind(this);
    this.roundRating = this.roundRating.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
  }

  getTask = (taskSlug) => {
    ajax.get([config.ALL_TASKS_DETAIL, taskSlug])
      .then((response) => {
        const review = {...this.state.review};
        review.task = response.data.pk;
        this.setState({taskDetails: response.data, review: review});
      })
      .catch((error) => {
        this.setState({review: {...this.emptyReview}});
        this.showErrorForm(error);
      });
  };

  getUser = (username) => {
    ajax.get([config.USER_DETAILS, username])
      .then((response) => {
        const review = {...this.state.review};
        review.linguist = response.data.id;
        this.setState({userDetails: response.data, review: review});
      })
      .catch((error) => {
        this.setState({review: {...this.emptyReview}});
        this.showErrorForm(error);
      });
  };

  getTaskAndUser(taskSlug, username) {
    if (taskSlug) {
      this.getTask(taskSlug);
    }
    if (username) {
      this.getUser(username);
    }
  }

  getDataForNew(taskSlug, username) {
    this.getTaskAndUser(taskSlug, username);
  }

  getDataForExisting(ratingId) {
    ajax.get([config.LINGUIST_REVIEWS_ITEM, ratingId])
      .then((response) => {
        this.setState({review: response.data, taskDetails: null});
        this.getTaskAndUser(response.data.task_slug, response.data.linguist_username);
      })
      .catch((error) => {
        this.props.errorsNew(error.response.data);
        this.setState({review: {...this.emptyReview}});
      });
  }

  getData(taskSlug, username, ratingId) {
    if (taskSlug && username) {
      this.getDataForNew(taskSlug, username);
      this.setState({review: {...this.emptyReview}});
    }
    if (username) {
      this.getUser(username);
      this.setState({review: {...this.emptyReview}});
    }
    if (ratingId) {
      this.getDataForExisting(ratingId);
    }
  }

  componentDidMount() {
    this.getData(
      this.props.match.params.taskSlug,
      this.props.match.params.username,
      this.props.match.params.ratingId
    );

    ajax.get(config.TASKS_ALL)
      .then((response) => {
        if (response.status === 200) {
          this.setState({tasks: response.data});
        }
      })
      .catch(this.showErrorForm);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.ratingId && this.props.match.params.ratingId !== prevProps.match.params.ratingId) {
      this.getDataForExisting(this.props.match.params.ratingId);
    }

    // If new to new rating from a different path, set review to empty.
    if (this.props.match.path === urls.NEW_RATING && this.props.match.path !== prevProps.match.path) {
      this.setState({review: this.emptyReview});
    }

    // If new to new rating from a different path, set review to empty.
    if (this.props.match.path === urls.NEW_RATING_NO_SLUG && this.state.taskDetails !== null) {
      this.setState({taskDetails: null});
    }

    //If task slug changed, get task for the new slug
    if (this.props.match.params.taskSlug && this.props.match.params.taskSlug !== prevProps.match.params.taskSlug) {
      this.getTask(this.props.match.params.taskSlug);
    }
  }

  getUserProfileUrl = () => {
    return urls.USER_PROFILE.replace(":username", this.state.userDetails.username)
      .replace(":tabName?", "ratings");
  };

  saveDetails() {
    this.props.errorsClear();

    const data = {...this.state.review};
    if (this.state.taskDetails) {
      data.task = this.state.taskDetails.pk;
    }
    data.linguist = this.state.userDetails.id;
    ajax.post(
      config.POST_LINGUIST_REVIEWS,
      {data}
    ).then((response) => {

      if (response.status === 201) {
        toastr.success("Success", "Rating added!");
        const url = this.getUserProfileUrl();
        this.props.history.push(url);
      }
    }).catch(this.showErrorForm);
  }

  updateDetails() {
    this.props.errorsClear();
    ajax.patch(
      [config.LINGUIST_REVIEWS_ITEM, this.state.review.id],
      {data: this.state.review}
    ).then((response) => {
      if (response.status === 200) {
        toastr.success("Success", "Rating updated!");
        const url = this.getUserProfileUrl();
        this.props.history.push(url);
      }
    }).catch(this.showErrorForm);
  }

  getDetails(path) {
    //returns field from userDetails object. Short name because its a convenience function
    return _.get(this.state.review, path) || "";
  }

  setDetails(path, value) {
    const review = {...this.state.review};
    _.set(review, path, value);
    this.setState({
      review: review
    });
  }

  showErrorForm = (error) => {
    this.props.errorsNew(error.response.data);
  };

  roundRating(r) {
    if (!r) {
      return 0;
    }
    return Math.round(r * 2) / 2;
  }

  handleRatingChange(e) {
    let val = this.roundRating(parseFloat(e.target.value));
    if (val > 6) {
      val = 6;
    }
    this.setDetails("rating", val);
  }

  handleTaskChange = (e, {value}) => {
    if (value === "") {
      this.clearTask(e, {value});
      return;
    }

    ajax.get(config.LINGUIST_SEARCH,
      {params: {username: this.state.userDetails.username, task: value}})
      .then((response) => {
        const url = urls.NEW_RATING.replace(":username", this.state.userDetails.username).replace(":taskSlug", value);
        this.handleTaskChangeResponse(response, url);
      })
      .catch(this.showErrorForm);
  };

  handleTaskChangeResponse = (response, url) => {
    if (response.status === 200) {
      //if no ratings for the selected task exist, redirect to new rating page
      if (!!response.data.id) {
        url = urls.RATING.replace(":ratingId", response.data.id);
      } else {
        if (this.state.review.id) {
          this.setState({review: this.emptyReview});
        }
      }
      this.props.history.push(url);
    }
  };

  renderHeader = () => {
    let headerContent = null;
    if (this.state.userDetails && this.state.taskDetails && this.state.review.id) {
      headerContent = (
        <span>{this.state.userDetails.username} - {this.state.taskDetails.title} <br/>
          author: {this.state.review.author_username}</span>
      );
    }

    if (this.state.userDetails && this.state.taskDetails) {
      headerContent = (
        <span>{this.state.userDetails.username} - {this.state.taskDetails.title}</span>
      );
    }
    if (this.state.userDetails) {
      headerContent = (
        <span>{this.state.userDetails.username}</span>
      );
    }

    return (
      <Header size="large">
        <Link to={this.state.userDetails ? this.getUserProfileUrl() : ""}>
          <Icon name={"arrow left"}/>
        </Link>
        {headerContent}
      </Header>
    );
  };

  clearTask = () => {
    ajax.get(config.LINGUIST_SEARCH,
      {params: {username: this.state.userDetails.username, task_null: 1}})
      .then((response) => {
        const url = urls.NEW_RATING_NO_SLUG.replace(":username", this.state.userDetails.username);
        this.handleTaskChangeResponse(response, url);
      })
      .catch(this.showErrorForm);
  };

  deleteRating = () => {
    if (!window.confirm("Are you sure you want to delete this review?")) {
      return;
    }
    ajax.delete([config.LINGUIST_REVIEWS_ITEM, this.state.review.id])
      .then((response) => {
        if (response.status === 204) {
          toastr.success("Success", "Rating deleted!");
          this.props.history.goBack();
        }
      })
      .catch(this.showErrorForm);
  };

  renderActionButtons = () => {
    if (this.state.review.id && this.state.review.author_username !== this.props.auth.username) {
      return null;
    }
    if (!this.state.review.id) {
      return (
        <Form.Button label="&nbsp;" width={3} color="green" content="Add Rating" onClick={this.saveDetails}/>
      );
    }
    if (this.state.review.id) {
      return (
        <React.Fragment>
          <Form.Button
            label="&nbsp;" width={3} color="green" content="Update Rating"
            onClick={this.updateDetails}
          />
          <Form.Button
            label="&nbsp;" width={3} color="red" content="Delete Rating"
            onClick={this.deleteRating}
          />
        </React.Fragment>
      );
    }
  };

  render() {
    const paths = {
      linguistic: "linguistic",
      other: "other",
      rating: "rating",
      title: "title",
      date: "creation_date",
    };

    const inputArgs = _.mapValues(paths, (obj) => {
      return {
        value: this.getDetails(obj),
        error: !!_.get(this.state.errors, obj),
        onChange: ((e) => this.setDetails(obj, e.target.value)),
      };
    });

    const userCanEdit = !(this.state.review.id && this.state.review.author_username !== this.props.auth.username);
    return (
      <Container className="Rating">
        {this.renderHeader()}
        <Form>
          <FormErrors/>
          <Form.Group>
            <FormDropdown
              label="Task"
              width={6}
              selection
              clearable
              search
              className={"task-dropdown"}
              placeholder={"-----"}
              value={_.get(this, "state.taskDetails.slug") || ""}
              options={_.map(this.state.tasks, (task) => {
                return {key: task.slug, value: task.slug, text: task.title};
              })}
              onChange={this.handleTaskChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Title" width={12}
              maxLength={200}
              {...inputArgs.title}
            />
            <Form.Field width={4}>
              <label>Date</label>
              <DateInput
                closable
                placeholder="Date"
                iconPosition="left"
                {...inputArgs.date}
                onChange={(e, {value}) => {
                  this.setState({
                    review: {
                      ...this.state.review,
                      creation_date: !!value ? value : null,
                    }
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.TextArea
              label="Linguistic" width={8}
              {...inputArgs.linguistic}
            />
            <Form.TextArea
              label="Other" width={8}
              {...inputArgs.other}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field width={2}>
              <label>Add stars</label>
              <Form.Checkbox
                disabled={!userCanEdit}
                width={3} toggle
                checked={!!this.state.review.rating}
                onChange={(e, {checked}) =>
                  this.setState({
                    review: {
                      ...this.state.review,
                      rating: checked ? 1 : null,
                    }
                  })}
              />
            </Form.Field>

            <Form.Input
              disabled={!userCanEdit}
              type="number" label="Rating" width={4} max={6} min={1} placeholder={0} step={0.5}
              value={this.getDetails(paths.rating)}
              error={!!_.get(this.state.errors, paths.rating)}
              onChange={this.handleRatingChange}
            />

            <Form.Field width={10}>
              <label>&nbsp;</label>
              <Stars count={parseFloat(this.state.review.rating)} size="big" max={6} showEmpty/>
            </Form.Field>
            <div className="four wide field"/>
            {this.renderActionButtons()}
          </Form.Group>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(Rating));
