import React, {Component} from "react";
import {Button, Checkbox} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import ModalConfirm from "./ModalConfirm";
import {confirmAccessToUsersData} from "../actions/actions_auth";


class ModalAccessToUsersData extends Component {
  static propTypes = {
    auth: PropTypes.object,
    confirmAccessToUsersData: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  render() {
    const renderModalEnable = !!this.props.auth.has_user_data_access
      && !this.props.auth.confirmed_access_to_users_data;

    if (!renderModalEnable) {
      return null;
    }

    const title = "Confirm access to work with users data.";
    const content = <Checkbox
      checked={this.state.isChecked}
      onChange={(e, {checked}) => {
        this.setState({isChecked: checked});
      }}
      label="I have permission to work with users data"
    />;

    const button = <Button
      disabled={!this.state.isChecked}
      color={"green"}
      onClick={this.props.confirmAccessToUsersData}
    >
      Confirm
    </Button>;

    return (
      <ModalConfirm
        hideCloseIcon
        title={title}
        content={content}
        button={button}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {confirmAccessToUsersData})(ModalAccessToUsersData);
