import moment from "moment";
import _ from "lodash";
import React from "react";
import {toastr} from "react-redux-toastr";

import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import LoaderBlocker from "../../../components/LoaderBlocker";

import "./TaskNew.css";
import EvaluationsSet from "../EvaluationsSet";
import TaskNew from "./TaskNew";

export default class TaskEvaluationNew extends TaskNew {
  constructor(props) {
    super(props);
    this.getNewUrl = config.TASK_GET_NEW_EVALUATIONS;
    this.saveUrl = config.TASK_SAVE_EVALUATION;
  }

  getPreloadId = (question) => {
    return question.answers[0].id;
  }

  onSubmit = (questionIdx, evaluationVals, answer, skip=false) => {
    // skip is unused in evaluations at the moment.
    this.setState({processedSubmit: questionIdx});
    let data = _.cloneDeep(evaluationVals);
    if (skip) {
      data = {
        skip: true
      };
    }
    data["time_taken"] = moment() - this.state.timer;
    if (_.get(this.state.questions[questionIdx], "fix_answer")) {
      data["fix_answer"] = true;
    }

    ajax.post([this.saveUrl, this.props.slug, answer.id], {data})
      .then((response) => {
        // remove answered question
        let questions = _.cloneDeep(this.state.questions);
        let callback = null;
        const timer = moment();

        // delete answered question
        if (this.state.deleteAnswered) {
          questions.splice(questionIdx, 1);
        } else {
          // mark question as answered
          questions[questionIdx].isAnswered = true;
          // Add answer_id
          questions[questionIdx].answer_id = response.data.answer_id;
          // Keep answer data if user want fix his previous answer
          questions[questionIdx]._data = data;
          // Hide button 'Fix answer'
          questions[questionIdx].fix_answer = false;
        }

        // if all questions removed or answered then add from preload and preload another
        if (!questions.length || this.countAnsweredQuestions(questions) === questions.length) {
          questions = _.cloneDeep(this.state.preloadedQuestions);
          callback = this.getPreloadedQuestions;
          window.scrollTo(0, 0);
        }

        this.props.onIncreaseAnswered();
        this.setState({questions, timer, processedSubmit: null}, callback);
      })
      .catch((error) => {
        this.setState({processedSubmit: null});
        toastr.error("Error", _.get(error, "response.data.detail") || error.message);
      });
  };


  render() {
    const questions = this.state.questions;

    if (this.props.blocked) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>You have been blocked from answering!</h2>
          </div>
        </div>
      );
    }

    if (!this.props.confirmed) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>Please accept the tutorial.</h2>
          </div>
        </div>
      );
    }

    // display spinner if questions aren't loaded yet
    if (this.state.loading) {
      return (
        <div className="tab-panel TaskNew">
          <div style={{height: "500px"}}>
            <LoaderBlocker show={true}/>
          </div>
        </div>
      );
    }

    // display no more answers
    if (!questions.length && this.props.allQuestionsAnswered) {
      /* eslint-disable react/no-unescaped-entities */
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>You can't answer more questions!</h2>
          </div>
        </div>
      );
      /* eslint-enable react/no-unescaped-entities */
    }

    // display no more answers
    if (!questions.length) {
      return (
        <div className="tab-panel TaskNew">
          <div className="Done">
            <h2>There are no more questions.</h2>
          </div>
        </div>
      );
    }
    const config = _.cloneDeep(this.props.config);
    config.structure = config.structure.splice(0, config.structure.length-1);
    const config_evaluation = _.cloneDeep(this.props.config_evaluation);

    return (
      <div className="tab-panel TaskNew">
        <EvaluationsSet
          questions={questions}
          config={config}
          config_evaluation={config_evaluation}
          onSubmit={this.onSubmit}
          isModerator={this.props.isModerator}
          onSkip={(questionIdx, widgetVals) => {
            this.onSubmit(questionIdx, widgetVals, true);
          }}
          onFixAnswer={(questionIdx) => {
            this.onFixAnswer(questionIdx);
          }}
        />
      </div>
    );
  }
}
