import _ from "lodash";
import {useState} from "react";

const useSegmentsState = (init, activePageIndex, selectedSegment) => {
  const [segments, setSegments] = useState(init);

  const handleSegmentsUpdateUtil = (modifier) => {
    setSegments((segments) => {
      const newSegments = _.cloneDeep(segments);
      const activeImage = newSegments[activePageIndex];
      const activeSegment = activeImage[selectedSegment.idx];
      modifier(activeImage, activeSegment);
      return newSegments;
    });
  };

  return [segments, handleSegmentsUpdateUtil];
};

export default useSegmentsState;
