/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {Container} from "semantic-ui-react";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import "./OrderListWidget.css";
import Widget from "../Widget";


class OrderListWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  static getDefaultConfig = () => {
    return {
      randomize: true,
      align: "align-left",
    };
  };

  componentDidMount() {
    const config = this.props.widgetConfig;

    if (!this.props.value || !this.props.value.initial) {
      const items = _.get(this.props.widgetVals, `[${this.props.componentId}].items`, []);

      let initial = _.map(items, (emoji, idx) => idx);
      if (config.randomize) {
        initial = _.shuffle(initial);
      }
      const widgetVals = this.setValue("initial", initial);

      this.registerEmptyValid();

      const widgetVal = widgetVals[this.props.componentId];
      this.update(widgetVal);
    }
  }

  isEmpty = () => {
    return false;
  };

  isValid = () => {
    return true;
  };

  setValue = (field, value) => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    if (!widgetVal) {
      widgetVals[this.props.componentId] = {[field]: value};
    } else {
      _.set(widgetVal, `${field}`, value);
    }
    return widgetVals;
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    let newOrderedList = [];
    if (this.props.value && this.props.value.orderedlist) {
      newOrderedList = arrayMove(_.cloneDeep(this.props.value.orderedlist), oldIndex, newIndex);
    } else {
      newOrderedList = arrayMove(_.cloneDeep(this.props.value.initial), oldIndex, newIndex);
    }

    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    if (!widgetVal) {
      widgetVals[this.props.componentId] = {orderedlist: newOrderedList};
    } else {
      widgetVal.orderedlist = newOrderedList;
    }

    this.update(widgetVal);
  };


  render() {
    const config = this.props.widgetConfig;

    const customClasses = `${config.align}`;
    let listInOrder = [];

    if (_.get(this.props, "value.orderedlist")) {
      listInOrder = _.map(this.props.value.orderedlist, (idx) => this.props.value.items[idx]);
    } else if (_.get(this.props, "value.initial")) {
      listInOrder = _.map(this.props.value.initial, (idx) => this.props.value.items[idx]);
    }

    const SortableItem = SortableElement(({value}) =>
      <li className={"dragged-list-element"}>{value}</li>
    );

    const SortableList = SortableContainer(({items}) => {
      return (
        <ul className={"dragged-list-element"}>
          {items.map((value, index) => (
            <SortableItem lockAxis={"y"} helperClass={"dragged-list-element"} key={`item-${index}`} index={index}
              value={value}/>
          ))}
        </ul>
      );
    });

    return (
      <Container className={`OrderListWidget Widget font-face ${customClasses}`}>
        <SortableList items={listInOrder} onSortEnd={this.onSortEnd}/>
      </Container>
    );
  }
}

export default OrderListWidget;
