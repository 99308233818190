import _ from "lodash";
import {useEffect} from "react";
import {toastr} from "react-redux-toastr";

const useRegisterValid = (segments, registerValid, componentId) =>
  useEffect(() => {
    function isValid() {
      const allSegments = _.flatten(segments);
      let isValid = true;
      allSegments.forEach((seg) => (seg.isEdit || !seg.isDone) && (isValid = false));
      if (!isValid) {
        toastr.error("Invalid Polygons", "You must save or finish your polygons before you submit");
      }
      return isValid;
    }

    if (registerValid) {
      registerValid(isValid, componentId);
    }
  }, [segments, registerValid, componentId]);

export default useRegisterValid;
