import React, {Component} from "react";
import {Modal} from "semantic-ui-react";
import Movable from "./ContainerWidget/Movable";
import WidgetId from "./WidgetId";
import PropTypes from "prop-types";

export default class ModalWidget extends Component {
  static propTypes = {
    editorProps: PropTypes.object,
    componentProps: PropTypes.shape({
      componentId: PropTypes.string,
      widgetConfig: PropTypes.shape({
        componentType: PropTypes.string
      })
    }),
    editor: PropTypes.func,
    component: PropTypes.func,
    onCopy: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.bool
    ]),
    idx: PropTypes.number.isRequired,
    onDrop: PropTypes.func,
    openPopup: PropTypes.func,
    open: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const editorProps = {
      ...props.editorProps,
      widgetId: props.componentProps.componentId,
    };

    props.editor.getDefaultVals(editorProps);
  }

  render() {
    const componentType = this.props.componentProps.widgetConfig.componentType;
    const componentId = this.props.componentProps.componentId;

    const editorProps = {
      ...this.props.editorProps,
      widgetId: componentId,
    };

    const editor = React.createElement(this.props.editor, editorProps);

    // create component
    const componentProps = {
      ...this.props.componentProps,
      key: componentId,
      editorMode: true,
    };

    var component = React.createElement(this.props.component, componentProps);
    component = (
      <React.Fragment>
        <WidgetId
          componentId={componentProps.componentId}
          onCopy={this.props.onCopy}
          onSelect={this.props.onSelect}
          onDelete={this.props.onDelete}
          selected={this.props.selected}/>
        {component}
      </React.Fragment>
    );

    const header = this.props.editor.header || componentType;

    let draggable = true;
    if (["SubmitWidget"].includes(componentType)) {
      draggable = false;
    }
    var movable = (
      <Movable
        key={"popup" + this.props.idx.toString()}
        draggable={draggable}
        onDrop={this.props.onDrop}
        component={component}
        componentType={componentType}
        componentId={componentId}
      />
    );

    const trigger = (
      <div
        onClick={(e) => {
          e.stopPropagation();
          this.props.openPopup(e, componentId);
        }}
      >
        {movable}
      </div>
    );

    return (
      <Modal
        className="EditorWindow"
        header={header}
        key={"popup" + this.props.idx.toString()}
        trigger={trigger}
        content={
          <Modal.Content>
            {editor}
          </Modal.Content>}
        open={this.props.open}
      />
    );
  }
}
