/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";

import React from "react";
import {Container, Grid, Header, Image} from "semantic-ui-react";
import "./GooglePlayWidget.css";
import Slider from "react-slick";
import Widget from "../Widget";

class GooglePlayWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  static getDefaultConfig = () => {
    return {};
  };

  constructor(props) {
    super(props);

    // set values for editor
    if (props.editorProps) {
      this.createEditor(props);
    }
  }

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId] || {};

    this.registerEmptyValid();
    this.update(widgetVal);
  }

  isEmpty = () => {
    return false;
  };

  isValid = () => {
    return true;
  };

  renderHeader() {
    const fullValue = this.props.value;
    const icon = fullValue.icon;
    const developer = fullValue.developer;
    const genre = fullValue.genre;
    const contentRating = fullValue.contentRating;
    const title = fullValue.title;

    return (
      <Grid.Row>
        <Grid.Column width={4} className="column-icon">
          <Image className="cover-image"
            src={icon}
          />
        </Grid.Column>
        <Grid.Column className="column-title" width={10}>
          <Grid>
            <Grid.Row>
              <Header as="h1">{title}</Header>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column className="column-nomargin">
                <b>{developer}</b>
              </Grid.Column>
              <Grid.Column className="column-nomargin">
                <b>{genre}</b>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <b>{contentRating}</b>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderGallery() {
    const fullValue = this.props.value;
    const screenshots = fullValue.screenshots;

    const settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      pauseOnFocus: true,
      initialSlide: 0,
      arrows: false,
    };

    /* eslint-disable-next-line no-unused-vars */
    const slides = _.map(screenshots, (img, idx) => {
      return (
        <div key={_.uniqueId()}>
          <img className="screenshot" src={img} alt={""}/>
        </div>
      );
    });

    const video = fullValue.video ? (
      <div>
        <iframe title={"video"} className="video" src={fullValue.video}/>
      </div>
    ) : "";

    return (
      <Slider {...settings}>
        {slides}
        {video}
      </Slider>
    );
  }

  renderContent() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.props.value.descriptionHTML}}/>
    );
  }

  render() {
    if (!this.props.value) {
      return null;
    }

    const component = (
      <Container className="GooglePlayWidget Widget">
        <Grid>
          {this.renderHeader()}
        </Grid>
        {this.renderGallery()}
        {this.renderContent()}
      </Container>
    );

    return (
      component
    );
  }
}

export default GooglePlayWidget;
