import React, {useEffect, useState} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown/LevenDropdown";

const InfiniteDropdown = (props) => {
  const [chunkCount, setChunkCount] = useState(1);
  const [allOptions, setAllOptions] = useState(true);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  // Slice options if props have changed.
  useEffect(() => {
    setSelected([]);
    setChunkCount(1);
    setAllOptions(!props.chunkSize);
    sliceUserOptions();
  }, [props.choices]);
  // Slice options when chunk number has changed.
  useEffect(() => sliceUserOptions(), [chunkCount]);
  // Slice options when allOptions should be displayed.
  useEffect(() => sliceUserOptions(), [allOptions]);

  const sliceUserOptions = () => {
    let newOptions = _.cloneDeep(props.choices);
    if (!allOptions) {
      const chunkedOptions = newOptions.splice(0, props.chunkSize * chunkCount);
      if (props.multiple) {
        newOptions = [
          ...chunkedOptions.filter((item1) =>
            !selected.find((item2) => item1.key === item2.key)
          ),
          ...selected
        ];
      } else {
        newOptions = [
          ...chunkedOptions.filter((item) =>
            selected.key !== item.key
          )
        ];
        if (!_.isEqual(selected, [])) {
          newOptions.push(selected);
        }
      }
    }
    setOptions(newOptions);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setChunkCount(chunkCount + 1);
    }
  };

  return (
    <Dropdown
      fluid={props.fluid || false}
      search={props.search || false}
      multiple={props.multiple || false}
      selection={props.selection || false}
      placeholder={props.placeholder || ""}
      options={options}
      value={props.value}
      onScroll={handleScroll}
      onSearchChange={(e, {searchQuery}) =>
        setAllOptions(searchQuery !== "")
      }
      onChange={(e, data) => {
        let newSelected;
        if (props.multiple) {
          newSelected = _.map(data.value, (option) => {
            return {
              "key": option,
              "text": option,
              "value": option
            };
          });
        } else {
          newSelected = _.find(props.choices, (option) => option.value === data.value);
        }

        setSelected(newSelected);
        props.onChange && props.onChange(e, data);
        setChunkCount(1);
        setAllOptions(false);
      }}
    />
  );
};

InfiniteDropdown.propTypes = {
  choices: PropTypes.array.isRequired,
  chunkSize: PropTypes.number,
  fluid: PropTypes.bool,
  search: PropTypes.bool,
  multiple: PropTypes.bool,
  selection: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType(
    [PropTypes.array, PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func
};

export default InfiniteDropdown;
