import React, {Component} from "react";
import {Bar} from "react-chartjs-2";
import {color_table, getCountChartData} from "../../helpers/utils";
import PropTypes from "prop-types";


export default class TaskStatisticsAnswersPerDay extends Component {
  static propTypes = {
    taskStatisticsAnswersPerDay: PropTypes.shape({
      answers: PropTypes.arrayOf(PropTypes.number),
      dates: PropTypes.arrayOf(PropTypes.string)
    })
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    if (this.props.taskStatisticsAnswersPerDay) {
      var data = this.props.taskStatisticsAnswersPerDay;
      var labels = data.dates;
      var answers = data.answers;

      var datasets = [
        {
          label: "Answers",
          color: color_table[1],
          data: answers,
        }
      ];

      const chart_data = getCountChartData(datasets, "Date", "Answers");
      return (<div>
        {!!this.props.taskStatisticsAnswersPerDay &&
        <Bar data={{labels: labels, datasets: chart_data[0]}} options={chart_data[1]}/>}
      </div>);
    }
  }
}
