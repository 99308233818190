import React from "react";
import _ from "lodash";
import { Form, Menu, Grid } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import PropTypes from "prop-types";
import moment from "moment";
import fs from "file-saver";

import { FormLevenDropdown as FormDropdown } from "../../../../components/simple/Dropdown/LevenDropdown";
import { getDateFormat } from "../../../../helpers/utils";
import ajax from "../../../../helpers/ajax";
import config from "../../../../config/config";

import {
  getUnresolvedReviews,
  handleCheckboxClick,
  handleSelectAll,
  onTaskChange,
  renderModal,
  resolveReviews
} from "./utils";

export default class TaskComponent extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    originalTask: PropTypes.object.isRequired,
    errors: PropTypes.oneOfType(
      [PropTypes.array,
        PropTypes.object]
    ),
    updateTask: PropTypes.func.isRequired,
    totalQuestions: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      checkedList: [],
      displayUnresolvedReviewsModal: false,
      isAllChecked: false,
      usernameOptions: [],
      currentUsernames: [],
      currentCompanies: [],
      filteredOut: [],
      newUsers: [],
      username_filter: null,
      company_filter: null,
      limit_from_filter: null,
      limit_to_filter: null,
      done_from_filter: null,
      done_to_filter: null,
      deadline_from_filter: null,
      deadline_to_filter: null,
      added_from_filter: null,
      added_to_filter: null
    };
    this.resolveReviews = resolveReviews.bind(this);
    this.renderModal = renderModal.bind(this);
    this.handleCheckboxClick = handleCheckboxClick.bind(this);
    this.handleSelectAll = handleSelectAll.bind(this);
    this.onTaskChange = onTaskChange.bind(this);
    this.getUnresolvedReviews = getUnresolvedReviews.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    var currentUsernames = [];
    var companies = [];
    var companyNames = [];
    var idx = 0;
    if (this.props.task[this.collectionName]) {
      Object.entries(this.props.task[this.collectionName]).forEach(function (ut) {
        currentUsernames.push({
          text: ut[1].username,
          value: ut[1].username,
          key: idx
        });
        companyNames.push(ut[1].company);
        idx += 1;
      });
      companyNames = [...new Set(companyNames)];
    }

    idx = 0;
    companyNames.forEach(function (company) {
      companies.push({
        text: company,
        value: company,
        key: idx
      });
      idx += 1;
    });

    this.setState({
      currentCompanies: companies, currentUsernames: currentUsernames.sort((a, b) => a.text.localeCompare(b.text)),
      filteredOut: currentUsernames.map((ut) => ut.value)
    });
  };

  onChange = (path, val) => {
    if (path.split(".")[2] === "username") {
      this.setState({ filteredOut: [...this.state.filteredOut, val], newUsers: [...this.state.newUsers, val] }, () => this.onChangeInner(path, val));
    } else {
      this.onChangeInner(path, val);
    }
  }

  onChangeInner = (coll_path, val) => {
    var path = coll_path;
    var filtered = this.getFilteredUsertasks();

    if (filtered.length != this.props.task[this.collectionName].length) {
      var found = filtered.find((ut, idx) => idx === parseInt(coll_path.split(".")[1]));
      // this maps index of filtered usretasks to the currnt usertasks
      path = found ? coll_path.split(".").map((cp, idx) => idx == 1 ?
        Object.entries(this.props.task[this.collectionName]).findIndex((v) => v[1].username === found.username) : cp).
        join(".") : coll_path.split(".").map((cp, idx) => idx == 1 ? this.props.task[this.collectionName].length : cp).join(".");
    }

    const changes = { [path]: val };

    if (this.props.originalTask[this.collectionName]) {
      const usertasks = this.props.originalTask[this.collectionName];
      const idx = parseInt(path.split(".")[1]);
      const initObj = idx < _.cloneDeep(usertasks.length) ? usertasks[idx] : {};
      // Convert date to another format for comparison.
      if (initObj.deadline) {
        const timezone = moment.tz.guess();
        initObj.deadline = moment.tz(initObj.deadline, timezone).utc().format("YYYY-MM-DD HH:mm:ssZ");
      }

      const newObj = idx < this.props.task[this.collectionName].length ?
        _.cloneDeep(this.props.task[this.collectionName][idx]) : {};
      _.set(newObj, path.split(".").pop(), val);
      delete newObj.is_modified;

      changes[`${this.collectionName}.${idx}.is_modified`] = !_.isEqual(initObj, newObj);
    }

    this.onTaskChange(changes);
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    const params = { slug: this.props.task.slug, collection: this.collectionName };
    ajax.get(
      config.TASK_EXPORT_USERTASKS,
      {
        params,
        responseType: "arraybuffer"
      }
    ).then((result) => {
      this.setState({ exportLoading: false });
      const blob = new Blob(
        [result.data],
        { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" }
      );
      fs.saveAs(blob, result.headers["x-filename"]);
    });
  };

  setModified = (idx) => {
    this.onTaskChange({ [`${this.collectionName}.${idx}.is_modified`]: true });
  };

  filterUsers = () => {
    let filter_return = [Object.entries(this.props.task[this.collectionName]), this.state.currentUsernames.map((ut) => ut.value)];

    filter_return = this.filterFunction(this.state.username_filter,
      filter_return[0], filter_return[1], "username", (a, b) => a === b);

    if (this.state.username_filter) {
      this.setState({ filteredOut: [...filter_return[1], ...this.state.newUsers] });
      return;
    }

    filter_return = this.filterFunction(this.state.company_filter,
      filter_return[0], filter_return[1], "company", (a, b) => a === b);

    filter_return = this.filterFunction(this.state.limit_from_filter,
      filter_return[0], filter_return[1], "max_questions", (a, b) => Number(a) >= b);

    filter_return = this.filterFunction(this.state.limit_to_filter,
      filter_return[0], filter_return[1], "max_questions", (a, b) => Number(a) <= b);

    filter_return = this.filterFunction(this.state.done_from_filter,
      filter_return[0], filter_return[1], "done", (a, b) => Number(a) >= b);

    filter_return = this.filterFunction(this.state.done_to_filter,
      filter_return[0], filter_return[1], "done", (a, b) => Number(a) <= b);

    filter_return = this.filterFunction(this.state.deadline_from_filter,
      filter_return[0], filter_return[1], "deadline", (a, b) => a ? moment(a).diff(b) >= 0 : false);

    filter_return = this.filterFunction(this.state.deadline_to_filter,
      filter_return[0], filter_return[1], "deadline", (a, b) => a ? moment(a).diff(b) <= 0 : false);

    filter_return = this.filterFunction(this.state.added_from_filter,
      filter_return[0], filter_return[1], "added", (a, b) => a ? moment(a).diff(b) >= 0 : false);

    filter_return = this.filterFunction(this.state.added_to_filter,
      filter_return[0], filter_return[1], "added", (a, b) => a ? moment(a).diff(b) <= 0 : false);

    this.setState({ filteredOut: [...filter_return[1], ...this.state.newUsers] });
  }

  filterFunction = (filter, usertasks, f_unames, ut_atribute, condition) => {
    var filtered_unames = [];
    var filtered = [];
    if (filter) {
      filtered = usertasks.filter((ut) => {
        if (condition(ut[1][ut_atribute], filter)) {
          filtered_unames.push(ut[1].username);
          return true;
        } else {
          return false;
        }
      });
      return [filtered, filtered_unames];
    } else {
      return [usertasks, f_unames];
    }
  }

  resetFilters = () => {
    let filter_return = [Object.entries(this.props.task[this.collectionName]), this.state.currentUsernames.map((ut) => ut.value)];
    this.setState({
      filteredOut: [...filter_return[1], ...this.state.newUsers],
      popupOpen: false,
      username_filter: null,
      company_filter: null,
      limit_from_filter: null,
      limit_to_filter: null,
      done_from_filter: null,
      done_to_filter: null,
      deadline_from_filter: null,
      deadline_to_filter: null,
      added_from_filter: null,
      added_to_filter: null
    });
  }

  renderFilter = () => {
    const deadline_from = this.state.deadline_from_filter ?
      moment(this.state.deadline_from_filter).format(getDateFormat()) : "";
    const deadline_to = this.state.deadline_to_filter ?
      moment(this.state.deadline_to_filter).format(getDateFormat()) : "";
    const added_from = this.state.added_from_filter ?
      moment(this.state.added_from_filter).format(getDateFormat()) : "";
    const added_to = this.state.added_to_filter ?
      moment(this.state.added_to_filter).format(getDateFormat()) : "";

    return (
      <Menu className={"filters-menu"}>
        <Form style={{ padding: "14px" }}>
          <Grid columns={2} >
            <Grid.Column>
              <FormDropdown
                clearable={true}
                className={"username"}
                label="Username"
                placeholder={"Username"}
                fluid search selection
                options={this.state.currentUsernames}
                value={this.state.username_filter}
                onChange={(e, data) => this.setState({ username_filter: data.value })}
              />
              <FormDropdown
                clearable={true}
                className={"username"}
                label="Company"
                placeholder={"Select Company"}
                fluid search selection
                options={this.state.currentCompanies}
                value={this.state.company_filter}
                onChange={(e, data) => this.setState({ company_filter: data.value })}
              />
              <Form.Field style={{ margin: "0px" }}>
                <label className="label-header">Limit</label>
              </Form.Field>
              <Form.Group inline>
                <Form.Field>
                  <Form.Input
                    fluid className="number-range"
                    placeholder="From"
                    value={this.state.limit_from_filter}
                    onChange={(e, data) => this.setState({ limit_from_filter: data.value })}
                  />
                </Form.Field>
                <label>-</label>
                <Form.Field style={{ padding: "0px" }}>
                  <Form.Input
                    fluid className="number-range"
                    placeholder="To"
                    value={this.state.limit_to_filter}
                    onChange={(e, data) => this.setState({ limit_to_filter: data.value })}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field style={{ margin: "0px" }}>
                <label className="label-header">Done</label>
              </Form.Field>
              <Form.Group inline>
                <Form.Field>
                  <Form.Input
                    fluid className="number-range"
                    placeholder="From"
                    value={this.state.done_from_filter}
                    onChange={(e, data) => this.setState({ done_from_filter: data.value })}
                  />
                </Form.Field>
                <label>-</label>
                <Form.Field style={{ padding: "0px" }}>
                  <Form.Input
                    fluid className="number-range"
                    placeholder="To"
                    value={this.state.done_to_filter}
                    onChange={(e, data) => this.setState({ done_to_filter: data.value })}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Field className={"deadline-from"}>
                <label>Deadline since</label>
                <DateInput
                  clearable={true}
                  dateFormat={"L"}
                  popupPosition="right center"
                  closable={true}
                  key={"deadline-from" + this.state.deadline_to_filter}
                  name="deadline-from"
                  placeholder="Deadline since"
                  value={deadline_from}
                  maxDate={deadline_to ? deadline_to : null}
                  onChange={(e, { value }) => {
                    const deadline = value ? moment(value, "L") : undefined;
                    this.setState({ deadline_from_filter: deadline });
                  }}
                  onClick={() => this.setState({ datepickerOpen: true })}
                />
              </Form.Field>
              <Form.Field className={"deadline-to"}>
                <label>Deadline till</label>
                <DateInput
                  clearable={true}
                  closable={true}
                  dateFormat={"L"}
                  popupPosition="right center"
                  key={"deadline-to" + this.state.deadline_from_filter}
                  name="deadline-to"
                  placeholder="Deadline till"
                  value={deadline_to}
                  minDate={deadline_from ? deadline_from : null}
                  onChange={(e, { value }) => {
                    const deadline = value ? moment(value, "L").add(86399, "seconds") : undefined; // add 23:59:59h
                    this.setState({ deadline_to_filter: deadline });
                  }}
                  onClick={() => this.setState({ datepickerOpen: true })}
                />
              </Form.Field>
              <Form.Field className={"added-from"}>
                <label>Added to task since</label>
                <DateInput
                  clearable={true}
                  dateFormat={"L"}
                  popupPosition="right center"
                  closable={true}
                  key={"added-from" + this.state.added_from_filter}
                  name="added-from"
                  placeholder="Added to task since"
                  value={added_from}
                  maxDate={added_to ? added_to : null}
                  onChange={(e, { value }) => {
                    const deadline = value ? moment(value, "L").toISOString() : "";
                    this.setState({ added_from_filter: deadline });
                  }}
                  onClick={() => this.setState({ datepickerOpen: true })}
                />
              </Form.Field>
              <Form.Field className={"added-to"}>
                <label>Added to task till</label>
                <DateInput
                  clearable={true}
                  closable={true}
                  dateFormat={"L"}
                  popupPosition="right center"
                  key={"added-to" + this.state.added_to_filter}
                  name="added-to"
                  placeholder="Added to task till"
                  value={added_to}
                  minDate={added_from ? added_from : null}
                  onChange={(e, { value }) => {
                    const deadline = value ? moment(value, "L").add(86399, "seconds").toISOString() : ""; // add 23:59:59h
                    this.setState({ added_to_filter: deadline });
                  }}
                  onClick={() => this.setState({ datepickerOpen: true })}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <div style={{ textAlign: "center" }}>
            <Form.Group className="form-buttons" inline>
              <Form.Button
                className="datahive-button submit-button"
                color="yellow"
                type="submit"
                onClick={() => this.filterUsers()}>
                Submit
              </Form.Button>
              <Form.Button
                className="datahive-button close-form-button"
                color="grey"
                type="submit"
                onClick={() => this.setState({ popupOpen: false })}>
                Close
              </Form.Button>
            </Form.Group>
          </div>

        </Form>
      </Menu>
    );
  }

  getAssignedQuestions = () => {
    let result = 0;
    _.forEach(this.props.task[this.collectionName], (usertask) => {
      result += parseInt(usertask.max_questions) || 0;
    });
    return result;
  };

  getFilteredUsertasks = () => {
    return this.props.task[this.collectionName] ? _.cloneDeep(this.props.task[this.collectionName]).filter(function (item) {
      return this.state.filteredOut.find((item2) => item.username === item2) ? true : false;
    }, this) : [];
  }
}
