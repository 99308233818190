import _ from "lodash";
import React from "react";
import {Button, Checkbox, Grid, Header, Input} from "semantic-ui-react";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class FileUploadWidgetEditor extends WidgetEditor {
  static header = "File Upload";

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      newFileType: "",
    };
  }

  addFileType = () => {
    this.updateWidgetConfig((widgetConfig) => {
      for (const ft in widgetConfig.acceptedFileTypes) {
        if (widgetConfig.acceptedFileTypes[ft] === this.state.newFileType) {
          return;
        }
      }
      if (!widgetConfig.acceptedFileTypes) {
        widgetConfig.acceptedFileTypes = [this.state.newFileType];
      } else {
        widgetConfig.acceptedFileTypes.push(this.state.newFileType);
      }
      this.setState({newFileType: ""});
    });
  };

  removeFileType = (fileType) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.acceptedFileTypes = _.filter(widgetConfig.acceptedFileTypes, (o) => {
        return o !== fileType;
      });
    });

    this.setState({}); // force re-render
  };

  setFileCountLimit = (e, {value}) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.fileCountLimit = value;
    });

    this.setState({}); // force re-render
  };

  // update acceptableWidgetConfig value - check if they are not the same
  onUpdateAcceptedFileTypes = (idx, value) => {
    this.updateWidgetConfig((widgetConfig) => {
      for (const opt in widgetConfig.acceptedFileTypes) {
        if (widgetConfig.acceptedFileTypes[opt] === value) {
          return;
        }
      }
      widgetConfig.acceptedFileTypes[idx] = value;

      this.setState({}); // force re-render
    });
  };

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const fileTypes = _.map(config.acceptedFileTypes, (fileType, idx) => {
      return (
        <Grid.Row
          className="options-row"
          key={"option" + idx.toString()}>
          <Grid.Column>
          </Grid.Column>
          <Grid.Column width={10}>
            <Input
              size="mini"
              fluid
              value={fileType}
              onChange={(e, data) => this.onUpdateAcceptedFileTypes(idx, data.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Button circular icon="minus" size="mini" color="red"
              onClick={() => this.removeFileType(fileType)}/>
          </Grid.Column>
        </Grid.Row>
      );
    });

    return (
      <Grid className={"RadioButtonWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header as="h4">Allowed File Types</Header>
            <Grid>
              {fileTypes}
              <Grid.Row
                className="options-row"
                key="new-option">
                <Grid.Column/>
                <Grid.Column width={10}>
                  <Input
                    size="mini"
                    fluid
                    placeholder="New fileType"
                    value={this.state.newFileType}
                    onChange={(e) => this.setState({newFileType: e.target.value})}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button size="mini" circular icon="plus" color="green" onClick={this.addFileType}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header
              className="row"
              as="h4">Maximum count of files.</Header>
            <Input
              size="mini" fluid
              type="number"
              value={config.fileCountLimit}
              onChange={this.setFileCountLimit}
            />
            <Header
              className="row"
              as="h4">Show Image Preview</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.showImagePreview}
              onChange={(e, data) => this.updateConfig("showImagePreview", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row/> {/* Used as spacer. */}
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default FileUploadWidgetEditor;
