import {useEffect, useState} from "react";

const useMousePosition = (nodeRef) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    let node = nodeRef.current;
    const setFromEvent = (e) =>
      setPosition({
        x: e.offsetX,
        y: e.offsetY
      });

    node.addEventListener("mousemove", setFromEvent);
    return () => {
      node.removeEventListener("mousemove", setFromEvent);
    };
  }, [nodeRef]);

  return position;
};

export default useMousePosition;
