import _ from "lodash";

import React, {Component} from "react";
import {Grid, Icon, Label} from "semantic-ui-react";
import Draggable from "./Draggable";

import PropTypes from "prop-types";

import "./Toolbar.css";

class ToolbarItem extends Component {
  static propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string
  };

  render() {
    return (
      <Label className="ToolbarItem">
        <Icon
          name={this.props.icon}
          size="large"
          style={{marginBottom: "5px"}}
        />
        <div>{this.props.text}</div>
      </Label>
    );
  }
}

export default class Toolbar extends Component {
  render() {
    const containerItem = <ToolbarItem icon="expand" text="Container"/>;
    const selectItem = <ToolbarItem icon="caret square right outline" text="Select"/>;
    const buttonItem = <ToolbarItem icon="square full" text="Button"/>;
    const richTextItem = <ToolbarItem icon="edit" text="Editor"/>;
    const readonlyTextItem = <ToolbarItem icon="edit outline" text="HTML"/>;
    const textAreaItem = <ToolbarItem icon="font" text="TextArea"/>;
    const textAreaPipedItem = <ToolbarItem icon="i cursor" text="Segment TextArea"/>;
    const googleItem = <ToolbarItem icon="google play" text="Google Play"/>;
    const spreadsheetItem = <ToolbarItem icon="file excel" text="Spread Sheet"/>;
    const SIOSTRAItem = <ToolbarItem icon="book" text="SIOSTRA"/>;
    const ImageSegmentationItem = <ToolbarItem icon="book" text="Image Segments"/>;
    const OrderListItem = <ToolbarItem icon="smile outline" text="Order List"/>;
    const FileUploadItem = <ToolbarItem icon="up arrow" text="File Upload"/>;
    const MediaPlayerItem = <ToolbarItem icon="play" text="Media Player"/>;
    const MediaWaveformWidgetItem = <ToolbarItem icon="sound" text="Media Waveform"/>;
    const AudioAnswerWidgetItem = <ToolbarItem icon="microphone" text="Audio Answer"/>;

    return (
      <Grid centered className="Toolbar" verticalAlign="middle">
        <Grid.Row className="title">Containers</Grid.Row>
        <Grid.Row className="buttons">
          <Draggable
            key={_.uniqueId()}
            objType={"ContainerWidget"}
            wraps={containerItem}
          />
        </Grid.Row>
        <Grid.Row className="title">Selections</Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"SelectWidget"}
              wraps={selectItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"RadioButtonWidget"}
              wraps={buttonItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"OrderListWidget"}
              wraps={OrderListItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"ImageSegmentationWidget"}
              wraps={ImageSegmentationItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="title">Text Editors</Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"RichTextWidget"}
              wraps={richTextItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"RichTextReadOnlyWidget"}
              wraps={readonlyTextItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"SIOSTRAWidget"}
              wraps={SIOSTRAItem}
            />
          </Grid.Column>
          <Grid.Column/>
        </Grid.Row>
        <Grid.Row className="title">Text Areas</Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"TextareaWidget"}
              wraps={textAreaItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"PipedTextAreaWidget"}
              wraps={textAreaPipedItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="title">Combos</Grid.Row>
        <Grid.Row columns={2} className="buttons">
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"GooglePlayWidget"}
              wraps={googleItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"SpreadsheetWidget"}
              wraps={spreadsheetItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={"buttons"}>
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"FileUploadWidget"}
              wraps={FileUploadItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"MediaPlayerWidget"}
              wraps={MediaPlayerItem}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={"buttons"}>
          <Grid.Column textAlign="right">
            <Draggable
              key={_.uniqueId()}
              objType={"MediaWaveformWidget"}
              wraps={MediaWaveformWidgetItem}
            />
          </Grid.Column>
          <Grid.Column>
            <Draggable
              key={_.uniqueId()}
              objType={"AudioAnswerWidget"}
              wraps={AudioAnswerWidgetItem}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
