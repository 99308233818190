import React, {Component} from "react";
import {List} from "semantic-ui-react";
import ShowInputButton from "./ShowInputButton";
import TreeNode from "./TreeNode";
import PropTypes from "prop-types";


class NodeWithChildren extends Component {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    deleteButton: PropTypes.element,
    displayAddButton: PropTypes.bool,
    updateOpenInputValue: PropTypes.func,
    node: PropTypes.object,
    parentProps: PropTypes.object,
    openInputValue: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
    };
  }

  render() {
    return (

      <List.Content className={"full-width"}>
        <div className={`node-title ${this.props.color ? "background_" + this.props.color : ""}`}>{this.props.title}</div>
        {
          this.props.deleteButton
        }
        {this.props.displayAddButton &&
        <ShowInputButton
          showInput={this.props.openInputValue === this.props.node.value}
          onClick={() => this.props.updateOpenInputValue(this.props.node.value)}
        />
        }
        <TreeNode
          {...this.props.parentProps}
          parentNodeValue={this.props.node.value}
          data={this.props.node.children} //must override data from above or we will run out of RAM and CPU
        />

      </List.Content>
    );
  }
}

export default NodeWithChildren;
