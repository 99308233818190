import _ from "lodash";
import React from "react";
import {
  Grid,
  Button,
  Table,
  Checkbox,
  Dropdown,
  List,
  Icon,
} from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import moment from "moment";

import ajax from "../../../helpers/ajax";
import { copyToClipboard } from "../../../helpers/utils";
import config from "../../../config/config";
import FilteredComponent from "../../../components/FilteredComponent";
import LoaderSpinner from "../../../components/LoaderSpinner";
import ModalConfirm from "../../../components/ModalConfirm";
import "./TaskDetails.css";

export class TaskDetails extends FilteredComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        user: [],
        company: [],
      },
      possibleFilterValues: {},
      users: [],
      answersList: [],
      durationSum: null,
      checkedList: [],
      currentUser: null,
      displayWidgetReportModal: false,
      isChecked: false,
      dataLoaded: false,
      isReportExcelLoading: false
    };
    this.arrayFilters = ["company", "username"];
    this.widgetReportsUrls = {
      AudioAnswerWidget: config.AUDIO_ANSWER_WIDGET_REPORT,
      VideoWidget: config.VIDEO_WIDGET_REPORT,
    };
  }

  componentDidMount() {
    this.getFilterOptions();
    super.componentDidMount();
  }

  getData = (newFilters) => {
    ajax
      .get([config.TASK_USERS_DETAILS, this.props.slug], {
        params: newFilters || this.getFilters(),
      })
      .then((response) => {
        this.setState({
          users: response.data.usertasks.map((item) => ({
            ...item,
            isChecked: false,
          })),
          dataLoaded: true,
          checkedList: [],
          allChecked: false,
        });
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  createOptions = (data, attribute) => {
    const options = _.map(data, (option) => {
      return {
        key: option[attribute],
        text: option[attribute],
        value: option[attribute],
      };
    });
    return options;
  };

  getFilterOptions = () => {
    const params = { ...this.state.filters };
    ajax
      .get([config.TASK_USER_DETAILS_FILTER_OPTIONS, this.props.slug], {
        params,
      })
      .then((response) => {
        const userOptions = this.createOptions(response.data.user, "username");
        const companyOptions = this.createOptions(
          response.data.company,
          "name"
        );
        const possibleFilterValues = {
          ...this.state.possibleFilterValues,
          user: userOptions,
          company: companyOptions,
        };
        this.setState({
          possibleFilterValues,
          allChecked: false,
          checkedList: [],
        });
      });
  };

  compareLastActivity = (lastAnswer, tutorialDisplay) => {
    const lastestAnswer = moment(lastAnswer).format("YYYY-MM-DDTHH:mm:ss[Z]");
    const diplayAnswer =
      moment(lastestAnswer).format("DD.MM.YYYY HH:mm") + "\n(last answer)";
    const diplayTutorial =
      moment(tutorialDisplay).format("DD.MM.YYYY HH:mm") +
      "\n(tutorial display)";

    if (moment(lastestAnswer) > moment(tutorialDisplay)) {
      return diplayAnswer;
    } else if (moment(lastestAnswer) < moment(tutorialDisplay)) {
      return diplayTutorial;
    } else if (lastAnswer) {
      return diplayAnswer;
    } else if (tutorialDisplay) {
      return diplayTutorial;
    }
    return "";
  };

  renderFilters = () => {
    const possibleFilterValues = this.state.possibleFilterValues;
    return (
      <div>
        <Grid columns={4}>
          <Grid.Row columns={2} className={"filtersOption"}>
            <Grid.Column className={"ui center aligned"}>
              Company
              <Dropdown
                className={"company-dropdown"}
                placeholder="Company"
                fluid
                search
                multiple
                selection
                options={possibleFilterValues.company || []}
                onChange={(e, data) => {
                  const newVal = _.isEqual(data.value, [null])
                    ? null
                    : data.value;
                  this.changeFilterInstantly({
                    company: newVal,
                    deadlines: [],
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column className={"ui center aligned"}>
              User
              <Dropdown
                className={"user-dropdown"}
                placeholder="User"
                fluid
                search
                multiple
                selection
                options={possibleFilterValues.user || []}
                onChange={(e, data) => {
                  const newVal = _.isEqual(data.value, [null])
                    ? null
                    : data.value;
                  this.changeFilterInstantly({ username: newVal });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={"filtersOption"}>
            {this.props.widgetReportType && (
              <Grid.Column width={16} align={"right"}>
                <Button color="green" loading={this.state.isReportExcelLoading} onClick={() => this.getReportExcel()}>
                  <Icon
                    name={
                      this.props.widgetReportType === "AudioAnswerWidget"
                        ? "file audio"
                        : "file video"
                    }
                  />{" "}
                  Overall Report
                </Button>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  toTime = (seconds) => {
    let date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  getReport = (userId) => {
    let response = {};
    response.data = [];
    let params = { slug: this.props.slug, userId: userId };
    ajax
      .get([this.widgetReportsUrls[this.props.widgetReportType]], { params })
      .then((response) => {
        if (response.data.length > 0) {
          let sum = response.data
            .map((item) => item.content && item.content.duration)
            .reduce((prev, next) => prev + next, 0);
          this.setState({
            answersList: response.data,
            durationSum: this.toTime(sum),
            currentUser: userId,
          });
        }
      });
    this.setState({
      displayWidgetReportModal: !this.state.displayWidgetReportModal,
    });
  };

  getReportExcel = () => {
    this.setState({isReportExcelLoading: true})
    let data = {
      slug: this.props.slug,
      userId: this.state.currentUser,
      type: "excel",
    };
    ajax
      .post([this.widgetReportsUrls[this.props.widgetReportType]], { data })
      .then((response) => {
        window.open(config.BACKEND_URL + response.data.url, "_blank");
        this.setState({isReportExcelLoading: false})
      }).catch((error) => {
        console.error("Error:", error)
        toastr.error("An error occurred. Please try again later.")
        this.setState({isReportExcelLoading: false})
      });

  };

  handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    const allUsers = [];
    this.setState((prevState) => {
      let { users, allChecked, checkedList } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        if (checked) {
          _.map(this.state.users, (item) => allUsers.push(item.profile.email));
          checkedList = allUsers;
        } else {
          checkedList = [];
        }
        users = users.map((item) => ({ ...item, isChecked: checked }));
      } else {
        users = users.map((item) =>
          item.profile.email === itemName
            ? { ...item, isChecked: checked }
            : item
        );
        allChecked = users.every((item) => item.isChecked);
        if (
          prevState.checkedList.includes(itemName) ||
          prevState.checkedList.length === 0
        ) {
          checkedList = prevState.checkedList.filter(
            (item) => !itemName.includes(item)
          );
        } else {
          prevState.checkedList.push(itemName);
        }
      }
      return { users, allChecked, checkedList };
    });
  };

  renderModal = () => {
    const listItem = _.map(this.state.checkedList, (email) => (
      <List.Item key={"id" + email}>{email}</List.Item>
    ));
    const content = <List>{listItem}</List>;
    const button = (
      <Button
        color="green"
        onClick={() => {
          copyToClipboard(this.state.checkedList);
          this.setState((prevState) => ({
            checkedList: [],
            allChecked: false,
            displayUsersEmailModal: false,
            users: prevState.users.map((item) => ({
              ...item,
              isChecked: false,
            })),
          }));
        }}
      >
        Copy emails
      </Button>
    );
    return (
      <ModalConfirm
        title="Users emails list"
        closeModal={() => this.setState({ displayUsersEmailModal: false })}
        content={content}
        checkedList={this.state.checkedList}
        button={button}
      />
    );
  };

  renderWidgetReportModal = () => {
    const tableRow = _.map(this.state.answersList, (item) => (
      <Table.Row>
        <Table.Cell>{item.answer_id}</Table.Cell>
        <Table.Cell>{item.question_id}</Table.Cell>
        <Table.Cell>
          {item.content ? this.toTime(Math.round(item.content.duration)) : 0}
        </Table.Cell>
      </Table.Row>
    ));
    const content = (
      <>
        {this.state.durationSum ? (
          <span>{this.state.durationSum} total.</span>
        ) : (
          <span>This user has no assigned recordings on this task</span>
        )}
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Answer ID</Table.HeaderCell>
              <Table.HeaderCell>Question ID</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRow}</Table.Body>
        </Table>
      </>
    );
    const button = (
      <Button
        color="green"
        onClick={() =>
          this.getReportExcel(this.state.currentUser && this.state.currentUser)
        }
      >
        Download xlsx
      </Button>
    );
    return (
      <ModalConfirm
        title={`${
          this.props.widgetReportType === "AudioAnswerWidget"
            ? "AudioAnswer"
            : "Video"
        } Widget Report`}
        size="fullscreen"
        closeModal={() =>
          this.setState({
            displayWidgetReportModal: false,
            answersList: [],
            durationSum: null,
            currentUser: null,
          })
        }
        content={content}
        answersList={this.state.answersList}
        button={this.state.durationSum && button}
      />
    );
  };

  renderOptionsPanel = () => {
    return <div className="options-panel">{this.renderFilters()}</div>;
  };

  renderRow(user) {
    const last_activity = this.compareLastActivity(
      user.last_activity,
      user.displayed_tutorial_datetime
    );
    return (
      <Table.Row key={user.id} className={"rowDetails"}>
        <Table.Cell textAlign="center">
          <Checkbox
            key={user.profile.email}
            id={user.profile.email}
            name={user.profile.email}
            value={user.profile.email}
            checked={user.isChecked}
            onChange={this.handleChange}
          />
        </Table.Cell>
        <Table.Cell>{user.username}</Table.Cell>
        <Table.Cell>{user.profile.first_name}</Table.Cell>
        <Table.Cell>{user.profile.last_name}</Table.Cell>
        <Table.Cell>{user.profile.email}</Table.Cell>
        <Table.Cell>{user.profile.company}</Table.Cell>
        <Table.Cell>{user.max_questions}</Table.Cell>
        <Table.Cell>{user.done}</Table.Cell>
        <Table.Cell>{last_activity}</Table.Cell>
        {this.props.widgetReportType && (
          <Table.Cell textAlign="center">
            <Icon
              name={
                this.props.widgetReportType === "AudioAnswerWidget"
                  ? "file audio outline"
                  : "file video outline"
              }
              size="large"
              onClick={() =>
                this.getReport(
                  user.profile && user.profile.id && user.profile.id
                )
              }
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  }

  render() {
    let rows = _.map(this.state.users, (user) => {
      return this.renderRow(user);
    });

    return (
      <div className="tab-panel">
        {this.renderOptionsPanel()}
        {this.state.displayUsersEmailModal && this.renderModal()}
        <Table selectable sortable fixed className={"detailsPanel"}>
          <Table.Header className={"header"} fullWidth>
            <Table.Row>
              <Table.HeaderCell textAlign="center" width={1}>
                <Checkbox
                  key="checkAll"
                  id="all"
                  name="checkAll"
                  onChange={this.handleChange}
                  checked={this.state.allChecked}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="username" width={2}>
                Username
              </Table.HeaderCell>
              <Table.HeaderCell className="first_name" width={2}>
                First name
              </Table.HeaderCell>
              <Table.HeaderCell className="last_name" width={2}>
                Last name
              </Table.HeaderCell>
              <Table.HeaderCell className="email" width={2}>
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="company" width={2}>
                Company
              </Table.HeaderCell>
              <React.Fragment>
                <Table.HeaderCell className="limit" width={2}>
                  Limit
                </Table.HeaderCell>
                <Table.HeaderCell className="sort-done" width={2}>
                  Done
                </Table.HeaderCell>
              </React.Fragment>
              <Table.HeaderCell className="last_activity" width={2}>
                Last activity
              </Table.HeaderCell>
              {this.props.widgetReportType && (
                <Table.HeaderCell width={2}>
                  {this.props.widgetReportType === "AudioAnswerWidget"
                    ? "Audio report"
                    : "Video report"}
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!this.state.dataLoaded && (
              <Table.Row>
                <Table.Cell colSpan={12}>
                  <LoaderSpinner />
                </Table.Cell>
              </Table.Row>
            )}
            {this.state.dataLoaded && rows}
          </Table.Body>
          {this.state.displayWidgetReportModal &&
            this.props.widgetReportType &&
            this.renderWidgetReportModal()}
        </Table>
      </div>
    );
  }
}

export default withRouter(TaskDetails);
