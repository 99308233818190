import { Component } from "react";
import config from "../../config/config";
import Perms from "../../config/frontend_perms";
import urls from "../../config/frontend_urls";

class DocsRender extends Component {
  constructor(props) {
    super(props);
    this.perms = new Perms();
  }

  render() {
    if (this.perms.haveAccessTo(urls.DOCUMENTATION_WIDGETS)) {
      const url = config.BACKEND_URL + config.API_SUFFIX + "docs/index.html";
      window.location.replace(url);
    }
    return null;
  }
}

export default DocsRender;
