export const GROUPED_BY = {
  USER: "user",
  TASK: "task",
  PROJECT: "project",
  TASK_AND_PROJECT: "task & project"
};

export function getMinHours(taskReport) {
  if (!taskReport.sessions) {
    taskReport.sessions = 0;
  }
  if (!taskReport.percentiles) {
    taskReport.percentiles = 0;
  }
  return taskReport.sessions < taskReport.percentiles ?
    taskReport.sessions / 3600.0 : taskReport.percentiles / 3600.0;
}

export function getMaxHours(taskReport) {
  if (!taskReport.sessions) {
    taskReport.sessions = 0;
  }
  if (!taskReport.percentiles) {
    taskReport.percentiles = 0;
  }
  return taskReport.sessions > taskReport.percentiles ?
    taskReport.sessions / 3600.0 : taskReport.percentiles / 3600.0;
}

export function getHoursAcceptable(taskReport) {
  if (!taskReport.sessions) {
    taskReport.sessions = 0;
  }
  if (!taskReport.percentiles) {
    taskReport.percentiles = 0;
  }

  return taskReport.hours <= getMaxHours(taskReport);
}

export function roundHours(hours) {
  /* Rounds hours to closest quarter. */
  return Math.round(hours * 4) / 4.0;
}
