import React from "react";
import PropTypes from "prop-types";
import {Table} from "semantic-ui-react";

import "./ListItem.css";

const ListItem = (props) =>
  <Table.Row
    onClick={() => props.changeDeadline(props)}
    className={props.is_staff
      ? "deadline-list-item clickable"
      : "deadline-list-item"}
  >
    <Table.Cell>
      {props.name}
    </Table.Cell>
    <Table.Cell>
      {props.is_staff && props.default ? "Users not assigned to company, " : null}
      {props.companies.map(
        (company, index) => (index ? ", " : "") + company.name)
      }
    </Table.Cell>
    <Table.Cell>
      {props.start}
    </Table.Cell>
    <Table.Cell>
      {props.startTime}
    </Table.Cell>
    <Table.Cell>
      {props.end}
    </Table.Cell>
    <Table.Cell>
      {props.endTime}
    </Table.Cell>
  </Table.Row>;

ListItem.propTypes = {
  changeDeadline: PropTypes.func.isRequired,
  is_staff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
};

export default ListItem;
