import React, {Component} from "react";
import {Button, Checkbox, Grid, Icon, Input, Modal} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import _ from "lodash";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";

import "./ReportDeadlineModal.css";
import PropTypes from "prop-types";
import {formatErrorMessage} from "../../../helpers/utils";
import moment from "moment-timezone";
import DragAndDropSelector from "../../../components/simple/DragAndDropSelector/DragAndDropSelector";
import {DateInput} from "../../../components/Calendar";
import {TimeInput} from "../../../components/TimeInput";
import ModalConfirm from "../../../components/ModalConfirm";

class ReportDeadlineModal extends Component {
  static propTypes = {
    reloadData: PropTypes.func,
    handleClose: PropTypes.func,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    deadline: PropTypes.object,
    start: PropTypes.string,
    end: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      display_spinner: false,
      deadline: {
        id: "",
        companies: [],
        start: "",
        end: "",
        startTime: "",
        endTime: "",
        default: true,
        name: "",
      },
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      companies: [],
      companyChoicesFilters: "",
      selectedCompanyId: null,
      modalOpen: true,
      confirmModalOpen: false,
      activeCompanies: false
    };
  }

  componentDidMount() {
    // receives company objects with name, map their ids to an array
    let companyIds;
    if (this.props.deadline.companies) {
      companyIds = [...this.props.deadline.companies.map(
        (company) => company.id
      )];
    }
    this.handleCompanyChange(this.props.deadline, companyIds);
  }

  handleCompanyChange = (deadline, selected) => {
    this.setState({
      deadline: {
        ...deadline,
        companies: selected,
      }
    });
  };

  handleInputsChange = (data, input) => {
    this.setState({
      deadline: {
        ..._.cloneDeep(this.state.deadline),
        [input]: data,
      }
    });
  };

  handleCheckboxChange = () => {
    const {deadline} = this.state;
    this.setState({
      deadline: {
        ...deadline,
        default: !deadline.default
      }
    });
  };

  convertDate = () => {
    const timezone = moment.tz.guess();
    let {start, startTime, end, endTime} = this.state.deadline;
    if (!startTime || !endTime) {
      return toastr.error("Please choose start/end time.");
    }
    start = moment.tz(start + " " + startTime, timezone).utc().format(config.FORMAT_ISO_SECOND);
    end = moment.tz(end + " " + endTime, timezone).utc().format(config.FORMAT_ISO_SECOND);
    return [start, end];
  }

  onSubmit = () => {
    const newFormatDate = this.convertDate();
    const data = this.state.deadline;
    data.start = newFormatDate[0];
    data.end = newFormatDate[1];
    const {deadline} = this.state;

    ajax.post(
      [config.LINGUISTS_MONTH_REPORTS_DEADLINE],
      {data: {...deadline}}
    ).then(() => {
      toastr.success("Success", "Deadline saved!");
      this.props.handleClose();
      this.props.reloadData();
    }).catch((error) => {
      const start = moment(this.state.deadline.start).format(config.FORMAT_Ymd);
      const end = moment(this.state.deadline.end).format(config.FORMAT_Ymd);
      this.setState({
        deadline: {
          ...deadline,
          start: start,
          end: end,
        }
      });
      let message = "";
      if (error.response.status === 500) {
        return toastr.error(
          "Something went wrong. Please make sure the deadlines are not overlapping");
      } else if (error.response.data.non_field_errors) {
        error.response.data.non_field_errors.forEach((error) => message += error);
      } else {
        message = error.response.data;
      }
      toastr.error(formatErrorMessage(message));
    });
  };

  onSubmitDelete = () => {
    const {deadline} = this.state;
    ajax.delete(
      [config.LINGUISTS_MONTH_REPORTS_DEADLINE],
      {data: {...deadline}}
    ).then(() => {
      toastr.success("Success", "Deadline deleted!");
      this.props.handleClose();
      this.props.reloadData();
    }).catch((error) => {
      let message = "";
      if (error.response.status === 500) {
        return toastr.error(
          "Something went wrong. Deadline wasn't deleted.");
      } else if (error.response.data.non_field_errors) {
        error.response.data.non_field_errors.forEach((error) => message += error);
      } else {
        message = error.response.data;
      }
      toastr.error(formatErrorMessage(message));
    });
  };

  renderConfirmModal = () => {
    const confirm = <>
      <Button
        onClick={this.onSubmitDelete}
        color="red"
        className="datahive-button"
      >
        Yes, I&apos;m sure
      </Button>
      <Button
        onClick={() => this.setState({confirmModalOpen: false})}
        floated={"left"}
        color="dark-grey"
        className="datahive-button"
      >
        Cancel
      </Button>
    </>;
    const content = <p>
    Are you sure you want to delete the selected linguist month report deadline?
    </p>;

    return <ModalConfirm
      closeModal={() => this.setState({confirmModalOpen: false})}
      title="Delete deadline"
      content={content}
      button={confirm}
    />;
  };

  render() {
    const {start, end, startTime, endTime, name} = this.state.deadline;
    const tempStart = start, tempEnd = end;
    const stTime = moment(startTime, config.FORMAT_Hm, true).isValid() ? startTime : tempStart.split(" ")[1];
    const enTime = moment(endTime, config.FORMAT_Hm, true).isValid() ? endTime : tempEnd.split(" ")[1];
    const {handleClose} = this.props;

    const activeCompaniesList = this.props.companies.filter((item) => item.is_active === true);

    return (
      <Modal
        dimmer={true}
        open={true}
        onClose={handleClose}
        className={"linguist-report-modal"}
      >
        {this.state.confirmModalOpen && this.renderConfirmModal()}
        <Modal.Header>
          <div>
            <Icon name={"calculator"}/>
            <span>Monthly report deadline</span>
          </div>
          <div
            className={"close-button"}
            onClick={handleClose}
          >
            <Icon name={"window close"}/>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid centered className={"DropdownMonthYear"}>
            <Grid.Row columns={3} stretched floated="left">
              <Grid.Column width={4}>
                <DateInput
                  name="startDate"
                  placeholder="start date"
                  value={start ? moment(start).format(config.FORMAT_Ymd) : ""}
                  iconPosition="left"
                  popupPosition="bottom right"
                  closable={true}
                  autoComplete="off"
                  onChange={(value) => this.handleInputsChange(value, "start")}
                />
                <TimeInput
                  name="startTime"
                  placeholder="start time"
                  popupPosition="bottom right"
                  value={stTime}
                  closable={true}
                  autoComplete="off"
                  onChange={(value) => this.handleInputsChange(value, "startTime")}
                />
              </Grid.Column>
              <Grid.Column width={2}/>
              <Grid.Column width={4}>
                <DateInput
                  name="endDate"
                  placeholder="end date"
                  value={end ? moment(end).format(config.FORMAT_Ymd) : ""}
                  iconPosition="left"
                  popupPosition="bottom left"
                  closable={true}
                  autoComplete="off"
                  onChange={(value) => this.handleInputsChange(value, "end")}
                />
                <TimeInput
                  name="endTime"
                  placeholder="end time"
                  popupPosition="bottom left"
                  value={enTime}
                  closable={true}
                  autoComplete="off"
                  onChange={(value) => this.handleInputsChange(value, "endTime")}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row />
          </Grid>
          <Grid.Row centered>
            <Checkbox
              toggle
              checked={this.state.activeCompanies}
              onChange={(e, {checked}) => {
                this.setState({activeCompanies: checked});
              }}
              label={"show only active companies"}/>
            <div>
              <DragAndDropSelector
                choices={this.state.activeCompanies ? activeCompaniesList : this.props.companies}
                choiceValue={"name"}
                filterable
                selectAll
                afterSelectionChange={(selected) => this.handleCompanyChange(this.state.deadline, selected)}
                selected={this.state.deadline.companies || []}
              />
            </div>
          </Grid.Row>
          <Grid centered stretched>
            <Grid.Row/>
            <Grid.Row>
              <Checkbox
                onChange={this.handleCheckboxChange}
                checked={this.state.deadline.default}
                label={"Apply deadline to persons not assigned to companies"}
              />
            </Grid.Row>
            <Grid.Row>
              <Input
                label={"Deadline name:"}
                value={name || ""}
                onChange={(e, data) => this.handleInputsChange(data.value, "name")}
              />
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={"centered"}>
          <Button
            onClick={() => this.setState({confirmModalOpen: true})}
            color="red"
            className="datahive-button"
            floated="left"
          >
            Delete
          </Button>
          <Button
            onClick={this.onSubmit}
            color="yellow"
            className="datahive-button"
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ReportDeadlineModal;
