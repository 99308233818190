import _ from "lodash";

import React from "react";
import moment from "moment-timezone";
import { Form, Icon, Input, Popup, Table, Segment } from "semantic-ui-react";

import PropTypes from "prop-types";
import urls from "../../../../config/frontend_urls";
import { getDateFormat } from "../../../../helpers/utils";
import { Link } from "react-router-dom";
import { DateTimeInput } from "../../../../components/DateTimeInput";
import InfiniteDropdown from "../../../../components/simple/InfiniteDropdown";


export default class UserTaskBase extends React.Component {
  static propTypes = {
    actions: PropTypes.element,
    collectionName: PropTypes.string,
    extraColumns: PropTypes.element,
    idx: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    setModified: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      max_questions: PropTypes.number.isRequired,
      deadline: PropTypes.string.isRequired
    }),
    isChecked: PropTypes.bool.isRequired,
    handleCheckboxClick: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
    usernameOptions: PropTypes.array.isRequired
  };

  // faster rendering with this one
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) ||
      !_.isEqual(this.state, nextState)
    );
  }

  convertDate = (date) => {
    const timezone = moment.tz.guess();
    date = moment.tz(date, timezone).utc().format("YYYY-MM-DD HH:mm:ssZ");
    date = date === "Invalid date" ? null : date;
    return date;
  };

  renderAddedDate = (date) => {
    return date ? moment(date).format(getDateFormat()) : "";
  }

  onChangeDeadline = (value) => {
    const date = this.convertDate(value);
    this.props.onChange(`${this.props.collectionName}.${this.props.idx}.deadline`, date);
  };

  renderDeadline = (row) => {
    const errors = _.get(this.props.errors, "deadline") &&
      <label className={"error-label"}>{this.props.errors.deadline}</label>;
    let iconClass = "black";
    let deadlineTime = null;
    if ("deadline" in row && row.deadline) {
      const currentTime = moment();
      deadlineTime = moment(row.deadline);
      const diff = deadlineTime.diff(currentTime, "minutes");
      if (diff < 0) {
        iconClass = "red";
      } else if (diff < 24 * 60) {
        iconClass = "orange";
      }
      deadlineTime = moment(row.deadline).format("YYYY-MM-DD HH:mm");
    }

    return (
      <React.Fragment>
        {errors}
        <div className={"deadline"}>
          <Icon
            name={"calendar"}
            className={iconClass}
          />
          <DateTimeInput
            closable
            clearable
            onFocus={() => this.props.setModified(this.props.idx)}
            onBlur={() => this.onChangeDeadline(row.deadline)}
            className="calendar"
            disabled={!row.username}
            placeholder="Deadline"
            popupPosition="bottom center"
            dateFormat={"YYYY-MM-DD"}
            iconPosition="left"
            icon={false}
            value={deadlineTime}
            onChange={(value) => this.onChangeDeadline(value)}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { row } = this.props;
    const profile = urls.USER_PROFILE.replace(":username", row.username)
      .replace(":tabName", "profile");
    let username;
    if (row.id) {
      username = <Link to={profile}>{row.username}</Link>;
    } else {
      username = <InfiniteDropdown
        search selection
        key={this.props.idx}
        className={"narrow username-dropdown"}
        value={row.username || ""}
        choices={this.props.usernameOptions}
        chunkSize={20}
        placeholder={"Start typing to find user"}
        onChange={(e, { value }) => {
          if (!value) {
            return;
          }
          this.props.onChange(`${this.props.collectionName}.${this.props.idx}.username`, value);
        }}
      />;
    }
    const isReadonly = !row.username;

    const className = row.toBeDeleted ? "to-be-deleted" : (row.is_modified ? "modified" : "");

    return (
      <Table.Row className={className}>
        <Table.Cell>
          <Form.Checkbox
            id={row.username}
            checked={this.props.isChecked}
            onChange={this.props.handleCheckboxClick}
          />
        </Table.Cell>
        <Table.Cell>{this.props.idx + 1}</Table.Cell>
        <Table.Cell>
          {username}
        </Table.Cell>
        <Table.Cell className={"max-questions"}>
          {
            _.get(this.props.errors, "max_questions") &&
            <label className={"error-label"}>{this.props.errors.max_questions}</label>
          }
          <Input
            disabled={isReadonly}
            className={"limit max-questions"} type="number"
            value={row.max_questions || 0} min={0}
            onChange={(e) =>
              this.props.onChange(`${this.props.collectionName}.${this.props.idx}.max_questions`, parseInt(e.target.value))}
          />
        </Table.Cell>
        <Table.Cell style={{ textAlign: "left" }}>

          <Popup
            content={
              <div>
                <p>Done: {row.done?.done}</p>
                <p>Skipped: {row.done?.skipped}</p>
              </div>
            }
            trigger={
              <Segment>
                {row.done ? parseInt(row.done.done) + parseInt(row.done.skipped) : 0}
                <Icon name="info circle" color="grey" size="small" />
              </Segment>
            }
          />
        </Table.Cell>
        <Table.Cell>
          {this.renderDeadline(row)}
        </Table.Cell>
        <Table.Cell>
          {this.renderAddedDate(row.added)}
        </Table.Cell>
        <Table.Cell textAlign={"center"}>
          <Popup
            content={row.tutorial_confirmed ? "Tutorial confirmed" : "Tutorial not confirmed"}
            trigger={
              <Icon
                name={"file alternate"}
                size={"large"}
                color={row.tutorial_confirmed ? "green" : "grey"}
              />
            }
          />
        </Table.Cell>
        {this.props.extraColumns}
        <Table.Cell className={"actions"} textAlign={"center"}>
          {this.props.actions}
        </Table.Cell>
      </Table.Row>
    );
  }
}
