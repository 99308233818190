import React, {useState} from "react";
import PropTypes from "prop-types";
import {Popup} from "semantic-ui-react";

import AdvancedFilterTrigger from "./AdvancedFilterTrigger";


const AdvancedFilterWrapper = ({
  advancedFilter, //filter component to display as modal content
  type,
  filters, updateFilters, advancedPopupRef,
  ...props
}) => {
  const AdvancedFilter = advancedFilter;
  const forceUpdate = useState()[1];

  let qty;
  if (type === "compare") {
    qty = filters.compare ? 1 : 0;
  } else {
    const qtyPath = "advanced_" + type;
    qty = filters[qtyPath].length +
          filters[qtyPath + "_exact_match"].length +
          filters[qtyPath + "_empty"].length +
          filters[qtyPath + "_not_empty"].length;
  }

  return <Popup
    on="click"
    basic
    flowing
    position="left center"
    className="advanced-filter"
    style={{height: advancedPopupRef.current?.offsetHeight}}
    context={advancedPopupRef.current}
    onMount={() => forceUpdate({})} //popup inside popup needs to update ref
    trigger={
      <AdvancedFilterTrigger
        text={type.split("_").join(" ")}
        quantity={qty}
      />
    }>
    <AdvancedFilter
      {...props}
      type={type}
      filters={filters}
      updateFilters={updateFilters}/>
  </Popup>;
};

AdvancedFilterWrapper.propTypes = {
  advancedFilter: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  advancedPopupRef: PropTypes.object.isRequired,
  // ...props are passed down to advancedFilter component
};

export default AdvancedFilterWrapper;
