import _ from "lodash";
import React, { Component } from "react";
import { Rnd } from "react-rnd";
import { Header, Button } from "semantic-ui-react";
import TreeSelect from "../../simple/TreeSelect/TreeSelect";
import { getNode } from "../../simple/TreeSelect/utils";
import "./Modal.css";
import NoteInput from "./NoteInput";
import AttributeDropdown from "./AttributeDropdown";
import PropTypes from "prop-types";

class Modal extends Component {
  static propTypes = {
    range: PropTypes.shape({
      begin: PropTypes.number,
      end: PropTypes.number,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      note: PropTypes.string,
      entityId: PropTypes.string,
    }).isRequired,
    maxRangeEnd: PropTypes.number,
    update: PropTypes.func.isRequired,
    rangeTypes: PropTypes.array,
    closeModal: PropTypes.func,
    deleteRange: PropTypes.func,
    selectedText: PropTypes.string,
    open: PropTypes.bool,
    default: PropTypes.object,
    enableNotes: PropTypes.bool,
    addFragment: PropTypes.func,
    disableAddFragment: PropTypes.bool,
    trimSelectedText: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.containerDiv = React.createRef();
  }

  changeRangeLimits = (field, value) => {
    const range = _.cloneDeep(this.props.range);
    range[field] = Math.max(
      0,
      Math.min(range[field] + value, this.props.maxRangeEnd + 1)
    );

    if (range.end <= range.begin) {
      if (range.begin === 0) {
        range.end = 1;
      } else if (range.end === this.props.maxRangeEnd) {
        range.begin = this.props.maxRangeEnd - 1;
      } else {
        range.end = range.begin + 1;
      }
    }

    this.props.update(range);
  };

  updateRangeType = (selectedTypes) => {
    const range = _.cloneDeep(this.props.range);
    range.type = _.last(selectedTypes);
    const node = getNode(this.props.rangeTypes, _.last(selectedTypes));
    if (node) {
      range.name = node.title ? node.title : node.value;
    }
    if (range.attributes) {
      delete range.attributes;
    }
    this.props.update(range);
  };

  updateRangeNote = (e) => {
    const note = e.target.value;
    const range = _.cloneDeep(this.props.range);
    range.note = note;
    this.props.update(range);
  };

  clearRangeNote = () => {
    const range = _.cloneDeep(this.props.range);
    range.note = "";
    this.props.update(range);
  };

  updateRangeAttribute = (e, data) => {
    const range = _.cloneDeep(this.props.range);
    if (!Array.isArray(data.value)) {
      data.value = [data.value];
    }
    range.attributes = data.value;
    this.props.update(range);
  };

  handleKeyDown = (e) => {
    if (this.getValue().length) {
      //range has type assigned
      if (e.keyCode === 27 || e.keyCode === 13) {
        //escape pressed or enter
        this.props.closeModal();
      }
    } else {
      if (e.keyCode === 27) {
        //escape pressed
        this.props.deleteRange();
      }
    }

    if (e.keyCode === 46) {
      //delete pressed
      this.props.deleteRange();
    }
  };

  getValue = () => {
    let value = [];
    if (this.props.range.type && this.props.range.type.isArray) {
      value = this.props.range.type;
    } else if (this.props.range.type && !this.props.range.type.isArray) {
      value = [this.props.range.type];
    }
    return value;
  };

  trimText = () => {
    let {
      selectedText,
      range: { begin, end },
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    for (let char of selectedText) {
      if (char == ("\r\n" | "\n" | "\r")) {
        if (char === "0") {
          break;
        }
        begin++;
      } else {
        break;
      }
    }
    for (let i = selectedText.length - 1; i >= 0; i--) {
      if (selectedText[i] == ("\r\n" | "\n" | "\r")) {
        if (selectedText[i] === "0") {
          break;
        }
        end--;
      } else {
        break;
      }
    }

    if (begin > end) {
      this.props.deleteRange();
    } else {
      this.props.update({ ...this.props.range, begin, end });
    }
  };

  componentDidMount() {
    try {
      this.containerDiv.current.focus({
        preventScroll: true,
      });
      this.props.trimSelectedText && this.trimText();
    } catch (error) {
      // Why is the reason to print this errors?
      /* DISABLED: console.log(error); */
    }
  }

  copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.innerText = this.props.selectedText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
  };

  onAccept = () => {
    this.props.trimSelectedText && this.trimText();
    this.props.closeModal();
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    const value = this.getValue();
    const rangeType = getNode(this.props.rangeTypes, this.props.range.type);
    const attributeConfig = rangeType ? rangeType.attributeSelectConfig : null;

    return (
      <Rnd default={this.props.default || { x: 0, y: 0 }}>
        <div
          ref={this.containerDiv}
          className={"Modal ui modal visible active"}
          tabIndex={-1}
          onKeyDown={this.handleKeyDown}
        >
          <i
            aria-hidden="true"
            className="close icon"
            onClick={
              value.length ? this.props.closeModal : this.props.deleteRange
            }
          />
          <Header className="header-with-buttons">
            <Button.Group>
              <Button
                size="mini"
                compact
                icon="arrow left"
                onClick={() => this.changeRangeLimits("begin", -1)}
              />
              <Button
                size="mini"
                compact
                icon="arrow right"
                onClick={() => this.changeRangeLimits("begin", 1)}
              />
            </Button.Group>
            <div className={"fill-height"}>
              <span>{this.props.selectedText}</span>
            </div>
            <Button.Group>
              <Button
                size="mini"
                compact
                icon="arrow left"
                onClick={() => this.changeRangeLimits("end", -1)}
              />
              <Button
                size="mini"
                compact
                icon="arrow right"
                onClick={() => this.changeRangeLimits("end", 1)}
              />
              <Button
                size={"mini"}
                onClick={this.copyToClipboard}
                icon={"copy"}
              ></Button>
            </Button.Group>
          </Header>
          <div className={"content"} onMouseDown={(e) => e.stopPropagation()}>
            <div className={"contentio"}>
              <TreeSelect
                dropdownOpen
                defaultOpen
                checkableNodes
                data={this.props.rangeTypes}
                value={value}
                updateValue={this.updateRangeType}
              />
            </div>

            {!!attributeConfig && (
              <AttributeDropdown
                obj={this.props.range}
                attributeConfig={attributeConfig}
                updateAttribute={this.updateRangeAttribute}
              />
            )}

            {this.props.enableNotes && (
              <NoteInput
                clearNote={this.clearRangeNote}
                note={this.props.range.note}
                updateNote={this.updateRangeNote}
              />
            )}
          </div>
          <div className={"actions"}>
            <Button
              disabled={!value.length}
              content="Accept"
              onClick={this.onAccept}
            />
            {!this.props.disableAddFragment && (
              <Button
                content="Add Fragment"
                onClick={() =>
                  this.props.addFragment(this.props.range.entityId)
                }
              />
            )}
            <Button content="Delete" onClick={this.props.deleteRange} />
          </div>
        </div>
      </Rnd>
    );
  }
}

export default Modal;
