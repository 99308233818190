import React from "react";
import {DateTimeInput as SemanticDateTimeInput} from "semantic-ui-calendar-react";
import PropTypes from "prop-types";

/**
 * Wrapper for {DateTimeInput} from "semantic-ui-calendar-react".
 * Component displays time in a browser-local time.
 * @param props
 * @returns {*}
 * @constructor
 */
export const DateTimeInput = (props) => {

  return (
    <SemanticDateTimeInput
      {...props}
      value={props.value || ""}
      iconPosition="left"
      onChange={(e, data) => {
        props.onChange(data.value);
      }}
    />
  );
};

DateTimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
