import React from "react";
import {Checkbox, Grid, Header} from "semantic-ui-react";
import {WidgetEditor} from "../WidgetEditor";

import "./RichTextEditorWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";
import RichTextEditor from "../../RichTextEditor";
import {getWidget} from "../widgetUtils";

class RichTextEditorWidgetEditor extends WidgetEditor {
  static header = "Text Editor";

  render() {
    const widgetConfig = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    return (
      <Grid className={"RichTextEditorWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Enable auto-height</Header>
            <Checkbox
              className="row"
              toggle
              checked={widgetConfig.autoHeight}
              onChange={(e, data) => this.updateConfig("autoHeight", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <RichTextEditor disable/>
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}

export default RichTextEditorWidgetEditor;
