import React from "react";
import {Container} from "semantic-ui-react";
import _ from "lodash";
import Mustache from "mustache";
import Widget from "../Widget";

class RichTextEditorReadOnlyWidget extends Widget {
  constructor(props) {
    super(props);

    this.state = {
      editorState: ""
    };
  }

  static getDefaultConfig = () => {
    return {};
  };

  onChange = (editorValue) => {
    this.setState({editorValue: editorValue});
    this.update(editorValue);
  };

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    let widgetVal = widgetVals[this.props.componentId];
    if (!widgetVal) {
      widgetVal = {};
      widgetVals[this.props.componentId] = widgetVal;
    }

    this.update(widgetVal);
  }

  decodeHTMLEntities = (str) => {
    var element = document.createElement("div");

    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }
    return str;
  };

  render() {
    const value = this.props.widgetVals[this.props.componentId] ?
      this.props.widgetVals[this.props.componentId] : "";
    let template = this.props.widgetConfig.value;
    let rendered = "No value...";

    // render with mustache template
    if (template) {
      if (template.indexOf("{{{") > -1 && template.indexOf("}}}") > template.indexOf("{{{")) {
        /* Example:
        1. In question: {{{ {{ &note }} }}}
        2. in widget: {{{ <div>Foo</div> }}}
         */
        template = this.decodeHTMLEntities(template);
        template = template.slice(template.indexOf("{{{") + 3, template.indexOf("}}}"));
      }

      if (value && Object.keys(value).length) {
        try {
          rendered = Mustache.render(template, value);
        } catch (error) {
          rendered = template;
        }
      } else {
        rendered = template;
      }
    }

    // Escape after render template??? audio-recordings vs lt-tmel-hte-notes-es
    // 1. User have HTML in question so we can't do this
    // 2. HTML is directly in Widget so we can't do this
    // 3. Delete only javascript/iframe/script?
    // rendered = this.decodeHTMLEntities(rendered)

    const customClasses = `${this.props.align || ""}`;
    const component = (
      <Container className={`RichTextEditorReadOnlyWidget Widget ${customClasses}`}>
        <div className="content__editor"
          dangerouslySetInnerHTML={{__html: rendered}}/>
      </Container>
    );
    return (
      component
    );
  }
}

export default RichTextEditorReadOnlyWidget;
