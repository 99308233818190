import React, {Component} from "react";
import {Button, Header, Checkbox} from "semantic-ui-react";
import PropTypes from "prop-types";
import {toastr} from "react-redux-toastr";

import ModalConfirm from "../../components/ModalConfirm";
import DragAndDropSelector from "../../components/simple/DragAndDropSelector/DragAndDropSelector";
import ajax from "../../helpers/ajax";
import config from "../../config/config";


class CopyWikiModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    wikis: PropTypes.array,
    tasks: PropTypes.array,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedTasks: [],
      selectedWiki: [],
      isPossibleRename: false
    };
  }

  onSubmitCopyTutorial = () => {
    const data = {
      wikis: this.state.selectedWiki,
      tasks: this.state.selectedTasks,
      isPossibleRename: this.state.isPossibleRename,
    };
    ajax.post([config.COPY_WIKI], {data})
      .then((response) => {
        response.data.map((item) => {
          return toastr.success("Success: " + item.details);
        });
        this.props.onClose();
      })
      .catch((error) => {
        if (Array.isArray(error.response.data)) {
          error.response.data.map((item) => {
            return toastr.error("Error: " + item.details);
          });
        } else {
          return toastr.error("Error: " + error.response.data["detail"]);
        }
      });
  }

  render() {
    const content =
      <React.Fragment>
        <Header size="medium" textAlign="center">Select wiki to copy</Header>
        <DragAndDropSelector
          choices={this.props.wikis || []}
          choiceValue={"title"}
          filterable
          selectAll
          afterSelectionChange={(newVal) => this.setState({selectedWiki: newVal})}
          selected={this.state.selectedWiki || []}
        />
        <Header size="medium" textAlign="center">Select task</Header>
        <DragAndDropSelector
          choices={this.props.tasks || []}
          choiceValue={"title"}
          filterable
          afterSelectionChange={(newVal) => this.setState({selectedTasks: newVal})}
          selected={this.state.selectedTasks || []}
        />
        <Header size="medium" textAlign="center">Rename wiki if already exists in task</Header>
        <div style={{textAlign: "center"}}>
          <Checkbox
            className="row"
            toggle
            checked={this.state.isPossibleRename}
            onChange={(e, data) => this.setState({isPossibleRename: data.checked})}
          /></div>
      </React.Fragment>;

    const isSelected = !!this.state.selectedWiki.length && !!this.state.selectedTasks.length;
    const button =
        <Button
          color="green"
          className="datahive-button"
          disabled={!isSelected}
          onClick={this.onSubmitCopyTutorial}
        >
          Save
        </Button>;

    return <ModalConfirm
      title={this.props.title}
      closeModal={this.props.onClose}
      content={content}
      button={button}
    />;
  }
}

export default CopyWikiModal;
