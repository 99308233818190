import React from "react";
import {Redirect, Route} from "react-router-dom";
import PropTypes from "prop-types";

import ErrorPage from "../components/ErrorPage";
import Perms from "../config/frontend_perms";
import urls from "../config/frontend_urls";


/**
 * This component either:
 * - redirects not logged in user on a protected route to /login
 * - redirects logged in users away from /login, /password-reset etc
 * - displays info page if user does not have access
 * - finally, renders requested component
 *
 * Private route for react navigation
 * @param Component
 * @param path
 * @param cprops - component props
 * @param rest
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({component: Component, path, cprops, ...rest}) => {
  return (
    <Route {...rest} render={(props) => {
      const perms = new Perms();
      const routePerms = perms.permissions()[path];

      if (!perms.userAuth.is_logged && routePerms.length) {
        return (
          <Redirect to={{
            pathname: "/login",
            state: {
              auto_login_path: (props.location.hash === "#autologin")
                ? props.location.pathname
                : undefined
            }
          }} />
        );
      }

      if (perms.userAuth.is_logged && !routePerms.length) {
        return <Redirect to={{pathname: urls.HOME}} />;
      }
      if (!perms.haveAccessTo(path)) {
        return <ErrorPage errorCode={403}/>;
      }

      return <Component {...props} {...cprops}/>;
    }} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  cprops: PropTypes.object,
  location: PropTypes.object,
};

export default PrivateRoute;
