import React from "react";
import PropTypes from "prop-types";
import {TimeInput as SemanticTimeInput} from "semantic-ui-calendar-react";

/**
 * Wrapper for {TimeInput} from "semantic-ui-calendar-react".
 * All props times should be in format: HH:mm
 * Component displays time in a browser-local time.
 * @param props
 * @returns {*}
 * @constructor
 */
export const TimeInput = (props) => {

  return (
    <SemanticTimeInput
      {...props}
      value={props.value}
      iconPosition="left"
      onChange={(e, data) => {
        props.onChange(data.value);
      }}
    />
  );
};

TimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
