import React, {Component} from "react";
import PropTypes from "prop-types";
import {Dropdown} from "semantic-ui-react";
import "./DropdownClasses.css";

export class DropdownClasses extends Component {
  /*
  This component displays stars based on the number it gets in the props.
   */
  static propTypes = {
    value: PropTypes.array,
    /* Values passed to the dropdown. */
    options: PropTypes.array.isRequired,
    /* Options passed to the dropdown */
    onChange: PropTypes.func.isRequired,
    /* On change handler passed to the dropdown  */
  };

  render() {
    return (
      <Dropdown
        placeholder="Classes"
        fluid multiple selection
        {...this.props}
      />
    );
  }
}

