/* eslint-disable react/forbid-foreign-prop-types */
import React from "react";
import {Container} from "semantic-ui-react";
import _ from "lodash";
import Widget from "../Widget";
import Textarea from "react-textarea-autosize";

import "./TextareaWidget.css";

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */


class TextareaWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  static getDefaultConfig = () => {
    return {
      align: "align-left",
      counter: false,
      readonly: false,
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      "characters": this.props.widgetConfig.characters,
      "enters": this.props.widgetConfig.enters
    };
  }

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    if (widgetVal && widgetVal.length) {
      return false;
    }
    return true;
  };

  isValid = () => {
    return true;
  };

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    let widgetVal = widgetVals[this.props.componentId] || "";

    this.registerEmptyValid();

    if ((this.props.widgetConfig.random) && (Array.isArray(this.props.value))) {
      widgetVal = this.props.value[Math.floor(Math.random() * this.props.value.length)];
    }

    this.update(widgetVal);
  }

  render() {
    const config = this.props.widgetConfig;
    const customClasses = `${config.align}`;
    let value = this.props.value;
    if (Array.isArray(value)) {
      value = value[0];
    }
    const value_length = value && this.state.characters ? value.length : 0;
    const value_enters = value && this.state.enters ? value.split(/\n/).length : 0;
    const counter_characters = ((config.characters) && (!config.readonly))
      && <div className={"Counter"}>
        {this.state.characters ? (
          !config.maxlength ?
            <span>Number of characters {value_length}</span> :
            <span>Characters remaining {config.maxlength - value_length}</span>
        ) : ""}</div>;

    const counter_enters = this.state.enters && !config.readonly
      && <div className={"Counter"}>
        {config.enters ? (
          <span>Number of enters {value_enters}</span>
        ) : ""}</div>;

    const component = (
      <Container className={"TextareaWidget"}>
        <div className="root">
          {config.label ?
            <div className="label"
              style={{width: `${config.labelWidth}px`}}>
              {config.label || ""}
            </div> : ""
          }
          <div className={`textarea ${config.align}`}>
            {counter_characters}
            {counter_enters}
            <Textarea
              value={value || ""}
              readOnly={config.readonly}
              maxLength={config.maxlength}
              maxRows={config.maxlength}
              onChange={(e) => {
                const value = e.target.value;
                if (!config.readonly) {
                  this.update(value);
                }
              }}
            />
          </div>
        </div>
      </Container>
    );
    return (
      component
    );
  }
}

export default TextareaWidget;
