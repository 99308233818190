import React from "react";
import { Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

import "./ShowPasswordButton.css";

const ShowPasswordButton = ({ showFunc }) => {
  return <Button type="button" basic onMouseDown={() => showFunc(true)} onMouseUp={() => showFunc(false)}
    onMouseLeave={() => showFunc(false)} onClick={(e) => e.preventDefault()}
    className={"show-pass-button"}><Icon name="eye" className="show-pass-icon" />
  </Button>;
};

ShowPasswordButton.propTypes = {
  showFunc: PropTypes.func.isRequired,
};

export default ShowPasswordButton;

