import _ from "lodash";
import React from "react";
import {withRouter, Link} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {
  Button,
  Card,
  Container,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import fs from "file-saver";

import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {updateFiltersStateless} from "../../helpers/filters";
import {convertDate, formatDate} from "../../helpers/utils";

import BasicTaskStatistics from "./BasicTaskStatistics";
import BasicGroupTasksStatistics from "./BasicGroupTasksStatistics";
import TaskStatisticsAnswersInTime from "./TaskStatisticsAnswersInTime";
import TaskStatisticsAnswersPerDay from "./TaskStatisticsAnswersPerDay";
import TaskStatisticsQuartiles from "./TaskStatisticsQuartiles";
import TaskProblematicQuestions from "./TaskProblematicQuestions";
import TaskStatisticsPercentiles from "./TaskStatisticsPercentiles";
import TaskStatisticsTable from "./TaskStatisticsTable";
import urls from "../../config/frontend_urls";

import "./TaskStatistics.css";


export class TaskStatistics extends FilteredComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    errorsClear: PropTypes.func.isRequired,
    errorsNew: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      all: true,
      is_archived: false,
      exportLoading: false,
      manual: {},
      date_range: "",
      popupOpen: false,
      possibleCompanyValues: [],
      delta_days: 7,
      copyPopupContent: "Right click to copy task slug.",
      copyPopupOpen: false
    };
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.arrayFilters = ["company"];
  }

  componentDidMount() {
    this.getPopupData();
    this.getFiltersValues();
    super.componentDidMount();
  }

  getTaskType = () => {
    ajax.get([config.TASK_IS_ARCHIVED, this.props.match.params.taskSlug])
      .then((response) => {
        this.setState({
          is_archived: response.data.archived,
        });
      })
      .catch(() => {
      });
  }

  getData = () => {
    this.getBasicStatistics();
    this.getTaskStatistics();
    if (this.props.match.params.taskSlug) {
      this.getTaskType();
      this.getTaskStatisticsQuartiles();
      this.getTaskStatisticsAnswersInTime();
      this.getTaskStatisticsAnswersPerDay();
      this.getTaskStatisticsPercentiles();
      this.getTaskProblematicQuestions();
    }
  }

  getBasicStatistics = () => {
    if (this.props.match.params.taskSlug) {
      ajax.get([config.BASIC_TASK_STATISTICS, this.props.match.params.taskSlug])
        .then((response) => {
          this.setState({
            basicStatistics: response.data,
          });
        })
        .catch(() => {
          this.setState({
            basicStatistics: {
              "status": null,
              "questions": null,
              "max_answers": null,
              "done_questions": "",
              "done_answers": ""
            },
          });
          // console.log('error', error);
        });
    } else {
      const filter_values = this.getFilters();
      let data = {
        "fields": "task,task_id,module,project,total_questions,answered_questions,fully_answered_questions,total_answers,target_answers,max_answers_per_question,is_active",
      };
      data = Object.assign(filter_values, data);
      ajax.get(config.BASIC_GROUP_TASKS_STATISTICS, {params: data})
        .then((response) => {
          this.setState({
            basicStatistics: response.data,
          });
        })
        .catch(() => {
          // console.log('error', error);
        });
    }
  }

  getFiltersValues = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        const companies = _.map(response.data, (company) => {
          return {key: company.id, value: company.name, text: company.name};
        });
        companies.push({key: 0, value: "", text: "------"});
        this.setState({possibleCompanyValues: companies});
      })
      .catch(() => {
      });
  }

  getTaskStatistics = () => {
    this.setState({taskStatisticsList: null});
    const filter_values = this.getFilters();

    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      formatDate(filter_values);
    }

    if ((filter_values.start_date && filter_values.end_date) || this.state.all) {
      if (this.props.match.params.taskSlug) {
        ajax.get([config.TASK_STATISTICS, this.props.match.params.taskSlug], {params: filter_values})
          .then((response) => {
            this.setState({taskStatisticsList: response.data});
          })
          .catch((error) => {
            if (error.request.status === 500) {
              toastr.error("Error!\nPlease use the following format: YYYY-MM-DD - YYYY-MM-DD");
            } else if (error.request.status === 404) {
              toastr.error("Error!\nTask does not exist.");
            }
            this.setState({taskStatisticsList: []});
            // console.log('error', error);
          });
      } else {
        let data = {
          "fields": "task,task_id,module,project,total_questions,answered_questions,fully_answered_questions,total_answers,target_answers,max_answers_per_question,is_active",
        };
        data = Object.assign(filter_values, data);
        ajax.get([config.GROUP_TASK_STATISTICS], {params: data})
          .then((response) => {
            this.setState({taskStatisticsList: response.data});
          })
          .catch(() => {
            this.setState({taskStatisticsList: []});
            // console.log('error', error);
          });
      }
    }
  }

  getTaskStatisticsQuartiles = () => {
    this.setState({taskStatisticsQuartiles: null});
    ajax.get([config.TASK_STATISTICS_QUARTILES, this.props.match.params.taskSlug])
      .then((response) => {
        this.setState({taskStatisticsQuartiles: response.data});
      })
      .catch(() => {
        this.setState({taskStatisticsQuartiles: []});
        // console.log('error', error);
      });
  }

  getTaskStatisticsPercentiles = () => {
    this.setState({taskStatisticsSimplePercentiles: null, taskStatisticsFixedPercentiles: null});
    ajax.get([config.TASK_STATISTICS_PERCENTILES, this.props.match.params.taskSlug])
      .then((response) => {
        const data = response.data;
        if (data) {
          const taskStatisticsSimplePercentiles = {
            task_percentiles: data.percentiles ? data.percentiles : [],
            avg_percentiles: data.avg_percentiles ? data.avg_percentiles : [],
            min_percentiles: data.min_percentiles ? data.min_percentiles : [],
            max_percentiles: data.max_percentiles ? data.max_percentiles : [],
          };
          const taskStatisticsFixedPercentiles = {
            task_percentiles: data.fixed_percentiles ? data.fixed_percentiles : [],
            avg_percentiles: data.avg_fixed_percentiles ? data.avg_fixed_percentiles : [],
            min_percentiles: data.min_fixed_percentiles ? data.min_fixed_percentiles : [],
            max_percentiles: data.max_fixed_percentiles ? data.max_fixed_percentiles : [],
          };
          this.setState({
            taskStatisticsSimplePercentiles, taskStatisticsFixedPercentiles
          });
        }
      })
      .catch(() => {
        const percentiles = {
          task_percentiles: [],
          avg_percentiles: [],
          min_percentiles: [],
          max_percentiles: []
        };
        this.setState({taskStatisticsSimplePercentiles: percentiles, taskStatisticsFixedPercentiles: percentiles});
        // console.log('error', error);
      });
  }

  getTaskProblematicQuestions = () => {
    this.setState({problematicAnsweredQuestions: null, problematicUnansweredQuestions: null});
    ajax.get([config.TASK_PROBLEMATIC_QUESTIONS, this.props.match.params.taskSlug])
      .then((response) => {
        const data = response.data;
        if (data) {
          const problematicAnsweredQuestions = data.answered;
          const problematicUnansweredQuestions = data.unanswered;
          this.setState({
            problematicAnsweredQuestions, problematicUnansweredQuestions
          });
        }
      })
      .catch(() => {
        this.setState({problematicAnsweredQuestions: [], problematicUnansweredQuestions: []});
        // console.log('error', error);
      });
  }

  getTaskStatisticsAnswersInTime = () => {
    this.setState({taskStatisticsAnswersInTime: null});
    const filter_values = this.getFilters();
    filter_values["delta_days"] = this.state.delta_days;
    ajax.get([config.TASK_STATISTICS_ANSWERS_IN_TIME, this.props.match.params.taskSlug], {params: filter_values})
      .then((response) => {
        const taskStatisticsAnswersInTime = response.data;
        this.setState({taskStatisticsAnswersInTime});
      })
      .catch(() => {
        this.setState({
          taskStatisticsAnswersInTime: {
            "real_data": {
              "dates": [],
              "idx": []
            }
          }
        });
        // console.log('error', error);
      });
  }

  getTaskStatisticsAnswersPerDay = () => {
    this.setState({taskStatisticsAnswersPerDay: null});
    const filter_values = this.getFilters();

    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      convertDate(filter_values);
    }
    delete filter_values.date_range;

    if ((filter_values.start_date && filter_values.end_date) || this.state.all) {
      ajax.get([config.TASK_STATISTICS_ANSWERS_PER_DAY, this.props.match.params.taskSlug], {params: filter_values})
        .then((response) => {
          this.setState({
            taskStatisticsAnswersPerDay: {
              "answers": response.data.answers,
              "dates": response.data.dates.map((item) => moment.utc(item).local().format("YYYY-MM-DD"))
            }
          });
        })
        .catch(() => {
          this.setState({
            taskStatisticsAnswersPerDay: {
              "answers": [],
              "dates": [],
            }
          });
          // console.log('error', error);
        });
    }
  }

  exportStatistics = () => {
    const filter_values = this.getFilters();
    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      convertDate(filter_values);
    }
    delete filter_values.date_range;

    if (_.get(this.props.match.params, "taskSlug", null) && !filter_values.selected_tasks) {
      filter_values.selected_tasks = _.get(this.props.match.params, "taskSlug", null);
    }
    this.setState({exportLoading: true});

    if ((filter_values.start_date && filter_values.end_date) || this.state.all) {
      ajax.post(config.TASKS_STATISTICS_EXPORT, {
        params: filter_values,
        "responseType": "arraybuffer"
      })
        .then((result) => {
          this.setState({exportLoading: false});
          const blob = new Blob([result.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
          fs.saveAs(blob, result.headers["x-filename"]);
        });
    }
  }

  getPopupData = () => {
    ajax.get(config.STATISTIC_MANUAL)
      .then((response) => {
        this.setState({manual: response.data});
      })
      .catch(() => {
      });
  }

  closePopup = () => {
    this.setState({
      popupOpen: false,
    });
  }

  copyToClipboard = (e, taskSlug) => {
    e.preventDefault();
    navigator.clipboard.writeText(taskSlug);
    this.setState({copyPopupContent: "Task slug copied to clipboard!"});
  };

  handleClose = () => {
    this.setState({copyPopupContent: "Right click to copy task slug."});
  };

  setAll = (flag) => {
    const date = `${moment("01", "DD").format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`;
    const date_range = flag ? "" : date;
    this.updateFilters({
      all: flag,
      date_range,
    });
    this.setState({all: flag});
  };

  renderFilter = () => {
    const params = this.getFilters();


    return (
      <Menu className={"filters-menu"}>
        <Form className="FormTaskStatistics">
          <Form.Group>
            <Form.Field width={12}>
              <Popup
                trigger={<label>Date range</label>}
                content={this.state.manual.DATE_RANGE}/>
              <DatesRangeInput
                autoComplete="off"
                dateFormat="YYYY-MM-DD"
                name="datesRange"
                placeholder="Start Date - End Date"
                iconPosition="left"
                popupPosition="bottom center"
                onChange={(e, data) => {
                  this.changeFilterInstantly({date_range: data.value});
                  this.setState({all: false});
                }}
                disabled={params.date_range ? params.all : this.state.all}
                value={params.date_range || ""}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Popup
                trigger={<label>All time</label>}
                content={this.state.manual.ALL_TIME}/>
              <Form.Checkbox
                checked={!params.date_range ? this.state.all : false}
                onChange={(e, data) => this.setAll(data.checked)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={12}>
              <Popup trigger={<label>Company</label>} content={this.state.manual.COMPANY}/>
              <Form.Dropdown
                placeholder="Company"
                fluid search multiple selection
                options={this.state.possibleCompanyValues || []}
                onChange={(e, data) => this.changeFilterInstantly({company: data.value})}
                value={params.company || []}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Popup trigger={<label>ELE</label>} content={this.state.manual.ELE}/>
              <Form.Checkbox
                checked={params.ele}
                onChange={(e, data) => this.changeFilterInstantly({ele: data.checked})}
              />
            </Form.Field>
          </Form.Group>
          <div>
            <Form.Button
              className="datahive-button"
              color="grey"
              type="submit"
              onClick={this.closePopup}
            >Close</Form.Button>
          </div>
        </Form>
      </Menu>
    );
  };

  render() {
    const dataLoaded = !!this.state.taskStatisticsList;
    let filterPopup = "";
    const isNotGrouped = this.props.match.params.taskSlug;
    const filter_values = this.getFilters();
    if (!!isNotGrouped) {
      filter_values["selected_tasks"] = this.isNotGrouped;
    }
    if (this.state.possibleCompanyValues && this.state.manual) {
      filterPopup = this.renderFilter();
    }
    const taskSlug = isNotGrouped ? this.props.match.params.taskSlug.toUpperCase() : "";
    const url_statistics = urls.BASIC_TASK_STATISTICS.replace(":taskSlug", taskSlug);
    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} style={{border: "0px", boxShadow: "none"}}>
        <Container className="TaskStatistics">
          {isNotGrouped &&
          <div style={{marginBottom: "10px"}}>
            <Link className={"ui icon"} to={url_statistics}>
              <Button size="mini" color="dark-grey" className="datahive-button">
                Go to simple task statistics
              </Button>
            </Link>
          </div>}
          <Card.Group>
            {isNotGrouped &&
            <Card fluid className="datahive-card">
              <Button.Group fluid>
                <Popup
                  on="hover"
                  position="bottom center"
                  content={this.state.copyPopupContent}
                  onClose={this.handleClose}
                  trigger={
                    <Button
                      id="task_slug"
                      className="task-button datahive-button"
                      size={"big"}
                      color={this.state.is_archived ? "grey" : "white2"}
                      onClick={() =>
                        this.props.history.push(urls.TASK.replace(":taskSlug", taskSlug.toLowerCase()))}
                      onContextMenu={(e) => this.copyToClipboard(e, taskSlug)}>
                      <Icon name="tasks"/>{taskSlug}
                    </Button>
                  }
                />
              </Button.Group>
            </Card>
            }
            <Card fluid className="datahive-card">
              <Card.Content>
                <Card.Header textAlign="center">Basic statistics</Card.Header>
              </Card.Content>
              {!isNotGrouped && <BasicGroupTasksStatistics {...this.state.basicStatistics}/>}
              {isNotGrouped && <BasicTaskStatistics {...this.state.basicStatistics}/>}
            </Card>
            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        color="black"
                        className="datahive-button"
                        floated="left"
                        loading={this.state.exportLoading}
                        onClick={this.exportStatistics}>
                        Export statistics
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Header textAlign="center">User statistics</Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Popup
                        on="click"
                        position={"bottom center"}
                        id="filter-popup"
                        trigger={
                          <Button
                            size="mini" floated="right"
                            className="datahive-button"
                            color="dark-grey"
                            onClick={() => {
                              this.setState({popupOpen: true});
                            }}>Filter <Icon name="chevron down"/>
                          </Button>
                        }
                        content={filterPopup}
                        open={this.state.popupOpen}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {dataLoaded &&
              <Card.Content>
                <TaskStatisticsTable
                  manual={this.state.manual}
                  taskSlug={this.props.match.params.taskSlug}
                  taskStatisticsList={this.state.taskStatisticsList}
                  isNotGrouped={isNotGrouped}
                  startDate={this.state.filters.start_date}
                  endDate={this.state.filters.end_date}
                  allTime={this.state.filters.all}/>
              </Card.Content>
              }
              {!dataLoaded && <LoaderSpinner/>}
            </Card>
            {isNotGrouped &&
            <React.Fragment>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Card.Header textAlign="center">Quartile boundaries</Card.Header>
                </Card.Content>
                {!!this.state.taskStatisticsQuartiles &&
                <Card.Content>
                  <TaskStatisticsQuartiles
                    manual={this.state.manual}
                    taskStatisticsQuartiles={this.state.taskStatisticsQuartiles}/>
                </Card.Content>}
                {!this.state.taskStatisticsQuartiles && <LoaderSpinner/>}
              </Card>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={<Card.Header textAlign="center">Answers in time</Card.Header>}
                    content={this.state.manual.ANSWERS_IN_TIME}/>
                  <div className={"deltaDays"}>
                    <Popup trigger={
                      <label className="chart-label">
                        Extrapolation delta days
                      </label>
                    } content={this.state.manual.EXTRAPOLATION_DAYS}/>
                    <Input
                      name="delta-days"
                      type="number" step="1"
                      value={this.state.delta_days}
                      onChange={(e, data) => this.setState({delta_days: data.value})}
                    />
                    <Button basic
                      type="submit"
                      onClick={this.getTaskStatisticsAnswersInTime}>Submit</Button>
                  </div>
                  {!!this.state.taskStatisticsAnswersInTime &&
                  <TaskStatisticsAnswersInTime
                    taskStatisticsAnswersInTime={this.state.taskStatisticsAnswersInTime}/>
                  }
                  {!this.state.taskStatisticsAnswersInTime && <LoaderSpinner/>}
                </Card.Content>
              </Card>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={<Card.Header textAlign="center">Answers per day</Card.Header>}
                    content={this.state.manual.ANSWERS_PER_DAY}/>
                  <div className={"answersPerDay"}>
                    <Popup trigger={
                      <label className="chart-label">
                        Date range: {(!this.state.all) ? `${filter_values.date_range}` : "whole task period"}
                      </label>
                    } content="Set filters to change date range."/>
                  </div>
                  {!!this.state.taskStatisticsAnswersPerDay &&
                  <TaskStatisticsAnswersPerDay
                    taskStatisticsAnswersPerDay={this.state.taskStatisticsAnswersPerDay}/>
                  }
                  {!this.state.taskStatisticsAnswersPerDay && <LoaderSpinner/>}
                </Card.Content>
              </Card>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={
                      <Card.Header textAlign="center">Simple percentiles</Card.Header>
                    } content={this.state.manual.SIMPLE_PERCENTILES}/>
                  {!!this.state.taskStatisticsSimplePercentiles &&
                  <TaskStatisticsPercentiles
                    taskStatisticsPercentiles={this.state.taskStatisticsSimplePercentiles}/>
                  }
                  {!this.state.taskStatisticsSimplePercentiles && <LoaderSpinner/>}
                </Card.Content>
              </Card>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={
                      <Card.Header textAlign="center">Fixed percentiles</Card.Header>
                    } content={this.state.manual.FIXED_PERCENTILES}/>
                  {!!this.state.taskStatisticsFixedPercentiles &&
                  <TaskStatisticsPercentiles
                    taskStatisticsPercentiles={this.state.taskStatisticsFixedPercentiles}/>
                  }
                  {!this.state.taskStatisticsFixedPercentiles && <LoaderSpinner/>}
                </Card.Content>
              </Card>
              <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={
                      <Card.Header textAlign="center">Problematic answered
                        questions</Card.Header>
                    } content={this.state.manual.PROBLEMATIC_ANSWERED_QUESTIONS}/>
                </Card.Content>
                {!!this.state.problematicAnsweredQuestions &&
                <Card.Content>
                  <TaskProblematicQuestions
                    problematicQuestions={this.state.problematicAnsweredQuestions}/>
                </Card.Content>}
                {!this.state.problematicAnsweredQuestions && <LoaderSpinner/>}
              </Card> <Card fluid className="datahive-card">
                <Card.Content>
                  <Popup
                    position={"top center"}
                    trigger={<Card.Header textAlign="center">Problematic unanswered
                    questions</Card.Header>
                    }
                    content={this.state.manual.PROBLEMATIC_UNANSWERED_QUESTIONS}/>
                </Card.Content>
                {!!this.state.problematicUnansweredQuestions &&
              <Card.Content>
                <TaskProblematicQuestions
                  problematicQuestions={this.state.problematicUnansweredQuestions}/>
              </Card.Content>}
                {!this.state.problematicUnansweredQuestions && <LoaderSpinner/>}
              </Card>
            </React.Fragment>
            }
          </Card.Group>
        </Container>
        <Dimmer
          className={"invisible-dimmer"}
          active={this.state.popupOpen}
          onClickOutside={this.closePopup}/>
      </Dimmer.Dimmable>
    );
  }
}

export default withRouter(TaskStatistics);
