import * as actionType from "../actions/types/types_notifications";

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {
  case actionType.NOTIFICATIONS_READ:
    return action.notifications || initState;
  case actionType.NOTIFICATIONS_READED:
    return action.notifications || initState;
  case actionType.NOTIFICATIONS_SENT_BY_ME:
    return action.notifications || initState;
  case actionType.NOTIFICATIONS_MARK_AS_READ:
    return initState;
  default:
    return state;
  }
}
