/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {withRouter} from "react-router-dom";
import {Button, Progress} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import config from "../../../config/config";
import ajax from "../../../helpers/ajax";

import "./FileUploadWidget.css";
import Widget from "../Widget";


const WARNING_MESSAGE = "In editor mode uploading files is disabled. " +
  "It works only on real scenario of task!";

class FileUploadWidget extends Widget {
  static propTypes = {
    ...Widget.propTypes,
    /* Id of question. Its necessary for saving files on the backend. */
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static getDefaultConfig = () => {
    return {};
  };

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    return !(widgetVal && widgetVal.length);
  };

  isValid = () => {
    return true;
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  componentDidMount() {
    this.registerEmptyValid();

    if (this.props.editorMode) {
      return;
    }
    this.getUploadedFiles();
  }

  getUploadedFiles = () => {
    const taskSlug = this.props.location?.pathname?.split('/')[2]
    const params = {
      questionId: this.props.questionId,
      username: this.props.answerAuthor,
      slug: taskSlug
    };
    ajax.get(config.FILE_UPLOAD_WIDGET_UPLOADED, {params})
      .then((response) => {
        const data = response.data;
        this.setState({uploadedFiles: data});
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  onUploadProgress = (e) => {
    if (e.type === "progress") {
      this.setState({progress: _.round(e.loaded / e.total * 100, 2)});
    }
  };

  onDrop = (files) => {
    if (this.props.editorMode) {
      toastr.warning(WARNING_MESSAGE);
      return;
    }
    const widgetConfig = this.props.widgetConfig;
    const data = new FormData();
    data.append("questionId", this.props.questionId);
    let files_cnt = 0;
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    const fileCountLimit = parseInt(widgetConfig.fileCountLimit, 10);
    if (files.length + this.state.uploadedFiles.length + (widgetVal ? widgetVal.length : 0) > fileCountLimit) {
      toastr.error(
        "Too many files!",
        `Maximum amount of files: ${fileCountLimit}`
      );
      return;
    }

    const acceptedFileTypes = _.map(widgetConfig.acceptedFileTypes, (o) => {
      const t = _.last(o.split("."));
      return t.toUpperCase();
    });

    _.each(files, (file, idx) => {
      if (!!widgetConfig.acceptedFileTypes && !!widgetConfig.acceptedFileTypes.length
        && !_.includes(acceptedFileTypes, _.last(file.name.split(".")).toUpperCase())) {
        toastr.error(
          "Wrong file format!",
          `Accepted file formats: ${widgetConfig.acceptedFileTypes.join(", ")}`
        );
        return;
      }
      files_cnt += 1;
      data.append(`file${idx}`, file, file.name);
    });
    if (!files_cnt) {
      return;
    }
    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const taskSlug = this.props.location?.pathname?.split('/')[2]
    data.append("slug", taskSlug)

    ajax.post(config.FILE_UPLOAD_WIDGET, {
      data,
      options,
      onUploadProgress: this.onUploadProgress,
    })
      .then((response) => {
        toastr.success("Success!", "File saved");
        const data = response.data;
        const widgetVals = _.cloneDeep(this.props.widgetVals);
        let widgetVal = widgetVals[this.props.componentId];
        if (!widgetVal) {
          widgetVals[this.props.componentId] = _.map(data, (el) => el.path);
          widgetVal = widgetVals[this.props.componentId];
        } else {
          widgetVal = widgetVals[this.props.componentId].concat(_.map(data, (el) => el.path));
        }

        this.update(widgetVal);
        this.getUploadedFiles();
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  deleteFiles = () => {
    if (!window.confirm("Are you sure you want to delete all files for this question?")) {
      return;
    }
    const taskSlug = this.props.location?.pathname?.split('/')[2]
    const data = {questionId: this.props.questionId, slug: taskSlug};
    ajax.delete(config.FILE_UPLOAD_WIDGET_UPLOADED, {data})
      .then(() => {
        const widgetVal = [];

        this.update(widgetVal);
        this.getUploadedFiles();
        toastr.success("Success!", "File deleted!");
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  deleteFile = (e, file) => {
    e.preventDefault();
    if (!window.confirm(`Are you sure you want to delete file ${file}`)) {
      return;
    }
    const taskSlug = this.props.location?.pathname?.split('/')[2]
    const data = {
      questionId: this.props.questionId,
      slug: taskSlug,
      file: _.last(file.split("/"))
    };
    ajax.delete(config.FILE_UPLOAD_WIDGET_UPLOADED, {data})
      .then((response) => {
        const widgetVals = _.cloneDeep(this.props.widgetVals);
        let widgetVal = widgetVals[this.props.componentId];
        widgetVal = _.filter(widgetVal, (file) => {
          return response.data.file_deleted !== _.last(file.split("/"));
        });
        this.update(widgetVal);
        this.getUploadedFiles();
        toastr.success("Success!", "File deleted!");
      })
      .catch((error) => {
        toastr.error("Error!", error);
      });
  };

  renderFile = (file, message, idx) => {
    const MEDIA_URL = (window.BACKEND_URL || config.BACKEND_URL) + config.MEDIA_SUFFIX;
    const button = (
      <Button
        size={"mini"}
        color={"red"}
        icon={"trash"}
        onClick={(e) => this.deleteFile(e, file)}
      />
    );
    if (this.props.widgetConfig.showImagePreview) {
      return (
        <div key={idx}>
          <img src={MEDIA_URL + file} alt={""}/>
          <br/>
          {message}
          {button}
        </div>
      );
    } else {
      return (
        <li key={idx}>{_.last(file.split("/"))} {message} {button}</li>
      );
    }
  };

  renderFiles = (widgetVal) => {
    // all files in answer
    let files = _.map(widgetVal, (file, idx) => {
      let message = null;
      const found = _.filter(this.state.uploadedFiles, (elem) => {
        return elem.path === file;
      });
      if (!found.length) {
        message = "This file is in answer but is not uploaded! ";
      }
      return this.renderFile(file, message, idx);
    });
    //add files that are uploaded but not in answer.
    const uploaded = _.reduce(this.state.uploadedFiles, (acc, elem) => {
      const found = _.filter(widgetVal, (file) => {
        return elem.path === file;
      });
      if (!found.length) {
        acc.push(this.renderFile(elem.path, "This file was uploaded but is not in answer!", acc.length + files.length));
      }
      return acc;
    }, []);
    files = _.concat(files, uploaded);
    return files;
  };

  handleClickInEditor = () => {
    if (this.props.editorMode) {
      toastr.warning(WARNING_MESSAGE);
    }
  };

  render() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];
    return (
      <div className={"FileUploadWidget "}>
        {this.state.progress &&
        <Progress percent={this.state.progress} progress success/>
        }
        <div className={"dropzone-container"} onClick={this.handleClickInEditor}>
          <Dropzone
            disabled={this.props.editorMode}
            onDrop={this.onDrop}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span>Drop files</span>
                  <ul className="files">
                    {(widgetVal || this.state.uploadedFiles) && this.renderFiles(widgetVal)}
                  </ul>
                </div>
              </section>
            )}
          </Dropzone>
          {widgetVal && widgetVal.length > 0 &&
          <Button content={"Delete files"} onClick={this.deleteFiles} icon={"delete"}/>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(FileUploadWidget);
