import _ from "lodash";
import React from "react";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {Link} from "react-router-dom";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {Button, Card, Container, Dimmer, Form, Grid, Header, Icon, Radio, Checkbox, Dropdown,
  Segment, Table, FormDropdown, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import urls from "../../config/frontend_urls";
import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {updateFiltersStateless} from "../../helpers/filters";

import "./TaskStatistics.css";


export class TaskStatisticsBasic extends FilteredComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      date_range: "",
      isDateRange: false,
      deadlines: [],
      taskStatisticsList: [],
    };
    this.defaultFilters = {all: true};
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.arrayFilters = ["deadlines", "company_id"];
  }

  componentDidMount() {
    this.getCompanies();
    this.getDeadlines();
    this.getTaskStatistics();
    super.componentDidMount();
  }

  getData = () => {}

  getCompanies = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        const companies = response.data;
        if (this.props.auth.is_staff && !_.find(response.data, ["id", -1])) {
          companies.push({
            id: -1,
            name: "No company assigned"
          });
        }
        if (!this.props.auth.is_staff && companies.length === 1) {
          this.updateFilters({company_id: [companies[0].id]});
        }
        this.setState({
          companies: companies,
        });
      });
  };

  getDeadlines = () => {
    ajax.get(config.LINGUISTS_MONTH_REPORTS_DEADLINE)
      .then((response) => {
        this.setState({deadlines: response.data});
      })
      .catch(() => {
        this.setState({deadlines: []});
        this.showErrorForm("Error fetching deadlines!");
      });
  };

  getTaskStatistics = () => {
    this.setState({taskStatisticsList: null});
    const filter_values = this.getFilters();
    const taskSlug = this.props.match.params.taskSlug;
    if (taskSlug) {
      ajax.get([config.TASK_STATISTICS_USER, taskSlug], {params: filter_values})
        .then((response) => {
          this.setState({taskStatisticsList: response.data});
        })
        .catch((error) => {
          if (error.request.status === 404) {
            toastr.error("Error!\nTask does not exist.");
          }
          this.setState({taskStatisticsList: []});
        });
    }
  }

  getFilterOptionsGeneric = (objs) => {
    return _.map(objs, (o) => {
      return {
        key: o.id,
        value: o.id,
        text: o.name ? o.name : o.id
      };
    });
  };

  changeMainFilter = (field, value) => {
    this.setState(
      {reports: [],
        totalPages: 1,
        displayLoaderSpinner: false},
      () => this.changeFilterInstantly({
        [field]: value,
        module_name: [],
        project_name: [],
        tasks: [],
        order_by: null,
        allTime: null,
      }));
  }

  isDateInDeadline = (date, start_date, end_date) => {
    return moment(moment(date).format("YYYY-MM-DD")).isBetween(moment(start_date), moment(end_date));
  }

  checkDeadlines(date) {
    const {deadlines} = this.state;
    let start_date;
    let end_date;
    if (date) {
      [start_date, end_date] = date.split(" - ");
    }
    const deadlinesIds = deadlines.filter((item) => this.isDateInDeadline(item.start, start_date, end_date)
      || this.isDateInDeadline(item.end, start_date, end_date)).map((item) => item.id);
    return deadlinesIds;
  }

  renderRows() {
    let rows = null;
    if (this.state.taskStatisticsList && this.state.taskStatisticsList.length) {
      const tablerow = this.state.taskStatisticsList[0].reports;
      rows = _.map(tablerow, (item) => {
        const tempo = item.hours && item.activity.answers ?
          (item.activity.answers / item.hours).toFixed(2) : "-";
        return (
          <Table.Row key={"user" + item.user.username + item.activity.answers}>
            <Table.Cell>{item.user.username}</Table.Cell>
            <Table.Cell>{item.deadline_name}
              {item.description && <Popup
                flowing
                position={"top center"}
                content={<>
                  <div>Description:</div>
                  <div>{item.description}</div>
                </>}
                trigger={<Icon className="deadlineDescription" name={"info circle"}/>}
              />}
            </Table.Cell>
            <Table.Cell>{item.activity.answers ? item.activity.answers : 0}</Table.Cell>
            <Table.Cell>{item.activity.reviews ? item.activity.reviews : 0}</Table.Cell>
            <Table.Cell>{item.activity.evaluation_answers ? item.activity.evaluation_answers : 0}</Table.Cell>
            <Table.Cell>{item.activity.evaluation_reviews ? item.activity.evaluation_reviews : 0}</Table.Cell>
            <Table.Cell>{tempo}</Table.Cell>
            <Table.Cell>{item.hours}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }

  renderTable = () => {
    const rows = this.renderRows();
    return (
      <Table sortable celled structured textAlign="center" className="SortableTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan={2}>Username</Table.HeaderCell>
            <Table.HeaderCell rowSpan={2}>Deadline</Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>Activity</Table.HeaderCell>
            <Table.HeaderCell rowSpan={2}>Tempo</Table.HeaderCell>
            <Table.HeaderCell rowSpan={2}>Estimated hours</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Answers</Table.HeaderCell>
            <Table.HeaderCell>Reviews</Table.HeaderCell>
            <Table.HeaderCell>Evaluation Answers</Table.HeaderCell>
            <Table.HeaderCell>Evaluation Review</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList && this.state.taskStatisticsList.length ?
                this.state.taskStatisticsList[0].answers_count : 0}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList && this.state.taskStatisticsList.length ?
                this.state.taskStatisticsList[0].reviews_count : 0}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList && this.state.taskStatisticsList.length ?
                this.state.taskStatisticsList[0].evaluation_answers_count : 0}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList && this.state.taskStatisticsList.length ?
                this.state.taskStatisticsList[0].evaluation_reviews_count : 0}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList[0]?.answers_count && this.state.taskStatisticsList[0]?.sum ?
                (this.state.taskStatisticsList[0].answers_count / this.state.taskStatisticsList[0].sum).toFixed(2) : 0}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.state.taskStatisticsList && this.state.taskStatisticsList.length ?
                this.state.taskStatisticsList[0].sum : 0}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>);
  }

  render() {
    const params = this.getFilters();
    const filtersOption =
      <Form className={"usersStatisticsFilters"}>
        <Form.Group widths="3" style={{justifyContent: "center"}}>
          <Form.Field className={"ui center aligned"}>
            Company
            <FormDropdown
              fluid search multiple selection
              className="Company"
              placeholder="All"
              options={this.getFilterOptionsGeneric(this.state.companies || [])}
              value={_.compact(_.concat([], _.map(params.company_id, (o) => parseInt(o))))}
              onChange={(e, {value}) => this.changeMainFilter("company_id", value)}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal" style={{textAlign: "center", justifyContent: "center"}}>
          <Form.Field className={"ui center aligned"}>
            {params.all && <Form.Checkbox
              style={{marginLeft: "0 px"}}
              checked={params.all || false}
              label={params.all ? "All time" : ""}
              onChange={() => {
                this.changeFilterInstantly({
                  all: !params.all,
                  dateRange: "",
                  deadlines: []
                });
              }}/>}
            {!params.all && <>
              Deadlines
              {!this.state.isDateRange && <Dropdown
                className={"deadline-dropdown"}
                placeholder="Deadlines"
                fluid search multiple selection
                options={this.getFilterOptionsGeneric(this.state.deadlines)}
                value={_.compact(_.concat([], _.map(params.deadlines, (o) => parseInt(o))))}
                onChange={(e, {value}) => this.changeMainFilter("deadlines", value)}
              />}
              {this.state.isDateRange && <DatesRangeInput
                className={"datesRange"}
                autoComplete="off"
                dateFormat="YYYY-MM-DD"
                name="datesRange"
                placeholder="Start Date - End Date"
                value={params.dateRange || ""}
                iconPosition="left"
                popupPosition="bottom center"
                onChange={(e, data) => {
                  const newVal = data.value;
                  const deadlineIds = this.checkDeadlines(newVal);
                  this.setState({reports: []},
                    this.changeFilterInstantly({
                      deadlines: deadlineIds,
                      order_by: null,
                      dateRange: newVal,
                      groupByProject: params.groupByProject
                    }));
                }}/>}</>}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="4" style={{textAlign: "center", justifyContent: "center"}}>
          <Form.Field className={"ui center aligned"} >
            {!params.all && <Radio
              className="filtersRadio"
              slider
              label={"Date range"}
              checked={this.state.isDateRange}
              onChange={(e, data) => this.setState({isDateRange: data.checked})}/>}
          </Form.Field>
          <Form.Field>
            {!params.all && <Checkbox
              className="filtersCheckbox"
              checked={params.all || false}
              label={!params.all ? "All time" : ""}
              onChange={() => {
                this.changeFilterInstantly({
                  all: !params.all,
                  dateRange: "",
                  deadlines: []
                });
              }}/>}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field className={"ui center aligned"}>
            <Form.Button
              color="dark-grey"
              className="submitButton datahive-button"
              type="submit"
              onClick={this.getTaskStatistics}>
              Submit
            </Form.Button>
          </Form.Field>
        </Form.Group>
      </Form>;


    const dataLoaded = !!this.state.taskStatisticsList;
    const isNotGrouped = this.props.match.params.taskSlug;
    const filter_values = this.getFilters();
    if (!!isNotGrouped) {
      filter_values["selected_tasks"] = this.isNotGrouped;
    }

    const taskSlug = this.props.match.params.taskSlug;
    const url_statistics = urls.TASK_STATISTICS.replace(":taskSlug", taskSlug);

    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} style={{border: "0px", boxShadow: "none"}}>
        <Container>
          <div style={{marginBottom: "10px"}}>
            <Link className={"ui icon"} to={url_statistics}>
              <Button size="mini" className="datahive-button" color="dark-grey">
                Go to advanced task statistics
              </Button>
            </Link>
          </div>
          <Card.Group>
            <Card fluid className="datahive-card">
              <Button.Group fluid>
                <Button
                  id="task_slug"
                  className="task-button datahive-button"
                  size="big"
                  color="white2"
                  onClick={() => this.props.history.push(
                    urls.TASK.replace(":taskSlug", taskSlug.toLowerCase()))}>
                  <Icon name="tasks"/>
                  {taskSlug}
                </Button>
              </Button.Group>
            </Card>
            <Card fluid className="datahive-card">
              {filtersOption}
            </Card>
            <Card fluid className="TaskStatisticsBasic datahive-card">
              <Card.Content>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column textAlign="center">
                      <Popup
                        flowing
                        position="top center"
                        trigger={<Header>Users statistics related to Reports
                          <Icon className="datahive-icon dark-grey popupHeader" size="mini" name={"info circle"}/></Header>}
                        content={<div>
                          <div>View of basic statistics is related to Reports.</div>
                          <div>Only users who added the report are visible.</div>
                          <div>e.g. Allows to estimate how many hours have already been reported by users in simple way.</div>
                        </div>}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {dataLoaded &&
              <Card.Content>
                {this.renderTable()}
              </Card.Content>}
              {!dataLoaded && <LoaderSpinner/>}
            </Card>
          </Card.Group>
        </Container>
      </Dimmer.Dimmable>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export default connect(mapStateToProps)(TaskStatisticsBasic);
