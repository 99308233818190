import _ from "lodash";
import React from "react";

import "./SIOSTRAWidget.css";
import {validateRelationships} from "./validation";
import {getAppropriateConnectorTypes} from "../../simple/TreeSelect/utils";
import Modal from "./Modal";
import ConnectorModal from "./ConnectorModal";
import SIOSTRADisplay from "./SIOSTRADisplay";
import Widget from "../Widget";
import {defaultSIOSTRAConfig} from "../../../containers/WidgetsEdit/defaultConfigs";

class SIOSTRAWidget extends Widget {
  /*
  He wore the crown of the Java Sentinels, and those that tasted the bite of his code named him... the DOM Slayer.

  This widget heavily uses the following SVG elements: g, text, path, rect. Please read about them here:
  https://www.w3schools.com/graphics/svg_inhtml.asp
  Overview of how the widget works:
  During React render, unpositioned elements are rendered. First, text is split into 'abstract' row objects that contain metadata.
  This is done in the calls made by getRowData function. After that, each row is rendered separately.
  Each row has 5 components: background, connectors, labels, text, idx.
  Each of those is rendered separately. During render elements are unpositioned - overlapping, etc.
  Positioning elements is handled in the positionEVERYTHING function.
  This function calls other functions to position nested elements, first horizontally and then vertically,
  when necessary. Usually, the logic of positioning is the following:
   - position elements horizontally
   - check if they overlap horizontally
   - if they overlap, slide them apart vertically
  Background and row numbers are positioned after all other components.
  The string to be annotated is taken from widget value, as it is question dependant, and a pure display widget would not
  make sense here. special field named originalString, placed beside value, is used.
  */

  static getDefaultConfig = () => {
    return {
      align: "align-left",
      ...defaultSIOSTRAConfig,
    };
  };

  constructor(props) {
    super(props);

    // set values for editor
    if (props.editorProps) {
      this.createEditor(props);
    }

    this.state = {};
    this.popupPosition = {x: 0, y: 0};
    this.updateRanges = this.updateRanges.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.deleteRange = this.deleteRange.bind(this);
    this.siostraDOM = React.createRef();
  }

  componentDidMount() {
    if (this.siostraDOM.current) { //because there is no DOM in tests
      this.siostraDOM.current.addEventListener("mousemove", this.throttledGetMousePosition);
    }
  }

  componentWillUnmount() {
    if (this.siostraDOM.current) { //because there is no DOM in tests
      this.siostraDOM.current.removeEventListener("mousemove", this.throttledGetMousePosition);
    }
  }

  getMousePosition = (e) => {
    if (this.siostraDOM.current !== null) {
      this.popupPosition = {
        x: e.offsetX < this.siostraDOM.current.offsetWidth / 2 ? e.offsetX : this.siostraDOM.current.offsetWidth / 2,
        y: e.offsetY + 60
      };
    }
  }

  throttledGetMousePosition = _.throttle(this.getMousePosition, 250);

  getRanges = () => {
    const value = this.props.widgetVals[this.props.componentId];
    return _.get(value, "ranges") ? value.ranges : [];
  };

  getOriginalString = () => {
    const placeholder = "Placeholder string. Second placeholder sentence. Last placeholder extra sentence, this one is longer.";
    return _.get(this.props.widgetVals, `[${this.props.componentId}]originalString`, placeholder);
  };

  isEmpty = () => {
    const ranges = this.getRanges() || [];
    if (!!_.filter(ranges, (range) => !range.type).length) {
      return true;
    }
    return ranges.length === 0;
  };

  isValid = () => {
    const ranges = _.orderBy(this.getRanges(), ["begin"]);

    const validatedRanges = validateRelationships(ranges, this.props.widgetConfig.overlapRules);
    const valid = _.reduce(validatedRanges, (valid, range) => {
      if (!range.valid) {
        return false;
      }
      return valid;
    }, true);

    if (_.filter(ranges, (range) => !range.type).length > 0 && ranges.length > 0) {
      return false;
    }

    return valid;
  };

  updateState = (modalData) => {
    this.setState({
      ...modalData,
    });
  };

  updateRange(updatedRange) {
    const ranges = [...this.getRanges()];
    const sameEntityRanges = _.filter(ranges, (o) => o.entityId === updatedRange.entityId && o.id !== updatedRange.id);
    _.each(sameEntityRanges, (r) => {
      r.type = updatedRange.type;
      r.name = updatedRange.name;
      r.attributes = updatedRange.attributes;
      r.note = updatedRange.note;
    });
    const rangeIdx = _.findIndex(ranges, {id: updatedRange.id});
    ranges[rangeIdx] = updatedRange;
    this.updateRanges(ranges);
  }

  deleteRange(id) {
    let ranges = [...this.getRanges()];
    ranges = _.filter(ranges, (r) => {
      return r.id !== id;
    });
    this.updateRanges(ranges);
    this.setState({
      selectedRangeId: null,
      displayModal: false,
    });
  }

  updateRanges(ranges) {
    const value = this.props.widgetVals[this.props.componentId];
    const validatedRanges = validateRelationships(ranges, this.props.widgetConfig.overlapRules);
    const widgetVal = {
      ...value,
      ranges: validatedRanges
    };
    this.update(widgetVal);
  }

  addFragment = (entityId) => {
    this.setState({
      newRangeEntityId: entityId,
      displayModal: false,
    });
  };

  render() {
    this.registerEmptyValid();

    const ranges = this.getRanges();
    const originalString = this.getOriginalString();
    const connectorTypes = this.props.widgetConfig.connectorTypes;
    const rangeTypes = this.props.widgetConfig.rangeTypes;

    const selectedRange = _.find(ranges, {id: this.state.selectedRangeId});
    const selectedConnector = selectedRange ? _.find(selectedRange.connectedTo, {id: this.state.selectedConnectorId}) : null;
    const selectedRangeContent = selectedRange ? originalString.substr(selectedRange.begin, selectedRange.end - selectedRange.begin) : null;
    const targetRange = selectedConnector && selectedRange.connectedTo ? _.find(ranges, (r) => r.id === selectedConnector.targetId) : null;

    return (
      <div className={"SIOSTRAWidget Widget"} ref={this.siostraDOM}>
        {originalString &&
        <SIOSTRADisplay
          updateRanges={this.updateRanges}
          updateRange={this.updateRange}
          deleteRange={this.deleteRange}
          originalString={originalString}
          rangeTypes={rangeTypes}
          connectorTypes={connectorTypes}
          ranges={_.orderBy(ranges, ["begin"])}
          widgetConfig={this.props.widgetConfig}
          updateParentState={this.updateState}
          newRangeEntityId={this.state.newRangeEntityId}
        />
        }
        {
          this.state.displayModal && this.state.selectedRangeId && selectedRange &&
          <Modal
            default={this.popupPosition}
            closeModal={() => {
              this.setState({displayModal: false});
            }}
            open={this.state.displayModal && this.state.selectedRangeId !== null}
            update={this.updateRange}
            deleteRange={() => {
              this.deleteRange(this.state.selectedRangeId);
            }}
            range={selectedRange}
            selectedText={selectedRangeContent}
            rangeTypes={rangeTypes || []}
            connectorTypes={connectorTypes || []}
            maxRangeEnd={originalString.length - 1}
            enableNotes={this.props.widgetConfig.enableRangeNotes}
            newRangeEntityId={this.state.newRangeEntityId}
            addFragment={this.addFragment}
            disableAddFragment={this.props.widgetConfig.disableAddFragment}
            trimSelectedText={this.props.widgetConfig.enableSelectionTrimming}
          />
        }
        {
          this.state.displayConnectorModal && this.state.selectedRangeId && selectedConnector &&
          <ConnectorModal
            default={this.popupPosition}
            closeModal={() => {
              this.setState({displayConnectorModal: false});
            }}
            open={this.state.displayConnectorModal && this.state.selectedRangeId !== null}
            update={this.updateRange}
            range={selectedRange}
            targetRange={targetRange}
            connector={selectedConnector}
            connectorTypes={getAppropriateConnectorTypes(selectedRange, targetRange, _.cloneDeep(connectorTypes), rangeTypes) || []}
            enableNotes={this.props.widgetConfig.enableConnectorNotes}
            originalString={originalString}
          />
        }
      </div>
    );
  }
}

export default SIOSTRAWidget;
