import React, {Component} from "react";
import {Checkbox, Icon, Popup, Table, Button, Label} from "semantic-ui-react";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import urls from "../../../../config/frontend_urls";
import TaskImprint from "./TaskImprint";
import {filterClear, loadTasks, updateFilter} from "../../../../actions/actions_tasks";
import TaskListProgressBar from "./TaskListProgressBar";

import "./TaskListItem.css";

export class TaskListRow extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    task: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      is_active: PropTypes.bool.isRequired,
      owner_username: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      modified: PropTypes.string.isRequired,
      is_moderator: PropTypes.bool.isRequired,
      end_date: PropTypes.string,
      coordinator_username: PropTypes.string,
      is_favorite: PropTypes.bool.isRequired,
      enable_evaluations: PropTypes.bool.isRequired,
      favorites_pin_color: PropTypes.string
    }).isRequired,
    imprint: PropTypes.shape({
      answers: PropTypes.shape({
        total: PropTypes.number,
        done: PropTypes.number,
        left: PropTypes.number,
      }),
      users: PropTypes.shape({
        total: PropTypes.number,
        active: PropTypes.number,
      }),
      estimated_end: PropTypes.string,
      assigned: PropTypes.number
    }),
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    updateFavorites: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.locale = window.navigator.userLanguage || window.navigator.language;
    this.date_format = this.locale === "pl" ? "DD.MM.YYYY" : "L";
  }

  renderImprint = (task) => {
    const imprint = this.props.imprint;
    if (imprint) {
      return (
        <Popup
          content={<TaskImprint imprint={this.props.imprint} type={task.task_type}/>}
          trigger={
            <Link to={urls.TASK.replace(":taskSlug", task.slug)}>
              {task.title}
            </Link>
          }
        />
      );
    }

    return (
      <Link to={urls.TASK.replace(":taskSlug", task.slug)}>
        {task.title}
      </Link>
    );
  };

  render() {
    const t = this.props.task;
    const colors = ['db244d', 'f85e00', 'fa9500', 'fbbd08', 'a5be00', '2e933c', '028090', '1d4e89', '6f2dbd', '000000'];
    const url_statistics = urls.BASIC_TASK_STATISTICS.replace(":taskSlug", t.slug);
    const url_fastusers = urls.BASIC_ADMIN_FAST_USERS.replace(":taskSlug", t.slug);
    const url_ratings = urls.TASK_RATINGS.replace(":taskSlug", t.slug);
    const questions = urls.ADMIN_QUESTIONS_BY_TASK_LIST.replace(":taskSlug", t.slug);
    const answers = urls.ADMIN_ANSWERS_BY_TASK_LIST.replace(":taskSlug", t.slug);

    let optionsPopups = null;
    if (this.props.auth.is_superuser || (t.is_moderator && this.props.auth.is_staff)) {
      optionsPopups = <>
        {this.props.auth.has_user_data_access &&
          <Popup
            content={"Edit task"}
            trigger={
              <Link className={"ui icon button"}
                to={urls.ADMIN_TASK_DETAIL.replace(":taskSlug", t.slug)}
              >
                <Icon name={"wrench"}/>
              </Link>
            }
          />
        }
        <Popup
          content={this.props.auth.has_user_data_access ? "Task users" : "Task statistics"}
          trigger={this.props.auth.has_user_data_access ?
            <Link className={"ui icon button"} to={url_fastusers}><Icon name={"user circle"}/></Link> :
            <Link className={"ui icon button"} to={url_statistics}><Icon name={"bar chart"}/></Link>
          }
        />
        <Popup
          content={"Task ratings"}
          trigger={
            <Link className={"ui icon button"} to={url_ratings}>
              <Icon name={"star"}/>
            </Link>
          }
        />
        <Popup
          content={"Task questions"}
          trigger={
            <Link className={"ui icon button"} to={questions}>
              Q
            </Link>
          }
        />
        <Popup
          content={"Task answers"}
          trigger={
            <Link className={"ui icon button"} to={answers}>
              A
            </Link>
          }
        />
      </>;
    }

    /* eslint-disable react/no-unescaped-entities */
    return (
      <Table.Row className={"TaskListItem"} key={t.id}>
        <Table.Cell
          textAlign="center"
          className="favorites-cell"
          >
          {t.is_favorite ?
              <Button
                  icon="pin"
                  className="favorites"
                  style={{color: t.is_favorite ? `#${t.favorites_pin_color}` : "#D8D8D8"}}
                  onClick={() => this.props.updateFavorites(t.slug, t.is_favorite, "#fbbd08")}
              />
          :
          <Popup
              content={colors.map((color) =>
                  <Button key={color}
                      icon="pin"
                      className="pin-color-item"
                      style={{color: `#${color}`}}
                      onClick={() => this.props.updateFavorites(t.slug, t.is_favorite, color)}
                  />
              )}
              on='click'
              pinned
              trigger={<Button
                  icon="pin"
                  className="favorites"
                  style={{color: t.is_favorite ? "#fbbd08" : "#D8D8D8"}}
              />}
          />}
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            checked={this.props.selected}
            onChange={(e, data) => this.props.onSelect(t.id, data)}/>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Icon name={t.is_active ? "check circle" : "times"}
            className={t.is_active ? "green-icon" : "red-icon"}/>
        </Table.Cell>
        <Table.Cell>
          <div className="evaluationItem">
            {this.renderImprint(t)}
            {t.enable_evaluations && <Label circular color="grey" size="mini">eval</Label>}
          </div>
        </Table.Cell>
        <Table.Cell>
          {t.end_date ? moment(t.end_date).format(this.date_format) : ""}
        </Table.Cell>
        <Table.Cell >
          {!!this.props.imprint && this.props.imprint.answers.total > 0 &&
          <TaskListProgressBar assigned={this.props.imprint.assigned} total={this.props.imprint.answers.total} done={this.props.imprint.answers.done}/>}
        </Table.Cell>
        <Table.Cell className={"actions"}>
          {optionsPopups}
        </Table.Cell>
        <Table.Cell>
          {t.owner_username}
        </Table.Cell>
        <Table.Cell>
          {t.coordinator_username}
        </Table.Cell>
        <Table.Cell>
          <Popup
            content={moment(t.created).format("L LT")}
            trigger={<div>{t.created ? moment(t.created).format(this.date_format) : ""}</div>}/>
        </Table.Cell>
        <Table.Cell>
          <Popup
            content={moment(t.modified).format("L LT")}
            trigger={<div>{t.modified ? moment(t.modified).format(this.date_format) : ""}</div>}/>
        </Table.Cell>
      </Table.Row>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export default withRouter(connect(
  mapStateToProps,
  ({
    loadTasks,
    filterClear,
    updateFilter
  })
)(TaskListRow));
