import _ from "lodash";
import axios from "axios";
import config from "../config/config";
import store from "../store/store";
import {logout} from "../actions/actions_auth";
import {errorsNew} from "../actions/actions_errors";
import {networkErrorsNew} from "../actions/actions_network_errors";

export default class Ajax {
  /*
    convert url to full API url
   */

  static _url(url) {
    const API_URL = (window.BACKEND_URL || config.BACKEND_URL) + config.API_SUFFIX;
    let newurl = API_URL, options, parts;
    if (_.isObject(url)) {
      // [api_url, parameter_1, parameter_2]
      newurl += url.slice(0, 1);
      options = url.slice(1);
    } else {
      // direct api url without extra parameters
      newurl += url;
    }
    parts = newurl.split(/\(|\)/);
    // eslint-disable-next-line no-unused-vars
    parts = _.map(parts, function (v, k) {
      if (v.indexOf("/") > -1) {
        return v;
      } else {
        if (!options) {
          return null;
        }
        return options.shift();
      }
    });
    // console.log("URL[FINAL]", parts, _.join(parts, ""));
    return _.join(parts, "");
  }

  // send axios request
  static _request(type, url, options) {
    var defaults = {
      method: type,
      url: this._url(url),
      dataType: "json",
      headers: {
        "Service": config.SERVICE
      },
      withCredentials: true
    };

    const merged = _.merge(defaults, options);

    return axios(merged)
      .catch((error) => {
        const status = _.get(error, "response.status"),
          data = JSON.stringify(_.get(error, "response.data")),
          message = `METHOD:${error.config.method} URL:${error.config.url}, STATUS:${status}, DATA:${data}`;

        console.debug("%cAXIOS:", "background: #ff0000; color: #bada55", message); /* eslint-disable-line no-console */

        if (!error.response) {
          // NetworkProblem2 is not set under var 'networkErrors' in store...
          store.dispatch(errorsNew(error + ". Connection refused?"));
          store.dispatch(networkErrorsNew("NetworkProblem2"));
          throw error;
        }
        if (!error.response.status) {
          // TODO: network error, API is down
        } else {
          if (error.response.status === 400) {
            const formError = _.get(error.response.data, "non_field_errors");
            formError && store.dispatch(errorsNew(String(formError)));
          }
          if (error.response.status === 401 && error.response.data.detail === "Signature has expired.") {
            // logout if unauthorized error
            store.dispatch(logout());
            store.dispatch(errorsNew(error.response.data.detail));
          }
          throw error;
        }
      });
  }

  static get(url, options = {}) {
    return this._request("GET", url, options);
  }

  static post(url, options = {}) {
    return this._request("POST", url, options);
  }

  static delete(url, options = {}) {
    return this._request("DELETE", url, options);
  }

  static patch(url, options = {}) {
    return this._request("PATCH", url, options);
  }

  static put(url, options = {}) {
    return this._request("PUT", url, options);
  }
}
