import React, {Component} from "react";
import PropTypes from "prop-types";
import {Form, Icon} from "semantic-ui-react";
import "./DragAndDropSelector.css";
import _ from "lodash";
import {VariableSizeList as List} from "react-window";
import DragOption from "./DragOption";
import levenshtein from "levenshtein-edit-distance";
import DragSelected from "./DragSelected";

export default class DragAndDropSelector extends Component {
  /*
  This component is only supposed to handle the display of drag and drop elements
  and forwarding of the appropriate handlers. Filtering, sorting, and the actual data layer management are supposed to be
  handled by the parent component. This component displays filters for the choices and selected area and upon a drop happening
  calls the proper function. Usually only the change in selected field has to be handled specifically.
   */
  static propTypes = {
    selected: PropTypes.array.isRequired,
    /* List of selected choices. */

    allOptions: PropTypes.array,
    /* List of all options. If not passed then props.choices is used as all options. */

    choices: PropTypes.array.isRequired,
    /* List of all possible choices. */

    optionsElement: PropTypes.func,
    /* React Element to be rendered in choice-area. */

    choiceValue: PropTypes.string.isRequired,
    /* Choice's field that should be displayed in DnD. */

    onSelect: PropTypes.func,
    /* Function that handles the selected change event. Overrides default behaviour. */

    onDeselect: PropTypes.func,
    /* Function that handles the selected change event. Overrides default behaviour. */

    afterSelectionChange: PropTypes.func,
    /* Function that is called after drop event. */

    selectedFilterChange: PropTypes.func,
    /* Function that handles the change of the selected filter. */

    filterable: PropTypes.bool,
    /* Flag that decides if filter should be rendered. */

    selectedFilter: PropTypes.string,
    /* Selected filter displayed in the input */

    selectAll: PropTypes.bool,
    /* Flag that decides if select-all button should be displayed. */

    selectedElement: PropTypes.func,
    /* React Element to be rendered in selected-area. */

    choiceProps: PropTypes.object,
    /* Props to be passed to List in choice-area. */

    selectedProps: PropTypes.object
    /* Props to be passed to List in selected-area. */
  };

  constructor(props) {
    super(props);
    this.choiceRef = React.createRef();
    this.selectedRef = React.createRef();
    this.state = {
      activeChoices: [],
      filter: null
    };
  }

  componentDidMount() {
    this.filterChoices();
  }

  componentDidUpdate() {
    this.filterChoices();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps.selected, this.props.selected) ||
      !_.isEqual(nextProps.allOptions, this.props.allOptions) ||
      !_.isEqual(nextProps.choices, this.props.choices) ||
      !_.isEqual(nextState, this.state);
  }

  getOptions = () => this.props.allOptions ? this.props.allOptions : this.props.choices;

  filterChoices = () => {
    let choices;
    if (this.props.selected.length > 0) {
      choices = _.differenceWith(
        this.props.choices,
        this.props.selected,
        (a, b) => a.id === b || a.id === _.get(b, "id", -1));
    } else {
      choices = _.cloneDeep(this.props.choices);
    }

    // Apply filter.
    if (this.state.filter) {
      choices = _.filter(choices, (choice) =>
        choice[this.props.choiceValue].toUpperCase().indexOf(this.state.filter.toUpperCase()) !== -1
      );
      // Sort results by Levenshtein distance.
      choices = _.sortBy(choices, (choice) =>
        levenshtein(choice[this.props.choiceValue].toUpperCase(), this.state.filter.toUpperCase()));
    }

    // Callback forces Lists to map item sizes again.
    this.setState(
      {activeChoices: choices},
      () => {
        this.choiceRef.current.resetAfterIndex(0);
        this.selectedRef.current.resetAfterIndex(0);
      }
    );
  };

  debouncedChangeFilter = _.debounce(
    (newVal) => this.setState({filter: newVal}),
    1000);

  _changeFilter = (newVal) => {
    this.debouncedChangeFilter.cancel();
    this.debouncedChangeFilter(newVal);
  };

  onSelect = (e) => {
    /* Handler for moving languages from choices to selected. */
    if (this.props.onSelect) {
      this.props.onSelect(e, this.choiceRef.current, this.selectedRef.current);
    } else {
      let selected;
      e.preventDefault();
      const id = parseInt(e.dataTransfer.getData("id"));
      const type = e.dataTransfer.getData("type");
      if (type === "Drag") {
        selected = _.cloneDeep(this.props.selected);
        selected.push(id);
        // Scroll to this position in list to fix 'blank-list' bug.
        this.choiceRef.current.scrollToItem(0);

        this.props.afterSelectionChange && this.props.afterSelectionChange(selected);
      }
    }
  }

  onDeselect = (e) => {
    /* Handler for moving languages from selected to choices. */
    if (this.props.onDeselect) {
      this.props.onDeselect(e, this.choiceRef.current, this.selectedRef.current);
    } else {
      let selected;
      e.preventDefault();
      const id = parseInt(e.dataTransfer.getData("id"));
      const type = e.dataTransfer.getData("type");
      if (type === "DragSelected") {
        selected = _.cloneDeep(this.props.selected);
        const idx = _.findIndex(selected, (o) => o === id || _.get(o, "id", -1) === id);
        selected.splice(idx, 1);
        // Scroll to this position in list to fix 'blank-list' bug.
        this.selectedRef.current.scrollToItem(0);
        this.choiceRef.current.scrollToItem(0);

        this.props.afterSelectionChange && this.props.afterSelectionChange(selected);
      }
    }
  }

  onSelectAll = () => {
    let selected = _.map(this.state.activeChoices, (choice) => choice.id);
    selected = _.unionWith(selected, this.props.selected);
    this.props.afterSelectionChange(selected);
  };

  onDeselectAll = () => {
    this.props.afterSelectionChange([]);
  };

  getListItemSize = (collection, index, selected=false) => {
    const options = this.getOptions();
    let item = collection[index];
    if (selected) {
      item = _.find(
        options,
        (opt) => opt.id === item || opt.id === _.get(item, "id", -1));
    }

    return item?.[this.props.choiceValue].length >= 45 ? 50 : 30;
  }

  passDnDProps = (onDrop) => {
    // Created to pass additional props to List component.
    function forwardRef(props, ref) {
      return <div
        ref={ref}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
        {...props}/>;
    }

    forwardRef.displayName = "DnDListPropsForwarder";

    return React.forwardRef(forwardRef);
  };

  ChoiceRow = ({data, index, style}) => (
    /*
    Default row to be rendered by FixedSizeList in choice-area.
    Docs: https://github.com/bvaughn/react-window
    */
    <div style={style}>
      <DragOption
        key={data[index].id}
        id={data[index].id}
        onDoubleClick={() => this.onSelect({ // fake drop event
          preventDefault: () => null,
          dataTransfer: {
            getData: (field) => field === "id" ? data[index].id : "Drag"
          },
        })}
      >
        {data[index][this.props.choiceValue]}
      </DragOption>
    </div>
  );

  SelectedRow = ({data, index, style}) => {
    /*
    Default row to be rendered by FixedSizeList in selected-area.
    Docs: https://github.com/bvaughn/react-window
    */
    const options = this.getOptions();
    const obj = data[index];
    let choice = _.find(
      options,
      (opt) => opt.id === obj || opt.id === _.get(obj, "id", -1));
    if (!choice) {
      return null;
    }

    return <div style={style}>
      <DragSelected
        key={choice.id}
        id={choice.id}
        onDoubleClick={() => this.onDeselect({ // fake drop event
          preventDefault: () => null,
          dataTransfer: {
            getData: (field) => field === "id" ? obj : "DragSelected"
          },
        })}
      >
        {choice[this.props.choiceValue]}
      </DragSelected>
    </div>;
  };

  render() {
    const {activeChoices} = this.state;
    const {selected} = this.props;

    let buttons = "";
    if (this.props.selectAll) {
      buttons = <div className={"buttons"}>
        <Icon
          className="take-all-buttons "
          name="angle double left"
          size="large"
          onClick={this.onDeselectAll}
        />
        <Icon
          className="take-all-buttons "
          name="angle double right"
          size="large"
          onClick={this.onSelectAll}
        />
      </div>;
    }

    return (
      <div className="drag-and-drop-selector">
        <div className={"filter-choices"}>
          {this.props.filterable &&
          <Form.Input placeholder="Filter Choices"
            onChange={(e) => this._changeFilter(e.target.value)}/>
          }
        </div>
        <List
          ref={this.choiceRef}
          className={"block-list const-height"}
          outerElementType={this.passDnDProps(this.onDeselect)}
          height={200}
          width={400}
          itemCount={activeChoices.length}
          itemSize={(index) => this.getListItemSize(activeChoices, index)}
          itemData={activeChoices}
          {...this.props.choiceProps}
        >
          {this.props.optionsElement || this.ChoiceRow}
        </List>
        {buttons}
        <List
          ref={this.selectedRef}
          className={"block-list const-height selected"}
          outerElementType={this.passDnDProps(this.onSelect)}
          height={200}
          width={400}
          itemCount={selected.length}
          itemSize={(index) => this.getListItemSize(selected, index, true)}
          itemData={selected}
          {...this.props.selectedProps}
        >
          {this.props.selectedElement || this.SelectedRow}
        </List>
      </div>
    );
  }
}
