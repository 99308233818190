import React from "react";
import {withRouter} from "react-router-dom";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import moment from "moment";
import {Button, Card, Container, Dimmer, Form, Grid, Header, Icon, Menu, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";

import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {updateFiltersStateless} from "../../helpers/filters";
import {formatDate} from "../../helpers/utils";
import UserStatisticsTable from "./UserStatisticsTable";

import "./TaskStatistics.css";
import "./FormStatistics.css";


class UserStatistics extends FilteredComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      all: true,
      manual: {},
      popupOpen: false,
      loaded: false,
      filtersOptions: {}
    };
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.arrayFilters = ["language", "project_name"];
  }

  componentDidMount() {
    this.getPopupData();
    super.componentDidMount();
  }

  getData = () => {
    this.setState({taskStatisticsList: null, loaded: false});
    const filter_values = this.getFilters();

    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }

    if (!this.state.filters.all) {
      formatDate(filter_values);
    }

    ajax.get([config.USER_STATISTIC, this.props.match.params.userName], {params: filter_values})
      .then((response) => {
        this.setState({taskStatisticsList: response.data, loaded: true});
      })
      .catch(() => {
        // console.log('error', error);
      });
    ajax.get(config.PROJECT_OPTIONS)
      .then((response) => {
        this.parseFilterOptions("project_name", response.data, "name", "name", "name", false);
      });
    ajax.get(config.LANGUAGES, {params: {filter: true}})
      .then((response) => {
        this.parseFilterOptions("language", response.data, "name", "name", "name", false);
      });
  };

  getPopupData = () => {
    ajax.get(config.STATISTIC_MANUAL)
      .then((response) => {
        this.setState({manual: response.data});
      })
      .catch(() => {
      });
  };

  closePopup = () => {
    this.setState({popupOpen: false});
  };

  setAll = (flag) => {
    const date = `${moment("01", "DD").format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`;
    const date_range = flag ? "" : date;
    this.updateFilters({
      all: flag,
      date_range,
    });
    this.setState({all: flag});
  };

  renderFilter = () => {
    const params = this.getFilters();
    return (
      <Menu>
        <Form className="FormStatistics">
          <Form.Field>
            <Popup trigger={<label>Date range</label>}
              content={this.state.manual.DATE_RANGE}/>
            <DatesRangeInput
              autoComplete="off"
              dateFormat="YYYY-MM-DD"
              name="datesRange"
              popupPosition="bottom center"
              placeholder="Start Date - End Date"
              value={params.date_range || ""}
              iconPosition="left"
              onChange={(e, data) => this.changeFilterInstantly({date_range: data.value})}
              disabled={params.all}/>
          </Form.Field>
          <Form.Field>
            <Popup
              trigger={<label>All time</label>}
              content={this.state.manual.ALL_TIME}/>
            <Form.Checkbox
              checked={this.state.all}
              onChange={(e, data) => this.setAll(data.checked)}/>
          </Form.Field>
          <Form.Field>
            <label>Project</label>
            <Form.Dropdown
              multiple selection fluid
              placeholder="None"
              options={this.state.filtersOptions.project_name}
              value={params.project_name || []}
              onChange={(e, data) => this.updateFilters({project_name: data.value})}
            />
          </Form.Field>
          <Form.Field>
            <label>Language</label>
            <Form.Dropdown
              multiple selection fluid
              placeholder="None"
              options={this.state.filtersOptions.language}
              value={params.language || []}
              onChange={(e, data) => this.updateFilters({language: data.value})}
            />
          </Form.Field>
          <Form.Button
            basic
            onClick={this.resetFilters}>
              Reset
          </Form.Button>
        </Form>
      </Menu>
    );
  };

  render() {
    const dataLoaded = this.state.loaded;

    return (
      <Dimmer.Dimmable>
        <Container className="TaskStatistics">
          <Card.Group>
            <Card fluid className="datahive-card">
              <Button fluid size={"big"} color="white2" className="datahive-button">
                <Icon
                  name="user"/>{this.props.match.params.userName}
              </Button>
            </Card>
            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                      <Header textAlign="center">User statistics</Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Popup on="click" position={"bottom center"}
                        className="popupUserStatistics"
                        trigger={
                          <Button
                            className="datahive-button"
                            color="dark-grey"
                            size="mini" floated="right"
                            onClick={() => {
                              this.setState({popupOpen: true});
                            }}>Filter <Icon name="chevron down"/>
                          </Button>}
                        content={this.renderFilter()}
                        open={this.state.popupOpen}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {dataLoaded &&
              <Card.Content>
                <UserStatisticsTable
                  manual={this.state.manual}
                  taskStatisticsList={this.state.taskStatisticsList}/>
              </Card.Content>}
              {!dataLoaded && <LoaderSpinner/>}
            </Card>
          </Card.Group>
        </Container>
        <Dimmer
          className={"invisible-dimmer"}
          active={this.state.popupOpen}
          onClickOutside={this.closePopup}/>
      </Dimmer.Dimmable>
    );
  }
}

export default withRouter(UserStatistics);
