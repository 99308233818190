import React from "react";
import PropTypes from "prop-types";

import {Dimmer, Loader, Segment} from "semantic-ui-react";

const LoaderSpinner = ({className, minHeight="50px"}) => (
  <Segment disabled={true}>{/* disabled because z-index is over Dropdown */}
    <div style={{minHeight}}>
      <Dimmer active inverted>
        <Loader className={className} inverted content="Loading"/>
      </Dimmer>
    </div>
  </Segment>
);

LoaderSpinner.propTypes = {
  className: PropTypes.string,
  minHeight: PropTypes.string
};

export default LoaderSpinner;
