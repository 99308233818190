import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import config from "../config/config";
import "./RichTextEditor.css";
import ajax from "../helpers/ajax";

import { Editor } from "@tinymce/tinymce-react";

// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";

import "tinymce/models/dom/model";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.min.css";

import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/importcss";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/template";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/emoticons/js/emojis";

import contentCss from "tinymce/skins/content/default/content.min.css";
import contentUiCss from "tinymce/skins/ui/oxide/content.min.css";

class RichTextEditor extends Component {
  static propTypes = {
    content: PropTypes.string,
    onChange: PropTypes.func,
    disable: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  IMAGES_UPLOAD_BASE_PATH = config.BACKEND_URL + config.MEDIA_SUFFIX;

  IMAGES_UPLOAD_URL =
    config.BACKEND_URL +
    config.API_SUFFIX +
    config.FILE_UPLOAD_FROM_TEXT_EDITOR;

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.isWrapping = false;

    this.state = {
      content: "",
      initialHeight: 400,
    };
  }

  componentDidMount = () => {
    this.setState({ content: this.props.content || "" });
  };

  handleEditorChange = (newContent) => {
    this.setState({ content: newContent }, () => {
      this.props.onChange(newContent);
    });
  };

  handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    if (clipboardData) {
      const { items } = clipboardData;
      for (const item of items) {
        if (item.kind === "file") {
          const blob = item.getAsFile();
          const data = new FormData();

          data.append("file", blob, blob.name);

          ajax
            .post(config.FILE_UPLOAD_FROM_TEXT_EDITOR, {
              data,
              options: {
                headers: { "content-type": "multipart/form-data" },
              },
            })
            .then((res) => {
              const { location } = res.data;
              const imageUrl = this.IMAGES_UPLOAD_BASE_PATH + location;
              const editor = this.editorRef.current.editor;
              editor.insertContent(`<img src="${imageUrl}" />`);
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else if (item.type === "text/html") {
          item.getAsString((html) => {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = html;
            const brokenImages = tempElement.querySelectorAll(
              'img[src^="file:///"'
            );

            brokenImages.forEach((image) => {
              image.parentNode.removeChild(image);
            });

            const tables = tempElement.querySelectorAll("table");
            tables.forEach((table) => {
              const wrappedTable = this.wrapTable(table.outerHTML);
              table.outerHTML = wrappedTable;
            });

            const editor = this.editorRef.current.editor;
            editor.insertContent(tempElement.innerHTML);
          });
        }
      }
    }
  };

  handleBeforeSetContent = (e) => {
    const editor = this.editorRef.current.editor;
    if (!this.isWrapping && e.content.includes("<table")) {
      this.isWrapping = true;
      const wrappedContent = e.content.replace(/<table.*?<\/table>/g, (match) =>
        this.wrapTable(match)
      );
      editor.insertContent(wrappedContent);
      this.isWrapping = false;
      e.preventDefault();
    }
  };

  handleFixTablesWrapping = () => {
    const editor = this.editorRef.current.editor;
    const tables = editor.contentDocument.querySelectorAll("table");

    tables.forEach((table) => {
      if (
        table.parentElement &&
        !table.parentElement.classList.contains("table-wrapper")
      ) {
        const wrappedTable = this.wrapTable(table.outerHTML);
        table.outerHTML = wrappedTable;
      }
    });
  };

  handleSetup = (editor) => {
    editor.on("BeforeSetContent", this.handleBeforeSetContent);
    editor.ui.registry.addButton("fixTablesWrapping", {
      icon: "table-classes",
      tooltip: "Fix tables wrapping",
      onAction: this.handleFixTablesWrapping,
    });
  };

  wrapTable = (tableHtml) => {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.classList.add("table-wrapper");
    wrapperDiv.style.overflow = "auto";
    wrapperDiv.style.maxHeight = "90vh";
    wrapperDiv.innerHTML = tableHtml;
    return wrapperDiv.outerHTML;
  };

  render = () => (
    <Editor
      value={this.state.content}
      ref={this.editorRef}
      onEditorChange={this.handleEditorChange}
      onPaste={this.handlePaste}
      init={{
        setup: this.handleSetup,
        height:
          this.props.height > this.state.initialHeight
            ? this.props.height
            : this.state.initialHeight,
        menubar: true,
        branding: false,
        promotion: false,
        paste_data_images: true,
        relative_urls: false,
        images_upload_base_path: this.IMAGES_UPLOAD_BASE_PATH,
        images_upload_url: this.IMAGES_UPLOAD_URL,
        images_upload_credentials: true,
        automatic_uploads: true,
        readonly: this.props.disable,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | fixTablesWrapping",
        table_default_styles: {
          width: "100%",
          "border-collapse": "collapse",
          "overflow-wrap": "word-wrap",
        },
        skin: false,
        content_css: false,
        content_style: contentUiCss.toString() + "\n" + contentCss.toString(),
      }}
    />
  );
}

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(RichTextEditor);
