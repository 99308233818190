import _ from "lodash";
import {useEffect} from "react";

const useRegisterEmpty = (segments, registerEmpty, componentId) =>
  useEffect(() => {
    function isEmpty() {
      const allSegments = _.flatten(segments);
      return allSegments.length === 0;
    }

    if (registerEmpty) {
      registerEmpty(isEmpty, componentId);
    }
  }, [segments, registerEmpty, componentId]);

export default useRegisterEmpty;
