import React, {Component} from "react";
import "./WidgetId.css";
import "./WidgetCopy.css";
import {Checkbox, Menu, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";

class WidgetId extends Component {
  static propTypes = {
    onCopy: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.bool
    ]),
    left: PropTypes.bool,
    componentId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handlePopupClose = () => this.setState({open: false});

  handleCopy = (event) => {
    event.stopPropagation();
    this.props.onCopy();
    this.handlePopupClose();
  };

  handleDelete = (event) => {
    event.stopPropagation();
    this.props.onDelete();
    this.handlePopupClose();
  };

  handleSelect = (event, componentId) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.props.onSelect(!this.props.selected, componentId);
  };

  handleClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    if (!this.props.left && this.props.componentId !== "submit-button") {
      this.setState({open: !this.state.open});
    }
  };

  render() {
    const componentId = this.props.componentId;

    return (
      <Popup
        className="widget-id-context-menu"
        onClose={this.handlePopupClose}
        open={this.state.open}
        on="click"
        trigger={
          <div
            className={`widget-id-container ${this.props.left ? "left" : ""}`}
            onContextMenu={this.handleClick} onClick={(e) => e.stopPropagation()}>
            Id: {componentId}
            {!this.props.left && componentId !== "submit-button" &&
            <Checkbox
              className="widget-id-copy-checkbox"
              checked={this.props.selected}
              onChange={(e) => this.handleSelect(e, componentId)}
            />
            }
          </div>
        }>
        <Menu.Header>Actions for selected widgets</Menu.Header>
        <Menu pointing vertical>
          <Menu.Item name="copy" onClick={(e) => this.handleCopy(e)}/>
          <Menu.Item name="delete" onClick={(e) => this.handleDelete(e)}/>
        </Menu>
      </Popup>
    );
  }
}

export default WidgetId;
