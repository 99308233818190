import _ from "lodash";

import React from "react";
import {Checkbox, Grid, Header, TextArea, Dropdown} from "semantic-ui-react";
import {AlignSelector} from "../components";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./SelectWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class SelectWidgetEditor extends WidgetEditor {
  static header = "Select";

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      newOptionName: "",
    };

    this.updateCategories = this.updateCategories.bind(this);
    this.updateDefaultCategory = this.updateDefaultCategory.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.updateAlign = this.updateAlign.bind(this);
  }

  updateCategories() {
    const textarea = document.querySelector(".SelectWidgetEditor textarea");
    const elements = textarea.value.split(";");
    const categories = elements.map(function (obj) {
      return obj.trim();
    });
    _.trim(categories);
    textarea.value = categories.join(";\n");
    this.updateConfig("categories", categories);
  }

  updateCategoriesFromQuestion(key, value) {
    this.updateWidgetConfig((widgetConfig) => {
      if (value) {
        _.set(widgetConfig, "isDefaultCategory", false);
        _.set(widgetConfig, "defaultCategory", "");
      }
      _.set(widgetConfig, key, value);
    });
  }

  updateDefaultCategory(value) {
    this.updateWidgetConfig((widgetConfig) => {
      _.set(widgetConfig, "isDefaultCategory", value);
      if (!value) {
        _.set(widgetConfig, "defaultCategory", "");
      }
    });
  }

  removeOption(option) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.options = _.filter(widgetConfig.options, (o) => {
        return o.value !== option;
      });
    });
  }

  updateAlign(e, data) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.align = data;
    });
    this.setState({}); // force re-render
  }

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const categories = config.categories.map(function (obj) {
      return obj;
    }).join(";\n");

    const options = [];
    config.categories.map((item) =>
      options.push({key: item, text: item, value: item})
    );

    return (
      <Grid className={"SelectWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <div className={config.customCategories ? "customCategories" : ""}>
              <Header as="h4">Categories</Header>
              <TextArea defaultValue={categories.toString()} rows={16} onBlur={this.updateCategories}/>
            </div>
            <Header className="row" as="h4">Categories from questions</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.customCategories}
              onChange={(e, data) => this.updateCategoriesFromQuestion("customCategories", data.checked)}/>
            <Header as="h4">Default category</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.isDefaultCategory}
              onChange={(e, data) => {
                this.updateDefaultCategory(data.checked);
              }}/>
            <div className={config.customCategories ? "customCategories" : ""}>
              <Dropdown
                search selection
                disabled={!config.isDefaultCategory}
                size="mini"
                placeholder="select default category"
                options={options}
                value={config.defaultCategory}
                onChange={(e, data) => this.updateConfig("defaultCategory", data.value)}/>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header className="row" as="h4">Align</Header>
            <AlignSelector
              justify
              className="row"
              size="mini"
              value={config.align}
              onChange={this.updateAlign}
            />
            <Header
              className="row"
              as="h4">Readonly</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.readonly}
              onChange={(e, data) => this.updateConfig("readonly", data.checked)}/>
            <Header
              className="row"
              as="h4">Multiple</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.multiple}
              onChange={(e, data) => this.updateConfig("multiple", data.checked)}/> <Header
              className="row"
              as="h4">Tags</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.tags}
              onChange={(e, data) => this.updateConfig("tags", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default SelectWidgetEditor;
