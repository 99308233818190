import React, {Component} from "react";
import {Button, Header, Input} from "semantic-ui-react";
import PropTypes from "prop-types";


class NoteInput extends Component {
  static propTypes = {
    clearNote: PropTypes.func.isRequired,
    updateNote: PropTypes.func.isRequired,
    note: PropTypes.string,
  };

  render() {

    return (

      <React.Fragment>
        <Header as="h4" content={"Note"}/>
        <Input
          fluid className={"input-note"}
          label={<Button className={"button-clear"} icon="delete" onClick={this.props.clearNote}/>}
          labelPosition="right"
          value={this.props.note || ""}
          onChange={this.props.updateNote}
        />
      </React.Fragment>
    );
  }
}

export default NoteInput;
