import React, {Component} from "react";
import PropTypes from "prop-types";
import {Message} from "semantic-ui-react";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {AuthView} from "./AuthView";

export const InvalidTokenView = () =>
  <React.Fragment>
    <Message
      className="datahive-message"
      warning={true}
      header={"Something went wrong"}
      content={"It appears your password reset token is invalid. Perhaps you have " +
        "waited too long to reset your password and it expired. If that is not the case, " +
        "please contact datahive.buzz administrators"}
    />
  </React.Fragment>;

export const withResetToken = (WrappedComponent) => {
  return class Comp extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired
    }

    constructor(props) {
      super(props);
      const url = this.props.location.pathname;
      const token = url.substr(url.lastIndexOf("/") + 1);

      this.state = {
        token,
        isTokenValid: false,
      };
    }

    componentDidMount() {
      const {token} = this.state;
      // check if a valid token exists
      ajax
        .post(config.PASSWORD_RESET_VERIFY_TOKEN, {data: {token, 'reset_type': 'password'}})
        .then(() => this.setState({isTokenValid: true}))
        .catch(() => this.setState({isTokenValid: false}));
    }

    render() {
      const {token, isTokenValid} = this.state;
      return (
        <React.Fragment>
          {isTokenValid
            ? <WrappedComponent
              {...this.props}
              token={token}
            />
            : <AuthView>
              <InvalidTokenView/>
            </AuthView>}
        </React.Fragment>
      );
    }
  };
};
