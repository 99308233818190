import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Icon, Popup, Table} from "semantic-ui-react";
import _ from "lodash";
import {formatSeconds} from "../../helpers/utils";
import urls from "../../config/frontend_urls";
import PropTypes from "prop-types";
import moment from "moment";

export default class UserStatisticsTable extends Component {
  static propTypes = {
    taskStatisticsList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    manual: PropTypes.object.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderTaskRows() {
    let rows = <Table.Row/>;
    let summary_data = {};
    if (!!this.props.taskStatisticsList) {
      summary_data = {
        deadline: "",
        answers: 0,
        std: 0,
        base_stats_mean: 0,
        base_stats_summary: 0,
        sessions_mean: 0,
        sessions_summary: 0,
        percentiles_mean: 0,
        percentiles_summary: 0,
      };
      rows = _.map(this.props.taskStatisticsList, (task, idx) => {
        const task_data = {
          deadline: "",
          title: "",
          username: "",
          answers: "",
          std: "",
          base_stats_mean: "",
          base_stats_summary: "",
          sessions_mean: "",
          sessions_summary: "",
        };
        if (!!task.sessions && "base_stats" in task && "sessions" in task) {
          task_data["title"] = task.task_title;
          task_data["username"] = task.username;
          if (task.deadline) {
            task_data["deadline"] = moment(task.deadline).format("DD.MM.YYYY h:mm A");
          } else {
            task_data["deadline"] = "---";
          }
          task_data["answers"] = task.base_stats.total_answers;
          task_data["std"] = formatSeconds(task.base_stats.std);
          task_data["base_stats_mean"] = formatSeconds(task.base_stats.mean);
          task_data["base_stats_summary"] = formatSeconds(task.base_stats.total_time);
          task_data["sessions_mean"] = formatSeconds(task.sessions.mean);
          task_data["sessions_summary"] = formatSeconds(task.sessions.total_time);
          task_data["percentiles_mean"] = "percentiles" in task ? formatSeconds(task.percentiles.mean) : 0;
          task_data["percentiles_summary"] = "percentiles" in task ? formatSeconds(task.percentiles.total_time) : 0;

          summary_data.answers += parseInt(task.base_stats.total_answers);
          summary_data.std += task.base_stats.std;
          summary_data.base_stats_mean += task.base_stats.total_time;
          summary_data.base_stats_summary += task.base_stats.total_time;
          summary_data.sessions_mean += task.sessions.total_time;
          summary_data.sessions_summary += task.sessions.total_time;
          summary_data.percentiles_mean += "percentiles" in task ? task.percentiles.total_time : 0;
          summary_data.percentiles_summary += "percentiles" in task ? task.percentiles.total_time : 0;
        }
        const url = urls.TASK_USER_STATISTICS
          .replace(":taskSlug", _.replace(task.task_title, /\//g, "").toLowerCase())
          .replace(":userName", task.username);
        return (
          <Table.Row key={"Task" + idx}>
            <Table.Cell>
              <Link to={url}>
                <Button basic fluid size="mini" color={"black"}>
                  <Icon name="tasks"/>{task.task_title}
                </Button>
              </Link>
            </Table.Cell>
            <Table.Cell className={"light-grey"}>{task_data.deadline}</Table.Cell>
            <Table.Cell className={"light-grey"}>{task_data.answers}</Table.Cell>
            <Table.Cell className={"light-grey"}>{task_data.std}</Table.Cell>
            <Table.Cell>{task_data.base_stats_mean}</Table.Cell>
            <Table.Cell>{task_data.base_stats_summary}</Table.Cell>
            <Table.Cell className={"light-grey"}>{task_data.sessions_mean}</Table.Cell>
            <Table.Cell className={"light-grey"}>{task_data.sessions_summary}</Table.Cell>
            <Table.Cell>{task_data.percentiles_mean}</Table.Cell>
            <Table.Cell>{task_data.percentiles_summary}</Table.Cell>
          </Table.Row>
        );
      }
      );
      if (this.props.taskStatisticsList.length > 0) {
        summary_data.std /= this.props.taskStatisticsList.length;
      } else {
        rows = <Table.Row/>;
      }
      if (summary_data.answers > 0) {
        summary_data.base_stats_mean /= summary_data.answers;
        summary_data.sessions_mean /= summary_data.answers;
        summary_data.percentiles_mean /= summary_data.answers;
      }
    }
    return [rows, summary_data];
  }

  render() {
    const [rows, summary_data] = this.renderTaskRows();
    return (
      <Table sortable celled structured textAlign="center" className="SortableTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={"light-grey"}
              rowSpan={2}>Task
            </Table.HeaderCell>
            <Popup
              trigger={
                <Table.HeaderCell
                  rowSpan={2} className="grey">Deadline
                </Table.HeaderCell>
              } content={this.props.manual.DEADLINE}/>
            <Popup
              trigger={
                <Table.HeaderCell
                  rowSpan={2} className={"grey"}>Answers
                </Table.HeaderCell>
              } content={this.props.manual.ANSWERS}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  rowSpan={2} className={"grey"}>Std
                </Table.HeaderCell>
              } content={this.props.manual.STD}/>
            <Popup
              position="bottom left"
              trigger={
                <Table.HeaderCell
                  colSpan={2} className={"light-grey"}>Simple stats
                </Table.HeaderCell>
              } content={<div
                dangerouslySetInnerHTML={{"__html": this.props.manual.SIMPLE_STATS}}/>
              }/>
            <Popup
              position="bottom left"
              trigger={
                <Table.HeaderCell
                  colSpan={2} className={"grey"}>Sessions
                </Table.HeaderCell>
              } content={<div
                dangerouslySetInnerHTML={{"__html": this.props.manual.SESSIONS_STATS}}/>
              }/>
            <Popup
              position="bottom left"
              trigger={
                <Table.HeaderCell
                  colSpan={2} className={"light-grey"}>Percentile
                </Table.HeaderCell>
              } content={<div
                dangerouslySetInnerHTML={{"__html": this.props.manual.PERCENTILES_STATS}}/>
              }/>
          </Table.Row>
          <Table.Row>
            <Popup
              trigger={
                <Table.HeaderCell
                  className={"light-grey"}>Mean
                </Table.HeaderCell>
              } content={this.props.manual.MEAN}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  className={"light-grey"}>Summary
                </Table.HeaderCell>
              } content={this.props.manual.SUMMARY}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  className={"grey"}>Mean
                </Table.HeaderCell>
              } content={this.props.manual.MEAN}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  className={"grey"}>Summary
                </Table.HeaderCell>
              } content={this.props.manual.SUMMARY}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  className={"light-grey"}>Mean
                </Table.HeaderCell>
              } content={this.props.manual.MEAN}/>

            <Popup
              trigger={
                <Table.HeaderCell
                  className={"light-grey"}>Summary
                </Table.HeaderCell>
              } content={this.props.manual.SUMMARY}/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
        <Table.Footer>
          <Table.Row className={"BoldRow"}>
            <Table.HeaderCell className={"light-grey"}/>
            <Table.HeaderCell className={"grey"}/>
            <Table.HeaderCell
              className={"grey"}>{summary_data.answers || 0}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.std || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.base_stats_mean || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.base_stats_summary || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.sessions_mean || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.sessions_summary || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.percentiles_mean || 0)}
            </Table.HeaderCell>
            <Table.HeaderCell className={"light-grey"}>
              {formatSeconds(summary_data.percentiles_summary || 0)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
