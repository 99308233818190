import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {iterateAllWidgets} from "../../components/Widgets/widgetUtils";

import "./QuestionsSet.css";
import QuestionsSet from "./QuestionsSet";
import EvaluationDoneContainer from "./EvaluationDoneContainer";


export default class EvaluationsDoneSet extends QuestionsSet {
  static propTypes = {
    ...QuestionsSet.propTypes,
    user: PropTypes.oneOf([PropTypes.object, undefined]),
    showUsers: PropTypes.bool
  };

  renderQuestions = () => {
    const config = this.props.config;
    // remove popup content from all widgets
    iterateAllWidgets(config.structure, (w) => {
      if (w.popupContent) {
        delete w.popupContent;
      }
    });

    return _.map(this.props.questions, (question, questionIdx) => {
      const questionId = question.id;

      if (!("answers" in question)) {
        question.answers = [];
      }

      return (
        <EvaluationDoneContainer
          key={"question" + questionId}
          idx={questionIdx}
          question={question}
          onSubmit={this.props.onSubmit}
          onSkip={this.props.onSkip ? this.onSkip : null}
          onFixAnswer={this.props.onFixAnswer}
          isModerator={this.props.isModerator}
          config={this.props.config}
          config_evaluation={this.props.config_evaluation}
          showReview={this.props.showReview}
          editReview={this.props.editReview}
          onReviewSubmit={this.props.onReviewSubmit}
          onReviewAccept={this.props.onReviewAccept}
          onCommentSubmit={this.props.onCommentSubmit}
          onCommentRemoval={this.props.onCommentRemoval}
          onCommentUpdate={this.props.onCommentUpdate}
        />
      );
    });
  };

  render() {
    return (
      <div className="QuestionsSet">
        {this.renderQuestions()}
      </div>
    );
  }
}
