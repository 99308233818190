import _ from "lodash";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Form} from "semantic-ui-react";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import LoaderBlocker from "../../../components/LoaderBlocker";
import EvaluationsDoneSet from "../EvaluationsDoneSet";
import {TaskDone} from "./TaskDone";
import BaseTaskQuestions from "./BaseTaskQuestions";
import {FormLevenDropdown as FormDropdown} from "../../../components/simple/Dropdown/LevenDropdown";
import AdvancedFiltersSet from "../../../components/AdvancedFilters/AdvancedFiltersSet";

import "./TaskEvaluationDone.css";
import LoaderSpinner from "../../../components/LoaderSpinner";

const reviewOptions = [
  {
    key: "All",
    text: "All",
    value: null
  },
  {
    key: "Commentary",
    text: "Commentary",
    value: "commentary"
  },
  {
    key: "Discussion (resolved)",
    text: "Discussion (resolved)",
    value: "discussion_resolved"
  },
  {
    key: "Discussion (not resolved)",
    text: "Discussion (not resolved)",
    value: "discussion_not_resolved"
  },
  {
    key: "No reviews",
    text: "No reviews",
    value: "no_reviews"
  },
];


export class TaskEvaluationDone extends TaskDone {
  static propTypes = {
    ...BaseTaskQuestions.propTypes,
    user: PropTypes.oneOf([PropTypes.object, undefined]),
    enable_search_evaluations: PropTypes.bool,
    isNew: PropTypes.bool// REFACTOR this is a flag that means that this component is actually used for new evaluations, not done.
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
    // set values for base component
    // questions are answers objects from backend but they are treated like questions in evaluations
    this.getQuestionsUrl = config.TASK_ANSWERED_EVALUATIONS;
    this.state.filters = {
      page: 1,
      page_size: props.questionsAtPage,
      order_by: "-timestamp",
      advanced_question: [],
      advanced_question_not_empty: [],
      advanced_question_empty: [],
      advanced_question_exact_match: [],
      advanced_answer: [],
      advanced_answer_not_empty: [],
      advanced_answer_empty: [],
      advanced_answer_exact_match: [],
      advanced_evaluation_question: [],
      advanced_evaluation_question_not_empty: [],
      advanced_evaluation_question_empty: [],
      advanced_evaluation_question_exact_match: [],
      advanced_evaluation_answer: [],
      advanced_evaluation_answer_not_empty: [],
      advanced_evaluation_answer_empty: [],
      advanced_evaluation_answer_exact_match: [],
      evaluation_author: [],
      answer_author: [],
      eval_review_author: [],
      eval_fix_author: []
    };

    if (props.enable_search_evaluations) {
      this.getQuestionsUrl = config.TASK_GET_NEW_EVALUATIONS;
    }

    this.advancedPopupRef = React.createRef();
  }

  getFilterOptions = () => {
    const params = {...this.state.filters};
    ajax.get([config.TASK_EVALUATION_FILTER_OPTIONS, this.props.slug], {params})
      .then((response) => {
        const userOptions = this.createOptions(response.data.user, "username");
        const evalAuthorOptions = this.createOptions(response.data.evaluation_authors, "username");
        const companyOptions = this.createOptions(response.data.company, "name");
        const evalReviewAuthorOptions = this.createOptions(response.data.evaluation_review_authors, "username");
        const evalFixAuthorOptions = this.createOptions(response.data.evaluation_fix_authors, "username");
        const possibleFilterValues = {
          ...this.state.possibleFilterValues,
          user: userOptions,
          evaluation_authors: evalAuthorOptions,
          company: companyOptions,
          eval_review_authors: evalReviewAuthorOptions,
          eval_fix_authors: evalFixAuthorOptions,
        };

        this.setState({possibleFilterValues});
      });
  };

  onCommentSubmit = (questionIdx, evaluationIdx, comment,
    discussion = false, commentId = null, successCallback = () => null) => {

    const evaluationId = this.state.questions[questionIdx].evaluations[evaluationIdx].id;
    const data = discussion ? {
      content: comment,
      discussion: true
    } : {content: comment};

    if (commentId) {
      data.commentId = commentId;
    }

    ajax.post([config.TASK_SAVE_EVALUATION_REVIEW, evaluationId], {data})
      .then((response) => {
        toastr.success(`${discussion ? "Discussion started!" : "Message submitted!"}`);
        const questions = this.state.questions;
        questions[questionIdx].evaluations[evaluationIdx].review = response.data;
        this.setState({questions});
        successCallback();
      }).catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  onCommentRemoval = (commentId, questionIdx, answerIdx, successCallback = () => null) => {
    ajax.delete([config.TASK_DELETE_EVALUATION_COMMENT, commentId])
      .then((response) => {
        const questions = this.state.questions;
        questions[questionIdx].evaluations[answerIdx].review = response.data;
        this.setState({questions});
        toastr.success("Message deleted!");
        successCallback();
      }).catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  onCommentUpdate = (commentId, comment, questionIdx, answerIdx, successCallback = () => null) => {
    const data = {content: comment};
    ajax.post([config.TASK_UPDATE_EVALUATION_COMMENT, commentId], {data})
      .then((response) => {
        const questions = this.state.questions;
        questions[questionIdx].evaluations[answerIdx].review = response.data;
        this.setState({questions});
        toastr.success("Message updated!");
        successCallback();
      }).catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  onSubmit = (questionIdx, widgetVals, correctionId, cb) => {
    if (this.sendingSubmit) {
      return;
    }
    this.sendingSubmit = true;
    const data = widgetVals;
    const questionId = this.state.questions[questionIdx].id; //answerId but answer is question here xD
    data["time_taken"] = moment() - this.state.timer;

    ajax.post([config.TASK_SAVE_EVALUATION, this.props.slug, questionId], {
      data,
      params: {correction: correctionId ? correctionId : null}
    }).then((response) => {
      toastr.success("Changes saved");
      if (!correctionId) {
        const newAnswerIdx = response.data.answer_id;
        const questions = this.addAnswer(questionIdx, newAnswerIdx, widgetVals, true);

        const timer = moment();
        this.setState({timer, questions}, cb);

        if (this.props.enable_search_evaluations) {
          this.setState({
            questions: questions.splice(questionIdx, 1),
            totalAnswers: this.state.totalAnswers - 1,
          }, () => this.onPageChange(this.state.filters.page));
        }
      } else {
        this.onPageChange(this.state.filters.page);
      }
    })
      .catch((error) => {
        toastr.error("Error", error.response.data.detail);
      })
      .finally(() => {
        this.sendingSubmit = false;
      });
  };

  onReviewAccept = (questionIdx, answerIdx, comment = null, successCallback = () => null) => {
    const data = comment ? {content: comment} : {};
    const evaluation = this.state.questions[questionIdx].evaluations[answerIdx];

    ajax.post([config.TASK_ACCEPT_EVALUATION_REVIEW, evaluation.id], {data})
      .then((response) => {
        toastr.success(`Discussion ${evaluation.review.is_accepted ? "unresolved" : "resolved"}!`);
        const questions = this.state.questions;
        questions[questionIdx].evaluations[answerIdx].review = response.data;
        this.setState({questions});
        successCallback();
      })
      .catch((errors) => {
        const err = JSON.stringify(_.get(errors, "response.data", "Something went wrong!"));
        toastr.error("Error!", err);
      });
  };

  /* eslint-disable-next-line react/display-name */
  renderFilters = () => {
    const possibleFilterValues = this.state.possibleFilterValues;
    const filters = this.state.filters;

    let evalAuthorFilter = "";
    if (!this.props.isNew && (this.props.isStaff ||
      this.props.isModerator ||
      this.props.isCompanyCoordinator)) {
      evalAuthorFilter =
        <FormDropdown
          label={"Evaluation Author"}
          placeholder="All"
          fluid search selection
          options={possibleFilterValues.evaluation_authors}
          value={filters.eval_author}
          onChange={(e, data) => this.setFilters("eval_author", data.value)}
        />;
    }

    let answerAuthorFilter = "";
    let reviewAuthorFilter = "";
    if (this.props.isStaff ||
      this.props.isModerator ||
      this.props.isCompanyCoordinator) {
      answerAuthorFilter =
        <FormDropdown
          label={"Answer Author"}
          placeholder="All"
          fluid search selection
          options={possibleFilterValues.user}
          value={filters.answer_author}
          onChange={(e, data) => this.setFilters("answer_author", data.value)}
        />;
      reviewAuthorFilter =
        <Form.Field>
          <label>Review Author</label>
          <Form.Dropdown
            multiple
            placeholder="All"
            fluid selection
            options={possibleFilterValues.eval_review_authors}
            value={filters.eval_review_author || []}
            onChange={(e, data) => this.setFilters("eval_review_author", data.value)}
          />
        </Form.Field>;
    }

    let fixAuthorFilter = "";
    if (this.props.isStaff) {
      fixAuthorFilter =
        <Form.Field>
          <label>Fix Author</label>
          <Form.Dropdown
            multiple
            placeholder="All"
            fluid selection
            options={possibleFilterValues.eval_fix_authors}
            value={filters.eval_fix_author || []}
            onChange={(e, data) => this.setFilters("eval_fix_author", data.value)}
          />
        </Form.Field>;
    }


    const advancedFilter =
      <AdvancedFiltersSet
        advancedPopupRef={this.advancedPopupRef}
        filters={filters}
        structure={this.props.config.structure}
        evaluationStructure={this.props.config_evaluation.structure}
        answer
        question
        evaluationQuestion
        evaluationAnswer={!this.props.isNew}
        possibleFilterValuesUsers={possibleFilterValues.user}
        updateFilters={this.updateFilters}
      />;

    return (
      <div className="ui filters-menu menu" ref={this.advancedPopupRef}>
        <Form className="filters-form" size="mini">
          {!this.props.isNew && reviewAuthorFilter}
          {answerAuthorFilter}
          {fixAuthorFilter}
          {advancedFilter}
        </Form>
        <Form className={"filters-form"} size="mini"><>
          {!this.props.isNew &&
          <Form.Field>
            <label>Review</label>
            <Form.Dropdown
              multiple
              placeholder="All"
              fluid selection
              options={reviewOptions}
              value={filters.eval_review || []}
              onChange={(e, data) => this.setFilters("eval_review", data.value)}
            />
          </Form.Field>
          }
          {evalAuthorFilter}
          <Form.Field>
            <Form.Input
              label="Question ID"
              value={filters.id}
              onChange={(e, data) => {
                this.setFilters("id", data.value);
              }}
              type="number"/>
          </Form.Field>
          {this.props.enable_search_evaluations}
          <Form.Field>
            <Form.Input
              label="Question text"
              value={this.state.filters.question_text}
              onChange={(e, data) => {
                this.setFilters("question_text", data.value);
              }}
              type="text" />
          </Form.Field>
          <div className={"filters-button"}>
            <Form.Button
              className="datahive-button"
              color="yellow"
              style={{marginTop: "15px"}}
              type="submit"
              onClick={this.onFiltersSubmit}>Submit</Form.Button>
          </div>
          <div className={"filters-button"} style={{textAlign: "center"}}>
            <Form.Button
              className="datahive-button"
              color="red"
              style={{marginTop: "15px"}}
              type="submit"
              onClick={this.resetFilters}
              content={"Clean"}
            />
          </div>
        </></Form>
      </div>
    );
  };

  render() {
    const questions = this.state.questions;

    // display spinner if questions aren't loaded yet
    if (this.state.loading) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <div style={{height: "500px"}}>
            <LoaderBlocker show={true}/>
          </div>
        </div>
      );
    }

    if (this.state.displaySpinner) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <LoaderSpinner/>
          {this.renderFooter()}
        </div>
      );
    }

    // display no more answers
    if (!questions || _.isEmpty(questions)) {
      return (
        <div className="tab-panel TaskDone">
          {this.renderOptionsPanel()}
          <div className="Done">
            <h2>There are no answers yet.</h2>
          </div>
        </div>
      );
    }
    const config = _.cloneDeep(this.props.config);
    config.structure = config.structure.splice(0, config.structure.length - 1);
    const config_evaluation = _.cloneDeep(this.props.config_evaluation);
    return (
      <div className="tab-panel TaskDone">
        {this.renderOptionsPanel()}
        <EvaluationsDoneSet
          questions={questions}
          config={config}
          config_evaluation={config_evaluation}
          onSubmit={this.onSubmit}
          isModerator={this.props.isModerator}
          showReview={true}
          editReview={this.props.editReview || false}
          onReviewAccept={this.onReviewAccept}
          onCommentSubmit={this.onCommentSubmit}
          onCommentRemoval={this.onCommentRemoval}
          onCommentUpdate={this.onCommentUpdate}
        />
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isStaff: state.auth.is_staff,
    username: state.auth.username,
  };
};

export default connect(mapStateToProps)(withRouter(TaskEvaluationDone));
