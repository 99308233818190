import React, {Component} from "react";
import moment from "moment";
import {Line} from "react-chartjs-2";
import {color_table, getTimeChartData} from "../../helpers/utils";
import PropTypes from "prop-types";


export default class TaskStatisticsAnswersInTime extends Component {
  static propTypes = {
    taskStatisticsAnswersInTime: PropTypes.shape({
      real_data: PropTypes.object,
      extrapolated_data: PropTypes.object,
      last_week_extrapolated_data: PropTypes.object
    })
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    if (this.props.taskStatisticsAnswersInTime) {
      var real_data = this.props.taskStatisticsAnswersInTime.real_data;
      var labels = real_data.idx;
      var real_dates = [];

      // real data
      for (let i = 0; i < real_data.dates.length; i++) {
        real_dates.push(moment(new Date(real_data.dates[i])));
      }
    }
    var datasets = [
      {
        label: "Answers",
        color: color_table[0],
        data: real_dates,
      }
    ];

    // extrapolated data
    var extrapolated_data = this.props.taskStatisticsAnswersInTime.extrapolated_data;
    var last_week_extrapolated_data = this.props.taskStatisticsAnswersInTime.last_week_extrapolated_data;
    var extrapolated_dates = [];

    if (extrapolated_data && real_data) {
      extrapolated_dates = [];

      for (let i = 0; i < extrapolated_data.dates.length; i++) {
        extrapolated_dates.push(moment(new Date(extrapolated_data.dates[i])));
      }

      if (extrapolated_data.idx && real_data.idx && extrapolated_data.idx.length > real_data.idx.length) {
        labels = extrapolated_data.idx;
      }

      datasets.push({
        label: "Extrapolated",
        color: color_table[1],
        data: extrapolated_dates,
      });
    }
    if (last_week_extrapolated_data) {
      extrapolated_dates = [];

      for (var i = 0; i < last_week_extrapolated_data.dates.length; i++) {
        extrapolated_dates.push(moment(new Date(last_week_extrapolated_data.dates[i])));
      }

      if (last_week_extrapolated_data.idx.length > real_data.idx.length) {
        labels = last_week_extrapolated_data.idx;
      }

      datasets.push({
        label: "Extrapolated last week",
        color: color_table[2],
        data: extrapolated_dates,
      });
    }

    const chart_data = getTimeChartData(datasets, "Answers", "");
    return (<div>
      {!!this.props.taskStatisticsAnswersInTime &&
      <Line data={{labels: labels, datasets: chart_data[0]}} options={chart_data[1]}/>}
    </div>);
  }
}
