const LANGUAGES = {
  "Afrikaans": "AF_ZA",
  "Albanian": "SQ_AL",
  "Arabic - Saudi Arabia": "AR_SA",
  "Arabic - United Arab Emirates": "AR_AE",
  "Armenian": "HY_AM",
  "Basque": "EU_ES",
  "Belarusian": "BE_BY",
  "Bengali - India": "BN_IN",
  "Bhojpuri": "BH_IN",
  "Bodo": "BO_IN",
  "Bosnian": "BS_BA",
  "Bulgarian": "BG_BG",
  "Burmese": "MY_MM",
  "Catalan": "CA_ES",
  "Chinese - China": "ZH_CN",
  "Croatian": "HR_HR",
  "Czech": "CS_CZ",
  "Danish": "DA_DK",
  "Divehi;Dhivehi;Maldivian": "DV_MV",
  "Dogri": "DO_IN",
  "Dutch - Belgium": "NL_BE",
  "Dutch - Netherlands": "NL_NL",
  "English - Australia": "EN_AU",
  "English - Canada": "EN_CA",
  "English - Great Britain": "EN_GB",
  "English - India": "EN_IN",
  "English - Ireland": "EN_IR",
  "English - New Zealand": "EN_NZ",
  "English - Southern Africa": "EN_SA",
  "English - United States": "EN_US",
  "Estonian": "ET_EE",
  "Farsi - Persian": "FA_FA",
  "Finnish": "FI_FI",
  "French - Belgium": "FR_BE",
  "French - Canada": "FR_CA",
  "French - France": "FR_FR",
  "French - Switzerland": "FR_CH",
  "French - West Indies": "FR_IN",
  "FYRO Macedonia": "MK_MK",
  "Gaelic - Ireland": "GD_IR",
  // 'Gaelic - Scotland': 'Gaelic - Scotland',
  "Galician": "GL_ES",
  "Georgian": "KA_GE",
  "German - Austria": "DE_AT",
  "German - Germany": "DE_DE",
  "German - Switzerland": "DE_CH",
  "Greek": "EL_GR",
  "Guarani - Paraguay": "GN_PY",
  "Hebrew": "HE_HE",
  "Hindi": "HI_IN",
  "Hungarian": "HU_HU",
  "Icelandic": "IS_IS",
  "Indonesian": "ID_ID",
  "Italian - Italy": "IT_IT",
  "Italian - Switzerland": "IT_CH",
  "Japanese": "JA_JP",
  "Kanglish ": "KN_IN",
  "Kashmiri": "KS_IN",
  "Kazakh": "KK_KZ",
  "Khmer": "KM_KH",
  "Konkani": "OK_IN",
  "Korean": "KO_KR",
  "Kyrgyz - Cyrillic": "KY_KG",
  "Lao": "LO_LA",
  "Latin": "LA_LA",
  "Latvian": "LV_LV",
  "Lithuanian": "LT_LT",
  "Maithili": "BH_IN",
  "Malayalam": "ML_IN",
  "Malay - Malaysia": "MS_MY",
  "Malay - Indonesia": "MS_ID",
  "Manipuri": "MA_IN",
  "Maori": "MI_NZ",
  "Marathi": "MR_IN",
  "Marwari": "MV_IN",
  "Mewari": "ME_IN",
  "Mongolian": "MN_MN",
  "Neplish": "NE_NP",
  "Norwegian - Bokml": "NB_NO",
  "Norwegian - Nynorsk": "NN_NO",
  "Odinglish": "OR_IN",
  "Polish": "PL_PL",
  "Portuguese - Brazil": "PT_BR",
  "Portuguese - Portugal": "PT_PT",
  "Punjabi": "PA_PK",
  "Romanian - Romania": "RO_RO",
  "Russian": "RU_RU",
  "Sanskrit": "SA_IN",
  "Serbian - Latin": "SR_RS",
  "Sesotho (Sutu)": "ST_ZA",
  "Sindhi": "SD_IN",
  "Singlish": "SI_LK",
  "Slovak": "SK_SK",
  "Slovenian": "SL_SI",
  "Somali": "SO_SO",
  "Spanish - Argentina": "ES_AR",
  "Spanish - Mexico": "ES_MX",
  "Spanish - Spain (Traditional)": "ES_ES",
  "Spanish - United States": "ES_US",
  "Swahili": "SW_SW",
  "Swedish - Finland": "SV_FI",
  "Swedish - Sweden": "SV_SE",
  "Syriac": "SY_SY",
  "Tamil": "TA_IN",
  "Telugu": "TE_IN",
  "Thai": "TH_TH",
  "Ukrainian": "UK_UA",
  "Urdu": "UR_IN",
  "Uzbek - Latin": "UZ_UZ",
  "Vietnamese": "VI_VN",
  "Welsh": "CY_GB",
  "Xhosa": "XH_ZA",
  "Yiddish": "YI_YI",
  "Zulu": "ZU_ZA",
};

const LANGUAGE_SKILLS = {
  10: "A1",
  20: "A1/A2",
  30: "A2",
  40: "A2/B1",
  50: "B1",
  60: "B1/B2",
  70: "B2",
  80: "B2/C1",
  90: "C1",
  100: "C1/C2",
  110: "C2",
};

export {LANGUAGES, LANGUAGE_SKILLS};
