import moment from "moment";
import _ from "lodash";

import React, {Component} from "react";
import PropTypes from "prop-types";

import Tabs from "../../../components/Tabs";

export default class TaskFAQ extends Component {
  static propTypes = {
    faqs: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    tutorialDisplayTimeAck: PropTypes.func,
    forEvaluators: PropTypes.bool,
  };

  componentDidMount() {
    this.props.tutorialDisplayTimeAck();
  }

  render() {
    const panes =
      _.map(this.props.faqs, (faq, idx) => {
        const modified = moment(faq.modified).format("LLLL");

        const _render = (
          <React.Fragment>
            <div className="ui label modified">
              Modified: {modified}
            </div>
            <div
              className="tab-panel sub-tab"
              dangerouslySetInnerHTML={{__html: faq.content}}/>
          </React.Fragment>
        );

        return {
          key: `${idx}faq`,
          label: {
            icon: "",
            name: faq.title || "FAQ",
          },
          components: _render
        };
      });

    return (
      <div className="TaskTutorial">
        <Tabs panes={panes} isWithoutUrl={true}/>
      </div>
    );
  }
}
