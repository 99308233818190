import React from "react";
import PropTypes from "prop-types";
import {DateInput as SemanticDateInput} from "semantic-ui-calendar-react";
import moment from "moment";

import config from "../config/config";

/**
 * Wrapper for {DateInput} from "semantic-ui-calendar-react".
 * All props dates should be in ISO format.
 * Component displays time in a browser-local time.
 * @param props
 * @returns {*}
 * @constructor
 */
export const DateInput = (props) => {

  return (
    <SemanticDateInput
      {...props}
      value={props.value ? props.value : ""}
      dateFormat={config.FORMAT_Ymd}
      onChange={(e, data) => {
        let date = moment(data.value, config.FORMAT_Ymd);
        props.onChange(moment(date).format(config.FORMAT_Ymd));
      }}
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
