import _ from "lodash";
import React, {useRef, useState} from "react";
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";
import {Pagination} from "semantic-ui-react";
import {Stage, Layer} from "react-konva/lib/ReactKonvaCore";

import Widget from "../../Widget";
import Shape from "./Shape";
import ShapeCreator from "./ShapeCreator";
import useCanvasResize from "../Hooks/useCanvasResize";
import useSegmentsState from "../Hooks/useSegmentsState";
import useRegisterEmpty from "../Hooks/useRegisterEmpty";
import useUpdateWidgetValue from "../Hooks/useUpdateWidgetValue";


const ShapeSegmentationWidget = (props) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [canvasSize, setCanvasSize] = useCanvasResize();
  const imageRef = useRef();

  let images = props.value ? props.value.images : [null];

  const [type, setType] = useState(props.widgetConfig.segmentTypes[0] || {});

  const [selectedSegment, setSelectedSegment] = useState({idx: null, active: false});

  const [segments, segmentsUpdateUtil] = useSegmentsState(
    props.value && props.value.segmentsOnPages
      ? props.value.segmentsOnPages.map(
        (img) => img.map((segment) => ({
          key: _.uniqueId(),
          ...segment
        }))
      )
      : images.map(() => [])
    , activePageIndex, selectedSegment);

  const [isCreationMode, setIsCreationMode] = useState(false);

  const {registerEmpty, componentId, value, update} = props;

  useRegisterEmpty(segments, registerEmpty, componentId);
  useUpdateWidgetValue(segments, !isCreationMode, componentId, update, value);

  const handleAddSegment = (line) => {
    segmentsUpdateUtil((activeImage) => {
      activeImage.push({
        type,
        line,
        key: _.uniqueId()
      });
    });
    setIsCreationMode(false);
  };

  const handleDeleteSegment = () => {
    segmentsUpdateUtil((activeImage) =>
      activeImage.splice(selectedSegment.idx, 1)
    );
    setSelectedSegment({idx: null, active: false});
  };

  const handleDragEnd = (absolutePoints) => {
    segmentsUpdateUtil((activeImage, activeSegment) => {
      activeSegment.line = absolutePoints;
    });
  };

  let contextMenuItems;
  if (selectedSegment.active !== false) {
    contextMenuItems =
      <MenuItem
        onClick={handleDeleteSegment}>
        Delete
      </MenuItem>;
  } else {
    contextMenuItems = props.widgetConfig.segmentTypes.map((segmentType) =>
      <MenuItem
        data={{segmentType}}
        onClick={(e, {segmentType}) => setType(segmentType)}
        key={segmentType.name}>
        {segmentType.name}
      </MenuItem>
    );
  }

  return <div className="ImageSegmentationWidget Widget">
    <ContextMenuTrigger
      holdToDisplay={-1}
      id="context-menu-id">
      <div ref={imageRef} className="image-container">
        <img src={images[activePageIndex]} onLoad={setCanvasSize} alt={""}/>
        <Stage width={canvasSize.width} height={canvasSize.height} className="canvas-container">
          <Layer>
            {segments[activePageIndex].map((segment, i) =>
              <Shape
                {...segment}
                key={segment.key}
                imageRef={imageRef}
                idx={i}
                canvasSize={canvasSize}
                selectedSegment={selectedSegment}
                onSegmentSelection={setSelectedSegment}
                onDragEnd={handleDragEnd}
                isDisabled={
                  isCreationMode
                  ||
                  (selectedSegment.active && selectedSegment.idx !== i)
                }
              />
            )}
            <ShapeCreator
              type={type}
              imageRef={imageRef}
              selectedSegment={selectedSegment}
              isCreationMode={isCreationMode}
              onAddSegment={handleAddSegment}
              onSetIsCreationMode={setIsCreationMode}/>
          </Layer>
        </Stage>
      </div>
    </ContextMenuTrigger>
    <ContextMenu
      id="context-menu-id"
      onHide={() => setSelectedSegment({idx: null, active: false})}>
      {contextMenuItems}
    </ContextMenu>
    <Pagination
      activePage={activePageIndex + 1}
      onPageChange={(e, {activePage}) => setActivePageIndex(activePage - 1)}
      totalPages={images.length}/>
  </div>;
};

ShapeSegmentationWidget.propTypes = {
  ...Widget.propTypes
};

export default ShapeSegmentationWidget;
