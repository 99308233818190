import React, {Component} from "react";
import {Button} from "semantic-ui-react";
import PropTypes from "prop-types";


class ShowInputButton extends Component {
  static propTypes = {
    showInput: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Button
        size="tiny" icon={`${this.props.showInput ? "plus square outline" : "plus"}`} className={"tiniest"}
        compact
        onClick={this.props.onClick}
      />
    );
  }
}

export default ShowInputButton;
