import React, { useState, useRef } from "react";
import {Button, Icon} from "semantic-ui-react";
import "./recorder.css";
import PropTypes from "prop-types";

export default function Recorder(props) {
  const [time, setTime] = useState({ms: 0, s: 0, m: 0, h: 0});
  const [interv, setInterv] = useState();

  const start = () => {
    run();
    setInterv(setInterval(run, 10));
  };

  var updatedMs = time.ms, updatedS = time.s, updatedM = time.m, updatedH = time.h;

  const run = () => {
    if (updatedM === 60) {
      updatedH++;
      updatedM = 0;
    }
    if (updatedS === 60) {
      updatedM++;
      updatedS = 0;
    }
    if (updatedMs === 100) {
      updatedS++;
      updatedMs = 0;
    }
    updatedMs++;
    return setTime({ms: updatedMs, s: updatedS, m: updatedM, h: updatedH});
  };

  const stop = () => {
    clearInterval(interv);
  };

  const reset = () => {
    clearInterval(interv);
    setTime({ms: 0, s: 0, m: 0, h: 0});
  };

  const resume = () => start();


  const [stream, setStream] = useState({
    access: false,
    recorder: null,
    error: ""
  });
  const [recording, setRecording] = useState({
    paused: false,
    active: false,
    available: false
  });

  const chunks = useRef([]);

  function cancel() {
    setStream({
      access: false,
      recorder: null,
    });
    setRecording({
      paused: false,
      active: false,
      available: false
    });
  }

  function record() {
    if (window.location.href.split("/").pop() !== "new") {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mic) => {
        let mediaRecorder;

        try {
          mediaRecorder = new MediaRecorder(mic, {
            mimeType: "audio/webm"
          });
        } catch (err) {
          console.log(err);
        }


        mediaRecorder.onstart = function () {
          setRecording({
            paused: false,
            active: true,
            available: false
          });
        };

        mediaRecorder.onpause = function () {
          setRecording({
            paused: true,
            active: true,
            available: false
          });
        };

        mediaRecorder.onresume = function () {
          setRecording({
            paused: false,
            active: true,
            available: false
          });
        };

        mediaRecorder.ondataavailable = function (e) {
          chunks.current.push(e.data);
        };

        mediaRecorder.onstop = async function () {
          props.onRecordingComplete(chunks.current[0]);
          chunks.current = [];

          setRecording({
            active: false,
            available: true,
          });
        };

        setStream({
          ...stream,
          access: true,
          recorder: mediaRecorder
        });
        mediaRecorder.start();
      })
      .catch((error) => {
        console.log(error);
        setStream({ ...stream, error });
      });
  }

  return (
    <div className="recorder-main">
      <div className="audio-container">
        {!recording.active ?
          <div className="recorder-body">
            <span>Please, press mic to start recording</span>
            <Button circular icon="microphone" onClick={() => {
              record(); start();
            }}/>
          </div>
          :
          <div className="recorder-body">
            <div className="recorder-controls">
              {!recording.paused ?
                <Button circular color="black" size="mini" icon="pause" onClick={() => {
                  stream.recorder.pause(); stop();
                }}/>
                :
                <Button circular color="green" size="mini" icon="microphone" onClick={() => {
                  stream.recorder.resume(); resume();
                }}/>
              }
              {recording.paused ?
                <span className="status"><Icon size="small" name="circle" color="grey"/></span>
                :
                <span className="status"><Icon size="small" color="red" className="playing" name="circle"/></span>
              }
              <div className="audio-answer-timer">
                <span>{time.h}</span>:
                <span>{time.m}</span>:
                <span>{time.s}</span>.
                <span>{time.ms}</span>
              </div>
              <Button circular color="red" size="mini" icon="close" onClick={() => {
                cancel(); reset();
              }}/>
              <Button circular color="blue" size="mini" icon="save" onClick={() => {
                stream.recorder.stop(); stop();
              }}/>
            </div>

          </div>
        }
      </div>

    </div>
  );
}
Recorder.propTypes = {
  onRecordingComplete: PropTypes.func.isRequired
};
