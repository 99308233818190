/* eslint-disable react/forbid-foreign-prop-types */
import _ from "lodash";
import React from "react";
import {Button, Container} from "semantic-ui-react";

import "./RadioButtonWidget.css";
import Widget from "../Widget";


class RadioButtonWidget extends Widget {
  static propTypes = {...Widget.propTypes};

  static getDefaultConfig = () => {
    return {
      options: [{value: "Option 1", color: "teal"}],
      customClass: "",
      align: "align-left",
      selection_color: "black",
      grouped: false,
      justify: false,
    };
  };

  componentDidMount() {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    let widgetVal = widgetVals[this.props.componentId];
    if (!widgetVal) {
      widgetVal = [];
      widgetVals[this.props.componentId] = widgetVal;
    }

    this.registerEmptyValid();
    this.update(widgetVal);
  }

  isEmpty = () => {
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    const widgetVal = widgetVals[this.props.componentId];

    return !(widgetVal && widgetVal.length);
  };

  isValid = () => {
    return true;
  };

  updateParent = (e, val) => {
    const multiple = this.props.widgetConfig.multiple;
    const widgetVals = _.cloneDeep(this.props.widgetVals);
    let widgetVal = widgetVals[this.props.componentId];
    if (_.isEmpty(widgetVal)) {
      widgetVal = [val];
    } else {
      if (multiple) {
        const currentVals = widgetVal;
        const index = currentVals.indexOf(val);
        if (index > -1) {
          currentVals.splice(index, 1);
        } else {
          currentVals.push(val);
        }
      } else {
        widgetVal = _.isEqual(widgetVal, [val]) ? [] : [val]; // weak comparison in purpose -> "val", ["val"]
      }
      widgetVal.empty = !widgetVal;
    }
    this.update(widgetVal);
  };

  render() {
    const config = this.props.widgetConfig;

    let customClasses = `${config.align} selection-${config.selection_color}`;
    if (!config.grouped) {
      customClasses += " ungrouped";
    } else {
      customClasses += " grouped";
    }

    const buttons = _.map(config.options, (option, idx) => {
      const value = option.value.split(" ").join("_").toLowerCase();
      // fix for answers saving wrong values after adding upper/lower case distinction
      const receivedValue = this.props.value && Array.isArray(this.props.value)
        ? this.props.value.map(
          (val) => {
            return val.toLowerCase();
          }
        )
        : null;
      return (
        <Button color={option.color}
          key={idx}
          active={_.includes(receivedValue, value)}
          onClick={(e) => this.updateParent(e, value)}
        >
          {option.value}
        </Button>
      );
    });

    const props = {};
    if (config.justify) {
      props.widths = 8;
    }

    return (
      <Container className={`RadioButtonWidget Widget ${customClasses}`}>
        {!!config.grouped ?
          <Button.Group {...props}>
            {buttons}
          </Button.Group>
          :
          buttons
        }
      </Container>
    );
  }
}

export default RadioButtonWidget;
