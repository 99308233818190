import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Icon, Popup, Table} from "semantic-ui-react";
import _ from "lodash";
import {formatSeconds} from "../../helpers/utils";
import {getParsedFilters, updateFiltersStateless} from "../../helpers/filters";
import {withRouter} from "react-router-dom";

import urls from "../../config/frontend_urls";
import PropTypes from "prop-types";
import moment from "moment";

export class TaskStatisticsTable extends Component {
  static propTypes = {
    taskStatisticsList: PropTypes.array,
    taskSlug: PropTypes.string,
    isNotGrouped: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    allTime: PropTypes.bool,
    manual: PropTypes.object,
    location: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.getFilters = getParsedFilters.bind(this);
  }

  sortUsers = () => {
    let userList;
    const userListFilter = this.getFilters(this.props).userList;
    if (!userListFilter) {
      userList = "ascending";
    } else if (userListFilter === "ascending") {
      userList = "descending";
    } else {
      userList = null;
    }
    this.updateFiltersStateless({userList}, false);
  }

  renderTaskRows() {
    let rows = <Table.Row/>;
    let summary_data = {};
    if (!!this.props.taskStatisticsList) {
      summary_data = {
        deadline: "",
        answers: 0,
        std: 0,
        base_stats_mean: 0,
        base_stats_summary: 0,
        sessions_mean: 0,
        sessions_summary: 0,
        percentiles_mean: 0,
        percentiles_summary: 0,
      };
      rows = this.props.taskStatisticsList;
      const userListFilter = this.getFilters(this.props).userList;
      if (!!userListFilter) {
        rows = _.sortBy(this.props.taskStatisticsList, [(o) => o.username.toLowerCase()]);
      }
      if (userListFilter === "descending") {
        rows = rows.reverse();
      }
      rows = _.map(rows, (user, idx) => {
        const user_data = {
          deadline: "",
          answers: "",
          std: "",
          base_stats_mean: "",
          base_stats_summary: "",
          sessions_mean: "",
          sessions_summary: "",
        };
        if ("base_stats" in user && "sessions" in user) {
          user_data["answers"] = !!user.base_stats.max_questions ?
            user.base_stats.total_answers + " / " + user.base_stats.max_questions :
            user.base_stats.total_answers;
          if (user.deadline) {
            user_data["deadline"] = moment(user.deadline).format("DD.MM.YYYY HH:mm");
          } else {
            user_data["deadline"] = "---";
          }
          user_data["std"] = formatSeconds(user.base_stats.std);
          user_data["base_stats_mean"] = formatSeconds(user.base_stats.mean);
          user_data["base_stats_summary"] = formatSeconds(user.base_stats.total_time);
          user_data["sessions_mean"] = formatSeconds(user.sessions.mean);
          user_data["sessions_summary"] = formatSeconds(user.sessions.total_time);
          user_data["percentiles_mean"] = "percentiles" in user ? formatSeconds(user.percentiles.mean) : 0;
          user_data["percentiles_summary"] = "percentiles" in user ? formatSeconds(user.percentiles.total_time) : 0;

          summary_data.answers += parseInt(user.base_stats.total_answers);
          summary_data.std += user.base_stats.std;
          summary_data.base_stats_mean += user.base_stats.total_time;
          summary_data.base_stats_summary += user.base_stats.total_time;
          summary_data.sessions_mean += user.sessions.total_time;
          summary_data.sessions_summary += user.sessions.total_time;
          summary_data.percentiles_mean += user.percentiles.total_time;
          summary_data.percentiles_summary += user.percentiles.total_time;
        }
        const url = urls.TASK_USER_STATISTICS
          .replace(":taskSlug", this.props.taskSlug)
          .replace(":userName", user.username);
        return (
          <Table.Row key={"Task" + idx}>
            <Table.Cell>
              {this.props.isNotGrouped &&
                <Link to={{
                  pathname: url,
                  state: {
                    start_date: this.props.startDate,
                    end_date: this.props.endDate,
                    all: this.props.allTime
                  }
                }}>
                  <Button basic fluid size="mini" color={"black"}>
                    <Icon name="user"/>{user.username}
                  </Button>
                </Link>
              }
              {!this.props.isNotGrouped &&
                <React.Fragment>
                  <Icon name="user"/>{user.username}
                </React.Fragment>
              }
            </Table.Cell>
            <Table.Cell className={"light-grey"}>{user_data.deadline}</Table.Cell>
            <Table.Cell className={"light-grey"}>{user_data.answers}</Table.Cell>
            <Table.Cell className={"light-grey"}>{user_data.std}</Table.Cell>
            <Table.Cell>{user_data.base_stats_mean}</Table.Cell>
            <Table.Cell>{user_data.base_stats_summary}</Table.Cell>
            <Table.Cell className={"light-grey"}>{user_data.sessions_mean}</Table.Cell>
            <Table.Cell className={"light-grey"}>{user_data.sessions_summary}</Table.Cell>
            <Table.Cell>{user_data.percentiles_mean}</Table.Cell>
            <Table.Cell>{user_data.percentiles_summary}</Table.Cell>
          </Table.Row>
        );
      }
      );
      if (this.props.taskStatisticsList.length > 0) {
        summary_data.std /= this.props.taskStatisticsList.length;
      } else {
        rows = <Table.Row/>;
      }
      if (summary_data.answers > 0) {
        summary_data.base_stats_mean /= summary_data.answers;
        summary_data.sessions_mean /= summary_data.answers;
        summary_data.percentiles_mean /= summary_data.answers;
      }
    }
    return [rows, summary_data];
  }

  render() {
    const [rows, summary_data] = this.renderTaskRows();
    const userListFilter = this.getFilters(this.props).userList;
    return (
      <Table selectable sortable celled structured textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan={2}
              onClick={this.sortUsers}
              className={userListFilter ? userListFilter + " sorted light-grey" : "light-grey"}>User</Table.HeaderCell>
            <Popup
              trigger={
                <Table.HeaderCell rowSpan={2} className={"grey"}>
                  Deadline
                </Table.HeaderCell>
              } content={this.props.manual.DEADLINE}/>
            <Popup trigger={
              <Table.HeaderCell rowSpan={2}
                className={"grey"}>Answers</Table.HeaderCell>
            } content={this.props.manual.ANSWERS}/>
            <Popup
              trigger={
                <Table.HeaderCell rowSpan={2}
                  className={"grey"}>Std</Table.HeaderCell>
              } content={this.props.manual.STD}/>
            <Popup trigger={
              <Table.HeaderCell colSpan={2} className={"light-grey"}>Simple
                stats</Table.HeaderCell>
            } content={<div
              dangerouslySetInnerHTML={{"__html": this.props.manual.SIMPLE_STATS}}/>
            } position="bottom left"/>
            <Popup trigger={
              <Table.HeaderCell colSpan={2}
                className={"grey"}>Sessions</Table.HeaderCell>
            } content={<div
              dangerouslySetInnerHTML={{"__html": this.props.manual.SESSIONS_STATS}}/>
            } position="bottom left"/>
            <Popup trigger={
              <Table.HeaderCell colSpan={2}
                className={"light-grey"}>Percentile</Table.HeaderCell>
            } content={<div
              dangerouslySetInnerHTML={{"__html": this.props.manual.PERCENTILES_STATS}}/>
            } position="bottom left"/>
          </Table.Row>
          <Table.Row>
            <Popup trigger={
              <Table.HeaderCell className={"light-grey"}>Mean</Table.HeaderCell>
            } content={this.props.manual.MEAN}/>
            <Popup trigger={
              <Table.HeaderCell className={"light-grey"}>Summary</Table.HeaderCell>
            } content={this.props.manual.SUMMARY}/>
            <Popup trigger={
              <Table.HeaderCell className={"grey"}>Mean</Table.HeaderCell>
            } content={this.props.manual.MEAN}/>
            <Popup trigger={
              <Table.HeaderCell className={"grey"}>Summary</Table.HeaderCell>
            } content={this.props.manual.SUMMARY}/>
            <Popup trigger={
              <Table.HeaderCell className={"light-grey"}>Mean</Table.HeaderCell>
            } content={this.props.manual.MEAN}/>
            <Popup trigger={
              <Table.HeaderCell className={"light-grey"}>Summary</Table.HeaderCell>
            } content={this.props.manual.SUMMARY}/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
        <Table.Footer>
          <Table.Row className={"BoldRow"}>
            <Table.HeaderCell className={"light-grey"}/>
            <Table.HeaderCell className={"grey"}/>
            <Table.HeaderCell
              className={"grey"}>{summary_data.answers}</Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.std)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.base_stats_mean)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.base_stats_summary)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.sessions_mean)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"grey"}>{formatSeconds(summary_data.sessions_summary)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.percentiles_mean)}</Table.HeaderCell>
            <Table.HeaderCell
              className={"light-grey"}>{formatSeconds(summary_data.percentiles_summary)}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
export default withRouter(TaskStatisticsTable);
