import _ from "lodash";

import React from "react";
import {Accordion, Button, Form, Input, Label, Table, Checkbox} from "semantic-ui-react";
import {Flexbox} from "../../../../components/simple/Flexbox/Flexbox";

import RichTextEditor from "../../../../components/RichTextEditor";
import PropTypes from "prop-types";


export default class Wiki extends React.Component {
  static propTypes = {
    wiki: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    idx: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    errors: PropTypes.object,
    isEvaluationEnable: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      editorInitiated: false,
    };
  }

  onWikiChange = (wiki) => {
    this.props.onChange({
      ...this.props.wiki,
      ...wiki
    });
  };

  render() {
    const {isEvaluationEnable, wiki} = this.props;
    const isTutorial = this.props.type === "tutorial";
    const is_error = !_.isEmpty(_.get(this.props.errors, this.props.idx));
    let tableRowClassName = `${wiki.to_delete ? "to-be-deleted" : ""}`;
    tableRowClassName += `${is_error ? " tab-errors description" : ""}`;

    return (
      <Table.Row className={tableRowClassName}>
        <Table.Cell>
          <Accordion fluid styled>
            <Accordion.Title
              active={this.state.active}
              onClick={() => this.setState({active: !this.state.active})}>
              <Flexbox>
                <span>{wiki.title} - click to {`${this.state.active ? "collapse" : "expand"}`}</span>
                <Button
                  className={"delete-button"}
                  basic size={"mini"} color={"red"} icon={"trash"}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onWikiChange({to_delete: ["", "YES"][wiki.to_delete ? 0 : 1]});
                  }}
                />
              </Flexbox>
            </Accordion.Title>
            <Accordion.Content active={this.state.active}>
              {isEvaluationEnable &&
                <Form.Field>
                  <Checkbox
                    className={"green-radio forLinguists"}
                    checked={!wiki.for_evaluators}
                    onChange={(e, data) => this.onWikiChange({for_evaluators: !data.checked})}
                    label={"For linguists"}
                  />
                  <Checkbox
                    className={"green-radio"}
                    label={"For evaluators"}
                    checked={wiki.for_evaluators}
                    onChange={(e, data) => this.onWikiChange({for_evaluators: data.checked})}
                  />
                </Form.Field>
              }
              {isTutorial && <Form.Field>
                <Checkbox
                  className={"green-radio"}
                  label={"Is required"}
                  checked={wiki.is_required}
                  onChange={(e, data) => this.onWikiChange({is_required: data.checked})}
                />
              </Form.Field>
              }
              <Form.Field>
                <Label>Title</Label>
                <Input
                  className={"title-input"}
                  fluid
                  // label={'Title'}
                  placeholder={"Title"}
                  value={wiki.title} // this is because backend expects a different field for new title...
                  onChange={(e) => this.onWikiChange({title: e.target.value})}
                />
              </Form.Field>

              {this.state.active && //bit of optimization. Rendering them all was slow.
              <RichTextEditor
                height="auto"
                content={wiki.content}
                onChange={(content) => this.onWikiChange({content})}
              />
              }
            </Accordion.Content>
          </Accordion>
        </Table.Cell>
      </Table.Row>
    );
  }
}
