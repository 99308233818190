import _ from "lodash";

import React from "react";
import {Checkbox, Grid, Header} from "semantic-ui-react";
import {WidgetEditor} from "../WidgetEditor";
import QueryBuilder from "../../QueryBuilder/QueryBuilder";

import "./SubmitButtonWidgetEditor.css";
import {iterateAllWidgets} from "../widgetUtils";

class SubmitButtonWidgetEditor extends WidgetEditor {
  static header = "Submit";

  generateFieldsConfig = () => {
    const widgetsConfig = _.cloneDeep(this.props.getWidgetConfig());

    const fields = {};
    iterateAllWidgets(widgetsConfig, (w) => {
      const componentId = w.componentId;
      const componentType = w.componentType;

      if (!_.find(["SubmitWidget", "ContainerWidget", "GooglePlayWidget", "RichTextReadOnlyWidget"],
        (o) => o === componentType)) {
        fields[componentId] = {
          label: componentId,
          type: "select",
          operators: [
            "is_empty",
            "is_not_empty",
            "is_valid",
            "is_not_valid",
          ]
        };

        // add values for RadioButton
        if (componentType === "RadioButtonWidget") {
          fields[componentId].operators = _.concat(
            fields[componentId].operators, ["select_equals", "select_not_equals"]);

          fields[componentId].listValues = {};
          /* eslint-disable-next-line no-unused-vars */
          _.forEach(w.options, (option, idx) => {
            fields[componentId].listValues[_.snakeCase(option.value)] = option.value;
          });
        }
      }
    });
    return fields;
  };

  onChange = (tree) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.query = tree;
    });
  };

  render() {
    const widgetsConfig = _.cloneDeep(this.props.getWidgetConfig());
    const widgetConfig = _.find(widgetsConfig, {componentId: this.state.widgetId});
    const initialValue = widgetConfig.query;

    return (
      <Grid
        className={"SubmitButtonWidgetEditor"}
      >
        <Header as="h3" textAlign="center">Submit queryset:</Header>
        <QueryBuilder
          init={initialValue}
          fields={this.generateFieldsConfig()}
          onChange={this.onChange}
        />
        <Header
          className="row"
          as="h4">Skippable</Header>
        <Checkbox
          toggle
          className="row"
          checked={widgetConfig.skippable}
          onChange={(e, data) => this.updateConfig("skippable", data.checked)}/>
        {this.renderClose()}
      </Grid>
    );
  }
}

export default SubmitButtonWidgetEditor;
