import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {Accordion, Button, Card, Container, Dimmer, Form, Grid, Header, Icon, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import config from "../../config/config";
import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import FilteredComponent from "../../components/FilteredComponent";
import LoaderSpinner from "../../components/LoaderSpinner";
import ajax from "../../helpers/ajax";
import {updateFiltersStateless} from "../../helpers/filters";
import {archive_switch, formatDate} from "../../helpers/utils";
import TaskUserBasicStatistics from "./TaskUserChart";
import TaskUserSessionTable from "./TaskUserSessionTable";
import TaskUserHeadersTable from "./TaskUserHeadersTable";

import "./TaskStatistics.css";
import "./TaskUserStatistics.css";

class TaskUserStatistics extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      manual: {},
      activeIndex: -1
    };
    this.defaultFilters = {all: true};
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
  }

  getBasicTaskUserStatistics = () => {
    this.setState({taskUserBasic: null});
    const filter_values = {...this.state.filters};
    filter_values.fixed = false;
    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      formatDate(filter_values);
    }
    delete filter_values.all;
    ajax.get([config.TASK_USER_PERCENTILES,
      this.props.match.params.taskSlug, this.props.match.params.userName],
    {params: filter_values}
    )
      .then((response) => {
        this.setState({taskUserBasic: response.data});
      })
      .catch(() => {
        // console.log('error', error);
      });
  };

  getBasicTaskUserSession = () => {
    this.setState({taskUserSession: null});
    const filter_values = {...this.state.filters};
    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      formatDate(filter_values);
    }
    delete filter_values.all;
    ajax.get([config.TASK_USER_SESSION,
      this.props.match.params.taskSlug, this.props.match.params.userName],
    {params: filter_values})
      .then((response) => {
        this.setState({taskUserSession: response.data});
      })
      .catch(() => {
        // console.log('error', error);
      });
  };

  getTaskStatisticsPercentiles = () => {
    this.setState({taskStatisticsSimplePercentiles: null});
    this.setState({taskStatisticsFixedPercentiles: null});
    ajax.get([config.TASK_STATISTICS_PERCENTILES, this.props.match.params.taskSlug])
      .then((response) => {
        const data = response.data;
        if (data) {
          const taskStatisticsSimplePercentiles = {
            task_percentiles: data.percentiles,
            avg_percentiles: data.avg_percentiles,
            min_percentiles: data.min_percentiles,
            max_percentiles: data.max_percentiles,
          };
          const taskStatisticsFixedPercentiles = {
            task_percentiles: data.fixed_percentiles,
            avg_percentiles: data.avg_fixed_percentiles,
            min_percentiles: data.min_fixed_percentiles,
            max_percentiles: data.max_fixed_percentiles,
          };
          this.setState({
            taskStatisticsSimplePercentiles,
            taskStatisticsFixedPercentiles
          });
        }
      })
      .catch(() => {
        // console.log('error', error);
      });
  };

  getBasicTaskUserPercentiles = () => {
    this.setState({taskUserPercentiles: null});
    const filter_values = {...this.state.filters};
    if (filter_values.date_range) {
      [filter_values.start_date, filter_values.end_date] = filter_values.date_range.split(" - ");
    }
    if (!this.state.filters.all) {
      formatDate(filter_values);
    }

    delete filter_values.all;
    ajax.get([config.TASK_USER_PERCENTILES,
      this.props.match.params.taskSlug, this.props.match.params.userName],
    {params: filter_values})
      .then((response) => {
        this.setState({taskUserPercentiles: response.data});
      })
      .catch(() => {
        // console.log('error', error);
      });
  };

  getData = () => {
    this.getBasicTaskUserStatistics();
    this.getBasicTaskUserSession();
    this.getBasicTaskUserPercentiles();
    this.getTaskStatisticsPercentiles();
  };

  setAll = (flag) => {
    const date = `${moment("01", "DD").format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`;
    const date_range = flag ? "" : date;
    this.changeFilterInstantly({
      all: flag,
      date_range
    });
  };

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({activeIndex: newIndex});
  };

  render() {
    const basicLoaded = !!this.state.taskUserBasic;
    const sessionLoaded = !!this.state.taskUserSession;
    const percentilesLoaded = !!this.state.taskUserPercentiles;
    const params = {...this.state.filters};

    const filterPopup =
      <Form>
        <Form.Group>
          <Form.Field>
            <label>Date range</label>
            <DatesRangeInput
              autoComplete="off"
              dateFormat="YYYY-MM-DD"
              style={{width: "225px"}}
              name="datesRange"
              placeholder="Start Date - End Date"
              value={params.date_range || ""}
              iconPosition="left"
              popupPosition="bottom center"
              onChange={(e, data) => this.changeFilterInstantly({date_range: data.value})}
              disabled={params.all}
            />
          </Form.Field>
          <Form.Field>
            <label>All time</label>
            <Form.Checkbox
              checked={params.all || false}
              onChange={(e, data) => this.setAll(data.checked)}
            />
          </Form.Field>
        </Form.Group>
      </Form>;

    return (
      <Dimmer.Dimmable as={Segment} dimmed={true} style={{boxShadow: "none", border: "0px"}}>
        <Container className="TaskUserStatistics">
          <Card.Group>
            <Card fluid className="datahive-card">
              <Link to={"/task-statistics/" + this.props.match.params.taskSlug}>
                <Button fluid size={"big"} color="white2" className="datahive-button">
                  <Icon
                    name="tasks"/> {this.props.match.params.taskSlug}
                </Button>
              </Link>
            </Card>
            <Card fluid className="datahive-card">
              <Link to={"/user-statistics/" + this.props.match.params.userName}>
                <Button fluid size={"big"} color="light-grey" className="datahive-button">
                  <Icon
                    name="user"/> {this.props.match.params.userName}
                </Button>
              </Link>
            </Card>
            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                      <Header textAlign="center">User Task statistics</Header>
                    </Grid.Column>
                    <Grid.Column/>
                  </Grid.Row>
                </Grid>
                <Grid columns={1}>
                  <Grid.Row>
                    <Grid.Column>
                      {filterPopup}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>

            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Header textAlign="center">Basic
                        statistics {archive_switch.title[this.state.switchIndex]}</Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {basicLoaded &&
              <Segment>
                <TaskUserHeadersTable data={this.state.taskUserBasic.stats} type={"Chart"}/>
                <Accordion>
                  <Accordion.Title active={this.state.activeIndex === 0} index={0}
                    onClick={this.handleClick}>
                    <Icon size="big" name="angle down"/>
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === 0}>
                    <TaskUserBasicStatistics DataPercentiles={this.state.taskUserBasic}
                      Percentiles={this.state.taskStatisticsSimplePercentiles}/>
                  </Accordion.Content>
                </Accordion>
              </Segment>
              }
              {!basicLoaded && <LoaderSpinner/>}
            </Card>

            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Header textAlign="center">Session
                        statistics {archive_switch.title[this.state.switchIndex]}</Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {sessionLoaded &&
              <Segment>
                <TaskUserHeadersTable data={this.state.taskUserSession} type={"Table"}/>
                <Accordion>
                  <Accordion.Title active={this.state.activeIndex === 1} index={1}
                    onClick={this.handleClick}>
                    <Icon size="big" name="angle down"/>
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === 1}>
                    <TaskUserSessionTable status={this.state.taskUserSession}/>
                  </Accordion.Content>
                </Accordion>
              </Segment>
              }
              {!sessionLoaded && <LoaderSpinner/>}
            </Card>

            <Card fluid className="datahive-card">
              <Card.Content>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Header textAlign="center">Percentiles
                        statistics {archive_switch.title[this.state.switchIndex]}</Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
              {percentilesLoaded &&
              <Segment>
                <TaskUserHeadersTable data={this.state.taskUserPercentiles.stats} type={"Chart"}/>
                <Accordion>
                  <Accordion.Title active={this.state.activeIndex === 2} index={2}
                    onClick={this.handleClick}>
                    <Icon size="big" name="angle down"/>
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === 2}>
                    <TaskUserBasicStatistics DataPercentiles={this.state.taskUserPercentiles}
                      Percentiles={this.state.taskStatisticsFixedPercentiles}/>
                  </Accordion.Content>
                </Accordion>
              </Segment>
              }
              {!percentilesLoaded && <LoaderSpinner/>}
            </Card>
          </Card.Group>
        </Container>
      </Dimmer.Dimmable>
    );
  }
}

const
  mapStateToProps = (state) => {
    return {
      auth: state.auth
    };
  };

const
  mapDispatchToProps = (dispatch) => {
    return {
      errorsNew: (message) => dispatch({
        type: ERRORS_NEW,
        errors: message
      }),
      errorsClear: () => dispatch({
        type: ERRORS_CLEAR,
      })
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskUserStatistics));
