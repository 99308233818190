import _ from "lodash";
import React, {Component} from "react";
import {toastr} from "react-redux-toastr";
import {Button, Container, Header} from "semantic-ui-react";
import PropTypes from "prop-types";

import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import urls from "../../../config/frontend_urls";
import ModalConfirm from "../../../components/ModalConfirm";
import BottomBar from "../../../components/BottomBar";
import QuestionAnswerTable from "./QuestionAnswerTable";
import LoaderSpinner from "../../../components/LoaderSpinner";
import JSONEditorReact from "../../../components/simple/JSONEditor/JSONEditor";

import "./QuestionAdminDetail.css";

export default class QuestionAdminDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      question: null,
      deleteModal: false
    };
  }

  componentDidMount() {
    ajax.get([config.ADMIN_QUESTIONS_DETAIL, this.props.match.params.id])
      .then((response) => {
        this.setState({question: response.data});
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  saveQuestion = () => {
    ajax.patch([config.ADMIN_QUESTIONS_DETAIL, this.props.match.params.id], {data: this.state.question})
      .then((response) => {
        this.setState({question: response.data},
          () => toastr.success("Success!", "Question saved successfuly"));
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  deleteQuestion = () => {
    ajax.delete([config.ADMIN_QUESTIONS_DETAIL, this.props.match.params.id])
      .then(() => {
        this.props.history.push(
          urls.ADMIN_QUESTIONS_BY_TASK_LIST.replace(":taskSlug", this.state.question.task));
        toastr.success("Success!", "Question deleted successfuly");
      }).catch((errors) => {
        toastr.error("Error!", _.get(errors, "response.statusText", "Something went wrong!"));
      });
  }

  manageDeleteModal = () => this.setState({deleteModal: !this.state.deleteModal})

  render() {
    let question = this.state.question;
    if (!question) {
      return <LoaderSpinner/>;
    }
    return (
      <Container className={"QuestionDetailAdmin"}>
        <Button basic color="black"
          onClick={this.props.history.goBack}
          icon={"arrow left"}
          content={"Back"}/>
        <Header>
          {question.task} #{question.id}
        </Header>
        <JSONEditorReact
          value={{input: question.input, evaluation_input: question.evaluation_input}}
          mode={"tree"}
          open
          onChange={(data) => this.setState({question: {...question, ...data}})}/>
        <QuestionAnswerTable answers={question.answers}/>
        <br/>
        <br/>
        <br/>
        <BottomBar
          panelRight={
            <Button color="green"
              onClick={this.saveQuestion}
              content="Save"
              size="large"/>}
          panelLeft={
            <Button color="red"
              onClick={this.manageDeleteModal}
              content="Delete"
              size="large"/>
          }/>
        {this.state.deleteModal &&
          <ModalConfirm
            title="Are you sure you want to delete this question with all its answers?"
            closeModal={this.manageDeleteModal}
            button={<>
              <Button onClick={this.deleteQuestion} content="Delete" size="large" color="red"/>
              <Button onClick={this.manageDeleteModal} content="Cancel" size="large"/>
            </>}/>}
      </Container>
    );
  }
}
