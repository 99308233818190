import _ from "lodash";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FormErrors from "../FormErrors";
import { autoLoginSaveRoute, passwordFactorLogin } from "../../actions/actions_auth";
import urls from "../../config/frontend_urls";
import store from "../../store/store";
import { AuthView } from "./AuthView";
import ShowPasswordButton from "./ShowPasswordButton";

import "./Login.css";

export class PasswordFactorLogin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,

    }),
    login: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ])
  };

  constructor(props) {
    super(props);
    // Redirect for autologin, info from PrivateRoute where we were before login
    const auto_login_path = _.get(props.location.state, "auto_login_path");
    this.state = {
      username: "",
      password: "",
      visPass: false,
      auto_login_path: auto_login_path
    };
    store.dispatch(autoLoginSaveRoute(auto_login_path));
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const clearPassword = () => this.setState({ password: "" });
    this.props.login(this.props.history, this.state.username, this.state.password,
      this.props.match.params.token, clearPassword);
  };

  renderAutoLogin() {
    const autoLoginError = !_.isEmpty(this.props.errors) ? (
      <div className={"row"}>
        <Message
          className="datahive-message"
          error={true}
          header=""
          content={this.props.errors}
        />
      </div>
    ) : "";
    return (
      <React.Fragment>
        <div className={"ui three column center aligned grid Login"}>
          <Message
            className="datahive-message"
            warning={false}
            header="Autologin"
            content="Please wait………"
          />
          {autoLoginError}
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.auto_login_path) {
      return this.renderAutoLogin();
    }

    return (
      <AuthView>
        <Form onSubmit={this.handleSubmit} widths="equal">
          <Message
            className="datahive-message"
            warning={false}
            header="Information"
            content="Please provide your credentials."
          />
          <FormErrors />
          <Form.Input fluid
            type={"text"}
            id={"username"}
            label="Username"
            placeholder="Username"
            onChange={this.handleChange}
          />
          <Form.Input fluid
            type={this.state.visPass ? "text" : "password"}
            id={"password"}
            label="Password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
            action={<ShowPasswordButton showFunc={(val) => this.setState({ visPass: val })} />}
          />
          <br />
          <div className={"button-row"}>
            <Link
              className={"reset-btn datahive-a"}
              to={urls.PASSWORD_RESET}
            >
              Forgot password?
            </Link>
            <Link
              className={"reset-btn datahive-a"}
              to={urls.CREATE_ACCOUNT}
            >
              Create account
            </Link>
            <Form.Button
              className="login-btn datahive-button"
              id={"login"}
              disabled={!this.validateForm()}
              color="yellow"
            >
              Login
            </Form.Button>
          </div>
        </Form>
      </AuthView>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors };
}

export default withRouter(connect(mapStateToProps, { login: passwordFactorLogin })(PasswordFactorLogin));
