import _ from "lodash";
import React, {Component} from "react";
import {Button, Input} from "semantic-ui-react";
import PropTypes from "prop-types";


class NewNodeInput extends Component {
  static propTypes = {
    handleAddClick: PropTypes.func.isRequired,
    nodeValue: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      newNode: {
        title: "",
        value: "",
      }
    };
  }

  handleNewNodeChange = (e) => {
    this.setState({
      newNode: {
        title: e.target.value,
        value: e.target.value,
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleAddClick(_.cloneDeep(this.state.newNode), this.props.nodeValue);
    this.setState({newNode: {title: "", value: ""}});
  };

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps) || JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  render() {
    return (
      <React.Fragment>
        <Input
          size="mini" value={this.state.newNode.title} onChange={this.handleNewNodeChange}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.handleSubmit(e);
            }
          }}
        />
        <Button
          content={"add"} size="tiny" icon={"plus"} compact
          onClick={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

export default NewNodeInput;
