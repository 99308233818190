import _ from "lodash";
import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback} from "react";
import {Group, Text} from "react-konva/lib/ReactKonvaCore";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import Vertex from "./Vertex";
import Edge from "../shared/Edge";
import Label from "../shared/Label";
import {createPoint, canSnap, check2LinesIntersection, checkPointToCloseToLine, checkOutOfBounds} from "../util/math";

const validatePolygon = (vertices, canvasSize, isDone) => {
  const errors = [];
  const lines = vertices.reduce((acc, vertex, i) => {
    if (i === vertices.length - 1) {
      if (isDone) {
        return acc.concat([[vertex, vertices[0]]]);
      }
      return acc;
    }
    const nextVertex = vertices[i + 1];
    return acc.concat([[vertex, nextVertex]]);
  }, []);

  if (canSnap(vertices[0], vertices[1])) {
    errors.push([
      "Distance Error!",
      "Points to close"
    ]);
  }
  for (let i = 0, len = vertices.length; i < len; i++) {
    if (checkOutOfBounds(vertices[i], canvasSize)) {
      errors.push([
        "Out of bounds!",
        `Vertex ${i+1} is out of bounds`
      ]);
    }
    for (let j = 0; j < lines.length; j++) {
      if (i !== j && i <= j && check2LinesIntersection(lines[i][0], lines[i][1], lines[j][0], lines[j][1])) {
        errors.push([
          "Intersection Error!",
          `Line ${i+1} and ${j+1} intersect`
        ]);
      }
      if ((vertices[i].key !== lines[j][0].key && vertices[i].key !== lines[j][1].key) && //skips points attached to checked line
        checkPointToCloseToLine(vertices[i], lines[j])) {
        errors.push([
          "Distance Error!",
          `${isDone ? `Vertex ${i+1}` : "New Vertex"} and line ${j+1} are too close`
        ]);
      }
    }
  }
  return errors;
};

const Segment = forwardRef(({
  x, y,
  type,
  absoluteVertices,
  idx,
  isDone,
  isEdit,
  isDisabled,
  mousePosition,
  canvasSize,
  imageRef,
  onSegmentDone,
  onSegmentSelection,
  onDragEnd
}, ref) => {
  const [vertices, setVertices] = useState(
    isDone
      ? absoluteVertices.map(({x, y}) => createPoint(x, y))
      : [createPoint(x, y)]
  );

  const leftVertices = type.vertices ? type.vertices - vertices.length : null;

  const handleVertexDragMove = (e, i) => {
    const newVertices = _.cloneDeep(vertices);
    newVertices[i].x = e.target.x();
    newVertices[i].y = e.target.y();
    setVertices(newVertices);
  };

  const segmentRef = useRef();

  const handleGetAbsoluteVertices = useCallback(() =>
    segmentRef.current.children
      .slice(vertices.length, -1)
      .map((vertex) => vertex.getAbsolutePosition())
  , [segmentRef, vertices]);

  const [copiedVertex, setCopiedVertex] = useState();
  const [copiedPosition, setCopiedPosition] = useState();

  const handleVertexDrop = (i) => {
    const newVertices = handleGetAbsoluteVertices();
    const errors = validatePolygon(newVertices, canvasSize, true);
    if (errors.length) {
      const fakeEvent = {
        target: {
          x: () => copiedVertex.x,
          y: () => copiedVertex.y
        }
      };
      handleVertexDragMove(fakeEvent, i);
      errors.forEach((err) => toastr.error(err[0], err[1]));
    }
  };

  const handlePolygonDrop = (e) => {
    const newVertices = handleGetAbsoluteVertices();
    const errors = validatePolygon(newVertices, canvasSize, true);
    if (errors.length) {
      errors.forEach((err) => toastr.error(err[0], err[1]));
      e.target.position(copiedPosition);
      onDragEnd(handleGetAbsoluteVertices());
    } else {
      onDragEnd(newVertices);
    }
  };

  const handleCursorChange = useCallback((cursorStyle) =>
    imageRef.current && (imageRef.current.style.cursor = cursorStyle)
  , [imageRef]);

  const vertexElements = [];
  const edgeElements = [];
  vertices.forEach((vertex, i) => {
    vertexElements.push(
      <Vertex
        {...vertex}
        color={type.color}
        isEdit={isEdit}
        onCursorChange={handleCursorChange}
        onDragStart={() => setCopiedVertex({...vertex})}
        onDragMove={(e) => handleVertexDragMove(e, i)}
        onDragEnd={() => handleVertexDrop(i)}
        isSettled={isEdit
          ? false
          : (i !== 0 && !isDone) || isDone
        }/>
    );

    const points = [vertex.x, vertex.y];
    if (i === vertices.length - 1) {
      if (isDone) {
        const firstVertex = vertices[0];
        return edgeElements.push(
          <Edge
            key={vertex.key + "," + vertices[0].key}
            color={type.color}
            points={points.concat([firstVertex.x, firstVertex.y])}/>
        );
      }
      return edgeElements.push(
        <Edge
          key={vertex.key + ",last"}
          color={type.color}
          points={
            (leftVertices === 0 || (leftVertices === null && vertices.length > 2))
            &&
            canSnap(mousePosition, vertices[0])
              ? points.concat([x, y])
              : points.concat([mousePosition.x, mousePosition.y])
          }/>
      );
    }
    const nextVertex = vertices[i + 1];
    edgeElements.push(
      <Edge
        key={vertex.key + "," + nextVertex.key}
        color={type.color}
        points={points.concat([nextVertex.x, nextVertex.y])}/>
    );
  });


  useEffect(() => {
    let node = imageRef.current;
    const handleAddNextPoint = (e) => {
      const snap = canSnap({x: e.offsetX, y: e.offsetY}, vertices[0]);
      let errors;
      if (snap && ((leftVertices === null && vertices.length > 2) || leftVertices === 0)) {
        errors = validatePolygon(vertices, canvasSize, true);
      } else {
        errors = validatePolygon(vertices.concat(createPoint(e.offsetX, e.offsetY)), canvasSize);
      }
      if (errors.length) {
        return errors.forEach((err) => toastr.error(err[0], err[1]));
      }

      if (leftVertices === null) { //Infinite vertices polygon
        if (vertices.length > 2 && snap) {
          onSegmentDone(handleGetAbsoluteVertices());
          node.removeEventListener("click", handleAddNextPoint);
        } else {
          setVertices(vertices.concat(createPoint(e.offsetX, e.offsetY)));
        }
      } else {
        if (leftVertices > 0) {
          setVertices(vertices.concat(createPoint(e.offsetX, e.offsetY)));
        } else if (leftVertices === 0) {
          if (snap) {
            onSegmentDone(handleGetAbsoluteVertices());
            node.removeEventListener("click", handleAddNextPoint);
          } else {
            toastr.error("You need to connect to first vertex!");
          }
        }
      }
    };
    if (!isDone) {
      node.addEventListener("click", handleAddNextPoint);
    }
    return () => {
      if (node) {
        node.removeEventListener("click", handleAddNextPoint);
      }
    };
  }, [vertices, handleGetAbsoluteVertices, leftVertices, imageRef, isDone, onSegmentDone, canvasSize]);

  useImperativeHandle(ref, () => ({
    verticesCount: vertices.length,
    getAbsoluteVertices: handleGetAbsoluteVertices,
    undo: () => setVertices(vertices.slice(0, -1))
  }));

  const [isDraggable, setIsDraggable] = useState(false);

  const cursorText =
    <Text
      text={leftVertices === null
        ? "Free draw"
        : leftVertices > 0
          ? leftVertices + "Vertices left"
          : "Close your polygon!"
      }
      fontStyle="bold"
      x={mousePosition.x + 12}
      y={mousePosition.y + 5}
    />;

  const firstVertexY = segmentRef.current?.children[vertices.length].getAbsolutePosition().y;
  const polygonLabel =
    <Label
      x={vertices[0].x}
      y={vertices[0].y}
      inverted={firstVertexY < 15}
      name={type.name}
      onCursorChange={!isDisabled && !isEdit ? handleCursorChange : () => null}
      onSetIsDraggable={!isDisabled && !isEdit ? setIsDraggable : () => null}
      onSegmentSelection={() => !isDisabled && onSegmentSelection({idx, active: false})}
      onRightClick={() => onSegmentSelection({idx, active: true})}
    />;

  return <Group
    ref={segmentRef}
    draggable={isDraggable}
    onDragStart={() => setCopiedPosition(segmentRef.current.absolutePosition())}
    onDragEnd={handlePolygonDrop}
    opacity={isDisabled ? 0.5 : 1}>
    {edgeElements}
    {vertexElements}
    {!isDone ? cursorText : polygonLabel}
  </Group>;
});

Segment.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  type: PropTypes.shape({
    name: PropTypes.string,
    vertices: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  mousePosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }).isRequired,
  imageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  absoluteVertices: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  ),
  isDone: PropTypes.bool.isRequired,
  onSegmentDone: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSegmentSelection: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  canvasSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }).isRequired
};

Segment.displayName = "Segment";

export default Segment;
