import _ from "lodash";

import React, {Component} from "react";
import {withRouter, Link} from "react-router-dom";

import config from "../../config/config";
import {Container, Modal, Button, List, Icon} from "semantic-ui-react";

import LoaderSpinner from "../../components/LoaderSpinner";
import ajax from "../../helpers/ajax";
import "./TaskRatings.css";
import urls from "../../config/frontend_urls";
import PropTypes from "prop-types";

export class TaskRatings extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    showMenu: PropTypes.func,
    history: PropTypes.object
  };

  state = {
    taskUsersList: null,
    taskRatedUsersList: [],
    taskUsersListRaw: [],
  };

  componentDidMount() {
    if (this.props.match.params.taskSlug) {
      this.getTaskUsers(this.props.match.params.taskSlug);
    }
  }

  getTaskUsers = (slug) => {
    ajax.get([config.TASK_USERS, slug])
      .then((response) => {
        this.setState({
          taskUsersList: response.data["unrated"],
          taskRatedUsersList: response.data["rated"],
          taskUsersListRaw: _.concat(response.data["unrated"], response.data["rated"])
        });
      })
      .catch(() => {
      });
  }

  renderModal = () => {
    let unrated_users = _.map(this.state.taskUsersList, (user) => {
      const url = urls.NEW_RATING.replace(":username", user.username)
        .replace(":taskSlug", this.props.match.params.taskSlug);
      return <List.Item key={user.id}>
        <List.Content className="ui button">
          <Link to={url}>
            {user.username}
          </Link>
        </List.Content>
      </List.Item>;
    });

    let rated_users = _.map(this.state.taskRatedUsersList, (user) => {
      const url = urls.RATING.replace(":ratingId", user["rating_id"])
        .replace(":taskSlug", this.props.match.params.taskSlug);
      return <List.Item key={user.id}>
        <List.Content className="ui button olive">
          <Link to={url}>
            {user.username}
          </Link>
        </List.Content>
      </List.Item>;
    });

    return (
      <Modal className="TaskRatings" open={true} size="tiny">
        <Modal.Header>
          <Icon name={"star outline"}/> User rating
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <List className={"user-list"}>
              {!this.state.taskUsersList && <LoaderSpinner/>}
              {this.state.taskUsersList && unrated_users}
            </List>
            <List className={"user-list"}>
              {this.state.taskRatedUsersList && rated_users}
            </List>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={
              this.props.history.length > 2
                ? this.props.history.goBack
                : () => this.props.history.push(urls.ADMIN_TASK_LIST)
            }
            color="green">
              Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    return (
      <Container className="TaskRatings">
        {this.props.match.params.taskSlug && this.renderModal()}
      </Container>
    );
  }
}

export default withRouter(TaskRatings);
