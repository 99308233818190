import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

import NewTicketForm from "./NewTicketForm";
import BottomBar from "../../../components/BottomBar";
import config from "../../../config/config";
import ajax from "../../../helpers/ajax";
import urls from "../../../config/frontend_urls";
import {
  getPossibleDropdownValues,
  formatErrorMessage,
} from "../../../helpers/utils";

import "./NewTicket.css";

const defaultFormData = {
  title: "",
  category: "",
  priority: "",
  description: "",
  attachments: [],
};

class NewTicket extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      form: defaultFormData,
      displayModal: false,
      priorities: [],
      categories: [],
      attachments: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    ajax.get(config.TICKET_OPTIONS).then((response) => {
      const categoriesData = response.data.categories;
      const prioritiesData = response.data.priorities;

      const categories = getPossibleDropdownValues(
        "value",
        "name",
        categoriesData,
        true,
        false
      );
      const priorities = getPossibleDropdownValues(
        "value",
        "name",
        prioritiesData,
        true,
        false
      );

      this.setState({ categories, priorities });
    });
  };

  addAttachment = (file) => {
    this.setState((prevState) => ({
      attachments: [...prevState.attachments, file],
    }));
  };

  removeAttachment = (name) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter(
        (attachment) => attachment.name !== name
      ),
    }));
  };

  sendNewTicket = () => {
    const form = this.state.form;

    const data = new FormData();

    for (const key in form) {
      data.append(key, form[key]);
    }

    for (const attachment in this.state.attachments) {
      data.append("attachments", this.state.attachments[attachment]);
    }

    ajax
      .post([config.TICKETS], { data })
      .then((response) => {
        if (response.status === 201) {
          this.props.history.push(
            urls.SUPPORT_TICKET_DETAIL.replace(":id", response.data.id)
          );
          toastr.success("Success", "You just sent a new ticket!");
        }
      })
      .catch((error) => {
        toastr.error("Error", formatErrorMessage(error.response.data));
      });
  };

  onChangeForm = (obj) => {
    const form = this.state.form;
    return this.setState({ form: { ...form, ...obj } });
  };

  render() {
    return (
      <div className="new-ticket">
        <NewTicketForm
          categories={this.state.categories}
          priorities={this.state.priorities}
          attachments={this.state.attachments}
          addAttachment={this.addAttachment}
          removeAttachment={this.removeAttachment}
          onChangeForm={this.onChangeForm}
        />
        <BottomBar
          panelRight={
            <Button
              content="Send"
              className="datahive-button"
              color="green"
              onClick={this.sendNewTicket}
            />
          }
        />
      </div>
    );
  }
}

export default NewTicket;
