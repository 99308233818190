import React, {Component} from "react";

import {Card, Statistic} from "semantic-ui-react";
import {formatSeconds} from "../../helpers/utils";
import PropTypes from "prop-types";

export default class TaskUserHeadersTable extends Component {
  static propTypes = {
    type: PropTypes.string,
    data: PropTypes.shape({
      stats: PropTypes.shape({
        total_answers: PropTypes.number,
        median: PropTypes.number,
        mean: PropTypes.number,
        total_time: PropTypes.number
      }),
      sessions: PropTypes.array,
      std: PropTypes.number,
      median: PropTypes.number,
      mean: PropTypes.number,
      total_time: PropTypes.number,
      total_answers: PropTypes.number,
    }),
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderStatistic(value, label, color) {
    return (
      <Statistic color={color}>
        <Statistic.Value>{value}</Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>);
  }

  render() {
    let first = "";
    let second = "";
    let third = "";
    let fourth = "";
    let fifth = "";
    if (this.props.type === "Table") {
      if ((this.props.data.stats !== null) && (this.props.data.sessions !== null)) {
        first = this.renderStatistic(this.props.data.stats.total_answers, "Answers", "yellow");
        second = this.renderStatistic(this.props.data.sessions.length, "Session", "grey");
        third = this.renderStatistic(formatSeconds(this.props.data.stats.median), "Median", "teal");
        fourth = this.renderStatistic(formatSeconds(this.props.data.stats.mean), "Mean", "blue");
        fifth = this.renderStatistic(formatSeconds(this.props.data.stats.total_time), "Total Time", "red");
      } else {
        first = this.renderStatistic(0, "Answers", "yellow");
        second = this.renderStatistic(0, "Session", "grey");
        third = this.renderStatistic(0, "Median", "teal");
        fourth = this.renderStatistic(0, "Mean", "blue");
        fifth = this.renderStatistic(0, "Total Time", "red");
      }
    } else {
      first = this.renderStatistic(this.props.data.total_answers, "Answers", "yellow");
      second = this.renderStatistic(formatSeconds(this.props.data.std), "STD", "pink");
      third = this.renderStatistic(formatSeconds(this.props.data.median), "Median", "teal");
      fourth = this.renderStatistic(formatSeconds(this.props.data.mean), "Mean", "blue");
      fifth = this.renderStatistic(formatSeconds(this.props.data.total_time), "Total Time", "red");
    }

    return (
      <Card.Content>
        <Statistic.Group widths="five" size="tiny">
          {first}
          {second}
          {third}
          {fourth}
          {fifth}
        </Statistic.Group>
      </Card.Content>
    );
  }
}
