import React, {Component} from "react";
import {Button, Icon, Input} from "semantic-ui-react";
import PropTypes from "prop-types";

import "./PaginationCompact.css";

export default class PaginationCompact extends Component {
  static propTypes = {
    activePage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      page: props.activePage,
      displayFromState: false
    };
  }

  onChange = (event, data) => {
    let page = "";
    if (data.value.length !== 0) {
      page = parseInt(data.value);

      if (page < 1) {
        page = 1;
      }
    }
    this.setState({page, displayFromState: true});
  };

  onKeyPress = (event) => {
    if (event.key === "Enter") {
      this.setState({displayFromState: false});
      this.props.onPageChange(this.state.page);
    }
  };

  onPageChange = (page) => {
    this.props.onPageChange(page);
  };

  render() {
    const activePage = this.props.activePage || 1;
    const totalPage = this.props.totalPages || 1;

    // flags if prev and next should be displayed
    const prevClass = activePage === 1 ? "prev-next hidden" : "prev-next";
    const nextClass = activePage === totalPage ? "prev-next hidden" : "prev-next";

    return (
      <div className="PaginationCompact">
        <Button
          className={prevClass}
          onClick={() => this.onPageChange(activePage - 1)}
        >
          <Icon name="chevron circle left" size="large" color="black"/>
        </Button>
        <Input
          type="number"
          value={this.state.displayFromState ? this.state.page : this.props.activePage}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          ref={this.inputRef}
        />
        <Button
          icon
          onClick={() => this.onPageChange(totalPage)}
          labelPosition="left"
          className="last-page"
        >
          <Icon name="angle double right"/>
          <span>{totalPage}</span>
        </Button>
        <Button
          className={nextClass}
          onClick={() => this.onPageChange(activePage + 1)}
        >
          <Icon name="chevron circle right" size="large" color="black"/>
        </Button>
      </div>
    );
  }
}


