import React from "react";
import {Checkbox, Grid, Header} from "semantic-ui-react";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class AudioAnswerWidgetEditor extends WidgetEditor {
  static header = "Audio Answer";

  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }


  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    return (
      <Grid className={"RadioButtonWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <Header
              className="row"
              as="h4">Show Player</Header>
            <Checkbox
              className="row"
              toggle
              checked={config.showPlayer}
              onChange={(e, data) => this.updateConfig("showPlayer", data.checked)}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row/> {/* Used as spacer. */}
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default AudioAnswerWidgetEditor;
