import _ from "lodash";

import React, {Component} from "react";
import {Icon, Table} from "semantic-ui-react";


export default class BasicGroupTasksStatistics extends Component {

  renderTaskRows() {
    let rows = [];
    if (!!this.props) {
      rows = _.map(this.props, (task, idx) => {
        return (
          <Table.Row key={"Task" + idx}>
            <Table.Cell>
              <Icon name="tasks"/>{task.title}
            </Table.Cell>
            <Table.Cell>
              <Icon name={task.is_active ? "check circle" : "times"} />
            </Table.Cell>
            <Table.Cell>{task.questions}</Table.Cell>
            <Table.Cell>{task.max_answers}</Table.Cell>
            <Table.Cell>{task.done_questions}</Table.Cell>
            <Table.Cell>{task.done_answers}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }


  render() {
    const rows = this.renderTaskRows();
    return (
      <Table selectable celled structured textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Task</Table.HeaderCell>
            <Table.HeaderCell>Is active</Table.HeaderCell>
            <Table.HeaderCell>Questions</Table.HeaderCell>
            <Table.HeaderCell>Max answers</Table.HeaderCell>
            <Table.HeaderCell>Done question</Table.HeaderCell>
            <Table.HeaderCell>Done answers</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    );
  }
}
