import _ from "lodash";

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Popup} from "semantic-ui-react";
import "./SemanticColorSelector.css";

export class SemanticColorSelector extends Component {
  /*
  This component displays stars based on the number it gets in the props.
   */
  static propTypes = {
    /* On change handler passed to the input*/
    onChange: PropTypes.func.isRequired,
    /* Value passed to selector */
    value: PropTypes.string,
    size: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {display: false};

    this.opts = ["red", "orange", "yellow", "olive", "green", "teal", "blue", "violet", "purple", "pink", "brown", "grey", "black"];

    this.toggleDisplay = this.toggleDisplay.bind(this);
  }

  toggleDisplay() {
    const state = {...this.state};
    state.display = !state.display;
    this.setState(state);
  }

  render() {
    const size = this.props.size ? this.props.size : "";

    // eslint-disable-next-line
    let buttons = _.map(this.opts, (color, idx) => {
      return (
        <Button
          icon=""
          className={`SemanticColorSelectorButton ${size}`}
          key={color}
          color={color}
          onClick={(e) => this.props.onChange(e, color)}
        />
      );
    });

    return (
      <Popup
        flowing hoverable position={"top center"}
        className="SemanticColorSelector"
        trigger={<Button className={`SemanticColorSelectorButton ${size}`} key="selected" color={this.props.value}/>}
      >
        {buttons}
      </Popup>
    );
  }
}

