/* eslint-disable no-undef */
// file: src/store.js
import {createStore, applyMiddleware} from "redux";
import {createLogger} from "redux-logger";
import thunkMiddleware from "redux-thunk";

import rootReducer from "../reducers/reducers";

let loggerMiddleware;

if (process.env.NODE_ENV === "test") {
  // Disable some logs from redux-loggers middleware
  loggerMiddleware = createLogger({
    logger: undefined, // implementation of the `console` API.
  });
} else {
  loggerMiddleware = createLogger({
    predicate: true, // if specified this function will be called before each action is processed with this middleware.
    collapsed: true, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
    duration: true, // print the duration of each action?
    timestamp: true, // print the timestamp with each action?
    level: "info", // console's level 'log' | 'console' | 'warn' | 'error' | 'info'
    logger: console, // implementation of the `console` API.
    logErrors: false, // should the logger catch, log, and re-throw errors?
    diff: false, // (alpha) show diff between states?
    diffPredicate: false // (alpha) filter function for showing states diff, similar to `predicate`
  });
}

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware //must be last middleware
  )
);

export default store;
