import React, {Component} from "react";
import {Button, Table, Header} from "semantic-ui-react";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import ModalConfirm from "../../components/ModalConfirm";
import config from "../../config/config";
import {renderLanguage} from "../../helpers/utils";


class AddManyUsersAdditionalInfo extends Component {
  static propTypes = {
    addUsers: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    inactiveUsers: PropTypes.object,
    existingUsers: PropTypes.object,
  };

  getRejectButton = (user) => {
    return (
      <Button
        size="tiny"
        className="datahive-button"
        color="red"
        onClick={() => this.props.onReject(user)}
        content="Reject"/>);
  };

  render() {
    const button = <>
      <Button
        onClick={this.props.addUsers}
        color="yellow"
        className="datahive-button"
      >
        Save Users
      </Button>
      <Button
        onClick={this.props.closeModal}
        floated={"left"}
        color="dark-grey"
        className="datahive-button"
      >
        Cancel
      </Button>
    </>;

    const {inactiveUsers, existingUsers} = this.props;

    const contentExistingUsers = <Table className="SortableTable">
      <Table.Header className={"header"} fullWidth>
        <Table.Row disabled={true}>
          <Table.HeaderCell width={3}>User</Table.HeaderCell>
          <Table.HeaderCell width={3}>Task</Table.HeaderCell>
          <Table.HeaderCell width={2}>Deadline</Table.HeaderCell>
          <Table.HeaderCell width={1}>Limit</Table.HeaderCell>
          <Table.HeaderCell width={1}>Done</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {existingUsers.map((item) => <Table.Row key={item.username}>
          <Table.Cell>{item.username}</Table.Cell>
          <Table.Cell>{item.task}</Table.Cell>
          <Table.Cell>{item.deadline ?
            moment(item.deadline).format(config.FORMAT_Ymd_Hm) : "-"}</Table.Cell>
          <Table.Cell>{item.limit ? item.limit : "-"}</Table.Cell>
          <Table.Cell>{item.answered}</Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>;

    const modalContent = <>
      {!!inactiveUsers.length && <Header as="h4" textAlign="center">Existing inactive accounts:</Header>}
      {!!inactiveUsers.length && <>
        <Table className="SortableTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>User</Table.HeaderCell>
              <Table.HeaderCell width={3}>Last login</Table.HeaderCell>
              <Table.HeaderCell width={3}>Languages</Table.HeaderCell>
              <Table.HeaderCell width={1} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inactiveUsers.map((item) => <Table.Row key={item.username}>
              <Table.Cell>{item.username}</Table.Cell>
              <Table.Cell>{item.last_login ? moment(item.last_login).format(config.FORMAT_Ymd_Hm) : "-"}</Table.Cell>
              <Table.Cell>{renderLanguage(item)}</Table.Cell>
              <Table.Cell>{this.getRejectButton(item)}</Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table></>}
      {!!existingUsers.length && <>
        <Header as="h4" textAlign="center">Existing UserTask:</Header>
        {contentExistingUsers}</>}
    </>;

    const title = "Additional information:";

    return <ModalConfirm
      title={title}
      closeModal={this.props.closeModal}
      content={modalContent}
      button={button}
    />;
  }
}

export default AddManyUsersAdditionalInfo;
