import {useEffect} from "react";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {toastr} from "react-redux-toastr";
import urls from "../../config/frontend_urls";
import PropTypes from "prop-types";


const SecondFactorReset = ({history, location}) => {
  useEffect(() => {
    const url = location.pathname;
    const token = url.substr(url.lastIndexOf("/") + 1);
    ajax.post(config.RESET_2FA_WITH_TOKEN, {data: {token}})
      .then(() => {
        toastr.success("Success", "Your second authentication step has been reset successfully.");
        history.push(urls.LOGIN);
      })
      .catch(() => {
        toastr.error("Error", "It appears your reset token is invalid. Perhaps you have " +
          "waited too long to reset your OTP configuration or the link has been used before. " +
          "If that is not the case, please contact Langtasks administrators.");
        history.push(urls.LOGIN);
      });
  }, [history, location.pathname]);
  return null;
};

SecondFactorReset.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default SecondFactorReset;
