import React from "react";
import {Checkbox, Grid, Header, Input} from "semantic-ui-react";
import {AlignSelector} from "../components";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./MediaPlayerWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";

class MediaPlayerWidgetEditor extends WidgetEditor {
  static header = "Media Player";

  updateAlign(e, data) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.align = data;
      widgetConfig.justify = data === "justify";
    });

    this.setState({}); // force re-render
  }

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const width = config.width || null;
    const height = config.height || null;

    return (
      <Grid className={"MediaPlayerWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header as="h4">Width</Header>
            <Input
              width={12} size="mini"
              placeholder="Value"
              type="number"
              value={width}
              onChange={(e) => this.updateConfig("width", e.target.value)}
            />
            <Header as="h4">Height</Header>
            <Input
              width={12} size="mini"
              placeholder="Value"
              type="number"
              value={height}
              onChange={(e) => this.updateConfig("height", e.target.value)}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header as="h4">Audio only</Header>
            <Checkbox
              toggle
              checked={config.audio_only || false}
              onChange={(e, data) => this.updateConfig("audio_only", data.checked)}
            />
            <Header as="h4">Align</Header>
            <AlignSelector
              justify
              size="mini"
              value={config.align}
              onChange={(e, data) => this.updateConfig("align", data)}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        {this.renderPreview()}
      </Grid>
    );
  }
}

export default MediaPlayerWidgetEditor;
