import _ from "lodash";
import React from "react";
import {iterateAllWidgets} from "../../components/Widgets/widgetUtils";

import "./QuestionsSet.css";
import EvaluationContainer from "./EvaluationContainer";
import QuestionsSet from "./QuestionsSet";


export default class EvaluationsSet extends QuestionsSet {

  onSubmit = (question_idx, widgetVals, answer) => {
    this.props.onSubmit(question_idx, widgetVals, answer);
  };

  renderQuestions = () => {
    const config = this.props.config;
    // remove popup content from all widgets
    iterateAllWidgets(config.structure, (w) => {
      if (w.popupContent) {
        delete w.popupContent;
      }
    });

    return _.map(this.props.questions, (question, questionIdx) => {
      const questionId = question.id;

      if (!("answers" in question)) {
        question.answers = [];
      }

      return (
        <EvaluationContainer
          key={"question" + questionId}
          idx={questionIdx}
          question={question}
          onSubmit={this.onSubmit}
          onSkip={this.props.onSkip ? this.onSkip : null}
          onFixAnswer={this.props.onFixAnswer}
          config={this.props.config}
          config_evaluation={this.props.config_evaluation}
          showReview={this.props.showReview}
          editReview={this.props.editReview}
          onReviewSubmit={this.props.onReviewSubmit}
          onReviewAccept={this.props.onReviewAccept}
        />
      );
    });
  };

  render() {
    return (
      <div className="QuestionsSet">
        {this.renderQuestions()}
      </div>
    );
  }
}
