import _ from "lodash";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Grid, Tab} from "semantic-ui-react";

import config from "../../config/config";
import WidgetDnD from "./components/WidgetDnD";
import Toolbar from "./components/Toolbar";
import {getParentWidget, getWidget, iterateAllWidgets, removeWidget, } from "../../components/Widgets/widgetUtils";

import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import {HIDE_MENU, SHOW_MENU} from "../../actions/types/types_top_menu";
import Accordion from "../../components/simple/Accordion/Accordion";
import "./WidgetsEdit.css";
import ajax from "../../helpers/ajax";
import widgetsMapping from "../../components/Widgets/WidgetsMapping";
import ContainerWidget from "../../components/Widgets/ContainerWidget/ContainerWidget";
import PropTypes from "prop-types";


export class WidgetsEdit extends Component {
  // this component is used for testing ideas for the widget config. in future for the task config creation.
  static propTypes = {
    hideMenu: PropTypes.func.isRequired,
    showMenu: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      popupDisplayId: null,
      widgetConfig: {
        structure: []
      },
      widgetVals: {},
      taskId: null,
      height: null,

      // current scroll position
      top: null,
      // scroll position saved when popup opened
      savedTop: null,
      // list of componentID's to copy
      selectedWidgets: [],
    };

    this.updateWidgets = this.updateWidgets.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.updateVals = this.updateVals.bind(this);
    this.updateId = this.updateId.bind(this);
    this.getWidgetVals = this.getWidgetVals.bind(this);
    this.getWidgetStructure = this.getWidgetStructure.bind(this);
    this.getDefaultConfig = this.getDefaultConfig.bind(this);
    this.renderEdit = this.renderEdit.bind(this);
    this.renderPreview = this.renderPreview.bind(this);

    this.rootId = _.uniqueId();
    this.submitId = "submit-button";
    /* Development */
    // addWidgetOnInit(this, ["SelectWidget"]);

    const BACKEND_URL = window.BACKEND_URL || config.BACKEND_URL;
    this.acceptedSource = BACKEND_URL.split("/").slice(0, 3).join("/");
  }

  componentDidMount() {
    this.props.hideMenu();

    // listen to the messages from django admin
    window.addEventListener("message", this.handleMessage, false);

    // ask for task id
    window.parent.postMessage({command: "getId"}, this.acceptedSource);

    this.createSubmit();
    this.handleResize();
  }

  componentWillUnmount() {
    this.props.showMenu();
  }

  /**
   * Automatically resize window size. Pure magic function!
   */
  handleResize = () => {
    const div = document.getElementsByClassName("WidgetsEdit")[0];
    if (div) {
      const height = div.clientHeight;
      if (height !== this.state.height) {
        document.body.style.height = height;
        // send window height to resize whole page
        window.parent.postMessage({command: "resize", height}, this.acceptedSource);
        this.setState({height});
      }
    }

    setTimeout(() => {
      this.handleResize();
    }, 200);
  };

  /**
   * Handle messages from Django.
   * @param e
   */
  handleMessage = (e) => {
    if (e.origin === this.acceptedSource && e.data.command) {
      switch (e.data.command) {
      case "id":
        this.onGetId(e);
        break;
      case "submit":
        this.onSubmit(e);
        break;
      case "scroll":
        this.setState({top: e.data.top});
        break;
      default:
        break;
      }
    }
  };


  onGetId = (e) => {
    const taskId = e.data.id;
    this.setState({taskId});
    ajax.get([config.TASK_CONFIGURATION, taskId])
      .then((response) => {
        if (response.data.structure) {
          // update config with response data
          this.updateConfig(response.data.structure);
        }
      });
  };

  // send config to API and send message to django admin
  onSubmit = (e) => {
    const data = this.state.widgetConfig;
    ajax.post([config.TASK_CONFIGURATION, this.state.taskId], {data})
      .then(() => {
        e.source.postMessage({command: "submitted"}, this.acceptedSource);
      });
  };

  updateWidgets(widgetVals) {
    this.setState({widgetVals: widgetVals});
  }

  openPopup(e, widgetId) {
    const widget = getWidget(this.state.widgetConfig.structure, this.state.popupDisplayId);
    if (this.state.popupDisplayId !== null && this.state.popupDisplayId !== widgetId && widget) {
      return;
    }

    // close popup
    if (this.state.popupDisplayId === widgetId) {
      window.parent.postMessage({command: "scroll", top: this.state.savedTop}, this.acceptedSource);
    } else {
      // open popup
      this.setState({savedTop: this.state.top});
      window.parent.postMessage({command: "scroll", top: 0}, this.acceptedSource);
    }

    this.setState({popupDisplayId: this.state.popupDisplayId === widgetId ? null : widgetId});
  }

  getWidgetStructure() {
    return this.state.widgetConfig.structure;
  }

  getWidgetVals() {
    return this.state.widgetVals;
  }

  updateConfig(structure, callback = null) {
    this.setState({
      widgetConfig: {
        ...this.state.widgetConfig,
        structure
      }
    }, callback);
  }

  updateVals(val, id) {
    this.setState({
      widgetVals: {
        ...this.state.widgetVals,
        [id]: val
      }
    });
  }

  updateValsObject = (id, valueObject) => {
    this.setState({
      widgetVals: {
        ...this.state.widgetVals,
        [id]: valueObject
      }
    });
  };

  updateId(oldId, newId) {
    // change widget config
    const widgetConfig = _.cloneDeep(this.state.widgetConfig);
    const widget = getWidget(widgetConfig.structure, oldId);
    iterateAllWidgets(widgetConfig.structure, (w) => {
      if (_.get(w, "displayRule.data.targetId", null) === oldId) {
        w.displayRule.data.targetId = newId;
      }
    });
    const popupDisplayId = newId;
    widget.componentId = newId;

    // change widget values
    const widgetVals = _.cloneDeep(this.state.widgetVals);
    widgetVals[newId] = widgetVals[oldId];
    delete widgetVals[oldId];

    this.setState({widgetConfig, popupDisplayId, widgetVals});
  }

  // NOTE: In this function set only config for new widget.
  // If you need setState for change widgetVals do this in getEditor method.
  getDefaultConfig(component, id) {
    const props = {
      componentType: component,
      componentId: id,
    };

    let additionalProps = {};
    // special treating for ContainerWidget (cyclic imports issue)
    if (component === "ContainerWidget") {
      additionalProps = ContainerWidget.getDefaultConfig();
    } else {
      additionalProps = widgetsMapping[component].getDefaultConfig();
    }

    return {
      ...props,
      ...additionalProps,
    };
  }

  handleDrop(dropId, e) {
    const create = e.dataTransfer.getData("create");

    if (create) {
      this.handleDropCreate(dropId, e);
    }
  }

  handleDropCreate(dropId, e) {
    e.stopPropagation();

    const component = e.dataTransfer.getData("type"),
      widgetConfig = _.cloneDeep(this.state.widgetConfig);

    // find unique id for new component
    let id = _.uniqueId();
    while (getWidget(this.state.widgetConfig.structure, id)) {
      id = _.uniqueId();
    }

    const newWidget = this.getDefaultConfig(component, id);

    if (dropId === "rootWidget") {
      widgetConfig.structure.splice(widgetConfig.structure.length - 1, 0, newWidget);
    } else {
      iterateAllWidgets(widgetConfig.structure, (w) => {
        if (w.componentId === dropId) {
          // there has to be a smarter way to do this
          if (w.widgetConfig) {
            if (w.widgetConfig.structure) {
              w.widgetConfig.structure.push(newWidget);
            } else {
              w.widgetConfig.structure = [newWidget];
            }
          } else {
            w.widgetConfig = {structure: [newWidget]};
          }
        }
      });
    }

    this.setState({widgetConfig});
  }

  checkIdUnique = (id) => {
    const ids = [];
    iterateAllWidgets(this.state.widgetConfig.structure, (w) => {
      ids.push(w.componentId);
    });
    const result = !_.includes(ids, id);
    return result;
  };

  createSubmit = () => {
    const widgetConfig = _.cloneDeep(this.state.widgetConfig);
    const type = "SubmitWidget";
    const id = this.submitId;

    const newWidget = this.getDefaultConfig(type, id);
    widgetConfig.structure.push(newWidget);

    this.setState({widgetConfig});
  };

  selectWidgets = (selected, componentId) => {
    const widgets = this.state.selectedWidgets;
    const index = widgets.indexOf(componentId);

    if (index > -1) {
      if (!selected) {
        widgets.splice(index, 1);
      }
    } else if (selected) {
      widgets.push(componentId);
    }

    this.setState({selectedWidgets: widgets});
  };

  getUniqueId = (widget) => {
    const id = widget.componentId;

    // Add numeral to end of new ID (to make it unique).
    let counter = 1;
    while (!this.checkIdUnique(id + counter)) {
      counter++;
    }

    widget.componentId = id + counter;
  };

  copyWidget = (componentId) => {
    // Prevent from copying submit button.
    if (componentId === "submit-button") {
      return;
    }

    // Get rootWidget object.
    const taskStructure = this.getWidgetStructure();

    let duplicate = null;
    iterateAllWidgets(taskStructure, (widget) => {
      if (widget.componentId === componentId) {
        duplicate = _.cloneDeep(widget);
      }
    });

    // Create unique ID for duplicate and it's children.
    iterateAllWidgets([duplicate], (w) => {
      this.getUniqueId(w, taskStructure);
    }
    );

    // Find parent of a widget.
    const parent = getParentWidget(taskStructure, componentId);
    // Add duplicate to rootWidget if parent is null.
    const structureToUpdate = !!parent ? parent.widgetConfig.structure : taskStructure;

    const lastWidget = structureToUpdate.pop();
    lastWidget.componentId === "submit-button" ?
      structureToUpdate.push(duplicate, lastWidget)
      : structureToUpdate.push(lastWidget, duplicate);

    const newConfig = {...this.state.widgetConfig, structure: taskStructure};
    // Force rerender.
    this.setState({widgetConfig: newConfig});
  };

  startCopy = () => {
    const widgets = this.state.selectedWidgets;
    _.forEach(widgets, (widget) => {
      this.copyWidget(widget);
    });

    this.setState({selectedWidgets: []});
  };

  deleteWidget = (componentId) => {
    // Prevent from deleting submit button.
    if (componentId === "submit-button") {
      return;
    }

    const taskStructure = this.getWidgetStructure();
    removeWidget(taskStructure, componentId);

    const newConfig = {...this.state.widgetConfig, structure: taskStructure};
    // Remove ID from selectedWidgets if is contained.
    this.selectWidgets(false, componentId);
    // Force rerender.
    this.setState({widgetConfig: newConfig});
  };

  startDelete = () => {
    const widgets = this.state.selectedWidgets;
    _.forEach(widgets, (widget) => {
      this.deleteWidget(widget);
    });

    this.setState({selectedWidgets: []});
  };

  renderPreview() {
    const widgetConfig = _.cloneDeep(this.state.widgetConfig);

    const config = (
      <React.Fragment>
        <pre>{JSON.stringify(widgetConfig, null, 2)}</pre>
        <pre>{JSON.stringify(this.state.widgetVals, null, 2)}</pre>
      </React.Fragment>
    );

    return (
      <Tab.Pane className="preview-panel">
        <Grid>
          <Grid.Row>
            <ContainerWidget
              componentId={"rootWidget"}
              widgetVals={this.state.widgetVals}
              update={this.updateVals}
              toRender={widgetConfig}
              getWidgetConfig={this.getWidgetStructure}
              questionId={"123"}
            />
          </Grid.Row>
          <Grid.Row>
            <Accordion
              title="JSON config"
              content={config}
            />
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }

  renderEdit() {
    const editorProps = {
      getWidgetConfig: this.getWidgetStructure,
      widgetVals: this.state.widgetVals,
      updateConfig: this.updateConfig,
      updateVals: this.updateVals,
      updateId: this.updateId,
      openPopup: this.openPopup,
      checkIdUnique: this.checkIdUnique,
    };

    const containerWidget = (
      <ContainerWidget
        key={this.rootId}
        editorProps={editorProps}
        componentId={"rootWidget"}
        widgetVals={this.state.widgetVals}
        update={null}
        onDrop={this.handleDrop}
        toRender={this.state.widgetConfig}
        openPopup={this.openPopup}
        popupDisplayId={this.state.popupDisplayId}
        skipDisplayRule={true}
        draggable={true}
        getWidgetConfig={this.getWidgetStructure}
        updateConfig={this.updateConfig}
        questionId={"123"}
        selectWidgets={this.selectWidgets}
        selectedWidgets={this.state.selectedWidgets}
        copyWidgets={this.startCopy}
        deleteWidgets={this.startDelete}
      />);

    const toolbar = <Toolbar/>;

    return (
      <Tab.Pane className="edit-panel">
        <Grid>
          <Grid.Row>
            <WidgetDnD
              choices={toolbar}
              selectedChange={(e) => this.handleDrop("rootWidget", e)}
              selected={[containerWidget]}
            />
          </Grid.Row>
          <Grid.Row>
            <Accordion title="JSON config"
              content={JSON.stringify(this.state.widgetConfig, null, 2)}
            />
            <Accordion title="JSON value"
              content={JSON.stringify(this.state.widgetVals, null, 2)}
            />
          </Grid.Row>
        </Grid>
      </Tab.Pane>
    );
  }

  render() {
    const panes = [
      {key: "iscd", menuItem: "Edit", render: this.renderEdit},
      {key: "iscd1", menuItem: "Preview", render: this.renderPreview},
    ];

    return (
      <Tab
        className="WidgetsEdit"
        panes={panes}
      />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    }),
    showMenu: () => dispatch({
      type: SHOW_MENU
    }),
    hideMenu: () => dispatch({
      type: HIDE_MENU
    }),
  };
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(WidgetsEdit));
