import React from "react";
import _ from "lodash";
import { Button, Form, Icon, Progress, Statistic, Table, Popup } from "semantic-ui-react";
import moment from "moment";

import TaskComponent from "./TaskComponent";

import UserTask from "./UserTask";
import UserTaskSetBase from "./UserTaskSetBase";

export default class UserTaskSet extends TaskComponent {

  constructor(props) {
    super(props);
    this.collectionName = "usertasks";
    this.renderStatistics = this.renderStatistics.bind(this);
  }

  renderStatistics() {
    const questions = this.props.totalQuestions;
    const maxAnswers = this.props.task.max_answers;
    const possibleAnswers = questions * maxAnswers;
    const assigned = this.getAssignedQuestions();
    const left = (possibleAnswers - assigned) < 0 ? 0 : possibleAnswers - assigned;
    const percent = possibleAnswers !== 0 ? ((assigned / possibleAnswers) * 100).toFixed(1) : 0;
    const daysLeft = moment(this.props.task.end_date).diff(moment(), "days");
    return (
      <div className={"statistics"}>
        <div className={"export"}>
          <Button
            className="datahive-button"
            content="Export data"
            color="green"
            onClick={this.exportData}
          />
        </div>
        <div className={"resolve"}>
          {this.state.displayUnresolvedReviewsModal && this.renderModal(this.resolveReviews)}
          <Button
            className="bottom-bar-resolve datahive-button"
            content="Resolve discussions"
            color="yellow"
            onClick={this.getUnresolvedReviews}
          />
        </div>
        <div className={"filter-button"}>
          <Popup
            on="click"
            position={"bottom center"}
            style={{ padding: 0, marginLeft: 100 }}
            trigger={
              <Button
                className="datahive-button"
                color="dark-grey"
                onClick={() => {
                  this.setState({ popupOpen: true });
                }}>
                Filter
                <Icon name="filter" />
              </Button>
            }
            content={this.renderFilter()}
            open={this.state.popupOpen}
          />
        </div>
        <span className={"reset-filters-button"} onClick={() => this.setState({ filteredOut: [] }, () => this.resetFilters())}>Reset all filters</span>
        <div>
          {!isNaN(daysLeft) &&
            <div className={"datahive-calendar"}>
              <Icon name={"calendar"} color={"grey"}>
                <div>
                  <div className={"number"}>{daysLeft}</div>
                  <div className={"days-left"}>DAYS LEFT</div>
                </div>
              </Icon>
            </div>
          }
          <div className={"progress-bar"}>
            <div className={"progress-bar-header"}>
              <Statistic size="mini" key={"questions"}>
                <Statistic.Value>{questions}</Statistic.Value>
                <Statistic.Label>QUESTIONS</Statistic.Label>
              </Statistic>
              {
                maxAnswers > 1
                &&
                <Statistic size="mini" key={"posibleAnswers"}>
                  <Statistic.Value>{possibleAnswers}</Statistic.Value>
                  <Statistic.Label>x{maxAnswers} POSIBLE ANSWERS</Statistic.Label>
                </Statistic>
              }
              <Statistic size="mini" key={"assigned"}>
                <Statistic.Value>{assigned}</Statistic.Value>
                <Statistic.Label>ASSIGNED</Statistic.Label>
              </Statistic>
              <Statistic size="mini" key={"left"}>
                <Statistic.Value>{left}</Statistic.Value>
                <Statistic.Label>LEFT</Statistic.Label>
              </Statistic>
            </div>
            <Progress percent={percent} color="green">{percent}% assigned</Progress>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.task) {
      return "";
    }

    const filtered = this.getFilteredUsertasks();

    filtered.push({
      is_active: true,
      blocked: false,
      tutorial_confirmed: false,
      auto_assigned: false,
      task: this.props.task.id,
      max_questions: 0,
    });

    const userTasks = _.map(filtered, (usrt, idx) => {
      return (
        <UserTask
          key={idx}
          idx={idx}
          usernameOptions={this.props.allUsers ? this.props.allUsers : []}
          usertask={usrt}
          onChange={this.onChange}
          setModified={this.setModified}
          errors={_.get(this.props, `errors.usertasks[${idx}]`)}
          handleCheckboxClick={this.handleCheckboxClick}
          isChecked={this.state.checkedList.includes(usrt.username)}
        />
      );
    });

    const header = (
      <Table.Row>
        <Table.HeaderCell width={1}>
          <Form.Checkbox
            id="all"
            onChange={this.handleSelectAll}
            checked={this.state.isAllChecked}
          />
        </Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell width={3}>User</Table.HeaderCell>
        <Table.HeaderCell>Limit</Table.HeaderCell>
        <Table.HeaderCell width={2}>Answers</Table.HeaderCell>
        <Table.HeaderCell width={2}>Deadline</Table.HeaderCell>
        <Table.HeaderCell width={2}>Added</Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell width={3}>Last activity</Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell />
      </Table.Row>
    );

    return (
      <UserTaskSetBase
        header={header}
        statistics={this.renderStatistics()}
        userTasks={userTasks}
      />
    );
  }
}
