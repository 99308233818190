import _ from "lodash";
import React, {useRef} from "react";
import PropTypes from "prop-types";
import {Header, Form, Icon, Popup, Radio} from "semantic-ui-react";


const WidgetValueFilter = ({type, widgets, filters, updateFilters}) => {
  const basicPath = "advanced_" + type;

  const handleSearchInput = (data, widgetId) => {
    let filter = _.fromPairs(filters[basicPath].map((el) => el.split("~")));
    let filterExact = _.fromPairs(filters[basicPath + "_exact_match"].map((el) => el.split("~")));

    if (!data.value) {
      delete filter[widgetId];
      delete filterExact[widgetId];
    } else if (data.value && widgetId in filterExact) {
      filterExact = {...filterExact, [widgetId]: data.value};
      delete filter[widgetId];
    } else {
      filter = {...filter, [widgetId]: data.value};
    }

    updateFilters({
      [basicPath]: _.toPairs(filter).map((el) => el.join("~")),
      [basicPath + "_exact_match"]: _.toPairs(filterExact).map((el) => el.join("~")),
    });
  };

  const handleIsEmptyButtons = (
    {checked, "data-checked-path": checkedPath, "data-other-path": otherPath},
    widgetId,
    isOtherChecked
  ) => {
    let newValueFilters = filters[basicPath];
    let newFilters = filters[basicPath + checkedPath];
    let newOtherFilters = filters[basicPath + otherPath];
    let newExactFilter = _.fromPairs(filters[basicPath + "_exact_match"].map((el) => el.split("~")));

    if (!checked) {
      newFilters = newFilters.filter((w) => w !== widgetId);
    } else {
      newFilters = [...newFilters, widgetId];
      newValueFilters = newValueFilters.filter((val) => val.split("~")[0] !== widgetId);
      delete newExactFilter[widgetId];
      if (isOtherChecked) {
        newOtherFilters = newOtherFilters.filter((w) => w !== widgetId);
      }
    }

    updateFilters({
      [basicPath]: newValueFilters,
      [basicPath + checkedPath]: newFilters,
      [basicPath + otherPath]: newOtherFilters,
      [basicPath + "_exact_match"]: _.toPairs(newExactFilter).map((el) => el.join("~")),
    });
  };

  const handleExactMatch = (
    {checked, "data-checked-path": checkedPath},
    widgetId
  ) => {
    let filter = _.fromPairs(filters[basicPath].map((el) => el.split("~")));
    let newFilters = _.fromPairs(filters[basicPath + checkedPath].map((el) => el.split("~")));

    if (!checked) {
      filter = {...filter, [widgetId]: newFilters[widgetId]};
      delete newFilters[widgetId];
    } else {
      if (widgetId in filter) {
        newFilters = {...newFilters, [widgetId]: filter[widgetId]};
      }
      delete filter[widgetId];
    }

    updateFilters({
      [basicPath]: _.toPairs(filter).map((el) => el.join("~")),
      [basicPath + checkedPath]: _.toPairs(newFilters).map((el) => el.join("~")),
    });
  };

  const handleFilterMode = (e, data) => {
    const mode = data.checked ? "OR" : "AND";
    const path = basicPath + "_filter_mode";
    updateFilters({[path]: mode});
  };

  //css fix for checkbox labels changing position when there is no scrollbar
  const isScrollRef = useRef({});
  const isScroll = isScrollRef.current.scrollHeight > isScrollRef.current.clientHeight;
  const widgetsFilterListClassName = `widgets-filter-list ${isScroll ? "" : "no-scrollbar"}`;

  return <>
    <Header size="tiny">Advanced {type.split("_").join(" ")} filter</Header>
    <div className={"filter-mode"}>
      <span>AND</span>
      <Radio
        slider
        checked={filters[basicPath + "_filter_mode"] === "OR"}
        onChange={handleFilterMode}
      />
      <span>
        OR
        <Popup
          flowing
          position={"top center"}
          content={"AND - All conditions | OR - One of conditions"}
          trigger={<Icon name={"info circle"}/>}
        />
      </span>
    </div>
    <div className={widgetsFilterListClassName} ref={isScrollRef}>
      {widgets.map((widget) => {
        const widgetId = widget.componentId;

        let isCheckedEmpty = false;
        let isCheckedNotEmpty = false;
        let isCheckedExactMatch = false;
        let isDisabled = false;
        let isVal = false;

        let val = filters[basicPath]
          .find((val) => val.split("~")[0] === widgetId);
        let valExactMatch = filters[basicPath + "_exact_match"]
          .find((val) => val.split("~")[0] === widgetId);
        let valNotEmpty = filters[basicPath + "_not_empty"]
          .find((val) => val === widgetId);
        let valEmpty = filters[basicPath + "_empty"]
          .find((val) => val === widgetId);

        if (val || valExactMatch) {
          isVal = true;
          if (val) {
            val = val.split("~")[1];
          } else {
            isCheckedExactMatch = true;
            val = valExactMatch.split("~")[1];
          }
        } else if (valNotEmpty) {
          isDisabled = true;
          val = "not empty";
          isCheckedNotEmpty = true;
        } else if (valEmpty) {
          isDisabled = true;
          val = "empty";
          isCheckedEmpty = true;
        } else {
          val = "";
        }

        return <Form.Group key={widgetId}>
          <span>{widgetId}:</span>
          <div>
            <Form.Input
              size="mini"
              placeholder={"value for " + widgetId}
              disabled={isDisabled}
              value={val}
              onChange={(e, data) => handleSearchInput(data, widgetId)}
            />
            <Form.Checkbox
              checked={isCheckedExactMatch}
              disabled={!isVal}
              onChange={(e, data) => handleExactMatch(data, widgetId)}
              data-checked-path="_exact_match"
            />
            <Form.Checkbox
              checked={isCheckedNotEmpty}
              className={"notEmpty"}
              onChange={(e, data) => handleIsEmptyButtons(data, widgetId, isCheckedEmpty || isCheckedExactMatch)}
              data-checked-path="_not_empty"
              data-other-path="_empty"
            />
            <Form.Checkbox
              checked={isCheckedEmpty}
              className={"empty"}
              onChange={(e, data) => handleIsEmptyButtons(data, widgetId, isCheckedNotEmpty || isCheckedExactMatch)}
              data-checked-path="_empty"
              data-other-path="_not_empty"
            />
          </div>
        </Form.Group>;
      })}
    </div>
  </>;
};

WidgetValueFilter.propTypes = {
  type: PropTypes.oneOf(["question", "answer", "evaluation_question", "evaluation_answer"]).isRequired,
  widgets: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired
};

export default WidgetValueFilter;
