import _ from "lodash";
import React, {Component} from "react";
import {Grid, Popup} from "semantic-ui-react";
import TaskReportRow from "./TaskReportRow";
import PropTypes from "prop-types";
import {GROUPED_BY} from "./utils";
import ReportTitleRow from "./ReportTitleRow";


export default class ReportContentRow extends Component {
  static propTypes = {
    report: PropTypes.oneOfType([
      PropTypes.shape({
        tasks_reports: PropTypes.array
      }),
      PropTypes.shape({
        reports: PropTypes.array
      })
    ]).isRequired,
    allTime: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    groupedBy: PropTypes.string,
    onAccept: PropTypes.func.isRequired,
    onHoursChange: PropTypes.func.isRequired,
    active: PropTypes.bool,
    idx: PropTypes.number,
    onTaskAccept: PropTypes.func,
    onTaskHoursChange: PropTypes.func,
    nested: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {activeIndex: -1};
  }

  onClick = (event, idx) => {
    const {activeIndex} = this.state;
    const newIndex = activeIndex === idx ? -1 : idx;

    this.setState({activeIndex: newIndex});
  };

  renderRowGroupedByUser = () => {
    const rows = _.map(this.props.report.tasks_reports, (taskReport, idx) => {
      return (
        <TaskReportRow
          auth={this.props.auth}
          key={taskReport.id}
          idx={idx}
          groupedBy={this.props.groupedBy}
          taskReport={taskReport}
          allTime={this.props.allTime}
          report={this.props.report}
          onAccept={this.props.onAccept}
          onHoursChange={this.props.onHoursChange}
        />
      );
    });

    return <Grid columns="equal">
      <Grid.Row className="sub-header">
        <Grid.Column width={3}>Task</Grid.Column>
        <Grid.Column>Language</Grid.Column>
        <Grid.Column width={3}>Activity</Grid.Column>
        <Grid.Column>Hours</Grid.Column>
        <Grid.Column width={1}>
          <Popup
            trigger={
              <div>
                Tempo
              </div>
            }
            content="Answers per hours"/>
        </Grid.Column>
        <Grid.Column>Status</Grid.Column>
        <Grid.Column width={3}>Options</Grid.Column>
      </Grid.Row>
      {rows}
    </Grid>;
  };

  renderRowGroupedByTask = () => {
    const rows = _.map(this.props.report.reports, (report, idx) => {
      return (
        <TaskReportRow
          auth={this.props.auth}
          key={idx}
          idx={idx}
          groupedBy={this.props.groupedBy}
          taskReport={report}
          onAccept={this.props.onAccept}
          onHoursChange={this.props.onHoursChange}
          allTime={this.props.allTime}
        />
      );
    });

    return <Grid>
      <Grid.Row className={this.props.nested ? "nested-sub-header" : "sub-header"}>
        <Grid.Column width={2}>Username</Grid.Column>
        <Grid.Column width={2}>Company</Grid.Column>
        <Grid.Column width={1}>Nativity</Grid.Column>
        {this.props.allTime && <Grid.Column width={2}>Deadline</Grid.Column>}
        <Grid.Column width={3}>Activity</Grid.Column>
        <Grid.Column width={2}>Hours</Grid.Column>
        <Grid.Column width={1}>
          <Popup
            trigger={
              <div>
                Tempo
              </div>
            }
            content="Answers per hours"/>
        </Grid.Column>
        <Grid.Column width={2}>Status</Grid.Column>
        <Grid.Column width={3}>Options</Grid.Column>
      </Grid.Row>
      {rows}
    </Grid>;
  };

  renderRowGroupedByTaskAndProject = () => {
    return _.map(this.props.report.reports, (report, idx) => {
      return (
        <Grid.Row className={"body nested"} key={report.id}>
          <Grid.Column width={16}>
            <Grid width={16} columns={4}>
              {idx === 0 && <Grid.Row className="sub-header">
                <Grid.Column width={5}>Task</Grid.Column>
                <Grid.Column width={3}>Language</Grid.Column>
                <Grid.Column width={3}>Deadline</Grid.Column>
                <Grid.Column width={2}>Hours</Grid.Column>
                <Grid.Column width={3}>Status</Grid.Column>
              </Grid.Row>}
              <ReportTitleRow
                nested
                auth={this.props.auth}
                groupedBy={GROUPED_BY.TASK}
                report={report}
                idx={idx}
                reportId={report.id}
                onClick={this.onClick}
                active={this.state.activeIndex === idx}
              />
              <ReportContentRow
                nested
                auth={this.props.auth}
                groupedBy={GROUPED_BY.TASK}
                active={this.state.activeIndex === idx}
                report={report}
                idx={idx}
                reportId={report.id}
                onAccept={this.props.onAccept}
                onHoursChange={this.props.onHoursChange}
              />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  render() {
    let headers;
    switch (this.props.groupedBy) {
    case GROUPED_BY.USER:
      headers = this.renderRowGroupedByUser();
      break;
    case GROUPED_BY.TASK_AND_PROJECT:
      headers = this.renderRowGroupedByTaskAndProject();
      break;
    default:
      headers = this.renderRowGroupedByTask();
      break;
    }
    let clsName = this.props.nested ? "nested-content" : "content";
    clsName = this.props.active ? `${clsName} active` : clsName;

    return (
      <Grid.Row
        className={clsName}
        key={this.props.idx}
      >
        <Grid.Column width={16}>
          {headers}
        </Grid.Column>
      </Grid.Row>
    );
  }
}
