import React from "react";
import {Button, Table, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";

import ajax from "../../../helpers/ajax";
import {parseFilters} from "../../../helpers/filters";

import config from "../../../config/config";
import ReportDeadlineModal from "./ReportDeadlineModal";
import SortableTable from "../../../components/simple/SortableTable/SortableTable";
import FilteredComponent from "../../../components/FilteredComponent";
import LoaderSpinner from "../../../components/LoaderSpinner";
import ListItem from "./ListItem";
import {toastr} from "react-redux-toastr";
import Pagination from "../../../components/Pagination";

import "./ReportDeadlineList.css";

class ReportDeadlineList extends FilteredComponent {
  constructor(props) {
    super(props);

    this.emptyDeadline = {
      id: null,
      companies: [],
      start: "",
      end: "",
      startTime: "00:00",
      endTime: "23:59",
      default: true,
      name: "",
    };

    this.state = {
      deadlines: null,
      totalPages: 1,
      displayModal: false,
      selectedDeadline: this.emptyDeadline,
      companies: [],
    };

    this.parseFilters = parseFilters.bind();
  }

  componentDidMount() {
    this.getCompanies();
    this.getData();
  }

  getData = (filters = null) => {
    const params = (filters ? filters : this.getFilters());
    params.page = params.page ? params.page : 1;

    ajax.get(config.LINGUISTS_MONTH_REPORTS_DEADLINE, {params})
      .then((response) => {
        this.setState({
          deadlines: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
        }, () =>
          this.setState({
            displaySpinner: false,
          })
        );
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  };

  getCompanies = () => {
    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        this.setState({
          companies: response.data,
        });
      });
  };

  handlePageChange = (e, {activePage}) => {
    let newFilters = this.updateFilters({page: activePage});
    this.getData(newFilters);
  };

  handleOpenModal = (deadline) => {
    this.setState({
      displayModal: true,
      selectedDeadline: {...deadline}
    });
  };

  handleCloseModal = () => {
    this.setState({
      displayModal: false,
      selectedDeadline: this.emptyDeadline,
    });
  };

  matchCompanyIdWithObject = (companyIds) => {
    const {companies} = this.state;
    const companyArr = [];

    // take company ids from deadline and match them with company objects
    companyIds.forEach(
      (companyId) => companyArr.push(
        ...companies.filter(
          (company) => company.id === companyId
        ))
    );
    return companyArr;
  };

  render() {
    const {
      deadlines,
      selectedDeadline,
      companies,
      totalPages,
      displayModal,
    } = this.state;
    const {is_staff} = this.props.auth;

    const canModify = is_staff && displayModal;
    const filters = this.getFilters();

    let columns = [
      {name: "name", title: "Name", headerProps: {width: 3}},
      {
        name: "companies", title: "Companies",
        unsortable: true, headerProps: {width: 5}
      },
      {name: "start", title: "Started on", headerProps: {width: 2}},
      {name: "startTime", title: "Start time", headerProps: {width: 2}},
      {name: "end", title: "Ends on", headerProps: {width: 2}},
      {name: "endTime", title: "End time", headerProps: {width: 2}},
    ];

    return (
      <div className={"ReportDeadlineList"}>
        <Segment.Group className="users-list">
          <Segment.Group>
            <Segment clearing className="datahive-segment">
              {is_staff &&
              <Button
                className={"ui button new-report-deadline-btn datahive-button"}
                color="yellow"
                floated="right"
                onClick={() => this.handleOpenModal(selectedDeadline)}
                content={"Add new deadline"}
              />
              }
            </Segment>
            <Segment className="table-segment datahive-segment">
              <SortableTable
                columns={columns}
                sorted={_.get(filters, "order_by")}
                tableProps={{compact: true, className: "SortableTable ReportDeadlineList", striped: true}}
                updateSorting={this.updateSorting}
              >
                <Table.Body>
                  {!deadlines &&
                  <Table.Row>
                    <Table.Cell colSpan={8}>
                      <LoaderSpinner/>
                    </Table.Cell>
                  </Table.Row>}
                  {deadlines && deadlines.map((deadline) =>
                    <ListItem
                      is_staff={is_staff}
                      id={deadline.id}
                      name={deadline.name || ""}
                      companies={this.matchCompanyIdWithObject(deadline.companies)}
                      default={deadline.default}
                      start={moment(deadline.start).format(config.FORMAT_Ymd)}
                      startTime={moment(deadline.start).format(config.FORMAT_Hm)}
                      end={moment(deadline.end).format(config.FORMAT_Ymd)}
                      endTime={moment(deadline.end).format(config.FORMAT_Hm)}
                      changeDeadline={this.handleOpenModal}
                      key={deadline.id}
                    />
                  )}
                </Table.Body>
              </SortableTable>
              <Pagination
                activePage={filters.page || 1}
                onPageChange={this.handlePageChange}
                totalPages={totalPages}
              />
            </Segment>
            {canModify &&
            <ReportDeadlineModal
              companies={companies}
              deadline={selectedDeadline}
              handleClose={this.handleCloseModal}
              reloadData={this.getData}
            />}
          </Segment.Group>
        </Segment.Group>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export {ReportDeadlineList}; //raw component for testing
export default connect(mapStateToProps)(ReportDeadlineList);
