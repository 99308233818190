import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid} from "semantic-ui-react";

export default class WidgetDnD extends Component {
  /*
  This component is only supposed to handle the display of drag and drop elements
  and forwarding of the appropriate handlers. Filtering, sorting, and the actual data layer management are supposed to be
  handled by the parent component. This component displays filters for the choices and selected area and upon a drop happening
  calls the proper function. Usually only the change in selected field has to be handled specifically.
   */
  static propTypes = {
    selected: PropTypes.arrayOf(PropTypes.element.isRequired),
    /* List of React Elements displayed in the selected section. */

    choices: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.object.isRequired,
    ]),
    /* List of React Elements displayed in the choices section. */

    choicesChange: PropTypes.func,
    /* Function that handles the choices change event. It is called by the list on the left. It is optional. */

    selectedChange: PropTypes.func.isRequired,
    /* Function that handles the selected change event. It is called by the list on the right. */

    choicesFilterChange: PropTypes.func,
    /* Function that handles the change of the choices filter. */

    selectedFilterChange: PropTypes.func,
    /* Function that handles the change of the selected filter. */

    choicesFilter: PropTypes.string,
    /* Choices filter displayed in the input */

    selectedFilter: PropTypes.string,
    /* Selected filter displayed in the input */

    onDrag: PropTypes.func,
    /* Change parent's state (to start scrolling). */

    onDragEnd: PropTypes.func
    /* Change parent's state (to stop scrolling). */
  };

  render() {
    return (
      <Grid className="DragAndDrop">
        <Grid.Row style={{paddingTop: 0}}>
          <Grid.Column className="toolbar" width={4}>
            <div
              className="block-list"
              onDrop={this.props.choicesChange}
              onDrag={this.props.onDrag}
              onDragEnd={this.props.onDragEnd}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {this.props.choices}
            </div>
          </Grid.Column>
          <Grid.Column className="content" width={12}>
            <div
              className="block-list"
              onDrop={this.props.selectedChange}
              onDrag={this.props.onDrag}
              onDragEnd={this.props.onDragEnd}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {this.props.selected}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

