import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Widget extends Component {
  static propTypes = {
    // widget configuration
    widgetConfig: PropTypes.object,

    componentId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    // function used for update parent widgetVals
    update: PropTypes.func,
    // component value -> parent.widgetVals[componentId]
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.array,
    ]),
    // widgetVals - values of all widgets
    widgetVals: PropTypes.object,

    // function for registered isValid method in parent registry
    registerValid: PropTypes.func,
    // function for registered isEmpty method in parent registry
    registerEmpty: PropTypes.func,
    // function for registering prepareForSubmit method in parent registry
    registerPrepareForSubmit: PropTypes.func,

    // flag if widget is in editor mode
    editorMode: PropTypes.bool,

    // function used to open widget popup
    onOpenPopup: PropTypes.func,

    // current question ID
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    editorProps: PropTypes.object
  };

  // method used for return default props values
  static getDefaultConfig = () => {
    throw new Error("Method getDefaultConfig must be implemented!");
  };

  // method used for create editor for this widget
  /* eslint-disable-next-line no-unused-vars */
  createEditor = (editorProps) => {

  };

  getEditor = () => {
    if (this.props.editorProps) {
      const editorProps = {
        ...this.props.editorProps,
        widgetId: this.props.componentId,
      };
      this.popupContent = React.createElement(this.editorClass, editorProps);
    }
  };

  isEmpty = () => {
    throw new Error("Method isEmpty must be implemented!");
  };

  isValid = () => {
    throw new Error("Method isValid must be implemented!");
  };

  prepareForSubmit = () => {
    //this is fired right before a submit, but synchronously.
  };

  update = (widgetVal) => {
    if (this.props.update) {
      this.props.update(widgetVal, this.props.componentId);
    } else {
      // console.warn('Update not passed to widget!')
    }
  };

  registerEmptyValid = () => {
    if (this.props.registerEmpty && this.props.registerValid) {
      this.props.registerEmpty(this.isEmpty, this.props.componentId);
      this.props.registerValid(this.isValid, this.props.componentId);
      this.props.registerPrepareForSubmit(this.prepareForSubmit, this.props.componentId);
    }
  };
}
