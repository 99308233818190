import moment from "moment";

import * as actionTypes from "./types/types_tasks";
import ajax from "../helpers/ajax";
import store from "../store/store";
import config from "../config/config";

// load tasks if they are expired
export function loadTasks() {
  return (dispatch) => {
    if (moment().isAfter(store.getState().tasks.expiration)) {
      ajax.get(config.TASK_LIST)
        .then((response) => {
          dispatch(loaded(response.data));
        });
    }
  };

  function loaded(tasks) {
    return {type: actionTypes.LOAD_TASKS, tasks};
  }
}

export function filterClear() {
  return {
    type: actionTypes.FILTER_CLEAR,
  };
}

export function updateFilter(filters) {
  // eslint-disable-next-line
  console.log('action', 'updateFilter', filters);
  return {
    type: actionTypes.UPDATE_FILTER,
    filters
  };
}
