import _ from "lodash";

import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {Button, Checkbox, Container, Table, TextArea} from "semantic-ui-react";

import ajax from "../../helpers/ajax";

import {ERRORS_NEW, ERRORS_CLEAR} from "../../actions/types/types_errors";

import urls from "../../config/config";

import "./AudioHunter.css";


class AudioHunter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imArtur: false,
      nameSelected: false,
      names: "",
    };
  }

  componentDidMount() {
    ajax.get(urls.AUDIO_HUNTER_RANKING)
      .then((response) => {
        this.setState({rankings: response.data});
      })
      .catch((errors) => {
        console.log(errors); // eslint-disable-line no-console
      });
  }

  migrate = () => {
    let usernames = _.map(this.state.names.split("\n"), (user) => user.trim());
    ajax.post(urls.AUDIO_HUNTER_MIGRATE, {data: {usernames}})
      .then((response) => {
        this.setState({migrated: response.data.migrated});
      });
  };

  getSummary = () => {
    let summary = new Set(_.map(this.state.migrated, (migrated) => migrated.username));

    return _.map(Array.from(summary), (user) => ({
      username: user,
      count: _.filter(this.state.migrated, {username: user}).length
    })
    );
  };

  renderMigrated = () => {
    return (
      <React.Fragment>
        <h2>
          Migrated
        </h2>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Username
              </Table.HeaderCell>
              <Table.HeaderCell>
                Ans ID
              </Table.HeaderCell>
              <Table.HeaderCell>
                Q ID
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              _.map(this.state.migrated, (mig, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell>
                    {mig.username}
                  </Table.Cell>
                  <Table.Cell>
                    {mig.ans_id}
                  </Table.Cell>
                  <Table.Cell>
                    {mig.q_id}
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  };

  renderSummary = () => {

    return (
      <React.Fragment>
        <h2>
          Summary
        </h2>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Username
              </Table.HeaderCell>
              <Table.HeaderCell
              >
                Migrated count
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              _.map(this.getSummary(), (summary, idx) => (
                <Table.Row key={idx}>
                  <Table.Cell>
                    {summary.username}
                  </Table.Cell>
                  <Table.Cell>
                    {summary.count}
                  </Table.Cell>
                </Table.Row>
              )
              )
            }
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  };

  render() {

    let nameSelected = this.state.nameSelected;

    return (
      <Container className="AudioHunter">
        <div className={"inline-block"}>
          <h2>
            Rankings
          </h2>
          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Place
                </Table.HeaderCell>
                <Table.HeaderCell
                  className={nameSelected ? "selected-column" : ""}
                  onClick={() => this.setState({nameSelected: !this.state.nameSelected})}
                >
                  Name (click to select)
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Score
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.map(this.state.rankings, (ranking, idx) => (
                  <Table.Row>
                    <Table.Cell className={nameSelected ? "noselect" : ""}>
                      {idx + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {ranking.name}
                    </Table.Cell>
                    <Table.Cell className={nameSelected ? "noselect" : ""}>
                      {ranking.points}
                    </Table.Cell>
                  </Table.Row>
                )
                )
              }
            </Table.Body>
          </Table>
        </div>
        <div className={"inline-block"}>
          <h2>
            Migrate this
          </h2>
          <Checkbox
            checked={this.state.imArtur}
            onChange={(e, {checked}) => {
              this.setState({imArtur: checked});
            }}
            label={"Jestem Artur, nie robot"}
          />
          <TextArea
            className={"block"}
            rows={30}
            onChange={(e, {value}) => {
              this.setState({names: value});
            }}
            value={this.state.names}
          />
          <Button
            disabled={!this.state.imArtur}
            content={"Migruj waćpanie!"}
            color={"green"}
            onClick={this.migrate}
          />
        </div>
        <div className={"inline-block"}>
          {this.renderSummary()}
          {this.renderMigrated()}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(AudioHunter));
