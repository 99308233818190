import React, {Component} from "react";
import {Form, Label, Message} from "semantic-ui-react";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import CONFIG from "../../config/config";
import {AuthView} from "./AuthView";

import "./PasswordReset.css";

export default class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            message: null,
            isValid: true,
            isSubmitted: false,
            resetEmails: [],
            selectedRadioEmail: 0,
        };
        this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    handleLoginUpdate = (e) => {
        this.setState({
            username: e.target.value,
        });
    };

    handleSubmit = () => {
        this.setState({isSubmitted: true});
        const {username, selectedRadioEmail} = this.state;
        let selectedRadioEmailData = {
            'username': username,
            'id': selectedRadioEmail,
            'reset_type': 'password'
        }
        ajax
            .post(CONFIG.PASSWORD_RESET, {data: selectedRadioEmailData})
            .then((response) => {
                let email = '';
                this.setState({resetEmails: response.data.emails});
                if (this.state.resetEmails.length === 1 && response.data.status === "OK"){
                    email = this.state.resetEmails[0];
                    toastr.success("Password reset link sent!");
                    this.setState({
                        message: `Password reset link has been sent to ${email}.`
                    });
                } else if (this.state.resetEmails.length === 1 && this.state.selectedRadioEmail === 0){
                    email = this.state.resetEmails[0].email;
                    toastr.success("Password reset link sent!");
                    this.setState({
                        message: `Password reset link has been sent to ${email}.`
                    });
                } else if (this.state.resetEmails.length > 1 && this.state.selectedRadioEmail !== 0) {
                    email = this.state.resetEmails.find(item => item.id === this.state.selectedRadioEmail)
                    toastr.success("Password reset link sent!");
                    this.setState({
                        message: `Password reset link has been sent to ${email.email}.`
                    });
                } else if (this.state.resetEmails.length > 1 && !this.state.selectedRadioEmail){
                    toastr.info("Please, select your coordinator's email!");
                } else {
                    toastr.info("An error occured. Please contact your coordinator!");
                }
            })
            .catch((error) => {
                    let errorMsg = error.response?.data?.message;
                    errorMsg = errorMsg ? errorMsg : "Something went wrong.";
                    toastr.error(errorMsg);
                    this.setState({isSubmitted: false});
                }
            );
    };
    handleSelect = (e, { value }) => {this.setState({ selectedRadioEmail: value, isSubmitted: false})}
    render() {
        const {message, username, isSubmitted, resetEmails, selectedRadioEmail} = this.state;
        const isValid = username.length > 0;
        return (
            <AuthView>
                <PasswordResetForm
                    updateLogin={this.handleLoginUpdate}
                    submit={this.handleSubmit}
                    handleSelect={this.handleSelect}
                    message={message}
                    selectedRadioEmail={selectedRadioEmail}
                    isValid={isValid}
                    isSubmitted={isSubmitted}
                    resetEmails={resetEmails}
                />
            </AuthView>
        );
    }
}

export const PasswordResetForm = (props) => (
    <React.Fragment>
        <Message
            className="datahive-message"
            warning={false}
            header="Information"
            content="You may have to contact your company coordinator to reset your password"
        />
        <h2>Recover your password</h2>
        <h3 className={"password-reset__feel-good"}>
            {props.resetEmails && props.resetEmails.length < 2 ?
                'Don\'t worry, happens to the best of us'
                :
                props.resetEmails ? 'Please, select coordinator email from list:'
                    :
                    'Here you go!'
            }
        </h3>
        <Form onSubmit={props.submit} >
            {props.resetEmails && props.resetEmails.length < 2 ?
                <Form.Input
                    label="Your username:"
                    onChange={(e) => props.updateLogin(e)}
                />
                :
                props.resetEmails ? props.resetEmails.map((item, key) =>
                    <Form.Radio
                        key={key}
                        label={item.email}
                        value={item.id}
                        checked={props.selectedRadioEmail === item.id}
                        onChange={props.handleSelect}
                    />
                ) : ''
            }
            <Form.Field>
                {props.message &&
                    <Label color={"red"}>{props.message}</Label>}
                <label>&nbsp;</label>
                <Form.Button
                    className={"reset-button datahive-button"}
                    color="yellow"
                    disabled={!props.isValid || props.isSubmitted}
                >
                    Send reset email
                </Form.Button>
            </Form.Field>
        </Form>
    </React.Fragment>
);

PasswordResetForm.propTypes = {
    submit: PropTypes.func.isRequired,
    updateLogin: PropTypes.func.isRequired,
    message: PropTypes.string,
    value: PropTypes.string,
    isValid: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    resetEmails: PropTypes.array,
    selectedRadioEmail: PropTypes.number,
    handleSelect: PropTypes.func.isRequired,
};
