import _ from "lodash";

import React from "react";
import {Grid, Header} from "semantic-ui-react";
import {getWidget, iterateAllWidgets, updateWidgetConfig} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";

import "./ContainerWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";
import Dropdown from "../../simple/Dropdown/LevenDropdown";

class ContainerWidgetEditor extends WidgetEditor {
  static header = "Container";

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      newOptionName: null,
      selectedClasses: [],
      valueOptions: [],
      displayRule: {
        rules: {val: "", op: ""},
        data: {targetId: ""},
      },
    };

    this.updateClasses = this.updateClasses.bind(this);
    this.deleteWidget = this.deleteWidget.bind(this);
    this.updateDisplayRuleRules = this.updateDisplayRuleRules.bind(this);
    this.updateDisplayRuleData = this.updateDisplayRuleData.bind(this);
    this.updateValueOptions = this.updateValueOptions.bind(this);
    this.updateWidgetConfig = updateWidgetConfig.bind(this);

    this.opts = [];

    this.classesOptions = _.map(this.opts, (opt) => {
      return {key: opt, text: opt, value: opt};
    });
  }

  updateClasses(e, data) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.customClass = data.value.join(" ");
    });
  }

  updateValueOptions(val) {
    const config = getWidget(this.props.getWidgetConfig(), val);
    const valueOptions = [{key: "", text: "", value: ""}];
    if (config) {
      iterateAllWidgets([config], (w) => {
        if (w.componentType === "RadioButtonWidget") {
          _.map(w.options, (v) => {
            valueOptions.push({key: v.value, text: v.value, value: v.value});
          });
        }
      });
    }
    return valueOptions;
  }

  updateDisplayRuleData(val) {
    this.updateValueOptions(val);
    this.updateWidgetConfig((widgetConfig) => {
      if (widgetConfig.displayRule) {
        widgetConfig.displayRule = {
          ...widgetConfig.displayRule,
          data: {
            ...widgetConfig.displayRule.data,
            targetId: val
          }
        };
      } else {
        widgetConfig.displayRule = {
          data: {
            targetId: val
          }
        };
      }
    });

    // FORCE RE-RENDER
    this.setState({});
  }

  updateDisplayRuleRules(field, val) {
    // Trigger updateDisplayRule on value field if updating operation
    if (field === "op") {
      const element = document.querySelector(".relationship-value-field input");
      if (element) {
        this.updateDisplayRuleRules("val", this.getProperValue(element.value, val));
      }
    }

    this.updateWidgetConfig((widgetConfig) => {
      if (widgetConfig.displayRule) {
        widgetConfig.displayRule = {
          ...widgetConfig.displayRule,
          rules: {
            ...widgetConfig.displayRule.rules,
            [field]: val
          }
        };
      } else {
        widgetConfig.displayRule = {
          rules: {
            [field]: val
          }
        };
      }
    });

    // FORCE RE-RENDER
    this.setState({});
  }

  getProperValue(value, operation) {
    if (operation === "intersects") {
      return [];
    }
    return value;
  }

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);
    const excludedId = [];
    const idOptions = [{key: "", text: "", value: ""}];
    iterateAllWidgets([config], (w) => {
      excludedId.push(w.componentId);
    });
    iterateAllWidgets(this.props.getWidgetConfig(), (w) => {
      if (!(_.includes(excludedId, w.componentId)) && (w.componentType !== "SubmitWidget") &&
        (w.componentType !== "ContainerWidget")) {
        idOptions.push({key: w.componentId, text: w.componentId, value: w.componentId});
      }
    });
    const displayRule = config.displayRule ? config.displayRule :
      {
        rules: {val: "", op: ""},
        data: {targetId: ""},
      };

    const op = displayRule.rules ? displayRule.rules.op : "";
    const multiple = displayRule.rules && displayRule.rules.op === "intersects";
    const value = displayRule.rules ? displayRule.rules.val : multiple ? [] : "";
    const targetId = displayRule.data ? displayRule.data.targetId : "";
    const relationshipOptions = [{key: "asd", text: "Equals", value: "==="},
      {key: "asd1", text: "Not Equals", value: "!=="},
      {key: "asd2", text: "Intersects", value: "intersects"}];
    return (

      <Grid className={"ContainerWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Header as="h4">Display When</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} textAlign="center">
          <Grid.Column>
            <label>
              Element with id:
            </label>
            <br/>
            <Dropdown
              width={12} size="mini"
              placeholder="id"
              options={idOptions}
              value={targetId}
              onChange={(e, data) => this.updateDisplayRuleData(data.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <label>
              Relationship
            </label>
            <br/>
            <Dropdown
              placeholder="select operation"
              options={idOptions.length > 1 ? relationshipOptions : []}
              value={op}
              onChange={(e, data) => this.updateDisplayRuleRules("op", data.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <label>
              Value
            </label>
            <br/>
            <Dropdown
              fluid search selection
              multiple={multiple}
              className={"relationship-value-field"}
              width={12} size="mini"
              placeholder="value"
              options={this.updateValueOptions(targetId)}
              value={value}
              onChange={(e, data) => this.updateDisplayRuleRules("val", data.value)}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
      </Grid>
    );
  }
}

export default ContainerWidgetEditor;
