import _ from "lodash";
import React, {Component} from "react";
import {Rnd} from "react-rnd";
import {Header, Button} from "semantic-ui-react";
import TreeSelect from "../../simple/TreeSelect/TreeSelect";
import {getNode} from "../../simple/TreeSelect/utils";
import "./ConnectorModal.css";
import AttributeDropdown from "./AttributeDropdown";
import NoteInput from "./NoteInput";
import PropTypes from "prop-types";


class ConnectorModal extends Component {
  static propTypes = {
    connectorTypes: PropTypes.array,
    update: PropTypes.func,
    range: PropTypes.shape({
      begin: PropTypes.number,
      end: PropTypes.number,
      name: PropTypes.string
    }),
    connector: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      note: PropTypes.string
    }),
    closeModal: PropTypes.func,
    open: PropTypes.bool,
    originalString: PropTypes.oneOfType([
      PropTypes.shape({
        substr: PropTypes.string
      }),
      PropTypes.string
    ]),
    targetRange: PropTypes.shape({
      begin: PropTypes.number,
      end: PropTypes.number,
      name: PropTypes.string
    }),
    default: PropTypes.object,
    enableNotes: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      tsOpen: true,
    };

    this.containerDiv = React.createRef();
  }

  componentDidMount() {
    try {
      this.containerDiv.current.focus();
    } catch (error) {
      // Why is the reason to print this errors?
      /* DISABLED: console.log(error); */
    }
  }

  updateConnectorType = (value) => {
    const [connector, range] = this.getConnectorAndRange();
    const connectorType = getNode(this.props.connectorTypes, _.last(value));
    connector.type = connectorType.value;
    connector.descr = connectorType.title ? connectorType.title : connectorType.value;
    this.props.update(range);
  };

  deleteConnector = () => {
    const range = _.cloneDeep(this.props.range);
    range.connectedTo = _.filter(range.connectedTo, (c) => {
      return c.id !== this.props.connector.id;
    });
    this.props.update(range);
  };

  updateValue = (selectedTypes) => {
    if (!selectedTypes.length) {
      return;
    }

    this.updateConnectorType(selectedTypes);
  };

  getConnectorAndRange = () => {
    const range = _.cloneDeep(this.props.range);
    const connector = _.find(range.connectedTo, (c) => {
      return c.id === this.props.connector.id;
    });

    return [connector, range];
  };

  updateConnectorNote = (e) => {
    const note = e.target.value;
    const [connector, range] = this.getConnectorAndRange();
    connector.note = note;
    this.props.update(range);
  };

  clearConnectorNote = () => {
    const [connector, range] = this.getConnectorAndRange();
    connector.note = "";
    this.props.update(range);
  };

  updateConnectorAttribute = (e, data) => {
    const [connector, range] = this.getConnectorAndRange();
    if (!Array.isArray(data.value)) {
      data.value = [data.value];
    }
    connector.attributes = data.value;
    this.props.update(range);
  };

  handleKeyDown = (e) => {
    if (this.props.connector.type) {
      //range has type assigned
      if (e.keyCode === 27 || e.keyCode === 13) {
        //escape pressed or enter
        this.props.closeModal();
      }
    } else {
      if (e.keyCode === 27) {
        //escape pressed
        this.deleteConnector();
      }
    }

    if (e.keyCode === 46) {
      //delete pressed
      this.deleteConnector();
    }
  };

  render() {
    if (!this.props.open) {
      return null;
    }
    const connectorType = getNode(this.props.connectorTypes, this.props.connector.type);
    const attributeConfig = connectorType ? connectorType.attributeSelectConfig : null;

    const fromRangeContent = this.props.originalString.substr(this.props.range.begin, this.props.range.end - this.props.range.begin);
    const toRangeContent = this.props.originalString.substr(this.props.targetRange.begin, this.props.targetRange.end - this.props.targetRange.begin);

    /* eslint-disable react/no-unescaped-entities */
    return (
      <Rnd
        default={this.props.default || {x: 0, y: 0}}
      >
        <div className={"ConnectorModal ui modal visible active"} ref={this.containerDiv} tabIndex={-1}
          onKeyDown={this.handleKeyDown}>
          <i aria-hidden="true" className="close icon"
            onClick={this.props.connector.type ? this.props.closeModal : this.deleteConnector}/>
          <Header>Connection</Header>
          <Header as={"h5"}>From {this.props.range.name} '{fromRangeContent}'</Header>
          <Header as={"h5"}>To {this.props.targetRange.name} '{toRangeContent}'</Header>

          <div className={"content contentio"} onMouseDown={(e) => e.stopPropagation()}>
            <div className={"contentio"}>
              <TreeSelect
                dropdownOpen defaultOpen
                data={this.props.connectorTypes || []}
                value={[this.props.connector.type] || []}
                updateValue={this.updateValue}
              />
            </div>

            {!!attributeConfig &&
            <AttributeDropdown
              obj={this.props.connector}
              attributeConfig={attributeConfig}
              updateAttribute={this.updateConnectorAttribute}/>
            }

            {this.props.enableNotes &&
            <NoteInput
              clearNote={this.clearConnectorNote}
              note={this.props.connector.note}
              updateNote={this.updateConnectorNote}
            />
            }
          </div>
          <div className={"actions"}>
            <Button content="Accept" onClick={this.props.closeModal}/>
            <Button content="Delete" onClick={this.deleteConnector}/>
          </div>
        </div>

      </Rnd>
    );
    /* eslint-enable react/no-unescaped-entities */
  }
}

export default ConnectorModal;
