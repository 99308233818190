import React, {Component} from "react";
import "../Tasks.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Modal, Icon, Button, Table, Label} from "semantic-ui-react";
import PropTypes from "prop-types";


class AddManyUsersModal extends Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired
  };

  render() {
    const content = Object.entries(this.props.userDetails).map(([usersType, users]) => {
      if (!users.length) {
        return null;
      }
      const usersList = users.map(({username, email, password=""}) =>
        <Table.Row key={username}>
          <Table.Cell width={3}>
            {username}
          </Table.Cell>
          <Table.Cell width={3}>
            {email}
          </Table.Cell>
          <Table.Cell width={3}>
            {password}
          </Table.Cell>
        </Table.Row>
      );
      return (
        <React.Fragment key={usersType}>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3">
                <Label>{usersType}</Label>
              </Table.Cell>
            </Table.Row>
            {usersList}
          </Table.Body>
        </React.Fragment>
      );
    });

    return <Modal
      dimmer={true}
      open={true}>
      <Modal.Header>
        <Icon name={"external"}/>
        Users Information
      </Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Username</Table.HeaderCell>
              <Table.HeaderCell width={3}>Email</Table.HeaderCell>
              <Table.HeaderCell width={3}>Password</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {content}
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={this.props.closeModal}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>;
  }
}

export default AddManyUsersModal;
