import React, {Component} from "react";
import _ from "lodash";
import {formatSeconds} from "../../helpers/utils";
import {Line} from "react-chartjs-2";
import PropTypes from "prop-types";

export default class TaskUserChart extends Component {
  static propTypes = {
    DataPercentiles: PropTypes.shape({
      percentiles: PropTypes.array
    }),
    Percentiles: PropTypes.shape({
      task_percentiles: PropTypes.array,
      avg_percentiles: PropTypes.array,
      min_percentiles: PropTypes.array,
      max_percentiles: PropTypes.array
    })
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderUserRows() {
    const parameters = {
      Data: {},
      Options: {}
    };
    if ((!!this.props.DataPercentiles) && (!!this.props.Percentiles)) {
      const color_table = [
        "#cc0237",
        "#0096ed",
        "#b5ff7c",
        "#fff830",
      ];
      const datasets_template = [
        {
          label: "User",
          color: color_table[0],
          data: this.props.DataPercentiles.percentiles,
        },
        {
          label: "Task",
          color: color_table[1],
          data: this.props.Percentiles.task_percentiles
        },
        {
          label: "Users",
          color: color_table[2],
          data: this.props.Percentiles.avg_percentiles
        },
        {
          label: "Users min",
          color: color_table[3],
          data: this.props.Percentiles.min_percentiles
        },
        {
          label: "Users max",
          color: color_table[3],
          data: this.props.Percentiles.max_percentiles
        },
      ];
      const step = 101 / this.props.DataPercentiles.percentiles.length;
      const percentiles = _.range(0, 101, step);

      const dataset = [];
      for (let i = 0; i < datasets_template.length; i++) {
        const dataset_template = {
          label: datasets_template[i].label,
          backgroundColor: datasets_template[i].color,
          borderColor: datasets_template[i].color,
          data: datasets_template[i].data,
          fill: false,
          pointRadius: 0,
        };

        dataset.push(dataset_template);
      }
      parameters["Options"] = {
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + formatSeconds(tooltipItem.yLabel);
            }
          }
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Percentile",
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Time",
            },
            ticks: {
              callback: function (v) {
                return formatSeconds(v);
              },
            }
          }]
        }
      };
      parameters["Data"] = {labels: percentiles, datasets: dataset};
    }
    return parameters;
  }

  render() {
    const rows = this.renderUserRows();
    return (
      <Line data={rows["Data"]} options={rows["Options"]}/>
    );
  }
}
