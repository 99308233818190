import React, {Component} from "react";
import {iterateAllWidgets, removeWidget, updateWidgetConfig} from "./widgetUtils";
import _ from "lodash";
import ContainerWidget from "./ContainerWidget/ContainerWidget";
import {Header, Button, Grid} from "semantic-ui-react";
import PropTypes from "prop-types";


/* This component contains all common methods.
It isn't recommended by some people but no one want to copy all shared methods...
 */
export class WidgetEditor extends Component {
  static propTypes = {
    widgetId: PropTypes.string.isRequired,
    openPopup: PropTypes.func.isRequired,
    updateId: PropTypes.func.isRequired,
    getWidgetConfig: PropTypes.func.isRequired,
    updateConfig: PropTypes.func.isRequired,
    widgetVals: PropTypes.object
  };

  // override this if editor should create default values
  static getDefaultVals = () => {

  };

  constructor(props) {
    super(props);

    this.state = {
      widgetId: props.widgetId,
    };

    this.updateWidgetConfig = updateWidgetConfig.bind(this);
    this.deleteWidget = this.deleteWidget.bind(this);
  }

  closeOnEscape = (event) => {
    if (event.keyCode === 27) {
      this.props.openPopup(event, this.state.widgetId);
    }
  };


  componentDidMount() {
    document.addEventListener("keydown", this.closeOnEscape, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeOnEscape, false);
  }

  updateId = (value) => {
    this.props.updateId(this.state.widgetId, value);

    this.setState({widgetId: value});
  };

  renderButtons() {
    return (
      <Grid.Row columns={2} className="Buttons-footer">
        <Grid.Column>
          <Button color="red" icon="trash" content="Delete"
            onClick={this.deleteWidget}/>
        </Grid.Column>
        <Grid.Column>
          <Button floated="right" color="blue" icon="close" content="Close"
            onClick={(e) => this.props.openPopup(e, this.state.widgetId)}/>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderClose() {
    return (
      <Grid.Row columns={2} className="Buttons-footer">
        <Grid.Column>
        </Grid.Column>
        <Grid.Column>
          <Button floated="right" color="blue" icon="close" content="Close"
            onClick={(e) => this.props.openPopup(e, this.state.widgetId)}/>
        </Grid.Column>
      </Grid.Row>
    );
  }

  deleteWidget() {
    /* Delete from container */
    const config = [...this.props.getWidgetConfig()];
    removeWidget(config, this.state.widgetId);
    this.props.updateConfig(config);
  }

  updateConfig(name, data) {
    /*
    *
    * */
    this.updateWidgetConfig((widgetConfig) => {
      _.set(widgetConfig, name, data);
    });

    /*
     * FORCE RE-RENDER
     * We need to do this because we don't have widgetConfig in props, but function to get it.
     * So if widgetConfig is change props of this component are still the same and render isn't trigger.
     */
    this.setState({});
  }

  renderPreview() {
    const config = {structure: []};
    const widgetConfig = _.cloneDeep(this.props.getWidgetConfig());
    const that = this;

    iterateAllWidgets(widgetConfig, (w) => {
      if (w.componentId === that.state.widgetId) {
        config.structure.push(w);
      }
    });

    return (
      <Grid.Row columns={1}>
        <Grid.Column
          textAlign="center"
          style={{
            marginTop: "20px",
            borderTop: "solid 1px grey",
          }}
        >
          <Header
            className="row"
            textAlign="center"
            as="h4">Preview
          </Header>
          <ContainerWidget
            componentId={"rootWidget"}
            widgetVals={this.props.widgetVals}
            toRender={config}
            editorMode={true}
            questionId={"123"}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}
