/* eslint-disable */
import _ from "lodash";
import React, { Component } from "react";
import { Container, Popup } from "semantic-ui-react";

import "./SIOSTRAWidget.css";
import { getRowData } from "./utils";
import {
  getNode,
  applyInheritance,
  iterateAllNodes,
  getAppropriateConnectorTypes,
} from "../../simple/TreeSelect/utils";
import Connector from "./Connector";

class SIOSTRADisplay extends Component {
  /*
  He wore the crown of the Java Sentinels, and those that tasted the bite of his code named him... the DOM Slayer.
  This widget heavily uses the following SVG elements: g, text, path, rect. Please read about them here:
  https://www.w3schools.com/graphics/svg_inhtml.asp
  Overview of how the widget works:
  During React render, unpositioned elements are rendered. First, text is split into 'abstract' row objects that contain metadata.
  This is done in the calls made by getRowData function. After that, each row is rendered separately.
  Each row has 5 components: background, connectors, labels, text, idx.
  Each of those is rendered separately. During render elements are unpositioned - overlapping, etc.
  Positioning elements is handled in the positionEVERYTHING function.
  This function calls other functions to position nested elements, first horizontally and then vertically,
  when necessary. Usually, the logic of positioning is the following:
   - position elements horizontally
   - check if they overlap horizontally
   - if they overlap, slide them apart vertically
  Background and row numbers are positioned after all other components.
  The string to be annotated is taken from widget value, as it is question dependant, and a pure display widget would not
  make sense here. special field named originalString, placed beside value, is used.
  */
  constructor(props) {
    super(props);

    this.colors = {
      Red: "#ff1d13",
      Orange: "#f2711c",
      Yellow: "#fbbd08",
      Olive: "#b5cc18",
      Green: "#21ba45",
      Teal: "#00b5ad",
      Blue: "#2185d0",
      Violet: "#6435c9",
      Purple: "#a333c8",
      Pink: "#e03997",
      Brown: "#a5673f",
      Grey: "#767676",
      Black: "#1b1c1d",
      SteelBlue: "#224459",
      Grapefruit: "#F2AC29",
      LightBrown: "#A6978F",
      DarkRed: "#B6440A",
    };

    this.connectorHeight = 10;
    this.connectorEndMarkerLen = 7;
    this.baseStrokeWidth = "1";
    this.boldStrokeWidth = "2";
    this.baseStrokeColorName = "Black";
    this.boldStrokeColorName = "Orange";
    this.offsetForRowIdx = 30;

    this.state = {
      rows: [],
    };

    this.containerRef = React.createRef();
    this.hiddenSpanRef = React.createRef();
    this.hiddenLabelRef = React.createRef();
    this.newConnectionPath = React.createRef();
    this.fragmentsRefs = [];
    this.rowRefs = [];
    this.labelsRefs = [];
    this.connectorRefs = [];

    this.checkComponentWidth = this.checkComponentWidth.bind(this);
    this.getApplicableRanges = this.getApplicableRanges.bind(this);
    this.positionFragmentsHorizontally =
      this.positionFragmentsHorizontally.bind(this);
    this.mouseUpHandler = this.mouseUpHandler.bind(this);
    this.getLabelsForRow = this.getLabelsForRow.bind(this);
    this.positionLabels = this.positionLabels.bind(this);
    this.positionEVERYTHING = this.positionEVERYTHING.bind(this);
    this.updateRowHeights = this.updateRowHeights.bind(this);
    this.getSVGDefs = this.getSVGDefs.bind(this);
    this.getConnectorsForRow = this.getConnectorsForRow.bind(this);
    this.positionConnectors = this.positionConnectors.bind(this);
    this.mountFragment = this.mountFragment.bind(this);
    this.getRowData = getRowData.bind(this);

    this.mouseDownTime = null; // Forgive me father for I have sinned. No other way to tell click from mousedown apart.
  }

  componentDidMount() {
    this.forceUpdate();
    window.addEventListener("resize", this.checkComponentWidth);
  }

  componentDidUpdate() {
    this.checkComponentWidth();
    this.positionEVERYTHING();
  }

  positionBackgroundAndRowNumbers = () => {
    //move everything to make space for row numbers
    let containerBox = this.containerRef.current.getBoundingClientRect();
    _.each(this.containerRef.current.children, (row) => {
      if (row.getAttribute("data-role") === "row") {
        let translate = row.getAttribute("transform");
        if (translate) {
          translate = translate.split(/[\( | \)]|,/);
          let x = parseInt(translate[1]) + this.offsetForRowIdx;
          let y = parseInt(translate[3], 10);
          row.setAttribute("transform", `translate(${x}, ${y})`);

          let rowIdx = _.find(
            row.children,
            (child) => child.getAttribute("data-role") === "rowNumber"
          );
          if (rowIdx) {
            let rowText = _.find(
              row.children,
              (child) => child.getAttribute("data-role") === "text"
            );
            if (rowText.getAttribute("transform")) {
              let textY = parseInt(
                rowText.getAttribute("transform").split(/[\( | \)]|,/)[3]
              );
              rowIdx.setAttribute("transform", `translate(-30, ${textY})`);
            }
          }
          let background = _.find(
            row.children,
            (child) => child.getAttribute("data-role") === "background"
          );
          if (background) {
            let connectors = _.find(
              row.children,
              (child) => child.getAttribute("data-role") === "connectors"
            );
            let labels = _.find(
              row.children,
              (child) => child.getAttribute("data-role") === "labels"
            );
            background.setAttribute("transform", `translate(-10, -12)`);

            let boxY = 0;
            if (connectors.getAttribute("data-content-height") !== "0") {
              boxY =
                connectors.getBoundingClientRect().y -
                row.getBoundingClientRect().y;
            } else if (
              connectors.getAttribute("data-content-height") === "0" &&
              labels.getAttribute("data-content-height") === "0"
            ) {
              boxY = 0;
            }

            background.firstChild.setAttribute("y", boxY);
            background.firstChild.setAttribute("width", containerBox.width);
            background.firstChild.setAttribute(
              "height",
              row.getAttribute("data-content-height")
            );
          }
        }
      }
    });
  };

  positionEVERYTHING() {
    this.positionFragmentsHorizontally();
    this.positionLabels();
    this.positionConnectors();
    this.updateRowHeights();
    this.positionBackgroundAndRowNumbers();
    this.positionConnectors(); // used again, to adjust positions of the connectors to take account the shift in background
  }

  updateRowHeights() {
    // updates heights of the g svg elements that serve as rows.
    let rowsHeight = _.reduce(
      this.rowRefs,
      (combinedHeight, row) => {
        let contentHeight = _.reduce(
          row.childNodes,
          (height, node) => {
            if (node.getAttribute("vertical-ignore")) {
              return height;
            }
            node.setAttribute("transform", `translate(0, ${height})`);
            return height + parseInt(node.getAttribute("data-content-height"));
          },
          0
        );

        row.setAttribute("transform", `translate(0, ${combinedHeight})`);
        row.setAttribute("data-content-height", contentHeight);
        return combinedHeight + contentHeight;
      },
      15
    );
    this.containerRef.current.setAttribute("height", rowsHeight);
  }

  checkComponentWidth() {
    // checks component width, and if changed, changes the rows to fit page.
    if (!this.containerRef.current) {
      return;
    }
    let width = this.containerRef.current.getBoundingClientRect().width;
    if (width !== this.state.width && width > 500) {
      this.setState({
        width: this.containerRef.current.getBoundingClientRect().width,
      });
    }
  }

  mouseUpHandler(e) {
    // handler for mouseup event, used on the svg container. Creates a new range on the selected text.
    try {
      this.containerRef.current.removeEventListener(
        "mousemove",
        this.mouseMoveHandler
      );
      this.newConnectionPath.current.style.visibility = "hidden";

      let oRange = window.getSelection().getRangeAt(0); //get the text range
      let startSpan = oRange.startContainer.parentElement;
      let endSpan = oRange.endContainer.parentElement;

      if (
        !startSpan.getAttribute("data-idx") ||
        !endSpan.getAttribute("data-idx")
      ) {
        // this catches bad selections that have other stuff in them
        return;
      }

      let begin =
        window.getSelection().getRangeAt(0).startOffset +
        parseInt(startSpan.getAttribute("data-begin"));
      let end =
        window.getSelection().getRangeAt(0).endOffset +
        parseInt(endSpan.getAttribute("data-begin"));
      let id = _.uniqueId(Date.now());

      let baseRange = null;
      if (this.props.newRangeEntityId) {
        baseRange = _.find(this.props.ranges, {
          entityId: this.props.newRangeEntityId,
        });
      }

      let newRange;
      let isOnlyRangeType = this.props.rangeTypes.length === 1;
      if (isOnlyRangeType) {
        const onlyRange = this.props.rangeTypes[0];
        newRange = {
          begin,
          end,
          id,
          entityId: id,
          name: onlyRange.title,
          type: onlyRange.value,
          attributes: null,
          note: null,
        };
      } else if (baseRange) {
        newRange = {
          begin,
          end,
          id,
          entityId: baseRange.entityId,
          name: baseRange.name,
          type: baseRange.type,
          attributes: baseRange.attributes,
          note: baseRange.note,
        };
      } else {
        newRange = {
          begin,
          end,
          id,
          entityId: id,
          name: "New Range",
          type: null,
          attributes: null,
          note: null,
        };
      }

      if (this.props.widgetConfig.defaultRangeType) {
        let defaultType = getNode(
          this.props.widgetConfig.rangeTypes,
          this.props.widgetConfig.defaultRangeType
        );
        newRange.type = defaultType.value;
        newRange.name = defaultType.title;
      }

      if (newRange.begin >= newRange.end) {
        return;
      }

      let ranges = [...this.props.ranges];
      ranges.push(newRange);
      ranges = _.orderBy(ranges, ["begin"]);
      this.props.updateRanges(ranges);

      this.props.updateParentState({
        displayModal: !isOnlyRangeType,
        selectedRangeId: newRange.id,
        newRangeEntityId: null,
        popupPos: {
          x: 0,
          y:
            oRange.endContainer.parentElement.parentElement.getBoundingClientRect()
              .bottom +
            window.pageYOffset -
            250,
        },
      });

      window.getSelection().removeAllRanges();
    } catch (e) {
      if (e.name === "IndexSizeError") {
      } else {
        throw e;
      }
    }
  }

  getConnectorsForRow(row, rowIdx) {
    // Returns the connector elements for a given row, to be rendered in the render function.

    this.connectorRefs = [];

    // get all ranges that connect intersect this row (in various combinations)
    let rangesBeforeEndOfRow = _.filter(this.props.ranges, (r) => {
      return r.end <= row.end;
    });
    let rangesAfterStartOfRow = _.filter(this.props.ranges, (r) => {
      return r.begin >= row.begin;
    });
    let multiLineRanges = _.filter(this.props.ranges, (r) => {
      return r.begin < row.begin && r.end > row.end;
    });

    // get lists of ids of the ranges intersecting this row, for simplicity's sake.
    let rangesBeforeEndOfRowIds = _.map(rangesBeforeEndOfRow, (r) => {
      return r.id;
    });
    let rangesAfterStartOfRowIds = _.map(rangesAfterStartOfRow, (r) => {
      return r.id;
    });
    let multiLineRangesIds = _.map(multiLineRanges, (r) => {
      return r.id;
    });

    // get ranges within the row that have connections to other ranges
    let rangesBeforeEndOfRowWithConnsToAfterStart = _.filter(
      rangesBeforeEndOfRow,
      (r) => {
        return _.some(r.connectedTo, (connector) => {
          return _.includes(rangesAfterStartOfRowIds, connector.targetId);
        });
      }
    );

    let rangesAfterStartOfRowWithConnsToBeforeEnd = _.filter(
      rangesAfterStartOfRow,
      (r) => {
        return _.some(r.connectedTo, (connector) => {
          return _.includes(rangesBeforeEndOfRowIds, connector.targetId);
        });
      }
    );

    let rangesInsideRow = _.filter(rangesBeforeEndOfRow, (r) => {
      return _.some(r.connectedTo, (connector) => {
        return _.includes(multiLineRangesIds, connector.targetId);
      });
    });
    let rangesInsideRowReversed = _.filter(multiLineRanges, (r) => {
      return _.some(r.connectedTo, (connector) => {
        return _.includes(rangesBeforeEndOfRowIds, connector.targetId);
      });
    });

    let ranges = _.concat(
      rangesBeforeEndOfRowWithConnsToAfterStart,
      rangesAfterStartOfRowWithConnsToBeforeEnd,
      rangesInsideRow,
      rangesInsideRowReversed
    );
    ranges = _.uniq(ranges);

    // get all connectors within this row
    let connectors = _.reduce(
      ranges,
      (connectors, r) => {
        if (!r.connectedTo) {
          return connectors;
        }

        _.each(r.connectedTo, (connector, idx) => {
          let target = _.find(this.props.ranges, (range) => {
            return range.id === connector.targetId;
          });

          if (
            !target ||
            (target.begin >= row.end && r.end > row.end) ||
            (target.end <= row.begin && r.begin < row.begin) ||
            (r.end < row.begin && target.begin <= row.begin)
          ) {
            return;
          }

          let localIdx = connectors.length;
          connectors.push(
            <Connector
              key={localIdx}
              isFragment={false}
              connector={connector}
              r={r}
              localIdx={localIdx}
              rowIdx={rowIdx}
              target={target}
              baseStrokeColorName={this.baseStrokeColorName}
              addRef={(self) => {
                if (this.connectorRefs[rowIdx]) {
                  this.connectorRefs[rowIdx][localIdx] = self;
                } else {
                  let arr = [];
                  arr[localIdx] = self;
                  this.connectorRefs[rowIdx] = arr;
                }
              }}
              clickHandler={(e) => {
                this.props.updateParentState({
                  displayConnectorModal: true,
                  selectedRangeId: r.id,
                  selectedConnectorId: connector.id,
                  popupPos: {
                    x: 0,
                    y: e.target.getBoundingClientRect().y,
                  },
                });
              }}
              colors={this.colors}
              onMouseLeave={() =>
                this.mouseHoverConnector(
                  connector.id,
                  this.baseStrokeWidth,
                  this.baseStrokeColorName
                )
              }
              onMouseOver={() =>
                this.mouseHoverConnector(
                  connector.id,
                  this.boldStrokeWidth,
                  this.boldStrokeColorName
                )
              }
            />
          );
        });

        return connectors;
      },
      []
    );

    // get fragment connectors
    rangesBeforeEndOfRow = _.filter(
      this.props.ranges,
      (r) => r.begin < row.end
    );
    _.each(rangesBeforeEndOfRow, (r) => {
      // for each range before end of row, find the next one with the same entity
      let nextEntityRange = _.find(rangesAfterStartOfRow, (r2) => {
        return (
          r2.id !== r.id &&
          r2.entityId === r.entityId &&
          r2.begin > r.begin &&
          r2.begin > row.begin
        );
      });
      let sameEntityRanges = _.filter(this.props.ranges, (r2) => {
        return r2.id !== r.id && r2.entityId === r.entityId;
      });

      if (nextEntityRange) {
        // Get count of same entity ranges between the current one and the target. If its more than 0, skip current.
        let nextEntityRanges = _.filter(sameEntityRanges, (r2) => {
          return r2.begin > r.end && r2.end < nextEntityRange.begin;
        });

        if (nextEntityRanges.length !== 0) {
          return;
        }

        let connector = {
          id: r.entityId,
          targetId: nextEntityRange.id,
          type: "fragment_connector",
          descr: "Fragment",
          isFragment: true,
        };

        let localIdx = connectors.length;
        connectors.push(
          <Connector
            connector={connector}
            r={r}
            localIdx={localIdx}
            rowIdx={rowIdx}
            target={nextEntityRange}
            baseStrokeColorName={this.baseStrokeColorName}
            addRef={(self) => {
              if (this.connectorRefs[rowIdx]) {
                this.connectorRefs[rowIdx][localIdx] = self;
              } else {
                let arr = [];
                arr[localIdx] = self;
                this.connectorRefs[rowIdx] = arr;
              }
            }}
            clickHandler={(e) =>
              this.setState({
                displayConnectorModal: true,
                selectedConnectorId: connector.id,
                selectedRangeId: r.id,
                popupPos: {
                  x: 0,
                  y: e.target.getBoundingClientRect().y,
                },
              })
            }
            colors={this.colors}
            onMouseLeave={() =>
              this.mouseHoverConnector(
                connector.id,
                this.baseStrokeWidth,
                this.baseStrokeColorName
              )
            }
            onMouseOver={() =>
              this.mouseHoverConnector(
                connector.id,
                this.boldStrokeWidth,
                this.boldStrokeColorName
              )
            }
          />
        );
      }
    });

    return connectors;
  }

  mouseHoverLabel(e, width, color) {
    // Handles the mouse hovering over the text on a label.

    let label;
    if (e.target.parentElement.getAttribute("data-id")) {
      label = e.target.parentElement;
    } else if (e.target.parentElement.parentElement.getAttribute("data-id")) {
      label = e.target.parentElement.parentElement;
    } else {
      return;
    }

    const labelId = label.getAttribute("data-id");

    const labels = document.querySelectorAll(
      "g[data-role='labels'] > g[data-id]"
    );
    const connectors = document.querySelectorAll("g[data-role='connectors'] g");

    const matchedConnectors = _.filter(connectors, (conn) => {
      const dataFrom = conn.getAttribute("data-from");
      const dataTo = conn.getAttribute("data-to");

      return dataFrom === labelId || dataTo === labelId;
    });

    const notMatchedConnectors = _.difference(connectors, matchedConnectors);

    const relatedLabels = _.filter(labels, (label) => {
      const dataId = label.getAttribute("data-id");

      return _.some(matchedConnectors, (conn) => {
        const dataFrom = conn.getAttribute("data-from");
        const dataTo = conn.getAttribute("data-to");
        return dataId && (dataFrom === dataId || dataTo === dataId);
      });
    });

    _.each(labels, (label) => {
      const isMatched = _.some(relatedLabels, (relatedLabel) => {
        return label === relatedLabel;
      });
      const dataId = label.getAttribute("data-id");
      if (isMatched || dataId === labelId) {
        if (e.type === "mouseover") {
          _.each(label.childNodes, (child) => {
            child.setAttribute("opacity", "1");
          });
        } else if (e.type === "mouseleave") {
          _.each(label.childNodes, (child) => {
            child.setAttribute("opacity", "1");
          });
        }
      } else {
        if (e.type === "mouseover") {
          _.each(label.childNodes, (child) => {
            child.setAttribute("opacity", "0");
          });
        } else if (e.type === "mouseleave") {
          _.each(label.childNodes, (child) => {
            child.setAttribute("opacity", "1");
          });
        }
      }
    });

    _.each(matchedConnectors, (conn) => {
      conn.firstChild.setAttribute("stroke-width", width);
      conn.firstChild.setAttribute("stroke", this.colors[color]);
      conn.firstChild.setAttribute("marker-end", `url(#arrow-${color})`);
    });

    _.each(notMatchedConnectors, (conn) => {
      if (e.type === "mouseover") {
        _.each(conn.childNodes, (child) => {
          child.setAttribute("opacity", "0.15");
        });
      } else if (e.type === "mouseleave") {
        _.each(conn.childNodes, (child) => {
          child.setAttribute("opacity", "1");
        });
      }
    });
  }

  mouseHoverConnector = (connId, width, color) => {
    // Handles the mouse hovering over the text on a connector.
    let connectors = document.getElementsByClassName(connId);
    _.each(connectors, (conn) => {
      conn.firstChild.setAttribute("stroke-width", width);
      conn.firstChild.setAttribute("stroke", this.colors[color]);
      conn.firstChild.setAttribute("marker-end", `url(#arrow-${color})`);
    });
  };

  positionConnectors() {
    // this function positions connectors so that they do not overlap.
    _.each(this.connectorRefs, (connectorsForRow, rowIdx) => {
      _.each(connectorsForRow, (connector, idx) => {
        // position connectors horizontally, so they match the labels that they connect.
        if (!connector) {
          return;
        }

        let [labelFromRect, rangeFrom, labelToRect, rangeTo] =
          this.getLabelsAndRanges(connector, rowIdx);
        let text = connector.lastChild;
        let [startX, textX] = this.getStartAndTextX(
          text,
          labelFromRect,
          labelToRect,
          rangeFrom,
          rangeTo
        );
        connector.setAttribute("transform", `translate(${startX}, 0)`);
        connector.setAttribute("startX", `${startX}`);
        text.setAttribute("x", textX);
      });

      // get groups of horizontally overlapping connectors
      let levels = [];
      connectorsForRow = _.sortBy(connectorsForRow, (c) =>
        c ? c.getBoundingClientRect().x : null
      );
      _.each(connectorsForRow, (connector) => {
        if (connector) {
          for (let i = 0; i < levels.length; i++) {
            if (
              _.last(levels[i]) &&
              _.last(levels[i]).getBoundingClientRect().right <
                connector.getBoundingClientRect().x
            ) {
              levels[i].push(connector);
              return;
            }
          }
          levels.push([connector]);
        }
      });
      // position connectors vertically so that they do not overlap.
      _.each(levels, (level, levelIdx) => {
        _.each(level, (connector, connectorIdx) => {
          connector.setAttribute(
            "transform",
            `translate(${connector.getAttribute("startX")}, ${
              levelIdx * this.connectorHeight
            })`
          );
          connector.firstChild.setAttribute(
            "d",
            this.getPathForConnector(connector, rowIdx)
          );
        });
      });

      if (this.rowRefs[rowIdx]) {
        // update the height of the component that contains connectors.
        let connectors = _.find(
          this.rowRefs[rowIdx].children,
          (child) => child.getAttribute("data-role") === "connectors"
        );

        connectors.setAttribute(
          "data-content-height",
          levels.length * this.connectorHeight + 5
        );
        if (
          levels.length === 0 ||
          (levels.length === 1 && levels[0].length === 1 && !levels[0][0])
        ) {
          connectors.setAttribute("data-content-height", 0);
        }
      }
    });
  }

  getStartAndTextX(text, labelFromRect, labelToRect, rangeFrom, rangeTo) {
    // Returns the horizontal start position of a label and its text.
    let rightLimit = this.containerRef.current.getBoundingClientRect().x;
    let textWidth = text.getBoundingClientRect().width;
    let textX = 0;
    let startX = 0;

    if (!!labelFromRect && !!labelToRect) {
      // If row contains both labels.
      startX = labelFromRect.x + labelFromRect.width / 2 - rightLimit;
      textX =
        (labelToRect.x +
          labelToRect.width / 2 -
          labelFromRect.x -
          labelFromRect.width / 2) /
          2 -
        textWidth / 2 -
        this.offsetForRowIdx;
    } else if (!!labelFromRect && !labelToRect) {
      // If row contains only the start label.
      startX = labelFromRect.x + labelFromRect.width / 2 - rightLimit;
      if (rangeTo.end < rangeFrom.begin) {
        startX = 0;
        textX =
          labelFromRect.x -
          rightLimit +
          labelFromRect.width / 2 -
          textWidth -
          10 -
          this.offsetForRowIdx;
      }
    } else if (!labelFromRect && !!labelToRect) {
      // If row contains only the end label.
      startX = labelToRect.x + labelToRect.width / 2 - rightLimit;
      if (rangeTo.begin > rangeFrom.end) {
        startX = 0;
        textX = -this.offsetForRowIdx;
      }
      if (rangeTo.end < rangeFrom.begin) {
        textX = -this.offsetForRowIdx + 10;
      }
    }
    // If for does not contain either label (connector is going across the row), text and label start at 0.
    return [startX, textX];
  }

  getPathForConnector(connector, rowIdx, skipHdiff = false) {
    // Returns the string the SVG path uses to draw path elements the d attribute.
    if (!connector) {
      return;
    }

    // This has to be set before the vertical paths, so hey can have a correct height (vertical length).

    let [labelFromRect, rangeFrom, labelToRect, rangeTo, labelTo, labelFrom] =
      this.getLabelsAndRanges(connector, rowIdx);
    let xDiff = this.getXDiff(
      rowIdx,
      labelFromRect,
      rangeFrom,
      labelToRect,
      rangeTo,
      labelTo,
      labelFrom
    );

    let [heightDiff1, heightDiff2] = !skipHdiff
      ? this.getHDiffs(connector, labelFromRect, labelToRect)
      : [0, 0];
    let offset = 5;
    if (xDiff < 0) {
      offset = -offset;
    }
    let path = `
       M ${-this.offsetForRowIdx} ${heightDiff1}
       l ${offset} ${-heightDiff1}
       l ${xDiff - offset} 0
       l ${offset} ${heightDiff2}`;

    if (rangeTo.end < rangeFrom.begin) {
      // If the target range is before the start range.
      if (!labelFromRect || !labelToRect) {
        path = `
         M ${xDiff - offset - this.offsetForRowIdx} ${heightDiff1}
         l ${-offset} ${-heightDiff1}
         l ${-xDiff + 2 * offset + this.offsetForRowIdx} 0
         `; //to force arrow to point to the left
      }
      if (!labelFromRect && !labelToRect) {
        // If connector is going across the row.
        path = `
         M ${xDiff} ${heightDiff1}
         l ${-xDiff} 0
         `;
      }
      if (!labelFromRect && labelToRect) {
        // If connector starts outside the row, and ends in this row.
        path = `
       M ${xDiff - this.offsetForRowIdx} ${heightDiff1}
       l 0 ${-heightDiff1}
       l ${-xDiff + offset} 0
       l ${-offset} ${heightDiff2}`;
      }
    }
    // If target range is after the start range.
    if (rangeTo.end >= rangeFrom.begin) {
      if (!labelFromRect && !labelToRect) {
        // If connector is going across the row.
        path = `
       M ${0} ${heightDiff1}
       l ${xDiff - 2 * this.connectorEndMarkerLen} 0
       `;
      }
      if (!labelFromRect && labelToRect) {
        // If connector starts outside the row, and ends in this row.
        path = `
       M ${0} ${heightDiff1}
       l ${xDiff - offset - this.offsetForRowIdx} 0
       l ${offset} ${heightDiff2}`;
      }
    }

    return path;
  }

  getHDiffs(connector, labelFromRect, labelToRect) {
    // Returns the height differences between the main part of the connector and their start points (labels)
    let heightDiff1 = labelFromRect
      ? labelFromRect.top -
        connector.lastChild.getBoundingClientRect().bottom +
        10
      : 0;
    let heightDiff2 = labelToRect
      ? labelToRect.top - connector.lastChild.getBoundingClientRect().bottom + 3
      : 0;
    if (heightDiff1 < 0 || heightDiff2 < 0) {
      // This is a poor mans fix to force arrows to point down on their first render.
      return [13, 6];
    }
    return [heightDiff1, heightDiff2];
  }

  getLabelsAndRanges(connector, rowIdx) {
    // returns start and end labels, ranges and convenience html elements for a given connector, in a given row.
    let idFrom = connector.getAttribute("data-from");
    let idTo = connector.getAttribute("data-to");

    let labelFrom = _.find(this.labelsRefs[rowIdx], (label) => {
      return !label ? false : label.getAttribute("data-id") === idFrom;
    });
    let labelFromRect = labelFrom ? labelFrom.getBoundingClientRect() : null;
    let rangeFrom = _.find(this.props.ranges, (r) => {
      return r.id === idFrom;
    });

    let labelTo = _.find(this.labelsRefs[rowIdx], (label) => {
      return !label ? false : label.getAttribute("data-id") === idTo;
    });
    let labelToRect = labelTo ? labelTo.getBoundingClientRect() : null;
    let rangeTo = _.find(this.props.ranges, (r) => {
      return r.id === idTo;
    });

    return [labelFromRect, rangeFrom, labelToRect, rangeTo, labelTo, labelFrom];
  }

  getXDiff(
    rowIdx,
    labelFromRect,
    rangeFrom,
    labelToRect,
    rangeTo,
    labelTo,
    labelFrom
  ) {
    // Returns the horizontal distance between the start and end of a connector.
    let parentNode = this.rowRefs[rowIdx];
    let parentNodeRect = parentNode.getBoundingClientRect();
    let containerRect = this.containerRef.current.getBoundingClientRect();
    let xDiff = 0;
    // For various combinations of the existence and position of the start and end ranges and labels there are
    // various equations.
    if (!!labelFromRect && !!labelToRect) {
      xDiff =
        labelToRect.x +
        labelToRect.width / 2 -
        (labelFromRect.x + labelFromRect.width / 2);
    } else {
      if (!labelFromRect && !labelToRect) {
        xDiff = containerRect.width - this.connectorEndMarkerLen; // Accomodate arrow length.
      } else if (!!labelFromRect && !labelToRect) {
        xDiff =
          containerRect.right -
          this.connectorEndMarkerLen -
          labelFromRect.right +
          labelFromRect.width / 2;
        if (rangeTo.end < rangeFrom.begin) {
          xDiff = labelFromRect.x - parentNodeRect.x + labelFromRect.width / 2;
        }
      } else if (!labelFromRect && !!labelToRect) {
        if (rangeTo.end < rangeFrom.begin) {
          xDiff =
            containerRect.right - labelToRect.right + labelToRect.width / 2;
        }
        if (rangeTo.begin > rangeFrom.end) {
          xDiff = labelToRect.x - parentNodeRect.x + labelToRect.width / 2;
        }
      }
    }
    return xDiff;
  }

  mouseMoveHandler = (e) => {
    // Handler for mouse move, used when dragging the new connection arrow.
    let containerRect = this.containerRef.current.getBoundingClientRect();
    let x = e.clientX - containerRect.x - this.arrowStart.x;
    let y = e.clientY - containerRect.y - this.arrowStart.y;
    this.newConnectionPath.current.setAttribute(
      "d",
      `M ${this.arrowStart.x} ${this.arrowStart.y} l ${x} ${y}`
    );
    this.newConnectionPath.current.style.visibility = "visible";
  };

  getRangeTypesWithInheritance = () => {
    return applyInheritance(
      this.props.rangeTypes,
      this.props.widgetConfig.inheritFields
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    let ignoredState = ["draggedRangeId"];

    if (!_.isEqual(nextProps, this.props)) {
      return true;
    }

    for (let field in nextState) {
      if (!_.isEqual(nextState[field], this.state[field]))
        if (!_.includes(ignoredState, field)) {
          return true;
        }
    }

    return false;
  }

  getLabelsForRow = (row, rowIdx) => {
    // Returns labels for ranges in a given row.
    this.labelsRefs = [];
    let ranges = this.getApplicableRanges(row);

    ranges = _.filter(ranges, (r) => {
      return r.id !== "General range";
    });

    let labels = _.map(ranges, (r, idx) => {
      let rangeType = getNode(this.getRangeTypesWithInheritance(), r.type);

      let text = r.name ? r.name : "";
      if (r.errorMessage) {
        text = "ERROR " + text;
      }
      if (r.startedAbove) {
        text = "<- " + text;
      }
      if (r.finishedBelow) {
        text = text + " ->";
      }
      let backgroundColor = "#fff";
      if (rangeType) {
        if (rangeType.color && this.colors[rangeType.color]) {
          backgroundColor = this.colors[rangeType.color];
        } else if (rangeType.color && !this.colors[rangeType.color]) {
          backgroundColor = rangeType.color;
        }
      }

      let svgText = (
        <text className={`label-description`} fontSize="12px">
          {text}
        </text>
      );
      let textObj = !!r.errorMessage ? (
        <Popup trigger={svgText} content={r.errorMessage} />
      ) : (
        svgText
      );

      return (
        <g
          key={idx}
          ref={(self) => {
            if (this.labelsRefs[rowIdx]) {
              this.labelsRefs[rowIdx][idx] = self;
            } else {
              let arr = [];
              arr[idx] = self;
              this.labelsRefs[rowIdx] = arr;
            }
          }}
          data-id={r.id}
          data-idx={idx}
          data-rowidx={rowIdx}
          data-begin={r.begin}
          data-end={r.end}
          onClick={(e) => {
            // On click open modal for the range that the label is for.
            e.preventDefault();
            this.props.updateParentState({
              displayModal: true,
              selectedRangeId: r.id,
              popupPos: {
                x: 0,
                y:
                  e.target.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect()
                    .bottom +
                  window.pageYOffset -
                  450,
              },
            });
          }}
          onMouseDown={(e) => {
            // Start dragging the new connection arrow.
            e.preventDefault();
            this.mouseDownTime = Date.now();
            this.setState({ draggedRangeId: r.id });

            let targetRect = e.target.getBoundingClientRect();
            let containerRect =
              this.containerRef.current.getBoundingClientRect();
            let x = targetRect.x + targetRect.width / 2 - containerRect.x;
            let y = targetRect.y - containerRect.y;
            this.arrowStart = { x, y };

            // this.newConnectionPath.current.style.visibility = 'visible';
            this.containerRef.current.addEventListener(
              "mousemove",
              this.mouseMoveHandler
            );
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            if (Date.now() - this.mouseDownTime < 500) {
              //If you have a better idea on how to differentiate MouseUp MouseDown from OnClick please tell me.
              return;
            }
            if (this.state.draggedRangeId === r.id) {
              return;
            }
            let targetRange = _.find(this.props.ranges, { id: r.id });
            let newRange = _.cloneDeep(
              _.find(this.props.ranges, { id: this.state.draggedRangeId })
            );
            let connected = newRange.connectedTo ? newRange.connectedTo : [];
            let newConnector = {
              id: "connector_" + _.uniqueId(Date.now()),
              targetId: targetRange.id,
            };
            if (this.props.widgetConfig.defaultConnectorType) {
              let defaultType = getNode(
                this.props.widgetConfig.connectorTypes,
                this.props.widgetConfig.defaultConnectorType
              );
              newConnector.type = defaultType.value;
              newConnector.descr = defaultType.title;
            }
            connected.push(newConnector);

            newRange.connectedTo = _.uniq(connected);

            if (
              getAppropriateConnectorTypes(
                newRange,
                targetRange,
                _.cloneDeep(this.props.connectorTypes),
                this.props.rangeTypes
              ).length === 0
            ) {
              // If there are no connector types that can be used, do not create a connection.
              return;
            }

            let ranges = [...this.props.ranges];
            let rangeIdx = _.findIndex(ranges, { id: newRange.id });
            ranges[rangeIdx] = newRange;
            this.props.updateRanges(ranges);

            this.setState({
              ranges,
              displayConnectorModal: true,
              selectedRangeId: newRange.id,
              selectedConnectorId: newConnector.id,
              popupPos: {
                x: 0,
                y:
                  e.target.parentElement.parentElement.getBoundingClientRect()
                    .bottom +
                  window.pageYOffset -
                  450,
              },
            });
          }}
          onMouseLeave={(e) =>
            this.mouseHoverLabel(
              e,
              this.baseStrokeWidth,
              this.baseStrokeColorName
            )
          }
          onMouseOver={(e) => {
            this.mouseHoverLabel(
              e,
              this.boldStrokeWidth,
              this.boldStrokeColorName
            );
          }}
        >
          <g>
            <rect
              x={0}
              y={-13}
              width={20}
              height={12}
              fill={backgroundColor}
              rx="5"
              ry="5"
            />
            {textObj}
          </g>
          <path
            style={{ width: "0" }}
            stroke="green"
            strokeWidth="1"
            fill="none"
            y={10}
            d={`M 0 0`} // Irrelevant, gets overwritten later when adjusting position.
            key={idx}
          />
        </g>
      );
    });
    return labels;
  };

  getRows() {
    // Returns the svg g elements that represent rows. At the moment, they contain: connectors, labels, text, background.
    let rows = this.getRowData(
      this.containerRef.current
        ? this.containerRef.current.getBoundingClientRect().width - 50
        : 0,
      this.props.widgetConfig.breakLinesOnlyOnNewLineCharacters
    );
    this.rowRefs = [];
    this.fragmentsRefs = [];
    this.connectorRefs = [];

    const higestRowDigitLength = String(rows.length - 1).length;
    // Rows are objects with str, begin, end properties.
    let svgRows = _.map(rows, (row, idx) => {
      let rowIdx = null;
      if (idx === 0 || row.dataRowIdx !== rows[idx - 1].dataRowIdx)
        rowIdx = (
          <g data-role="rowNumber" data-content-height="0" vertical-ignore="1">
            <text cursor="default">{`${row.dataRowIdx}${"   ".repeat(
              higestRowDigitLength - String(row.dataRowIdx).length
            )}|`}</text>
          </g>
        );

      return (
        <g
          data-role="row"
          data-row-idx={`${row.dataRowIdx}`}
          ref={(self) => {
            if (self) {
              this.rowRefs.push(self);
            }
          }}
          key={idx}
        >
          <g
            data-role="background"
            data-content-height="0"
            vertical-ignore="1"
            transform="translate(0, 0)"
          >
            <rect
              x="-20"
              y={0}
              width={100}
              height={6}
              fill={`${row.dataRowIdx % 2 === 0 ? "white" : "gray"}`}
              fillOpacity={0.2}
            />
          </g>
          <g data-role="connectors" data-content-height="0">
            {this.getConnectorsForRow(row, idx)}
          </g>
          <g data-role="labels" data-content-height="0">
            {this.getLabelsForRow(row, idx)}
          </g>
          <g data-role="text" data-content-height="17">
            {this.getSplitText(row, idx)}
          </g>

          {rowIdx}
        </g>
      );
    });

    return svgRows;
  }

  mountFragment(fragment, rowIdx, idx) {
    // Adds a text component which is a fragment of the displayed text to refs of the component.
    if (this.fragmentsRefs[rowIdx]) {
      this.fragmentsRefs[rowIdx][idx] = fragment;
    } else {
      let arr = [];
      arr[idx] = fragment;
      this.fragmentsRefs[rowIdx] = arr;
    }
  }

  getSplitText = (row, rowIdx) => {
    // Returns text fragments, split by ranges.
    this.fragmentsRefs = [];
    let rangeTypes = this.getRangeTypesWithInheritance();
    return _.map(row.content, (t, idx) => {
      let extraData = {};
      let ranges = _.cloneDeep(this.props.ranges);
      _.each(ranges, (range) => {
        if (
          t.words[0].begin >= range.begin &&
          _.last(t.words).end <= range.end
        ) {
          let rangeType = getNode(rangeTypes, range.type);
          let color = _.get(rangeType, "color");
          if (color) {
            extraData.filter = `url(#solid-${color})`;
          }
        }
      });
      let text = _.reduce(
        t.words,
        (text, word) => {
          return text + word.text;
        },
        ""
      );
      let begin = t.words[0].begin;
      let end = _.last(t.words).end;
      return (
        <text
          {...extraData}
          key={idx}
          x="0"
          ref={(fragment) => {
            this.mountFragment(fragment, rowIdx, idx);
          }}
          y={0}
          data-begin={begin}
          data-end={end}
          data-idx={idx}
          data-idy={rowIdx}
          data-width={t.width}
          data-offset={t.offset}
        >
          {text}
        </text>
      );
    });
  };

  getApplicableRanges(row) {
    // Returns all ranges that intersect the given row.
    let ranges = _.cloneDeep(this.props.ranges) || [];
    // This widget needs to have at least 1 range to render properly. It should be ignored everywhere.
    ranges.unshift({
      begin: 0,
      end: this.props.originalString ? this.props.originalString.length : 0,
      id: "General range",
    });
    let applicable = _.filter(ranges, (r) => {
      return r.begin < row.end && r.end > row.begin;
    });

    applicable = _.map(applicable, (r) => {
      if (r.begin < row.begin) {
        r.begin = row.begin;
        r.startedAbove = true;
      }
      if (r.end > row.end) {
        r.end = row.end;
        r.finishedBelow = true;
      }
      return r;
    });

    return applicable;
  }

  positionFragmentsHorizontally() {
    // Positions the text fragments horizontally, so they don't overlap.
    _.each(this.fragmentsRefs, (rowFragments, rowIdx) => {
      _.reduce(
        rowFragments,
        (offset, fragment) => {
          if (!fragment) {
            return offset;
          }
          let extraOffset = parseInt(fragment.getAttribute("data-offset"), 10); // Added earlier if label is longer than selected text.
          fragment.setAttribute("x", offset + extraOffset / 2);
          return offset + fragment.getBoundingClientRect().width + extraOffset;
        },
        0
      );
    });
  }

  positionLabels() {
    // Position labels label the text in the range.
    let labelHeight = 0;
    _.each(this.labelsRefs, (labelsInRow, rowIdx) => {
      _.each(labelsInRow, (label, idx) => {
        if (!label || !this.fragmentsRefs[rowIdx]) {
          return;
        }
        let id = label.getAttribute("data-id");
        let range = _.cloneDeep(_.find(this.props.ranges, { id: id }));

        // Find fragments that begin and end with the range. If range boundaries are outside of the row, use first and last fragments.
        let beginFragment = _.find(this.fragmentsRefs[rowIdx], (f) => {
          return f
            ? parseInt(f.getAttribute("data-begin")) === range.begin
            : false;
        });
        let endFragment = _.find(this.fragmentsRefs[rowIdx], (f) => {
          return f ? parseInt(f.getAttribute("data-end")) === range.end : false;
        });

        if (!beginFragment) {
          beginFragment = this.fragmentsRefs[rowIdx][0];
          range.startedAbove = true;
        }
        if (!endFragment) {
          endFragment = _.last(this.fragmentsRefs[rowIdx]);
          range.finishedBelow = true;
        }

        if (!beginFragment || !endFragment) {
          return;
        }

        label.setAttribute(
          "transform",
          `translate(${beginFragment.getAttribute("x")}, 0)`
        );
        label.setAttribute("startX", `${beginFragment.getAttribute("x")}`);

        let labelGraphic = label.childNodes[1];
        // Get the length of the label.
        let x1 = parseFloat(beginFragment.getAttribute("x"));
        let x2 =
          parseFloat(endFragment.getAttribute("x")) +
          endFragment.getBoundingClientRect().width;
        let len = x2 - x1;
        // Set the d parameter of the path element that is that marks the range.
        labelGraphic.setAttribute(
          "d",
          `M 0 10 a 10,10 0 0 1 10,-10 l ${len - 20} 0 a 10,10 0 0 1 10,10`
        );
        if (range.finishedBelow && range.startedAbove) {
          labelGraphic.setAttribute("d", `M 0 0 l ${len} 0`);
        } else if (range.startedAbove) {
          labelGraphic.setAttribute(
            "d",
            `M 0 0 l ${len - 10} 0 a 10,10 0 0 1 10,10`
          );
        } else if (range.finishedBelow) {
          labelGraphic.setAttribute(
            "d",
            `M 0 10 a 10,10 0 0 1 10,-10 l ${len - 10} 0 `
          );
        }

        // Position the text of the label.
        let center = (x2 - x1) / 2;
        let textContainer = label.firstChild;
        let text = textContainer.lastChild;
        let textBackground = textContainer.firstChild;
        let textWidth = text.getBoundingClientRect().width;

        text.setAttribute("x", center - textWidth / 2);
        text.setAttribute("y", -3);
        textBackground.setAttribute("x", center - textWidth / 2 - 2);
        textBackground.setAttribute("width", textWidth + 4);

        labelHeight = label.getBoundingClientRect().height;
      });

      // Position labels vertically. Works exactly the same as for connectors.
      let levels = [];
      _.each(labelsInRow, (label, idx) => {
        if (!!label) {
          for (let i = 0; i < levels.length; i++) {
            if (
              _.last(levels[i]) &&
              !!label &&
              _.last(levels[i]).getBoundingClientRect().right <=
                label.getBoundingClientRect().x
            ) {
              levels[i].push(label);
              return;
            }
          }
          levels.push([label]);
        }
      });
      levels.reverse();

      _.each(levels, (level, levelIdx) => {
        _.each(level, (label) => {
          if (label) {
            label.setAttribute(
              "transform",
              `translate(${label.getAttribute("startX")}, ${
                levelIdx * labelHeight + 6
              })`
            );
          }
        });
      });
      let rowRefs = this.rowRefs[rowIdx];
      if (!rowRefs) {
        return;
      }
      let labels = _.find(
        rowRefs.children,
        (child) => child.getAttribute("data-role") === "labels"
      );
      // Set the content height on the parent component.
      if (!!rowRefs) {
        if (labelsInRow && labelsInRow[0]) {
          labels.setAttribute(
            "data-content-height",
            levels.length * labelHeight
          );
        } else {
          labels.setAttribute("data-content-height", 0);
        }
      }
    });
  }

  getSVGDefs() {
    // Get helper elements used by other SVG elements.
    let markers = _.map(this.colors, (color, key) => (
      <marker
        id={`arrow-${key}`}
        key={`arrow-${key}`}
        markerWidth="10"
        markerHeight="10"
        refX="7"
        refY="3"
        orient="auto"
        markerUnits="userSpaceOnUse"
      >
        <path d="M0,0 L0,6 L9,3 z" fill={color} />
      </marker>
    ));
    markers.push(
      <marker
        id={`drag-arrow`}
        key={`drag-arrow`}
        markerWidth="10"
        markerHeight="10"
        refX="6"
        refY="3"
        orient="auto"
        markerUnits="strokeWidth"
      >
        <path
          d="M0,0 L0,6 L9,3 z"
          fill={this.colors[this.baseStrokeColorName]}
        />
      </marker>
    );

    let filters = _.map(this.colors, (color, key) => (
      <filter
        x="0"
        y="0"
        width="1"
        height="1"
        id={`solid-${key}`}
        key={`solid-${key}`}
      >
        <feFlood floodColor={color} floodOpacity={0.1} />
        <feComposite in="SourceGraphic" />
      </filter>
    ));

    let usedColors = new Set();
    iterateAllNodes(this.props.rangeTypes, (r) => {
      if (r.color) {
        usedColors.add(r.color);
      }
    });
    usedColors = Array.from(usedColors);
    let filters2 = _.map(usedColors, (color) => (
      <filter
        x="0"
        y="0"
        width="1"
        height="1"
        id={`solid-${color}`}
        key={`solid-${color}`}
      >
        <feFlood floodColor={color} floodOpacity={0.4} />
        <feComposite in="SourceGraphic" />
      </filter>
    ));
    filters = filters.concat(filters2);

    filters.push(
      <filter x="0" y="0" width="1" height="1" id="solid" key={`solid`}>
        <feFlood floodColor="green" floodOpacity={0.1} />
        <feComposite in="SourceGraphic" />
      </filter>
    );
    filters = _.uniqBy(filters, "key");

    return (
      <React.Fragment>
        {filters}
        {markers}
      </React.Fragment>
    );
  }

  render() {
    let rows = this.getRows();
    return (
      <Container className="SIOSTRAWidget Widget">
        <div>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "100%" }}
            ref={this.containerRef}
            onMouseUp={this.mouseUpHandler}
          >
            <defs>{this.getSVGDefs()}</defs>
            <text
              style={{ visibility: "collapse", whiteSpace: "pre", height: "0" }}
              ref={this.hiddenSpanRef}
            />
            <text
              className={`label-description`}
              style={{ visibility: "collapse", whiteSpace: "pre", height: "0" }}
              fontSize="12px"
              ref={this.hiddenLabelRef}
            />
            <path
              ref={this.newConnectionPath}
              className={``}
              style={{ visibility: "collapse" }}
              stroke={this.colors[this.baseStrokeColorName]}
              strokeWidth="1"
              fill="none"
              y={0}
              d={`M 0 0`} //irrelevant, gets overwritten later when adjusting position
              markerEnd={`url(#drag-arrow)`}
            />
            {rows}
          </svg>
        </div>
      </Container>
    );
  }
}

export default SIOSTRADisplay;

/* eslint-enable */
