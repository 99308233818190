import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { matchPath, withRouter } from "react-router";
import { Dimmer, Dropdown, Icon, Menu, Transition } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import config from "../../config/config";
import urls from "../../config/frontend_urls";
import Perms from "../../config/frontend_perms";
import { logout } from "../../actions/actions_auth";

import "./TopNavigationBar.css";

export class TopNavigationBar extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.perms = new Perms();
    this.state = {
      openHamburger: false,
      isMobile: window.innerWidth < 890,
    };
    window.addEventListener("resize", this.checkIfMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
  }

  checkIfMobile = () =>
    this.setState(() => ({ isMobile: window.innerWidth < 890 }));

  closeMenu = () => this.setState({ openHamburger: false });

  mapToDropdown = (items, trigger) =>
    items.length > 0 && (
      <Dropdown item trigger={trigger} className="icon">
        <Dropdown.Menu>
          {items.map((item, i) => (
            <Dropdown.Item
              key={i}
              as={Link}
              onClick={this.closeMenu}
              {...item.props}
            >
              {item.content}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );

  mapToMenuItem = (items) =>
    items.map((item, i) => (
      <Menu.Item key={i} as={Link} onClick={this.closeMenu} {...item.props}>
        {item.content}
      </Menu.Item>
    ));

  mapToNav = (items, trigger) =>
    this.state.isMobile || items.length === 1
      ? this.mapToMenuItem(items)
      : this.mapToDropdown(items, trigger);

  renderTasks = () =>
    this.perms.haveAccessTo(urls.TASKS_LIST) && (
      <Menu.Item
        as={Link}
        onClick={this.closeMenu}
        to={this.props.auth.is_staff ? urls.ADMIN_TASK_LIST : urls.TASKS_LIST}
      >
        <Icon name="tasks" />
        Tasks
      </Menu.Item>
    );

  renderUsersAndPermissions = () => {
    const items = [];
    if (this.perms.haveAccessTo(urls.USERS_LIST)) {
      items.push({
        props: {
          to: urls.USERS_LIST,
        },
        content: (
          <>
            <Icon name="users" />
            Users
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.USERS_PERMISSIONS)) {
      items.push({
        props: {
          to: urls.USERS_PERMISSIONS,
        },
        content: (
          <>
            <Icon name="user" />
            Permissions
          </>
        ),
      });
    }
    return this.mapToNav(items, "Users");
  };

  renderReports = () => {
    const items = [];
    if (this.perms.haveAccessTo(urls.REPORTS)) {
      items.push({
        props: {
          to: urls.REPORTS,
        },
        content: (
          <>
            <Icon name="table" />
            Reports
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.REPORTS_DEADLINE)) {
      items.push({
        props: {
          to: urls.REPORTS_DEADLINE,
        },
        content: (
          <>
            <Icon name="clock" />
            Deadlines
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.ADMIN_GENERATE_REPORTS)) {
      items.push({
        props: {
          to: urls.ADMIN_GENERATE_REPORTS,
        },
        content: (
          <>
            <Icon name="download" />
            Report Generator
          </>
        ),
      });
    }
    return this.mapToNav(items, "Reports");
  };

  renderStatistics = () => {
    const items = [];
    if (this.perms.haveAccessTo(urls.TASKS_STATISTICS)) {
      items.push({
        props: {
          to: urls.TASKS_STATISTICS,
        },
        content: (
          <>
            <Icon name="list ul" />
            Tasks statistics
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.USERS_STATISTICS)) {
      items.push({
        props: {
          to: urls.USERS_STATISTICS,
        },
        content: (
          <>
            <Icon name="users" />
            Users statistics
          </>
        ),
      });
    }
    return this.mapToNav(items, "Statistics");
  };

  renderOther = () => {
    const items = [];
    if (this.perms.haveAccessTo(urls.COMPANIES)) {
      items.push({
        props: {
          to: urls.COMPANIES,
        },
        content: (
          <>
            <Icon name="building" />
            Companies
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.DIVISIONS)) {
      items.push({
        props: {
          to: urls.DIVISIONS,
        },
        content: (
          <>
            <Icon name="sitemap" />
            Divisions
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.MODULES)) {
      items.push({
        props: {
          to: urls.MODULES,
        },
        content: (
          <>
            <Icon name="th" />
            Modules
          </>
        ),
      });
    }
    if (this.perms.haveAccessTo(urls.PRICE_TARIFFS)) {
      items.push({
        props: {
          to: urls.PRICE_TARIFFS + "/users",
        },
        content: (
          <>
            <Icon name="money bill alternate" />
            Price Tariffs
          </>
        ),
      });
    }
    return this.mapToNav(items, "Other");
  };

  renderRightMenu = (leftMenuItems) => {
    if (!this.props.auth.is_logged) {
      return (
        <>
          <Menu.Item
            className="ui button login"
            as={Link}
            to={urls.LOGIN}
            onClick={this.closeMenu}
          >
            <Icon name="sign-in" />
            Login
          </Menu.Item>
        </>
      );
    }

    const support = (
      <Menu.Item
        as={Link}
        to={this.props.auth.is_superuser ? urls.ADMIN_SUPPORT : urls.SUPPORT}
        onClick={this.closeMenu}
      >
        <Icon name="headphones" />
        Support
      </Menu.Item>
    );

    const docs = (
      <Menu.Item as={Link} to={urls.DOCUMENTATION_WIDGETS} target="_blank">
        <Icon name="help circle" />
        Docs
      </Menu.Item>
    );

    const admin = (
      <Menu.Item
        link
        href={
          (window.BACKEND_URL || config.BACKEND_URL) +
          config.BACKEND_ADMIN_SUFFIX +
          "?type=frontend"
        }
      >
        <Icon name="setting" />
        Admin
      </Menu.Item>
    );

    const editProfile = {
      props: {
        to: urls.USER_PROFILE.replace(
          ":username",
          this.props.auth.username
        ).replace("/:tabName?", ""),
      },
      content: (
        <>
          <Icon name="user" />
          Edit profile
        </>
      ),
    };

    const changePassword = {
      props: {
        to: urls.PASSWORD_CHANGE,
      },
      content: (
        <>
          <Icon name="key" />
          Change password
        </>
      ),
    };

    const signOut = {
      props: {
        as: undefined,
        onClick: () => {
          this.props.logout(this.props.history);
          this.closeMenu();
        },
      },
      content: (
        <>
          <Icon name="sign-out" />
          Logout
        </>
      ),
    };

    const trigger = (
      <span>
        <Icon name="user" />
        {this.props.auth.username}
      </span>
    );

    const rightMenuItems = (
      <>
        {this.props.auth.is_samsung_employee && support}
        {this.props.auth.is_staff && docs}
        {this.props.auth.is_staff && admin}
        {this.mapToNav([editProfile, changePassword, signOut], trigger)}
      </>
    );

    if (!leftMenuItems) {
      return rightMenuItems;
    } else {
      //hamburger
      return (
        <>
          <Menu.Menu>
            <Menu.Item
              position="right"
              onClick={() =>
                this.setState(({ openHamburger }) => ({
                  openHamburger: !openHamburger,
                }))
              }
            >
              <Icon
                name="sidebar"
                size="big"
                rotated={this.state.openHamburger ? "clockwise" : null}
              />
            </Menu.Item>
          </Menu.Menu>
          <div
            className={`hamburger ${
              this.state.openHamburger ? "openHamburger" : ""
            }`}
          >
            <hr />
            <Menu.Header>Logged in as {this.props.auth.username}</Menu.Header>
            <hr />
            {leftMenuItems}
            <hr />
            {rightMenuItems}
          </div>
        </>
      );
    }
  };

  render() {
    const pathname = _.get(this.props, "location.pathname");
    if (pathname && !!matchPath(pathname, urls.AUDIO_HUNTER_CONFIRM)) {
      return null; // this view has disabled navbar
    }
    const leftMenuItems = (
      <>
        {this.renderTasks()}
        {this.renderUsersAndPermissions()}
        {this.renderReports()}
        {this.renderStatistics()}
        {this.renderOther()}
      </>
    );
    /* eslint-disable no-undef */
    const home = (
      <Menu.Item header position="left" as={Link} to={urls.HOME}>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/svg/logo_basic.svg"}
            alt="DATAHIVE.BUZZ"
          />
        </div>
      </Menu.Item>
    );

    let content;
    if (this.state.isMobile) {
      content = (
        <>
          {home}
          {this.renderRightMenu(leftMenuItems)}
        </>
      );
    } else {
      content = (
        <>
          <Menu.Menu position="left">
            {home}
            {leftMenuItems}
          </Menu.Menu>
          <Menu.Menu position="right">{this.renderRightMenu()}</Menu.Menu>
        </>
      );
    }

    return (
      <>
        <Menu fixed="top" className="centered borderless top-menu">
          {content}
        </Menu>
        {this.state.isMobile && (
          <Transition visible={this.state.openHamburger} duration={300}>
            <Dimmer
              active={this.state.openHamburger}
              onClick={this.closeMenu}
            />
          </Transition>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { logout })(TopNavigationBar)
);
