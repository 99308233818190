import * as actionType from "../actions/types/types_messages";

const initState = {};

export default function(state=initState, action) {
  switch (action.type) {
  case actionType.MESSAGES_NEW:
    if (!action.messages) {
      return state;
    }
    return action.messages;
  case actionType.MESSAGES_CLEAR:
    return initState;
  default:
    return state;
  }
}
