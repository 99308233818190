import React from "react";
import _ from "lodash";
import {Button, Form, Icon, Popup, Table} from "semantic-ui-react";
import PropTypes from "prop-types";

import EvaluatorTask from "./EvaluatorTask";
import TaskComponent from "./TaskComponent";

import "./UserTaskSet.css";
import UserTaskSetBase from "./UserTaskSetBase";

export default class EvaluatorTaskSet extends TaskComponent {
  static propTypes = {
    usersToEvaluation: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.collectionName = "evaluatortasks";
  }

  render() {
    if (!this.props.task) {
      return "";
    }

    const evaluatorTasksData = this.getFilteredUsertasks();
    evaluatorTasksData.push({
      is_active: true,
      tutorial_confirmed: false,
      task: this.props.task.id,
      max_questions: 0,
    });

    const evaluatorTasks = _.map(evaluatorTasksData, (evalt, idx) => {
      return (
        <EvaluatorTask
          key={idx}
          idx={idx}
          usernameOptions={this.state.usernameOptions}
          evaluatorTask={evalt}
          usersToEvaluation={this.props.usersToEvaluation}
          onChange={this.onChange}
          setModified={this.setModified}
          errors={_.get(this.props, `errors.evaluatortasks[${idx}]`)}
          handleCheckboxClick={this.handleCheckboxClick}
          isChecked={this.state.checkedList.includes(evalt.username)}
        />
      );
    });

    const header = (
      <Table.Row>
        <Table.HeaderCell width={1}>
          <Form.Checkbox
            id="all"
            onChange={this.handleSelectAll}
            checked={this.state.isAllChecked}
          />
        </Table.HeaderCell>
        <Table.HeaderCell/>
        <Table.HeaderCell width={5}>User</Table.HeaderCell>
        <Table.HeaderCell>Limit</Table.HeaderCell>
        <Table.HeaderCell>Done</Table.HeaderCell>
        <Table.HeaderCell width={3}>Deadline</Table.HeaderCell>
        <Table.HeaderCell width={2}>Added</Table.HeaderCell>
        <Table.HeaderCell/>
        <Table.HeaderCell width={3}>
          Evaluating
          <Popup
            content={"Leaving empty means that user will be able to evaluate random answers."}
            trigger={<Icon name={"info circle"}/>}
          />
        </Table.HeaderCell>
        <Table.HeaderCell/>
      </Table.Row>
    );

    const stats = (
      <div className={"statistics"}>
        <div className={"export"}>
          <Button
            className="datahive-button"
            content="Export data"
            color="green"
            onClick={this.exportData}
          />
        </div>
        <div className={"resolve"}>
          {this.state.displayUnresolvedReviewsModal && this.renderModal(this.resolveReviews)}
          <Button
            className={"bottom-bar-resolve"}
            content="Resolve discussions"
            color="blue"
            onClick={this.getUnresolvedReviews}
          />
        </div>
        <div className={"filter-button"}>
          <Popup
            on="click"
            position={"bottom center"}
            style={{padding: 0, marginLeft: 100}}
            trigger={
              <Button
                className="datahive-button"
                color="dark-grey"
                onClick={() => {
                  this.setState({popupOpen: true});
                }}>
                Filter
                <Icon name="filter"/>
              </Button>
            }
            content={this.renderFilter()}
            open={this.state.popupOpen}
          />
        </div>
        <span className={"reset-filters-button"} onClick={() => this.setState({filteredOut: []}, () => this.resetFilters())}>Reset all filters</span>

      </div>
    );

    return (
      <UserTaskSetBase
        header={header}
        statistics={stats}
        userTasks={evaluatorTasks}
      />
    );
  }
}
