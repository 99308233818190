import _ from "lodash";

import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import qs from "query-string";
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Popup,
  Grid,
  Label,
} from "semantic-ui-react";

import ajax from "../../../../helpers/ajax";
import config from "../../../../config/config";
import urls from "../../../../config/frontend_urls";
import UserTaskSet from "./UserTaskSet";
import Detail from "./Detail";
import Description from "./Description";
import WidgetsEdit2 from "../../../WidgetsEdit/WidgetsEdit2";
import BottomBar from "../../../../components/BottomBar";
import {
  ERRORS_CLEAR,
  ERRORS_NEW,
} from "../../../../actions/types/types_errors";
import Tabs from "../../../../components/Tabs";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import EvaluatorTaskSet from "./EvaluatorTaskSet";
import CopyWikiModal from "../../CopyWikiModal";
import ReorderTutorials from "../../ReorderTutorials";
import { ChangelogTaskModal } from "../../ChangelogTaskModal";
import ModalConfirm from "../../../../components/ModalConfirm";
import "./TaskAdminDetail.css";
import { getUsers } from "./utils";

const NEW_TASK_TITLE = "";

export class TaskAdminDetail extends React.Component {
  static propTypes = {
    options: PropTypes.object,
    selected: PropTypes.array,
    onChange: PropTypes.func,
    choicesFilter: PropTypes.object,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        taskSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
    errorsClear: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.object,
    getData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.emptyTask = {
      id: null,
      is_brat: false,
      is_active: false,
      max_answers: 1,
      config: {
        structure: [
          {
            componentId: "submit-button",
            componentType: "SubmitWidget",
          },
        ],
      },
      config_evaluation: {
        structure: [
          {
            componentId: "submit-button",
            componentType: "SubmitWidget",
          },
        ],
      },
      description: "",
      task_comment: null,
      title: NEW_TASK_TITLE,
      questions_at_page: 1,
      auto_submit: false,
      possible_empty_answers: false,
      possible_review: false,
      tools: "",
      visual: "",
      kb_shortcuts: "",
      annotation: "",
      slug: NEW_TASK_TITLE,
      wiki_modified: null,
      created: "",
      modified: "",
      is_archive: false,
      status: "NS",
      status_change_timestamp: null,
      owner: _.get(props, "auth.id"),
      module: null,
      users: [],
      moderators: [],
    };

    this.state = {
      displaySpinner: true,
      task: { ...this.emptyTask },
      originalTask: { ...this.emptyTask },
      languages: [],
      modules: [],
      coordinators: [],
      staff: [],
      users: [],
      allUsers: [],
      taskTypes: {},
      totalQuestions: 0,
      usersToEvaluation: [],
      displayCopyWikisModal: false,
      oldTutorials: [],
      usernameOptions: [],
      popupOpen: false,
      updateFields: {},
      displayChangelogTaskModal: false,
      displayReorderTutorialModal: false,
      callback: false,
      displayDeleteModal: false,
    };
    this.getUsers = getUsers.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getErrorText = (errors) => {
    let status = _.get(errors, "response.statusText", "Something went wrong!");
    if (status === "Bad Request") {
      status = errors.response.data.length
        ? errors.response.data
        : "Please fill or fix required fields";
    }
    return status;
  };

  getData = () => {
    const getAllUsers = ajax.get(config.USER_LIST_ALL, {
      params: { include_inactive: true },
    });
    const getStaffUsers = ajax.get(config.USER_DETAILS_USERS_UNPAGINATED, {
      params: { is_staff: true },
    });
    const getModules = ajax.get(config.MODULE_OPTIONS, {
      params: this.state.filters,
    });
    const getLanguages = ajax.get(config.LANGUAGES);
    const getTypes = ajax.get(config.TASK_TYPES);
    const getTasks = ajax.get(config.TASKS);
    this.getUsers();

    if (!this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW)) {
      const getTaskDetail = ajax.get([
        config.ADMIN_TASKS_DETAIL,
        this.props.match.params.taskSlug,
      ]);
      const getQuestionsCount = ajax.get([
        config.ADMIN_QUESTIONS_COUNT_BY_TASK,
        this.props.match.params.taskSlug,
      ]);

      Promise.all([
        getTaskDetail,
        getQuestionsCount,
        getAllUsers,
        getStaffUsers,
        getModules,
        getLanguages,
        getTypes,
        getTasks,
      ]).then((values) => {
        // Options for evaluator drag'n'drop.
        const usersToEvaluation = values[2].data.filter((user) =>
          values[0].data.users.includes(user.id)
        );
        this.setState({
          displaySpinner: false,
          task: values[0].data,
          originalTask: values[0].data,
          is_active: values[0].data.is_active,
          totalQuestions: values[1].data.questions_count,
          users: _.filter(values[2].data, "is_active"),
          allUsers: values[2].data,
          coordinators: values[3].data,
          staff: values[3].data,
          modules: values[4].data,
          languages: values[5].data,
          taskTypes: values[6].data,
          tasks: values[7].data,
          usersToEvaluation,
          oldTutorials: values[0].data.tutorial,
        });
      });
    } else {
      Promise.all([
        getAllUsers,
        getStaffUsers,
        getModules,
        getLanguages,
        getTypes,
        getTasks,
      ]).then((values) =>
        this.setState({
          displaySpinner: false,
          users: _.filter(values[0].data, "is_active"),
          allUsers: values[0].data,
          coordinators: values[1].data,
          staff: values[1].data,
          modules: values[2].data,
          languages: values[3].data,
          taskTypes: values[4].data,
          tasks: values[5].data,
        })
      );
    }
  };

  getUsersToEvaluation = () => {
    ajax
      .get(config.USER_LIST_ALL, { params: { include_inactive: true } })
      .then((response) => {
        // Options for evaluator drag'n'drop.
        const usersToEvaluation = response.data.filter((user) =>
          this.state.task.users.includes(user.id)
        );
        this.setState({ usersToEvaluation });
      });
  };

  delete = () => {
    this.props.errorsClear();
    ajax
      .delete([config.ADMIN_TASKS_DETAIL, this.props.match.params.taskSlug])
      .then(() => {
        this.props.history.push(urls.ADMIN_TASK_LIST);
        toastr.success("Success!", "Task removed!");
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
        this.setState({ errors: errors.response.data });
      });
  };

  updateFavorites = (slug, isFavorite, pinColor) => {
    const text = !isFavorite ? "added to" : "removed from";
    ajax
      .post([config.TASKS_UPDATE_FAVORITES, slug, pinColor])
      .then(() => {
        this.setState((prevState) => ({
          task: {
            ...prevState.task,
            is_favorite: !isFavorite,
            favorites_pin_color: pinColor,
          },
        }));
        toastr.success("Success!", `Task ${text} favorites!`);
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
      });
  };

  saveNew = (data, callback, shouldStayOnSameTab) => {
    ajax
      .post(config.ADMIN_TASKS, { data })
      .then((response) => {
        this.setState({
          displaySpinner: false,
          task: response.data,
          oldTutorials: response.data.tutorial,
          errors: null,
          displayChangelogTaskModal: false,
        });
        toastr.success("Success!", "Task saved!");

        if (callback) {
          callback();
        } else {
          const url = urls.ADMIN_TASK_DETAIL_TAB;
          if (shouldStayOnSameTab) {
            const currentTab = this.props.location.pathname
              .split("/")
              .slice(-1)[0];
            url.replace(":subTab", currentTab);
          }
          this.props.history.push(url.replace(":taskSlug", response.data.slug));
        }
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
        this.setState({
          displaySpinner: false,
          errors: errors.response.data,
        });
      });
  };

  saveUpdate = (data, callback) => {
    const oldSlug = this.props.match.params.taskSlug;
    delete data["favorites"];
    ajax
      .patch([config.ADMIN_TASKS_DETAIL, oldSlug], { data })
      .then((response) => {
        this.setState({
          displaySpinner: false,
          task: response.data,
          oldTutorials: response.data.tutorial,
          errors: null,
          displayChangelogTaskModal: true,
          updateFields: response.data["update_fields"],
          callback: callback,
        });
        toastr.success("Success!", "Task saved!");

        if (oldSlug !== response.data.slug) {
          const pathname = this.props.location.pathname.replace(
            oldSlug,
            response.data.slug
          );
          this.props.history.replace(pathname);
        }
        this.getUsersToEvaluation();
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
        this.setState({
          displaySpinner: false,
          errors: errors.response?.data,
        });
      });
  };

  saveData = (callback, clone = false) => {
    this.setState({ displaySpinner: true });
    let data = _.cloneDeep(this.state.task);
    let canProceed = true;

    const validate = (path) => {
      data[path] = _.filter(data[path], (o) => {
        if (!o.toBeDeleted) {
          return true;
        } else if (o.toBeDeleted && o.done > 0) {
          canProceed = false;
        }
      });
    };
    if (!clone) {
      validate("usertasks");
      validate("evaluatortasks");
    }

    const descriptionValidation = document.createElement("div");
    descriptionValidation.innerHTML = this.state.task.description;
    // first condition is needed cose tests...
    if (descriptionValidation.innerText !== undefined) {
      //check if description editor contains only html tags or spaces
      if (descriptionValidation.innerText.trim().length === 0) {
        //if so, replace it with empty string so backend can return errors correctly
        data.description = "";
      }
    }

    this.props.errorsClear();

    if (!canProceed && !clone) {
      this.setState({ displaySpinner: false }, () =>
        toastr.error(
          "Error!",
          "You cannot unassign user who has answered any question."
        )
      );
    } else if (
      this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW)
    ) {
      this.saveNew(data, callback, true);
    } else if (clone) {
      delete data.end_date;
      this.saveNew(data, callback);
    } else {
      this.saveUpdate(data, callback);
    }
  };

  changeUsertasks = (usertasks) => {
    this.setState({
      task: {
        ...this.state.task,
        usertasks,
      },
    });
  };

  changeEvaluatorTasks = (evaluatortasks) => {
    this.setState({
      task: {
        ...this.state.task,
        evaluatortasks,
      },
    });
  };

  isDiffInTutorials = () => {
    if (
      JSON.stringify(this.state.oldTutorials) ===
      JSON.stringify(this.state.task.tutorial)
    ) {
      this.setState({ displayCopyWikisModal: true });
    } else {
      this.setState({ popupOpen: true });
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      displayDeleteModal: !prevState.displayDeleteModal,
    }));
  };

  reorderTutorials = (data) => {
    ajax
      .post([config.REORDER_WIKI, this.props.match.params.taskSlug], { data })
      .then(() => {
        this.setState({ displayReorderTutorialModal: false });
        return toastr.success("Success: ", "Tutorials order has been saved!");
      })
      .catch((error) => {
        return toastr.error("Error: ", error);
      });
  };

  render() {
    let slug = {
      title: this.state.task.title,
    };
    let task = qs.stringify(slug);
    const setting_errors = _.cloneDeep(this.state.errors) || {};
    delete setting_errors.config;
    delete setting_errors.description;

    if (this.state.displaySpinner) {
      return (
        <LoaderSpinner className={"task-detail-spinner"} minHeight={"400px"} />
      );
    }

    const settings = (
      <Detail
        task={this.state.task}
        users={this.state.users}
        allUsers={this.state.allUsers}
        coordinators={this.state.coordinators}
        staff={this.state.staff}
        modules={this.state.modules}
        languages={this.state.languages}
        updateTask={(task) => this.setState({ task })}
        errors={this.state.errors}
        taskTypes={this.state.taskTypes}
      />
    );

    const settingsPane = {
      key: "settings",
      customClass: `${_.keys(setting_errors).length > 0 ? "tab-errors" : ""}`,
      label: { name: "Settings" },
      components: settings,
    };

    const description = (
      <Description
        description={this.state.task.description}
        faq={this.state.task.faq}
        tutorial={this.state.task.tutorial}
        isEvaluationEnable={this.state.task.enable_evaluations}
        updateTask={(newVal) => {
          this.setState({
            task: {
              ...this.state.task,
              ...newVal,
            },
          });
        }}
        errors={this.state.errors}
      />
    );
    const tabDescriptionError =
      !!_.get(this.state.errors, "description") ||
      !_.isEmpty(_.get(this.state.errors, "tutorial")) ||
      !_.isEmpty(_.get(this.state.errors, "faq"));
    const descriptionPane = {
      key: "description",
      customClass: `${tabDescriptionError ? "tab-errors" : ""}`,
      label: { name: "Description" },
      components: description,
    };
    const docsURL = urls.DOCUMENTATION_WIDGETS;
    const docs = (
      <div>
        <a href={docsURL} className="redirect-to-admin">
          Documentation about widgets
        </a>
      </div>
    );
    const widgetsEdit = (
      <React.Fragment>
        {docs}
        <WidgetsEdit2
          widgetConfig={this.state.task.config}
          onChange={(config) => {
            this.setState({
              task: {
                ...this.state.task,
                config: config,
              },
            });
          }}
        />
      </React.Fragment>
    );
    const configPane = {
      key: "widgets",
      customClass: `${_.get(this.state.errors, "config") ? "tab-errors" : ""}`,
      label: { name: "Widgets" },
      components: widgetsEdit,
    };
    const userTasks = (
      <UserTaskSet
        task={this.state.task}
        originalTask={this.state.originalTask}
        errors={this.state.errors}
        updateTask={(task) => {
          this.setState({ task });
        }}
        location={this.props.location}
        allUsers={this.state.usernameOptions ? this.state.usernameOptions : []}
        totalQuestions={this.state.totalQuestions}
        onChange={this.changeUsertasks}
      />
    );
    const usersPane = {
      key: "users",
      customClass: `${_.get(this.state.errors, "users") ? "tab-errors" : ""}`,
      label: { name: "Users" },
      components: userTasks,
    };

    const panes = [settingsPane, descriptionPane, configPane];

    if (this.state.task.enable_evaluations) {
      const configEvaluation = (
        <WidgetsEdit2
          widgetConfig={this.state.task.config_evaluation}
          onChange={(config) => {
            this.setState({
              task: {
                ...this.state.task,
                config_evaluation: config,
              },
            });
          }}
        />
      );
      const configEvaluationPane = {
        key: "widgets-evaluation",
        customClass: `${
          _.get(this.state.errors, "config-evaluation") ? "tab-errors" : ""
        }`,
        label: { name: "Evaluation" },
        components: configEvaluation,
      };

      const evaluatorTasks = (
        <EvaluatorTaskSet
          task={this.state.task}
          originalTask={this.state.originalTask}
          usersToEvaluation={this.state.usersToEvaluation}
          errors={this.state.errors}
          updateTask={(task) => {
            this.setState({ task });
          }}
          location={this.props.location}
          totalQuestions={this.state.totalQuestions}
          onChange={this.changeEvaluatorTasks}
        />
      );
      const evaluatorsPane = {
        key: "evaluators",
        customClass: `${
          _.get(this.state.errors, "evaluators") ? "tab-errors" : ""
        }`,
        label: { name: "Evaluators" },
        components: evaluatorTasks,
      };

      panes.push(...[configEvaluationPane, usersPane, evaluatorsPane]);
    } else {
      panes.push(usersPane);
    }

    // generate url props for tabs component
    const taskSlug = this.props.match.params.taskSlug || this.state.task.slug;
    const baseUrl = this.props.location.pathname.includes(
      urls.ADMIN_TASK_DETAIL_NEW
    )
      ? urls.ADMIN_TASK_DETAIL_NEW_TAB
      : urls.ADMIN_TASK_DETAIL_TAB;
    const taskUrl = baseUrl.replace(":taskSlug", taskSlug);
    // push tab specific urls to panes array
    panes.forEach((pane) => {
      pane.url = taskUrl.replace(":subTab", "");
      pane.tabUrl = taskUrl.replace(":subTab", pane.key);
    });

    const buttonLeft = (
      <Button
        className="bottom-bar-delete datahive-button"
        content="Delete task"
        color="red"
        onClick={this.toggleModal}
      />
    );
    const buttonRight = (
      <>
        <div className={"bottom-right"}>
          <Button
            className={"bottom-bar-save bottom-bar-save-as-new datahive-button"}
            content="Save as new"
            color="blue"
            onClick={() => this.saveData(null, true)}
          />
        </div>
        <div className={"bottom-right"}>
          <Button
            className={"bottom-bar-save bottom-bar-save-cont datahive-button"}
            content="Save and continue editing"
            color="blue"
            key={"bottom-bar-save"}
            onClick={() => this.saveData()}
          />
        </div>
        <div className={"bottom-right"}>
          <Button
            key={"bottom-bar-save-another"}
            className={
              "bottom-bar-save bottom-bar-save-another datahive-button"
            }
            content="Save and add another"
            color="blue"
            onClick={() =>
              this.saveData(() => {
                this.setState({ task: this.emptyTask }, () =>
                  this.props.history.push(urls.ADMIN_TASK_DETAIL_NEW)
                );
              })
            }
          />
        </div>
        <div className={"bottom-right"}>
          <Button
            className={"bottom-bar-save bottom-bar-save-return datahive-button"}
            content="Save"
            color="green"
            onClick={() =>
              this.saveData(() => this.props.history.push(urls.ADMIN_TASK_LIST))
            }
          />
        </div>
      </>
    );

    let { wikis, tutorials, evalTutorials } = [];
    if (!this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW)) {
      if (this.state.task.tutorial && this.state.task.faq) {
        wikis = this.state.task.tutorial.concat(this.state.task.faq);
        wikis = wikis.filter((item) => "id" in item);
      }
      if (this.state.task.tutorial) {
        tutorials = this.state.task.tutorial.filter(
          (item) => item.for_evaluators === false
        );
        evalTutorials = this.state.task.tutorial.filter(
          (item) => item.for_evaluators === true
        );
      }
    }

    const title = "Are you sure you want to delete this task?";
    const buttons = (
      <>
        <Button
          onClick={this.delete}
          content="I'm aware of the consequences"
          color="red"
          className="datahive-button"
          id={"delete"}
          floated={"left"}
        />
        <Button
          onClick={this.toggleModal}
          className="datahive-button"
          color="dark-grey"
          content="Cancel"
        />
      </>
    );
    const content = (
      <p>
        Task: <strong>{this.state.task.slug}</strong> and its related items will
        be deleted
      </p>
    );
    const favorites_pin_colors = [
      "db244d",
      "f85e00",
      "fa9500",
      "fbbd08",
      "a5be00",
      "2e933c",
      "028090",
      "1d4e89",
      "6f2dbd",
      "000000",
    ];
    return (
      <div className={"TaskAdminDetail"}>
        <Header size="large">
          <div className="header-heading-area">
            <Icon
              name={this.state.is_active ? "check" : "close"}
              size={"large"}
              className="active-icon"
            />
            {_.get(this.state, "task.title") || "new task"}
            {this.state.task.enable_evaluations ? (
              <Label circular color="grey" size="large">
                eval
              </Label>
            ) : undefined}
            <Checkbox
              className={"green is-active"}
              label={"is active"}
              checked={this.state.task.is_active}
              onChange={(e, data) =>
                this.setState({
                  task: {
                    ...this.state.task,
                    is_active: data.checked,
                  },
                })
              }
            />
            {this.state.task.is_favorite ? (
              <Button
                icon="pin"
                className="favorites"
                style={{
                  color: this.state.task.is_favorite
                    ? `#${this.state.task.favorites_pin_color}`
                    : "#D8D8D8",
                }}
                onClick={() =>
                  this.updateFavorites(
                    this.state.task.slug,
                    this.state.task.is_favorite,
                    "fbbd08"
                  )
                }
              />
            ) : (
              <Popup
                content={favorites_pin_colors.map((color) => (
                  <Button
                    key={color}
                    icon="pin"
                    className="pin-color-item"
                    style={{ color: `#${color}` }}
                    onClick={() =>
                      this.updateFavorites(
                        this.state.task.slug,
                        this.state.task.is_favorite,
                        color
                      )
                    }
                  />
                ))}
                position="right center"
                on="click"
                pinned
                trigger={
                  <Button
                    icon="pin"
                    className="favorites"
                    style={{
                      color: this.state.task.is_favorite
                        ? `#${this.state.task.favorites_pin_color}`
                        : "#D8D8D8",
                    }}
                  />
                }
              />
            )}
          </div>
          <div>
            {!this.props.location.pathname.includes(
              urls.ADMIN_TASK_DETAIL_NEW
            ) && (
              <>
                <Popup
                  on="click"
                  position={"bottom center"}
                  disabled={!this.state.popupOpen}
                  trigger={
                    <Button
                      basic
                      content={"Copy wiki"}
                      onClick={this.isDiffInTutorials}
                    />
                  }
                >
                  <Grid centered>
                    <Grid.Column textAlign="center">
                      <Header as="h4">Notice</Header>
                      <p>You have unsaved changes in Tutorials.</p>
                      <p>Please save changes in task before proceeding.</p>
                    </Grid.Column>
                  </Grid>
                </Popup>
                <Button
                  basic
                  content="Reorder Tutorial"
                  onClick={() =>
                    this.setState({ displayReorderTutorialModal: true })
                  }
                />
                <Link
                  to={
                    urls.ADD_USERS.replace(":usersRole", "users") + "?" + task
                  }
                >
                  <Button basic>Add many users</Button>
                </Link>
                <Link to={urls.TASK.replace(":taskSlug", this.state.task.slug)}>
                  <Button basic>View on site</Button>
                </Link>
              </>
            )}
            <Link to={urls.ADMIN_TASK_LIST}>
              <Button basic>Tasks list</Button>
            </Link>
          </div>
        </Header>
        <Tabs className={"content"} panes={panes} />
        <div className={"bottom-bar-spacer"} />
        <BottomBar panelRight={buttonRight} panelLeft={buttonLeft} />
        {!this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW) &&
          this.state.displayCopyWikisModal && (
          <CopyWikiModal
            title="Copy wiki"
            onClose={() => this.setState({ displayCopyWikisModal: false })}
            tasks={this.state.tasks.filter(
              (item) => item.id !== this.state.task.id
            )}
            wikis={wikis}
          />
        )}
        {!this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW) &&
          this.state.displayReorderTutorialModal && (
          <ReorderTutorials
            title="Reorder tutorials"
            onClose={() =>
              this.setState({ displayReorderTutorialModal: false })
            }
            tutorials={tutorials}
            evalTutorials={evalTutorials}
            reorderTutorials={(data) => this.reorderTutorials(data)}
          />
        )}
        {!this.props.location.pathname.includes(urls.ADMIN_TASK_DETAIL_NEW) &&
          this.state.displayChangelogTaskModal && (
          <ChangelogTaskModal
            title="Changelog"
            onClose={() => {
              this.setState({ displayChangelogTaskModal: false });
              if (this.state.callback) {
                this.state.callback();
              }
            }}
            updateFields={this.state.updateFields}
            task={this.state.task}
            allUsers={this.state.allUsers}
            modules={this.state.modules}
            callback={this.state.callback}
            getData={this.getData}
          />
        )}
        {this.state.displayDeleteModal && (
          <ModalConfirm
            id={"deleteTaskModal"}
            title={title}
            content={content}
            closeModal={this.toggleModal}
            button={buttons}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) =>
      dispatch({
        type: ERRORS_NEW,
        errors: message,
      }),
    errorsClear: () =>
      dispatch({
        type: ERRORS_CLEAR,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAdminDetail);
