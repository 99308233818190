import React from "react";
import _ from "lodash";
import {Icon} from "semantic-ui-react";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import propTypes from "prop-types";

import DraggableSegmentCorner from "./DraggableSegmentCorner";

export default class Segment extends React.Component {
  static propTypes = {
    idx: propTypes.number,
    updateSegment: propTypes.func,
    segment: propTypes.object,
    remove: propTypes.func
  };

  constructor(props) {
    super(props);
    this.xOffset = -6;
    this.yOffset = -9;

    this.state = {
      corner1Pos: {x: this.props.segment.x + this.xOffset, y: this.props.segment.y + this.yOffset},
      corner2Pos: {x: this.props.segment.x + this.xOffset + this.props.segment.width,
        y: this.props.segment.y + this.yOffset + this.props.segment.height}
    };
    // header of WidgetEditor
    this.header = "ImageSegmentationWidget";
    this.rectangle = React.createRef();
  }

  corner1Stop = (e, position) => {
    this.setState({corner1Pos: position});

    this.props.updateSegment({
      ...this.props.segment,
      x: position.x - this.xOffset,
      y: position.y - this.yOffset,
      width: this.state.corner2Pos.x - position.x,
      height: this.state.corner2Pos.y - position.y,
    },
    this.props.idx);
  };

  corner2Stop = (e, position) => {
    this.setState({corner2Pos: position});

    this.props.updateSegment({
      ...this.props.segment,
      x: this.state.corner1Pos.x - this.xOffset,
      y: this.state.corner1Pos.y - this.yOffset,
      width: position.x - this.state.corner1Pos.x,
      height: position.y - this.state.corner1Pos.y,
    },
    this.props.idx);
  };

  getRectanglePos = () => {
    return {
      left: _.min([this.state.corner1Pos.x, this.state.corner2Pos.x]) - this.xOffset,
      top: _.min([this.state.corner1Pos.y, this.state.corner2Pos.y]) - this.yOffset,
      width: Math.abs(this.state.corner2Pos.x - this.state.corner1Pos.x),
      height: Math.abs(this.state.corner2Pos.y - this.state.corner1Pos.y),
    };
  };

  render() {
    let seg = this.props.segment;

    return (
      <React.Fragment>
        <DraggableSegmentCorner
          initialX={seg.x + this.xOffset}
          initialY={seg.y + this.yOffset}
          onDragStop={this.corner1Stop}
          onDrag={(e, position) => this.setState({corner1Pos: position})}
        >
          <Icon color={"green"} name="expand"/>
        </DraggableSegmentCorner>
        <DraggableSegmentCorner
          initialX={seg.x+seg.width+this.xOffset}
          initialY={seg.y+seg.height+this.yOffset}
          onDragStop={this.corner2Stop}
          onDrag={(e, position) => this.setState({corner2Pos: position})}
        >
          <Icon color={"red"} name="expand"/>
        </DraggableSegmentCorner>
        <ContextMenuTrigger
          holdToDisplay={-1}
          id={`context-menu-${this.props.idx}`}> {/* yeah its stupid, elements find eachother by having the same id... */}
          <div
            ref={this.rectangle}
            className={"segment-rectangle"}
            style={this.getRectanglePos()}
          >
            {this.props.segment.type}
          </div>
        </ContextMenuTrigger>
        <ContextMenu id={`context-menu-${this.props.idx}`}>
          <MenuItem
            data={{"remove": this.props.idx}}
            onClick={this.props.remove}
          >
            Remove segment
          </MenuItem>
        </ContextMenu>
      </React.Fragment>
    );
  }

}
