import React from "react";
import PropTypes from "prop-types";
import {Header, Form} from "semantic-ui-react";

const compareOptions = [
  {
    key: "None",
    text: "None",
    value: null
  },
  {
    key: "Compare",
    text: "Compare",
    value: "compare"
  },
  {
    key: "Compare different",
    text: "Compare different",
    value: "compare_different"
  },
  {
    key: "Compare users",
    text: "Compare users",
    value: "compare_users"
  },
];

const CompareFilter = ({filters, updateFilters, possibleFilterValuesUsers}) => <>
  <Header size="tiny">Compare filter</Header>
  <Form size="mini">
    <Form.Field>
      <Form.Dropdown
        placeholder="None"
        fluid selection
        options={compareOptions}
        value={filters.compare}
        onChange={(e, data) => updateFilters({
          compare: data.value,
          compare_user1: null,
          compare_user2: null
        })}
      />
    </Form.Field>
    {filters.compare === "compare_users" && <>
      <Form.Field>
        <label>User 1</label>
        <Form.Dropdown
          placeholder="None"
          fluid search selection
          options={possibleFilterValuesUsers}
          value={filters.compare_user1 || []}
          onChange={(e, data) => updateFilters({compare_user1: data.value})}
        />
      </Form.Field>
      <Form.Field>
        <label>User 2</label>
        <Form.Dropdown
          placeholder="None"
          fluid search selection
          options={possibleFilterValuesUsers}
          value={filters.compare_user2 || []}
          onChange={(e, data) => updateFilters({compare_user2: data.value})}
        />
      </Form.Field>
    </>}
  </Form>
</>;

CompareFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  possibleFilterValuesUsers: PropTypes.array.isRequired
};

export default CompareFilter;
