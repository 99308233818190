import React, {Component} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import "./Home.css";
import "../style/colors.css";

import store from "../store/store";
import urls from "../config/frontend_urls";


class Ele extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isStaff = store.getState().auth.is_staff;
    const coordinator = store.getState().auth.is_company_coordinator;
    const url = isStaff || coordinator ? urls.REPORTS_LIST : urls.USER_REPORT;

    return (
      <Redirect to={url}/>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.auth};
}

export default withRouter(connect(mapStateToProps)(Ele));
