import _ from "lodash";
import React, {useCallback} from "react";
import {HexColorPicker} from "react-colorful";
import "react-colorful/dist/index.css";
import {Input, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";

import "./ColorPicker.css";


const ColorPicker = ({color, onColorChange}) => {
  const handleSetColor = useCallback((newColor) => {
    if (/^#[0-9A-F]{0,6}$/i.test(newColor)) {
      onColorChange(newColor);
    }
  }, [onColorChange]);

  const debouncedSetColor = _.debounce(handleSetColor, 200, {leading: false, trailing: true});

  return <div className="ColorPickerContainer">
    <Popup
      hoverable
      trigger={
        <Input
          style={{backgroundColor: color}}
          className="color-input"
          placeholder="Color"
          size="mini"
          type="text"
          value={color}
          onChange={(e, {value}) => handleSetColor(value)}
        />
      }
      content={
        <HexColorPicker
          color={color}
          onChange={debouncedSetColor}
        />
      }
    />
  </div>;
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired
};

export default ColorPicker;
