import React, {Component} from "react";
import {withRouter, Redirect} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import {AuthView} from "./AuthView";
import {withResetToken} from "./withResetToken";
import urls from "../../config/frontend_urls";
import PasswordChangeForm from "./PasswordChangeForm";
import PasswordRequirements from "./PasswordRequirements";
import {
  MAX_PASSWORD_LENGTH,
  FIELD_ERRORS,
  TOO_LONG_ERRORS,
  BACKEND_ERROR_TYPES
} from "./utils";

import "./PasswordChange.css";

/**
 * Allows users to change their password. There are two ways this can be done
 * 1. Logged in user can freely update their password
 * 2. Unauthenticated user can change password only if the url contains a valid
 *    password reset token, which is verified by the 'withResetToken' HOC
 */

export class PasswordChange extends Component {
  static propTypes = {
    token: PropTypes.string,
    history: PropTypes.object,
    auth: PropTypes.object,
    location: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      password: "",
      password2: "",
      message: [],
      checkedErrors: Array(6).fill(false),
      newPasswordsMatch: true,
      newPasswordSameAsOld: false,
      loadAnimations: false,
      errorNewPassword: "",
      errorVerifyPassword: "",
      errorOldPassword: "",
      disableFormButton: false
    };
  }

  handlePasswordChange = () => {
    const {
      newPasswordsMatch,
      newPasswordSameAsOld,
      password,
      oldPassword,
      password2,
    } = this.state;

    let foundError = false;
    let checkedErrors = this.state.checkedErrors.slice();
    checkedErrors[0] = !!oldPassword && !!password && !!password2;

    if (checkedErrors.includes(false)) {
      if (!checkedErrors[0]) {
        if (!password) {
          this.setState({errorNewPassword: FIELD_ERRORS.FIELD_REQUIRED});
        }
        if (!password2) {
          this.setState({errorVerifyPassword: FIELD_ERRORS.FIELD_REQUIRED});
        }
        if (!oldPassword) {
          this.setState({errorOldPassword: FIELD_ERRORS.FIELD_REQUIRED});
        }
      }
      if (checkedErrors.slice(1, checkedErrors.length).includes(false)) {
        this.setState({errorNewPassword: FIELD_ERRORS.PASSWORD_TOO_WEAK});
      }

      foundError = true;
    }

    if (!newPasswordsMatch) {
      this.setState({errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH});
      foundError = true;
    }

    if (newPasswordSameAsOld) {
      this.setState({errorNewPassword: FIELD_ERRORS.PASSWORD_SAME_AS_OLD});
      foundError = true;
    }

    if (password.length > MAX_PASSWORD_LENGTH) {
      this.setState({errorNewPassword: TOO_LONG_ERRORS.PASSWORD});
      foundError = true;
    }

    if (oldPassword.length > MAX_PASSWORD_LENGTH) {
      this.setState({errorOldPassword: TOO_LONG_ERRORS.PASSWORD});
      foundError = true;
    }

    if (foundError) {
      return;
    }

    const data = {
      old_password: this.state.oldPassword,
      password: this.state.password
    };

    const {history} = this.props;
    const requestUrl = this.props.auth.username ? config.UPDATE_PASSWORD_TOKEN : config.UPDATE_PASSWORD;

    ajax.post(requestUrl, {data})
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Success", "Password saved!");
          this.setState({
            oldPassword: "",
            password: "",
            password2: "",
            disableFormButton: true
          });
          setTimeout(() => {
            this.props.auth.username ? history.push(urls.HOME) : history.push(urls.LOGIN);
          }, 3000);
        }
      })
      .catch((error) => {
        const {data} = error.response;

        if (data.detail) {
          this.setState({message: [data.detail]});
        } else if (!data.message) {
          let new_message = [];
          for (let d of data) {
            if (d.type === BACKEND_ERROR_TYPES.OLD_PASSWORD_ERROR) {
              this.setState({errorOldPassword: d.message});
            } else if (d.type === BACKEND_ERROR_TYPES.PASSWORD_ERROR) {
              this.setState({errorNewPassword: d.message});
            } else if (d.message) {
              new_message.push(d.message);
            }
          }
          this.setState({message: new_message});
        } else {
          this.setState({message: [data.message]});
        }

        if (data.non_field_errors) {
          toastr.error("Error", data.non_field_errors[0]);
          setTimeout(() => {
            history.push(urls.LOGIN);
          }, 3000);
        }
      });
  };

  handlePasswordReset = () => {
    const {token} = this.props;

    const {
      newPasswordsMatch,
      password,
      password2,
    } = this.state;

    let foundError = false;
    let checkedErrors = this.state.checkedErrors.slice();
    checkedErrors[0] = !!password && !!password2;

    if (checkedErrors.includes(false)) {
      if (!checkedErrors[0]) {
        if (!password) {
          this.setState({errorNewPassword: FIELD_ERRORS.FIELD_REQUIRED});
        }
        if (!password2) {
          this.setState({errorVerifyPassword: FIELD_ERRORS.FIELD_REQUIRED});
        }
      }
      if (checkedErrors.slice(1, checkedErrors.length).includes(false)) {
        this.setState({errorNewPassword: FIELD_ERRORS.PASSWORD_TOO_WEAK});
      }

      foundError = true;
    }

    if (!newPasswordsMatch) {
      this.setState({errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH});
      foundError = true;
    }

    if (password.length > MAX_PASSWORD_LENGTH) {
      this.setState({errorNewPassword: TOO_LONG_ERRORS.PASSWORD});
      foundError = true;
    }

    if (foundError) {
      return;
    }

    ajax.post(config.PASSWORD_RESET_CONFIRM, {
      data: {
        password,
        token
      }
    })
      .then(() => {
        this.setState({disableFormButton: true});
        toastr.success(
          "Success",
          "Password changed! You will be redirected to the login page in 5 seconds.");
        // redirect to login after 5
        setTimeout(() => {
          this.props.history.push("/login");
        }, 5000);
      })
      .catch((error) => {
        toastr.error("Password not changed");
        const {data} = error.response;
        let new_message = [];
        for (let d of data) {
          if (d.type === BACKEND_ERROR_TYPES.OLD_PASSWORD_ERROR) {
            this.setState({errorOldPassword: d.message});
          } else if (d.type === BACKEND_ERROR_TYPES.PASSWORD_ERROR) {
            this.setState({errorNewPassword: d.message});
          } else if (d.message) {
            new_message.push(d.message);
          }
        }

        const message = data.password ? data.password : new_message;
        this.setState({message});
      });
  };

  handlePasswordInput = () => {
    const {password} = this.state;

    if (!this.state.loadAnimations) {
      this.setState({loadAnimations: true});
    }

    let checkedCopy = this.state.checkedErrors.slice();

    // Password must contain 10 or more characters.
    checkedCopy[1] = password.length >= 10 ? true : false;
    // Password must contain at least 1 digit.
    checkedCopy[2] = password.match(/\d/) ? true : false;
    // Password must contain at least 1 lowercase letter.
    checkedCopy[3] = password.toUpperCase() != password ? true : false;
    // Password must contain at least 1 uppercase letter.
    checkedCopy[4] = password.toLowerCase() != password ? true : false;
    // Password must contain at least 1 special character: ~!@#$%^&*()_+{}\":;'[]
    checkedCopy[5] = password.match(/^(?=.*[~!@#$%^&*()_+{}":;'[\]])/) ? true : false;

    this.setState({checkedErrors: checkedCopy}, () => this.handleNewPasswordInput());
  };

  handleOldPasswordInput = () => {
    if (this.state.oldPassword) {
      this.setState({errorOldPassword: ""});
      if (!this.state.errorNewPassword || this.state.errorNewPassword === FIELD_ERRORS.PASSWORD_SAME_AS_OLD) {
        this.state.newPasswordSameAsOld ? this.setState({errorNewPassword: FIELD_ERRORS.PASSWORD_SAME_AS_OLD}) : this.setState({errorNewPassword: ""});
      }
    }
  }

  handleNewPasswordInput = () => {
    if (this.state.password) {
      this.setState({errorNewPassword: ""});
      this.state.newPasswordsMatch ? this.setState({errorVerifyPassword: ""}) : this.setState({errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH});
    }
  }

  handleVerifyPasswordInput = () => {
    if (this.state.password2) {
      this.state.newPasswordsMatch ? this.setState({errorVerifyPassword: ""}) : this.setState({errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH});
    }
  }

  render() {
    const {
      isValid,
      message,
      checkedErrors,
      newPasswordsMatch,
      newPasswordSameAsOld,
      loadAnimations,
      errorVerifyPassword,
      errorNewPassword,
      errorOldPassword,
      disableFormButton
    } = this.state;

    if (!this.props.location.state && !this.props.auth?.username && !this.props.token) {
      return <Redirect to={urls.LOGIN}/>;
    }

    return (
      <AuthView wide={true}>
        <PasswordRequirements message={message.length ? message : undefined}/>
        <PasswordChangeForm
          withToken={!!this.props.token}
          onSave={this.props.token
            ? this.handlePasswordReset
            : this.handlePasswordChange}
          isValid={isValid}
          checkedErrors={checkedErrors}
          newPasswordsMatch={newPasswordsMatch}
          newPasswordSameAsOld={newPasswordSameAsOld}
          loadAnimations = {loadAnimations}
          errorNewPassword = {errorNewPassword}
          errorOldPassword = {errorOldPassword}
          errorVerifyPassword = {errorVerifyPassword}
          disableFormButton = {disableFormButton}
          updateOldPassword={(newVal) =>
            this.setState({"oldPassword": newVal, newPasswordSameAsOld: newVal == this.state.password},
              () => this.handleOldPasswordInput())}
          updatePassword={(newVal) =>
            this.setState({"password": newVal,
              newPasswordsMatch: newVal == this.state.password2,
              newPasswordSameAsOld: newVal == this.state.oldPassword },
            () => this.handlePasswordInput())}
          updateVerifyPassword={(newVal) =>
            this.setState({"password2": newVal, newPasswordsMatch: newVal == this.state.password},
              () => this.handleVerifyPasswordInput())}
        />
      </AuthView>
    );
  }
}

export const PasswordChangeWithResetToken = compose(
  withResetToken,
  withRouter,
)(PasswordChange);


const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(PasswordChange));
