import ImageSegmentationWidgetEditor from "./ImageSegmentationWidget/ImageSegmentationWidgetEditor";
import SpreadsheetWidgetEditor from "./SpreadsheetWidget/SpreadsheetWidgetEditor";
import RadioButtonWidgetEditor from "./RadioButtonWidget/RadioButtonWidgetEditor";
import RichTextEditorWidgetEditor from "./RichTextEditorWidget/RichTextEditorWidgetEditor";
import RichTextEditorReadOnlyWidgetEditor
  from "./RichTextEditorReadOnlyWidget/RichTextEditorReadOnlyWidgetEditor";
import TextareaWidgetEditor from "./TextareaWidget/TextareaWidgetEditor";
import PipedTextAreaWidgetEditor from "./PipedTextArea/PipedTextAreaWidgetEditor";
import GooglePlayWidgetEditor from "./GooglePlayWidget/GooglePlayWidgetEditor";
import SelectWidgetEditor from "./SelectWidget/SelectWidgetEditor";
import SubmitButtonWidgetEditor from "./SubmitButtonWidget/SubmitButtonWidgetEditor";
import SIOSTRAWidgetEditor from "./SIOSTRAWidget/SIOSTRAWidgetEditor";
import OrderListWidgetEditor from "./OrderListWidget/OrderListWidgetEditor";
import FileUploadWidgetEditor from "./FileUploadWidget/FileUploadWidgetEditor";
import MediaPlayerWidgetEditor from "./MediaPlayerWidget/MediaPlayerWidgetEditor";
import MediaWaveformWidgetEditor from "./MediaWaveformWidget/MediaWaveformWidgetEditor";
import AudioAnswerWidgetEditor from "./AudioAnswerWidget/AudioAnswerWidgetEditor";

const widgetEditorMapping = {
  "SpreadsheetWidget": SpreadsheetWidgetEditor,
  "RadioButtonWidget": RadioButtonWidgetEditor,
  "RichTextWidget": RichTextEditorWidgetEditor,
  "RichTextReadOnlyWidget": RichTextEditorReadOnlyWidgetEditor,
  "TextareaWidget": TextareaWidgetEditor,
  "PipedTextAreaWidget": PipedTextAreaWidgetEditor,
  "GooglePlayWidget": GooglePlayWidgetEditor,
  "SelectWidget": SelectWidgetEditor,
  "SubmitWidget": SubmitButtonWidgetEditor,
  "SIOSTRAWidget": SIOSTRAWidgetEditor,
  "OrderListWidget": OrderListWidgetEditor,
  "FileUploadWidget": FileUploadWidgetEditor,
  "MediaPlayerWidget": MediaPlayerWidgetEditor,
  "AudioWidget": MediaWaveformWidgetEditor,
  "MediaWaveformWidget": MediaWaveformWidgetEditor,
  "ImageSegmentationWidget": ImageSegmentationWidgetEditor,
  "AudioAnswerWidget": AudioAnswerWidgetEditor,
};

export default widgetEditorMapping;
