import React from "react";
import PropTypes from "prop-types";

import "./UserTaskSet.css";
import { Table } from "semantic-ui-react";

export default function UserTaskSetBase(props) {
  return (
    <div className={"UserTasks"}>
      {props.statistics}
      <Table compact={"very"}>
        <Table.Header>
          {props.header}
        </Table.Header>
        <Table.Body>
          {props.userTasks}
        </Table.Body>
      </Table>
    </div>
  );
}

UserTaskSetBase.propTypes = {
  header: PropTypes.element,
  statistics: PropTypes.element,
  userTasks: PropTypes.array
};
