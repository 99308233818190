import React from "react";
import PropTypes from "prop-types";
import {Label, Form} from "semantic-ui-react";


const AdvancedFilterTrigger = ({text, quantity, ...props}) =>
  <Form.Button {...props} size="tiny" className="advanced-button">
    {text}
    {quantity > 0 && <Label
      className="advanced-filter-label"
      circular
      attached={"top right"}
      size={"tiny"}
    >Active{quantity > 1 && `: ${quantity}`}</Label>
    }
  </Form.Button>;

AdvancedFilterTrigger.propTypes = {
  text: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired
};

export default AdvancedFilterTrigger;
