import React, {Component} from "react";
import {Header, Input} from "semantic-ui-react";

import "./WidgetNameInput.css";
import PropTypes from "prop-types";

class WidgetNameInput extends Component {
  static propTypes = {
    widgetId: PropTypes.string.isRequired,
    checkIdUnique: PropTypes.func.isRequired,
    updateId: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      widgetId: props.widgetId,
      uniqueId: true,
    };
  }

  checkIdUnique = (id) => {
    if (this.props.widgetId !== id) {
      return this.props.checkIdUnique(id);
    }

    return true;
  };

  onChange = (e, data) => {
    const widgetId = data.value;
    const uniqueId = this.checkIdUnique(widgetId);
    this.setState({uniqueId, widgetId});
    if (uniqueId) {
      this.props.updateId(widgetId);
    }
  };

  render() {
    const customClass = this.state.uniqueId ? "" : "red";

    return (
      <React.Fragment>
        <Header as="h3">Widget name</Header>
        <Input
          size="mini"
          className={"WidgetNameInput " + customClass}
          style={{width: "200px"}}
          value={this.state.widgetId}
          onChange={this.onChange}
        />
      </React.Fragment>
    );
  }
}

export default WidgetNameInput;
