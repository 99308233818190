import moment from "moment";
import * as actionTypes from "../actions/types/types_tasks";
import config from "../config/config";
import {getFilteredList} from "../helpers/utils";

const initState = {
  tasks: [],
  visible_tasks: [],
  expiration: moment(),
  filter: {
    title: "",
    module: "",
    status: "",
  },
  filters: []
};

export default function(state=initState, action) {
  switch (action.type) {
  case actionTypes.LOAD_TASKS:
    const expiration = moment().add(config.TASKS_EXPIRATION_TIME);
    return {
      ...state,
      tasks: action.tasks,
      visible_tasks: action.tasks,
      expiration
    };
  case actionTypes.FILTER_CLEAR:
    return {
      ...state,
      visible_tasks: state.tasks,
      filters: []
    };
  case actionTypes.UPDATE_FILTER:
    return {
      ...state,
      filters: action.filters,
      visible_tasks: getFilteredList(state.tasks, action.filters)
    };
  default:
    return state;
  }
}
