import React, {Component} from "react";
import {Popup, Table} from "semantic-ui-react";
import {formatSeconds} from "../../helpers/utils";
import PropTypes from "prop-types";

export default class TaskStatisticsQuartiles extends Component {
  static propTypes = {
    manual: PropTypes.shape({
      QUARTILE_Q1: PropTypes.string,
      QUARTILE_Q23: PropTypes.string,
      QUARTILE_Q4: PropTypes.string,
      QUARTILE_MAX: PropTypes.string,
      QUARTILE_MIN: PropTypes.string
    }),
    taskStatisticsQuartiles: PropTypes.shape({
      q1min: PropTypes.number,
      q1max: PropTypes.number,
      q23min: PropTypes.number,
      q23max: PropTypes.number,
      q4min: PropTypes.number,
      q4max: PropTypes.number
    })
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  render() {
    return (
      <Table selectable sortable celled structured textAlign="center">
        <Table.Header>
          <Table.Row>
            <Popup trigger={
              <Table.HeaderCell colSpan={2}>Q1</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_Q1}/>
            <Popup trigger={
              <Table.HeaderCell colSpan={2}>Q2 + Q3</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_Q23}/>
            <Popup trigger={
              <Table.HeaderCell colSpan={2}>Q4</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_Q4}/>
          </Table.Row>
          <Table.Row>
            <Popup trigger={
              <Table.HeaderCell>Min</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MIN}/>
            <Popup trigger={
              <Table.HeaderCell>Max</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MAX}/>
            <Popup trigger={
              <Table.HeaderCell>Min</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MIN}/>
            <Popup trigger={
              <Table.HeaderCell>Max</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MAX}/>
            <Popup trigger={
              <Table.HeaderCell>Min</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MIN}/>
            <Popup trigger={
              <Table.HeaderCell>Max</Table.HeaderCell>
            } content={this.props.manual.QUARTILE_MAX}/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!this.props.taskStatisticsQuartiles && <Table.Row>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q1min)}</Table.Cell>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q1max)}</Table.Cell>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q23min)}</Table.Cell>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q23max)}</Table.Cell>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q4min)}</Table.Cell>
            <Table.Cell>{formatSeconds(this.props.taskStatisticsQuartiles.q4max)}</Table.Cell>
          </Table.Row>}
          {!this.props.taskStatisticsQuartiles && <Table.Row/>}
        </Table.Body>
      </Table>
    );
  }
}
