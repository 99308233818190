export function cut(params, instance) {
  /*
  ---------------------------------------------
  The function will take the buffer used to create the waveform and will
  create
  a new blob without the selected area from the original blob using the
  offlineAudioContext
  */

  const start = params.start;
  const end = params.end;

  const originalAudioBuffer = instance.backend.buffer;
  const sampleRate = originalAudioBuffer.sampleRate;
  const fragmentLengthInSamples = Math.floor((end - start) * sampleRate);

  if (!window.OfflineAudioContext) {
    if (!window.webkitOfflineAudioContext) {
      alert("webkit context not found");
    }
    window.OfflineAudioContext = window.webkitOfflineAudioContext;
  }

  const offlineAudioContext = instance.backend.ac;

  const newAudioBuffer = offlineAudioContext.createBuffer(
    originalAudioBuffer.numberOfChannels,
    (originalAudioBuffer.length - fragmentLengthInSamples),
    sampleRate
  );

  for (let channel = 0; channel < originalAudioBuffer.numberOfChannels; channel++) {
    const new_channel_data = newAudioBuffer.getChannelData(channel);
    const original_channel_data = originalAudioBuffer.getChannelData(channel);

    const before_data = original_channel_data.subarray(0, start * sampleRate);
    const after_data = original_channel_data.subarray(
      Math.floor(end * sampleRate), (originalAudioBuffer.length * sampleRate));

    if (start > 0) {
      new_channel_data.set(before_data);
      new_channel_data.set(after_data, (start * sampleRate));
    } else {
      new_channel_data.set(after_data);
    }
  }

  return newAudioBuffer;
}

export function trim(params, instance) {
  /*
  ---------------------------------------------
  The function will take the buffer used to create the waveform and will
  create
  a new blob with the selected area from the original blob using the
  offlineAudioContext
  */
  const start = params.start;
  const end = params.end;

  const originalAudioBuffer = instance.backend.buffer;
  const sampleRate = originalAudioBuffer.sampleRate;
  const fragmentLengthInSamples = Math.ceil((end - start) * sampleRate);

  if (!window.OfflineAudioContext) {
    if (!window.webkitOfflineAudioContext) {
      alert("webkit context not found");
    }
    window.OfflineAudioContext = window.webkitOfflineAudioContext;
  }

  const offlineAudioContext = instance.backend.ac;

  const newAudioBuffer = offlineAudioContext.createBuffer(
    originalAudioBuffer.numberOfChannels,
    fragmentLengthInSamples,
    sampleRate
  );

  for (let channel = 0; channel < originalAudioBuffer.numberOfChannels; channel++) {
    const new_channel_data = newAudioBuffer.getChannelData(channel);
    const original_channel_data = originalAudioBuffer.getChannelData(channel);

    const data = original_channel_data.subarray(
      Math.floor(start * sampleRate), Math.floor(end * sampleRate));
    new_channel_data.set(data);
  }

  return newAudioBuffer;
}

export function bufferToWave16(audioBuffer, offset, len) {

  var numOfChan = audioBuffer.numberOfChannels,
    length = len * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [], i, sample,
    pos = 0;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(audioBuffer.sampleRate);
  setUint32(audioBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels.push(audioBuffer.getChannelData(i));
  }

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) { // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // update data chunk
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], {type: "audio/wav; codecs=0"});

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
}

export function bufferToWave32(audioBuffer, offset, len) {

  var numOfChan = audioBuffer.numberOfChannels,
    length = len * numOfChan * 4 + 58,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [], i, sample,
    pos = 0;

  const sampleRate = 48000;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(18); // length = 18 for 32 float
  setUint16(3); // PCM (float uncompressed)
  setUint16(numOfChan);
  setUint32(sampleRate);
  setUint32(sampleRate * 4 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 4); // block-align
  setUint16(32); // 32-bit (hardcoded in this demo)

  setUint16(0);
  setUint32(0x74636166);
  setUint32(0x00000004);

  const totalAudioLen = length - 58;
  setUint8(totalAudioLen / 4 & 0xff);
  setUint8((totalAudioLen / 4 >> 8) & 0xff);
  setUint8((totalAudioLen / 4 >> 16) & 0xff);
  setUint8((totalAudioLen / 4 >> 24) & 0xff);


  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length
  // 16-bit (hardcoded in this demo)
  // write interleaved data
  for (i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels.push(audioBuffer.getChannelData(i));
  }

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) { // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // update data chunk
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], {type: "audio/wav; codecs=0"});

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }

  function setUint8(data) {
    view.setUint8(pos, data, true);
    pos += 1;
  }
}
