import _ from "lodash";
import React from "react";
import {
  Button,
  Grid,
  Icon,
  Input,
  Popup,
  Table,
  Form,
  Menu,
  Container,
  Header,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import qs from "query-string";
import PropTypes from "prop-types";
import moment from "moment";
import Cookies from "universal-cookie";

import config from "../config/config";
import { filterClear, loadTasks, updateFilter } from "../actions/actions_tasks";
import Pagination from "../components/Pagination";
import LoaderSpinner from "../components/LoaderSpinner";
import FilteredComponent from "../components/FilteredComponent";
import ajax from "../helpers/ajax";
import { updateFiltersStateless } from "../helpers/filters";
import { convertDateToLocalDatetime, getDateFormat } from "../helpers/utils";
import { FormLevenDropdown as FormDropdown } from "../components/simple/Dropdown/LevenDropdown";
import ModalConfirm from "../components/ModalConfirm";
import urls from "../config/frontend_urls";

import "./Tasks.css";

const cookies = new Cookies();

class Tasks extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      delete_selected: [],
      show_confirm_delete: false,
      totalPages: 0,
      tasksList: [],
      loading: true,
      loadingButton: "",
      title: _.get(qs.parse(props.location.search), "title") || "",
      usersInSelectedTask: [],
      filtersOptions: [],
      popupOpen: false,
      datepickerOpen: false,
      displaySubmitReminder: false,
    };
    this.updateFiltersStateless = updateFiltersStateless.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getFiltersOptions();
    this.checkSubmits();
  }

  getData = () => {
    this.getTasks();
  };

  checkSubmits = () => {
    let submits = cookies.get("submits") || 0;
    if (submits >= config.SUBMIT_REMINDER_MIN) {
      this.setState({ displaySubmitReminder: true });
    }
  };

  getTasks = () => {
    ajax
      .get(config.TASK_LIST, { params: this.state.filters })
      .then((response) => {
        if (response.data) {
          this.setState({
            tasksList: response.data.results,
            totalPages: response.data.num_pages,
            page: response.data.page_number,
            loading: false,
          });
        } else {
          this.setState({
            tasksList: [],
            totalPages: 0,
            page: 1,
            loading: false,
          });
        }
      });
  };

  getAnswersCountsForTask = (selectedTaskSlug) => {
    if (selectedTaskSlug !== this.state.selectedTaskSlug) {
      this.setState({ loadingButton: selectedTaskSlug });
      ajax
        .get([config.TASK_USERS_WITH_ANS_CNTS_FOR_COMPANY, selectedTaskSlug])
        .then((response) => {
          this.setState({
            selectedTaskSlug: selectedTaskSlug,
            usersInSelectedTask: response.data.users,
            answersInSelectedTask: response.data.answers_from_company,
            evaluationanswersInSelectedTask:
              response.data.evaluationanswers_from_company,
            loadingButton: "",
          });
        });
    } else {
      this.setState({ selectedTaskSlug: null });
    }
  };

  updateFavorites = (slug, isFavorite, pinColor) => {
    const text = !isFavorite ? "added to" : "removed from";
    ajax
      .post([config.TASKS_UPDATE_FAVORITES, slug, pinColor])
      .then(() => {
        toastr.success("Success!", `Task ${text} favorites!`);
        const tasks = this.state.tasksList;
        const taskIndex = tasks.findIndex((t) => t.slug === slug);
        const updatedTask = {
          ...tasks[taskIndex],
          is_favorite: !isFavorite,
          favorites_pin_color: pinColor,
        };
        const filteredTasksList = tasks.filter((t) => t.slug !== slug);
        const firstNotFavoriteTaskIndex = filteredTasksList.findIndex(
          (t) => t.is_favorite === false
        );
        let updatedTasksList = [];
        updatedTask.is_favorite
          ? (updatedTasksList = [updatedTask, ...filteredTasksList])
          : (updatedTasksList = [
              ...filteredTasksList.slice(0, firstNotFavoriteTaskIndex),
              updatedTask,
              ...filteredTasksList.slice(
                firstNotFavoriteTaskIndex,
                filteredTasksList.length
              ),
            ]);
        this.setState({ tasksList: updatedTasksList });
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
      });
  };

  // eslint-disable-next-line react/display-name
  getTaskSlugColumn = (task) => {
    if (this.state.selectedTaskSlug === task.slug) {
      return (
        <Table.Cell className={"title"} width={16}>
          <div>
            <Link className={"selected-task-link"} to={"/task/" + task.slug}>
              {task.title}
            </Link>
            {this.props.auth.is_company_coordinator &&
              `Answers: ${this.state.answersInSelectedTask} | Evaluation answers: ${this.state.evaluationanswersInSelectedTask}`}
          </div>
          <Grid className={"usersList"}>
            <Grid.Row className="sub-row" key={-1} columns={5} stretched>
              <Grid.Column width={5}>User</Grid.Column>
              <Grid.Column width={4} textAlign="center">
                <div className="headers">
                  <div>Answers (/ Max Answers)</div>
                  <Popup
                    position={"top center"}
                    flowing
                    content={
                      <div className="popupHeaders">
                        <div>Answers (/ Max Answers)</div>
                        <div>Evaluation Answers (/ Max Evaluation Answers)</div>
                      </div>
                    }
                    trigger={
                      <Icon
                        className="datahive-icon dark-grey"
                        name={"info circle"}
                      />
                    }
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={3} textAlign={"center"}>
                Deadline
              </Grid.Column>
              <Grid.Column width={2} textAlign={"center"}>
                Status
              </Grid.Column>
              <Grid.Column width={2} textAlign={"center"}>
                Last answer
              </Grid.Column>
            </Grid.Row>
            {_.map(this.state.usersInSelectedTask, (usr, idx) => {
              const user = !!usr.max_questions_in_task ? (
                <Popup
                  position={"bottom center"}
                  flowing
                  content={
                    <div className="popupHeaders">Answers / Max Answers</div>
                  }
                  trigger={
                    <div>
                      {usr.answered} / {usr.max_questions_in_task}
                    </div>
                  }
                />
              ) : (
                <Popup
                  position={"bottom center"}
                  flowing
                  content={<div className="popupHeaders">Answers</div>}
                  trigger={<div>{usr.answered}</div>}
                />
              );
              const evaluator = !!usr.evaluation_max_questions ? (
                <Popup
                  position={"bottom center"}
                  flowing
                  content={
                    <div className="popupHeaders">
                      Evaluation Answers / Max Evaluation Answers
                    </div>
                  }
                  trigger={
                    <div>
                      {usr.evaluation_answered} / {usr.evaluation_max_questions}
                    </div>
                  }
                />
              ) : (
                <Popup
                  position={"bottom center"}
                  flowing
                  content={
                    <div className="popupHeaders">Evaluation Answers</div>
                  }
                  trigger={<p>{usr.evaluation_answered}</p>}
                />
              );
              const userTaskOrEvaluatorTask = usr.user_is_evaluator
                ? evaluator
                : user;

              return (
                <Grid.Row className="sub-row" key={idx} columns={4} stretched>
                  <Grid.Column width={5}>{usr.username}</Grid.Column>
                  <Grid.Column width={4} textAlign={"center"}>
                    {usr.user_is_assigned && usr.user_is_evaluator ? (
                      <>
                        {user}
                        {evaluator}
                      </>
                    ) : (
                      <>{userTaskOrEvaluatorTask}</>
                    )}
                  </Grid.Column>
                  <Grid.Column width={3} textAlign={"center"}>
                    {usr.deadline
                      ? convertDateToLocalDatetime(usr.deadline)
                      : null}
                    {usr.user_is_evaluator && usr.evaluator_deadline ? (
                      <div>
                        {convertDateToLocalDatetime(usr.evaluator_deadline)}
                      </div>
                    ) : null}
                  </Grid.Column>
                  <Grid.Column
                    width={2}
                    className={"status-col"}
                    textAlign={"center"}
                  >
                    <Popup
                      content={
                        usr.resignation
                          ? `Resigned at: ${usr.resignation}`
                          : usr.status
                          ? _.capitalize(usr.status)
                          : null
                      }
                      className={"status-popup"}
                      trigger={
                        <Icon
                          className={`status-${usr.status} ${
                            usr.user_is_evaluator && "status-evaluator"
                          }`}
                          name={"handshake outline"}
                          size={"large"}
                        />
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    {usr.last_answer
                      ? convertDateToLocalDatetime(usr.last_answer)
                      : null}
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Table.Cell>
      );
    }
    return (
      <Table.Cell className={"title"} width={4}>
        <Link to={"/task/" + task.slug}>{task.title}</Link>
      </Table.Cell>
    );
  };

  updateFiltersOptions = (field, value) => {
    const filtersOptions = this.state.filtersOptions;
    filtersOptions[field] = value;
    this.setState({ filtersOptions });
  };

  getFiltersOptions = () => {
    ajax.get(config.LANGUAGES).then((response) => {
      if (response.data) {
        const language = _.map(response.data, (item) => {
          return {
            key: item.name,
            value: item.name,
            text: item.display_name,
          };
        });
        language.unshift({
          key: "",
          value: "",
          text: "-----",
        });
        this.updateFiltersOptions("language", language);
      }
    });
  };

  onPopupClose = () => {
    if (this.state.datepickerOpen) {
      this.setState({ datepickerOpen: false });
    } else {
      this.setState({ popupOpen: false });
    }
  };

  handleSubmit = () => {
    this.onSubmit();
    this.setState({ popupOpen: false });
  };

  renderFilter = () => {
    const deadline_from =
      "deadline_from" in this.state.filters
        ? moment(this.state.filters.deadline_from).format(getDateFormat())
        : "";
    const deadline_to =
      "deadline_to" in this.state.filters
        ? moment(this.state.filters.deadline_to).format(getDateFormat())
        : "";

    return (
      <Menu className={"filters-menu"}>
        <Form style={{ padding: "14px" }}>
          <Form.Field className={"deadline-from"}>
            <label>Deadline from</label>
            <DateInput
              dateFormat={"L"}
              popupPosition="right center"
              closable={false}
              key={"deadline-to" + this.state.filters.deadline_to}
              name="deadline-from"
              placeholder="Deadline from"
              value={deadline_from}
              maxDate={deadline_to ? deadline_to : null}
              onChange={(e, { value }) => {
                const deadline = value ? moment(value, "L").toISOString() : "";
                this.setFilterState("deadline_from", deadline);
              }}
              onClick={() => this.setState({ datepickerOpen: true })}
            />
          </Form.Field>
          <Form.Field className={"deadline-to"}>
            <label>Deadline to</label>
            <DateInput
              closable={false}
              dateFormat={"L"}
              popupPosition="right center"
              key={"deadline-to" + this.state.filters.deadline_from}
              name="deadline-to"
              placeholder="Deadline to"
              value={deadline_to}
              minDate={deadline_from ? deadline_from : null}
              onChange={(e, { value }) => {
                const deadline = value
                  ? moment(value, "L").add(86399, "seconds").toISOString()
                  : ""; // add 23:59:59h
                this.setFilterState("deadline_to", deadline);
              }}
              onClick={() => this.setState({ datepickerOpen: true })}
            />
          </Form.Field>
          <Form.Field className={"language"}>
            <FormDropdown
              label="Language"
              placeholder="Language"
              fluid
              search
              selection
              options={this.state.filtersOptions.language || []}
              value={this.state.filters.language}
              onChange={(e, data) =>
                this.setFilterState("language", data.value)
              }
            />
          </Form.Field>
          <div style={{ textAlign: "center" }}>
            <Form.Button
              className="datahive-button"
              color="yellow"
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </Form.Button>
            <Form.Button
              className="datahive-button"
              color="grey"
              type="submit"
              onClick={() => this.setState({ popupOpen: false })}
            >
              Close
            </Form.Button>
          </div>
        </Form>
      </Menu>
    );
  };

  // eslint-disable-next-line react/display-name
  renderRow = (task) => {
    const colors = [
      "db244d",
      "f85e00",
      "fa9500",
      "fbbd08",
      "a5be00",
      "2e933c",
      "028090",
      "1d4e89",
      "6f2dbd",
      "000000",
    ];
    const popupEvaluation = (
      <Popup
        as="span"
        position="top center"
        flowing
        content={<div>Evaluation</div>}
        trigger={
          <Icon
            className="datahive-icon dark-grey userRowEvaluation"
            name="info circle"
          />
        }
      />
    );

    const userRow = (
      <>
        <Table.Cell className="center aligned">
          {task.user_is_assigned && (
            <div>
              {task.deadline ? convertDateToLocalDatetime(task.deadline) : "-"}
            </div>
          )}
          {task.user_is_evaluator && (
            <span className="userRowEvaluation">
              {task.evaluation_deadline
                ? convertDateToLocalDatetime(task.evaluation_deadline)
                : "-"}
            </span>
          )}
          {task.user_is_assigned && task.user_is_evaluator && popupEvaluation}
        </Table.Cell>
        <Table.Cell className="center aligned">
          {task.user_is_assigned && (
            <div>
              {task.limit ? `${task.answered} / ${task.limit}` : task.answered}
            </div>
          )}
          {task.user_is_evaluator && (
            <span className="userRowEvaluation">
              {task.evaluation_max_questions
                ? `${task.evaluation_answered} / ${task.evaluation_max_questions}`
                : task.evaluation_answered}
            </span>
          )}
          {task.user_is_assigned && task.user_is_evaluator && popupEvaluation}
        </Table.Cell>
        <Table.Cell className={"center aligned"}>
          {task.reported_hours ? task.reported_hours : "-"}
        </Table.Cell>
      </>
    );

    const coordinatorRow = (
      <>
        <Table.Cell className={"center aligned"}>
          {task.deadline ? convertDateToLocalDatetime(task.deadline) : "-"}
        </Table.Cell>
        <Table.Cell className={"center aligned"}>
          {task.reported_hours ? task.reported_hours : "-"}
        </Table.Cell>
        <Table.Cell className={"center aligned"}>
          {task.answered && task.reported_hours
            ? (task.answered / task.reported_hours).toFixed(2)
            : "-"}
        </Table.Cell>
      </>
    );

    return (
      <Table.Row
        key={`TaskListItem task-row-${task.pk}`}
        className="taskListItem"
      >
        <Table.Cell>
          {task.is_favorite ? (
            <Button
              icon="pin"
              className="favorites"
              style={{
                color: task.is_favorite
                  ? `#${task.favorites_pin_color}`
                  : "#D8D8D8",
              }}
              onClick={() =>
                this.updateFavorites(task.slug, task.is_favorite, "#fbbd08")
              }
            />
          ) : (
            <Popup
              content={colors.map((color) => (
                <Button
                  key={color}
                  icon="pin"
                  className="pin-color-item"
                  style={{ color: `#${color}` }}
                  onClick={() =>
                    this.updateFavorites(task.slug, task.is_favorite, color)
                  }
                />
              ))}
              on="click"
              pinned
              trigger={
                <Button
                  icon="pin"
                  className="favorites"
                  style={{
                    color: task.is_favorite ? `#${task.favorites_pin_color}` : "#D8D8D8",
                  }}
                />
              }
            />
          )}
        </Table.Cell>
        {this.getTaskSlugColumn(task)}
        <Table.Cell collapsing />
        {this.props.auth.is_company_coordinator &&
          !this.state.selectedTaskSlug &&
          coordinatorRow}
        {!this.props.auth.is_company_coordinator && userRow}
        {this.props.auth.is_company_coordinator &&
          this.state.selectedTaskSlug && (
            <>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
            </>
          )}
        <Table.Cell className={"options center aligned-top"}>
          {this.props.auth.is_company_coordinator && (
            <Button
              icon
              loading={this.state.loadingButton === task.slug}
              className="datahive-button"
              color="yellow"
              onClick={() => this.getAnswersCountsForTask(task.slug)}
            >
              <Icon
                name={`${
                  this.state.selectedTaskSlug === task.slug ? "up" : "down"
                } arrow`}
              />
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const { filters } = this.state;
    const columns = 8;
    const { tasksList } = this.state;
    const pagination = (
      <Pagination
        activePage={filters?.page || 1}
        onPageChange={(e, { activePage }) =>
          this.changeFilterInstantly({ page: activePage })
        }
        totalPages={this.state.totalPages}
      />
    );

    const rows = _.map(tasksList, (task) => this.renderRow(task));

    const headerCellUser = (
      <>
        <Table.HeaderCell width={2}>Deadline</Table.HeaderCell>
        <Table.HeaderCell width={1}>Answers (/Max Answers)</Table.HeaderCell>
        <Table.HeaderCell width={1}>Reported hours</Table.HeaderCell>
      </>
    );

    const headerCellCoordinator = (
      <>
        <Popup
          flowing
          position={"bottom center"}
          content={<div>First user deadline that hasn&#39;t expired</div>}
          trigger={
            <Table.HeaderCell width={3} textAlign={"center"}>
              Deadline
            </Table.HeaderCell>
          }
        />
        <Table.HeaderCell width={1}>Reported hours</Table.HeaderCell>
        <Table.HeaderCell width={1}>Tempo</Table.HeaderCell>
      </>
    );

    const headerCellEmpty = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
      </>
    );

    return (
      <div className={"ui container Tasks"}>
        <Table selectable singleLine className="SortableTable">
          <Table.Header className={"header"} fullWidth>
            <Table.Row disabled={true}>
              <Table.HeaderCell width={1}>Favorites</Table.HeaderCell>
              <Table.HeaderCell width={4}>Title</Table.HeaderCell>
              <Table.HeaderCell width={1} />
              {!this.props.auth.is_company_coordinator && headerCellUser}
              {this.props.auth.is_company_coordinator &&
              !this.state.selectedTaskSlug
                ? headerCellCoordinator
                : null}
              {this.props.auth.is_company_coordinator &&
                this.state.selectedTaskSlug &&
                headerCellEmpty}
              <Table.HeaderCell width={2}>Options</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={"filter"} className="taskListItemx">
              <Table.Cell className={"title"} colSpan="3">
                <Input
                  className={"title-input"}
                  placeholder="Title"
                  fluid
                  onChange={(e, data) =>
                    this.searchChange(data.value.toLowerCase(), "title")
                  }
                  value={this.state.title || ""}
                />
              </Table.Cell>
              <Table.Cell colSpan="3">
                <Popup
                  on="click"
                  position={"bottom center"}
                  style={{ padding: 0 }}
                  trigger={
                    <Button
                      className="datahive-button"
                      color="dark-grey"
                      onClick={() => {
                        this.setState({ popupOpen: true });
                      }}
                    >
                      Filter
                      <Icon name="filter" />
                    </Button>
                  }
                  content={this.renderFilter()}
                  open={this.state.popupOpen}
                />
                <span
                  className={"reset-filters-button"}
                  onClick={this.resetFilters}
                >
                  Reset all filters
                </span>
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
            {tasksList && rows}
            {this.state.loading && (
              <Table.Row>
                <Table.Cell singleLine={true} colSpan={columns}>
                  <LoaderSpinner />
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row key={"pagination-down"}>
              <Table.Cell colSpan={columns}>{pagination}</Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={columns} />
            </Table.Row>
          </Table.Footer>
        </Table>
        {this.state.displaySubmitReminder && (
          <ModalConfirm
            title="Recent answers detected"
            content={
              <Container textAlign="center">
                <Header as="h2">Please, remember to report work hours.</Header>
                <Link as={"Button"} to={urls.REPORTS}>
                  <Button size="big" color="red" className="datahive-button">
                    Go to Reports
                  </Button>
                </Link>
              </Container>
            }
            closeModal={() => this.setState({ displaySubmitReminder: false })}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.tasks,
    auth: state.auth,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadTasks,
    filterClear,
    updateFilter,
  })(Tasks)
);
