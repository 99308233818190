import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Checkbox} from "semantic-ui-react";
import {SortableContainer, SortableElement, arrayMove} from "react-sortable-hoc"

import ModalConfirm from "../../components/ModalConfirm";

import "./ReorderTutorials.css";


const SortableItem = SortableElement(({value}) => (
  <li tabIndex={0} className="tutorialItem"><span>{value.title}</span></li>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (<SortableItem key={`item-${value.id}`} index={index} value={value}/>))}
    </ul>)
})


class ReorderTutorials extends Component {
  static propTypes = {
    title: PropTypes.string,
    reorderTutorials: PropTypes.func,
    onClose: PropTypes.func,
    tutorials: PropTypes.array,
    evalTutorials: PropTypes.array
  }

    constructor(props) {
    super(props);
    this.state = {
      items: this.props.tutorials,
      isEval : false,
    };
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
        items: arrayMove(items, oldIndex, newIndex)
    }))
 }

  orderTutorials = (to_reset) => {
    const reorderedWikis = this.state.items.map((item) => item.id)
    const data = {
      "wikis": reorderedWikis
    }
    data["to_remove"] = to_reset;
    this.props.reorderTutorials(data);
  }

  handleChange = (e, {checked}) => {
    const items = !checked ? this.props.tutorials : this.props.evalTutorials;
    this.setState({isEval: checked, items});
  }

  render() {
    const content =
      <React.Fragment>
        <Checkbox
          toggle
          checked={this.state.isEval}
          onChange={this.handleChange}
          label={"Evaluation Tutorials"}/>
          <SortableList  items={this.state.items} onSortEnd={this.onSortEnd}/>
      </React.Fragment>;

    const buttons = <>
        <Button
          color="green"
          className="datahive-button"
          onClick={() => this.orderTutorials(false)}>
          Save
        </Button>
        <Button
          color="red"
          floated="left"
          className="datahive-button"
          onClick={() => this.orderTutorials(true)}>
        Reset order
        </Button>
        </>;

    return <ModalConfirm
      title={this.props.title}
      closeModal={this.props.onClose}
      content={content}
      button={buttons}
    />;
  }
}

export default ReorderTutorials;
