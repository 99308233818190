import _ from "lodash";

import React from "react";
import {Button, Checkbox, Grid, Header, Input, Popup} from "semantic-ui-react";
import {getWidget} from "../widgetUtils";
import {WidgetEditor} from "../WidgetEditor";
import "./SpreadsheetWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";


class SpreadsheetWidgetEditor extends WidgetEditor {
  static header = "Spreadsheet";

  constructor(props) {
    super(props);

    this.startState = {
      newColumnName: "",
      newColumnType: "text",
      newColumnWidth: 100,
      newColumnOptions: [],
      newColumnReadonly: false,
      newRow: {},
      addRowPopupOpen: false,
      addColumnPopupOpen: false
    };

    this.state = {
      ...this.state,
      ...this.startState
    };

    this.addColumn = this.addColumn.bind(this);
    this.addRow = this.addRow.bind(this);
  }

  addColumn() {
    this.updateWidgetConfig((widgetConfig) => {
      for (const idx in widgetConfig.columns) {
        if (widgetConfig.columns[idx].name === this.state.newColumnName) {
          return;
        }
      }
      const key = this.state.newColumnName.split().join("-").toLowerCase();

      const config = {
        key: key,
        name: this.state.newColumnName,
        width: this.state.newColumnWidth,
        resizable: true,
        editable: !this.state.newColumnReadonly
      };
      widgetConfig.columns.push(config);
      widgetConfig.rows = _.map(widgetConfig.rows, (r) => ({...r, [this.state.newColumnName]: ""}));
      this.setState({...this.startState});
    });
  }

  addRow() {
    this.updateWidgetConfig((widgetConfig) => {
      const firstColumn = widgetConfig.columns[0].name;
      const config = {...this.state.newRow};
      // Every row is expected to have at least one (first) field.
      if (_.isEmpty(config)) {
        config[firstColumn] = "";
      }
      widgetConfig.rows.push(config);
      // Init temporary row with empty value.
      this.setState({...this.startState, newRow: {[firstColumn]: ""}});
    });
  }

  removeColumn(column) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.columns = _.filter(widgetConfig.columns, (c) => {
        return c.name !== column.name;
      });

      !!widgetConfig.columns.length ?
        widgetConfig.rows.forEach((r) => {
          delete r[column.name];
        }) : widgetConfig.rows = [];
    });

    this.setState({}); // force re-render
  }

  removeRow(row_idx) {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig.rows.splice(row_idx, 1);
    });

    this.setState({}); // force re-render
  }

  renderColumnNames(columns) {
    const columnsCount = columns.length + 1;
    const renderedColumns = _.map(columns, (column, idx) => {
      return (
        <Grid.Column key={"column" + idx}>
          <Popup
            id={`remove-column-popup-${idx}`}
            trigger={
              <Input
                id={`column-name-${idx}`}
                size="mini"
                fluid
                label={{icon: "certificate"}}
                labelPosition="right corner"
                placeholder={column.name}
                value={column.name}
                onChange={(e, data) => this.updateConfig(`columns[${idx}].name`, data.value)}
              />
            }
            position="bottom center"
            hoverable>
            <Grid centered divided>
              <Grid.Row textAlign="center">
                <Header as="h4">{column.name}</Header>
              </Grid.Row>
              <Grid.Row>
                <Input
                  id={`options-column-width-${idx}`}
                  size="mini"
                  label="Width"
                  type="number"
                  placeholder="Column width"
                  value={column.width}
                  onChange={(e, data) => this.updateConfig(`columns[${idx}].width`, parseInt(data.value))}
                />
              </Grid.Row>
              <Grid.Row>
                <Checkbox
                  id={`options-column-readonly-${idx}`}
                  toggle
                  label="Read-only"
                  checked={!column.editable}
                  onChange={(e, data) => this.updateConfig(`columns[${idx}].editable`, !data.checked)}
                />
              </Grid.Row>
              <Grid.Row textAlign="center">
                <Button
                  id={`remove-column-button-${idx}`}
                  color="red"
                  icon="trash"
                  content="Remove"
                  onClick={() => this.removeColumn(column)}
                />
              </Grid.Row>
            </Grid>
          </Popup>
        </Grid.Column>
      );
    });
    return (
      <Grid.Row key="title-row" columns={columnsCount}>
        {renderedColumns}
        <Grid.Column key={"column-new"}>
          <Popup
            id="add-column-popup"
            onOpen={() => this.handleOpenPopup("addColumnPopupOpen")}
            open={this.state.addColumnPopupOpen}
            onClose={() => this.handleClosePopup("addColumnPopupOpen")}
            trigger={
              <Button
                circular
                id="add-column-button"
                data-tooltip="Add column"
                size="mini"
                icon="plus"
                color="green"
              />
            }
            on="click"
            hideOnScroll
            position="bottom right">
            <Grid centered>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Header as="h4">Add column</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Input
                    id={"new-column-name"}
                    size="mini"
                    label="Name"
                    placeholder="Column name"
                    value={this.state.newColumnName}
                    onChange={(e) => this.setState({newColumnName: e.target.value})}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Input
                    id="new-column-width"
                    size="mini"
                    label="Width"
                    type="number"
                    placeholder="Column width"
                    value={this.state.newColumnWidth}
                    onChange={(e) => this.setState({newColumnWidth: parseInt(e.target.value)})}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Checkbox
                    id="new-column-readonly"
                    toggle
                    label="Read-only"
                    checked={this.state.newColumnReadonly}
                    onChange={(e, data) => this.setState({newColumnReadonly: data.checked})}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button id="submit-column-button" onClick={this.addColumn}>Add</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Popup>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderColumns(row, idx, columns) {
    // Add new column -> extra column
    const columnsCount = columns.length + 1;
    const renderedColumns = _.map(columns, (column, column_idx) => {
      return (
        <Grid.Column key={"column" + column_idx}>
          <Popup
            id={`remove-row-popup-${idx}`}
            trigger={column_idx === 0 ?
              <Input
                size="mini"
                fluid
                label={{icon: "certificate"}}
                labelPosition="right corner"
                value={row[column.name]}
                onChange={(e, data) => this.updateConfig(`rows[${idx}].${column.name}`, data.value)}
              />
              :
              <Input
                size="mini"
                fluid
                disabled={true}
                value=""
              />
            }
            position="right center"
            hoverable>
            <Grid centered divided>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Header as="h4">{row[column.name]}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    id={`remove-row-button-${idx}`}
                    color="red"
                    icon="trash"
                    content="Remove"
                    onClick={() => this.removeRow(idx)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Popup>
        </Grid.Column>
      );
    });

    return (
      <Grid.Row key={`row-${idx}`} columns={columnsCount}>
        {renderedColumns}
      </Grid.Row>
    );
  }

  handleOpenPopup = (isOpen) => {
    this.setState({[isOpen]: true});
  };

  handleClosePopup = (isOpen) => {
    this.setState({[isOpen]: false});
  };

  render() {
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId);

    const columnNames = this.renderColumnNames(config.columns);
    const columns = _.map(config.rows, (row, idx) => {
      return this.renderColumns(row, idx, config.columns);
    });

    const firstColumn = !!config.columns.length ? config.columns[0].name : "";

    return (

      <Grid className={"SpreadsheetWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <Header
              className="row"
              as="h4">Enable Add row</Header>
            <Checkbox
              id="add-permission"
              className="row"
              toggle
              checked={config.add_row}
              onChange={(e, data) => this.updateConfig("add_row", data.checked)}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header
              className="row"
              as="h4">Enable Remove row</Header>
            <Checkbox
              id="remove-permission"
              className="row"
              toggle
              checked={config.remove_row}
              onChange={(e, data) => this.updateConfig("remove_row", data.checked)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header as="h4">Columns & Rows</Header>
            <Grid celled>
              {columnNames}
              {columns}
              {!!config.columns.length &&
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Popup
                    id="add-row-popup"
                    onOpen={() => this.handleOpenPopup("addRowPopupOpen")}
                    open={this.state.addRowPopupOpen}
                    onClose={() => this.handleClosePopup("addRowPopupOpen")}
                    trigger={
                      <Button
                        circular
                        id="add-row-button"
                        data-tooltip="Add row"
                        size="mini"
                        icon="plus"
                        color="green"
                      />
                    }
                    hideOnScroll
                    on="click"
                    position="bottom center"
                  >
                    <Grid centered divided>
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Header as="h4">Add row</Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Input
                            id={"new-row-name"}
                            size="mini"
                            fluid
                            label="Name"
                            placeholder="Row name"
                            onChange={(e) => this.setState({
                              newRow: {[firstColumn]: e.target.value}
                            })}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column textAlign="center">
                          <Button id="submit-row-button" onClick={this.addRow}>Add</Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Popup>
                </Grid.Column>
              </Grid.Row>
              }
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="footer">
          <Grid.Column>
            <Button
              id="delete-button"
              color="red"
              icon="trash"
              content="Delete"
              onClick={this.deleteWidget}/>
          </Grid.Column>
          <Grid.Column>
            <Button
              id="close-button"
              floated="right"
              color="blue"
              icon="close"
              content="Close"
              onClick={(e) => this.props.openPopup(e, this.state.widgetId)}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {this.renderPreview()}
        </Grid.Row>
      </Grid>
    )
    ;
  }
}

export default SpreadsheetWidgetEditor;
