import _ from "lodash";

import React from "react";
import {Button, Table} from "semantic-ui-react";
import Wiki from "./Wiki";
import PropTypes from "prop-types";


export default class Wikis extends React.Component {
  static propTypes = {
    wikis: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    errors: PropTypes.object,
    isEvaluationEnable: PropTypes.bool
  };

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  render() {
    const wikis = _.map(this.props.wikis, (w, idx) => {
      return (
        <Wiki
          key={idx}
          idx={idx}
          wiki={w}
          type={this.props.type}
          onChange={(newWiki) => {
            let wikis = [...this.props.wikis];
            wikis.splice(idx, 1, newWiki);
            this.props.onChange(wikis);
          }}
          errors={_.get(this.props.errors, `${this.props.type}`)}
          isEvaluationEnable={this.props.isEvaluationEnable}
        />);
    });

    const addWikiButton = (
      <Table.Row key={wikis.length}>
        <Table.Cell colSpan={10}>
          <Button
            icon={"plus"} style={{width: "100%"}}
            color="grey"
            className="datahive-button"
            onClick={() => {
              const wikis = [...this.props.wikis];
              wikis.push({
                title: "Title",
                is_this_change_tracked: true,
              });
              this.props.onChange(wikis);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );

    wikis.push(addWikiButton);

    return (
      <Table compact={"very"} className={"UserTasks"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{this.props.title}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {wikis}
        </Table.Body>
      </Table>
    );
  }
}
