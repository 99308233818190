import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Header,
  Icon,
  Popup,
  Modal,
  Label,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import config from "../../config/config";
import urls from "../../config/frontend_urls";
import ajax from "../../helpers/ajax";
import "./DescriptionContainer.css";

export default class DescriptionContainer extends Component {
  static propTypes = {
    extraOptions: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    deadline: PropTypes.object,
    is_favorite: PropTypes.bool.isRequired,
    favorites_pin_colors: PropTypes.array.isRequired,
    favorites_pin_color: PropTypes.string,
    updateFavorites: PropTypes.func,
    evaluationDeadline: PropTypes.object,
    onResigned: PropTypes.func.isRequired,
    maxQuestions: PropTypes.number.isRequired,
    evaluationMaxQuestions: PropTypes.number.isRequired,
    allQuestionsAnswered: PropTypes.bool.isRequired,
    allEvaluationQuestionsAnswered: PropTypes.bool.isRequired,
    canResign: PropTypes.bool.isRequired,
    numberOfQuestions: PropTypes.object,
    enable_evaluations: PropTypes.bool,
    canSeeReminder: PropTypes.bool,
    userAnsweringNow: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      resignPopupIsOpen: false,
      displayModalReminder: true,
    };
    this.getDeadlineInfo = this.getDeadlineInfo.bind(this);
    this.getMaxQuestionsInfo = this.getMaxQuestionsInfo.bind(this);
    this.getLimitInfo = this.getLimitInfo.bind(this);
  }

  resignFromTask = () => {
    ajax
      .post([config.TASK_RESIGNATION, this.props.slug])
      .then(() => {
        this.props.onResigned();
      })
      .catch(() => {
        toastr.error("You can't resign from this task!");
      });
  };
  /* eslint-disable-next-line */
  openPopupResignFromTask = (e) => {
    this.setState({ resignPopupIsOpen: true });
  };
  /* eslint-disable-next-line */
  closePopupResignFromTask = (e) => {
    this.setState({ resignPopupIsOpen: false });
  };

  getDeadlineInfo(deadline, answerType) {
    const now = moment().local();
    const cls = now < deadline ? "green" : "red";
    return (
      <div className={`deadline ${cls}`}>
        <Header as="h3">{`Your deadline for ${answerType}:`}</Header>
        {deadline.format("MMMM Do YYYY, h:mm:ss a")}
      </div>
    );
  }

  getMaxQuestionsInfo(maxQuestions, answerType) {
    return (
      <div className={"answer-limit"}>
        <Header as="h3">
          You have been limited to&nbsp;
          <span style={{ color: "red" }}>{`${maxQuestions}`}&nbsp;</span>
          {`${maxQuestions === 1 ? `${answerType}.` : `${answerType}s.`}`}
        </Header>
      </div>
    );
  }

  getLimitInfo(answerType) {
    return (
      <div className={"answer-limit"}>
        <Header as="h3">
          {`You have reached your ${answerType}'s limit!`}
        </Header>
      </div>
    );
  }

  renderModal = () => {
    return (
      <Modal
        className="reminder"
        dimmer="blurring"
        open={this.state.displayModalReminder}
        size="small"
      >
        <Header as="h3">
          <Icon name="edit" size="small" />
          Reminder: Add report
        </Header>
        <Modal.Content>
          <Header as="h4">
            Please don&apos;t forget to add the working hours to your report.
          </Header>
        </Modal.Content>
        <Modal.Actions>
          <Button
            floated="left"
            className="datahive-button"
            color="red"
            onClick={() => this.setState({ displayModalReminder: false })}
          >
            <Icon name="remove" /> No, thank you
          </Button>
          <Link to={urls.USER_REPORT}>
            <Button
              color="yellow"
              className="datahive-button"
              onClick={() => this.setState({ displayModalReminder: false })}
            >
              <Icon name="checkmark" /> Add report
            </Button>
          </Link>
        </Modal.Actions>
      </Modal>
    );
  };

  renderPin = () => {
    return this.props.is_favorite ? (
      <Button
        icon="pin"
        className="favorites"
        style={{
          color: this.props.is_favorite
            ? `#${this.props.favorites_pin_color}`
            : "#D8D8D8",
        }}
        onClick={() =>
          this.props.updateFavorites(
            this.props.slug,
            this.props.is_favorite,
            "fbbd08"
          )
        }
      />
    ) : (
      <Popup
        className="pin-popup"
        content={this.props.favorites_pin_colors.map((color) => (
          <Button
            key={color}
            icon="pin"
            className="pin-color-item"
            style={{ color: `#${color}` }}
            onClick={() =>
              this.props.updateFavorites(
                this.props.slug,
                this.props.is_favorite,
                color
              )
            }
          />
        ))}
        position="right center"
        on="click"
        pinned
        trigger={
          <Button
            icon="pin"
            className="favorites"
            style={{
              color: this.props.is_favorite ? "#fbbd08" : "#D8D8D8",
            }}
          />
        }
      />
    );
  };

  render() {
    const urlStatistics = urls.TASK_STATISTICS.replace(
      ":taskSlug",
      this.props.slug
    );

    let isLimit = false;
    if (this.props.numberOfQuestions) {
      const { userAnswers } = this.props.numberOfQuestions.state;
      isLimit = this.props.maxQuestions === userAnswers;
    }

    let isEvalLimit = false;
    if (this.props.numberOfQuestions) {
      const { userAnswers } = this.props.numberOfQuestions.state;
      isEvalLimit = this.props.evaluationMaxQuestions === userAnswers;
    }

    let deadline = null;
    if (this.props.deadline) {
      deadline = this.getDeadlineInfo(this.props.deadline, "answers");
    }
    let evaluationDeadline = null;
    if (this.props.evaluationDeadline) {
      evaluationDeadline = this.getDeadlineInfo(
        this.props.evaluationDeadline,
        "evaluations"
      );
    }

    let maxQuestions = null;
    if (!!this.props.maxQuestions) {
      if (!this.props.allQuestionsAnswered) {
        maxQuestions = this.getMaxQuestionsInfo(
          this.props.maxQuestions,
          "answer"
        );
      } else if (this.props.allQuestionsAnswered && isLimit) {
        maxQuestions = this.getLimitInfo("answer");
      }
    }

    let evaluationMaxQuestions = null;
    if (!!this.props.evaluationMaxQuestions) {
      if (!this.props.allEvaluationQuestionsAnswered) {
        evaluationMaxQuestions = this.getMaxQuestionsInfo(
          this.props.evaluationMaxQuestions,
          "evaluation"
        );
      } else if (this.props.allEvaluationQuestionsAnswered && isEvalLimit) {
        evaluationMaxQuestions = this.getLimitInfo("evaluation");
      }
    }

    let modal = null;
    if (!!this.props.canSeeReminder) {
      modal = this.renderModal();
    }

    return (
      <div className="DescriptionContainer">
        <Header as="h4" textAlign="left">
          {this.props.title}
          {this.props.enable_evaluations ? (
            <Label circular color="grey" size="small">
              eval
            </Label>
          ) : undefined}
          {this.renderPin()}
          <div style={{ float: "right" }}>
            {this.props.extraOptions ? (
              <React.Fragment>
                <Link
                  className={"ui icon button datahive-icon-button"}
                  to={urls.ADMIN_TASK_DETAIL.replace(
                    ":taskSlug",
                    this.props.slug
                  )}
                >
                  <Icon name={"wrench"} />
                </Link>
                <Link
                  className="ui icon button link datahive-icon-button"
                  to={urlStatistics}
                >
                  <Icon name={"bar chart"} />
                </Link>
              </React.Fragment>
            ) : this.props.canResign ? (
              <Popup
                flowing
                hoverable
                on="click"
                trigger={<Button content="Resign from task" color={"brown"} />}
                open={this.state.resignPopupIsOpen}
                onClose={this.closePopupResignFromTask}
                onOpen={this.openPopupResignFromTask}
              >
                <Grid centered divided columns={1}>
                  <Grid.Column textAlign="center">
                    <Header as="h4">Confirmation</Header>
                    <p>Do you want to resign from this task?</p>
                    <p>{"You won't be able to see this task again."}</p>
                    <Button
                      color={"red"}
                      className="ui icon button link"
                      id="resign"
                      onClick={this.resignFromTask}
                    >
                      Yes
                    </Button>
                    <Button
                      color={"green"}
                      className="ui icon button link"
                      onClick={() => this.closePopupResignFromTask()}
                    >
                      No
                    </Button>
                  </Grid.Column>
                </Grid>
              </Popup>
            ) : (
              ""
            )}
          </div>
        </Header>
        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
        {deadline}
        {maxQuestions}
        {evaluationDeadline}
        {evaluationMaxQuestions}
        {this.props.userAnsweringNow && isLimit && modal}
      </div>
    );
    /* eslint-enable react/no-unescaped-entities */
  }
}
