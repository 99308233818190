import React from "react";
import {Button, Icon, Modal, Popup, Table} from "semantic-ui-react";
import UserTaskBase from "./UserTaskBase";
import PropTypes from "prop-types";
import _ from "lodash";
import DragAndDropSelector from "../../../../components/simple/DragAndDropSelector/DragAndDropSelector";

export default class EvaluatorTask extends React.Component {
  static propTypes = {
    actions: PropTypes.element,
    collectionName: PropTypes.string,
    extraColumns: PropTypes.element,
    idx: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      max_questions: PropTypes.number.isRequired,
      deadline: PropTypes.string.isRequired
    }),
    isChecked: PropTypes.bool.isRequired,
    handleCheckboxClick: PropTypes.func.isRequired,
    evaluatorTask: PropTypes.object.isRequired,
    usernameOptions: PropTypes.array.isRequired,
    usersToEvaluation: PropTypes.array.isRequired,
    setModified: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.collectionName = "evaluatortasks";
    const allUsersToEvaluation = _.reject(props.usersToEvaluation, ["username", props.evaluatorTask.username]);
    this.state = {
      evaluated: props.evaluatorTask.evaluated_users || [],
      displayModal: false,
      usersToEvaluation: _.filter(allUsersToEvaluation, "is_active"),
      allUsersToEvaluation,
    };
  }

  componentDidMount() {
    const popupContent = this.getPopupContent();
    this.setState({popupContent});
  }

  getPopupContent = () => {
    let popupContent = "";
    if (this.state.evaluated.length !== 0) {
      const users = _.map(this.state.evaluated, (user) =>
        _.find(this.state.allUsersToEvaluation, (user2) => user === user2.id).username);
      const rows = _.map(users, (el, i) =>
        <p key={i}>{el}</p>);
      popupContent = <>
        <p><b>{this.props.evaluatorTask.username}</b> evaluates:</p>
        {rows}
      </>;
    }
    return popupContent;
  };

  onSaveEvaluated = () => {
    const popupContent = this.getPopupContent();
    this.setState(
      {
        displayModal: false,
        popupContent
      },
      () => this.props.onChange(`evaluatortasks.${this.props.idx}.evaluated_users`, this.state.evaluated)
    );
  };

  onCloseModal = () => {
    this.setState(
      {
        evaluated: this.props.evaluatorTask.evaluated_users || [],
        displayModal: false
      });
  };

  render() {
    const {evaluatorTask} = this.props;

    const extraColumns = <Table.Cell>
      {this.state.evaluated.length ?
        <Popup
          className={"scrollable-popup"}
          content={this.state.popupContent}
          hoverable
          trigger={
            <Button
              size={"tiny"}
              content={`Users: ${this.state.evaluated.length}`}
              onClick={() => this.setState({displayModal: !this.state.displayModal})}
            />
          }
        />
        :
        <Button
          size={"tiny"}
          content={"Random"}
          onClick={() => this.setState({displayModal: !this.state.displayModal})}
        />
      }
    </Table.Cell>;

    let actions = null;
    if (evaluatorTask.username) {
      actions = <Icon
        name={"trash alternate"}
        size={"large"}
        color={"red"}
        onClick={() => this.props.onChange(`evaluatortasks.${this.props.idx}.toBeDeleted`, !evaluatorTask.toBeDeleted)}
      />;
    }

    return (
      <>
        <UserTaskBase
          actions={actions}
          collectionName={"evaluatortasks"}
          extraColumns={extraColumns}
          idx={this.props.idx}
          row={evaluatorTask}
          handleCheckboxClick={this.props.handleCheckboxClick}
          isChecked={this.props.isChecked}
          onChange={this.props.onChange}
          setModified={this.props.setModified}
          usernameOptions={this.props.usernameOptions}
        />
        <Modal
          dimmer={true}
          open={this.state.displayModal}
          onClose={this.onCloseModal}
        >
          <Modal.Header>
            Select users to be evaluated by {evaluatorTask.username}
          </Modal.Header>
          <Modal.Content>
            <DragAndDropSelector
              allOptions={this.state.allUsersToEvaluation}
              choices={this.state.usersToEvaluation}
              choiceValue={"username"}
              filterable
              selectAll
              afterSelectionChange={(newVal) => this.setState({evaluated: newVal})}
              selected={this.state.evaluated}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color={"red"}
              onClick={this.onCloseModal}>
              Cancel
            </Button>
            <Button
              color={"green"}
              onClick={this.onSaveEvaluated}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
