import _ from "lodash";
import React, { Component } from "react";
import { Form, Header, List, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import ShowPasswordButton from "../Auth/ShowPasswordButton";
import FormPopupHelper from "../FormPopupHelper/FormPopupHelper";

import "./AddUserForm.css";


export class AddUserForm extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    divisions: PropTypes.array.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    userTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      visNewPass: false,
      visVerifyPass: false
    };
  }

  render() {
    return <Form className="AddUserForm">
      <Header size="medium" textAlign="center">Add User</Header>
      <Form.Input
        required
        label="Username"
        placeholder="Username"
        onChange={(e, data) => this.props.onChangeForm({ username: data.value })}
      />
      <Form.Input
        required
        label="Email address"
        placeholder="Email address"
        onChange={(e, data) => this.props.onChangeForm({ email: data.value })}
      />
      <Form.Input
        required
        label={<label>Password&nbsp;
          <FormPopupHelper content={<List size="small">
            <List.Item><List.Header>Your password must contain:</List.Header></List.Item>
            <List.Item className="mini"><Icon name="right triangle" />Min length of 10 symbols</List.Item>
            <List.Item><Icon name="right triangle" />1 uppercase symbol</List.Item>
            <List.Item><Icon name="right triangle" />1 special symbol</List.Item>
            <List.Item><Icon name="right triangle" />1 digit</List.Item>
          </List>} />
        </label>}
        placeholder="Password"
        type={this.state.visNewPass ? "text" : "password"}
        onChange={(e, data) => this.props.onChangeForm({ password: data.value })}
        action={<ShowPasswordButton showFunc={(val) => this.setState({ visNewPass: val })} />}
      />
      <Form.Input
        required
        label="Password confirmation"
        placeholder="Password confirmation"
        type={this.state.visVerifyPass ? "text" : "password"}
        onChange={(e, data) => this.props.onChangeForm({ confirmPassword: data.value })}
        action={<ShowPasswordButton showFunc={(val) => this.setState({ visVerifyPass: val })} />} />
      <Header size="medium" textAlign="center">Profiles</Header>
      <Form.Group
        className="FormCompanyType"
        widths="equal">
        <Form.Dropdown
          className="company"
          fluid search selection
          placeholder="Select Company"
          options={this.props.companies}
          label="Company"
          onChange={(e, data) => this.props.onChangeForm({
            company: _.find(this.props.companies, (o) => o.key === data.value).key
          })} />
        <Form.Dropdown
          className="userType"
          fluid search selection
          placeholder="Select User Type"
          options={this.props.userTypes}
          label="User Type"
          onChange={(e, data) => this.props.onChangeForm({
            type: _.find(this.props.userTypes, (o) => o.key === data.value).key
          })} />
      </Form.Group>
      <Header size="medium" textAlign="center">Division</Header>
      <Form.Group widths="equal">
        <Form.Dropdown
          required
          fluid search selection
          placeholder="Select Division"
          options={this.props.divisions}
          label="Division"
          onChange={(e, data) => this.props.onChangeForm({
            division: _.find(this.props.divisions, (o) => o.key === data.value).key
          })} />
      </Form.Group>
    </Form>;
  }
}

export default AddUserForm;
