import _ from "lodash";

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */


export function getNode(tree, value, field = "value") {
  // returns a node with the given value
  let node = null;
  iterateAllNodes(tree, (n) => {
    node = n[field] === value ? n : node;
  });
  return node;
}

export function iterateAllNodes(tree, iteratee) {
  // iterates over all nodes in the tree and passes the node to the given function
  _.each(tree, (n) => {
    iteratee(n);
    if (n.children) {
      iterateAllNodes(n.children, iteratee);
    }
  });
}

export function getAllNodesFlat(tree) {
  // Returns a list of all nodes that are in a tree in a flat list format.
  const nodes = [];
  iterateAllNodes(tree, (node) => {
    nodes.push(node);
  });
  return nodes;
}

export function nodesFulfillCriteria(nodes, criterionFunc) {
  // returns a tree with nodes that only have leaves with matching criteria.
  return _.reduce(nodes, (passing, node) => {
    if (node.children) { // is node
      const childrenThatPass = nodesFulfillCriteria(node.children, criterionFunc);
      if (childrenThatPass && childrenThatPass.length > 0) {
        node.children = childrenThatPass;
        passing.push(node);
      }
      return passing;
    } else { // is leaf
      if (criterionFunc(node)) {
        passing.push(node);
      }
      return passing;
    }
  }, []);
}

export function getAppropriateConnectorTypes(sourceRange, targetRange, connectorTypes, rangeTypes) {
  // find values that fulfill criteria - source and target match
  return nodesFulfillCriteria(connectorTypes, (node) => {


    if (!!node.source && !!node.target) { // only leaves are checked.
      let sourceFamily = [];
      let targetFamily = [];

      _.each(node.source, (sourceElement) => {
        const sourceNode = getNode(rangeTypes, sourceElement);
        if (!sourceNode) {
          return;
        }
        const nodeFamily = _.map(_.concat(getAllNodesFlat(sourceNode.children), sourceNode), (node) => node.value);
        sourceFamily = _.uniq(_.concat(sourceFamily, nodeFamily));
      });

      _.each(node.target, (targetElement) => {
        const targetNode = getNode(rangeTypes, targetElement);
        if (!targetNode) {
          return;
        }
        const nodeFamily = _.map(_.concat(getAllNodesFlat(targetNode.children), targetNode), (node) => node.value);
        targetFamily = _.uniq(_.concat(targetFamily, nodeFamily));
      });

      return sourceFamily.includes(sourceRange.type) && targetFamily.includes(targetRange.type);
    }
    return true;
  });
}

export function getValuesToFind(data, searchQuery) {
  return _.map(search(getOptions(data), searchQuery), (n) => n.value);
}

export function getOptions(data, statelessParents = false, parentUpdateChangeChildren = false) {
  const options = [];
  iterateAllNodes(data, (n) => {
    options.push({
      key: n.value,
      text: n.title ? n.title : n.value,
      value: n.value,
      className: n.children ? "isNode" : "",
      disabled: (n.children && statelessParents && !parentUpdateChangeChildren),
      icon: n.children ? "code branch" : "",
    });
  });

  return options;
}

export function search(options, searchString) {
  let filteredOptions = null;
  if (searchString.length < 3) {
    filteredOptions = options;
  } else {
    filteredOptions = _.filter(options, (o) => _.includes(o.text, searchString) || _.includes(o.value, searchString));
  }

  return filteredOptions;
}

export function treeLeavesValuesDiff(tree, original) {
  const values = getLeavesValues(tree);
  const original_values = getLeavesValues(original);
  return _.differenceBy(values, original_values);
}

export function getLeavesValues(tree) {
  const values = [];
  iterateAllNodes(tree, (n) => {
    if (!n.children) {
      values.push(n.value);
    }
  });
  return values;
}

export function makeOptionsTree(categories) {
  const options = [];
  for (let i = 0; i < categories.length; i++) {
    const steps = categories[i].split(".");
    let currentOption = options;
    let value = "";
    for (let j = 0; j < steps.length; j++) {
      if (j !== 0) {
        value += ".";
      }
      value += steps[j];
      let found = false;
      let node = null;
      for (let k = 0; k < currentOption.length; k++) {
        if (currentOption[k].value === value) {
          node = currentOption[k];
          found = true;
          break;
        }
      }
      if (!found) {
        node = {"title": value, "value": value};
        currentOption.push(node);
      }
      if (j + 1 < steps.length) {
        if (!("children" in node)) {
          node["children"] = [];
        }
        currentOption = node["children"];
      }
    }
  }
  return options;
}

export function removeNode(nodes, val) {
  // iterates over all nodes in the tree and removes one that has matching id
  for (let i = 0; i < nodes.length; i++) {
    const n = nodes[i];
    if (n.value === val) {
      nodes.splice(i, 1);
      return;
    } else if (n.children && n.children.length) {
      removeNode(n.children, val);
    }
  }
}

export function applyInheritance(tree, fieldsToInherit) {
  return _.map(tree, (node) => {
    if (node.children) {
      _.each(node.children, (child) => {
        if (!child.stopInheritance) {
          _.each(fieldsToInherit, (field) => {
            child[field] = child[field] ? child[field] : node[field];
          });
        }
      });
      node.children = applyInheritance(node.children, fieldsToInherit);
    }
    return node;
  });
}
