/**
 * DO NOT PRESS THE FOLLOWING KEYS OR FABULOUS THINGS WILL HAPPEN!!!
 * UP UP DOWN DOWN LEFT RIGHT LEFT RIGHT B A
 */

// hard to guess what this function does
const whoKnowsWhatWillHappen = () => {
  const a = "transform:rotate(1turn);",
    b = "transition:3s;";
  document.head.innerHTML += `<style>body{${ a + b }}</style>`;
};

// smartest men alive tried to figure this out, to no avail
const genuinelyNoOneKnows = () => {
  document.head.innerHTML += "" +
    "<style>" +
    ".top-menu{background-color: pink !important;}" +
    ".ui.button{background-color: pink !important}" +
    ".menu.transition.visible{background-color: pink !important}" +
    ".row.header{background-color: pink !important}" +
    "</style>";
};

const Konami = (() => {
  let combo = 0;
  const KONAMI_CODE = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

  document.addEventListener("keyup", (e) => {
    combo = (e.keyCode === KONAMI_CODE[combo]) ? combo + 1 : 0;
    if (combo === KONAMI_CODE.length) {
      whoKnowsWhatWillHappen();
      genuinelyNoOneKnows();
    }
  });
});

export default Konami;
