import React, { Component } from "react";
import { Button, Form, Divider, Grid } from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";

import ajax from "../../helpers/ajax";
import config from "../../config/config";
import { AuthView } from "./AuthView";
import PasswordChangeForm from "./PasswordChangeForm";
import PasswordRequirements from "./PasswordRequirements";
import {
  FIELD_LENGTHS,
  FIELD_ERRORS,
  TOO_LONG_ERRORS,
  BACKEND_ERROR_TYPES,
  USERNAME_REGEX
} from "./utils";

import "./PasswordChange.css";

/**
 * Allows users to change their password. There are two ways this can be done
 * 1. Logged in user can freely update their password
 * 2. Unauthenticated user can change password only if the url contains a valid
 *    password reset token, which is verified by the 'withResetToken' HOC
 */


class CreateAccount extends Component {
  static propTypes = {
    token: PropTypes.string,
    history: PropTypes.object,
    email: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      created: false,
      password: "",
      password2: "",
      username: "",
      email: "",
      message: [],
      checkedErrors: Array(6).fill(false),
      newPasswordsMatch: true,
      loadAnimations: false,
      errorNewPassword: "",
      errorVerifyPassword: "",
      errorEmail: "",
      errorUsername: ""
    };
  }

  handleAccountCreate = () => {
    const {
      newPasswordsMatch,
      password,
      password2,
      username,
      email
    } = this.state;

    let foundError = false;
    let checkedErrors = this.state.checkedErrors.slice();
    checkedErrors[0] = this.checkAllFields();

    if (checkedErrors.includes(false)) {
      if (!checkedErrors[0]) {
        if (!password) {
          this.setState({ errorNewPassword: FIELD_ERRORS.FIELD_REQUIRED });
        }
        if (!password2) {
          this.setState({ errorVerifyPassword: FIELD_ERRORS.FIELD_REQUIRED });
        }
        if (!username) {
          this.setState({ errorUsername: FIELD_ERRORS.FIELD_REQUIRED });
        }
        if (!email) {
          this.setState({ errorEmail: FIELD_ERRORS.FIELD_REQUIRED });
        }
      }
      if (checkedErrors.slice(1, checkedErrors.length).includes(false)) {
        this.setState({ errorNewPassword: FIELD_ERRORS.PASSWORD_TOO_WEAK });
      }

      foundError = true;
    }

    if (!newPasswordsMatch) {
      this.setState({ errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH });
      foundError = true;
    }

    if (!USERNAME_REGEX.test(username)){
      this.setState({ errorUsername: FIELD_ERRORS.USERNAME_REGEX_NO_MATCH });
      foundError = true;
    }

    if (password.length > FIELD_LENGTHS.PASSWORD) {
      this.setState({ errorNewPassword: TOO_LONG_ERRORS.PASSWORD });
      foundError = true;
    }

    if (username.length > FIELD_LENGTHS.USERNAME) {
      this.setState({ errorUsername: TOO_LONG_ERRORS.USERNAME });
      foundError = true;
    }

    if (email.length > FIELD_LENGTHS.EMAIL) {
      this.setState({ errorEmail: TOO_LONG_ERRORS.EMAIL });
      foundError = true;
    }

    if (foundError) {
      return;
    }

    const data = {
      password: this.state.password,
      username: this.state.username,
      email: this.state.email
    };
    ajax.post(
      config.CREATE_ACCOUNT, { data }
    ).then((response) => {
      if (response.status === 201) {
        toastr.success("Success", "Account created!");
        this.setState({ password: "", password2: "", created: true });
      }
    }).catch((error) => {
      toastr.error("Account not created");
      const { data } = error.response;
      let new_message = [];

      for (let d of data) {
        if (d.type === BACKEND_ERROR_TYPES.EMAIL_ERROR) {
          this.setState({ errorEmail: d.message });
        } else if (d.type === BACKEND_ERROR_TYPES.COMBINATION_ERROR) {
          this.setState({ errorEmail: d.message, errorUsername: d.message });
        } else if (d.type === BACKEND_ERROR_TYPES.PASSWORD_ERROR) {
          this.setState({ errorNewPassword: d.message });
        } else if (d.message) {
          new_message.push(d.message);
        }
      }
      this.setState({ message: new_message });
    });
  };

  handlePasswordInput = () => {
    const { password } = this.state;

    if (!this.state.loadAnimations) {
      this.setState({ loadAnimations: true });
    }

    let checkedCopy = this.state.checkedErrors.slice();

    // Password must contain 10 or more characters.
    checkedCopy[1] = password.length >= 10 ? true : false;
    // Password must contain at least 1 digit.
    checkedCopy[2] = password.match(/\d/) ? true : false;
    // Password must contain at least 1 lowercase letter.
    checkedCopy[3] = password.toUpperCase() != password ? true : false;
    // Password must contain at least 1 uppercase letter.
    checkedCopy[4] = password.toLowerCase() != password ? true : false;
    // Password must contain at least 1 special character: ~!@#$%^&*()_+{}\":;'[]
    checkedCopy[5] = password.match(/^(?=.*[~!@#$%^&*()_+{}":;'[\]])/) ? true : false;

    this.setState({ checkedErrors: checkedCopy }, () => this.handlNewPasswordErrorCheck());
  };

  handleUsernameErrorCheck = () => {
    if (this.state.username && this.state.errorUsername) {
      this.setState({ errorUsername: "" });
    }
  }

  handleEmailErrorCheck = () => {
    if (this.state.email && this.state.errorEmail) {
      this.setState({ errorEmail: "" });
    }
  }

  handlNewPasswordErrorCheck = () => {
    if (this.state.password) {
      this.setState({ errorNewPassword: "" });
      this.state.newPasswordsMatch ? this.setState({ errorVerifyPassword: "" }) : this.setState({ errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH });
    }
  }

  handleVerifyPasswordErrorCheck = () => {
    if (this.state.password2) {
      this.state.newPasswordsMatch ? this.setState({ errorVerifyPassword: "" }) : this.setState({ errorVerifyPassword: FIELD_ERRORS.PASSWORDS_DO_NOT_MATCH });
    }
  }

  checkAllFields = () => {
    return !!this.state.username && !!this.state.email && !!this.state.password && !!this.state.password2;
  };

  render() {
    const {
      created,
      isValid,
      message,
      checkedErrors,
      newPasswordsMatch,
      loadAnimations,
      errorNewPassword,
      errorOldPassword,
      errorVerifyPassword,
      errorUsername,
      errorEmail
    } = this.state;
    return (
      <AuthView wide={true}>
        {created && <AccountCreated email={this.state.email} />}
        {!created && <div>
          <CreateAccountForm
            message={message}
            isValid={isValid}
            updateUsername={(newVal) =>
              this.setState({ "username": newVal },
                () => this.handleUsernameErrorCheck())}
            updateEmail={(newVal) =>
              this.setState({ "email": newVal },
                () => this.handleEmailErrorCheck())}
            errorUsername={errorUsername}
            errorEmail={errorEmail}
          />
          <Divider section />
          <PasswordChangeForm
            checkedErrors={checkedErrors}
            withToken={true}
            newAccount={true}
            updatePassword={(newVal) =>
              this.setState({
                "password": newVal,
                newPasswordsMatch: newVal == this.state.password2
              },
                () => this.handlePasswordInput())}
            updateVerifyPassword={(newVal) =>
              this.setState({ "password2": newVal, newPasswordsMatch: newVal == this.state.password },
                () => this.handleVerifyPasswordErrorCheck())}
            newPasswordsMatch={newPasswordsMatch}
            loadAnimations={loadAnimations}
            errorNewPassword={errorNewPassword}
            errorOldPassword={errorOldPassword}
            errorVerifyPassword={errorVerifyPassword}
          />
          <Button
            className={"change-pw-btn datahive-button"}
            id={"submit-button"}
            color="yellow"
            onClick={this.handleAccountCreate}>
            Create account
          </Button>
        </div>}
      </AuthView>
    );
  }
}

const AccountCreated = (props) =>
  <div className={"password-change"}>
    <h2>Account created!</h2>
    <h3 className={"password-reset__feel-good"}>Welcome to datahive.buzz</h3>
    {/*eslint-disable-next-line*/}
    <h3 className={"password-reset__feel-good"}>Confirmation email sent to {props.email}</h3>
  </div>;


const CreateAccountForm = (props) =>
  <div className={"password-change"}>
    <PasswordRequirements message={props.message.length ? props.message : undefined} />
    <h2>Create your account</h2>
    <h3 className={"password-reset__feel-good"}>Welcome to datahive.buzz</h3>

    <Form id={"data-form"} >
      <Grid className="change-grid">
        <Grid.Row className="sub-row" columns={2}>
          <Grid.Column width={8}>
            <Form.Input
              label={"Username:"}
              id={"username"}
              type={"text"}
              maxLength={FIELD_LENGTHS.USERNAME}
              required
              placeholder="e.g. j.doe"
              error={props.errorUsername ? props.errorUsername : undefined}
              onChange={({ target }) =>
                props.updateUsername(target.value)}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input
              label={"Email:"}
              type={"email"}
              id={"email"}
              maxLength={FIELD_LENGTHS.EMAIL}
              required
              placeholder="Company domain email"
              error={props.errorEmail ? props.errorEmail : undefined}
              onChange={({ target }) =>
                props.updateEmail(target.value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  </div>;

CreateAccountForm.propTypes = {
  updateUsername: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  message: PropTypes.array,
  errorEmail: PropTypes.string,
  errorUsername: PropTypes.string
};

export default CreateAccount;
