import React, {Component} from "react";
import PropTypes from "prop-types";
import {Switch, withRouter, Route, Redirect} from "react-router-dom";
import Tab from "./Tab";

import "./Tabs.css";

/**
 * panes = [
 *   {
 *     key: 'unique key',
 *     label: {
 *       name: 'label to display',
 *       icon: 'from semantic ui',
 *     },
 *      components: component to render,
 *      url: 'e.g. .../3.0/task/:taskSlug',
 *      tabUrl: 'e.g. :subTab',
 *   },
 * ]
 */

class Tabs extends Component {
  static propTypes = {
    panes: PropTypes.arrayOf(PropTypes.object).isRequired,
    isWithoutUrl: PropTypes.bool,
    location: PropTypes.object.isRequired,
    className: PropTypes.string,
    onChangeTab: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: -1
    };
  }

  componentDidMount() {
    // sets current tab on reload
    this.getCurrentTab();
  }

  componentDidUpdate(prevProps) {
    // detect 'go back' 'go forward' browser buttons
    if (this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.hash !== prevProps.location.hash) {
      this.getCurrentTab();
    }
  }

  getCurrentTab = () => this.props.isWithoutUrl ? this.getCurrentTabHash() : this.getCurrentTabIndex();

  getCurrentTabIndex = () => {
    const index = this.props.panes.findIndex((pane) => pane.tabUrl === this.props.location.pathname);
    this.setState(
      {activeTab: index},
      () => this.props.onChangeTab(this.props.panes[index])
    );
  };

  getCurrentTabHash = () => {
    let currentTab = parseInt(this.props.location.hash.replace("#", ""));
    if (!currentTab) {
      currentTab = 0;
    }
    this.setState(
      {activeTab: currentTab},
      () => this.props.onChangeTab(this.props.panes[currentTab])
    );
  };

  renderRoutes = () => {
    const {panes} = this.props;

    return (
      <React.Fragment>
        <ol className="tabs-menu">
          {panes.map((pane, index) =>
            <Tab
              index={index}
              label={pane.label}
              activeTab={this.state.activeTab}
              tabUrl={pane.tabUrl}
              key={pane.key}
              customClass={pane.customClass}
            />
          )}
        </ol>
        <Switch>
          {panes.map((pane) => {
            return <Route
              path={pane.tabUrl}
              render={() => pane.components}
              key={pane.key}/>;
          })}
          {/* if no tab param provided, go first tab pane */}
          <Redirect from={panes[0].url}
            to={panes[0].tabUrl}/>
        </Switch>
      </React.Fragment>
    );
  };

  renderAnchors = () => {
    const {panes} = this.props;
    const {activeTab} = this.state;

    return (
      <React.Fragment>
        <ol className="tabs-menu">
          {panes.map((pane, index) => {
            return (
              <Tab
                index={index}
                activeTab={activeTab}
                label={pane.label}
                key={pane.key}
                isWithoutUrl={this.props.isWithoutUrl}
                customClass={pane.customClass}
              />
            );
          })}
        </ol>
        <div>
          {panes.map((pane, index) => {
            let element = null;
            if (activeTab === index) {
              element = <div key={pane.key}>
                {pane.components}
              </div>;
            }
            return element;
          })}
          {activeTab === -1 && panes[0].components}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.isWithoutUrl
          ? this.renderAnchors()
          : this.renderRoutes()}
      </div>
    );
  }
}

Tabs.defaultProps = {
  onChangeTab: () => null
};

export default withRouter(Tabs);
