import React from "react";
import {
  Button,
  Form,
  Icon,
  Input,
  Menu,
  Popup,
  Table,
} from "semantic-ui-react";
import ContainerList from "../../../components/ContainerList";
import PropTypes from "prop-types";
import FilteredComponent from "../../../components/FilteredComponent";
import LoaderSpinner from "../../../components/LoaderSpinner";
import { FormLevenDropdown as FormDropdown } from "../../../components/simple/Dropdown/LevenDropdown";

import moment from "moment";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import urls from "../../../config/frontend_urls";
import _ from "lodash";

import "./TicketAdminList.css";
import "../../../style/buttons.css";

class TicketAdminList extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.emptyTicket = {
      id: null,
      author: null,
      title: "",
      description: "",
      category: "",
      status: "",
      priority: null,
      created_at: "",
      last_updated: "",
    };

    this.state = {
      tickets: [],
      displaySpinner: true,
      totalPages: 1,
      filtersOptions: {},
      filters: {},
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getFiltersOptions();
  }

  getData = (filters = null) => {
    const params = filters ? filters : this.getFilters();
    this.setState({
      tickets: null,
      filters: params,
    });
    params.page = params.page ? params.page : "1";

    ajax
      .get(config.ADMIN_TICKETS, { params })
      .then((response) => {
        this.setState({
          tickets: response.data.results,
          totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          displaySpinner: false,
        });
      })
      .catch((error) => {
        const message = error.response
          ? error.response.statusText
          : "something went wrong";
        toastr.error(`Error! ${message}`);
      });
  };

  pageChange = (e, { activePage }) => {
    const newFilters = this.updateFilters({ page: activePage });
    this.getData(newFilters);
  };

  handleSubmit = () => {
    this.onSubmit();
    this.setState({ popupOpen: false });
  };

  getFiltersOptions = () => {
    ajax.get(config.TICKET_OPTIONS).then((response) => {
      if (response.data) {
        let { categories, statuses, priorities } = response.data;

        categories = categories.map((category) => ({
          key: category.value,
          value: category.value,
          text: category.name,
        }));

        statuses = statuses.map((status) => ({
          key: status.value,
          value: status.value,
          text: status.name,
        }));

        priorities = priorities.map((priority) => ({
          key: priority.value,
          value: priority.value,
          text: priority.name,
        }));

        this.updateFiltersOptions("categories", categories);
        this.updateFiltersOptions("statuses", statuses);
        this.updateFiltersOptions("priorities", priorities);
      }
    });
  };

  updateFiltersOptions = (field, value) => {
    const filtersOptions = this.state.filtersOptions;
    filtersOptions[field] = value;
    this.setState({ filtersOptions });
  };

  pageChange = (e, { activePage }) => {
    const newFilters = this.updateFilters({ page: activePage });
    this.getData(newFilters);
  };

  renderRow = (item) => {
    const { history } = this.props;

    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    const truncatedTitle =
      "#" +
      item.id +
      " " +
      item.title.slice(0, 50) +
      (item.title.length > 50 ? "..." : "");
    const truncatedDescription =
      item.description.slice(0, 50) +
      (item.description.length > 50 ? "..." : "");

    return (
      <Table.Row
        key={item.id}
        onClick={() =>
          history.push(urls.SUPPORT_TICKET_DETAIL.replace(":id", item.id))
        }
      >
        <Table.Cell className={"list-item clickable"}>
          {truncatedTitle}
        </Table.Cell>
        <Table.Cell>{truncatedDescription}</Table.Cell>
        <Table.Cell>{item.author_username}</Table.Cell>
        <Table.Cell>{item.category}</Table.Cell>
        <Table.Cell>{item.status}</Table.Cell>
        <Table.Cell>{item.priority}</Table.Cell>
        <Table.Cell>
          {item.created_at
            ? moment(item.created_at).format(config.FORMAT_Ymd)
            : ""}
        </Table.Cell>
        <Table.Cell>
          {item.last_updated
            ? moment(item.last_updated).format(config.FORMAT_Ymd)
            : ""}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderItems = () => {
    return _.map(this.state.tickets, (ticket) => {
      return this.renderRow(ticket);
    });
  };

  renderFilter = () => {
    return (
      <Menu className={"filters-menu"}>
        <Form style={{ padding: "14px" }}>
          <Form.Field>
            <Form.Input
              label="Ticket ID"
              value={this.state.filters.id || ""}
              onChange={(e, data) => {
                this.setFilterState("id", data.value);
              }}
              type="number"
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Author"
              value={this.state.filters.author || ""}
              onChange={(e, data) => {
                this.setFilterState("author", data.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              label="Category"
              placeholder="Category"
              fluid
              search
              selection
              options={this.state.filtersOptions.categories || []}
              value={this.state.filters.category || ""}
              onChange={(e, data) => {
                this.setFilterState("category", data.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              label="Status"
              placeholder="Status"
              fluid
              search
              selection
              options={this.state.filtersOptions.statuses || []}
              value={this.state.filters.status}
              onChange={(e, data) => {
                this.setFilterState("status", data.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              label="Priority"
              placeholder="Priority"
              fluid
              search
              selection
              options={this.state.filtersOptions.priorities || []}
              value={this.state.filters.priority}
              onChange={(e, data) => {
                this.setFilterState("priority", data.value);
              }}
            />
          </Form.Field>
          <div style={{ textAlign: "center" }}>
            <Form.Button
              className="datahive-button"
              color="yellow"
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </Form.Button>
            <Form.Button
              className="datahive-button"
              color="grey"
              type="submit"
              onClick={() => this.setState({ popupOpen: false })}
            >
              Close
            </Form.Button>
          </div>
        </Form>
      </Menu>
    );
  };

  render() {
    let columns = [
      {
        name: "ticket",
        title: "Ticket",
        headerProps: { width: 3 },
      },
      { name: "description", title: "Description", headerProps: { width: 3 } },
      { name: "author", title: "Author", headerProps: { width: 2 } },
      { name: "category", title: "Category", headerProps: { width: 2 } },
      { name: "status", title: "Status", headerProps: { width: 2 } },
      { name: "priority", title: "Priority", headerProps: { width: 2 } },
      { name: "created_at", title: "Created at", headerProps: { width: 2 } },
      {
        name: "last_updated",
        title: "Last updated",
        headerProps: { width: 2 },
      },
    ];
    const headerButtons = (
      <div className="header">
        <div className="header-filters">
          <Input
            icon={{ name: "search" }}
            placeholder="Ticket title"
            onChange={(e, data) =>
              this.debouncedFilterChange("title", data.value)
            }
            value={this.state.filters.title || ""}
          />
          <Popup
            on="click"
            position={"bottom center"}
            style={{ padding: 0 }}
            trigger={
              <Button
                className="datahive-button"
                color="grey"
                onClick={() => {
                  this.setState({ popupOpen: true });
                }}
              >
                Filter
                <Icon name="filter" />
              </Button>
            }
            content={this.renderFilter()}
            open={this.state.popupOpen}
          />
          <span className={"reset-filters-button"} onClick={this.resetFilters}>
            Reset all filters
          </span>
        </div>

        <Link to={urls.SUPPORT_TICKET_NEW}>
          <Button
            className="ui button new-ticket-btn datahive-button"
            color="yellow"
            floated="right"
            content={"Add new ticket"}
          />
        </Link>
      </div>
    );

    if (this.state.displaySpinner) {
      return (
        <div className="tab-panel">
          <LoaderSpinner />
        </div>
      );
    }

    return (
      <ContainerList
        className="TicketAdminList"
        renderRow={this.renderRow}
        items={this.state.tickets}
        columns={columns}
        button={headerButtons}
        totalPages={this.state.totalPages}
        pageChange={this.pageChange}
        filters={this.state.filters.page || "1"}
        isLoading={this.state.displaySpinner}
      />
    );
  }
}

export default TicketAdminList;
