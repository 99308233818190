import React, {Component} from "react";
import "react-dates/initialize";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import _ from "lodash";
import ajax from "../../../helpers/ajax";
import config from "../../../config/config";
import {toastr} from "react-redux-toastr";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {
  NATIVE_OPTIONS,
  REQUIRED_FIELDS_COMPANY,
  REQUIRED_FIELDS_INDIVIDUAL,
  REQUIRED_FIELDS_USERS
} from "../PriceTariffsFunctions";
import PropTypes from "prop-types";

import "../../Tasks.css";
import "react-dates/lib/css/_datepicker.css";
import "./PriceTariffsModal.css";
import DragAndDropSelector from "../../../components/simple/DragAndDropSelector/DragAndDropSelector";

class PriceTariffsModal extends Component {

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    companies: PropTypes.array,
    currencies: PropTypes.array.isRequired,
    data: PropTypes.object,
    filterByUser: PropTypes.bool,
    languages: PropTypes.array.isRequired,
    taskTypes: PropTypes.object.isRequired,
    isIndividual: PropTypes.bool.isRequired,
    username: PropTypes.string,
    userTypes: PropTypes.array,
    users: PropTypes.array
  };

  static DEFAULT_STATE = {
    id: null,
    company: [],
    language: [],
    native: null,
    user_type: null,
    task_subtype: null,
    task_type: null,
    date: null,
    price: null,
    users: [],
    currency: null,
    errors: {
      company: false,
      user: false,
      language: false,
      native: false,
      user_type: false,
      task_subtype: false,
      task_type: false,
      date: false,
      price: false,
      currency: false
    }
  };

  constructor(props) {
    super(props);
    this.state = PriceTariffsModal.DEFAULT_STATE;
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState(this.props.data);
    }
  }

  setDataWithError = (data, name) => {
    let newState = {...this.state};
    if (!!data) {
      newState.errors[name] = false;
    }
    newState[name] = data;
    this.setState(newState);
  };

  getSubtypeOptions = () => {
    return this.state.task_type ?
      _.map(this.props.taskTypes[this.state.task_type], (subtype) => {
        return {
          text: subtype.name,
          value: subtype.name,
          key: subtype.name
        };
      }) : [];
  };

  formatArrayValue = (value) => {
    if (!Array.isArray(value)) {
      value = value === null ? [] : [value];
    }
    return value;
  };

  addPriceTariffs = (e, data) => {
    let req_fields;
    if (this.props.isIndividual || (this.props.data && this.props.data.user)) {
      req_fields = REQUIRED_FIELDS_INDIVIDUAL;
    } else if (this.props.filterByUser && !this.props.data) {
      req_fields = REQUIRED_FIELDS_USERS;
    } else {
      req_fields = REQUIRED_FIELDS_COMPANY;
    }

    let postData = _.pick(this.state, req_fields);
    postData = {
      ...postData,
      company: this.formatArrayValue(this.state.company),
      id: this.state.id,
      language: this.formatArrayValue(this.state.language),
      native: this.state.native ? this.state.native === "native" : null,
      username: this.props.username || this.state.user || null,
      users: this.formatArrayValue(this.state.users)
    };

    let errors = {};
    _.map(req_fields, (key) => {
      let value = postData[key];
      if (value === null || (Array.isArray(value) && !value.length)) {
        errors[key] = true;
      }
    });

    if (!_.isEmpty(errors)) {
      this.setState({errors});
      toastr.error("All fields are required!");
      return;
    }

    let request;
    if (postData.id) {
      request = ajax.patch(config.PRICE_TARIFFS + postData.id + "/", {data: postData});
    } else {
      request = ajax.post(config.PRICE_TARIFFS, {data: postData});
    }

    request.then((response) => {
      if (response.status === 200) {
        toastr.success("Success", "Tariffs saved!");
        if (data.value === "close") {
          this.props.closeModal();
        } else if (data.value === "addAnother") {
          this.setState(PriceTariffsModal.DEFAULT_STATE);
        }
      }
    }).catch((error) => {
      toastr.error("Internal server error.", error.response.data);
    });
  };

  render() {
    const {companies, data, filterByUser, users} = this.props;

    return (
      <Modal
        dimmer={true}
        open={true}
        size={"large"}
      >
        <Modal.Header>
          <Icon name={"external"}/>
          {data && (data.user || data.company) ? data.user || data.company : "New"}
          <span>&nbsp;&nbsp;</span> Price Tariff
          <div className={"close-button"} onClick={this.props.closeModal}>
            <Icon name={"window close"}/>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form
            autoComplete={"off"}
            size={"large"}
          >
            <Form.Group>
              {companies && !filterByUser
                ? <Form.Dropdown
                  selection
                  multiple={!data}
                  id={"company"}
                  label={"Company"}
                  error={this.state.errors.company || false}
                  options={companies}
                  value={this.state.company || (!data ? [] : "")}
                  onChange={(e, data) => this.setDataWithError(data.value, "company")}
                />
                : null
              }
              {users && filterByUser && !data
                ? <div className={"users"}>
                  <label>Users</label>
                  <DragAndDropSelector
                    choices={users || []}
                    choiceValue={"username"}
                    filterable
                    selectAll
                    selected={this.state.users || []}
                    afterSelectionChange={(users) => this.setDataWithError(users, "users")}
                  />
                </div>
                : null
              }
            </Form.Group>
            <Form.Group>
              <DatesRangeInput
                width={5}
                label={"Date"}
                dateFormat={"YYYY-MM-DD"}
                name={"date"}
                placeholder={"Start Date - End Date"}
                error={this.state.errors.date || false}
                value={this.state.date || ""}
                iconPosition={"left"}
                onChange={(e, data) => this.setDataWithError(data.value, "date")}
              />
              <Form.Dropdown
                selection
                id={"taskType"}
                label={"Task type"}
                error={this.state.errors.task_type || false}
                options={_.map(Object.keys(this.props.taskTypes), (type) => {
                  return {
                    text: type,
                    value: type,
                    key: type.id
                  };
                })}
                value={this.state.task_type || ""}
                onChange={(e, data) => this.setDataWithError(data.value, "task_type")}
              />
              <Form.Dropdown
                selection
                search
                id={"taskSubType"}
                label={"Task subtype"}
                disabled={!this.state.task_type}
                error={this.state.errors.task_subtype || false}
                options={this.getSubtypeOptions()}
                value={this.state.task_subtype || ""}
                onChange={(e, data) => this.setDataWithError(data.value, "task_subtype")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Dropdown
                selection
                search
                multiple={!data}
                id={"language"}
                label={"Language"}
                error={this.state.errors.language || false}
                options={this.props.languages}
                value={this.state.language || (!data ? [] : "")}
                onChange={(e, data) => this.setDataWithError(data.value, "language")}
              />
              <Form.Dropdown
                selection
                id={"native"}
                label={"Native"}
                error={this.state.errors.native || false}
                options={NATIVE_OPTIONS}
                value={this.state.native || ""}
                onChange={(e, data) => this.setDataWithError(data.value, "native")}
              />
              {!this.props.isIndividual && !filterByUser
                ? <Form.Dropdown
                  selection
                  id={"userType"}
                  label={"User Type"}
                  error={this.state.errors.user_type || false}
                  options={this.props.userTypes}
                  value={this.state.user_type || ""}
                  onChange={(e, data) => this.setDataWithError(data.value, "user_type")}
                />
                : null
              }
            </Form.Group>
            <Form.Group>
              <Form.Input
                id={"price"}
                label={"Price"}
                type="number"
                error={this.state.errors.price || false}
                value={this.state.price || ""}
                onChange={(e, data) => {
                  let {value} = data;
                  if (value >= 0) {
                    let dot_position = value.indexOf(".");
                    if (dot_position !== -1 && value.length - dot_position > 3) {
                      value = value.slice(0, value.length - 1);
                    } else if (dot_position === 0) {
                      value = "0" + value;
                    }
                    this.setDataWithError(value, "price");
                  }
                }}
              />
              <Form.Dropdown
                selection
                search
                id={"currency"}
                label={"Currency"}
                options={this.props.currencies}
                error={this.state.errors.currency || false}
                value={this.state.currency || ""}
                onChange={(e, data) => this.setDataWithError(data.value, "currency")}
              />
            </Form.Group>
            <Form.Group>
              <Button color="green" className="datahive-button" value={"close"}
                onClick={this.addPriceTariffs}>Save</Button>
              <Button color="green" className="datahive-button" value={"addAnother"}
                onClick={this.addPriceTariffs}>Save and add another</Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default PriceTariffsModal;
