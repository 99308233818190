import _ from "lodash";

import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {Button, Form, Input, Menu, Popup, Segment, Table} from "semantic-ui-react";
import fs from "file-saver";
import qs from "query-string";
import PropTypes from "prop-types";

import {ERRORS_CLEAR, ERRORS_NEW} from "../../actions/types/types_errors";
import FilteredComponent from "../../components/FilteredComponent";
import Pagination from "../../components/Pagination";
import LoaderSpinner from "../../components/LoaderSpinner";
import Stars from "../../components/simple/Stars/Stars";
import {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";
import config from "../../config/config";
import urls from "../../config/frontend_urls";
import ajax from "../../helpers/ajax";
import {getSorted, updateFiltersStateless, yesNoOptions} from "../../helpers/filters";
import {LANGUAGE_SKILLS, LANGUAGES} from "../../helpers/languages";
import {formatPhoneNumber} from "../../helpers/utils";

import "react-dropdown-tree-select/dist/styles.css";
import "./UsersList.css";


export class UsersList extends FilteredComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filtersOptions: {
        languages: null
      },
      filters: {},
      // Saved in state to debounce search.
      name: _.get(qs.parse(props.location.search), "name") || "",
      totalPages: 1,
      displaySpinner: false,
      exportLoading: false,
    };

    this.updateFiltersStateless = updateFiltersStateless.bind(this);
    this.getSorted = getSorted.bind(this);
    this.arrayFilters = ["company", "languages", "user_type"];
    this.defaultFilters = {order_by: "username", page: 1};
  }

  componentDidMount() {
    this.getFilterOptions();
    super.componentDidMount();
  }

  getData = (newFilters) => {
    this.setState({display_spinner: true});
    ajax.get(config.USER_DETAILS_USERS, {params: newFilters || this.state.filters})
      .then((response) => {
        if (response.data) {
          this.setState({
            filters: newFilters || this.state.filters,
            usersList: response.data.results,
            totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
          }, () => {
            this.setState({displaySpinner: false});
          });
        } else {
          this.setState({
            filters: newFilters || this.state.filters,
            usersList: [],
            totalPages: 1,
          }, () => {
            this.setState({displaySpinner: false});
          });
        }
      });
  };

  getFilterOptions = () => {
    ajax.get(config.LANGUAGES, {params: {filter: true}})
      .then((response) => {
        this.parseFilterOptions("languages", response.data, "name", "name", "name", false, true);
      });

    ajax.get(config.COMPANY_OPTIONS)
      .then((response) => {
        if (this.props.auth.is_staff) {
          this.parseFilterOptions("company", response.data, "name", "name", "name", true, true);
        } else {
          this.parseFilterOptions("company", response.data, "name", "name", "name");
        }
      });

    ajax.get(config.USER_TYPES)
      .then((response) => {
        this.parseFilterOptions("user_type", response.data, "text", "text", "text", false);
      });
  };

  exportUsers = () => {
    this.setState({exportLoading: true});
    const params = this.state.filters;
    ajax.get(
      config.USER_EXPORT_USERS,
      {
        params,
        responseType: "arraybuffer"
      }
    ).then((result) => {
      this.setState({exportLoading: false});
      const blob = new Blob(
        [result.data],
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"}
      );
      fs.saveAs(blob, result.headers["x-filename"]);
    });
  };

  getLanguageOptions = (languages, selected) => {
    return _.map(languages, (lang) => {
      const parentChecked = _.includes(selected, lang.value);
      if (lang.key === "none") {
        return {
          label: "Empty",
          value: lang.value,
          checked: parentChecked,
          className: "language-leaf",
          tagClassName: "pill",
        };
      }
      return {
        label: lang.value,
        value: lang.value,
        checked: parentChecked,
        className: "language-leaf",
        tagClassName: "pill",
        children: _.map(Object.keys(LANGUAGE_SKILLS), (skill) => {
          const value = `${lang.value}-${skill}`;
          const checked = _.includes(selected, value) || parentChecked;
          return {
            label: `${lang.value} ${LANGUAGE_SKILLS[skill]}`,
            value,
            checked,
            className: "language-leaf",
            tagClassName: "pill"
          };
        })
      };
    });
  };

  renderFilter() {
    const isStaff = this.props.auth.is_staff;
    const params = this.state.filters;

    const languageOptions = this.getLanguageOptions(this.state.filtersOptions.languages, params.languages);
    return (
      <Menu secondary className="filter-menu">
        <Form className="filter-form">
          {
            isStaff ?
              <>
                <Form.Dropdown
                  multiple
                  selection
                  fluid
                  search
                  label="Company"
                  placeholder="All"
                  className="company-filter"
                  options={this.state.filtersOptions.company || []}
                  onChange={(e, data) => this.changeFilterInstantly({company: data.value})}
                  value={_.compact(_.concat([], params.company))}/>
                <FormDropdown
                  multiple
                  selection
                  fluid
                  search
                  label="User Type"
                  placeholder="All"
                  className="user-type-filter"
                  options={this.state.filtersOptions.user_type || []}
                  onChange={(e, data) => this.changeFilterInstantly({user_type: data.value})}
                  value={_.compact(_.concat([], params.user_type))}/>
              </> : null
          }
          <Form.Field>
            <label>Language</label>
            <DropdownTreeSelect
              texts={{label: "Language"}}
              className={"language-filter"}
              data={languageOptions}
              onChange={(currentNode, selectedNodes) => {
                const newValue = _.map(selectedNodes, (node) => node.value);
                this.changeFilterInstantly({languages: newValue});
              }}
            />
          </Form.Field>
          <FormDropdown
            fluid
            search
            selection
            label="Linguistic education"
            placeholder="All"
            className="linguistic-education-filter"
            options={yesNoOptions}
            onChange={(e, data) => this.changeFilterInstantly({linguistic_education: data.value})}
            value={params.linguistic_education !== undefined ? params.linguistic_education : null}/>
          <Form.Button
            fluid
            color="red"
            className="clear-filters datahive-button"
            content="Clean"
            onClick={this.resetFilters}/>
        </Form>
      </Menu>);
  }

  renderRow(user) {
    const languages = [];
    _.map(user.languages, (language) => {
      if (LANGUAGES[language.name]) {
        languages.push(LANGUAGES[language.name].toUpperCase() + " - " + LANGUAGE_SKILLS[language.skill]);
      }
    });
    const isStaff = this.props.auth.is_staff;

    return (
      <Table.Row key={user.id}>
        <Table.Cell className="title">
          <Link to={`/user/profile/${user.username}`}>
            {user.username}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {user.first_name}
        </Table.Cell>
        <Table.Cell>
          {user.last_name}
        </Table.Cell>
        <Table.Cell>
          {user.email}
        </Table.Cell>
        <Table.Cell>
          {user.phone_number ? formatPhoneNumber(user.phone_number): ""}
        </Table.Cell>
        {
          isStaff &&
          <React.Fragment>
            <Table.Cell>
              {user.company}
            </Table.Cell>
          </React.Fragment>
        }
        <Table.Cell>
          {languages.join(", ")}
        </Table.Cell>
        {
          isStaff &&
          <Table.Cell>
            <Stars count={user.rating_avg ? user.rating_avg : 0}/>
            <div className="tooltip">
              <span className="tooltiptext">{`${user.rating_avg} (${user.rating_count})`}</span>
            </div>
          </Table.Cell>
        }
      </Table.Row>
    );
  }

  render() {
    const params = this.state.filters;

    const dataLoaded = !!this.state.usersList;
    let rows = null;
    if (dataLoaded) {
      rows = _.map(this.state.usersList, (user) => {
        return this.renderRow(user);
      });
    }
    const isSuperuser = this.props.auth.is_superuser;
    const isStaff = this.props.auth.is_staff;

    return (
      <Segment.Group className="users-list">
        {(isSuperuser || isStaff) &&
        <Segment textAlign="center" className={"add-many-users datahive-segment"}>
          <Link className={"add-many-users-link"} to={urls.ADD}>
            <Button
              color="yellow"
              className="datahive-button">
              Add many users
            </Button>
          </Link>
          <Link className={"add-many-users-link"} to={urls.ADD_USER}>
            <Button
              color="yellow"
              className="datahive-button">
              Add user
            </Button>
          </Link>
        </Segment>}
        <Segment.Group>
          <Segment.Group horizontal className="filter-segment">
            <Segment textAlign="center" className="datahive-segment">
              <Input
                fluid
                className="name-filter"
                onChange={(e, data) => {
                  this.searchChange(data.value, "name");
                }}
                value={this.state.name || ""}
                action={
                  <Popup
                    on="click" position="bottom center"
                    className="filter-popup"
                    trigger={
                      <Button
                        color="dark-grey"
                        className="filter-button datahive-button"
                        size="tiny"
                        floated="right"
                        content="More"
                        icon="filter"/>
                    }
                    content={this.renderFilter()}
                  />
                }
                icon="search"
                iconPosition="left"
                placeholder="Search by first name, last name, username, email, phone number or user hash code..."
              />
            </Segment>
            {(isSuperuser || isStaff) &&
            <Segment className="export-segment datahive-segment" textAlign="center">
              <Button
                fluid
                color="black"
                className="export-button datahive-button"
                loading={this.state.exportLoading}
                onClick={this.exportUsers}
              >
                Export users
              </Button>
            </Segment>}
          </Segment.Group>
          <Segment className="table-segment datahive-segment">
            {(dataLoaded && !this.state.displaySpinner) ?
              <Table sortable fixed className="SortableTable">
                <Table.Header className={"header"} fullWidth>
                  <Table.Row>
                    <Table.HeaderCell
                      className="sort-username"
                      sorted={this.getSorted("username")}
                      onClick={() => this.updateSorting("username")}
                      width={4}>Username</Table.HeaderCell>
                    <Table.HeaderCell
                      className="sort-first_name"
                      sorted={this.getSorted("first_name")}
                      onClick={() => this.updateSorting("first_name")}
                      width={2}>First name</Table.HeaderCell>
                    <Table.HeaderCell
                      className="sort-last_name"
                      sorted={this.getSorted("last_name")}
                      onClick={() => this.updateSorting("last_name")}
                      width={2}>Last name</Table.HeaderCell>
                    <Table.HeaderCell
                      className="sort-email"
                      sorted={this.getSorted("email")}
                      onClick={() => this.updateSorting("email")}
                      width={2}>Email</Table.HeaderCell>
                    <Table.HeaderCell
                      className="sort-phone_number"
                      sorted={this.getSorted("phone_number")}
                      onClick={() => this.updateSorting("phone_number")}
                      width={2}>Phone number</Table.HeaderCell>
                    {
                      isStaff &&
                      <React.Fragment>
                        <Table.HeaderCell
                          className="sort-company"
                          sorted={this.getSorted("company")}
                          onClick={() => this.updateSorting("company")}
                          width={2}>Company</Table.HeaderCell>
                      </React.Fragment>
                    }
                    <Table.HeaderCell
                      width={1}>Languages</Table.HeaderCell>
                    {isStaff &&
                    <Table.HeaderCell
                      className="sort-rating_avg"
                      sorted={this.getSorted("rating_avg")}
                      onClick={() => this.updateSorting("rating_avg")}
                      width={1}>Average rating</Table.HeaderCell>
                    }
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rows}
                </Table.Body>
              </Table>
              : <LoaderSpinner/>
            }
            {!dataLoaded && <LoaderSpinner/>}
            <Pagination
              activePage={params.page}
              onPageChange={(e, {activePage}) => this.changeFilterInstantly({page: activePage})}
              totalPages={this.state.totalPages}
            />
          </Segment>
        </Segment.Group>
      </Segment.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    errorsNew: (message) => dispatch({
      type: ERRORS_NEW,
      errors: message
    }),
    errorsClear: () => dispatch({
      type: ERRORS_CLEAR,
    })
  };
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(UsersList));
