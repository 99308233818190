import React, {Component} from "react";
import {toastr} from "react-redux-toastr";
import {Button} from "semantic-ui-react";
import PropTypes from "prop-types";

import AddUserForm from "./AddUserForm";
import PasswordRequirements from "../Auth/PasswordRequirements";
import BottomBar from "../../components/BottomBar";
import urls from "../../config/frontend_urls";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {getPossibleDropdownValues, formatErrorMessage} from "../../helpers/utils";

import "./AddUser.css";


const defaultFormData = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  type: "",
  company: "",
  division: ""
};

class AddUser extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      form: defaultFormData,
      displayModal: false,
      companies: [],
      divisions: [],
      userTypes: [],
      message: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    ajax.get(config.COMPANY_OPTIONS, {params: {"active_companies": true}})
      .then((response) => {
        const companies = getPossibleDropdownValues("id", "name", response.data, true);
        this.setState({companies: companies});
      });
    ajax.get(config.ALL_DIVISIONS)
      .then((response) => {
        const divisions = getPossibleDropdownValues("id", "name", response.data);
        this.setState({divisions: divisions});
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        const userTypes = getPossibleDropdownValues("value", "text", response.data);
        this.setState({userTypes});
      });
  };

  addUser = () => {
    const {password, confirmPassword} = this.state.form;
    if (password !== confirmPassword) {
      toastr.error("Error", "Passwords don't match.");
      return;
    }
    const form = this.state.form;
    const data = {
      username: form.username,
      email: form.email,
      password: form.password,
      company: form.company,
      division: form.division,
      type: form.type,
    };
    ajax.post([config.ADD_USER], {data})
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push(urls.USER_PROFILE.replace(":username", this.state.form.username));
          toastr.success("Success", "User saved!");
        }
      }).catch((error) => {
        if (Array.isArray(error.response.data)) {
          const message = error.response.data;
          this.setState({message});
        } else {
          toastr.error("Error", formatErrorMessage(error.response.data));
        }
      });
  };

  onChangeForm = (obj) => {
    const form = this.state.form;
    return this.setState({form: {...form, ...obj}});
  }

  render() {
    const {message} = this.state;

    return <div className="add-user">
      <AddUserForm
        companies={this.state.companies}
        divisions={this.state.divisions}
        userTypes={this.state.userTypes}
        onChangeForm={this.onChangeForm}/>
      <PasswordRequirements {...(message.length && {message})}/>
      <BottomBar
        panelRight={
          <Button content="Save" className="datahive-button" color="green" onClick={this.addUser}/>}/>
    </div>;
  }
}

export default AddUser;
