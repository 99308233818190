import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {Container} from "semantic-ui-react";
import PropTypes from "prop-types";

import ajax from "../helpers/ajax";
import config from "../config/config";


class AudioHunterRegistrationSuccess extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      confirmed: false,
    };
  }

  componentDidMount() {
    ajax.post([config.CONFIRM_WITH_TOKEN, this.props.match.params.token])
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Welcome to Audio hunter!");
          this.setState({confirmed: true});
        }
      })
      .catch(() => {
        toastr.error("Something went wrong!");
      })
    ;
  }

  render() {
    if (!this.state.confirmed) {
      return null;
    }

    return (
      <Container className="AudioHunterRegistrationSuccess" style={{textAlign: "center"}}>
        <h1>
          Congratulations! You have successfully registered into Daj Głos app.
        </h1>
        <h1>
          Please log into the app now, using the login and password you have used during the registration process.
        </h1>
      </Container>
    );
  }
}

export default withRouter(AudioHunterRegistrationSuccess);
