import React, {Component} from "react";
import PropTypes from "prop-types";
import {Container, Header} from "semantic-ui-react";
import {withRouter} from "react-router-dom";

import ModalConfirm from "./ModalConfirm";
import LoaderSpinner from "./LoaderSpinner";
import BottomBar from "./BottomBar";
import "./ContainerDetail.css";


class ContainerDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    details: PropTypes.element.isRequired,
    url: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    displaySpinner: PropTypes.bool.isRequired,
    urlNew: PropTypes.string,
    button: PropTypes.element,
    displayDeleteModal: PropTypes.bool,
    panelRight: PropTypes.element,
    panelLeft: PropTypes.element,
    title: PropTypes.string,
    toggleModal: PropTypes.func,
  };

  render() {
    const {item, location, urlNew, details, displaySpinner, displayDeleteModal, panelRight, panelLeft,
      title, toggleModal, button} = this.props;
    const content = <span>{item.name}</span>;

    return (
      <Container className={"ContainerDetail"}>
        {!location.pathname.includes(urlNew) &&
        <div className="header">
          <Header size="large">{item.name}</Header>
        </div>
        }
        {displaySpinner && <LoaderSpinner/>}
        {!displaySpinner && details}
        <BottomBar
          panelRight={panelRight}
          panelLeft={panelLeft}
        />
        {displayDeleteModal &&
        <ModalConfirm
          id={"deleteModal"}
          title={title}
          content={content}
          closeModal={toggleModal}
          button={button}
        />}
      </Container>
    );
  }
}

export default withRouter(ContainerDetail);
