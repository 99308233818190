import React, {useState, useCallback, useRef} from "react";
import {Group} from "react-konva/lib/ReactKonvaCore";
import {toastr} from "react-redux-toastr";
import PropTypes from "prop-types";

import Edge from "../shared/Edge";
import Label from "../shared/Label";

const validateShape = (newPoints, canvasSize) => {
  for (let i = 0; i < newPoints.length; i++) {
    if (newPoints[i] < 0 ||
      (i % 2 === 0 && newPoints[i] > canvasSize.width) ||
      (i % 2 === 1 && newPoints[i] > canvasSize.height)) {
      return true;
    }
  }
  return false;
};

const Shape = ({
  type,
  line,
  idx,
  canvasSize,
  imageRef,
  isDisabled,
  selectedSegment,
  onSegmentSelection,
  onDragEnd
}) => {
  const shapeRef = useRef();

  const handleGetAbsolutePoints = useCallback(() => {
    const groupPosition = shapeRef.current.getAbsolutePosition();
    return shapeRef.current.children[0].children[0].attrs.points.map((cord, i) => {
      if (i % 2 === 0) {
        return cord + groupPosition.x;
      } else {
        return cord + groupPosition.y;
      }
    });
  }, [shapeRef]);

  const [copiedPosition, setCopiedPosition] = useState();
  const [isDraggable, setIsDraggable] = useState(false);

  const handlePolygonDrop = (e) => {
    const newPoints = handleGetAbsolutePoints();
    const error = validateShape(newPoints, canvasSize);
    if (error) {
      toastr.error("Out of bounds", "Currently moved shape was placed out of bounds");
      e.target.position(copiedPosition);
      onDragEnd(handleGetAbsolutePoints());
    } else {
      e.target.position({x: 0, y: 0});
      onDragEnd(newPoints);
    }
    onSegmentSelection({idx, active: false});
    setIsDraggable(false);
  };

  const handleCursorChange = useCallback((cursorStyle) => {
    if (!isDisabled && imageRef.current) {
      imageRef.current.style.cursor = cursorStyle;
      if (cursorStyle === "move" && !selectedSegment.active) {
        onSegmentSelection({idx, active: false});
      } else if (!selectedSegment.active) {
        onSegmentSelection({idx: null, active: false});
      }
    }
  }, [idx, imageRef, isDisabled, onSegmentSelection, selectedSegment.active]);

  return <Group
    ref={shapeRef}
    draggable={isDraggable}
    onDragStart={() => setCopiedPosition(shapeRef.current.absolutePosition())}
    onDragEnd={handlePolygonDrop}
    opacity={isDisabled ? 0.5 : 1}>
    <Edge points={line} color={type.color}/>
    <Label
      x={line[0]}
      y={line[1]}
      inverted={line[1] < 15}
      name={type.name}
      onCursorChange={handleCursorChange}
      onSetIsDraggable={!isDisabled ? setIsDraggable : () => null}
      onSegmentSelection={() => !isDisabled && onSegmentSelection({idx, active: true})}
      onRightClick={() => onSegmentSelection({idx, active: true})}/>
  </Group>;
};

Shape.propTypes = {
  type: PropTypes.shape({
    name: PropTypes.string,
    vertices: PropTypes.number,
    color: PropTypes.string,
  }).isRequired,
  line: PropTypes.arrayOf(PropTypes.number),
  idx: PropTypes.number,
  canvasSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }).isRequired,
  imageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  isDisabled: PropTypes.bool,
  selectedSegment: PropTypes.shape({
    idx: PropTypes.number,
    active: PropTypes.bool,
  }).isRequired,
  onSegmentSelection: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

export default Shape;
