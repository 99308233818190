import _ from "lodash";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import Edge from "../shared/Edge";
import {createPoint, check2LinesIntersection, canSnap} from "../util/math";

const ShapeCreator = ({type, imageRef, selectedSegment, isCreationMode, onAddSegment, onSetIsCreationMode}) => {
  const [line, setLine] = useState([]);

  useEffect(() => {
    let node = imageRef.current;

    const handleMouseDown = (e) => {
      if (e.which !== 1 || selectedSegment.idx !== null) {
        return;
      }
      onSetIsCreationMode(true);
      setLine([e.offsetX, e.offsetY]);
    };

    const handleMouseMove = (e) => {
      if (!isCreationMode) {
        return;
      }
      setLine((line) => {
        let newLine = line.concat(e.offsetX, e.offsetY);
        if (!canSnap(
          createPoint(e.offsetX, e.offsetY), createPoint(line[line.length - 2], line[line.length - 1])
        )) {
          for (let i = 0; i < newLine.length - 2; i+=2) {
            for (let j = 2; j < newLine.length; j+=2) {
              if (check2LinesIntersection(
                createPoint(newLine[i], newLine[i+1]),
                createPoint(newLine[i+2], newLine[i+3]),
                createPoint(newLine[j], newLine[j+1]),
                createPoint(newLine[j+2], newLine[j+3]),
              )) {
                const validFragment = newLine.slice(i, j);
                validFragment.push(validFragment[0], validFragment[1]);
                Promise.resolve().then(() => onAddSegment(validFragment)); //crazy hack, huh?
                return [];
              }
            }
          }
          return newLine;
        }
        return line;
      });
    };

    const throttledMouseMove = _.throttle(handleMouseMove, 50, {leading: true, trailing: false});

    const handleMouseUp = (e) => {
      if (e.which !== 1) {
        return;
      }
      onSetIsCreationMode(false);
      setLine([]);
    };

    if (node) {
      node.addEventListener("mousedown", handleMouseDown);
      node.addEventListener("mousemove", throttledMouseMove);
      node.addEventListener("mouseup", handleMouseUp);
      node.addEventListener("mouseleave", handleMouseUp);
    }
    return () => {
      if (node) {
        node.removeEventListener("mousedown", handleMouseDown);
        node.removeEventListener("mousemove", throttledMouseMove);
        node.removeEventListener("mouseup", handleMouseUp);
        node.removeEventListener("mouseleave", handleMouseUp);
      }
    };
  }, [imageRef, selectedSegment, isCreationMode, onAddSegment, onSetIsCreationMode]);


  return <Edge points={line} color={type.color}/>;
};

ShapeCreator.propTypes = {
  type: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    thickness: PropTypes.number
  }).isRequired,
  selectedSegment: PropTypes.shape({
    idx: PropTypes.number,
    active: PropTypes.bool,
  }).isRequired,
  isCreationMode: PropTypes.bool.isRequired,
  imageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  onAddSegment: PropTypes.func.isRequired,
  onSetIsCreationMode: PropTypes.func.isRequired,
};

export default ShapeCreator;
