import _ from "lodash";
import React, {Component} from "react";
import fs from "file-saver";
import moment from "moment";
import {toastr} from "react-redux-toastr";
import {Button, Container, Grid, Table, Input} from "semantic-ui-react";
import {DateTimeInput} from "semantic-ui-calendar-react";

import Dropdown from "../../components/simple/Dropdown/LevenDropdown";
import {FormLevenDropdown as FormDropdown} from "../../components/simple/Dropdown/LevenDropdown";
import SortableTable from "../../components/simple/SortableTable/SortableTable";
import config from "../../config/config";
import ajax from "../../helpers/ajax";
import {getPossibleDropdownValues} from "../../helpers/utils";
import {yesNoOptions} from "../../helpers/filters";

import "./GenerateReports.css";


class GenerateReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      reportOptions: {},
      selectedReportName: "",
      selectedReport: "",
      reportsList: [],
      idRaport: "",
      params: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.getOptions();
  }

  getData = () => {
    ajax.get(config.REPORTS_LIST)
      .then((response) => {
        this.setState({
          reportsList: response.data,
        });
        this.intervalID = setTimeout(this.getData.bind(this), 10000);
      });
  }

  getOptions = () => {
    let options = [];
    ajax.get(config.REPORTS_OPTIONS)
      .then((response) => {
        const keys = Object.keys(response.data);
        this.setState({
          reports: response.data,
          reportOptions: keys,
        });
      });
    ajax.get(config.USER_TYPES)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({userTypes: response.data});
      });
    ajax.get(config.TASKS_ALL)
      .then((response) => {
        options = getPossibleDropdownValues("pk", "title", response.data);
        this.setState({taskTypes: options});
      });
    ajax.get(config.LINGUISTS_MONTH_REPORTS_DEADLINE)
      .then((response) => {
        options = getPossibleDropdownValues("name", "name", response.data);
        this.setState({deadlines: options});
      });
  }

  getList = () => {
    ajax.get(config.REPORTS_LIST)
      .then((response) => {
        this.setState({
          reportsList: response.data,
        });
      });
  }

  generateReport = () => {
    const params = this.state.params;
    const data = {
      ...params,
      method_name: this.state.selectedReport["method_name"]
    };
    ajax.post(config.GERERATE_REPORT, {data})
      .then((response) => {
        toastr.success("Success", "Please wait a few seconds and download the selected raport.");
        this.setState({idRaport: response.data["status"]["id"]});
        this.getList();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          toastr.error("Error!\n", error.response.data);
        }
      });
  }

  downloadReport = (id) => {
    const data = {
      filename: id
    };
    ajax.post(config.REPORTS_LIST, {
      data,
      "responseType": "arraybuffer"
    })
      .then((result) => {
        toastr.success("Success", "File downloaded.");
        const blob = new Blob(
          [result.data],
          {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"}
        );
        fs.saveAs(blob, result.headers["x-filename"]);
      });
  }

  onReportChange = (e, data) => {
    const selectedReport = data.value;
    const nameParams = this.state.reports[selectedReport].parameters;
    const emptyParams = {};
    _.map(nameParams, (key) =>
      emptyParams[key.name] = ""
    );
    this.setState({
      selectedReportName: selectedReport,
      selectedReport: this.state.reports[selectedReport],
      params: emptyParams,
      idRaport: "",
    },
    this.renderParams
    );
  };

  static tableColumns = [
    {
      title: "No.",
      key: "no",
      headerProps: {
        textAlign: "center",
        width: 1
      },
      id: 0
    },
    {
      title: "Name",
      key: "name",
      headerProps: {
        textAlign: "center",
        width: 6
      },
      id: 1
    },
    {
      title: "Generation Time",
      key: "generationTime",
      headerProps: {
        textAlign: "center",
        width: 2
      },
      id: 2
    },
    {
      title: "Status",
      key: "status",
      headerProps: {
        textAlign: "center",
        width: 2
      },
      id: 3
    },
    {
      title: "Action",
      key: "action",
      headerProps: {
        textAlign: "center",
        width: 1
      },
      id: 4
    }
  ];

  setParams = (path, value) => {
    const params = {...this.state.params};
    _.set(params, path, value);
    this.setState({
      params: params
    });
  };

  renderParams = () => {
    const parameters = this.state.selectedReport["parameters"];
    return (
      _.map(parameters, (param) => (
        <div key={"param-" + param.name}>
          {param.fieldType === "type" && <FormDropdown
            className="type params"
            placeholder="Select user type"
            fluid search selection multiple
            options={this.state.userTypes}
            onChange={(e, data) => this.setParams("type", data.value)}
          />}
          {param.fieldType === "datetime" && <DateTimeInput
            autoComplete="off"
            dateFormat={"YYYY-MM-DD"}
            placeholder={"Date " + param.name}
            key={"date"}
            name={param.name}
            popupPosition="bottom center"
            closable
            className="datetime"
            onChange={(e, data) => {
              this.setParams(param.name, data.value);
            }}
            value={this.state.params[param.name] || ""}
          />}
          {param.fieldType === "task" && <FormDropdown
            className="params"
            placeholder="Select task"
            fluid search selection
            name={param.name}
            options={this.state.taskTypes}
            onChange={(e, data) => this.setParams(param.name, data.value)}
          />}
          {param.fieldType === "tasks" && <FormDropdown
            className="params"
            placeholder="Select tasks"
            fluid search selection multiple
            name={param.name}
            options={this.state.taskTypes}
            onChange={(e, data) => this.setParams(param.name, data.value)}
          />}
          {param.fieldType === "input" && <Input
            placeholder={"Fill name of " + param.name}
            onChange={(e) => this.setParams(param.name, e.target.value)}
          />}
          {param.fieldType === "deadline" && <FormDropdown
            className="params"
            placeholder="Select deadlines"
            fluid search selection
            name={param.name}
            options={this.state.deadlines}
            onChange={(e, data) => this.setParams(param.name, data.value)}
          />}
          {param.fieldType === "boolean" && <FormDropdown
            className="params"
            placeholder="Is active"
            fluid selection
            name={param.name}
            options={yesNoOptions}
            onChange={(e, data) => this.setParams(param.name, data.value)}
          />}
        </div>
      ))
    );
  }

  checkStatus = (status) => {
    const isCompleted = status === 3;
    return isCompleted ? "Completed" : "Generating...";
  }

  render() {
    const idRaport = this.state.idRaport;

    const rows = _.map(this.state.reportsList, (report) => (
      <Table.Row active={idRaport === report.id} key={"report" + report.id}>
        <Table.Cell textAlign="center">{report.id}</Table.Cell>
        <Table.Cell textAlign="center">{report.method_name}</Table.Cell>
        <Table.Cell textAlign="center">
          {report.time_end ? moment(report.time_end).format(config.FORMAT_dmY_Hm) : ""}
        </Table.Cell>
        <Table.Cell textAlign="center">{this.checkStatus(report.status)}</Table.Cell>
        <Table.Cell textAlign="center">
          {report.progress === 100 ? <Button
            active
            basic
            size="tiny"
            icon="cloud download"
            id={report.filemane}
            onClick={() => this.downloadReport(report.filename)}
          /> : null}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Container className="GenerateReports">
        <div className="reports">
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column className="ui center aligned">
                Name report
                <Dropdown
                  className="reports-dropdown"
                  placeholder="select report"
                  fluid search selection
                  options ={getPossibleDropdownValues("name", "name", this.state.reports)}
                  value={this.state.selectedReportName ? this.state.selectedReportName : null}
                  onChange={this.onReportChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="ui center aligned">
                <Button className="datahive-button" color="yellow" onClick={this.generateReport}>Generate report</Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              {this.state.selectedReportName && <div>Parameters:</div>}
            </Grid.Row>
            <div className="parameters">
              {this.state.selectedReport && this.renderParams()}
            </div>
          </Grid>
        </div>
        <SortableTable
          sorted=""
          columns={GenerateReports.tableColumns}
          tableProps={{compact: true, className: "SortableTable"}}
        >
          <Table.Body>
            {rows}
          </Table.Body>
        </SortableTable>
      </Container>
    );
  }
}

export default GenerateReports;
