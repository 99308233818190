import React from "react";
import { Grid } from "semantic-ui-react";
import { getWidget } from "../widgetUtils";
import { WidgetEditor } from "../WidgetEditor";

import "./RichTextEditorReadOnlyWidgetEditor.css";
import WidgetNameInput from "../components/WidgetNameInput/WidgetNameInput";
import RichTextEditor from "../../RichTextEditor";

class RichTextEditorReadOnlyWidgetEditor extends WidgetEditor {
  static header = "HTML Fragment";

  onChange = (editorValue) => {
    this.updateWidgetConfig((widgetConfig) => {
      widgetConfig["value"] = editorValue;
    });
    this.setState({ editorValue: editorValue });
  };

  render() {
    const { editorValue } = this.state;
    let value;
    const config = getWidget(this.props.getWidgetConfig(), this.state.widgetId),
      vals = this.props.widgetVals;

    if (!!config.value) {
      value = config.value;
    } else {
      value = vals[this.state.widgetId] ? vals[this.state.widgetId] : "";
    }

    return (
      <Grid className={"RichTextEditorReadOnlyWidgetEditor"}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <WidgetNameInput
              widgetId={this.state.widgetId}
              updateId={this.updateId}
              checkIdUnique={this.props.checkIdUnique}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <RichTextEditor
              content={value}
              onChange={(content) => this.onChange(content)}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderButtons()}
        <div
          className="content__editor"
          dangerouslySetInnerHTML={{ __html: editorValue }}
        />
      </Grid>
    );
  }
}

export default RichTextEditorReadOnlyWidgetEditor;
