import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import JSONEditor from "jsoneditor";


export default class JSONEditorReact extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired, //object to display.
    mode: PropTypes.string.isRequired, // JSONEditor mode.
    onChange: PropTypes.func, // change handler.
    open: PropTypes.bool, // expand all on render
  };

  constructor(props) {
    super(props);
    this.containerId = _.uniqueId();
    this.container = React.createRef();
    this.editor = null;
  }

  componentDidMount() {
    const options = {
      mode: this.props.mode,
      name: "JSON",
    };

    if (this.props.mode === "tree") {
      // in this mode we need to push the changes to parent, but we do not rerender based on new props.
      options.onChangeJSON = this.props.onChange;
    }

    this.editor = new JSONEditor(this.container.current, options);
    this.editor.set(this.props.value);
    if (this.props.open) {
      this.editor.expandAll();
    }
  }

  onChange = () => {
    try {
      this.props.onChange(this.editor.get());
    } catch (e) {
      console.log(e);// eslint-disable-line no-console
    }
  };

  render() {
    return (
      <div
        ref={this.container}
        id={this.containerId}
        onBlur={ this.props.mode === "tree" || this.props.mode === "view" ? null : this.onChange}
      />
    );
  }
}
