import React, {Component} from "react";
import {Table, Icon, Checkbox} from "semantic-ui-react";
import urls from "../../../config/frontend_urls";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class AnswerListRow extends Component {
  static propTypes = {
    answer: PropTypes.object.isRequired, //answer to display.
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
  };

  render() {
    const answer = this.props.answer;
    return (
      <Table.Row>
        <Table.Cell>
          <Checkbox
            id={answer.id}
            checked={this.props.checked}
            onChange={this.props.handleChange}
          />
        </Table.Cell>
        <Table.Cell>
          <Link to={urls.ADMIN_ANSWER_DETAIL.replace(":id", answer.id)}>
            {answer.id}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Link to={urls.ADMIN_QUESTIONS_DETAIL.replace(":id", answer.question)}>
            {answer.question}
          </Link>
        </Table.Cell>
        <Table.Cell>
          {answer.user}
        </Table.Cell>
        <Table.Cell>
          {answer.is_answered ? <Icon name={"check circle outline"}/> : <Icon name={"circle outline"}/>}
        </Table.Cell>
        <Table.Cell>
          {answer.is_active ? <Icon name={"check circle outline"}/> : <Icon name={"circle outline"}/>}
        </Table.Cell>
        <Table.Cell>
          {answer.is_skipped ? <Icon name={"check circle outline"}/> : <Icon name={"circle outline"}/>}
        </Table.Cell>
        <Table.Cell>
          {answer.is_first ? <Icon name={"check circle outline"}/> : <Icon name={"circle outline"}/>}
        </Table.Cell>
      </Table.Row>
    );
  }
}
