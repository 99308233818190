import _ from "lodash";

import PropTypes from "prop-types";
import React, {Component} from "react";
import {Table} from "semantic-ui-react";

import "./SortableTable.css";

export default class SortableTable extends Component {
  /*
  This class renders a table with headers ready for handling sorting. Expects rows as children, but of course
  you are free to put whatever you want there. Expects a list of JSON objects as config for the table column headers.
   */
  static propTypes = {
    children: PropTypes.any, // typically, rows of the array
    sorted: PropTypes.string, // name of the sorted column
    columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
    /* Columns are a list of objects or just plain strings. If a string is passed, it is used just for the column name.
    * If an object is passed it can have the following fields:
    *   - unsortable - Prevents sorting.
    *   - headerProps - Props that will be passed to the headerCell. Can contain onClick to override the updateSortingProp.
    *   - name - Name of column, used for checking if sorting is by this column.
    *   - title - Displayed text in the column header. */
    tableProps: PropTypes.object, //props for the table object, for example className
    updateSorting: PropTypes.func, //function to update sorting. Gets overriten by onClick in columns[colname].headerProps, if passed.
  };


  shouldComponentUpdate(nextProps, nextState) {
    return _.isEqual(nextProps, this.props) || _.isEqual(nextState, this.state);
  }

  getHeaderCell = (col, idx) => {
    let onClick = null;
    let sorted = null;

    if (typeof (col) === "object") {
      if (!col.unsortable) {
        if (this.props.sorted === col.name) {
          sorted = "ascending";
        } else if (this.props.sorted === `-${col.name}`) {
          sorted = "descending";
        }

        if (this.props.updateSorting) {
          onClick = () => this.props.updateSorting(col.name);
        }
      }

      return (
        <Table.HeaderCell
          key={idx}
          sorted={sorted}
          onClick={onClick}
          content={col.title}
          {...col.headerProps}
        />
      );
    } else {
      if (this.props.updateSorting) {
        onClick = () => this.props.updateSorting(col);
      }

      return (
        <Table.HeaderCell
          key={idx}
          sorted={sorted}
          onClick={onClick}
          content={col}
        />
      );
    }
  };

  render() {

    return (
      <Table sortable celled fixed {...this.props.tableProps}>
        <Table.Header>
          <Table.Row>
            {_.map(this.props.columns, (c, i) => this.getHeaderCell(c, i))}
          </Table.Row>
        </Table.Header>
        {this.props.children}
      </Table>

    );
  }
}
