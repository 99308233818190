import React, {Component} from "react";
import {Table} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import config from "../../config/config";
import {formatSeconds} from "../../helpers/utils";

export default class TaskUserSessionTable extends Component {
  static propTypes = {
    status: PropTypes.object
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  renderRows() {
    let rows = {};
    if (!!this.props.status) {
      rows = _.map(this.props.status.sessions, (i, idx) => {
        return (
          <Table.Row key={"Session" + idx}>
            <Table.Cell>{moment(i["start"]*1000).format(config.FORMAT_Dmy_Hm)}</Table.Cell>
            <Table.Cell>{moment(i["end"]*1000).format(config.FORMAT_Dmy_Hm)}</Table.Cell>
            <Table.Cell>{i["count"]}</Table.Cell>
            <Table.Cell>{formatSeconds(i["mean"])}</Table.Cell>
            <Table.Cell>{formatSeconds(i["length"])}</Table.Cell>
          </Table.Row>
        );
      });
    }
    return rows;
  }

  render() {
    const rows = this.renderRows();

    return (
      <Table selectable sortable celled structured textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell>Answers</Table.HeaderCell>
            <Table.HeaderCell>Mean</Table.HeaderCell>
            <Table.HeaderCell>Total time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <>{rows}</>
        </Table.Body>
      </Table>
    );
  }
}
