import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import "./ErrorPage.css";

class ErrorPage extends Component {
  static propTypes = {
    errorCode: PropTypes.number.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    })
  };

  renderErrorMessage = () => {
    let errorMessage = "";
    if (this.props.errorCode === 404) {
      errorMessage = <span>{"OOPS, THE PAGE YOU ARE"}<br/>{"LOOKING FOR CAN'T BE FOUND!"}</span>;
    } else if (this.props.errorCode === 403) {
      errorMessage = <span>{"SORRY, YOU DON'T HAVE"}<br/>{"PERMISSION TO ACCESS THIS AREA"}</span>;
    }
    return errorMessage;
  };

  render() {
    return (
      <div className={"main-container"}>
        <div className={"central-container"}>
          <FontAwesomeIcon className={"meh-face-icon"} icon={"meh-rolling-eyes"}/>
          <div className={"divider"}/>
          <div className={"information-container"}>
            <span className={"error-code"}>{this.props.errorCode}</span>
            <span className={"error-message"}>{this.renderErrorMessage()}</span>
            <Button positive size={"medium"}
              className={"go-back"}
              content={"Back to homepage"}
              onClick={() => this.props.history.push("/tasks")}/>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorPage);
