import React, {useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {Dropdown as SemanticDropdown, Form} from "semantic-ui-react";
import levenshtein from "levenshtein-edit-distance";

const PROP_TYPES = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  error: PropTypes.bool,
  fluid: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onScroll: PropTypes.func,
  onSearchChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placeholder: PropTypes.string,
  search: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  selection: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.object,
    PropTypes.string, PropTypes.number])
};

const areEqual = (prevProps, nextProps) => {
  return (
    _.isEqual(nextProps.options, prevProps.options) && _.isEqual(nextProps.value, prevProps.value)
    && _.isEqual(nextProps.error, prevProps.error)
  );
};

const LevenDropdown = (props) => {
  /*
	*
  * Dropdown that uses Levenshtein distance to sort matching options.
  * If 'search' prop is not passed it will behave as simple Dropdown.
  * */
  const [_searchInput, setSearchInput] = useState("");
  const [_searchQuery, setSearchQuery] = useState("");

  const levenshteinSearch = (options, query) => {
    const filteredOptions = _.filter(options, (option) => {
      return option.text.toUpperCase().indexOf(query.toUpperCase()) !== -1;
    });
    return _.sortBy(filteredOptions, (option) =>
      levenshtein(option.text.toUpperCase(), query.toUpperCase()));
  };

  const filterOptionsDebounced = _.debounce((newVal) => setSearchQuery(newVal), 1000);

  const handleSearchChange = (event, {searchQuery}) => {
    // Change search-input value.
    setSearchInput(searchQuery);
    // Change search query. Once it's completed, it fires levenshteinSearch.
    if (_searchQuery.length === 0) {
      // Change immediately if input was empty.
      setSearchQuery(searchQuery);
    } else {
      filterOptionsDebounced.cancel();
      filterOptionsDebounced(searchQuery);
    }
    // Call method passed in props.
    props.onSearchChange && props.onSearchChange(event, {searchQuery});
  };

  const handleChange = (event, data) => {
    /* Clear search input if user picked any option. */
    setSearchInput("");
    setSearchQuery("");
    props.onChange && props.onChange(event, data);
  };

  return <SemanticDropdown
    {...props}
    placeholder={props.placeholder || "Choose"}
    search={!!props.search && levenshteinSearch}
    searchQuery={_searchQuery}
    searchInput={{value: _searchInput}}
    onSearchChange={handleSearchChange}
    onChange={handleChange}
  />;
};
LevenDropdown.propTypes = {...PROP_TYPES};
export default React.memo(LevenDropdown, areEqual);

// eslint-disable-next-line react/display-name
export const FormLevenDropdown = React.memo((props) => (
  /*
  * LevenDropdown with label.
  * Adequate to Semantic-UI's Form.Dropdown.
  * */
  <Form.Field>
    <label>{props.label || ""}</label>
    <LevenDropdown {...props}/>
  </Form.Field>
), areEqual);
FormLevenDropdown.propTypes = {
  ...PROP_TYPES,
  label: PropTypes.string
};
