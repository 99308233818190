import _ from "lodash";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Grid, List, Divider } from "semantic-ui-react";
import { MAX_PASSWORD_LENGTH } from "./utils";
import ShowPasswordButton from "./ShowPasswordButton";

import "./PasswordChangeForm.css";

const errorDefaults = [
  "All fields are required",
  "10 or more characters",
  "at least 1 digit",
  "at least 1 lowercase letter",
  "at least 1 uppercase letter",
  "at least 1 special character: ~!@#$%^&*()_+{}\":;'[]"
];

const STYLES = {
  CHECKED: "item checked-requirement",
  UNCHECKED: "item unchecked-requirement",
  NOLINE: "item unchecked-no-decoration"
};

const PasswordChangeForm = (props) => {

  const [visOldPass, setVisOldPass] = useState(false);
  const [visNewPass, setVisNewPass] = useState(false);
  const [visVerifyPass, setVisVerifyPass] = useState(false);

  useEffect(() => {
    setPrevChecks(newChecks.slice());
    setNewChecks(props.checkedErrors.slice());
  }, [props.checkedErrors]);

  const [prevChecks, setPrevChecks] = useState(Array(props.checkedErrors.length).fill(false));
  const [newChecks, setNewChecks] = useState(Array(props.checkedErrors.length).fill(false));

  const [style] = useState(
    _.map(errorDefaults.slice(1, errorDefaults.length), (msg, index) =>
      <List.Item id={"item_" + index} className={STYLES.NOLINE} key={index + 1}>
        {msg}
      </List.Item>
    ));

  const renderList = () => {
    for (let i = 0; i < style.length; i++) {
      let doc = document.getElementById("item_" + i);
      if (!!doc && prevChecks[i + 1] !== newChecks[i + 1]) {
        if (newChecks[i + 1]) {
          doc.className = STYLES.CHECKED;
          setTimeout(() => {
            disableLine(doc);
          }, 1000);
        } else {
          if (doc.className === STYLES.CHECKED) {
            doc.className = STYLES.UNCHECKED;
          }
        }
      }
    }
    return style;
  };

  const disableLine = (doc) => {
    if (!!doc && doc.className !== STYLES.CHECKED) {
      doc.className = STYLES.NOLINE;
    }
  };

  return (
    <div className={"password-change"}>
      {!props.newAccount &&
        <h2>Change your password</h2>}
      <Grid className="change-grid">
        <Grid.Row className="sub-row" columns={2}>
          <Grid.Column width={8}>
            <Form onSubmit={props.onSave}>
              {!props.withToken &&
                <Form.Input
                  label={"Old password:"}
                  type={visOldPass ? "text" : "password"}
                  id={"old-password"}
                  maxLength={MAX_PASSWORD_LENGTH}
                  onChange={({ target }) => props.updateOldPassword(target.value)}
                  required
                  error={props.errorOldPassword ? props.errorOldPassword : undefined}
                  action={<ShowPasswordButton showFunc={setVisOldPass} />}
                />}
              <Form.Input
                label={props.newAccount ? "Password:" : "New password:"}
                type={visNewPass ? "text" : "password"}
                id={"password"}
                maxLength={MAX_PASSWORD_LENGTH}
                error={props.errorNewPassword ? props.errorNewPassword : undefined}
                onChange={({ target }) => props.updatePassword(target.value)}
                required
                action={<ShowPasswordButton showFunc={setVisNewPass} />}
              />
              <Form.Input
                label={"Repeat password:"}
                type={visVerifyPass ? "text" : "password"}
                id={"password-verify"}
                maxLength={MAX_PASSWORD_LENGTH}
                required
                error={props.errorVerifyPassword ? props.errorVerifyPassword : undefined}
                onChange={({ target }) => props.updateVerifyPassword(target.value)}
                action={<ShowPasswordButton showFunc={setVisVerifyPass} />}
              />
              <Form.Field>
                {!props.newAccount && <Form.Button
                  className={"change-pw-btn datahive-button"}
                  color={"red"}
                  disabled={!!props.disableFormButton}
                >
                  Change password
                </Form.Button>}
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column className={props.loadAnimations ? "data-loaded" : "preload"} width={8}>
            <List className="checked-errors">
              <List.Item> <span className={"bold-requirement"}>{"Password must contain:"}</span>
                <List>
                  {renderList()}
                </List>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        {!!props.newAccount && <Divider />}
      </Grid>
    </div>
  );
};


PasswordChangeForm.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  updateVerifyPassword: PropTypes.func.isRequired,
  checkedErrors: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  updateOldPassword: PropTypes.func,
  withToken: PropTypes.bool,
  newPasswordsMatch: PropTypes.bool,
  newPasswordSameAsOld: PropTypes.bool,
  newAccount: PropTypes.bool,
  loadAnimations: PropTypes.bool,
  errorOldPassword: PropTypes.string,
  errorNewPassword: PropTypes.string,
  errorVerifyPassword: PropTypes.string,
  disableFormButton: PropTypes.bool
};

export default PasswordChangeForm;
