import React, {Component} from "react";
import {Dimmer, Loader} from "semantic-ui-react";
import PropTypes from "prop-types";

class LoaderBlocker extends Component {
  static propTypes = {
    show: PropTypes.bool
  };

  render() {
    const show = this.props.show || false;

    return show ? (
      <Dimmer active={show} inverted>
        <Loader size="medium">Loading…</Loader>
      </Dimmer>
    ) : "";
  }
}

export default LoaderBlocker;
