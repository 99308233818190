import _ from "lodash";

import React from "react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Form,
  Icon,
  Input,
  Menu,
  Popup,
  Table,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

import ajax from "../../../../helpers/ajax";
import config from "../../../../config/config";
import FilteredComponent from "../../../../components/FilteredComponent";
import urls from "../../../../config/frontend_urls";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import SortableTable from "../../../../components/simple/SortableTable/SortableTable";
import Pagination from "../../../../components/Pagination";
import qs from "query-string";

import TaskListRow from "./TaskListItem";
import { getDateFormat } from "../../../../helpers/utils";
import { FormLevenDropdown as FormDropdown } from "../../../../components/simple/Dropdown/LevenDropdown";
import BottomBar from "../../../../components/BottomBar";
import { connect } from "react-redux";
import { yesNoOptions } from "../../../../helpers/filters";

import "./TaskAdminList.css";
import "../../../../style/buttons.css";

/* eslint-disable react/display-name */

export class TaskAdminList extends FilteredComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      tasks: null,
      imprints: [],
      totalPages: 1,
      slug: _.get(qs.parse(props.history.location.search), "slug") || "",
      filtersOptions: {
        staff: [],
      },
      popupOpen: false,
      datepickerOpen: false,
      selected: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getFiltersOptions();
  }

  getImprints = () => {
    const allTasks = this.state.tasks;
    let selectedTasks = [];
    if (this.props.auth.is_superuser) {
      selectedTasks = allTasks;
    } else {
      selectedTasks = allTasks.filter((task) => task.is_moderator !== false);
    }

    const tasks = _.map(selectedTasks, (task) => {
      return task.id;
    });
    ajax
      .get(config.ADMIN_TASKS_IMPRINT, { params: { tasks } })
      .then((response) => {
        if (response.data) {
          this.setState({ imprints: response.data });
        }
      });
    // Intentionally without catch.
    // If some error occured, imprints just don't display.
  };

  getData = (filters = null) => {
    const params = filters ? filters : this.getFilters();
    this.setState({
      tasks: null,
      filters: params,
    });
    ajax
      .get(config.ADMIN_TASKS, { params })
      .then((response) => {
        if (response.data) {
          this.setState(
            {
              tasks: response.data.results,
              totalPages: Math.ceil(response.data.count / config.PAGE_SIZE),
              selected: [],
            },
            () => {
              this.setState({ display_spinner: false });
            }
          );
        }
        this.getImprints();
      })
      .catch((errors) => {
        toastr.error(
          "Error!",
          _.get(errors, "response.statusText", "Something went wrong!")
        );
      });
  };

  updateFiltersOptions = (field, value) => {
    const filtersOptions = this.state.filtersOptions;
    filtersOptions[field] = value;
    this.setState({ filtersOptions });
  };

  updateUsersOptions = (field, data) => {
    const options = _.map(data, (item) => {
      return {
        key: item.username,
        value: item.username,
        text: item.username,
      };
    });
    options.unshift({
      key: "",
      value: "",
      text: "-----",
    });
    this.updateFiltersOptions(field, options);
  };

  getFiltersOptions = () => {
    ajax
      .get(config.USER_DETAILS_USERS_UNPAGINATED, {
        params: { is_staff: true },
      })
      .then((response) => {
        if (response.data) {
          this.updateUsersOptions("staff", response.data);
          this.updateUsersOptions("coordinators", response.data);
        }
      });
    ajax.get(config.TASK_STATUS_OPTIONS).then((response) => {
      if (response.data) {
        const status = _.map(response.data, (item) => {
          return {
            key: item[1],
            value: item[0],
            text: item[1],
          };
        });
        this.updateFiltersOptions("status", status);
      }
    });
    ajax.get(config.LANGUAGES).then((response) => {
      if (response.data) {
        const language = _.map(response.data, (item) => {
          return {
            key: item.name,
            value: item.name,
            text: item.display_name,
          };
        });
        language.unshift({
          key: "",
          value: "",
          text: "-----",
        });
        this.updateFiltersOptions("language", language);
      }
    });
    ajax.get(config.TASK_TYPES).then((response) => {
      const types = _.map(Object.keys(response.data), (type) => ({
        text: type,
        value: type,
        key: type,
      }));
      this.updateFiltersOptions("types", types);
    });
    ajax.get(config.MODULE_OPTIONS).then((response) => {
      if (response.data) {
        const modules = _.map(response.data, (module) => {
          return {
            key: `${module.id}`,
            value: `${module.id}`,
            text: module.name,
          };
        });
        modules.unshift({
          key: "",
          value: "",
          text: "-----",
        });
        this.updateFiltersOptions("modules", modules);
      }
    });
  };

  pageChange = (e, { activePage }) => {
    const newFilters = this.updateFilters({ page: activePage });
    this.getData(newFilters);
  };

  handleSubmit = () => {
    this.onSubmit();
    this.setState({ popupOpen: false });
  };

  onPopupClose = () => {
    if (this.state.datepickerOpen) {
      this.setState({ datepickerOpen: false });
    } else {
      this.setState({ popupOpen: false });
    }
  };

  onTaskSelection = (id, { checked }) => {
    let selected = [...this.state.selected];
    if (checked) {
      selected.push(id);
    } else {
      selected = selected.filter((task) => task !== id);
    }
    this.setState({ selected });
  };

  duplicateTasks = (withUsers = false) => {
    const data = {
      tasks: [...this.state.selected],
      with_users: withUsers,
    };
    ajax
      .post(config.ADMIN_DUPLICATE_TASKS, { data })
      .then(() => {
        toastr.success("Success!", "Tasks duplicated!");
        this.setState({ displaySpinner: false, selected: [] }, this.getData);
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
        this.setState({
          displaySpinner: false,
          errors: errors.response.data,
        });
      });
  };

  updateFavorites = (slug, isFavorite, pinColor) => {
    const text = !isFavorite ? "added to" : "removed from";
    ajax
      .post([config.TASKS_UPDATE_FAVORITES, slug, pinColor])
      .then(() => {
        toastr.success("Success!", `Task ${text} favorites!`);
        const tasks = this.state.tasks;
        const taskIndex = tasks.findIndex((t) => t.slug === slug);
        const updatedTask = {
          ...tasks[taskIndex],
          is_favorite: !isFavorite,
          favorites_pin_color: pinColor,
        };
        const filteredTasksList = tasks.filter((t) => t.slug !== slug);
        const firstNotFavoriteTaskIndex = filteredTasksList.findIndex(
          (t) => t.is_favorite === false
        );
        let updatedTasksList = [];
        updatedTask.is_favorite
          ? (updatedTasksList = [updatedTask, ...filteredTasksList])
          : (updatedTasksList = [
              ...filteredTasksList.slice(0, firstNotFavoriteTaskIndex),
              updatedTask,
              ...filteredTasksList.slice(
                firstNotFavoriteTaskIndex,
                filteredTasksList.length
              ),
            ]);
        this.setState({ tasks: updatedTasksList });
      })
      .catch((errors) => {
        toastr.error("Error!", this.getErrorText(errors));
      });
  };

  renderFilter = () => {
    const deadline_from =
      "deadline_from" in this.state.filters
        ? moment(this.state.filters.deadline_from).format(getDateFormat())
        : "";
    const deadline_to =
      "deadline_to" in this.state.filters
        ? moment(this.state.filters.deadline_to).format(getDateFormat())
        : "";

    return (
      <Menu className={"filters-menu"}>
        <Form style={{ padding: "14px" }}>
          <FormDropdown
            className={"owner"}
            label="Owner"
            placeholder={"Owner"}
            fluid
            search
            selection
            options={this.state.filtersOptions.staff || []}
            value={this.state.filters.owner}
            onChange={(e, data) => this.setFilterState("owner", data.value)}
          />
          <FormDropdown
            className={"s-coordinator"}
            label="Internal coordinator"
            placeholder="Internal coordinator"
            fluid
            search
            selection
            options={this.state.filtersOptions.coordinators || []}
            value={this.state.filters["internal_coordinator"]}
            onChange={(e, data) =>
              this.setFilterState("internal_coordinator", data.value)
            }
          />
          <FormDropdown
            className="is_active"
            label="Is active"
            placeholder="Is active"
            fluid
            search
            selection
            options={yesNoOptions}
            value={this.state.filters.is_active}
            onChange={(e, data) => this.setFilterState("is_active", data.value)}
          />
          <Form.Field className={"deadline-from"}>
            <label>Deadline from</label>
            <DateInput
              dateFormat={"L"}
              popupPosition="right center"
              closable={false}
              key={"deadline-from" + this.state.filters.deadline_from}
              name="deadline-from"
              placeholder="Deadline from"
              value={deadline_from}
              maxDate={deadline_to ? deadline_to : null}
              onChange={(e, { value }) => {
                const deadline = value ? moment(value, "L").toISOString() : "";
                this.setFilterState("deadline_from", deadline);
              }}
              onClick={() => this.setState({ datepickerOpen: true })}
            />
          </Form.Field>
          <Form.Field className={"deadline-to"}>
            <label>Deadline to</label>
            <DateInput
              closable={false}
              dateFormat={"L"}
              popupPosition="right center"
              key={"deadline-to" + this.state.filters.deadline_from}
              name="deadline-to"
              placeholder="Deadline to"
              value={deadline_to}
              minDate={deadline_from ? deadline_from : null}
              onChange={(e, { value }) => {
                const deadline = value
                  ? moment(value, "L").add(86399, "seconds").toISOString()
                  : ""; // add 23:59:59h
                this.setFilterState("deadline_to", deadline);
              }}
              onClick={() => this.setState({ datepickerOpen: true })}
            />
          </Form.Field>
          <Form.Field className={"language"}>
            <FormDropdown
              label="Language"
              placeholder="Language"
              fluid
              search
              selection
              options={this.state.filtersOptions.language || []}
              value={this.state.filters.language}
              onChange={(e, data) =>
                this.setFilterState("language", data.value)
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label="Question ID"
              value={this.state.filters.id || ""}
              onChange={(e, data) => {
                this.setFilterState("id", data.value);
              }}
              type="number"
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              label="Task type"
              placeholder="Task type"
              fluid
              search
              selection
              options={this.state.filtersOptions.types || []}
              value={this.state.filters.task_type || ""}
              onChange={(e, data) => {
                this.setFilterState("task_type", data.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <FormDropdown
              label="Module"
              placeholder="Module"
              fluid
              search
              selection
              options={this.state.filtersOptions.modules || []}
              value={this.state.filters.module}
              onChange={(e, data) => {
                this.setFilterState("module", data.value);
              }}
            />
          </Form.Field>
          <div style={{ textAlign: "center" }}>
            <Form.Button
              className="datahive-button"
              color="yellow"
              type="submit"
              onClick={this.handleSubmit}
            >
              Submit
            </Form.Button>
            <Form.Button
              className="datahive-button"
              color="grey"
              type="submit"
              onClick={() => this.setState({ popupOpen: false })}
            >
              Close
            </Form.Button>
          </div>
        </Form>
      </Menu>
    );
  };

  renderItems = () => {
    return _.map(this.state.tasks, (t, i) => {
      let imprint = null;
      if (t.slug in this.state.imprints) {
        imprint = this.state.imprints[t.slug];
      }
      return (
        <TaskListRow
          task={t}
          key={i}
          imprint={imprint}
          selected={this.state.selected.includes(t.id)}
          onSelect={this.onTaskSelection}
          updateFavorites={this.updateFavorites}
        />
      );
    });
  };

  renderButtons = () => {
    return (
      <>
        <div className={"bottom-right"}>
          <Button
            className={
              "datahive-button bottom-bar-duplicate bottom-bar-duplicate"
            }
            content="Duplicate"
            color="blue"
            onClick={() => this.duplicateTasks()}
          />
        </div>
        <div className={"bottom-right"}>
          <Button
            className={
              "datahive-button bottom-bar-duplicate bottom-bar-duplicate-with-users"
            }
            content="Duplicate with users"
            color="blue"
            onClick={() => this.duplicateTasks(true)}
          />
        </div>
      </>
    );
  };

  render() {
    const dataLoaded = !!this.state.tasks;
    const filters = this.state.filters;
    const columns = [
      {
        name: "favorite",
        title: "Favorites",
        headerProps: { width: 1 },
      },
      {
        name: "selected",
        title: "",
        headerProps: { width: 1 },
        unsortable: true,
      },
      {
        name: "active",
        title: "Active",
        headerProps: { width: 1 },
      },
      {
        name: "title",
        title: "Title",
        headerProps: { width: 6 },
      },
      {
        name: "deadline",
        title: "Deadline",
        headerProps: { width: 2 },
      },
      {
        name: "progress",
        title: "Progress",
        headerProps: { width: 3 },
        unsortable: true,
      },
      {
        name: "actions",
        title: "",
        headerProps: { width: 7 },
        unsortable: true,
      },
      {
        name: "owner",
        title: "Owner",
        headerProps: { width: 2 },
      },
      {
        name: "coordinator",
        title: "Coordinator",
        headerProps: { width: 2 },
      },
      {
        name: "created",
        title: "Created",
        headerProps: { width: 2 },
      },
      {
        name: "modified",
        title: "Modified",
        headerProps: { width: 2 },
      },
    ];

    return (
      <div
        className={
          this.state.selected.length
            ? "TaskAdminList bottom-bar-displayed"
            : "TaskAdminList"
        }
      >
        <div className={"header"}>
          <div className={"header-left"}>
            <Input
              icon={{ name: "search" }}
              placeholder="Task title"
              onChange={(e, data) =>
                this.debouncedFilterChange("slug", data.value)
              }
              value={this.state.filters.slug || ""}
            />
            <Popup
              on="click"
              position={"bottom center"}
              style={{ padding: 0 }}
              trigger={
                <Button
                  className="datahive-button"
                  color="dark-grey"
                  onClick={() => {
                    this.setState({ popupOpen: true });
                  }}
                >
                  Filter
                  <Icon name="filter" />
                </Button>
              }
              content={this.renderFilter()}
              open={this.state.popupOpen}
            />
            <span
              className={"reset-filters-button"}
              onClick={this.resetFilters}
            >
              Reset all filters
            </span>
          </div>
          {this.props.auth.has_user_data_access && (
            <Link as={"Button"} to={urls.ADMIN_TASK_DETAIL_NEW}>
              <Button
                className="datahive-button"
                color="yellow"
                content={"Add new task"}
              />
            </Link>
          )}
        </div>
        <SortableTable
          columns={columns}
          sorted={_.get(filters, "order_by")}
          tableProps={{
            compact: true,
            className: "SortableTable",
            celled: false,
          }}
          updateSorting={this.updateSorting}
        >
          <Table.Body>
            {!dataLoaded && (
              <Table.Row>
                <Table.Cell colSpan={10}>
                  <LoaderSpinner />
                </Table.Cell>
              </Table.Row>
            )}
            {dataLoaded && this.renderItems()}
          </Table.Body>
        </SortableTable>
        <Pagination
          activePage={filters.page || 1}
          onPageChange={this.pageChange}
          totalPages={this.state.totalPages}
        />
        {this.state.selected.length > 0 && (
          <BottomBar panelRight={this.renderButtons()} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(TaskAdminList);
