import React, {Component} from "react";
import "../Tasks.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {Grid} from "semantic-ui-react";
import {DatesRangeInput} from "semantic-ui-calendar-react";
import {NATIVE_OPTIONS} from "./PriceTariffsFunctions";
import PropTypes from "prop-types";
import _ from "lodash";
import InfiniteDropdown from "../../components/simple/InfiniteDropdown";
import Dropdown from "../../components/simple/Dropdown/LevenDropdown";

class PriceTariffsFilters extends Component {
  static propTypes = {
    changeFilter: PropTypes.func.isRequired,
    companyOptions: PropTypes.array,
    filterByUser: PropTypes.bool.isRequired,
    filterParams: PropTypes.object.isRequired,
    languageOptions: PropTypes.array.isRequired,
    taskTypeOptions: PropTypes.object.isRequired,
    userOptions: PropTypes.array,
    userTypeOptions: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {filterByUser, filterParams} = this.props;
    let name = filterByUser ? "User" : "Company";
    let options = filterByUser ? this.props.userOptions : this.props.companyOptions;

    let value;
    if (filterByUser) {
      value = filterParams.user || [];
    } else {
      value = filterParams.company || [];
    }

    return (
      <div className={"filters"}>
        <Grid>
          <Grid.Row columns={6}>
            <Grid.Column width={3}>
              {name}
              <InfiniteDropdown
                choices={options}
                chunkSize={10}
                fluid
                search
                multiple
                selection
                placeholder={name}
                value={_.compact(_.concat([], value)) || []}
                onChange={(e, data) =>
                  this.props.changeFilter(name.toLowerCase(), data.value)
                }
              />
            </Grid.Column>
            <Grid.Column width={3}>
              Data
              <DatesRangeInput
                fluid
                dateFormat={"YYYY-MM-DD"}
                name={"datesRange"}
                placeholder={"Start date - End date"}
                value={filterParams.date_range || ""}
                iconPosition="left"
                onChange={(e, data) => {
                  this.props.changeFilter("date_range", data.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              Language
              <Dropdown
                fluid
                search multiple selection
                placeholder={"Language"}
                options={this.props.languageOptions}
                value={_.compact(_.concat([], filterParams.language))}
                onChange={(e, data) => {
                  this.props.changeFilter("language", data.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              Task type
              <Dropdown
                fluid
                search multiple selection
                placeholder={"Task type"}
                options={_.map(Object.keys(this.props.taskTypeOptions), (type) => {
                  return {
                    text: type,
                    value: type,
                    key: type.id
                  };
                })}
                value={_.compact(_.concat([], filterParams.task_type))}
                onChange={(e, data) => {
                  this.props.changeFilter("task_type", data.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              Native
              <Dropdown
                fluid selection
                placeholder={"Native"}
                options={
                  _.concat([{
                    key: null,
                    value: null,
                    text: "-----"
                  }], NATIVE_OPTIONS)
                }
                value={filterParams.native || ""}
                onChange={(e, data) => {
                  this.props.changeFilter("native", data.value);
                }}
              />
            </Grid.Column>
            {!filterByUser ?
              <Grid.Column width={2}>
                User type
                <Dropdown
                  fluid multiple selection
                  placeholder={"User type"}
                  options={this.props.userTypeOptions}
                  value={_.compact(_.concat([], filterParams.user_type))}
                  onChange={(e, data) => {
                    this.props.changeFilter("user_type", data.value);
                  }}
                />
              </Grid.Column>
              : null}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default PriceTariffsFilters;
