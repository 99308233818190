// for IE compability
import "@babel/polyfill";

import React from "react";
import * as ReactDOM from "react-dom";
import AppRouter from "./containers/AppRouter";
import "semantic-ui-css/semantic.min.css";

import "./index.css";

const render = (Component) => {
  ReactDOM.render(
    <Component/>,
    document.getElementById("root")
  );
};

render(AppRouter);

/* eslint-disable no-undef */
if (module.hot) {
  module.hot.accept("./containers/AppRouter", () => {
    const nextAppRouter = require("./containers/AppRouter").default;
    render(nextAppRouter);
  });
}
/* eslint-enable no-undef */

// unregister();
if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(
    function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
}
