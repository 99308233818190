import React, {Component} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {Header} from "semantic-ui-react";

import "./TaskImprint.css";
import {getDateFormat} from "../../../../helpers/utils";

export default class TaskImprint extends Component {
  static propTypes = {
    imprint: PropTypes.shape({
      answers: PropTypes.shape({
        total: PropTypes.number,
        done: PropTypes.number,
        left: PropTypes.number,
      }),
      users: PropTypes.shape({
        total: PropTypes.number,
        active: PropTypes.number,
      }),
      estimated_end: PropTypes.string,
      assigned: PropTypes.number
    }).isRequired,
    type: PropTypes.string
  };


  render() {
    const imprint = this.props.imprint;

    if (!imprint || Object.keys(imprint).length === 0) {
      return (
        <React.Fragment/>
      );
    }

    const estimated_end = imprint.estimated_end ?
      moment(imprint.estimated_end).format(getDateFormat()) : "Cannot be estimated!";
    return (
      <div className={"task-imprint"}>
        <Header>Answers</Header>
        <div>
          <div className={"task-imprint__block"}>
            <div className={"label"}>Total:</div>
            <div className={"value"}>{imprint.answers.total}</div>
          </div>
          <div className={"task-imprint__block"}>
            <div className={"label"}>Done:</div>
            <div className={"value"}>{imprint.answers.done}</div>
          </div>
          <div className={"task-imprint__block"}>
            <div className={"label"}>Left:</div>
            <div className={"value"}>{imprint.answers.left}</div>
          </div>
        </div>
        <Header>Users</Header>
        <div>
          <div className={"task-imprint__block"}>
            <div className={"label"}>Total:</div>
            <div className={"value"}>{imprint.users.total}</div>
          </div>
          <div className={"task-imprint__block"}>
            <div className={"label"}>Active:</div>
            <div className={"value"}>{imprint.users.active}</div>
          </div>
        </div>
        <Header>Est. end day</Header>
        <div>
          <div className={"task-imprint__block task-imprint__date"}>
            {estimated_end}
          </div>
          <Header>Assigned</Header>
          <div>
            <div className={"task-imprint__block"}>
              {imprint.assigned}
            </div>
          </div>
          <Header>Type</Header>
          <div className={"task-imprint__block"}>
            <div className={"value"}>{this.props.type}</div>
          </div>
        </div>
      </div>
    );
  }
}
